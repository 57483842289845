import { app, ControlState, globalMatrix } from "../../../globals";
import { FieldHandlerFactory, IPlugin, plugins } from "../../businesslogic/index";
import { IExternalItem, Tasks } from "../../businesslogic/index";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { IWorkflowControlOptions };
export { WorkflowControlImpl };
export { initialize };

interface IWorkflowControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: {
        titleBarControl?: JQuery; // should be one level above
        pollFrequencyMS?: number; // when clicking on a link, default 1000 ms
        pollCount?: number; // when clicking on a link how often to poll, default 20
    };
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.workflowControl = function (this: JQuery, options: IWorkflowControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_workflow,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new WorkflowControlImpl(this, options.fieldHandler as EmptyFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class WorkflowControlImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IWorkflowControlOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private btn: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private issue: IExternalItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private waitForChange: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private poll: number;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IWorkflowControlOptions) {
        let that = this;
        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                // item the item containing the rest of the information
            },
        };
        this.settings = <IWorkflowControlOptions>ml.JSON.mergeOptions(defaultOptions, options);

        if (this.settings.controlState === ControlState.Print) {
            // to avoid two JIRA calls
            return;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.parameter.titleBarControl) {
            return;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let space = this.settings.parameter.titleBarControl.find(".workflowTools");
        if (!space) {
            return;
        }

        this.poll = 0;
        this.btn = $("<button class='btn btn-default btn-xs jiratooltip'>");

        if (this.settings.canEdit) {
            this.btn.click(function () {
                if (that.issue) {
                    let win = window.open(that.issue.externalItemUrl);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.poll = that.settings.parameter.pollCount ? that.settings.parameter.pollCount : 20;
                    that.updateButton();
                } else {
                    that.setButtonSpinning();
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    Tasks.createOne2OneTask(that.settings.id)
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        .done(function (externalItem: IExternalItem) {
                            that.issue = externalItem;
                            that._root.data("new", externalItem.externalItemId);
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.settings.valueChanged();
                            that.updateButton();
                        })
                        .fail(function (msg) {
                            ml.UI.showError("cannot create task", msg);
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.issue = null;
                            that.updateButton();
                        });
                }
            });
        }

        space.append(this.btn);

        if (this.settings.fieldValue) {
            this.setButtonSpinning();

            Tasks.getOne2OneTask(this.settings.fieldValue)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(function (externalItem: IExternalItem) {
                    that.issue = externalItem;
                    that.updateButton();
                })
                .fail(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.issue = null;
                    that.updateButton();
                });
        } else {
            that.updateButton();
        }

        this._root.data("original", this.settings.fieldValue);
        this._root.data("new", this.settings.fieldValue);
    }
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return this._root.data("original") !== this._root.data("new");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getValueAsync() {
        return this._root.data("new");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(issueId: string) {
        let that = this;

        let pluginId = 0;
        $.each(Tasks.tasksConfiguration, function (idx, config) {
            if (config.one2OneMapping) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                pluginId = config.pluginId;
            }
        });
        if (!pluginId) {
            ml.UI.showError("no one to one mapping defined", "need to change configuration to allow to do that!");
        }
        that.setButtonSpinning();

        Tasks.getMeta(pluginId, issueId).done(function (ticket) {
            Tasks.postCreateLinks(app.getCurrentItemId(), [
                {
                    externalItemId: issueId,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    externalItemTitle: ticket.externalItemTitle,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    externalItemUrl: ticket.externalItemUrl,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    externalDone: ticket.externalDone,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    externalDescription: ticket.externalDescription,
                    plugin: pluginId,
                },
            ])
                .done(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that._root.data("new", ticket.externalItemId);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.issue = ticket;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.settings.valueChanged();
                    that.updateButton();
                })
                .fail(function (msg) {
                    ml.UI.showError("cannot create task", msg);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.issue = null;
                    that.updateButton();
                });
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {
        window.clearTimeout(this.waitForChange);
        $(".popover").remove();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    // private functions

    // initialize object

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private updateButton(waitForChangeItemId?: string) {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (waitForChangeItemId && waitForChangeItemId != this.settings.id) {
            // ignore
            return;
        }
        window.clearTimeout(this.waitForChange);
        this.btn.prop("disabled", false);
        let renderInfo = Tasks.getOne2OneRenderInfo(that.issue);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.btn.css("background-color", renderInfo.background).css("color", renderInfo.color);
        this.btn.html(renderInfo.text);
        this.btn.css("text-decoration", renderInfo.strikethrough ? "line-through" : "");

        if (this.issue) {
            this.btn.data("issue-key", this.issue.externalItemId);
            this.btn.data("issue-done", this.issue.externalDone);
            this.btn.popover({
                container: "body",
                html: true,
                placement: "auto",
                title: "Task Description",
                trigger: "hover",
                content: this.issue.externalDescription ? this.issue.externalDescription : this.issue.externalItemId,
            });
            if (this.poll > 0) {
                this.waitForChange = window.setTimeout(
                    function (itemId: string) {
                        that.poll--;
                        Tasks.getOne2OneTask(that.issue.externalItemId)
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .done(function (externalItem: IExternalItem) {
                                that.issue = externalItem;
                                that.updateButton(itemId);
                            })
                            .fail(function () {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                that.issue = null;
                                that.updateButton(itemId);
                            });
                    },
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.settings.parameter.pollFrequencyMS ? that.settings.parameter.pollFrequencyMS : 1000,
                    this.settings.id,
                );
            }
        } else {
            this.btn.data("issue-key", "");
            this.btn.data("issue-done", false);
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setButtonSpinning() {
        this.btn.prop("disabled", true);
        this.btn.html('<span class="fal fa-sync-alt icon-refresh-animate"></span>');
    }
}

class WorkflowMenu implements IPlugin {
    public isDefault = true; // show always (no need to enable in admin)

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMenu(ul: JQuery) {
        let that = this;

        let current = ml.Item.parseRef(app.getCurrentItemId());
        if (!current || current.isFolder) {
            return;
        }
        let workflow = globalMatrix.ItemConfig.getFieldsOfType("workflow", current.type);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (workflow.length != 1) {
            return;
        }
        let menu = $('<li><a href="javascript:void(0)">Add/change workflow link</a></li>')
            .click(function () {
                that.editLink(workflow[0].field.id);
            })
            .appendTo(ul);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private editLink(fieldId: number) {
        let form = $(
            '<div class="container" style="width:100%;height:100%;position:relative">' +
                '  <div class="row"><br/>' +
                "  </div>" +
                '  <div class="row">' +
                '    <div style="padding:0" class="col-md-1"><label style="white-space: nowrap;padding-top:6px" for="issueId" class="control-label">Issue Id:</label></div>' +
                '    <div class="col-md-11"><input autocomplete="off" type="text" class="form-control" id="issueId" placeholder="enter issue id"></div>' +
                "  </div>" +
                "</div>",
        );

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-v-scroll");
        app.dlgForm.addClass("dlg-no-scroll");
        app.dlgForm.append(form);

        app.dlgForm.dialog({
            autoOpen: true,
            title: "Edit one 2 one link",
            height: 250,
            width: 600,
            modal: true,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            resizeStop: function () {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        let newIssueId = $("#issueId").val();
                        app.setFieldValue(fieldId, newIssueId);
                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
            open: async function () {
                $("#issueId").val(await app.getFieldValueAsync(fieldId));

                $("#inputUrl").focus();
            },
        });
    }
}

// **********************************************************
// init plugin's
// **********************************************************

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    // register the plugin for the folder pre-selection
    plugins.register(new WorkflowMenu());
}
