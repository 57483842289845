import { IStringJQueryMap, IStringStringArrayMap, IStringMap } from "../globals";
import { IDropdownOption, IRiskConfig } from "../ProjectSettings";
import { IPrintCustomFormatter } from "./PrintFormatter";
import { IPrintFunctionParamsOverwrites, IPrintBaseFunctionMap, IPrintFunctionMap } from "./PrintFunction";
import { IPrintFieldInfo } from "./PrintIterators";
import { IPrintSorterMap } from "./PrintSorter";

export type {
    ICustomSection,
    IPrintProcessorOptions,
    IProcessResult,
    IPrintProcessor,
    IPrintConfig,
    IStringRiskConfigMap,
    IFieldCache,
    IPrintGlobals,
};

export { PrintFindAllScriptsRegex, globalPrintConfig, setGlobalPrintConfig };

/************************************** Main section definition  ********************************************/
interface ICustomSection {
    //title: string // the section title
    //depth?: number // optional heading depth for title, default is <H1>
    description?: string; // a rich text description
    descriptionContent?: string; // a rich text description if there are kids
    descriptionNoContent?: string; // a rich text description if there are no kids
    formatter: string; // either a list or table formatter id
    functionDefaults?: IPrintFunctionParamsOverwrites; // possible to overwrite defaults for functions
}

// saved in customer, project or section
interface IPrintProcessorOptions {
    headers?: {
        maxDepth?: number; // can be used to limit the <H2>, <H3> headings to a certain depth (0: no restriction, 1 no headers, 2: one level <h2>)
        altBefore?: string; // can be used to define an alternative if depth is bigger than max, e.g. "<div style='text-decoration:underline' class='myheading_depth_'>"
        altAfter?: string; // closes the above tag </div>
    };
}

interface IProcessResult {
    /** generated html */
    html: string;
    /** primary list of items from selection + first level interators */
    redlining: string[];
}

interface IPrintProcessor {
    prepareProcessing(mf: JQuery, onError: (message: string) => void, format: string): void;
    processSection(
        formatter: IPrintCustomFormatter,
        section: ICustomSection,
        projectOverwrites: IPrintFunctionParamsOverwrites,
        selection: string[],
        possibleTargets: string[],
    ): Promise<IProcessResult>;
    getCustomStylesheet(): string;

    /**
     * This can be used by render functions to get a table and process its data,
     * for example for a row count
     * @param tableId The (sub)table to render
     * @param selection The selection to use as the root
     */
    getTableData(tableId: string, selection: string[]): Promise<string>;

    globals?: IPrintGlobals;
}

const PrintFindAllScriptsRegex = /\$\[.*?\]\$/g;

interface IPrintConfig {
    getPrintProcessor(): IPrintProcessor;
    getFieldAndLabelsIteratorsDropdown(): IDropdownOption[];
    getItemIteratorsDropdown(items: boolean, folders: boolean, allowNoIterator: boolean): IDropdownOption[];
    getItemConditionDropdown(): IDropdownOption[];
    showOptionsEditor(fctName: string, currentValue: string, onUpdate: (newValue: string) => void): void;
    editFunctionOptions(currentValue: string, onUpdate: (newOptions: string) => void): void;
    editStyle(wrap: JQuery): void;
    functionHasOptions(functionUid: string): boolean;
    getFunctions(group: string): IPrintFunctionMap;
    getItemSorters(): IPrintSorterMap;
    getAllFunctions(): IPrintBaseFunctionMap;
    getAllIterators(): IPrintBaseFunctionMap;
}

let globalPrintConfig: IPrintConfig;
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function setGlobalPrintConfig(config: IPrintConfig) {
    globalPrintConfig = config;
}
// let globalPrintConfig =  (window as any).__GLOBAL_PRINT_CONFIG__ as IPrintConfig;
interface IStringRiskConfigMap {
    [key: string]: IRiskConfig;
}
interface IFieldCache {
    [key: string]: IPrintFieldInfo;
}
interface IPrintGlobals {
    itemMap: IStringJQueryMap;
    riskControlCategories: IStringStringArrayMap;
    categories: IStringJQueryMap;
    fieldsPerCategory: IStringJQueryMap; // the field definitions
    fieldIdByLabel: IStringMap; // note: label to id is not necessarily unique
    fieldDefById: IStringJQueryMap; //  for a combination of category and field id -> caches the specs of the field
    riskConfigs: IStringRiskConfigMap;
    down: IStringStringArrayMap;
    up: IStringStringArrayMap;
    children: IStringStringArrayMap;
    count: number;
    lastItem: string; // cache last retrieved item
    lastFields: IFieldCache; // cache last retrieved fields
}
