/// <reference types="matrixrequirements-type-declarations" />
import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IColorPickerParams, IColorPickerControlOptions };
export { ColorPickerImpl };

interface IColorPickerParams {
    externalHelp?: string;
    readonly?: boolean;
    allowResize?: boolean;
    requiresContent?: boolean;
    inlineHelp?: string;
    initialContent?: string; // allows to set the initial content of text boxes
    hideFullscreen?: boolean; // set to true to hide fullscreen button
}

interface IColorPickerControlOptions extends IBaseControlOptions {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    lostFocus?: Function;
    parameter?: IColorPickerParams;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.colorPicker = function (this: JQuery, options: IColorPickerControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_colorPicker,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new ColorPickerImpl(this, options.fieldHandler as GenericFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };

    baseControl.init(options);
    return this;
};

class ColorPickerImpl extends BaseControl<GenericFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IColorPickerControlOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastValueChanged: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _editor: JQuery;
    private doesRequireContent: boolean = false;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IColorPickerControlOptions) {
        let that = this;

        let defaultOptions: IColorPickerControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            dummyData: false, // fill control with a dumy text (for form design...)
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                allowResize: true, // allow to resize control
                hideFullscreen: false, //  hide fullscreen
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);
        // have default values
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.fieldValue && this.settings.parameter.initialContent && !this.settings.item) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.fieldValue = this.settings.parameter.initialContent;
        }
        if (typeof this.settings.fieldValue === "undefined" || this.settings.fieldValue === "") {
            this.settings.fieldValue = "grey";
        }

        // Convert the color to HEX
        this.settings.fieldValue = ml.UI.standardizeColor(this.settings.fieldValue);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.settings.fieldValue != undefined && this.settings.fieldValue.length == 9) {
            this.settings.fieldValue = this.settings.fieldValue.substring(0, this.settings.fieldValue.length - 2);
        }

        if (this.settings.controlState === ControlState.Print || this.settings.controlState === ControlState.Tooltip) {
            this._root.append(super.createHelp(this.settings));
            let css = this.settings.controlState === ControlState.Print ? "class='printBox'" : "";
            this._root.append(
                `<div class='${css}' style='height:20px; width:40px;background:${this.settings.fieldValue}'></div>`,
            );
            return;
        }

        if (options.parameter && options.parameter.requiresContent) {
            this.doesRequireContent = options.parameter.requiresContent;
        }
        let helpLine = this._root.append(super.createHelp(this.settings));
        let ctrlContainer = $("<div>").addClass("baseControl");

        this._root.append(ctrlContainer);
        this._editor = $(
            '<input class="lineInput form-control" type="color" ' + (this.settings.canEdit ? "" : "readonly ") + " />",
        );

        ctrlContainer.append(this._editor);

        this._editor.val(this.settings.fieldValue);

        // remove mouseout to avoid frequent changes change
        this._editor.change(function () {
            clearTimeout(that.lastValueChanged);
            console.log("Color changed!");
            that.lastValueChanged = window.setTimeout((noCallBack?: boolean) => that.valueChanged(noCallBack), 50);
        });
        this._editor.on("blur", function () {
            if (that.settings.focusLeft) {
                that.settings.focusLeft();
            }
        });
        let rt = this._editor.val();
        this._root.data("original", rt);
        this._root.data("new", rt);
    }

    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        // make sure no changes are pending
        clearTimeout(this.lastValueChanged);
        // this will take and text from the editor and put it int he variable  _root.data("new")
        // but it will not recursively trigger a change
        this.valueChanged(true);
        // now compare
        return this._root.data("original") !== this._root.data("new");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        // make sure no changes are pending
        clearTimeout(this.lastValueChanged);
        this.valueChanged(true);
        let text = this._root.data("new");
        return DOMPurify.sanitize(text);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    requiresContent() {
        return this.doesRequireContent;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refresh() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(newValue: string, reset?: boolean) {
        if (this._editor) {
            this._editor.val(newValue);
        }

        this._root.data("new", newValue);
        if (reset) {
            this._root.data("original", newValue);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {
        if (this._editor) {
            this._editor.off();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this._editor = null;
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    //  private functions
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private valueChanged(noCallback?: boolean) {
        if (this._editor) {
            this._root.data("new", this._editor.val());
        }
        if (this.settings.valueChanged && !noCallback) {
            // removed cause the event should be sent also sent if something changes back to normal ... && this._root.data("new")!=this._root.data("original")) {
            this.settings.valueChanged.apply(null);
        }
    }

    // initialize object
}
