/// <reference types="matrixrequirements-type-declarations" />
import { XRFieldTypeAnnotated } from "../../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, plugins } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";

import { mDHF } from "../../../common/businesslogic/index";
import { IItem, IGenericMap, globalMatrix, matrixSession, app, restConnection } from "../../../globals";

export type { IItemCopyBuffer };
export { initialize };

interface IItemCopyBuffer extends IItem {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: any;
}

class CopyPaste implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private copypasteConfig: XRFieldTypeAnnotated[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private copyIsFolder: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lookup: IGenericMap;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private itemCopied: number;
    public isDefault = true;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {}
    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMenu(ul: JQuery) {
        let that = this;

        let dateFormat = new SimpleDateFormat("yyMMdd");
        let today = dateFormat.format(new Date());

        let extras = globalMatrix.ItemConfig.getExtrasConfig();

        if (
            extras &&
            this._item.type !== "XTC" &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            mDHF.getDocumentTypes().indexOf(this._item.type) == -1 &&
            (ml.JSON.isTrue(extras.copyPaste) || (extras.copyPaste === "admin" && matrixSession.isAdmin()))
        ) {
            // create COPY menu entry
            let dev = $(
                '<li><span class="toolmenu">Copy ' +
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (ml.Item.parseRef(this._item.id).isFolder ? "folder" : "item") +
                    "</span></li>",
            );
            dev.click(function () {
                globalMatrix.serverStorage.setItem(today + "copied", matrixSession.getProject() + "/" + that._item.id);
                let labels = ml
                    .CreateNewLabelTools()
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .getLabelDefinitions([that._item.type])
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    .filter((label) => label.dontCopy == true)
                    .map((label) => label.label);
                globalMatrix.serverStorage.setItem(today + "ignoreLabels", labels.length ? labels.join(",") : "");
                ml.UI.showSuccess(
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    "Copied " + (ml.Item.parseRef(that._item.id).isFolder ? "folder with items" : "item"),
                );
            });

            ul.append(dev);

            // create PASTE menu entry
            let lastCopied = globalMatrix.serverStorage.getItem(today + "copied");
            let ignoreLabels = globalMatrix.serverStorage.getItem(today + "ignoreLabels");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (app.isFolder(this._item.id) && this._item.type !== "XTC" && lastCopied) {
                let lastCopiedProject = lastCopied.split("/")[0];
                let lastCopiedItem = lastCopied.split("/")[1];
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let what = lastCopiedProject == matrixSession.getProject() ? lastCopiedItem : lastCopied;
                let dev = $('<li><span class="toolmenu">Paste ' + what + "</span></li>");
                dev.click(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    matrixSession.getCommentAsync().done(function (comment: string) {
                        const project = matrixSession.getProject();

                        if (!project || !that._item.id || !that._item.type) {
                            return;
                        }

                        let params: Record<string, string | number> = {
                            copyLabels: 1,
                            targetFolder: that._item.id,
                            targetProject: project,
                            reason: comment,
                        };
                        let labels = ml
                            .CreateNewLabelTools()
                            .getLabelDefinitions([that._item.type])
                            .filter((label) => label.dontCopy === true)
                            .map((label) => label.label);
                        if (ignoreLabels) {
                            for (let label of ignoreLabels.split(",")) {
                                if (labels.indexOf(label) === -1) {
                                    labels.push(label);
                                }
                            }
                        }

                        if (labels.length) {
                            params["ignoreLabels"] = labels.join(",");
                        }
                        restConnection
                            .postServer(lastCopiedProject + "/copy/" + lastCopiedItem, params)
                            .done(function () {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                if (app.canNavigateAwayAsync()) {
                                    // need to reload

                                    ml.UI.showSuccess("Pasted " + what + ". Reloading project...");
                                    window.setTimeout(function () {
                                        location.reload();
                                    }, 1500);
                                } else {
                                    ml.UI.showSuccess("Pasted " + what + ". Reload to see the pasted item(s)");
                                }
                            });
                    });
                });
                ul.append(dev);
            }
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    supportsControl() {
        return false;
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    plugins.register(new CopyPaste());
}
