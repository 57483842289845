/// <reference types="matrixrequirements-type-declarations" />
import { ml } from "../../matrixlib";
import { ISearchCounts } from "./ProjectTree";
import { SearchState, IDelayedAction } from "./ProjectViewDefines";
import { ProjectView } from "./ProjectView";

import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { NavBar } from "./NavigationBar";
import { app, globalMatrix, matrixSession } from "../../../globals";
import { LabelSwitches } from "../Parts/LabelSwitches";
import { plugins } from "../../businesslogic";

export type SearchBoxSelection = "all" | "matching";

export class SearchBox {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private spinnerServerSearch: number;
    private currentStatus: SearchState;
    private currentResults: string[];
    private currentSearchExpression: string;
    private delayedAction: IDelayedAction;
    private panel: ProjectView;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private filterHighlight_timeout: number; // timeout for highlighting while filtering
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private filterSearch_timeout: number; // timeout for search while filtering
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private searchMetaInfo: JQuery;
    private isEnabled: boolean;
    private isConfigSearch: boolean;
    private savedSearchIndex = -1;

    constructor(panel: ProjectView) {
        this.panel = panel;

        this.currentStatus = SearchState.NoSearch;
        this.currentResults = []; // results from server (
        this.currentSearchExpression = ""; // no search expression
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.delayedAction = null;

        this.isEnabled = false;
        this.isConfigSearch = false;
    }

    // after a resize fix display...
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateHeights() {
        this.isEnabled && this.updateSearchStatusHeights();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderSearchField(
        searchBox: JQuery,
        enableServerSearch: boolean,
        highlightResults: boolean,
        isConfigSearch: boolean,
        currentFilterContainer: JQuery,
    ) {
        let that = this;

        this.isEnabled = true;
        that.isConfigSearch = isConfigSearch;

        let inputSpace = $('<div class="mrqlSearchInputContainer">');
        let inputCtrl = $(
            '<input  data-cy="mrqlSearchInput" autocomplete="off" type="text" autofocus="autofocus" name="search" placeholder="Search..." class="form-control searchNoX">',
        );
        searchBox.append(inputSpace.append(inputCtrl));

        // clear input field
        inputSpace.append(
            '<span data-cy="searchClear" name="clear" class="fal fa-times-circle filter-clear-x"></spans>',
        );
        $("span[name=clear]", searchBox).click(function (evt) {
            ml.Search.hideHighlight();
            $("input[name=search]", searchBox).val("").focus();
            that.resetSearch();
        }); // icon left of search server button inside the text input field

        // add box for server search
        if (enableServerSearch) {
            let ss = this.getMiracleControl(inputCtrl);
            searchBox.append(ss);
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (ml.LabelTools.getLabelDefinitions().length > 0) {
            // add Global filter
            this.addGlobalFilter(searchBox, currentFilterContainer);
        }

        // handle filter / search input field
        $("input[name=search]", searchBox)
            .keyup(function (e: JQueryEventObject) {
                clearTimeout(that.filterSearch_timeout);
                clearTimeout(that.filterHighlight_timeout);

                // get search string
                let untrimmed = $(e.delegateTarget).val();
                let trimmed = $.trim($(e.delegateTarget).val());

                if (that.setPrefixCategory(untrimmed)) {
                    untrimmed = untrimmed.substr(that.panel.prefixCategory.length + 1);
                    trimmed = trimmed.substr(that.panel.prefixCategory.length + 1);
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (trimmed.length == 0) {
                        // just a prefix has been type, e.g. REQ:
                        return;
                    }
                }

                // user hit return
                if (e && e.keyCode === 13 && !isConfigSearch) {
                    // run fulltext search
                    that.savedSearchIndex = -1;
                    that.search(trimmed, highlightResults);
                    that.addToSavedSearch(trimmed, searchBox);
                    return;
                }
                if (e && e.keyCode === 38) {
                    let savedSearchLength = $(".savedSearch").length;
                    if (savedSearchLength > 0) {
                        that.savedSearchIndex++;
                        let newVal = $(".savedSearch", searchBox)[that.savedSearchIndex % savedSearchLength].innerText;
                        let textField = $(".searchNoX", searchBox);
                        $(document).ready(() => {
                            textField.val(newVal);
                        });
                    }
                }

                if (e && e.keyCode === 40) {
                    let savedSearchLength = $(".savedSearch").length;
                    if (savedSearchLength > 0) {
                        e.preventDefault();
                        that.savedSearchIndex--;
                        if (that.savedSearchIndex < 0) {
                            that.savedSearchIndex = savedSearchLength - 1;
                        }

                        console.log(that.savedSearchIndex);
                        let newVal = $(".savedSearch", searchBox)[that.savedSearchIndex % savedSearchLength].innerText;
                        let textField = $(".searchNoX", searchBox);
                        $(document).ready(() => {
                            textField.val(newVal);
                        });
                    }
                }

                if (
                    e &&
                    e.keyCode === 32 &&
                    trimmed.toLowerCase().indexOf("mrql") === -1 &&
                    app.getItemTitle(trimmed.toUpperCase())
                ) {
                    if (searchBox.closest("#projectTree").length === 1) {
                        that.panel.select(trimmed.toUpperCase());
                        $("input[name=search]", searchBox).val("");
                        ml.Search.hideHighlight();
                        that.resetSearch();
                        return;
                    }
                }
                if ((e && e.which === $.ui.keyCode.ESCAPE) || trimmed === "") {
                    $("input[name=search]", searchBox).val("");
                    that.resetSearch();
                    ml.Search.hideHighlight();
                    that.panel.resizeItem();
                    return;
                }
                // Pass text as filter string (will be matched as substring in the node title)
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (untrimmed.indexOf("mrql:") != 0) {
                    that.filterSearch_timeout = window.setTimeout(
                        function () {
                            that.filter(untrimmed);
                            that.panel.resizeItem();

                            if (highlightResults) {
                                // highlight again (the filtering above removes the highlights from the tree)
                                clearTimeout(that.filterHighlight_timeout);

                                that.filterHighlight_timeout = window.setTimeout(function () {
                                    ml.Search.highlight(trimmed);
                                }, 100);
                            }
                            // instead of hard coded timeouts allow to overwrite per pc
                            // serverStorage.setItem("timeoutSearch1",2000)) less than 2 letters entered. default 3000ms
                            // serverStorage.setItem("timeoutSearch2",4000)) 2+ letters entered . default 3000ms
                        },
                        trimmed && trimmed.length > 2
                            ? Number(globalMatrix.serverStorage.getItemDefault("timeoutSearch2", "600"))
                            : Number(globalMatrix.serverStorage.getItemDefault("timeoutSearch1", "1200")),
                    );

                    if (highlightResults) {
                        // wait a bit and highlight in main page

                        clearTimeout(that.filterHighlight_timeout);
                        that.filterHighlight_timeout = window.setTimeout(function () {
                            ml.Search.highlight(trimmed);
                        }, 300);
                    }
                }
            })
            .focus();

        this.renderSearchMetaInfo(searchBox);

        if (this.panel.settings.selectMode !== 0) {
            $(this.renderSelectionCheckbox("Select all / Unselect all", "selectAll", "all")).insertBefore(searchBox);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderSearchMetaInfo(searchBox: JQuery) {
        // add search status info
        // checkbox to select all
        this.searchMetaInfo = $("<div class='searchMetaInfo'>");
        this.searchMetaInfo.append($('<div class="searchResultInfo">'));

        if (!this.panel.settings.singleSelect) {
            this.searchMetaInfo.append(
                $("<div class='searchResultSelect'>").append(
                    this.renderSelectionCheckbox(
                        "Add to / Remove from current selection",
                        "searchSelectAll",
                        "matching",
                    ),
                ),
            );
        }

        this.searchMetaInfo.append(
            $("<div class='searchResultWaiting'>").append(ml.UI.getSpinningWait("searching...")),
        );
        this.searchMetaInfo.children().hide();
        this.searchMetaInfo.insertAfter(searchBox);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderSelectionCheckbox(label: string, cy: string, type: SearchBoxSelection) {
        return $(`
                <label class="searchBox__checkbox _${type}">
                    <input name="select-${type}" data-cy="${cy}" data-type="${type}" type="checkbox" class="searchBox__checkbox-input">
                    <span class="searchBox__checkbox-label">${label}</span>
                </label>
            `).change((e: JQueryEventObject) => {
            const target = $(e.target);
            this.panel.toggleSelection(target.prop("checked"), target.data("type"));
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateSelectionCheckboxesState() {
        const root = this.panel.getRoot();
        const selectAll = root.find('input[name="select-all"]');
        const selectMatching = root.find('input[name="select-matching"]');
        const fancyTree = this.panel.projectTree.getFancyTree();

        let itemsLength = 0;
        let selectedLength = 0;
        const matchingLength = this.currentResults.length;
        let selectedMatchingLength = 0;

        // doing it in one loop, because of performance considerations, especially during "select all" action.
        // our fancytree doesn't support "selectAll" method, meaning that we have to loop through all the items and
        // select them separately. this results in a lot of "select" events triggered in the short time,
        // which can be a problem.
        fancyTree.visit((node) => {
            // @ts-ignore TODO: find a typed way
            if (!node.unselectable) {
                itemsLength++;
            }

            if (node.isSelected()) {
                selectedLength++;

                if (this.currentResults.includes(node.key)) {
                    selectedMatchingLength++;
                }
            }
        });

        const allChecked = itemsLength === selectedLength;
        const allIndeterminate = !allChecked && selectedLength > 0;

        selectAll.prop("checked", allChecked);
        selectAll.prop("indeterminate", allIndeterminate);

        const matchingChecked = matchingLength > 0 && matchingLength === selectedMatchingLength;
        const matchingIndeterminate = !matchingChecked && selectedMatchingLength > 0;

        selectMatching.prop("checked", matchingChecked);
        selectMatching.prop("indeterminate", matchingIndeterminate);
    }

    private setPrefixCategory(searchExpression: string): boolean {
        let that = this;
        that.panel.prefixCategory = "";
        $.each(globalMatrix.ItemConfig.getCategories(), function (idx, cat) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (searchExpression.toUpperCase().indexOf(cat.toUpperCase() + ":") == 0) {
                that.panel.prefixCategory = cat;
            }
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return that.panel.prefixCategory != "";
    }
    // renders a tree or a list, with or without checkboxes
    // refresh only is set if the tree changes, rather than the search
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render() {
        let that = this;

        if (!this.isEnabled) {
            return;
        }
        if (this.currentStatus === SearchState.NoSearch) {
            this.showSearchStatus(-1, true);

            // render as tree control
            this.panel.listView.hide();
            this.panel.projectTree.show();
            // make sure nothing is hidden or dimmed
            this.panel.projectTree.setHideMismatches(false);
            // render the tree without any filters
            this.panel.projectTree.removeFilter();
        } else if (this.currentStatus === SearchState.ServerRunning) {
            // user might click faster than server can react
            this.delayedAction = {
                type: "render",
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                expression: null,
            };
            return;
        } else if (this.currentStatus === SearchState.FilterDone) {
            // this is a local filtering: do it
            if (this.panel.viewModeSelector.showAsList()) {
                // render as list control
                this.panel.listView.show();
                this.panel.projectTree.hide();
                // show results
                let matches = this.panel.listView.filterList(this.currentSearchExpression);

                that.showMatches(matches, true);
            } else {
                // render as tree control
                this.panel.listView.hide();
                this.panel.projectTree.show();
                // hide or dim
                this.panel.projectTree.setHideMismatches(this.panel.viewModeSelector.hideMismatches());
                // render the tree with current filter
                let matches = this.panel.projectTree.filterTree(this.currentSearchExpression);
                that.showMatches(matches, true);
            }
        } else if (this.currentStatus === SearchState.ServerDone) {
            // this is a local filtering: do it
            if (this.panel.viewModeSelector.showAsList()) {
                // render as list control
                this.panel.listView.show();
                this.panel.projectTree.hide();
                // show results
                let actualResults = this.panel.listView.showSearchResults(this.currentResults);
                that.showMatches(actualResults, false);
            } else {
                // render as tree control
                this.panel.listView.hide();
                this.panel.projectTree.show();
                // hide or dim
                this.panel.projectTree.setHideMismatches(this.panel.viewModeSelector.hideMismatches());
                // render the tree with current filter
                let actualResults = this.panel.projectTree.showSearchResults(this.currentResults);
                that.showMatches(actualResults, false);
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showMatches(matches: ISearchCounts, localSearch: boolean) {
        let diff = matches.total - matches.current;
        let details = "";
        if (diff) {
            details = "There are " + diff + " hits in other tabs:";
            details += "<ul>";
            for (let tab of matches.perTab) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (tab.tabName == NavBar.getCurrentTab()) {
                    details += `<li><span class="" data-tab="${tab.tabName}">${tab.tabName} ${tab.count} matches</span></li>`;
                } else {
                    details += `<li><span class="searchResultTabLink" data-tab="${tab.tabName}">${tab.tabName} ${tab.count} matches</span></li>`;
                }
            }
            details += "</ul>";
        }
        this.showSearchStatus(matches.current, localSearch, details);
        this.updateSelectionCheckboxesState();
    }
    // resets the search
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resetSearch() {
        $(".searchVizMode", this.panel.getRoot()).hide();
        this.panel.viewModeSelector.setEnabled(false);
        this.currentResults = []; // No results
        this.currentSearchExpression = "";
        // always render as tree:
        this.currentStatus = SearchState.NoSearch;
        this.render();
    }

    private getMiracleControl(textField: JQuery): JQuery {
        let that = this;

        let btn_grp = $('<div class="rowFlex" style="">');

        let mir_btn = $(
            '<span class="mrqlSearchIcon" data-toggle="tooltip" data-placement="bottom" title="Fulltext search on server"><i style="" class="fal fa-search"></span>',
        );
        mir_btn.appendTo($(".mrqlSearchInputContainer"));
        btn_grp.append(
            '<span class="btn btn-ssearch" data-cy="searchBoxMrqlDP"  type="button" data-toggle="dropdown"><span class="fal fa-chevron-down"></span></span>',
        );

        let config = globalMatrix.ItemConfig.getSearchConfig();
        if (!config || !config.searches || config.searches.length === 0) {
            config = {
                searches: [
                    { name: "outdated links", expr: "mrql:outdated=1" },
                    { name: "last week", expr: "mrql:updated<1w" },
                    { name: "failed tests", expr: 'mrql:"test run result"~"error"' },
                ],
            };
        } else {
            // sort
            $.each(config.searches, function (idx, st) {
                st.name = st.name.split("'").join('"');
                st.expr = st.expr.split("'").join('"');
            });
        }

        let ul = $(' <ul id="savedSearches" class="dropdown-menu dropdown-menu-sub pull-right role="menu"> ');

        for (let idx = 0; idx < config.searches.length; idx++) {
            let li = $(
                '<li data-cy="serverSearchExp" title="' +
                    config.searches[idx].expr +
                    '"><a href="javascript:void(0)">' +
                    config.searches[idx].name +
                    "</a></li>",
            );
            ul.append(li);
            li.click(function (event: JQueryEventObject) {
                if (that.panel.settings.highlight) {
                    // wait a bit and highlight
                    window.setTimeout(function () {
                        ml.Search.highlight($(event.delegateTarget).data("mrql"));
                    }, 50);
                }
                textField.val($(event.delegateTarget).data("mrql"));
                that.search($(event.delegateTarget).data("mrql"));
            }).data("mrql", config.searches[idx].expr);
        }
        let help = $(
            '<li class="miracleHelp" title="Miracle Help" ><a class="documentationLink" href="javascript:void(0)">Miracle Help</a></li>',
        );
        ul.append(help);
        help.click(function () {
            window.open("https://urlshort.matrixreq.com/d25/manual/miracle");
        });

        let items = that.getSavedSearches();
        items.forEach((item) => {
            item = decodeURIComponent(item);
            let li = $("<li/>")
                .attr("title", item)
                .append($('<a class="savedSearch" href="javascript:void(0)"/>').text(item));
            ul.append(li);
            li.click(function (event: JQueryEventObject) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                textField.val(event.currentTarget.textContent);
                let e = $.Event("keyup");
                e.keyCode = 13; // Enter key
                textField.trigger(e);
            }).data("mrql", item);
        });

        let pluginsSearchInjection = plugins.getCustomSearches();
        if (pluginsSearchInjection.length > 0) {
            ul.append($('<li class="divider"></li>'));
            for (let ps of pluginsSearchInjection) {
                let li = $(
                    '<li data-cy="serverSearchExp" title="' +
                        ps.menu +
                        '"><a href="javascript:void(0)">' +
                        ps.menu +
                        "</a></li>",
                );
                ul.append(li);
                li.click(async (event: JQueryEventObject) => {
                    this.currentStatus = SearchState.ServerRunning;
                    this.render();
                    await ps.search((itemIds: string[]) => {
                        that.panel.tree.fancytree("getTree").visit(function (node: Fancytree.FancytreeNode) {
                            // @ts-ignore TODO: investigate what "this" should refer to
                            this.currentStatus = SearchState.ServerDone;
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (itemIds.indexOf(node.key) != -1) {
                                that.currentResults.push(node.key);
                            }
                            that.render();
                        });
                    });
                });
            }
        }

        ul.css("max-width", $("#sidebar").width() - 10 + "px");

        btn_grp.append(ul);

        $("i", mir_btn).click(() => {
            that.doSearch(textField);
        });

        return btn_grp;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    doSearch(textField: JQuery) {
        let that = this;
        let searchExpression = textField.val();
        if (that.setPrefixCategory(searchExpression)) {
            searchExpression = searchExpression.substr(that.panel.prefixCategory.length + 1);
        }
        that.search(searchExpression);
        that.addToSavedSearch(searchExpression, textField.parent().parent());
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addToSavedSearch(str: string, filter: JQuery) {
        let that = this;
        let items = that.getSavedSearches();
        str = str.trim();
        let strEncoded = encodeURIComponent(str);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (str.length == 0) {
            return;
        }
        let strIndex = items.indexOf(strEncoded);
        let decodedStr = decodeURIComponent(str);

        let def = $.Deferred();

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (strIndex != -1) {
            items.splice(strIndex, 1);
            $().ready(() => {
                $("li[title='" + decodedStr + "']", filter).remove();
            });
        }

        items.unshift(strEncoded);
        items.splice(10);
        globalMatrix.projectStorage.setItem("savedSearches", JSON.stringify(items), false);
        $().ready(() => {
            let li = $("<li/>")
                .attr("title", decodedStr)
                .append(
                    $('<a class="savedSearch" data-cy="localSearchExp" href="javascript:void(0)"/>').text(decodedStr),
                );
            li.insertAfter($(".miracleHelp", filter));
            li.click(function (event: JQueryEventObject) {
                let textField = $(".searchNoX", filter);
                textField.val(decodeURIComponent(str));
                textField.val($(event.delegateTarget).data("mrql"));
                let e = $.Event("keyup");
                e.keyCode = 13; // Enter key
                textField.trigger(e);
            }).data("mrql", decodedStr);
        });
    }
    getSavedSearches(): string[] {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let items: string[] = undefined;
        if (globalMatrix.projectStorage) {
            let itemsString = globalMatrix.projectStorage.getItem("savedSearches", true);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (itemsString && itemsString != "") {
                items = JSON.parse(itemsString);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (items != undefined) {
                    return items;
                }
            }
        }

        return items ?? [];
    }

    // runs a local search
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private filterAgainExpression: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private filter(filterExpression: string) {
        this.filterAgainExpression = filterExpression;
        this.filterAgain();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private filterAgain() {
        if (this.currentStatus === SearchState.ServerRunning) {
            // user might click faster than server can react
            this.delayedAction = {
                type: "filter",
                expression: this.filterAgainExpression,
            };
            return;
        }

        if (this.filterAgainExpression === "") {
            this.resetSearch();
            return;
        }
        this.panel.viewModeSelector.setEnabled(true);

        this.currentStatus = SearchState.FilterDone;
        this.currentSearchExpression = this.filterAgainExpression;
        this.render();
    }

    // runs a server search
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private search(searchExpression: string, highlightResults?: boolean) {
        let that = this;
        $(".searchVizMode", this.panel.getRoot()).show();
        searchExpression = searchExpression.replace(/_me_/g, matrixSession.getUser());
        searchExpression = searchExpression.replace(/_this_/g, app.getCurrentItemId());

        if (this.currentStatus === SearchState.ServerRunning) {
            // user might click faster than server can react
            this.delayedAction = {
                type: "server",
                expression: searchExpression,
            };
            return;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.delayedAction = null;

        this.panel.viewModeSelector.setEnabled(true);

        if (searchExpression === "") {
            this.resetSearch();
            return;
        }
        this.currentResults = [];
        this.currentStatus = SearchState.ServerRunning;

        this.showSearchStatus(-3, true);

        app.searchAsyncMinimalOutput(
            searchExpression
                .replace("descendant=*", "descendant=" + app.getCurrentItemId())
                .replace("ancestor=*", "ancestor=" + app.getCurrentItemId())
                .replace("parent=*", "parent=" + app.getCurrentItemId())
                .replace("child=*", "child=" + app.getCurrentItemId()),
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            null,
            false,
            this.panel.settings.crossProject,
        )
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (results: string[]) {
                if (that.panel.prefixCategory) {
                    results = results.filter(function (result) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return ml.Item.parseRef(result).type == that.panel.prefixCategory;
                    });
                }

                /*
            this is potentially n2 if the results is the whole tree ....

            for (var idx = 0; idx < results.length; idx++) {
                // verify that the tree is not filtered otherwise
                var node = that.panel.tree.fancytree("getTree").getNodeByKey(results[idx]);
                if (node) {
                    // if not add it to results
                    that.currentResults.push(results[idx]);
                }
            }
            */

                // that's the same but indexOf is much faster
                that.panel.tree.fancytree("getTree").visit(function (node: Fancytree.FancytreeNode) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (results.indexOf(node.key) != -1) {
                        that.currentResults.push(node.key);
                    }
                });

                that.showSearchStatus(that.currentResults.length, false);
                // search done
                that.currentStatus = SearchState.ServerDone;
                if (that.delayedAction) {
                    that.doDelayed();
                    return;
                }
                let filtered = that.render();

                if (highlightResults) {
                    // highlight again (the filtering above removes the highlights from the tree)
                    window.clearTimeout(that.filterHighlight_timeout);
                    that.filterHighlight_timeout = window.setTimeout(function () {
                        ml.Search.highlight(searchExpression);
                    }, 100);
                }
            })
            .fail(function (jqxhr, textStatus, error) {
                if (jqxhr) {
                    let errorText = "unknown error";
                    if (jqxhr && jqxhr.responseJSON) {
                        if (jqxhr.responseJSON.detailsList && jqxhr.responseJSON.detailsList.length > 0) {
                            errorText = jqxhr.responseJSON.detailsList[0];
                        }
                        if (jqxhr.responseJSON.displayError) {
                            errorText = jqxhr.responseJSON.displayError;
                        }
                        ml.UI.showError("Search failed", errorText);
                    }
                    // error in search
                    that.showSearchStatus(
                        -2,
                        false,
                        errorText.replace("[EClient/MRQL]|", "").replace("|FindItemsMethod", ""),
                    );
                    that.currentStatus = SearchState.ServerDone;
                    that.doDelayed(); // just in case
                } else {
                    // no search performed (e.g. empty string)
                    that.showSearchStatus(-1, false);
                    that.currentStatus = SearchState.NoSearch;
                    that.doDelayed(); // just in case
                }
            });
    }

    // delayed actions might happen if user types or uses UI while a server
    // request is going on. in that case the (last) delayed action is done after
    // server returns
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private doDelayed() {
        if (!this.delayedAction) {
            return;
        }
        if (this.delayedAction.type === "server") {
            this.search(this.delayedAction.expression);
        } else if (this.delayedAction.type === "filter") {
            this.filter(this.delayedAction.expression);
        } else if (this.delayedAction.type === "render") {
            this.render();
        }
    }

    // status:
    // -1 no search running
    // -2 error (details contains message)
    // -3 server search running
    // 0 no matching results
    // n>0 n matching results
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showSearchStatus(status: number, local: boolean, details?: string) {
        let that = this;
        let searchResultInfo = $(".searchResultInfo", this.panel.getRoot());

        clearTimeout(this.spinnerServerSearch);
        $(".searchResultWaiting", this.panel.getRoot()).hide();
        $(".searchResultSelect", this.panel.getRoot()).hide();

        let searchHint = "";
        if (ml.LabelTools.getFilter()) {
            let filterNames = ml.LabelTools.getFilter()
                .split(",")
                .map(function (f) {
                    return ml.LabelTools.getFilterName(f);
                })
                .join(",");
            searchHint +=
                " <span style='color:" +
                UIToolsConstants.CIColors.BrownDiSerria.color +
                "'>Results filtered by label(s): '" +
                filterNames +
                "'</span>.";
        }
        if (local && !that.isConfigSearch) {
            searchHint += " Click <a class='performFullTextSearch'>here</a> to run fulltext search.";
        }

        if (status === -3) {
            this.spinnerServerSearch = window.setTimeout(function () {
                $(".searchResultWaiting", that.searchMetaInfo).show();
                that.updateSearchStatusHeights();
            }, 200);
        } else if (status === -2) {
            searchResultInfo.html("error in search expression: " + details);
            searchResultInfo.show();
        } else if (status === -1) {
            searchResultInfo.html("");
            searchResultInfo.hide();
            $(".searchVizMode", this.panel.getRoot()).hide();
        } else if (status === 0) {
            searchResultInfo.html(
                "<span style='color:grey;width:100%;padding-top:20px'>No matching items found." +
                    searchHint +
                    (details ? details : "") +
                    "</span>",
            );
            searchResultInfo.show();
            $(".searchVizMode", this.panel.getRoot()).show();
        } else {
            searchResultInfo.html(status + " matching items found." + (details ? details : "") + searchHint);
            searchResultInfo.show();
            $(".searchVizMode", this.panel.getRoot()).show();
            if (this.panel.settings.canSelectItems) {
                $(".searchResultSelect", this.panel.getRoot()).show();
            }
        }

        $(".performFullTextSearch", searchResultInfo).on("click", () => {
            that.doSearch($(".searchNoX", searchResultInfo.parentsUntil(".treeCtrl").parent()));
        });
        $(".searchResultTabLink", searchResultInfo).click((event) => {
            let link = $(event.delegateTarget);
            let tab = link.data("tab");
            NavBar.switchTab(tab);
            that.render();
        });

        this.updateSearchStatusHeights();
    }

    // recompute position of tree/list control
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private updateSearchStatusHeights() {
        let top = 44;
        $.each(this.searchMetaInfo.children(), function (idx, div) {
            if ($(div).is(":visible")) {
                top += $(div).height();
            }
        });

        this.panel.updateTopPosition(top);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addGlobalFilter(filter: JQuery, currentFilterContainer: JQuery) {
        if (!globalMatrix.ItemConfig.getTimeWarp()) {
            let filterButton = $(
                `<span class="btn btn-ssearch searchBoxFilter" data-cy="searchBoxFilter" type="button" ><span class="fal fa-filter"></span></span>`,
            );
            filter.prepend(filterButton);

            let dialog = $("#filterDialog");
            // Do not add the dialog if allready exists
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (dialog.length == 0) {
                // show and apply project filters

                let dialog = $(
                    '<div class="modal " id="filterDialog" tabindex="-1" role="dialog" aria-labelledby="filterDialogLabel" aria-hidden="true">',
                );
                let dialogContent = $('<div class="modal-dialog modal-lg" role="document">');
                let dialogContentInner = $('<div class="modal-content">');
                let dialogHeader = $('<div class="modal-header">');
                let dialogBody = $('<div class="modal-body">');
                let dialogFooter = $('<div class="modal-footer">');
                let dialogTitle = $('<h4 class="modal-title" id="filterDialogLabel">Filters</h4>');
                let dialogClose = $(
                    '<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>',
                );

                dialogHeader.append(dialogClose);
                dialogHeader.append(dialogTitle);
                dialogFooter.append(dialogClose);
                dialogContentInner.append(dialogHeader);
                dialogContentInner.append(dialogBody);
                dialogContentInner.append(dialogFooter);
                dialogContent.append(dialogContentInner);
                dialog.append(dialogContent);
                $("body").append(dialog);

                dialogClose.click(function () {
                    dialog.modal("hide");
                });
                dialogClose.click(function () {
                    dialog.modal("hide");
                });
                $("<div  id='globalProjectFilter'></div>").html("").appendTo(dialogBody);
                $("<div  id='globalProjectExplanation' ></div>")
                    .html(
                        `You can single click a label to match items with the label set,
                    and double click the label to match items which do not have the
                    label set. If you select multiple labels, only the items with all
                    selected labels are matched in the tree.`,
                    )
                    .appendTo(dialogBody);
                let labelFilter = ml.LabelTools.getFilter();
                if (labelFilter && labelFilter.length > 0 && ml.LabelTools.getFilterColor()) {
                    $("header").css("background-color", ml.LabelTools.getFilterColor());
                } else {
                    $("header").css("background-color", "");
                }

                // show the filters -> if someone uses them, reload  the project with the filter
                new LabelSwitches(
                    $("#globalProjectFilter"),
                    true,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    null,
                    labelFilter && labelFilter.length > 0 ? labelFilter.split(",") : [],
                    "project_filter",
                    function (newSelection: string[]) {
                        app.canNavigateAwayAsync()
                            .done(function () {
                                ml.LabelTools.setFilter(newSelection);

                                const project = matrixSession.getProject();

                                if (!project) {
                                    return;
                                }

                                app.loadProject(project, app.getCurrentItemId()).then(() => {
                                    if (currentFilterContainer.length > 0) {
                                        $(".itemFilterTool").show();
                                        new LabelSwitches(
                                            $("#currentFilterContainer"),
                                            false,
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            null,
                                            newSelection,
                                            "filter_chip",
                                            (sel) => {},
                                        );
                                    } else {
                                        $(".itemFilterTool").hide();
                                    }

                                    ml.UI.toggleFilters(true);
                                });
                                // this code shouldn't be reachable, but let's leave it just in case
                            })
                            .fail(function () {
                                ml.UI.showError(
                                    "Filter was not activated.",
                                    "To activate reload project after saving the item!",
                                );
                            });
                    },
                );
            }

            filterButton.click(() => {
                dialog.modal("show");
            });
        } else {
            // no filters
            $("#projectTree").addClass("timewarp");
        }
    }
}
