import { mDHF, DocumentSectionType, ColumnTypesInfo } from "../../../common/businesslogic";
import { IDHFConfig } from "../../../ProjectSettings";
import { Checkbox } from "./Checkbox";
import { CustomSection } from "./DoubleItemSelection/CustomSection";
import { DerivedFrom } from "./DoubleItemSelection/DerivedFrom";
import { DesignReviews } from "./SingleItemsSelection/DesignReviews";
import { DhfTable } from "./DHFTable";
import { DocumentOptions } from "./DocumentOptions";
import { DueDate } from "./DueDate";
import { ItemIndex } from "./ItemIndex";
import { ItemList } from "./SingleItemsSelection/ItemList";
import { Items } from "./SingleItemsSelection/Items";
import { ItemTable } from "./SingleItemsSelection/ItemTable";
import { LinkList } from "./SingleItemsSelection/LinkList";
import { ListOfFigures } from "./ListOfFiguresTables";
import { MultiSelect } from "./MultiSelect";
import { Remarks } from "./Remarks";
import { RichText } from "./RichText";
import { RiskStats } from "./SingleItemsSelection/RiskStats";
import { SmartText } from "./SmartText";
import { TableOfContent } from "./TableOfContent";
import { TestResults } from "./DoubleItemSelection/TestResults";
import { TextLine } from "./TextLine";
import { TraceMatrix } from "./DoubleItemSelection/TraceMatrix";
import { Traces } from "./Traces";
import { SectionDescriptions } from "../../../common/businesslogic/FieldHandlers/Document/SectionDescriptions";
import { Dashboard } from "./Dashboard";

export { InitializeDocumentSections };

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function addSection(
    type: DocumentSectionType,
    dynamic: boolean,
    sectionId: string,
    sectionName: string,
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    creator: any,
    hidden?: boolean,
) {
    mDHF.registerSection2(type, dynamic, sectionId, sectionName, creator, hidden);
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function addTable(dynamic: boolean, sectionId: string, sectionName: string, creator: any, hidden?: boolean) {
    addSection(DocumentSectionType.Table, dynamic, sectionId, sectionName, creator, hidden);
}
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function addDatabase(dynamic: boolean, sectionId: string, sectionName: string, creator: any, hidden?: boolean) {
    addSection(DocumentSectionType.Database, dynamic, sectionId, sectionName, creator, hidden);
}
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function addStatic(dynamic: boolean, sectionId: string, sectionName: string, creator: any, hidden?: boolean) {
    addSection(DocumentSectionType.Static, dynamic, sectionId, sectionName, creator, hidden);
}
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function addTechnical(dynamic: boolean, sectionId: string, sectionName: string, creator: any, hidden?: boolean) {
    addSection(DocumentSectionType.Technical, dynamic, sectionId, sectionName, creator, hidden);
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeDocumentSections() {
    addStatic(false, SectionDescriptions.section_checkbox, "Checkbox", () => new Checkbox());

    addDatabase(true, SectionDescriptions.section_CustomSection, "Custom", () => new CustomSection());
    addDatabase(true, SectionDescriptions.section_derivedfrom, "Items Derived From", () => new DerivedFrom());
    addDatabase(true, SectionDescriptions.section_designreviews, "Design Review Labels", () => new DesignReviews());

    addDatabase(true, SectionDescriptions.section_dashboard, "Dashboard", () => new Dashboard(), true);

    addStatic(
        false,
        SectionDescriptions.section_audittrail,
        "Audit Trail",
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "audittrail", dhfType, columnTypes),
    );
    addStatic(
        false,
        SectionDescriptions.section_signaturebox,
        "Signature Box",
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "signaturebox", "signatures", columnTypes),
    );
    addStatic(
        false,
        SectionDescriptions.section_templateapproval,
        "Template Approval",
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "templateapproval", "signatures", columnTypes),
        true,
    );
    addStatic(
        false,
        SectionDescriptions.section_signatures,
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        null,
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "signatures", "signatures", columnTypes),
    );
    addStatic(
        false,
        SectionDescriptions.section_responsibilities,
        "Responsibilities",
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "responsibilities", dhfType, columnTypes),
    );
    addStatic(
        false,
        SectionDescriptions.section_genericTable,
        "Custom Table",
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "genericTable", dhfType, columnTypes),
    );

    addStatic(
        false,
        SectionDescriptions.section_dhf_table,
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        null,
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) =>
            new DhfTable(config, "dhf_table", dhfType, columnTypes),
    );
    addTechnical(
        false,
        SectionDescriptions.section_document_options,
        "Document Options",
        (config: IDHFConfig, dhfType: string, columnTypes: ColumnTypesInfo) => new DocumentOptions(),
    );
    addStatic(false, SectionDescriptions.section_duedate, "Date", () => new DueDate());

    addTable(true, SectionDescriptions.section_item_index, "Index of Item References", () => new ItemIndex());
    addDatabase(true, SectionDescriptions.section_itemlist, "List of Item References", () => new ItemList(false));
    addDatabase(true, SectionDescriptions.section_package, "Packaged SIGN Items", () => new ItemList(true));
    addDatabase(true, SectionDescriptions.section_items, "List of Items", () => new Items());
    addDatabase(true, SectionDescriptions.section_itemtable, "Table of items with fields", () => new ItemTable());
    addDatabase(true, SectionDescriptions.section_linklist, "List of External References", () => new LinkList());
    addTable(
        false,
        SectionDescriptions.section_figures_tables,
        "List of Figures & Tables",
        () => new ListOfFigures(),
        true,
    );
    addStatic(false, SectionDescriptions.section_multiselect, "(Multi-) Select", () => new MultiSelect());
    addStatic(false, SectionDescriptions.section_remarks, "Remarks / Spacer", () => new Remarks());
    addStatic(false, SectionDescriptions.section_richtext, "Text Block", () => new RichText());
    addDatabase(true, SectionDescriptions.section_riskstats, "Risk Analysis", () => new RiskStats());
    addTable(false, SectionDescriptions.section_terms_abbreviations, "Terms and Abbreviations", () => new SmartText());
    addTable(true, SectionDescriptions.section_table_of_content, "Table of Contents", () => new TableOfContent());
    addDatabase(true, SectionDescriptions.section_testresults, "Test Results", () => new TestResults());
    addStatic(false, SectionDescriptions.section_textline, "Text Line", () => new TextLine());
    addDatabase(true, SectionDescriptions.section_tracematrix, "Trace Matrix", () => new TraceMatrix());
    addDatabase(true, SectionDescriptions.section_downtraces, "Down Traces", () => new Traces(false));
    addDatabase(true, SectionDescriptions.section_uptraces, "Up Traces", () => new Traces(true));
}
