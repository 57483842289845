import { ControlState, IReference, IItemGet, globalMatrix, app } from "../../../globals";
import { ILinkType } from "../Components/ItemForm";
import { SelectMode } from "../Components/ProjectViewDefines";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { ItemCreationTools, ICreateDialogButtonOptions } from "../Tools/ItemCreationView";
import { ItemSelectionTools, IItemSelectButtonOptions } from "../Tools/ItemSelectionView";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { DATA_HTMLDIFF_ID } from "../../../../sdk/utils/differs/diffHtml";

export type { IUpLinkInfoOptions, IUpLinkDetails };
export { UpLinkInfoImpl };

interface IUpLinkInfoOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fieldValue?: string | {};
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: IUpLinkDetails;
    validate?: boolean;
}

interface IUpLinkDetails {
    /** a comma separated list of categories */
    cats: string; //CAT1,CAT2. Comma separated list of categories to which this rule applies
    /** it is possible to show a specific text with some icons if there are (set to true)/ are no links (set to false) */
    exists?: boolean; // true/false. If true, the information is shown if a link exists. If false, if the link does not exist
    /** depends on exist:  shows an icon if there's at least one (or none)  */
    icon?: string; // "fal fa-clock" an icon to show (if rule applies). Must be font-awesome icon
    /** depends on exist:  color of icon  */
    iconfg?: string; // foreground color
    /** depends on exist:  background color of icon  */
    iconbg?: string; // background color
    /** depends on exist:  text behind icon  */
    text?: string; // text to be displayed
    /** to show information about the actualy uplinks: false (don't show)|true (shows a simple list with all id's)|listref (shows a list with id's as links and titles)|ref (shows id's as links and titles inline behind text) */
    itemInfo?: string | boolean; // false/true/listref/ref. Makes only sense if rule is exists = true. Shows links (unless false)

    /** show create button */
    showCreate?: boolean; // show create buttons
    /**  a comma separated list of categories for which to hide the create button (subset of cats) */
    hideCreate?: string;
    /**  a comma separated list of categories for which to hide in selection (subset of cats) */
    hideSelect?: string;

    reports?: boolean; // whether field should shop up in reports
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.uplinkinfo = function (this: JQuery, options: IUpLinkInfoOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_uplinkinfo,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new UpLinkInfoImpl(this, options.fieldHandler as EmptyFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class UpLinkInfoImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IUpLinkInfoOptions;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IUpLinkInfoOptions) {
        let that = this;

        let defaultOptions: IUpLinkInfoOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                cats: "", // comma separated list of categories
                reports: false, // boolean whether the field should appear in reports
                exists: true, // true if the message shall be displayed if any of the cats exists
                icon: "", // can be set to display an icon (name of favicon)
                iconbg: "", // can be set to change background color
                iconfg: "", // can be set to change foreground color
                text: "", // a text which should be written
                itemInfo: false, // true if hyperlinks shall be written
            },
        };
        this.settings = <IUpLinkInfoOptions>ml.JSON.mergeOptions(defaultOptions, options);
        this.renderRefs(<IReference[]>this.settings.fieldValue);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refreshLinks() {
        this.renderRefs(<IReference[]>this.settings.fieldValue);
    }
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return false;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        return "";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateItem(newItem: IItemGet) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.renderRefs(newItem.upLinks);
    }

    private renderRefs(links: IReference[]): void {
        let that = this;

        this._root.html("");
        let cats = this.settings.parameter?.cats.split(",") || [];

        this._root.append(super.createHelp(this.settings));
        // check if exists rule holds...
        let hasLink = false;
        $.each(links, function (idx, link) {
            hasLink = hasLink || cats.indexOf(ml.Item.parseRef(link.to).type) !== -1;
        });
        // check if condition for info holds
        if (this.settings.parameter?.exists === hasLink) {
            let fg = this.settings.parameter.iconfg ? ";color:" + this.settings.parameter.iconfg : "";
            let bg = this.settings.parameter.iconbg ? ";background:" + this.settings.parameter.iconbg : "";
            if (this.settings.parameter.icon) {
                this._root.append(
                    '<i  style="' + fg + bg + '" class="uplinkIcon fal ' + this.settings.parameter.icon + '">',
                );
            }
            if (this.settings.parameter.text) {
                this._root.append($("<span>").html(this.settings.parameter.text));
            }
            if (this.settings.parameter.itemInfo) {
                let ul = this.settings.parameter.itemInfo === "ref" ? $("<span>") : $("<ul>");
                let spacer = $("<span> </span>");
                $.each(links, function (idx, link) {
                    if (cats.indexOf(ml.Item.parseRef(link.to).type) !== -1) {
                        switch (that.settings.parameter?.itemInfo + "") {
                            case "true":
                                ul.append(
                                    $(`<li ${DATA_HTMLDIFF_ID}="${link.to}">`).append(
                                        $("<span class='highlight-diff'>").html(link.to),
                                    ),
                                );
                                break;
                            case "reflist":
                            case "reflistedit": {
                                let referenceLI = $(`<li ${DATA_HTMLDIFF_ID}="${link.to}">`).append(
                                    $("<span class='highlight-diff'>").refLink({
                                        folder: false, // show id if it exists
                                        id: link.to,
                                        title: app.getItemTitle(link.to),
                                        style: that.settings.isHistory ? refLinkStyle.link : refLinkStyle.selectTree,
                                        isHidden: app.isHiddenLink(link.to),
                                        tooltip: refLinkTooltip.html,
                                        callback: (id: string, newText: string) => {},
                                        validate: that.settings.validate,
                                    }),
                                );
                                ul.append(referenceLI);
                                if (that.settings.parameter?.itemInfo + "" === "reflistedit" && that.settings.canEdit) {
                                    let button = $(
                                        "<span title data-original-title='Remove link' class='btn-deleteRef hidden-print'> <i class='fal fa-unlink'></i></span>",
                                    );

                                    button.data("to", link.to).click(function (elem: JQueryEventObject) {
                                        let whatToDelete = $(elem.delegateTarget).data("to");
                                        ml.UI.showConfirm(
                                            2,
                                            { title: "Remove link from '" + whatToDelete + "'?", ok: "Remove" },
                                            function () {
                                                if (that.settings.id) {
                                                    app.removeDownLinkAsync(whatToDelete, that.settings.id).done(
                                                        function () {
                                                            // remove from list and call self to render again
                                                            let del = -1;
                                                            $.each(links, function (lidx2, li2) {
                                                                if (li2.to === whatToDelete) {
                                                                    del = lidx2;
                                                                    return;
                                                                }
                                                            });
                                                            links.splice(del, 1);
                                                            that.renderRefs(links);
                                                        },
                                                    );
                                                }
                                            },
                                            () => {},
                                        );
                                    });
                                    button.tooltip();
                                    referenceLI.append(button);
                                }
                                break;
                            }
                            case "ref":
                                ul.append(spacer);
                                ul.append(
                                    // using "display: inline;" to improve html diffing
                                    $(`<p style="display: inline;" ${DATA_HTMLDIFF_ID}="${link.to}">`).refLink({
                                        folder: false, // show id if it exists
                                        id: link.to,
                                        title: app.getItemTitle(link.to),
                                        style: refLinkStyle.show,
                                        isHidden: app.isHiddenLink(link.to),
                                        tooltip: refLinkTooltip.html,
                                        callback: function (id: string, newText: string) {
                                            app.treeSelectionChangeAsync(id);
                                        },
                                        validate: that.settings.validate,
                                    }),
                                );
                                spacer = $("<span>, </span>");
                                break;
                        }
                    }
                });
                this._root.append(ul);
            }
        }

        if (this.settings.parameter?.showCreate && this.settings.isForm && this.settings.canEdit) {
            this.renderCreateButtons(links);
        }
        // @ts-ignore TODO: I don't really understand how can we get to the "links.length === 0" part.
        //  leaving it as is just in case
        if (this.settings.controlState === ControlState.DialogCreate && (links || links.length === 0)) {
            //Hide the root as no links in there.
            this._root.hide();
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderCreateButtons(links: IReference[]) {
        // get the possible uplinks -> don't allow risks
        let actualCreateTypes: ILinkType[] = [];
        let actualSelectTypes: ILinkType[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let hideCreate = this.settings.parameter.hideCreate ? this.settings.parameter.hideCreate.split(",") : [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let hideSelect = this.settings.parameter.hideSelect ? this.settings.parameter.hideSelect.split(",") : [];

        let riskCategories = globalMatrix.ItemConfig.getFieldsOfType("risk2").map(function (rc) {
            return rc.category;
        });
        $.each(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            globalMatrix.ItemConfig.getLinkTypes(this.settings.type, false, false).concat(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                globalMatrix.ItemConfig.getLinkTypes(this.settings.type, false, true),
            ),
            function (idx, type) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (riskCategories.indexOf(type) == -1) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (hideCreate.indexOf(type) == -1) {
                        actualCreateTypes.push({
                            type: type,
                            name: globalMatrix.ItemConfig.getCategoryLabel(type),
                            required: false,
                        });
                    }
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (hideSelect.indexOf(type) == -1) {
                        actualSelectTypes.push({
                            type: type,
                            name: globalMatrix.ItemConfig.getCategoryLabel(type),
                            required: false,
                        });
                    }
                }
            },
        );

        // create the create / select buttons
        let create = $("<div style='margin-bottom: 12px;'>").appendTo(this._root);
        let createTools = new ItemCreationTools();
        let options: ICreateDialogButtonOptions = {
            control: create,
            linkTypes: actualCreateTypes,
            created: async (newRef: IReference) => this.addReference(links, newRef),
            isRiskControl: false,
            dontOpenNewItem: false,
        };

        createTools.renderButtons(options);

        if (actualSelectTypes.length) {
            let selectTools = new ItemSelectionTools();
            let selectOptions: IItemSelectButtonOptions = {
                control: create,
                linkTypes: actualSelectTypes,
                selectionChange: (newSelection: IReference[]) => this.selectionChange(links, newSelection),
                getSelectedItems: async () => {
                    return links;
                },
                isRiskControl: !!this.settings.mitigationRenderer,
                selectMode: SelectMode.items,
            };

            selectTools.renderButtons(selectOptions);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addReference(links: IReference[], newRef: IReference) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.addDownLinkAsync(newRef.to, this.settings.id).done(function () {
            links.push(newRef);

            that.renderRefs(links);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private selectionChange(links: IReference[], newSelection: IReference[]) {
        let that = this;

        let oldSelection: IReference[] = ml.JSON.clone(links);

        // copy old links including mitigation details
        let riskCategories = globalMatrix.ItemConfig.getFieldsOfType("risk2").map(function (rc) {
            return rc.category;
        });
        oldSelection.forEach((link) => {
            let cat = ml.Item.parseRef(link.to).type;
            // If it's a risk and not in the new selection we add it.
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (riskCategories.indexOf(cat) != -1 && newSelection.findIndex((l) => l.to === link.to) == -1) {
                newSelection.push(link);
            }
            // If it's in hideSelect
            else if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.settings.parameter.hideSelect != undefined &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.settings.parameter.hideSelect.split(",").indexOf(cat) != -1
            ) {
                newSelection.push(link);
            }
            // If it's not in the displayed CAT
            else if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.settings.parameter.cats != undefined &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.settings.parameter.cats.split(",").indexOf(cat) == -1
            ) {
                newSelection.push(link);
            }
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let changes = ml.Item.updateReferences(oldSelection, newSelection, null, this.settings.id);
        // save new selection to immediately update UI

        // commit new selection to UI
        app.commitChangeListAsync(changes).always(function (error, stepsDone) {
            if (error) {
                ml.UI.showError(error, "cancelled operation");
                return;
            }

            that.renderRefs(newSelection);
        });
    }
}
