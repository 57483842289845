import { app, globalMatrix, matrixSession, restConnection } from "../../../globals";
import { IAutoFillSetting, IDropdownOption } from "../../../ProjectSettings";
import {
    XRSettingType,
    XRGetUser_AllUsers_GetUserListAck,
    XRGetProject_ProjectInfo_ProjectInfo,
    XRGetProject_ProjectAccess_GetAccessAck,
} from "../../../RestResult";
import { IFileUploadProgress, IFileUploadResult, IJcxhr } from "../../businesslogic/RestConnector";
import { ml } from "../../matrixlib";
import { Email } from "./EmailValidator";
import { StrongPass } from "./PasswordValidator";
import { User } from "./UserNameValidator";

export type { UserEditMode, IUserCreate, IUserGet, ITokenConfig, IEmailNotificationSetting };
export { UserControl, userControls };

type UserEditMode = "create" | "useredit" | "adminedit";

interface IUserCreate {
    pw1?: string;
    pw2?: string;
    pw3?: string;
    password?: string;
    admin?: number;
    login?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    signatureImage?: number;
    customerAdmin?: number;
    signaturePassword?: string;
    userSettingsList?: XRSettingType[];
}
interface IUserGet {
    pw1?: string;
    pw2?: string;
    pw3?: string;
    password?: string;
    admin?: number;
    login?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    signatureImage?: string;
    customerAdmin?: number;
    signaturePassword?: string;
}

interface ITokenConfig {
    enabled: boolean;
    users: string[];
}

interface IEmailNotificationSetting {
    periodicity: "" | "weekly" | "daily";
}
class UserControl {
    static TOKEN_CONFIG = "settingsToken";
    static EMAILNOTIF_CONFIG = "emailNotificationReminders";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor() {}

    // mode
    // create (always an admin)
    // adminedit (an admin editing in admin client)
    // useredit (a user through menu)

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editUserDetails(mode: UserEditMode, userId?: string, userAddedCb?: Function, noUserAddedCb?: Function) {
        let that = this;
        restConnection
            .getServer("user")
            .done(async (result) => {
                const allUsers = result as XRGetUser_AllUsers_GetUserListAck;
                let userDetails: IUserCreate;
                if (userId) {
                    for (let idx = 0; idx < allUsers.user.length; idx++) {
                        if (allUsers.user[idx].login.toLowerCase() === userId.toLowerCase()) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            userDetails = <IUserCreate>(<any>allUsers.user[idx]);
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            userDetails.login = userDetails.login.toLowerCase();
                        }
                    }
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                await that.editUserDetailsDlg(mode, userDetails, userAddedCb, noUserAddedCb, allUsers);
            })
            .fail(function (error) {
                ml.UI.showError("Cannot edit user", error);
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resetPassword(userId: string) {
        let that = this;
        restConnection
            .getServer("user")
            .done(function (result) {
                const allUsers = result as XRGetUser_AllUsers_GetUserListAck;
                for (let idx = 0; idx < allUsers.user.length; idx++) {
                    if (allUsers.user[idx].login.toLowerCase() === userId.toLowerCase()) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        let currentUser = <IUserGet>(<any>allUsers.user[idx]);
                        // TODO: convert to const and make sure it's still works
                        // eslint-disable-next-line no-var
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        var userDetails: IUserCreate = <IUserCreate>(<any>currentUser);
                        userDetails.password = that.generatePassword();
                        userDetails.signaturePassword = that.generatePassword();
                        // fix MATRIX-1046 reset password button resets admin flag
                        userDetails.admin = userDetails.customerAdmin;
                        // fix MATRIX-1660 The "reset password" function in the admin UI doesn't work anymore
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (currentUser.signatureImage == "") {
                            userDetails.signatureImage = -1;
                        } else {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            userDetails.signatureImage = parseInt(currentUser.signatureImage.split("?")[0]);
                        }
                        restConnection
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .putServer("user/" + userDetails.login.toLowerCase(), {
                                json: JSON.stringify(userDetails),
                            })
                            .done(function (result) {
                                /* MATRIX-2996 initial set password dialog ask for user name if not set*/
                                let askForName =
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    userDetails.firstName == undefined ||
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    userDetails.firstName == "" ||
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    userDetails.lastName == undefined ||
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    userDetails.lastName == "";
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                that.createUserMail(allUsers, userDetails.login.toLowerCase(), true, "", askForName);
                            })
                            .fail(function (errorMsg) {
                                ml.UI.showError("Failed to change passwords!", errorMsg);
                            });
                    }
                }
            })
            .fail(function (error) {
                ml.UI.showError("Cannot retrieve user info!", error);
            });
    }

    private canAutoFill = false;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    askForPassword(
        container: JQuery,
        btnName: string,
        showUser: boolean,
        userWidth: number,
        onSign: (name: string, pwd: string) => void,
    ) {
        let that = this;

        let table = $('<div class="signatureInfo input-group" style="margin-top:12px;width:100%">').appendTo(container);

        // by default do not allow change
        let autoFill = <IAutoFillSetting>matrixSession.getCustomerSettingJSON("autoFillSettings", {});
        that.canAutoFill = autoFill.allowAutoFill || autoFill.allowDocSignAutoFill;

        let name = $(
            "<input autocomplete='off' type='text' class='signatureInfo form-control' placeholder='enter user id' style='padding:8px'>",
        );
        let pwd = $(
            "<input type='" +
                (that.canAutoFill ? "password" : "text") +
                "' class='form-control' placeholder='password'>",
        );
        let signIt = $("<button class='form-control btn btn-default'>" + btnName + "</button>").click(function () {
            onSign(name.val(), pwd.val());
        });

        let pwdSpan = $("<span class='input-group-btn'>").append(pwd);
        table.append(name);
        table.append(pwdSpan);
        table.append($("<span class='input-group-btn'>").append(signIt));
        if (userWidth) {
            name.width(userWidth);
            pwdSpan.css("width", "90%");
        } else {
            pwdSpan.css("width", "90%");
        }

        that.initUserAndPassword(name, pwd, showUser);

        pwd.on("keyup paste", () => {
            pwd.attr("type", "password");
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected initUserAndPassword(name: JQuery, pwd: JQuery, showUser: boolean) {
        // canChange is set to true at first keypress, mousedown before that
        // it is an autofill which is not allowed in signature box
        if (showUser) {
            // normally the user can be displayed and not be changed
            name.val(matrixSession.getUser() + (this.canAutoFill ? "" : " ")); // the " "  makes sure the password is not filled when loading
            name.attr("readonly", "readonly");
        }

        if (this.canAutoFill) {
            return;
        }

        if (!showUser) {
            // sometimes user need to type it (this is actually a very special case)
            name.val("");
        }

        pwd.val("");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async updateGlobalUserInfo() {
        if (globalMatrix.ItemConfig.isConfigured() && matrixSession.getProject()) {
            try {
                const accessInfo = <XRGetProject_ProjectAccess_GetAccessAck>(
                    await restConnection.getServer(matrixSession.getProject() + "/access")
                );
                globalMatrix.ItemConfig.addUsers(accessInfo.userPermission, accessInfo.groupPermission);
            } catch (e) {
                ml.Logger.error("Error updating global user information.");
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async editUserDetailsDlg(
        mode: UserEditMode,
        userDetails: IUserCreate,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        userAddedCb: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        noUserAddedCb: Function,
        userInfo: XRGetUser_AllUsers_GetUserListAck,
    ) {
        let that = this;

        if (mode === "create") {
            userDetails = {
                login: "",
                email: "",
                firstName: "",
                lastName: "",
                customerAdmin: 0,
            };
        }
        let tablewidth = 680;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let form = $(this.getUserDetailsHTML(mode, userDetails.login));

        app.dlgForm.hide();
        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");
        let scrollPanel = $('<div class="layoutContainerScroll"></div>');
        app.dlgForm.append(scrollPanel);
        scrollPanel.append(form);

        let table = $("#userPrefTable");

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let save = function (event: JQueryEventObject) {
            that.enableSavePwd(true);
            ml.UI.showSuccess(mode === "create" ? "Creating user!" : "Updating user!");

            let newValues = readform();
            if (!newValues) {
                return;
            }
            let pl = "";

            $.each($(".qmsViewerOnly:checked"), function (idx, p) {
                let url = globalMatrix.matrixBaseUrl + "/pub/" + $(p).data("pid");
                pl +=
                    "<a href='" +
                    url +
                    "'><b>" +
                    $(p).data("pid") +
                    "</b> - " +
                    $(p).data("plabel") +
                    "</a> (as QMS viewer)<br/>";
            });

            $.each($(".proch:checked"), function (idx, p) {
                pl += "<b>" + $(p).data("pid") + "</b> - " + $(p).data("plabel") + "<br/>";
            });

            // create or update user
            saveData(newValues).done(function () {
                // MATRIX-1341
                // The create user function in the admin UI doesn't open up projects for users
                // save projects to give access later
                let paccess: string[] = [];
                $.each($(".proch:checked"), function (idx, p) {
                    paccess.push($(p).data("pid"));
                });

                let paccessQms: string[] = [];
                $.each($(".qmsViewerOnly:checked"), function (idx, p) {
                    paccessQms.push($(p).data("pid"));
                });
                $(app.dlgForm).empty();
                ml.UI.getSpinningWait("Refreshing user list").appendTo($(app.dlgForm));
                // user created
                if (mode === "create") {
                    restConnection
                        .getServer("user")
                        .done(async function (allUsers) {
                            $(app.dlgForm).empty();
                            ml.UI.getSpinningWait("Granting access").appendTo($(app.dlgForm));
                            // update list of existing users if necessary.
                            await that.updateGlobalUserInfo();

                            let projectList = $("<ul>");
                            $(app.dlgForm).append(projectList);

                            let askForName =
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                newValues.firstName == undefined ||
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                newValues.firstName == "" ||
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                newValues.lastName == undefined ||
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                newValues.lastName == "";
                            // send mail to user
                            that.createUserMail(
                                allUsers as XRGetUser_AllUsers_GetUserListAck,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                newValues.login,
                                false,
                                pl,
                                askForName,
                            );

                            // give initial access rights to projects
                            for (let pid of paccess) {
                                projectList.prepend(`<li>${pid} (RW access)</li>`);
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                await restConnection.postServer(`user/${newValues.login}/${pid}`, {
                                    permission: "2", // read Write access
                                    reason: "admin.js",
                                });
                            }
                            for (let pid of paccessQms) {
                                projectList.prepend(`<li>${pid}  (LiveQMS access) </li>`);
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                await restConnection.postServer(`user/${newValues.login}/${pid}`, {
                                    permission: "4", // QMS access
                                    reason: "admin.js",
                                });
                            }
                            setTimeout(() => {
                                app.dlgForm.dialog("close");
                            }, 3000);
                        })
                        .fail(() => {
                            ml.UI.showError("Failed to get user!", "");
                            app.dlgForm.dialog("close");
                        });
                } else {
                    app.dlgForm.dialog("close");
                }
            });
        };

        if (mode !== "create") {
            let table3 = $("<table class='userSettings' style='width:100%'><tbody/></table>");
            $("#userPref").append(table3);

            let table2 = $("<table class='dateSettings' style='width:100%'><tbody/></table>");

            $("#userPref").append(table2);

            await ml.UI.DateTime.renderSettingControlsAsync({
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                user: userDetails.login,
                help: "User Date and Time Settings",
                table: table2,
            });

            $("#userPref").append(table2);

            that.renderNotificationEmailReminder(userDetails, table3);

            let savePwDiv = $("<div class=''  ></div>");
            let saveButton = $("<button class='savePwButton ' id='saveBtn'> Save </button>").click(save);
            saveButton.button();
            let savePwTb = $(" <span class='' style='float:right'><button class='' >PW</button></span></div>");
            savePwTb.append(saveButton);
            savePwDiv.append(savePwTb);
            table.after(savePwDiv);

            setTimeout(() => {
                $("#ud_pw3").width($("#ud_login").width() - $("#saveBtn").width() - 40);
            }, 400);
        }

        $("input", scrollPanel).on("change keyup paste", function () {
            that.enableSavePwd(!readform());
            let isEnabled = !$("#saveBtn").attr("disabled");
            $("#ud_pw3").css("border-color", isEnabled ? "" : "red");
        });

        // attach password creation handler
        $(".pwgen", scrollPanel).click(function (event: JQueryEventObject) {
            that.generatePassword($(event.delegateTarget));
        });

        // fill form with input
        $.each(userDetails, function (detail, val) {
            if (detail === "signatureImage") {
                if (val && val !== -1) {
                    $("#ud_currentImg")
                        .html("")
                        .append(
                            "<img class='signature' src='" + globalMatrix.matrixRestUrl + "/all/file/" + val + "'>",
                        );
                }
            }
            if (detail === "customerAdmin") {
                $("#ud_" + detail).prop("checked", val ? true : false);
            } else {
                $("#ud_" + detail).val(val);
            }
        });

        // hide / disable stuff which cannot be changed

        if (mode === "create") {
            $("#ud_pw1_line").hide();
            $("#ud_pw1R_line").hide();
            $("#ud_pw2_line").hide();
            $("#ud_pw1").val(that.generatePassword());
            $("#ud_pw1R").val($("#ud_pw1").val());
            $("#ud_pw2").val(that.generatePassword());
        } else if (mode === "adminedit") {
            $("#ud_login").attr("readonly", "readonly");
        } else {
            $("#ud_login").attr("readonly", "readonly");
            $(".admin_line").hide();
        }
        if (matrixSession.oAuthOnly()) {
            $("#ud_pw1").val(that.generatePassword());
            $("#ud_pw1R").val($("#ud_pw1").val());
            $("#ud_pw1_line").hide();
            $("#ud_pw1R_line").hide();
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (userInfo.needDoublePassword != 1 && !matrixSession.oAuthOnly()) {
            $("#ud_pw2_line").hide();
            $("#ud_pw1").on("change keyup paste", function () {
                $("#ud_pw2").val($("#ud_pw1").val() + "#'Ä*PSSD");
            });
        }
        if (mode !== "useredit") {
            $(".ud_useredit_line").hide();
        }
        if (mode === "adminedit") {
            $("fieldset", app.dlgForm).css("border", "none").css("padding", 0).css("padding-bottom", 0);
            $("legend", app.dlgForm).hide();
        }
        if (mode === "create") {
            $("fieldset", app.dlgForm).css("border", "none").css("padding", 0).css("padding-bottom", 0);
            $("legend", app.dlgForm).hide();

            let accessRights = $("<div >");
            table.after(accessRights);
            if (matrixSession.getProjectList(false).filter((p) => p.qmsProject).length > 0) {
                accessRights.append("<span class='baseControlHelp'>LiveQMS (viewer only)</span>");
                $.each(matrixSession.getProjectList(false), function (ip, p) {
                    if (p.shortLabel !== "EMPTY") {
                        if (p.qmsProject) {
                            accessRights.append(
                                '<div style="padding-left:35px" >' +
                                    '<label><input type="checkbox" class="qmsViewerOnly" data-pid="' +
                                    p.shortLabel +
                                    '" data-plabel="' +
                                    p.label +
                                    '">' +
                                    '<span style="padding-left:10px">' +
                                    p.shortLabel +
                                    '</span> <span style="font-weight:normal">' +
                                    p.label +
                                    "</span>" +
                                    "</div>",
                            );
                        }
                    }
                });
            }
            accessRights.append("<span class='baseControlHelp'>Give user read/write access to these projects</span>");

            accessRights.append(
                '<div style="padding-left:35px" >' +
                    '<label><input type="checkbox" class="toogleProjectAccess">' +
                    '<span style="padding-left:10px;font-weight:normal">toggle all projects</span></label>' +
                    "</div>",
            );

            $(".toogleProjectAccess").change(function () {
                let checked = $(".toogleProjectAccess").is(":checked");
                $.each($(".proch"), function (idx, proch) {
                    $(proch).prop("checked", checked);
                });
                if (checked) {
                    $(".qmsViewerOnly").prop("checked", false);
                }
            });
            $.each(matrixSession.getProjectList(false), function (ip, p) {
                if (p.shortLabel !== "EMPTY") {
                    accessRights.append(
                        '<div style="padding-left:35px" >' +
                            '<label><input type="checkbox" class="proch" data-pid="' +
                            p.shortLabel +
                            '" data-plabel="' +
                            p.label +
                            '">' +
                            '<span style="padding-left:10px">' +
                            p.shortLabel +
                            '</span> <span style="font-weight:normal">' +
                            p.label +
                            "</span></label>" +
                            "</div>",
                    );
                }
            });

            $(".proch").change(function (event) {
                if ($(event.target).is(":checked")) {
                    $(".qmsViewerOnly[data-pid='" + $(event.target).data("pid") + "']").prop("checked", false);
                }
            });
            $(".qmsViewerOnly").change(function (event) {
                if ($(event.target).is(":checked")) {
                    $(".proch[data-pid='" + $(event.target).data("pid") + "']").prop("checked", false);
                    $(".toogleProjectAccess").prop("checked", false);
                }
            });
        }
        // init validators
        let pw1 = new StrongPass("#ud_pw1", {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            passFail: function () {
                //console.log(passed);
            },
            passIndex: userInfo.passwordStrength,
            other: "#ud_pw2",
        });
        let pw2 = new StrongPass("#ud_pw2", {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            passFail: function () {
                //console.log(passed);
            },
            passIndex: userInfo.passwordStrength,
            other: "#ud_pw1",
        });

        let user = new User("#ud_login");
        let email = new Email("#ud_email");

        // handle signature file upload
        $("#ud_signatureImageChange").change(function () {
            let files = (<HTMLInputElement>$("#ud_signatureImageChange")[0]).files;
            if (files) {
                let validFileExtensions = ["jpg", "jpeg", "bmp", "gif", "png"];
                let nameparts = files[0].name.toLowerCase().split(".");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (validFileExtensions.indexOf(nameparts[nameparts.length - 1]) != -1) {
                    restConnection
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        .uploadFileCustomerAsync(files[0], function (progress: IFileUploadProgress) {})
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        .done(function (result: IFileUploadResult) {
                            let imgPath =
                                globalMatrix.matrixRestUrl + "/all/file/" + result.fileId + "?key=" + result.key;

                            let testSize = $("<img style='display:none'>")
                                .appendTo("body")
                                .attr("src", imgPath)
                                .load(function (event) {
                                    if (testSize.height() > 300 || testSize.width() > 800) {
                                        ml.UI.showError(
                                            "Signature image too large",
                                            "Image cannot exceed 800x300 pixels.",
                                        );
                                    } else {
                                        $("#ud_signatureImage").val(result.fileId);
                                        $("#ud_currentImg")
                                            .html("")
                                            .append("<img class='signature' src='" + imgPath + "'>");
                                        if (mode === "useredit" && matrixSession.oAuthOnly()) {
                                            that.enableSaveOAuth();
                                        }
                                    }
                                    testSize.remove();
                                });
                        })
                        .fail(function (error) {
                            ml.UI.showError("Failed to upload image", error);
                        });
                } else {
                    ml.UI.showError("Only image files are supported", "");
                }
            }
        });

        // function to get values back from form
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function readform() {
            let error = false;

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            error = ($("#ud_pw1").val() != "" && !pw1.isOK()) || error;
            error =
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ((userInfo.needDoublePassword == 1 || matrixSession.oAuthOnly()) &&
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    $("#ud_pw2").val() != "" &&
                    !pw2.isOK()) ||
                error;
            error = !email.isOK() || error;
            error = !user.isOK() || error;
            if (error) {
                return null;
            }
            let signImageVal = <string>$("#ud_signatureImage").val();
            let signImage = signImageVal ? parseInt(signImageVal.split("?")[0]) : -1;
            return {
                login: (<string>$("#ud_login").val()).toLowerCase(),
                email: $("#ud_email").val(),
                pw1: $("#ud_pw1").val(),
                firstName: $("#ud_firstName").val(),
                lastName: $("#ud_lastName").val(),
                signatureImage: signImage,
                pw2: $("#ud_pw2").val(),
                pw3: $("#ud_pw3").val(),
                customerAdmin: $("#ud_customerAdmin").prop("checked") ? 1 : 0,
            };
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function saveData(newValues: IUserCreate) {
            let res = $.Deferred();
            let signatureImage = -1;
            if (newValues.signatureImage) {
                signatureImage = newValues.signatureImage;
                if (isNaN(signatureImage)) {
                    signatureImage = -1;
                }
            }
            let jsonParamObj = {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                login: newValues.login.toLowerCase(),
                email: newValues.email,
                password: newValues.pw1,
                firstName: newValues.firstName,
                lastName: newValues.lastName,
                signatureImage: signatureImage,
                signaturePassword: newValues.pw2, // signature for password
                admin: newValues.customerAdmin, // whether user is an admin
            };

            if (!jsonParamObj.password) {
                delete jsonParamObj.password;
            }
            if (!jsonParamObj.signaturePassword) {
                delete jsonParamObj.signaturePassword;
            }

            let jsonParam = JSON.stringify(jsonParamObj);

            if (mode === "create") {
                restConnection
                    .postServer("user", {
                        json: jsonParam,
                    })
                    .done(function () {
                        if (userAddedCb) {
                            userAddedCb();
                        }
                        res.resolve();
                    })
                    .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                        let msg: string = ml.UI.getDisplayError(jqxhr, textStatus, error);

                        if (
                            jqxhr &&
                            jqxhr.responseJSON &&
                            jqxhr.responseJSON.detailsList &&
                            jqxhr.responseJSON.detailsList.length &&
                            jqxhr.responseJSON.detailsList[0].indexOf(
                                "Last operation would overflow number of licenses (error in compute)|CreateUserMethod",
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                            ) != -1
                        ) {
                            msg = "There are no more write licenses.";
                        } else if (jqxhr && jqxhr.responseText && jqxhr.responseText.indexOf("duplicate") !== -1) {
                            msg = "user id exists already";
                        }

                        ml.UI.showError("Failed to create user!", msg);

                        if (noUserAddedCb) {
                            noUserAddedCb();
                        }
                        res.reject();
                    });
            } else if (mode === "adminedit") {
                restConnection
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .putServer("user/" + newValues.login.toLowerCase(), {
                        json: jsonParam,
                    })
                    .done(function (result) {
                        if (userAddedCb) {
                            userAddedCb();
                        }
                        res.resolve();
                    })
                    .fail(function (errorMsg) {
                        ml.UI.showError("Failed to create user!", errorMsg);
                        if (noUserAddedCb) {
                            noUserAddedCb();
                        }
                        res.reject();
                    });
            } else if (matrixSession.oAuthOnly()) {
                // user edit oAuth only

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.setUserData(newValues.login.toLowerCase(), jsonParam, userAddedCb, noUserAddedCb)
                    .done(function () {
                        res.resolve();
                    })
                    .fail(function () {
                        res.reject();
                    });
            } else {
                // user edit normal auth user

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $.post(globalMatrix.matrixRestUrl + "/user/" + newValues.login.toLowerCase() + "/login", {
                    password: newValues.pw3,
                })
                    .done(function (data) {
                        that.setUserData(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            newValues.login.toLowerCase(),
                            jsonParam,
                            userAddedCb,
                            noUserAddedCb,
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            newValues.pw1 != "" && newValues.pw2 != "",
                        )
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        ml.UI.showError("Incorrect current password!", "");
                        if (noUserAddedCb) {
                            noUserAddedCb();
                        }
                        res.reject();
                    });
            }
            return res;
        }

        let buttons = [];

        buttons.push({
            text: "Close",
            class: "btnCancelIt",
            click: function () {
                if (noUserAddedCb) {
                    noUserAddedCb();
                }
                app.dlgForm.dialog("close");
            },
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (mode === "create" || mode == "adminedit") {
            buttons.push({
                text: mode === "create" ? "Create" : "Save",
                class: "btnDoIt savePwButton",
                click: save,
            });
        }

        app.dlgForm.dialog({
            autoOpen: true,
            title: mode === "create" ? "Create new User" : "My profile",
            width: tablewidth + 110,
            height: 600,
            modal: true,
            resize: function () {
                $("#ud_pw3").width($("#ud_login").width() - $("#saveBtn").width() - 40);
            },
            open: function () {
                let pwb = $("span button:contains(PW)", app.dlgForm.parent());

                if (mode === "useredit") {
                    if (matrixSession.oAuthOnly()) {
                        pwb.replaceWith($("<span>"));
                        $("#ud_signatureImage").data("org", $("#ud_signatureImage").val());
                        let saveOAuth = $(
                            `<button id="saveBtnOAuth" class="ui-button ui-corner-all ui-widget"> Save </button>`,
                        ).click(() => {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            saveData(readform());
                        });
                        $("#saveBtn").hide().parent().append(saveOAuth);
                        $("#ud_pw2").on("change keyup paste", function () {
                            that.enableSaveOAuth();
                        });
                        that.enableSaveOAuth();
                    } else {
                        // request a current password to change user
                        let pwp = $(
                            '<input type="text" class="form-control ud_useredit_line" id="ud_pw3" style="height:31px;margin-right:10px;width:250px;display: inline-block;" placeholder="enter current password to save">',
                        );
                        pwp.on("keyup", function () {
                            pwp.attr("type", "password");
                        });
                        let errorPwp = $("<span id='errorPWP' style='color:red;padding-right:8px;'>").html(
                            ml.UI.spaceMessage(false, true),
                        );
                        pwb.replaceWith($("<span>").append(errorPwp).append(pwp));
                        $("#saveBtn").closest("div").css("margin-top", "36px");
                        errorPwp.hide();

                        $(".savePwButton").button("disable");
                        pwp.on("change keyup paste", function () {
                            that.enableSavePwd(!readform());
                        });
                        that.enableSavePwd(!readform());
                    }
                } else {
                    pwb.hide();
                    $("#saveBtn").hide();
                    $("#userPref .dateSettings").hide();
                    that.enableSavePwd(!readform());
                }
            },
            buttons: buttons,
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    enableSaveOAuth() {
        ml.UI.setEnabled(
            $("#saveBtnOAuth"),
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            $("#ud_signatureImage").data("org") != $("#ud_signatureImage").val() || !!$("#ud_pw2").val(),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    saveEmailNotificationsSetting(user: string, setting: IEmailNotificationSetting) {
        let data = {
            key: UserControl.EMAILNOTIF_CONFIG,
            value: JSON.stringify(setting),
            reason: "Updating notification Setting",
        };
        restConnection.postServer("user/" + user + "/setting", data).then(() => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (user == matrixSession.getUser()) {
                matrixSession.tryReconnect();
            }
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderNotificationEmailReminder(userDetails: IUserCreate, table: JQuery) {
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (userDetails.userSettingsList == undefined) {
            userDetails.userSettingsList = [];
        }

        let emailNotificationSettings = userDetails.userSettingsList.filter((set) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return set.key == UserControl.EMAILNOTIF_CONFIG;
        });

        let emailNotificationSetting: IEmailNotificationSetting =
            emailNotificationSettings.length > 0 ? JSON.parse(emailNotificationSettings[0].value) : { periodicity: "" };

        let tableBody = $("tbody", table);
        tableBody.append(
            $(
                '<tr class="notif showInAdmin" ><td colspan="2" class="baseControlHelp">Notifications Digest Email</td></tr',
            ),
        );

        let tr = $("<tr  class='notif showInAdmin' >");
        tableBody.append(tr);

        tr.append($("<td class='admin_utdl'>").append($("<label class='control-label'>").html("Email notifications")));

        let options: IDropdownOption[] = [
            { id: "", label: "No email" },
            { id: "daily", label: "Email me daily" },
            { id: "weekly", label: "Email me weekly" },
        ];

        let div = $("<div/>");

        // ml.UI.addDropdownToValue(  "Periodicity", emailNotificationSetting, "periodicity", options, false, false, () => {
        //     that.saveEmailNotificationsSetting(userDetails.login, emailNotificationSetting); });

        tr.append(
            $("<td>").append(`<select  style="width: 100%;height: 34px;" class="form-control" id="emailNotifCombo">
                <option value="">No email</option>
                <option value="daily">Email me daily</option>
                <option value="weekly">Email me weekly</option>
           </select>`),
        );

        $("#emailNotifCombo").change((o) => {
            let value = $("#emailNotifCombo option:selected").val();
            emailNotificationSetting.periodicity = value;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.saveEmailNotificationsSetting(userDetails.login, emailNotificationSetting);
        });
        $("#emailNotifCombo").val(emailNotificationSetting.periodicity);

        tr.append($("<td>").append(""));
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createUserMail(
        allUsers: XRGetUser_AllUsers_GetUserListAck,
        user: string,
        reset: boolean,
        pl: string,
        askForName: boolean,
    ) {
        let that = this;
        let prl = parseInt(matrixSession.getCustomerSetting("passwordResetLimitation"));
        // if NaN or undefined then fallback to 3days
        let maxDuration = prl ? prl : 72;
        restConnection
            .postServer("user/" + user + "/token", {
                purpose: "password_reset",
                validity: maxDuration,
                reason: "password reset by admin",
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .done(async function (token: any) {
                // Update list of existing users if necessary.
                await that.updateGlobalUserInfo();

                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let userDetails = allUsers.user.filter((o) => o.login == user);

                let additionnalParameters = "";
                if (askForName) {
                    additionnalParameters = "&askForName=1";
                    if (userDetails.length > 0) {
                        additionnalParameters +=
                            "&first=" + userDetails[0].firstName + "&last=" + userDetails[0].lastName;
                    }
                }
                let url =
                    globalMatrix.matrixBaseUrl +
                    "/resetPassword.jsp?user=" +
                    user +
                    "&passwordStrength=" +
                    allUsers.passwordStrength +
                    additionnalParameters +
                    "&double=" +
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    (allUsers.needDoublePassword == 1 || matrixSession.oAuthOnly() ? "1" : "0") +
                    "&oAuthOnly=" +
                    (matrixSession.oAuthOnly() ? "1" : "0") +
                    "&token=" +
                    token.token;

                let userinfo =
                    "You need to set your password(s) using this link: <a href='" +
                    url +
                    "'>" +
                    url +
                    "</a><br/><br/>" +
                    "Once you set your password you can login here: <a href='" +
                    globalMatrix.matrixBaseUrl +
                    "'>" +
                    globalMatrix.matrixBaseUrl +
                    "</a><br/>" +
                    "Your user id is: <b>" +
                    user +
                    "</b><br/><br/>" +
                    "Note: you have " +
                    maxDuration +
                    " hours to do this. If you are too late ask your admin for a new link!";

                let message = ml.Mail.getCannedMessage(reset ? "reset_pwd" : "new_user", user, "");

                message = message.replace("_userinfo_", userinfo);
                message = message.replace("_projectlist_", pl);

                ml.Mail.sendMail(
                    user,
                    "",
                    "",
                    reset ? "Password Reset" : "Access to Matrix",
                    message,
                    undefined,
                    undefined,
                    1,
                );
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private enableSavePwd(disable: boolean) {
        let pwp = $("#ud_pw3");
        let errorPwp = $("#errorPWP");
        let errorMatch = $("#errorMatch");
        let btn = $(".savePwButton");
        errorPwp.hide();
        errorMatch.hide();

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if ($("#ud_pw1").val() != $("#ud_pw1R").val()) {
            btn.button("disable");
            errorMatch.show();
        } else if (pwp.length && (<string>pwp.val()).length === 0) {
            btn.button("disable");
        } else if (pwp.length && (<string>pwp.val()).indexOf(" ") !== -1) {
            btn.button("disable");
            errorPwp.show();
        } else {
            btn.button(disable ? "disable" : "enable");
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setUserData(
        userId: string,
        jsonParam: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        userAddedCb: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        noUserAddedCb: Function,
        signOut = true,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
    ): JQueryDeferred<{}> {
        let res = $.Deferred();
        restConnection
            .putServer("user/" + userId, {
                json: jsonParam,
            })
            .done(function (result) {
                if (userAddedCb) {
                    userAddedCb();
                }
                if (signOut) {
                    matrixSession.signOut(false);
                    ml.UI.showSuccess("The password has been updated!");
                }

                res.resolve();
            })
            .fail(function (errorMsg) {
                ml.UI.showError("Failed to create user!", errorMsg);
                if (noUserAddedCb) {
                    noUserAddedCb();
                }
                res.reject();
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getUserDetailsHTML(mode: string, login: string) {
        /* !!! IMPORTANT !!!
    In response to a customer's concern about compliance with 21 CFR Part 11 Section 11.200a(3),
    it was found that our system allows administrators to change a user's password or email and subsequently
    log in as that user, potentially impersonating them and signing documents on their behalf.

    To address this compliance issue, we have made the following changes:
      - Any changes to a user's email address now require a support ticket.
      - We have restricted the ability to change a user's password and email  to  superadmins (or the user itself).

    These changes ensure that the system complies with the requirement for "2 or more" individuals
    and enhances security by limiting certain administrative actions.
    See : SER-25549
*/

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let part11disabled = mode == "useredit" || (mode == "adminedit" && login == matrixSession.getUser());

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let isUserSelf = mode == "useredit" || login == matrixSession.getUser();
        let isSuperAdmin = matrixSession.isSuperAdmin();
        let isAdmin = matrixSession.isAdmin();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let isCreate = mode == "create";

        let canEditEmail = isSuperAdmin || isCreate;
        let canSeeEmail = isSuperAdmin || isAdmin || isCreate;
        let canEditName = (isSuperAdmin || isCreate || isAdmin) && !isUserSelf;

        let canEditPassWord = isSuperAdmin || isCreate || isUserSelf || globalMatrix.mxOauth;
        let canSeePassWord = isSuperAdmin || isCreate || isUserSelf || globalMatrix.mxOauth;
        let canEditSignature = isSuperAdmin || isCreate || isUserSelf;

        let part11 = part11disabled
            ? // TODO: MATRIX-7555: lint errors should be fixed for next line
              // eslint-disable-next-line
              "disabled for part 11 compliance, ask " + (mode == "adminedit" ? "another" : "an") + " admin to change"
            : "";

        return (
            '<div class="container" style="width:100%;white-space:nowrap;">' +
            '<fieldset><legend>User info</legend><table id="userPrefTable" style="width:100%" >' +
            '  <tr><td colspan="2" class="baseControlHelp">Sign-In</td></tr>' +
            "  <tr>" +
            '    <td class="admin_utdl"><label for="ud_login" class="control-label">User Id</label></td>' +
            '    <td class="admin_utdr"><input autocomplete="off" type="text" class="form-control" id="ud_login" placeholder="User id"></td>' +
            "  </tr>" +
            "  <tr>" +
            '    <td class="admin_utdl"><label for="ud_firstName" class="control-label">First Name</label></td>' +
            '    <td class="admin_utdr"><input title="' +
            part11 +
            '" autocomplete="off" type="text" class="form-control" id="ud_firstName" placeholder="First name" ' +
            (canEditName ? "" : "disabled") +
            "></td>" +
            "  </tr>" +
            "  <tr>" +
            '    <td class="admin_utdl"><label for="ud_lastName" class="control-label">Last Name</label></td>' +
            '    <td class="admin_utdr"><input title="' +
            part11 +
            '" autocomplete="off" type="text" class="form-control" id="ud_lastName" placeholder="Last name" ' +
            (canEditName ? "" : "disabled") +
            "></td>" +
            "  </tr>" +
            "  <tr " +
            (canSeeEmail ? "" : "style='display:none' ") +
            ">" +
            '    <td class="admin_utdl"><label for="ud_email" class="control-label">Email</label></td>' +
            '    <td class="admin_utdr"><input title="' +
            part11 +
            '" autocomplete="off" type="text" class="form-control" id="ud_email" placeholder="Email" ' +
            (canEditEmail ? "" : "disabled") +
            "></td>" +
            "  </tr>" +
            "  <tr   " +
            (canSeePassWord ? "" : "style='display:none' ") +
            ' id="ud_pw1_line">' +
            '    <td class="admin_utdl"><label autocomplete="new-password" for="ud_pw1" class="control-label">Password for Login</label>' +
            //'       <button class="btn btn-default btn-xs pwgen" data-target="ud_pw1" id="ud_cpw1" >create</button>' +
            "    </td>" +
            '    <td class="admin_utdr"><input autocomplete="new-password" type="password" class="form-control" id="ud_pw1" placeholder="password" ' +
            (canEditPassWord ? "" : "disabled") +
            " ></td>" +
            "  </tr>" +
            "  <tr  " +
            (canSeePassWord ? "" : "style='display:none' ") +
            ' id="ud_pw1R_line">' +
            '    <td class="admin_utdl"><label autocomplete="new-password" for="ud_pw1R" class="control-label">Repeat</label>' +
            "    </td>" +
            '    <td class="admin_utdr"><input autocomplete="new-password" type="password" class="form-control" id="ud_pw1R" placeholder="repeat" ' +
            (canEditPassWord ? "" : "disabled") +
            ">" +
            '         <div id="errorMatch" style="color:red;">passwords do not match</div></td>' +
            "  </tr>" +
            //'  <tr><td colspan="2" class="baseControlHelp">Electronic Signatures</td></tr>' +
            "  <tr>" +
            '    <td class="admin_utdl"><label class="control-label">Signature Image</label></td>' +
            '    <td class="admin_utdr">' +
            '       <span id="ud_currentImg"></span>' +
            '       <label for="ud_signatureImageChange"  style="color:blue;font-weight:normal;cursor:pointer;' +
            (canEditSignature ? "" : "display:none") +
            '">select signature image</label>' +
            '       <input accept="image/JPEG,image/GIF,image/PNG,image/BMP" style="display:none" type="file" id="ud_signatureImageChange">' +
            '       <input autocomplete="off" style="display:none" type="text" id="ud_signatureImage">' +
            "    </td>" +
            "  </tr>" +
            "  <tr>" +
            '    <td class="admin_utdl"></td>' +
            '    <td class="admin_utdr">' +
            "    </td>" +
            "  </tr>" +
            "  <tr " +
            (canSeePassWord ? "" : "style='display:none' ") +
            ' id="ud_pw2_line">' +
            '    <td class="admin_utdl"><label for="ud_pw2" class="control-label">Password for eSignatures</label>' +
            //'       <button class="btn btn-default btn-xs pwgen" data-target="ud_pw2" id="ud_cpw2" >create</button>' +
            "    </td>" +
            '    <td class="admin_utdr"><input type="password" class="form-control" id="ud_pw2" placeholder="password" ' +
            (canEditPassWord ? "" : "disabled") +
            "></td>" +
            "  </tr>" +
            '  <tr class="admin_line"><td colspan="2" class="baseControlHelp">Server Administration Rights</td></tr>' +
            '  <tr class="admin_line">' +
            '    <td class="admin_utdl" colspan="2" > <div class="checkbox"><label><input id="ud_customerAdmin" type="checkbox"> is administrator</label></div></td>' +
            "  </tr>" +
            "  </table></fieldset><br/>" +
            '<fieldset id="userPref"><legend>User preferences</legend></fieldset>'
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private generatePassword(btn?: JQuery) {
        let keylist1 = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789_@?+-";
        let keylist2 = "_@?+-";

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function gp(chars: string, count: number) {
            let pwd = "";
            for (let idx = 0; idx < count; idx++) {
                pwd += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return pwd;
        }

        let pw = gp(keylist1, 11) + gp(keylist2, 1) + gp(keylist1, 3);
        if (btn) {
            let target = btn.data("target");
            $("#" + target).val(pw);
            // make readable
            (<HTMLInputElement>$("#" + target).get(0)).type = "text";
        }
        return pw;
    }
}

const userControls = new UserControl();
