import { InitializeComponents } from "./Components/initialize";
import { InitializeControls } from "./Controls/initialize";
import { InitializePanels } from "./Panels/initialize";
import { InitializeParts } from "./Parts/initialize";

export { InitializeUI };

// Function exists to ensure initializations in the imported modules above
// are made.
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeUI() {
    InitializeParts();
    InitializeControls();
    InitializeComponents();
    InitializePanels();
}
