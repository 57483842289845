import { IItem, globalMatrix, matrixSession, restConnection, ControlState, IReference, app } from "../../globals";
import { ml } from "../../common/matrixlib";

import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins } from "../../common/businesslogic/index";
import { IJcxhr } from "../../common/businesslogic//RestConnector";
import { EImportMode, ItemParentMode } from "../../common/businesslogic/ComponentImport";

import {
    XRCategoryFull,
    XRFancyFolder,
    XRFancyLeaf,
    XRGetProject_CategoryDetail_CategoryFull,
    XRGetProject_FullTree_FancyFolderList,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRTrimFolder,
} from "../../RestResult";
import { IItemSelectDialogOptions, ItemSelectionTools } from "../../common/UI/Tools/ItemSelectionView";
import { SelectMode } from "../../common/UI/Components/ProjectViewDefines";
import { IBaseControlOptions } from "../../common/UI/Controls/BaseControl";
import { config } from "process";
import { IDeletedProjects, IImportConfig, IImportConfigDetails } from "../../ProjectSettings";

export { ComponentImportDashboards as ProjectImports };
export { initialize };

interface IProjectImportInfo {
    /** list of all imports ever done for this project */
    imports: IProjectImport[];
}

/** one import which might be already deleted or replaced by another one */
interface IProjectImport {
    /** unique id from server*/
    importHistoryId: string;
    /** UTC date when import was created */
    importDateIso: string;
    /** iso date in user formatted when import was created */
    importDateIsoUserTimeZone: string;
    /** user formatted date when import was created */
    importDateUserFormat: string;
    /** date when import was deleted or updated (if updated a new import with same import date will exist). Null if import is still in project */
    deleteDate: string;
    /** user formatted date when import was deleted or updated*/
    deleteDateUserFormat: string;
    /** project from which items were imported */
    sourceProject: string;
    /** selection used when creating the import */
    sourceSelection: string;
    /** project from which data was exported */
    targetProject: string;
    /** folders into which the items where imported -> these are created on the server during import and can be moved but not deleted */
    importRoots: string[];
    /** mode:e.g. include or copy */
    import: EImportMode;
    /** item parent mode, orphan or preserve. If not present, orphan is the default value */
    itemParentMode?: ItemParentMode;
}

interface IItemParentModeMapping {
    project: string;
    importMode: EImportMode;
    itemParentMode: ItemParentMode;
}
interface IItemParentModeMappings {
    itemParentModes: IItemParentModeMapping[];
}

interface IncludePlan {
    sourceProject: string;
    selectionPaths: string[];
}

class ComponentImportDashboards implements IPlugin {
    protected dashboardSettingsName = "compose_dashboard";
    protected pageRoot = "COMPOSE";
    protected pageID: string;
    protected pageTitle: string;
    protected mode: EImportMode;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected importInfo: IProjectImportInfo;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected config: IImportConfigDetails;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected canEdit: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected itemParentModes: IItemParentModeMappings;

    constructor(pageID: string, pageTitle: string, mode: EImportMode) {
        this.pageID = pageID;
        this.mode = mode;
        this.pageTitle = pageTitle;
    }
    // ****************************************
    // standard plugin interface
    // ****************************************
    public isDefault = true;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(_item: IItem, _jui: JQuery) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings(serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) {}

    supportsControl(fieldType: string): boolean {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createControl(ctrl: JQuery, options: IBaseControlOptions) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {
        this.config =
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.mode == EImportMode.Include
                ? globalMatrix.ItemConfig.getIncludeConfig().includes
                : globalMatrix.ItemConfig.getIncludeConfig().copies;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.config.itemParentMode == undefined) {
            this.config.itemParentMode = ItemParentMode.Orphan;
        }
        this.canEdit =
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.config.importMasters.length == 0 || this.config.importMasters.includes(matrixSession.getUser());
        this.itemParentModes = <IItemParentModeMappings>(
            globalMatrix.ItemConfig.getSettingJSON(this.dashboardSettingsName, { itemParentModes: [] })
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isEnabled() {
        return true; //matrixSession.isAdmin();
    }

    // add one new project page to  the tree
    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];

            if (that.isEnabled()) {
                pages.push({
                    id: this.pageID,
                    folder: this.pageRoot,
                    icon: "fal fa-code-merge",
                    title: this.pageTitle,
                    order: 2000,
                    usesFilters: false,
                    render: (options: IPluginPanelOptions) => {
                        that.showPage(options.control);
                    },
                });
            }
            resolve(pages);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showPage(container: JQuery) {
        container.append(ml.UI.getPageTitle("Imports - " + this.pageTitle));

        if (!matrixSession.isCompose()) {
            let err = $(`<h2 style="padding-left:12px">`).html("Compose module not licensed!");
            container.append(err);
            return;
        }
        $(
            '<div id="mainControl" class="panel-body-v-scroll fillHeight panel-default" style="padding:12px;"> ',
        ).appendTo(container);
        this.showWait("getting imports");
        this.showContent();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showWait(text: string) {
        let control = $("#mainControl");
        control.html("");
        let gettingInfo = ml.UI.getSpinningWait(text);
        let thingToRemove = $("<div>").appendTo(control);
        thingToRemove.append(gettingInfo);
    }

    /**
     * This method brings any known ItemParentModes into the importInfo structure, and saves the compose_dashboard project
     * setting for any imports that aren't recorded there with a default mode of ItemParentMode.Orphan.
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected async updateItemParentModes() {
        let changed = false;
        for (let imp of this.importInfo.imports) {
            let found = false;
            for (let mode of this.itemParentModes.itemParentModes) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (imp.sourceProject == mode.project && imp.import == mode.importMode) {
                    found = true;
                    if (mode.itemParentMode === undefined) {
                        mode.itemParentMode = ItemParentMode.Orphan;
                    }
                    imp.itemParentMode = mode.itemParentMode;
                }
            }
            if (!found) {
                this.itemParentModes.itemParentModes.push({
                    project: imp.sourceProject,
                    itemParentMode: ItemParentMode.Orphan,
                    importMode: imp.import,
                });
                changed = true;
            }
        }
        if (changed) {
            await app.setSettingJSON(this.dashboardSettingsName, this.itemParentModes);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showContent() {
        let that = this;
        let control = $("#mainControl");

        restConnection.getProject("importinfo").done(async function (importInfo) {
            that.importInfo = importInfo as IProjectImportInfo;
            await that.updateItemParentModes();
            control.html("");
            that.showImports(control);
        });
    }

    /** show a list of previously done imports, with functions to change them. add button to create new */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showImports(control: JQuery) {
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mode == EImportMode.Include) {
            this.showIncludes(control);
        } else {
            this.showCopies(control);
        }
    }

    // Include based on the stored plan.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected async importInclude(text: string) {
        const that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let plan: IncludePlan = undefined;
        try {
            plan = JSON.parse(text);
        } catch (e) {
            // unable to include.
            ml.UI.showError("Unable to parse", "Unable to parse text");
            return;
        }

        // Does source project exist?
        const sourceProject = plan.sourceProject;

        const projectList = matrixSession.getProjectList(false);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (projectList.filter((value) => value.label == sourceProject).length !== 1) {
            // Too bad, project doesn't exist.
            ml.UI.showError(
                "Project doesn't exist: ",
                `Can't find project ${sourceProject} specified in the import plan`,
            );
            return;
        }

        // Is project forbidden?
        const forbiddenProjects: string[] = that.getForbiddenProjectsForIncludes();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (forbiddenProjects.filter((f) => f == sourceProject).length === 1) {
            // Too bad, forbidden.
            ml.UI.showError("Project is already included: ", `Project ${sourceProject} is already included.`);
            return;
        }

        const toRemove = ml.UI.getSpinningWait(`Converting include selection for source project ${sourceProject}...`);
        toRemove.appendTo($(".navbar-brand"));

        try {
            const result = await restConnection.getServer(sourceProject + "/tree");
            const tree = result as XRCategoryFull[];

            // Convert the tree into a map.
            let pathMap = new Map<string, string>();
            for (const t of tree) {
                that.fillMap(pathMap, t.folder, "/");
            }

            // Reverse the map.
            let reversedMap = new Map<string, string>();
            for (const k of pathMap.keys()) {
                const path = pathMap.get(k);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                reversedMap.set(path, k);
            }

            let selection: string[] = [];
            for (const selectedPath of plan.selectionPaths) {
                if (reversedMap.has(selectedPath)) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    selection.push(reversedMap.get(selectedPath));
                }
            }

            const itemParentMode: ItemParentMode =
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                that.config.itemParentMode == "preserve" ? ItemParentMode.Preserve : ItemParentMode.Orphan;
            that.showImportDialogSingle(selection, sourceProject, itemParentMode);
        } catch (e) {
            // unable to include.
            ml.UI.showError(
                "Unexpected error",
                `Unexpected error importing an include plan for source project ${sourceProject}`,
            );
        }

        toRemove.remove();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showIncludes(control: JQuery) {
        let that = this;

        let parentFolderStructureInfo =
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.config.itemParentMode == "preserve"
                ? "Imported items <b>will include parent folder structure</b>."
                : "Imported items <b>will not include parent folder structure</b>.";

        let lockLabelInfo = this.config.lockLabel
            ? `Imported items will be locked with label "${this.config.lockLabel}".`
            : "The included items will <b>not be locked</b>. You can change this in the Configure Compose page.";

        $(
            `<p class="inlineHelp ">Includes are references to items in other projects. These items have the same ids as in the original project. ${parentFolderStructureInfo} ${lockLabelInfo}</p>`,
        ).appendTo(control);

        let anchor = $("<div class='importList'>").appendTo(control);

        $(`<div class='separator'><div><span>Current Includes</span></div></div>`).appendTo(anchor);

        // this will be removed if something was imported
        $(`<p class="nothingImported">nothing has been included into this project</p>`).appendTo(anchor);

        // filter out everything which is not an include
        that.importInfo.imports = that.importInfo.imports.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (imp) => imp.import == "include" && imp.targetProject == matrixSession.getProject(),
        );

        // sort imports by date
        that.importInfo.imports.sort((a, b) => {
            return a.importDateIso < b.importDateIso ? -1 : 1;
        });

        // render all not deleted imports
        for (let imp of that.importInfo.imports.filter((a) => !a.deleteDate)) {
            that.addIncludeToList(imp, anchor);
        }

        let divider = false;
        // render fake groups for imports which have no current ones
        let deletedImports = that.importInfo.imports.filter((a) => !!a.deleteDate).map((a) => a.sourceProject);
        let activeImports = that.importInfo.imports.filter((a) => !a.deleteDate).map((a) => a.sourceProject);
        for (let deletedImport of deletedImports) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (activeImports.indexOf(deletedImport) == -1) {
                if (!divider) {
                    divider = true;
                    $(`<div class='separator'><div><span>Previous Includes</span></div></div>`).appendTo(anchor);
                }
                // this project was included but is not anymore: create a fake entry to be able to show it in the list
                activeImports.push(deletedImport);

                let importInfo: IProjectImport = {
                    importHistoryId: "",
                    importDateIso: "",
                    importDateIsoUserTimeZone: "",
                    importDateUserFormat: "currently not included",
                    deleteDate: "",
                    deleteDateUserFormat: "",
                    sourceProject: deletedImport,
                    sourceSelection: "",
                    targetProject: "",
                    importRoots: ["&nbsp;"],
                    import: EImportMode.Include,
                    itemParentMode: ItemParentMode.Orphan,
                };
                that.addIncludeToList(importInfo, anchor);
            }
        }

        app.waitForMainTree(() => {
            anchor.highlightReferences();
        });

        if (that.canEdit) {
            // allow to import something new as include
            $(
                `<button class="btn btn-default" id="newInclude" title="include items from other project"> Include </button>`,
            )
                .click(function (event: JQueryEventObject) {
                    event.stopPropagation();
                    let forbiddenProjects: string[] = [];
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (that.mode == EImportMode.Include) {
                        forbiddenProjects = that.getForbiddenProjectsForIncludes();
                    }
                    const itemParentMode: ItemParentMode =
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.config.itemParentMode == "preserve" ? ItemParentMode.Preserve : ItemParentMode.Orphan;
                    that.showImportDialog([], forbiddenProjects, itemParentMode);
                    return false;
                })
                .appendTo($("<p>").appendTo(control));

            // Allow to import according to a saved plan.
            if (matrixSession.isSuperAdmin()) {
                $("<p>Upload an include plan").appendTo(control);
                $(`<input type="file">`)
                    .change(async function (event: JQueryEventObject) {
                        event.stopPropagation();
                        //
                        // Somehow upload a file.
                        //
                        if ("files" in event.target) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            const files: any = event.target.files;
                            if (files.length && files.length === 1) {
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                const file: any = files.item(0);
                                const text = await file.text();
                                await that.importInclude(text);
                            }
                        }
                        return false;
                    })
                    .appendTo($("<p>").appendTo(control));
            }
        } else {
            $(`<span class="readonlyComment" >you have no right to add/change the includes</span>`).appendTo(
                $("<p>").appendTo(control),
            );
        }
    }

    /** projects which cannot be included, are the ones without unique ids or projects from which something has been included before */
    protected getForbiddenProjectsForIncludes(switchFromProject?: string): string[] {
        // when switching from a project you cannot switch to it, hence it's forbidden
        let forbiddenProjects = switchFromProject ? [switchFromProject] : [];

        // now go through active includes, if you already included something from a project you cannot include a second time, hence it's forbidden
        for (let imp of this.importInfo.imports) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (!imp.deleteDate && imp.import == EImportMode.Include) {
                // it's a not deleted import
                forbiddenProjects.push(imp.sourceProject);
            }
        }

        // note: in theory there project should not appear twice in the list (unless someone changed with the unique server setting) and even if it should not matter:)
        return forbiddenProjects;
    }

    /** show all copies done in the past */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showCopies(control: JQuery) {
        let that = this;

        let parentFolderStructureInfo =
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.config.itemParentMode == "preserve"
                ? "Imported items <b>will include parent folder structure</b>."
                : "Imported items <b>will not include parent folder structure</b>.";

        $(
            `<p class="inlineHelp ">Copies are items copied from other projects. These items have different ids than in the original project. ${parentFolderStructureInfo} You can change this in the Configure Compose page.</p>`,
        ).appendTo(control);

        let anchor = $("<div class='importList'>").appendTo(control);

        $(`<div class='separator'><div><span>Copied from other projects</span></div></div>`).appendTo(anchor);

        // this will be removed if something was imported
        $(`<p class="nothingImported">nothing has been copied into this project</p>`).appendTo(anchor);

        // filter out everything which is not an include
        that.importInfo.imports = that.importInfo.imports.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (imp) => imp.import == EImportMode.Copy && imp.targetProject == matrixSession.getProject(),
        );

        // sort imports by date
        that.importInfo.imports.sort((a, b) => {
            return a.importDateIso < b.importDateIso ? -1 : 1;
        });

        for (let imp of that.importInfo.imports) {
            that.addCopyToList(imp, anchor);
        }

        app.waitForMainTree(() => {
            anchor.highlightReferences();
        });

        if (that.canEdit) {
            // allow to import something new as copy
            $(
                `<button class="btn btn-default" id="newCopy" title="copy items from other project"> Copy items into this project </button>`,
            )
                .click(function (event: JQueryEventObject) {
                    event.stopPropagation();
                    let forbiddenProjects =
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.mode == EImportMode.Include
                            ? that.importInfo.imports
                                  // TODO: MATRIX-7555: lint errors should be fixed for next line
                                  // eslint-disable-next-line
                                  .filter((imp) => !imp.deleteDate && imp.import == that.mode)
                                  .map((imp) => imp.sourceProject)
                            : [];
                    const itemParentMode: ItemParentMode =
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.config.itemParentMode == "preserve" ? ItemParentMode.Preserve : ItemParentMode.Orphan;
                    that.showImportDialog([], forbiddenProjects, itemParentMode);
                    return false;
                })
                .appendTo($("<p>").appendTo(control));
        } else {
            $(`<span class="readonlyComment" >you have no right to copy items in this project</span>`).appendTo(
                $("<p>").appendTo(control),
            );
        }
    }

    /** shows one line for previous imports */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected addIncludeToList(imp: IProjectImport, anchor: JQuery) {
        let that = this;

        $(".nothingImported").remove();
        let deletedProjects = (<IDeletedProjects>(
            matrixSession.getCustomerSettingJSON("deleted_projects", { deleted: [] })
        )).deleted;
        let visibleProjects = matrixSession.getProjectList(false).map((pl) => pl.shortLabel);

        // to handle legacy info in db
        if (!imp.importRoots) {
            imp.importRoots = ["&nbsp;"];
        }
        // see if this is an include which replaced other includes
        let deletedIncludes: IProjectImport[] = [];
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.mode == EImportMode.Include) {
            // find previous includes from the same project
            deletedIncludes = that.importInfo.imports.filter(
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (deletedInclude) => !!deletedInclude.deleteDate && deletedInclude.sourceProject == imp.sourceProject,
            );
        }
        // render include

        let date = imp.importDateUserFormat;
        let sourceProject = imp.sourceProject;
        let sourceIsDeleted = deletedProjects.includes(sourceProject);
        let sourceIsAccsible = visibleProjects.includes(sourceProject);

        let folders = imp.importRoots.join(",");

        let previous = deletedIncludes.length
            ? "Previous imports&#10;" + deletedIncludes.map((pi) => pi.importDateUserFormat).join("&#10;")
            : "import date";

        let container = $(`<div class="importLine">`).appendTo(anchor);
        container.append(
            `<b>${sourceProject}</b>${
                sourceIsDeleted
                    ? '<span style="color: red;"> - project was deleted</span>'
                    : sourceIsAccsible
                    ? ""
                    : '<span style="color: red;"> - you have no access to project</span>'
            }`,
        );
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        container.append(` ${imp.itemParentMode == ItemParentMode.Preserve ? "(preserves folder structure)" : ""} `);
        let right = $(`<span class="pull-right" style="text-align:right;">`).appendTo(container);
        right.append(`<div title='${previous}'>${date}</div>`);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (imp.import == EImportMode.Include && that.canEdit && imp.importHistoryId) {
            let actions = $(`<div>`).appendTo(right);
            // get original selection
            let selection = imp.sourceSelection.split(",");

            // let user delete
            $(`<a class="lineAction link" title="remove the included items">delete</a>`)
                .click(function (event: JQueryEventObject) {
                    event.stopPropagation();
                    that.deleteInclude(actions, imp);
                    return false;
                })
                .appendTo(actions);

            // let user select another branch and change selection
            $(`<a class="actionUpdate  link" title="switch to another project/branch">switch</a>`)
                .click(async (event: JQueryEventObject) => {
                    event.stopPropagation();
                    let confirmed = await that.confirmAnyChanges(imp, "Update Anyway");

                    if (confirmed) {
                        let forbiddenProjects = that.getForbiddenProjectsForIncludes(imp.sourceProject);

                        that.showImportDialog(
                            selection,
                            forbiddenProjects,
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            imp.itemParentMode,
                            imp.importHistoryId,
                            false,
                        );
                    }

                    return false;
                })
                .appendTo(actions);

            // let user update to latest and change selection - unless the project is deleted
            if (!sourceIsDeleted && sourceIsAccsible) {
                $(`<a class="actionUpdate link" title="update to latest / change items">update</a>`)
                    .click(async (event: JQueryEventObject) => {
                        event.stopPropagation();
                        let confirmed = await that.confirmAnyChanges(imp, "Update Anyway");

                        if (confirmed) {
                            that.showImportDialog(
                                selection,
                                [imp.sourceProject],
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                imp.itemParentMode,
                                imp.importHistoryId,
                                true,
                            );
                        }

                        return false;
                    })
                    .appendTo(actions);

                // Allow the user to export the current plan
                if (matrixSession.isSuperAdmin()) {
                    $(`<a class="actionUpdate link" title="export plan">export</a>`)
                        .click(async (event: JQueryEventObject) => {
                            event.stopPropagation();
                            await that.exportPlan(imp.sourceProject, selection);
                        })
                        .appendTo(actions);
                }
            }
        }

        container.append(`<div>${folders}</div>`);
    }

    /** shows one line for previous imports */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected addCopyToList(imp: IProjectImport, anchor: JQuery) {
        let that = this;

        $(".nothingImported").remove();

        let deletedProjects = (<IDeletedProjects>(
            matrixSession.getCustomerSettingJSON("deleted_projects", { deleted: [] })
        )).deleted;

        // to handle legacy info in db
        if (!imp.importRoots) {
            imp.importRoots = ["&nbsp;"];
        }

        // render copy

        let date = imp.importDateUserFormat;
        let sourceProject = imp.sourceProject;
        let sourceIsDeleted = deletedProjects.includes(sourceProject);

        let folders = imp.importRoots.join(",");

        let container = $(`<div class="importLine">`).appendTo(anchor);
        container.append(`<b>${sourceProject}</b>${sourceIsDeleted ? "<span  > - project was deleted</span>" : ""}`);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        container.append(` ${imp.itemParentMode == ItemParentMode.Preserve ? "(preserves folder structure)" : ""} `);
        let right = $(`<span class="pull-right" style="text-align:right;">`).appendTo(container);
        right.append(`<div title='import date'>${date}</div>`);

        container.append(`<div>${folders}</div>`);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected async ensureEntry(projectShortLabel: string, importMode: EImportMode, itemParentMode: ItemParentMode) {
        // First, save the ItemParentMode before reloading the page.
        let changed = false;
        let foundItem = this.itemParentModes.itemParentModes.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (ipm) => ipm.project == projectShortLabel && ipm.importMode == importMode,
        );
        if (foundItem.length > 0) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (foundItem[0].itemParentMode != itemParentMode) {
                changed = true;
                foundItem[0].itemParentMode = itemParentMode;
            }
        } else {
            changed = true;
            this.itemParentModes.itemParentModes.push({
                itemParentMode: itemParentMode,
                project: projectShortLabel,
                importMode: importMode,
            });
        }
        if (changed) {
            // Save this.
            await app.setSettingJSON(this.dashboardSettingsName, this.itemParentModes);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected fillMap(map: Map<string, string>, current: XRTrimFolder, path: string) {
        // Do we have an id for the current folder?
        const newPath = path + current.title;
        map.set(current.itemRef, newPath);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (current.isFolder != 0) {
            for (let child of current.itemList) {
                this.fillMap(map, child, newPath + "/");
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected async exportPlan(sourceProject: string, selection: string[]) {
        const that = this;

        // Let the user download a plan file.
        let plan: IncludePlan = {
            sourceProject: sourceProject,
            selectionPaths: [],
        };

        const toRemove = ml.UI.getSpinningWait(`Formatting include selection for source project ${sourceProject}...`);
        toRemove.appendTo($(".navbar-brand"));

        const result = await restConnection.getServer(sourceProject + "/tree");
        const tree = result as XRCategoryFull[];

        // Convert the tree into a map.
        let pathMap = new Map<string, string>();
        for (const t of tree) {
            that.fillMap(pathMap, t.folder, "/");
        }

        // Get the path for each id in selection, because this is how the plan importer will
        // find the items later!
        for (let sel of selection) {
            // Find the id (sel) in the pathMap.
            if (pathMap.has(sel)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                plan.selectionPaths.push(pathMap.get(sel));
            }
        }

        const output = JSON.stringify(plan, null, 2);
        const filename = `include-${sourceProject}.json`;
        this.download(filename, output);
        toRemove.remove();
        ml.UI.showSuccess("Downloaded export plan");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected download(filename: string, text: string) {
        // creating an invisible element
        let element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8, " + encodeURIComponent(text));
        element.setAttribute("download", filename);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showImportDialogSingle(selection: string[], refProject: string, itemParentMode: ItemParentMode) {
        let that = this;

        let st = new ItemSelectionTools();
        let projectShortLabel: string;

        // all projects but this
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let allProjects = matrixSession.getProjectList(false).filter((p) => p.shortLabel != matrixSession.getProject());

        let lockLabel = this.config.lockLabel;

        // all projects depending on update / include / copy ....
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let allowedProjects = allProjects.filter((p) => p.shortLabel == refProject);

        // don't allow the user to select items from other categories than exist in this project
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let allowedCategories = globalMatrix.ItemConfig.getCategories(true).filter((cat) => cat != "REPORT");

        let dialogOptions: IItemSelectDialogOptions = {
            selectMode: SelectMode.autoPrecise,
            linkTypes: [],
            selectionChange: function (newSelection: IReference[]) {
                if (newSelection.length > 0) {
                    // this comes back from server
                    let importList = newSelection.map((sel) => sel.to).join(",");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    matrixSession.getCommentAsync().done((comment: string) => {
                        let importParams: Record<string, string> = {
                            mode: that.mode,
                            sourceProject: projectShortLabel,
                            sourceSelection: importList,
                            itemParentMode: itemParentMode,
                            reason: encodeURIComponent(comment),
                        };
                        if (lockLabel) {
                            importParams["lockLabel"] = lockLabel;
                        }
                        that.showWait("updating imports");
                        restConnection
                            .postProject(`moduleimport`, importParams)
                            .done(async (importResult) => {
                                await that.ensureEntry(projectShortLabel, that.mode, itemParentMode);
                                await app.loadTreeWithSearches(app.getCurrentItemId());
                                that.showContent();
                            })
                            .fail((jqxhr: IJcxhr, textStatus: string, error: string) => {
                                // define known messages in displayError
                                let inTheWay = `[EServer/Unknown exception]|Can't do the import, some items are in the way (`;
                                let alreadyExists = "already exists in target";
                                let conflictingItems = "Conflicting items in import: ";

                                let displayError =
                                    jqxhr && jqxhr.responseJSON && jqxhr.responseJSON.displayError
                                        ? jqxhr.responseJSON.displayError
                                        : "";
                                // extract other information from
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (displayError.indexOf(conflictingItems) != -1) {
                                    let items = displayError.substring(displayError.lastIndexOf(" ") + 1);
                                    ml.UI.showError(
                                        that.mode + " failed",
                                        "Some items are already in this project: " + items,
                                    );
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                } else if (displayError.indexOf(inTheWay) != -1) {
                                    let items = displayError.replace(inTheWay, "").replace(")", "");
                                    ml.UI.showError(
                                        that.mode + " failed",
                                        "Some items are already in this project: " + items,
                                    );
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                } else if (displayError.indexOf(alreadyExists) != -1) {
                                    ml.UI.showError(that.mode + " failed", "Some items are already in this project.");
                                } else {
                                    console.log(jqxhr);
                                    console.log(textStatus);
                                    console.log(error);
                                    ml.UI.showError(that.mode + " failed", displayError ? displayError : textStatus);
                                }
                                that.showContent();
                            });
                    });
                }
            },
            crossProjectInit: function (psl: string) {
                projectShortLabel = psl;
            },
            getSelectedItems: async function () {
                return selection.map((s) => {
                    return { to: s, title: "" };
                });
            },
            allowAutoDownlinkSelection: true,
            allowedProjects: allowedProjects,
            allowedCategories: allowedCategories,
        };
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (allowedProjects.length == 1) {
            dialogOptions["crossProjectProject"] = allowedProjects[0].shortLabel;
        }
        // let user select the items
        st.showCrossProjectDialog(dialogOptions);
    }

    /** show dialog to select items to import / update
     * refProjects: a list of possible or required projects
     * isUpdate: is set to true the refProjects are required, otherwise the only possible ones (without other import)
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showImportDialog(
        selection: string[],
        refProjects: string[],
        itemParentMode: ItemParentMode,
        replaceImport?: string,
        isUpdate?: boolean,
    ) {
        let that = this;

        let st = new ItemSelectionTools();
        let projectShortLabel: string;

        // all projects but this
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let allProjects = matrixSession.getProjectList(false).filter((p) => p.shortLabel != matrixSession.getProject());

        let lockLabel = this.config.lockLabel;

        // all projects depending on update / include / copy ....
        let allowedProjects = isUpdate
            ? allProjects.filter((p) => refProjects.includes(p.shortLabel)) // limit by selection
            : allProjects.filter((p) => !refProjects.includes(p.shortLabel)); // anything but selection

        // don't allow the user to select items from other categories than exist in this project
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let allowedCategories = globalMatrix.ItemConfig.getCategories(true).filter((cat) => cat != "REPORT");

        let dialogOptions: IItemSelectDialogOptions = {
            selectMode: SelectMode.autoPrecise,
            linkTypes: [],
            selectionChange: function (newSelection: IReference[]) {
                if (newSelection.length > 0) {
                    // this comes back from server
                    let importList = newSelection.map((sel) => sel.to).join(",");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    matrixSession.getCommentAsync().done((comment: string) => {
                        let importParams: Record<string, string> = {
                            mode: that.mode,
                            sourceProject: projectShortLabel,
                            sourceSelection: importList,
                            itemParentMode: itemParentMode,
                            reason: encodeURIComponent(comment),
                        };
                        if (replaceImport) {
                            importParams["update"] = isUpdate ? "update" : "rebase";
                            if (!isUpdate) {
                                importParams["oldSourceProject"] = refProjects[0];
                            }
                        }
                        if (lockLabel) {
                            importParams["lockLabel"] = lockLabel;
                        }
                        that.showWait("updating imports");
                        restConnection
                            .postProject(`moduleimport`, importParams)
                            .done(async (importResult) => {
                                await that.ensureEntry(projectShortLabel, that.mode, itemParentMode);
                                await app.loadTreeWithSearches(app.getCurrentItemId());
                                that.showContent();
                            })
                            .fail((jqxhr: IJcxhr, textStatus: string, error: string) => {
                                // define known messages in displayError
                                let inTheWay = `[EServer/Unknown exception]|Can't do the import, some items are in the way (`;
                                let alreadyExists = "already exists in target";
                                let conflictingItems = "Conflicting items in import: ";

                                let displayError =
                                    jqxhr && jqxhr.responseJSON && jqxhr.responseJSON.displayError
                                        ? jqxhr.responseJSON.displayError
                                        : "";
                                // extract other information from
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (displayError.indexOf(conflictingItems) != -1) {
                                    let items = displayError.substring(displayError.lastIndexOf(" ") + 1);
                                    ml.UI.showError(
                                        that.mode + " failed",
                                        "Some items are already in this project: " + items,
                                    );
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                } else if (displayError.indexOf(inTheWay) != -1) {
                                    let items = displayError.replace(inTheWay, "").replace(")", "");
                                    ml.UI.showError(
                                        that.mode + " failed",
                                        "Some items are already in this project: " + items,
                                    );
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                } else if (displayError.indexOf(alreadyExists) != -1) {
                                    ml.UI.showError(that.mode + " failed", "Some items are already in this project.");
                                } else {
                                    console.log(jqxhr);
                                    console.log(textStatus);
                                    console.log(error);
                                    ml.UI.showError(that.mode + " failed", displayError ? displayError : textStatus);
                                }
                                that.showContent();
                            });
                    });
                }
            },
            crossProjectInit: function (psl: string) {
                projectShortLabel = psl;
            },
            getSelectedItems: async function () {
                return selection.map((s) => {
                    return { to: s, title: "" };
                });
            },
            allowAutoDownlinkSelection: true,
            allowedProjects: allowedProjects,
            allowedCategories: allowedCategories,
        };
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (allowedProjects.length == 1) {
            dialogOptions["crossProjectProject"] = allowedProjects[0].shortLabel;
        }
        // let user select the items
        st.showCrossProjectDialog(dialogOptions);
    }

    /** allow users to delete included items */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected deleteInclude(line: JQuery, imp: IProjectImport) {
        let that = this;
        ml.UI.showConfirm(
            -1,
            { title: "Delete all included items?", ok: "Delete Included Items", nok: "Cancel" },
            async () => {
                let confirmed = await that.confirmAnyChanges(imp, "Delete Anyway");

                if (confirmed) {
                    that.executeDelete(line, imp);
                }
            },
            () => {},
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected executeDelete(line: JQuery, imp: IProjectImport) {
        let that = this;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        matrixSession.getCommentAsync().done((comment: string) => {
            that.showWait("deleting import");
            restConnection
                .deleteProjectAsync("import/" + imp.importHistoryId, { reason: comment }, false)
                .done(() => {
                    $(".lineAction", line).remove();
                    $(".deleteMsg", line).html("deleted");
                    app.loadTreeWithSearches(app.getCurrentItemId()).done(() => {
                        that.showContent();
                    });
                })
                .fail((jqxhr: IJcxhr, textStatus: string, error: string) => {
                    console.log(jqxhr);
                    console.log(textStatus);
                    console.log(error);
                    ml.UI.showError("Delete failed", textStatus);
                    that.showContent();
                });
        });
    }

    // if any of the included items changed, ask for confirmation to replace/delete them
    protected confirmAnyChanges(imp: IProjectImport, confirmation: string): JQueryDeferred<boolean> {
        let res: JQueryDeferred<boolean> = $.Deferred();
        let ms = new Date().getTime() - new Date(imp.importDateIso).getTime();
        let mi = Math.floor(ms / 60000);
        // make sure you can delete if someone deleted a folder
        let existingFolders = imp.importRoots.filter((folder) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return app.getItemTitle(folder) != "";
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (existingFolders.length == 0) {
            res.resolve(true);
            return res;
        }
        let search =
            "mrql:" + "(" + existingFolders.map((tlf) => "folderm=" + tlf).join(" or ") + ") and updated<" + mi + "M";
        app.searchAsyncMinimalOutput(search)
            .done((results) => {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (results.length) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let message = `<p>Some items changed!<p><ul style="text-align:left;height: 200px;overflow-y: auto;">${results
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        .map((cgd) => "<li>" + ml.Item.renderLink(cgd, null, true).html() + "</li>")
                        .join("")}</ul>`;
                    ml.UI.showConfirm(
                        -1,
                        { title: message, ok: confirmation, nok: "Cancel" },
                        () => {
                            res.resolve(true);
                        },
                        () => {
                            res.resolve(false);
                        },
                    );
                } else {
                    // no changes
                    res.resolve(true);
                }
            })
            .fail(() => {
                // maybe a folder was deleted
                res.resolve(false);
            });

        return res;
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    plugins.register(new ComponentImportDashboards("INCLUDES", "Includes", EImportMode.Include));
    plugins.register(new ComponentImportDashboards("COPIES", "Copies", EImportMode.Copy));
}
