import { IDataStorage } from "../globals";

export { ProjectStorageAdmin };

class ProjectStorageAdmin implements IDataStorage {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setItem(itemKey: string, itemVal: string) {}

    getItem(itemKey: string): string {
        return "";
    }
    getItemDefault(itemKey: string, defaultValue: string): string {
        return "";
    }
}
