import { JsonEditorValidation } from "../../../jsonvalidation/JsonValidator";
import { IFieldDescription } from "../../ProjectSettings";
import { plugins } from "./PluginManager";
export { FieldDescriptions };

class FieldDescriptions {
    public static Field_sourceref = "sourceref";
    public static Field_markAsTemplate = "markAsTemplate";
    public static Field_dhf = "dhf";
    public static Field_docFilter = "docFilter";

    // normal fields
    public static Field_richtext = "richtext";
    public static Field_text = "text";
    public static Field_section = "section";
    public static Field_fileManager = "fileManager";
    public static Field_tasksControl = "tasksControl";
    public static Field_textline = "textline";
    public static Field_user = "user";
    public static Field_date = "date";
    public static Field_dropdown = "dropdown";
    public static Field_links = "links";
    public static Field_uplinkinfo = "uplinkinfo";
    public static Field_crosslinks = "crosslinks";
    public static Field_risk2 = "risk2";
    public static Field_checkbox = "checkbox";
    public static Field_gateControl = "gateControl";
    public static Field_colorPicker = "colorPicker";
    public static Field_steplist = "steplist";

    // toolbar
    public static Field_labels = "labels";
    public static Field_workflow = "workflow";

    // tests and xtcs
    public static Field_test_steps = "test_steps";
    public static Field_test_steps_result = "test_steps_result";
    public static Field_test_result = "test_result";

    // agile sync
    public static Field_syncStatus = "syncStatus";
    public static Field_syncSourceInfo = "syncSourceInfo";

    // beta should not be used really
    public static Field_report = "report";
    public static Field_cascadingSelect = "cascadingSelect";
    public static Field_hyperlink = "hyperlink";

    // technical - these need to be added once when creating a new DOC category or something special like this
    public static Field_reportId = "reportId";
    public static Field_filter_file = "filter_file";
    public static Field_signature = "signature";
    public static Field_signatureControl = "signatureControl";
    public static Field_signCache = "signCache";
    static Field_publishedItemList = "publishedItemList";
    static Field_publishedFilesList = "publishedFilesList";
    static Field_publishedContent = "publishedContent";
    static Field_publishedTitle = "publishedTitle";
    static Field_docTraining = "docTraining";
    static Field_docReview = "docReview";
    static Field_risk = "risk";
    static Field_htmlForm = "htmlForm";
    static Field_hidden = "hidden";
    static Field_dummy = "dummy";
    static Field_guid = "guid";
    static Field_oid = "oid";
    static Field_versionLive = "versionLive";
    static Field_version = "version";
    static Field_currentVersion = "currentVersion";
    static Field_riskFolder = "riskFolder";
    static Field_reviewControl = "reviewControl";

    public static findById(idToFind: string): IFieldDescription | undefined {
        let returnedValue = undefined;
        let result = this.descriptions.filter((description) => description.id === idToFind);

        returnedValue = result.length === 1 ? result[0] : undefined;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (returnedValue == undefined) {
            // try to find it in the plugins, if they exist
            let descriptionFromPlugins = plugins?.getFieldConfigOptions() ?? [];
            result = descriptionFromPlugins.filter((description) => description.id === idToFind);
            returnedValue = result.length === 1 ? result[0] : undefined;
        }
        return returnedValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static appendFieldDescriptions(newFields: IFieldDescription[]) {
        for (let fco of newFields) {
            let result = this.descriptions.filter((description) => description.id === fco.id);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result.length == 0) {
                this.descriptions.push(fco);
            }
        }
    }

    public static get(): IFieldDescription[] {
        return FieldDescriptions.descriptions;
    }

    // A [fieldId]: JsonValidationFunction map built up at runtime.
    // We can't build this at compile time because validation functions are high-level entities.
    private static validationFunctions = {};

    private static descriptions: IFieldDescription[] = [
        // [ "richtext",  "user" , "date", "text", "textline" ,"test_result",
        //         "crosslinks", "gateControl","fileManager", "reviewControl"];
        // docs and sign
        {
            id: FieldDescriptions.Field_sourceref,
            capabilities: {
                canImportedFromExcel: true,
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Source (Original Document) [sourceref]",
            class: "docsign",
            help: "keeps a reference to original document when copying and pasting",
        },
        {
            id: FieldDescriptions.Field_markAsTemplate,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Mark as template [markAsTemplate]",
            class: "docsign",
            help: "allows to define DOC templates which can be used in other projects",
        },
        {
            id: FieldDescriptions.Field_dhf,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "DFH field  [dhf]",
            class: "docs",
            help: "for documents only: holds a section in a document",
        },
        {
            id: FieldDescriptions.Field_docFilter,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Label Filter in DOCs [docFilter]",
            class: "docs",
            help: "a special field for Document categories to filter documents by label",
        },

        // normal fields
        {
            id: FieldDescriptions.Field_richtext,
            capabilities: {
                canImportedFromExcel: true,
                canBeUnsafe: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Rich Text Control [richtext]",
            class: "all",
            help: "a rich text editor field (with formatting)",
        },
        {
            id: FieldDescriptions.Field_text,
            capabilities: {
                canImportedFromExcel: true,
                canBeUnsafe: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Plain Text Control [text]",
            class: "all",
            help: "a text editor field (no formatting)",
        },
        {
            id: FieldDescriptions.Field_section,
            capabilities: {
                canBeUsedInDocs: false,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: true,
                canBeReadonly: false,
                canRequireContent: false,
            },
            label: "Section with explanation [section]",
            class: "all",
            help: "a title with some explanation to separate fields",
        },
        {
            id: FieldDescriptions.Field_fileManager,
            capabilities: {
                canImportedFromExcel: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
            },
            label: "File Manager Control [fileManager]",
            class: "all",
            help: "a control which allows to attach files by browsing or drag and drop",
        },
        {
            id: FieldDescriptions.Field_tasksControl,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Tasks Control [tasksControl]",
            class: "all",
            help: "a field which shows links to external systems, like JIRA, github etc. (eg extensions folder)",
        },
        {
            id: FieldDescriptions.Field_textline,
            capabilities: {
                canImportedFromExcel: true,
                canBeUnsafe: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Text Line Control [textline]",
            class: "all",
            help: "a field for one line of text",
        },
        {
            id: FieldDescriptions.Field_user,
            capabilities: {
                canImportedFromExcel: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "User Select Control [user]",
            class: "all",
            help: "a drop down to select a user in the project",
        },
        {
            id: FieldDescriptions.Field_date,
            capabilities: {
                canImportedFromExcel: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Date Select Control [date]",
            class: "all",
            help: "a field to select a date",
        },
        {
            id: FieldDescriptions.Field_dropdown,
            capabilities: {
                canBeUnsafe: true,
                needsConfiguration: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Drop Down Control [dropdown]",
            class: "all",
            help: "a drop-down selection field",
        },
        {
            id: FieldDescriptions.Field_links,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Down Traces Control [links]",
            class: "all",
            help: "a field to show and edit down traces as defined in the traceability configuration",
        },
        {
            id: FieldDescriptions.Field_uplinkinfo,
            capabilities: {
                schema: "IUpLinkDetails",
                needsConfiguration: true,
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Uplink information [uplinkinfo]",
            class: "all",
            help: "a field to show information about up traces in the user interface",
        },
        {
            id: FieldDescriptions.Field_crosslinks,
            capabilities: {
                canImportedFromExcel: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Cross Project Link Control [crosslinks]",
            class: "all",
            help: "a field to add links to other matrix projects",
        },
        {
            id: FieldDescriptions.Field_risk2,
            capabilities: {
                canBeUnsafe: true,
                onlyOne: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Risk Control [risk2]",
            class: "all",
            help: "a field rendering an FMEA-type risk field",
        },
        {
            id: FieldDescriptions.Field_checkbox,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
            },
            label: "Checkbox Control [checkbox]",
            class: "all",
            help: "a checkbox field",
        },
        {
            id: FieldDescriptions.Field_hyperlink,
            capabilities: {
                canImportedFromExcel: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Hyperlink Control [hyperlink]",
            class: "all",
            help: "a field holding a hyperlink (url)",
        },

        {
            id: FieldDescriptions.Field_gateControl,
            capabilities: {
                canImportedFromExcel: true,
                canBeUnsafe: true,
                needsConfiguration: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: false,
                canBeReadonly: true,
                schema: "IGate",
            },
            label: "Gate Control [gateControl]",
            class: "all",
            help: "a quality gate field",
        },

        {
            id: FieldDescriptions.Field_colorPicker,
            capabilities: {
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: true,
                canBePublished: true,
                canBeReadonly: true,
                canRequireContent: true,
            },
            label: "Color picker [colorPicker]",
            class: "super",
            help: "a color picker field",
        },

        {
            id: FieldDescriptions.Field_steplist,
            capabilities: {
                needsConfiguration: true,
                canBeUsedInDocs: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: true,
                canBeReadonly: true,
                canBeUnsafe: true,
                schema: "IFieldParameter",
            },
            label: "Table Control [steplist]",
            class: "all",
            help: "a field rendered as a table",
        },

        // toolbar
        {
            id: FieldDescriptions.Field_labels,
            capabilities: {
                onlyOne: true,
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Labels [labels]",
            class: "toolbar",
            help: "shows defined labels in the item's tool bar",
        },
        {
            id: FieldDescriptions.Field_workflow,
            capabilities: {
                onlyOne: true,
                canBeUsedInDocs: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "One2One Issue Mapping [workflow]",
            class: "toolbar",
            help: "a field shown in the item's tool bar showing the status of an external ticket (i.e. jira)",
        },

        // tests and xtcs
        {
            id: FieldDescriptions.Field_test_steps,
            capabilities: {
                canBeUnsafe: true,
                onlyOne: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Test Table [test_steps]",
            class: "tests",
            help: "a special table control to define tests (see test configuration)",
        },
        {
            id: FieldDescriptions.Field_test_steps_result,
            capabilities: {
                canBeUnsafe: true,
                onlyOne: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Test Result Table [test_steps_result]",
            class: "xtcs",
            help: "a special table control to define tests with results (see test configuration)",
        },
        {
            id: FieldDescriptions.Field_test_result,
            capabilities: {
                canImportedFromExcel: true,
                onlyOne: true,
                canHideInDoc: true,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Test Result Summary [test_result]",
            class: "xtcs",
            help: "a special field to compute or specify the overall test result",
        },

        // agile sync
        {
            id: FieldDescriptions.Field_syncStatus,
            capabilities: {
                onlyOne: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "SYNC Status [syncStatus]",
            class: "sync",
            help: "control to hold the information about synced external items ",
        },
        {
            id: FieldDescriptions.Field_syncSourceInfo,
            capabilities: {
                onlyOne: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "SYNC Source Info [syncSourceInfo]",
            class: "sync",
            help: "a control to show control syncing of external items",
        },

        // beta should not be used really
        {
            id: FieldDescriptions.Field_report,
            capabilities: { canHideInDoc: false, canBeXtcPreset: false, canBePublished: false, canBeReadonly: true },
            label: "Embedded Report [report]",
            class: "beta",
            help: "allows to embed a standard report",
        },
        {
            id: FieldDescriptions.Field_cascadingSelect,
            capabilities: { canHideInDoc: false, canBeXtcPreset: true, canBePublished: true, canBeReadonly: true },
            label: "Cascading Select [cascadingSelect]",
            class: "beta",
            help: "a field to select values from hierachies of drop downs",
        },

        // technical - these need to be added once when creating a new DOC category or something special like this
        {
            id: FieldDescriptions.Field_reportId,
            capabilities: {
                onlyOne: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "report ID [reportId]",
            class: "super",
            help: "contains the id of the xslt template for reports",
        },
        {
            id: FieldDescriptions.Field_filter_file,
            capabilities: {
                onlyOne: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "filter_file [filter_file]",
            class: "super",
            help: "internal field required for documents",
        },
        {
            id: FieldDescriptions.Field_signature,
            capabilities: {
                onlyOne: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Signature Table [signature]",
            class: "super",
            help: "field showing signature in documents",
        },
        {
            id: FieldDescriptions.Field_signatureControl,
            capabilities: {
                onlyOne: true,
                canHideInDoc: false,
                canBeXtcPreset: false,
                canBePublished: false,
                canBeReadonly: true,
            },
            label: "Signature Password Field [signatureControl]",
            class: "super",
            help: "field with control for signatures",
        },
    ];

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static setValidationFunction(fieldId: string, validator: JsonEditorValidation) {
        let fieldDesc = FieldDescriptions.findById(fieldId);
        if (!fieldDesc) {
            throw new Error(`No such field type ${fieldId} registered`);
        }
        fieldDesc.capabilities.validationFunction = validator;
    }
}
