import {
    BranchingConstants,
    BranchingHelper,
    IPlugin,
    IPluginPanelOptions,
    IProjectPageParam,
    plugins,
} from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { IBaseControlOptions } from "../../common/UI/Controls/BaseControl";
import { app, ControlState, globalMatrix, IBooleanMap, IItem, matrixSession, restConnection } from "../../globals";

import { IDropdownOption } from "../../ProjectSettings";
import {
    XRGetProject_ProjectInfo_ProjectInfo,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRMergeAction,
    XRMergeEntry,
    XRMergeHistory,
    XRMergeInfo,
    XRMergeItem,
    XRMergeItemLink,
} from "../../RestResult";
import {
    EItemChangeState,
    EMergeActionChoice,
    EMergeChangeStatus,
    EMergeType,
    IBranchInfo,
    IFieldMergeMapping,
    ILastMerges,
    IMergeCommand,
    IMergeLookup,
} from "./../../common/businesslogic/index";
import { ImportMergeBase } from "./ImportMergeBase";

interface MergeCacheSetting {
    saved: {
        mainline: string;
        branch: string;
        merges: Record<string, string>;
    }[];
}

export class BranchBase extends ImportMergeBase implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected pageRoot: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected pageID: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected pageTitle: string;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected mergeType: EMergeType;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_na: string; // don't even show option
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_ignore: string; // don't merge a difference from the branch to the mainline
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_add_restore: string; // add an item from the branch to the mainline. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_add_restore_now: string; // add an item from the branch to the mainline. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_replace: string; // update the mainline item to match the branch
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_restore_replace: string; // restore and update the mainline item to match the branch
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_delete: string; // delete the mainline item to match the delete in the branch

    // misc texted explaining the situation
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_bothChanged: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_changedInBranchOnly: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_changedInMainlineOnly: string;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_explain_merge_push: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_explain_merge_push_help: string;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_links_added: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_links_removed: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected msg_links_not_in_other: string;

    // ****************************************
    // standard plugin interface
    // ****************************************
    public isDefault = true;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private branchProject: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private branchInfo: IBranchInfo;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastMerges: ILastMerges; // this is used to record timing of merge actions.. stored in mainline

    // the basic data

    private mainlineBaseItemMap: IMergeLookup = {};
    private mainlineNowItemMap: IMergeLookup = {};
    private branchBaseItemMap: IMergeLookup = {};
    private branchNowItemMap: IMergeLookup = {};

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private branchBaseItems: string[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private branchNowItems: string[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mainBaseItems: string[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mainNowItems: string[];

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mergeLineCount: number; // number of items requiring a decision to take
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mergeLineNoAction: boolean; // nothing really to do (but we still want to know, to see if we can mark the main and branch as sync)

    // the result of the user choice
    private mergeOptions: IMergeCommand = {
        copy: [],
        conflicted: [],
        delete: [],
        move: [],
        add_links: [],
        remove_links: [],
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(_item: IItem, _jui: JQuery) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings(serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) {}

    supportsControl(fieldType: string): boolean {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createControl(ctrl: JQuery, options: IBaseControlOptions) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {
        // get info about which projects have been merged back into this project
        this.lastMerges = <ILastMerges>globalMatrix.ItemConfig.getSettingJSON("lastMerges");
        if (!this.lastMerges) {
            this.lastMerges = {};
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isEnabled() {
        return true; //matrixSession.isAdmin();
    }
    // add one new project page to  the tree
    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];

            if (that.isEnabled()) {
                pages.push({
                    id: this.pageID,
                    folder: this.pageRoot,
                    icon: "fal fa-code-merge",
                    title: this.pageTitle,
                    order: 2000,
                    usesFilters: false,
                    render: (options: IPluginPanelOptions) => {
                        that.showPage(options.control);
                    },
                });
            }
            resolve(pages);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showPage(control: JQuery) {}

    // **************************************************************************************
    // show merge history
    // **************************************************************************************

    // show the merge history from this project
    protected showBranchHistory(container: JQuery): void {
        let that = this;

        container.append(ml.UI.getPageTitle("Branch History"));

        let control = $('<div class="panel-body-v-scroll fillHeight panel-default"style="padding:12px;"> ').appendTo(
            container,
        );

        const project = matrixSession.getProject();

        if (project) {
            let branchesOfThisProject = matrixSession.getBranches(project, "").map((info) => info.branch);
            restConnection.getProject("mergehistory").done((history) => {
                $("<h2>Changed in this project</h2>").appendTo(control);
                let entries = (history as XRMergeHistory).entries.filter(
                    (entry) => entry.mainlineProject === matrixSession.getProject(),
                );
                if (entries.length) {
                    for (let merge of entries.reverse()) {
                        let isFromBranch = branchesOfThisProject.indexOf(merge.branchProject) !== -1;
                        let explain = isFromBranch ? "merged" : "pushed";
                        $("<h3>")
                            .appendTo(control)
                            .html(`${merge.dateUser}: ${merge.user} ${explain} from ${merge.branchProject}`);
                        $("<p class='inlineHelp'>").appendTo(control).html(`Comment: ${merge.comments}`);
                        that.showMergeDetails(control, merge, isFromBranch);
                    }
                } else {
                    $("<p>nothing has been merged/pushed into this project</p>").appendTo(control);
                }

                $("<h2>Updated in other projects</h2>").appendTo(control);
                entries = (history as XRMergeHistory).entries.filter(
                    (entry) => entry.branchProject === matrixSession.getProject(),
                );
                if (entries.length) {
                    for (let merge of entries.reverse()) {
                        let isToBranch = branchesOfThisProject.indexOf(merge.mainlineProject) !== -1;
                        let explain = isToBranch ? "pushed" : "merged";
                        $("<h3>")
                            .appendTo(control)
                            .html(`${merge.dateUser}: ${merge.user} ${explain} into ${merge.mainlineProject}`);
                        $("<p class='inlineHelp'>").appendTo(control).html(`Comment: ${merge.comments}`);
                        let ul = $("<ul>").appendTo(control);
                        for (let detail of merge.details) {
                            that.addMergeDetail(ul, detail, merge.mainlineProject, !isToBranch);
                        }
                    }
                } else {
                    $("<p>nothing has been merged from this project</p>").appendTo(control);
                }

                /**********************************************
                 *  show smart links
                 **********************************************/
                app.waitForMainTree(() => {
                    control.highlightReferences();
                });
            });
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showMergeDetails(control: JQuery, entry: XRMergeEntry, isFromBranch: boolean) {
        let ul = $("<ul>").appendTo(control);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!entry.details || entry.details.length == 0) {
            $("<li>").appendTo(ul).html(`No changes done during this merge.`);
            return;
        }
        for (let detail of entry.details) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.addMergeDetail(ul, detail, null, isFromBranch);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addMergeDetail(ul: JQuery, detail: XRMergeAction, targetProject: string, isFromBranch: boolean) {
        BranchingHelper.addMergeDetail(ul, detail, targetProject, isFromBranch);
    }

    // **************************************************************************************
    // merge wizard ui - first step - allow user to select branch
    // **************************************************************************************

    // first "step" allow user to select a project
    protected wizardShowBranchSelect(container: JQuery): void {
        let that = this;

        container.append(ml.UI.getPageTitle(this.pageTitle));

        let control = $('<div class="panel-body-v-scroll fillHeight panel-default" style="padding:12px;">').appendTo(
            container,
        );

        control.html("<h2>" + that.msg_explain_merge_push + "</h2>");

        const project = matrixSession.getProject();

        if (project) {
            // get other project (need write access to change merge date)
            let branches = matrixSession.getBranches(project, "").map((info) => info.branch);

            let writeProjects: IDropdownOption[] = matrixSession
                .getProjectList(true)
                .filter(function (project) {
                    return branches.indexOf(project.shortLabel) !== -1;
                })
                .sort(function (a, b) {
                    if (that.lastMerges[a.shortLabel] && !that.lastMerges[b.shortLabel]) {
                        return -1;
                    }
                    if (that.lastMerges[b.shortLabel] && !that.lastMerges[a.shortLabel]) {
                        return 1;
                    }
                    return a.shortLabel + " " + a.label < b.shortLabel + " " + b.label ? -1 : 1;
                })
                .map(function (project) {
                    return {
                        id: project.shortLabel,
                        label: project.shortLabel + " - " + project.label,
                        class: that.lastMerges[project.shortLabel] ? "merged" : "other",
                    };
                });

            let select = $("<div>")
                .appendTo(control)
                .mxDropdown({
                    controlState: ControlState.FormEdit,
                    canEdit: true,
                    help: "Select Project",
                    parameter: {
                        inlineHelp: that.msg_explain_merge_push_help,
                        placeholder: "Select project",
                        maxItems: 1,
                        options: writeProjects,
                        groups: [
                            { value: "merged", label: "previous" },
                            { value: "other", label: "other" },
                        ],
                        create: false,
                        sort: false,
                        splitHuman: false,
                    },
                    valueChanged: async function () {
                        if (await select.getController().getValueAsync()) {
                            that.mergeSelect(await select.getController().getValueAsync(), control).done(() => {
                                that.wizardContentChanges(control);
                            });
                        }
                    },
                });
        }
    }

    // **************************************************************************************
    // merge wizard ui - second step - allow user to select content changes to merge
    // **************************************************************************************

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private isMerge(entry: XRMergeEntry) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return entry.mainlineProject == matrixSession.getProject() && entry.branchProject == this.branchProject;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private isPush(entry: XRMergeEntry) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return entry.branchProject == matrixSession.getProject() && entry.mainlineProject == this.branchProject;
    }
    // second "step" get deltas in each project
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private wizardContentChanges(control: JQuery) {
        let that = this;
        control.html("");

        let baseDateUser = ml.UI.DateTime.renderHumanDate(new Date(that.branchInfo.branchDate));

        // show branch info
        let branchDetails = $("<div class='mergeInfoBox'>").appendTo(control);
        $("<p>")
            .appendTo(branchDetails)
            .html(
                `<b>${this.branchProject}</b> was branched off on <b>${baseDateUser}</b> with tag ${that.branchInfo.branchTag}`,
            );

        restConnection.getProject("mergehistory").done((history) => {
            let entriesPush = (history as XRMergeHistory).entries.filter((entry) => that.isPush(entry));
            let entriesMerge = (history as XRMergeHistory).entries.filter((entry) => that.isMerge(entry));

            let selectedDate = that.branchInfo.branchDate;
            let dateOptions = [{ id: that.branchInfo.branchDate, label: "branch creation at " + baseDateUser }];

            if (entriesPush.length) {
                // there was at least one push
                let lastPushDate = entriesPush[entriesPush.length - 1].date;
                let lastPushDateHuman = ml.UI.DateTime.renderHumanDate(new Date(lastPushDate));
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.mergeType == EMergeType.push) {
                    // this is a push and it was pushed before: remove the branch creation
                    dateOptions.splice(0, 1);
                    selectedDate = lastPushDate;
                }
                dateOptions.push({ id: lastPushDate, label: "last update of branch: " + lastPushDateHuman });
                $("<p>")
                    .appendTo(branchDetails)
                    .html("Branch was last updated from mainline on " + lastPushDateHuman);
            } else {
                // never
                $("<p>").appendTo(branchDetails).html("Branch was never updated from mainline");
            }

            if (entriesMerge.length) {
                // there was at least one push
                let lastMergeDate = entriesMerge[entriesMerge.length - 1].date;
                let lastMergeDateHuman = ml.UI.DateTime.renderHumanDate(new Date(lastMergeDate));
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.mergeType == EMergeType.merge) {
                    // this is a merge  and it was merged before: remove the branch creation
                    dateOptions.splice(0, 1);
                    selectedDate = lastMergeDate;
                }
                dateOptions.push({ id: lastMergeDate, label: "last merge into main " + lastMergeDateHuman });
                $("<p>")
                    .appendTo(branchDetails)
                    .html("Mainline was last updated from branch on " + lastMergeDateHuman);
            } else {
                // never merged/pushed
                $("<p>").appendTo(branchDetails).html("Mainline was never updated from branch");
            }

            if (!that.isBranchMaster()) {
                $("<p>")
                    .appendTo(branchDetails)
                    .html(
                        `<span style='color:red'>You have no rights to ${
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            that.mergeType == EMergeType.merge ? "merge the branch back" : "push into branch"
                        }.</span>`,
                    );
            }

            if (dateOptions.length < 2) {
                that.showChanges(selectedDate, control);
                return;
            }
            let selector = $("<div>")
                .appendTo(control)
                .mxDropdown({
                    controlState: ControlState.FormEdit,
                    canEdit: true,
                    help: "Select Base Date",
                    fieldValue: selectedDate,
                    parameter: {
                        inlineHelp: "Show changes after the selected date",
                        placeholder: "Select Date",
                        maxItems: 1,
                        options: dateOptions,
                        create: false,
                        sort: false,
                        splitHuman: false,
                    },
                    valueChanged: async function () {
                        if (await selector.getController().getValueAsync()) {
                            selectedDate = await selector.getController().getValueAsync();
                        }
                    },
                });

            let nextButton = $("<button id='mergeSelectedBase' class='btn btn-success mergeWizardButton'>Next</button>")
                .click(function () {
                    nextButton.remove();
                    selector.remove();
                    that.showChanges(selectedDate, control);
                })
                .appendTo(control);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showChanges(selectedDate: string, control: JQuery) {
        let that = this;

        let gettingInfo = ml.UI.getSpinningWait("getting info from branch");
        $("<div id='mergeTasks'>").appendTo(control).append(gettingInfo);

        // get changes for branch and mainline
        let ignoreCategories = ["FOLDER", "REPORT"];
        if (that.branchInfo && that.branchInfo.dontMerge) {
            ignoreCategories = ignoreCategories.concat(that.branchInfo.dontMerge);
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let push = that.mergeType == EMergeType.push ? "push=1&" : "";
        let baseLine = encodeURIComponent(new Date(selectedDate).toISOString());

        restConnection
            .getServer(
                this.branchProject +
                    "/mergeinfo?" +
                    push +
                    "fromDate=" +
                    baseLine +
                    "&excludeCategories=" +
                    ignoreCategories.join(","),
            )
            .done((result) => {
                const mergeInfo = result as XRMergeInfo;

                const loadDec = $("<button class='btn btn-success'>Load Saved Decisions</button>").on("click", () =>
                    this.loadDecisions(),
                );
                const saveDec = $(
                    "<button style='margin-left:20px' class='btn btn-success'>Save Current Decisions</button>",
                ).on("click", () => this.saveDecisions());

                let newLoadSave = $("<div>").append(loadDec).append(saveDec).appendTo(control);

                that.mergeOptions = { copy: [], conflicted: [], delete: [], move: [], add_links: [], remove_links: [] };

                gettingInfo.remove();

                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.mergeType == EMergeType.push) {
                    that.branchBaseItemMap = that.createMap(mergeInfo.mainlineBase.items);
                    that.branchNowItemMap = that.createMap(mergeInfo.mainlineNow.items);
                    that.mainlineBaseItemMap = that.createMap(mergeInfo.branchBase.items);
                    that.mainlineNowItemMap = that.createMap(mergeInfo.branchNow.items);
                } else {
                    that.mainlineBaseItemMap = that.createMap(mergeInfo.mainlineBase.items);
                    that.mainlineNowItemMap = that.createMap(mergeInfo.mainlineNow.items);
                    that.branchBaseItemMap = that.createMap(mergeInfo.branchBase.items);
                    that.branchNowItemMap = that.createMap(mergeInfo.branchNow.items);
                }

                that.branchBaseItems = Object.keys(that.branchBaseItemMap);
                that.branchNowItems = Object.keys(that.branchNowItemMap);
                that.mainBaseItems = Object.keys(that.mainlineBaseItemMap);
                that.mainNowItems = Object.keys(that.mainlineNowItemMap);

                // show items which need decisions
                let contentChanges = $("<div style='padding: 6px 0 24px;'>").appendTo(control);

                // show all the changes
                that.showMergeDecisionsToTake(contentChanges);
                contentChanges.highlightReferences();

                let nextActions = $("<div class='merge-next-actions'>").appendTo(control);
                // next button
                let nextButton = $("<button id='mergeContent' class='btn btn-success mergeWizardButton'>Next</button>")
                    .click(function () {
                        newLoadSave.remove();
                        ml.UI.setEnabled(nextButton, false);

                        contentChanges.hide();
                        nextButton.hide();

                        // read the the choices
                        that.getUserContentChoices();

                        // make a list of items which exist in mainline after content merges
                        let copiedItems = that.mergeOptions.copy.map((copy) => ml.Item.parseRef(copy).id); // get rid version info from copy

                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        let current = that.mergeType == EMergeType.merge ? that.mainNowItems : that.branchNowItems;
                        let itemsAfterMerge = []
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .concat(current) // all items in current tree
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            .filter((item) => that.mergeOptions.delete.indexOf(item) == -1) // unless they get deleted in merge
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            .concat(copiedItems.filter((copy) => current.indexOf(copy) == -1)); // plus the ones added in merge

                        // continue with link and structural changes

                        that.wizardLinkChanges(
                            control,
                            mergeInfo,
                            itemsAfterMerge,
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            that.mergeLineCount != 0 || that.mergeLineNoAction,
                        );
                    })
                    .appendTo(nextActions);

                // help user to figure what still needs a decision
                let showActionNeededOnly = $(
                    `<label class="toogleNoAction"><input class="toggleNoAction__input" type="checkbox"><span class="checkboxLabel toggleNoAction__label">show only lines still needing a decision</span></label>`,
                )
                    .appendTo(nextActions)
                    .click(function () {
                        if ($("input", showActionNeededOnly).is(":checked")) {
                            // hide paris for which a decision has been taken
                            for (let mergeLine = 0; mergeLine < that.mergeLineCount; mergeLine++) {
                                let choice = $(`input[name="mergeChoice${mergeLine}"]:checked`);
                                if (choice.val()) {
                                    choice.closest(".mergePair").hide();
                                }
                            }
                        } else {
                            // show all pairs
                            $(".mergePair").show();
                        }
                    });

                that.activateContentChangesNext(nextButton, showActionNeededOnly);

                $("input", control).on("change", function () {
                    that.activateContentChangesNext(nextButton, showActionNeededOnly);
                });
            });
    }

    async saveDecisions(): Promise<void> {
        const project = matrixSession.getProject();

        if (!project) {
            return;
        }

        ml.UI.showSuccess("Please wait...");

        const merges: Record<string, string> = {};

        $(".mergePair").each((idx, row) => {
            if ($("input", $(row)).length === 2 && $("input:checked", $(row)).length === 1) {
                let hash = $(row).text();
                merges[hash] = $("input:checked", $(row)).val();
            }
        });

        let cache = (await app.readSettingJSONAsync("mergeCache")) as MergeCacheSetting;

        if (!cache || !cache.saved) {
            cache = { saved: [] };
        }

        const saved = cache.saved.filter((f) => f.mainline === project && f.branch === this.branchProject);

        if (saved.length === 0) {
            cache.saved.push({
                mainline: project,
                branch: this.branchProject,
                merges: merges,
            });
        } else {
            for (let dec in merges) {
                saved[0].merges[dec] = merges[dec];
            }
        }

        await app.setSettingJSON("mergeCache", cache);

        ml.UI.showSuccess("Saved");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    loadDecisions() {
        ml.UI.showSuccess("Please wait...");

        const cache = globalMatrix.ItemConfig.getSettingJSON("mergeCache") as MergeCacheSetting;

        if (!cache || !cache.saved) {
            ml.UI.showSuccess("Nothing saved for this main & branch");
            return;
        }

        const saved = cache.saved.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (f) => f.mainline == matrixSession.getProject() && f.branch === this.branchProject,
        );

        if (saved.length === 0) {
            ml.UI.showSuccess("Nothing saved for this main & branch");
            return;
        }

        const merged = saved[0].merges;

        $(".mergePair").each((idx, row) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if ($("input", $(row)).length == 2) {
                const hash = $(row).text();

                if (merged[hash]) {
                    // there is a saved decision
                    $("input", $(row)).each((jdx, input) => {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if ($(input).val() == merged[hash]) {
                            $(input).attr("checked", "checked");
                        } else {
                            $(input).removeAttr("checked");
                        }
                    });
                }
            }
        });

        this.activateContentChangesNext($("#mergeContent"), $(".toogleNoAction"));
        ml.UI.showSuccess("Applied");
    }

    // show the content merge decisions to be taken
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showMergeDecisionsToTake(control: JQuery) {
        let that = this;

        $("<h1>").html("Content Changes").appendTo(control);

        this.mergeLineCount = 0;

        /**********************************************
         *  conflicting changes,
         *  user needs to decide
         **********************************************/

        let conflicts = $("<div style='margin-bottom:12px'>").appendTo(control);
        let actionsConflicts = ml.UI.addChevronSection(conflicts, "no conflicting changes", that.msg_bothChanged, true);

        let actionsConflictsCount = 0;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mergeType == EMergeType.merge) {
            actionsConflictsCount += this.showPairs(
                actionsConflicts,
                this.getItemsOnlyInBranch(EMergeChangeStatus.changed),
                "does not exist in mainline, changed in branch",
                that.msg_ignore,
                that.msg_add_restore_now,
                EMergeActionChoice.add_restore,
                EMergeActionChoice.undecided,
                true,
            );
        }
        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.changed, EMergeChangeStatus.created),
            "changed in mainline, restored in branch",
            that.msg_ignore,
            that.msg_replace,
            EMergeActionChoice.replace,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.changed, EMergeChangeStatus.changed),
            "changed in mainline, changed in branch",
            that.msg_ignore,
            that.msg_replace,
            EMergeActionChoice.replace,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.changed, EMergeChangeStatus.deleted),
            "changed in mainline, deleted in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_delete : that.msg_restore_replace,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.delete : EMergeActionChoice.add_restore,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.deleted, EMergeChangeStatus.changed),
            "deleted in mainline, changed in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_restore_replace : that.msg_delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.add_restore : EMergeActionChoice.delete,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.deleted, EMergeChangeStatus.created),
            "deleted in mainline, restored in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_restore_replace : that.msg_delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.add_restore : EMergeActionChoice.delete,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.changed),
            "restored in mainline, changed in branch",
            that.msg_ignore,
            that.msg_replace,
            EMergeActionChoice.replace,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.deleted),
            "restored in mainline, deleted in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_delete : that.msg_restore_replace,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.delete : EMergeActionChoice.add_restore,
            EMergeActionChoice.undecided,
            true,
        );

        actionsConflictsCount += this.showPairs(
            actionsConflicts,
            this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.created),
            "restored in mainline, restored in branch",
            that.msg_ignore,
            that.msg_replace,
            EMergeActionChoice.replace,
            EMergeActionChoice.undecided,
            true,
        );

        if (!actionsConflictsCount) {
            conflicts.hide();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (actionsConflictsCount == 1) {
            $(".chevronText", conflicts).html("1 conflicting change");
        } else {
            $(".chevronText", conflicts).html(actionsConflictsCount + " conflicting changes");
        }

        /**********************************************
         *  changes only in branch,
         *  merge: by default these will be merged back
         *  push: no push back
         **********************************************/

        let branchOnly = $("<div style='margin-bottom:12px'>").appendTo(control);
        let actionsBranchOnly = ml.UI.addChevronSection(
            branchOnly,
            "no changes only in branch",
            that.msg_changedInBranchOnly,
        );

        let actionsBranchOnlyCount = this.showPairs(
            actionsBranchOnly,
            this.getItemsOnlyInBranch(EMergeChangeStatus.created),
            "does not exist in mainline, restored/created in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_add_restore : that.msg_delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.add_restore : EMergeActionChoice.delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.add_restore : EMergeActionChoice.ignore,
            false,
        );

        actionsBranchOnlyCount += this.showPairs(
            actionsBranchOnly,
            this.getPairs(EMergeChangeStatus.unchanged, EMergeChangeStatus.changed),
            "not changed in mainline, changed in branch",
            that.msg_ignore,
            that.msg_replace,
            EMergeActionChoice.replace,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.replace : EMergeActionChoice.ignore,
            false,
        );

        actionsBranchOnlyCount += this.showPairs(
            actionsBranchOnly,
            this.getPairs(EMergeChangeStatus.unchanged, EMergeChangeStatus.deleted),
            "not changed in mainline, deleted in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_delete : that.msg_add_restore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.delete : EMergeActionChoice.add_restore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.delete : EMergeActionChoice.ignore,
            false,
        );

        actionsBranchOnlyCount += this.showPairs(
            actionsBranchOnly,
            this.getPairs(EMergeChangeStatus.unchanged, EMergeChangeStatus.created),
            "not changed in mainline, restored in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_replace : that.msg_delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.replace : EMergeActionChoice.delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.replace : EMergeActionChoice.ignore,
            false,
        );

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mergeType == EMergeType.push) {
            actionsBranchOnlyCount += this.showPairs(
                actionsBranchOnly,
                this.getItemsOnlyInBranch(EMergeChangeStatus.changed),
                "does not exist in mainline, changed in branch",
                that.msg_ignore,
                that.msg_delete,
                EMergeActionChoice.delete,
                EMergeActionChoice.ignore,
                false,
            );
        }

        if (!actionsBranchOnlyCount) {
            branchOnly.hide();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (actionsBranchOnlyCount == 1) {
            $(".chevronText", branchOnly).html("1  change only in branch");
        } else {
            $(".chevronText", branchOnly).html(actionsBranchOnlyCount + "  changes only in branch");
        }

        /**********************************************
         *  changes only in mainline
         **********************************************/

        let mainlineOnly = $("<div style='margin-bottom:12px'>").appendTo(control);
        let actionsMainlineOnly = ml.UI.addChevronSection(
            mainlineOnly,
            "No changes only in mainline",
            that.msg_changedInMainlineOnly,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.mergeType == EMergeType.push,
        );

        let actionsMainlineOnlyCount = this.showPairs(
            actionsMainlineOnly,
            this.getPairs(EMergeChangeStatus.changed, EMergeChangeStatus.unchanged),
            "changed in mainline, not changed in branch",
            that.msg_ignore,
            that.msg_replace,
            EMergeActionChoice.replace,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.ignore : EMergeActionChoice.replace,
            false,
        );

        actionsMainlineOnlyCount += this.showPairs(
            actionsMainlineOnly,
            this.getPairs(EMergeChangeStatus.deleted, EMergeChangeStatus.unchanged),
            "deleted in mainline, not changed in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_restore_replace : that.msg_delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.add_restore : EMergeActionChoice.delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.ignore : EMergeActionChoice.delete,
            false,
        );

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mergeType == EMergeType.push) {
            actionsMainlineOnlyCount += this.showPairs(
                actionsMainlineOnly,
                this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.notExist),
                "restored/created in mainline, does not exist in branch",
                that.msg_ignore,
                that.msg_add_restore,
                EMergeActionChoice.add_restore,
                EMergeActionChoice.add_restore,
                false,
            );
            actionsMainlineOnlyCount += this.showPairs(
                actionsMainlineOnly,
                this.getPairs(EMergeChangeStatus.changed, EMergeChangeStatus.notExist),
                "changed in mainline, does not exist in branch",
                that.msg_ignore,
                that.msg_add_restore,
                EMergeActionChoice.add_restore,
                EMergeActionChoice.add_restore,
                false,
            );
            actionsMainlineOnlyCount += this.showPairs(
                actionsMainlineOnly,
                this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.unchanged),
                "restored in mainline, not changed in branch",
                that.msg_ignore,
                that.msg_replace,
                EMergeActionChoice.replace,
                EMergeActionChoice.replace,
                false,
            );
        }

        if (!actionsMainlineOnlyCount) {
            mainlineOnly.hide();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (actionsMainlineOnlyCount == 1) {
            $(".chevronText", mainlineOnly).html("1 change only in mainline");
        } else {
            $(".chevronText", mainlineOnly).html(actionsMainlineOnlyCount + " changes only in mainline");
        }

        /**********************************************
         *  items only in branch
         **********************************************/

        let inBranchOnly = $("<div style='margin-bottom:12px'>").appendTo(control);
        let actionsInBranchOnly = ml.UI.addChevronSection(
            inBranchOnly,
            "no unchanged items, existing only in branch",
            "By default the mainline stays as it is.",
        );

        let actionsInBranchOnlyCount = this.showPairs(
            actionsInBranchOnly,
            this.getItemsOnlyInBranch(EMergeChangeStatus.unchanged),
            "does not exist in mainline, not changed  in branch",
            that.msg_ignore,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? that.msg_add_restore_now : that.msg_delete,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            that.mergeType == EMergeType.merge ? EMergeActionChoice.add_restore : EMergeActionChoice.delete,
            EMergeActionChoice.ignore,
            false,
        );

        if (!actionsInBranchOnlyCount) {
            inBranchOnly.hide();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (actionsInBranchOnlyCount == 1) {
            $(".chevronText", inBranchOnly).html("1 previously ignored item, existing only in branch");
        } else {
            $(".chevronText", inBranchOnly).html(
                actionsInBranchOnlyCount + " previously ignored items, existing only in branch",
            );
        }

        /**********************************************
         *  changes with no need for action
         **********************************************/

        let showNoAction = $("<div style='margin-bottom:12px'>").appendTo(control);
        let noActions = ml.UI.addChevronSection(
            showNoAction,
            "no other changes",
            "These changes do not require any action.",
        );

        let noActionsCount = this.showPairs(
            noActions,
            this.getPairs(EMergeChangeStatus.deleted, EMergeChangeStatus.deleted),
            "deleted in mainline, deleted in branch",
            that.msg_na,
            that.msg_na,
            EMergeActionChoice.noActionNeeded,
            EMergeActionChoice.noActionNeeded,
            false,
        );

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mergeType == EMergeType.merge) {
            noActionsCount += this.showPairs(
                noActions,
                this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.notExist),
                "restored/created in mainline, does not exist in branch",
                that.msg_na,
                that.msg_na,
                EMergeActionChoice.noActionNeeded,
                EMergeActionChoice.noActionNeeded,
                false,
            );

            noActionsCount += this.showPairs(
                noActions,
                this.getPairs(EMergeChangeStatus.created, EMergeChangeStatus.unchanged),
                "restored in mainline, not changed in branch",
                that.msg_na,
                that.msg_na,
                EMergeActionChoice.noActionNeeded,
                EMergeActionChoice.noActionNeeded,
                false,
            );
        }

        noActions.hide();

        if (!noActionsCount) {
            showNoAction.hide();
        } else {
            this.mergeLineNoAction = true;

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (noActionsCount == 1) {
                $(".chevronText", showNoAction).html("1  other change");
            } else {
                $(".chevronText", showNoAction).html(noActionsCount + " other changes");
            }
        }

        /**********************************************
         *  changes in mainline which were previously ignored, by default still ignored.
         **********************************************/
        let showPushIgnored = $("<div style='margin-bottom:12px'>").appendTo(control);
        let pushIgnored = ml.UI.addChevronSection(
            showPushIgnored,
            "no other changes",
            "These changes do not require any action.",
        );
        let ignoredMainlineOnlyCount = 0;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mergeType == EMergeType.push) {
            ignoredMainlineOnlyCount += this.showPairs(
                pushIgnored,
                this.getPairs(EMergeChangeStatus.unchanged, EMergeChangeStatus.notExist),
                "unchanged in mainline, does not exist in branch",
                that.msg_ignore,
                that.msg_add_restore,
                EMergeActionChoice.add_restore,
                EMergeActionChoice.ignore,
                false,
            );
        }

        if (!ignoredMainlineOnlyCount) {
            showPushIgnored.hide();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (ignoredMainlineOnlyCount == 1) {
            $(".chevronText", showPushIgnored).html("1 previously ignored change only in mainline");
        } else {
            $(".chevronText", showPushIgnored).html(
                ignoredMainlineOnlyCount + " previously ignored changes only in mainline",
            );
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (actionsConflictsCount + actionsBranchOnlyCount + actionsMainlineOnlyCount + noActionsCount == 0) {
            control.append("<div>no content changes done which require any action</div>");
        }
    }

    // get items which (did not) changed in mainline / branch
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getPairs(statusMain: EMergeChangeStatus, statusBranch: EMergeChangeStatus) {
        let mainline = this.getItemsByChangeStatus(this.mainlineBaseItemMap, this.mainlineNowItemMap, statusMain);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (statusBranch == EMergeChangeStatus.notExist) {
            // it didn't exist before and not now in branch
            return mainline.filter((mId) => !this.branchBaseItemMap[mId] && !this.branchNowItemMap[mId]);
        }
        let branch = this.getItemsByChangeStatus(this.branchBaseItemMap, this.branchNowItemMap, statusBranch);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let pairs = mainline.filter((mId) => branch.indexOf(mId) != -1);
        return pairs;
    }

    // these items exist in main but did not in the past or now in branch
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getItemsOnlyInBranch(status: EMergeChangeStatus) {
        let items: string[] = [];

        let branch = this.branchBaseItems.concat(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.branchNowItems.filter((id) => this.branchBaseItems.indexOf(id) == -1),
        );
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let main = this.mainBaseItems.concat(this.mainNowItems.filter((id) => this.mainBaseItems.indexOf(id) == -1));

        let byStatus = this.getItemsByChangeStatus(this.branchBaseItemMap, this.branchNowItemMap, status);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return branch.filter((id) => main.indexOf(id) == -1 && byStatus.indexOf(id) != -1);
    }

    // return items which were created or deleted, changed or unchanged between the past and now
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getItemsByChangeStatus(base: IMergeLookup, now: IMergeLookup, status: EMergeChangeStatus) {
        let items: string[] = [];

        let baseItems = Object.keys(base);
        let nowItems = Object.keys(now);

        switch (status) {
            case EMergeChangeStatus.changed:
                return (
                    baseItems
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        .filter((id) => nowItems.indexOf(id) != -1)
                        .filter(
                            (id) =>
                                now[id] &&
                                base[id] &&
                                now[id].version - base[id].version - (now[id].nbMoveSinceV1 - base[id].nbMoveSinceV1) >
                                    0,
                        )
                );

            case EMergeChangeStatus.unchanged:
                return baseItems.filter(
                    (id) =>
                        now[id] &&
                        base[id] &&
                        now[id].version - base[id].version - (now[id].nbMoveSinceV1 - base[id].nbMoveSinceV1) <= 0,
                );

                break;
            case EMergeChangeStatus.deleted:
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return baseItems.filter((id) => nowItems.indexOf(id) == -1);

            case EMergeChangeStatus.created:
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return nowItems.filter((id) => baseItems.indexOf(id) == -1);
        }

        return items;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getOptionButtons(
        itemId: string,
        ignoreText: string,
        actionText: string,
        actionOption: EMergeActionChoice,
        defaultAction: EMergeActionChoice,
    ) {
        // create a radio button for each option
        let b1 = this.getOptionButton(this.mergeLineCount, ignoreText, EMergeActionChoice.ignore, defaultAction);
        let b2 = this.getOptionButton(this.mergeLineCount, actionText, actionOption, defaultAction);
        if (b1 || b2) {
            this.mergeLineCount++;
        }

        return `<span class='mergeOptions' data-itemid='${itemId}'>${b1}${b2}</span>`;
    }

    // create a radio button with the user option
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getOptionButton(
        line: number,
        actionText: string,
        action: EMergeActionChoice,
        defaultAction: EMergeActionChoice,
    ) {
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (actionText == that.msg_na) {
            return "";
        }
        let text = actionText;
        let tooltip = that.getToolTip(actionText);

        let disable = this.isBranchMaster() ? "" : "disabled";
        return `<label class="radio-inline" title="${tooltip}" ><input type="radio" name="mergeChoice${line}" ${disable} value="${action}" ${
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            action == defaultAction ? "checked" : ""
        }>${text}</label>`;
    }

    // show two items which are mapped (same item in mainline and branch, the content and the id can be different)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showPairs(
        control: JQuery,
        pairs: string[],
        heading: string,
        ignoreText: string,
        actionText: string,
        actionOption: EMergeActionChoice,
        defaultOption: EMergeActionChoice,
        markAsConflict: boolean,
    ) {
        let that = this;

        // keep UI clean, show only needed blocks
        if (!pairs.length) {
            return 0;
        }

        let block = $("<div>").appendTo(control);

        block.append("<div class='mergeGroup'>" + heading + "</div");
        for (let pair of pairs) {
            let choiceSelect = $(this.getOptionButtons(pair, ignoreText, actionText, actionOption, defaultOption));
            if (markAsConflict) {
                choiceSelect.addClass("isConflict");
            }
            let p = $("<p class='mergePair'>").appendTo(block);

            let before = this.renderSingleItem(p, pair, true);

            let compare = $(`<span class='mergeCompare'>`)
                .appendTo(p)
                .click(() => {
                    that.compareMainVsBranch(
                        this.mainlineBaseItemMap[pair],
                        this.mainlineNowItemMap[pair],
                        this.branchBaseItemMap[pair],
                        this.branchNowItemMap[pair],
                    );
                });

            let after = this.renderSingleItem(p, pair, false);

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (before == EItemChangeState.now && after == EItemChangeState.now) {
                if (ml.Item.parseRef(pair).isFolder) {
                    compare.replaceWith(`<span class="mergeNoBtn">changed</span>`);
                } else {
                    compare.append($(`<span class='mergeBtnCompare'>compare</span>`));
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (before == EItemChangeState.never || after == EItemChangeState.never) {
                compare.remove();
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (before == EItemChangeState.now) {
                compare.replaceWith(
                    `<span class="mergeNoBtn"><span style="margin-right:6px" class="fal fa-arrow-alt-right"></span><span class="fal fa-trash"></span></span>`,
                );
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (after == EItemChangeState.now) {
                compare.replaceWith(
                    `<span class="mergeNoBtn"><span style="margin-right:6px" class="fal fa-trash"></span><span class="fal fa-arrow-alt-left"></span></span>`,
                );
            } else {
                compare.replaceWith(
                    `<span class="mergeNoBtn"><span style="margin-right:6px" class="fal fa-trash"></span><span class="fal fa-trash"></span></span>`,
                );
            }
            p.append($(`<span class='mergeChoice'>`).append(choiceSelect));
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (actionOption == EMergeActionChoice.noActionNeeded || ignoreText == that.msg_na) {
            block.addClass("noActionNeeded");
        }

        return pairs.length;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private activateContentChangesNext(nextButton: JQuery, showActionNeededOnly: JQuery) {
        if (!this.isBranchMaster()) {
            nextButton.remove();
        }
        let allGood = true;
        for (let mergeLine = 0; allGood && mergeLine < this.mergeLineCount; mergeLine++) {
            if (!$(`input[name="mergeChoice${mergeLine}"]:checked`).val()) {
                allGood = false;
            }
        }
        if (allGood) {
            showActionNeededOnly.hide();
        } else {
            showActionNeededOnly.show();
        }
        ml.UI.setEnabled(nextButton, allGood);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private isBranchMaster() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return matrixSession.amIAllowedUser(this.branchInfo.branchMasters);
    }

    // second step get user choices {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getUserContentChoices() {
        for (let mergeLine = 0; mergeLine < this.mergeLineCount; mergeLine++) {
            let checked = $(`input[name="mergeChoice${mergeLine}"]:checked`);
            let mergeOptions = checked.closest(".mergeOptions");
            let item = mergeOptions.data("itemid");
            let vm = this.mainlineNowItemMap[item];
            let vb = this.branchNowItemMap[item];
            let choice = checked.val();
            switch (choice) {
                case "" + EMergeActionChoice.add_restore:
                case "" + EMergeActionChoice.replace:
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (this.mergeType == EMergeType.merge) {
                        this.mergeOptions.copy.push(`${item}-v${vb.version}-to-v${vm && vm.version ? vm.version : 0}`);
                    } else {
                        this.mergeOptions.copy.push(
                            `${item}-v${vm && vm.version ? vm.version : 0}-to-v${vb && vb.version ? vb.version : 0}`,
                        );
                    }
                    if (!ml.Item.parseRef(item).isFolder && mergeOptions.hasClass("isConflict")) {
                        // the item is restored in the mainline, but there was a conflict... maybe set another label
                        this.mergeOptions.conflicted.push(item);
                    }
                    break;
                case "" + EMergeActionChoice.delete:
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (this.mergeType == EMergeType.merge) {
                        this.mergeOptions.delete.push(`${item}-v${0}-to-v${vm && vm.version ? vm.version : 0}`);
                    } else {
                        this.mergeOptions.delete.push(`${item}-v${0}-to-v${vb && vb.version ? vb.version : 0}`);
                    }
                    break;
            }
        }
    }

    // **************************************************************************************
    // merge wizard ui - second step - allow user to select content changes to merge
    // **************************************************************************************

    // 3rd step: allow user to decide what to do with link changes
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private wizardLinkChanges(
        control: JQuery,
        mergeInfo: XRMergeInfo,
        itemsAfterMerge: string[],
        contentChanged: boolean,
    ) {
        let that = this;

        // figure out which could be added/deleted to mainline -> only for items which actually will exist in mainline/branch

        let linksAdded = mergeInfo.linksAdded.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (link) => itemsAfterMerge.indexOf(link.upItemRef) != -1 && itemsAfterMerge.indexOf(link.downItemRef) != -1,
        );
        let linksDeleted = mergeInfo.linksDeleted.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (link) => itemsAfterMerge.indexOf(link.upItemRef) != -1 && itemsAfterMerge.indexOf(link.downItemRef) != -1,
        );
        let linksDifferent = mergeInfo.linksDifferent.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (link) => itemsAfterMerge.indexOf(link.upItemRef) != -1 && itemsAfterMerge.indexOf(link.downItemRef) != -1,
        );

        // remove all links with items which are not in mainline

        let linkChanges = $("<div>").appendTo(control);
        $("<h1>").html("Traceability Changes").appendTo(linkChanges);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (linksAdded.length == 0 && linksDeleted.length == 0 && linksDifferent.length == 0) {
            $("<p>").html("No links were changed").appendTo(linkChanges);
        } else {
            contentChanged = true;
            this.addLinkList(linkChanges, that.msg_links_added, "add", linksAdded, true);
            this.addLinkList(linkChanges, that.msg_links_removed, "remove", linksDeleted, true);
            this.addLinkList(linkChanges, that.msg_links_not_in_other, "add", linksDifferent, false);
        }

        linkChanges.highlightReferences();

        // but to execute all merges
        let mergeButton = $("<button  id='mergeLinks' class='btn  btn-success mergeWizardButton'>Next</button>")
            .appendTo(linkChanges)
            .click(function () {
                linkChanges.hide();
                ml.UI.setEnabled(mergeButton, false);
                // build list of actions

                $.each($(".mergeLinkMergeBox  input:checked", linkChanges), function (idx, ml) {
                    let label = $(ml).parent();
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (label.data("action") == "add") {
                        that.mergeOptions.add_links.push({ from: label.data("from"), to: label.data("to") });
                    } else {
                        that.mergeOptions.remove_links.push({ from: label.data("from"), to: label.data("to") });
                    }
                });

                that.wizardStructuralChanges(control, itemsAfterMerge, contentChanged);
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addLinkList(control: JQuery, title: string, action: string, linkList: XRMergeItemLink[], checked: boolean) {
        if (!linkList.length) {
            return;
        }

        this.mainlineBaseItemMap;
        let links = $("<div class='mergeLinkMergeBox'>");
        $("<div class='mergeGroup'>").html(title).appendTo(control);

        let toggle = $(
            `<label class="mergeAllLinksLabel"><input style="margin-top:0px" type="checkbox"><span class="checkboxLabel">${action} all</span></label>`,
        )
            .appendTo($("<div style='padding: 0 14px;'>").appendTo(control))
            .click(function () {
                $(".linkMergeCB", links).prop("checked", $("input", toggle).is(":checked") ? "checked" : "");
            });
        control.append(links);
        for (let link of linkList) {
            let which =
                action +
                " link from " +
                this.renderBranchOrMainId(link.upItemRef) +
                " to " +
                this.renderBranchOrMainId(link.downItemRef);
            $(
                `<label class="mergeLinkLabel"><input style="margin-top:0px" type="checkbox" class="linkMergeCB" ${
                    checked ? "checked" : ""
                }><span class="checkboxLabel mergeLinkCheckbox">${which}</span></label>`,
            )
                .appendTo(links)
                .data("from", link.upItemRef)
                .data("to", link.downItemRef)
                .data("action", action);
        }
    }

    // 4th step: allow user to decide what to do with moved items
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private wizardStructuralChanges(control: JQuery, itemsAfterMerge: string[], contentChanged: boolean) {
        let that = this;

        // figure out which items should be merged in mainline/branch
        let afterMerge = itemsAfterMerge.map((id) => id.split("-v")[0]); // remove 'funny' -vx0-v3 appendixes

        let itemsAfterMergeLookup: IBooleanMap = {};
        for (let id of afterMerge) {
            itemsAfterMergeLookup[id] = true;
        }

        let deltaMoved: string[] = [];
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.mergeType == EMergeType.push) {
            deltaMoved = afterMerge // potentially all items might need to be moved
                .filter((id) => {
                    if (!that.mainlineNowItemMap[id]) {
                        return false;
                    } // item not in mainline, no reason to move in branch

                    // item was moved in mainline and in branch it exists in a different folder
                    if (
                        that.mainlineBaseItemMap[id] && // item exists in mainline
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.mainlineNowItemMap[id].parentFolder != that.mainlineBaseItemMap[id].parentFolder && // item was moved in mainline
                        that.branchNowItemMap[id] && // item exists (will exist) in branch
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.branchNowItemMap[id].parentFolder != that.mainlineNowItemMap[id].parentFolder && // current parent folder in branch is different to baseline's parent folder
                        itemsAfterMergeLookup[that.mainlineNowItemMap[id].parentFolder] // the parent folder actually exists in the branch after the merge
                    ) {
                        return true;
                    }

                    // an item was created or restored in mainline, the parent folder exists in branch (after merge)
                    if (
                        !that.mainlineBaseItemMap[id] &&
                        that.mainlineNowItemMap[id] &&
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        afterMerge.indexOf(that.mainlineNowItemMap[id].parentFolder) != -1
                    ) {
                        return true;
                    }

                    // maybe it was moved in a folder which does not exist in main after merge
                    return false;
                });
        } else {
            deltaMoved = afterMerge // potentially all items might need to be moved
                .filter((id) => {
                    if (!that.branchNowItemMap[id]) {
                        return false;
                    } // item not in branch, no reason to move in mainline

                    // item was moved in branch and in mainline it exists in a different folder
                    if (
                        that.branchBaseItemMap[id] && // item exists in branch
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.branchNowItemMap[id].parentFolder != that.branchBaseItemMap[id].parentFolder && // item was moved in branch
                        that.mainlineNowItemMap[id] && // item exists (will exist) in mainline
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.mainlineNowItemMap[id].parentFolder != that.branchNowItemMap[id].parentFolder && // current parent folder in mainline is different to baseline's parent folder
                        itemsAfterMergeLookup[that.branchNowItemMap[id].parentFolder] // the parent folder actually exists in the mainline after the merge
                    ) {
                        return true;
                    }

                    // an item was created or restored in branch, the parent folder exists in mainline (after merge)
                    if (
                        !that.branchBaseItemMap[id] &&
                        that.branchNowItemMap[id] &&
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        afterMerge.indexOf(that.branchNowItemMap[id].parentFolder) != -1
                    ) {
                        return true;
                    }

                    // maybe it was moved in a folder which does not exist in main after merge
                    return false;
                });
        }

        let structureChanges = $("<div>").appendTo(control);
        $("<h1>").html("Structural Changes (moved items)").appendTo(structureChanges);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (deltaMoved.length == 0) {
            // nothing needs to be moved, no reason to show that page
            $("<p>").html("No structural changes").appendTo(structureChanges);
        } else {
            contentChanged = true;
            let links = $("<div class='mergeLinkMergeBox'>");
            let toggle = $(
                `<label class="mergeAllLinksLabel"><input style="margin-top:0px" type="checkbox" checked><span class="checkboxLabel">un/select all</span></label>`,
            )
                .appendTo($("<div style='padding: 0 14px;'>").appendTo(structureChanges))
                .click(function () {
                    $(".linkMergeCB", links).prop("checked", $("input", toggle).is(":checked") ? "checked" : "");
                });
            structureChanges.append(links);

            for (let id of deltaMoved) {
                let targetId =
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    that.mergeType == EMergeType.push
                        ? that.mainlineNowItemMap[id].parentFolder
                        : that.branchNowItemMap[id].parentFolder;

                let which = `moved ${
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    that.mergeType == EMergeType.push ? that.renderMainId(id) : that.renderBranchId(id)
                } to ${
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    that.mergeType == EMergeType.push ? that.renderMainId(targetId) : that.renderBranchId(targetId)
                }`;
                $(
                    `<label><input style="margin-top:0px" type="checkbox" class="linkMergeCB" checked><span class="checkboxLabel mergeLinkCheckbox">${which}</span></label>`,
                )
                    .appendTo($("<div>").appendTo(links))
                    .data("itemid", id)
                    .data("moveto", targetId);
            }

            links.highlightReferences();
        }

        // but to execute all merges
        let strict = $("<div style='padding: 12px;'>");
        let buttonText = "Merge";
        switch (this.mergeType) {
            case EMergeType.push:
                buttonText = "Push";
                break;
            case EMergeType.merge:
            default:
                buttonText = "Merge";
                break;
        }

        let mergeButton = $(`<button id='doMerge' class='btn btn-success mergeWizardButton'>${buttonText}</button>`)
            .appendTo(control)
            .click(function () {
                ml.UI.setEnabled(mergeButton, false);
                mergeButton.html(ml.UI.getSpinningWait("merging....").html());
                if (firstClick) {
                    firstClick = false;

                    ml.UI.setEnabled($("input", structureChanges), false);

                    // build list of actions
                    $.each($(".mergeLinkMergeBox input:checked", structureChanges), function (idx, ml) {
                        let label = $(ml).parent();
                        that.mergeOptions.move.push({ id: label.data("itemid"), parent: label.data("moveto") });
                    });
                }

                that.wizardExecuteMerge(control, isStrict.strict)
                    .done((result) => {
                        control.html("");

                        // check if a risk or potential mitigation was changed
                        // list of risk and mitigation categories
                        let riskCatgories: string[] = [];
                        let mitigationCatgories: string[] = [];
                        let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2");
                        for (let riskField of riskFields) {
                            riskCatgories.push(riskField.category);
                            let config =
                                riskField.field.parameterJson &&
                                riskField.field.parameterJson &&
                                riskField.field.parameterJson.riskConfig
                                    ? riskField.field.parameterJson.riskConfig
                                    : globalMatrix.ItemConfig.getRiskConfig();

                            $.each(config.mitigationTypes, function (mitTypeIdx, mitType) {
                                mitigationCatgories.push(mitType.type);
                            });
                        }
                        let riskChanged = "";
                        let mitigationChanged = "";
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        for (let r of result.details) {
                            if (r.mainlineItem) {
                                let rc = ml.Item.parseRef(r.mainlineItem).type;
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (riskCatgories.indexOf(rc) != -1) {
                                    riskChanged = "Please review risks as some risks changed";
                                }
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (mitigationCatgories.indexOf(rc) != -1) {
                                    mitigationChanged = "Please review risks as potential risk controls changed";
                                }
                            }
                        }
                        let warn = riskChanged ? riskChanged : mitigationChanged ? mitigationChanged : "";

                        ml.UI.showSuccess(warn ? warn : `Merge completed - loading changes`, warn ? 4000 : 1000);

                        window.setTimeout(
                            () => {
                                window.location.href =
                                    globalMatrix.matrixBaseUrl +
                                    "/" +
                                    matrixSession.getProject() +
                                    "/" +
                                    BranchHistory.redirect;
                            },
                            warn ? 4000 : 1000,
                        );
                    })
                    .fail((error: string) => {
                        mergeButton.html("Merge");
                        ml.UI.showError("Merge failed", error);
                        ml.UI.setEnabled(mergeButton, true);
                    });
            });

        if (!contentChanged) {
            ml.UI.setEnabled(mergeButton, false);
            mergeButton.after("<div style='padding:12px'>There are no content changes, no need to merge</div>");
        }
        control.append(strict);
        let isStrict = { strict: true };
        let firstClick = true;
        ml.UI.addCheckbox(
            strict,
            "strict (only update items which were not modified since starting of merge)",
            isStrict,
            "strict",
            () => {},
        );
    }

    // 5th step: let server do the work
    private wizardExecuteMerge(control: JQuery, strict: boolean): JQueryDeferred<XRMergeEntry> {
        let res: JQueryDeferred<XRMergeEntry> = $.Deferred();

        let that = this;

        // copy the merge commands
        let mergeParams: IMergeCommand = ml.JSON.clone({ ...that.mergeOptions });
        if (!strict) {
            // remove the -v in copy and delete
            mergeParams.copy = mergeParams.copy.map((id) => id.split("-v")[0]);
            mergeParams.delete = mergeParams.delete.map((id) => id.split("-v")[0]);
        }

        // make sure we're still connected
        app.getItemAsync("F-DOC-1")
            .done(function () {
                let gettingInfo = ml.UI.getSpinningWait("executing merge");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                matrixSession.getCommentAsync().done(function (comment: string) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (that.mergeType == EMergeType.push) {
                        mergeParams.push = 1;
                        restConnection
                            .postServer(
                                that.branchProject +
                                    "/merge/" +
                                    matrixSession.getProject() +
                                    "?reason=" +
                                    encodeURIComponent(comment),
                                mergeParams,
                                true,
                            )
                            .done((result) => {
                                gettingInfo.remove();
                                res.resolve(result as XRMergeEntry);
                            });
                    } else {
                        restConnection
                            .postProject(
                                "merge/" + that.branchProject + "?reason=" + encodeURIComponent(comment),
                                mergeParams,
                                true,
                            )
                            .done((result) => {
                                gettingInfo.remove();
                                res.resolve(result as XRMergeEntry);
                            });
                    }
                });
            })
            .fail(() => {
                that.wizardExecuteMerge(control, strict);
            });

        return res;
    }

    // *************************************************************
    // show differences and items
    // *************************************************************

    // show the revision of an item as hyperlink to open a dialog to show this revisions content
    private renderSingleItem(anchor: JQuery, itemId: string, mainline: boolean): EItemChangeState {
        let that = this;

        let base = mainline ? this.mainlineBaseItemMap[itemId] : this.branchBaseItemMap[itemId];
        let now = mainline ? this.mainlineNowItemMap[itemId] : this.branchNowItemMap[itemId];

        if (!base && !now) {
            //anchor.append(`<span class="">did/does not exist</span>`);
            return EItemChangeState.never;
        }

        let isFolder = ml.Item.parseRef(itemId).isFolder;

        if (mainline) {
            // show base hyperlink (unless item does not exist)
            let title = now ? now.title : "";
            anchor.append(
                `<span class="mergeItem" title="${title}">${
                    base && !now ? itemId.replace(/-/g, "&dash;") : itemId
                }</span>`,
            );
        } else if (base && !now) {
            anchor.append(`<span class="mergeItem">${this.branchProject}/${itemId.replace(/-/g, "&dash;")}</span>`);
        } else {
            anchor.append(`<span class="mergeItem">${this.renderBranchId(itemId)}`);
        }

        let baseLink = $(`<span class='mergeRevision${isFolder ? "F" : ""}'>`);
        let nowLink = $(`<span class='mergeRevision${isFolder ? "F" : ""}'>`);

        if (base) {
            baseLink.html(" v" + base.version);
            if (!isFolder) {
                baseLink.click(() => {
                    if (mainline) {
                        that.showItem({ id: itemId, revision: base.version, description: "Mainline" });
                    } else {
                        that.showItem({
                            project: that.branchProject,
                            id: itemId,
                            revision: base.version,
                            description: "Branch",
                        });
                    }
                });
            }
        }
        if (now) {
            nowLink.html(" v" + now.version);
            if (!isFolder) {
                nowLink.click(() => {
                    if (mainline) {
                        that.showItem({ id: itemId, revision: now.version, description: "Mainline" });
                    } else {
                        that.showItem({
                            project: that.branchProject,
                            id: itemId,
                            revision: now.version,
                            description: "Branch",
                        });
                    }
                });
            }
        }
        if (base && now) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (base.version != now.version) {
                anchor.append(baseLink);
                anchor.append($(`<span class="mergeVersionChange">-></span>`));
                anchor.append(nowLink);
            } else {
                anchor.append(baseLink);
            }
        } else if (base) {
            anchor.append(baseLink);
            anchor.append(`<span class="mergeNoVersion"> was deleted</span>`);
            // current revision is deleted (so no 3 way merge)
            return EItemChangeState.notNow;
        } else if (now) {
            anchor.append(nowLink);
            anchor.append(`<span class="mergeNoVersion"> was created</span>`);
        }

        return EItemChangeState.now;
    }

    // compare items from main versus branch
    // if there's a main and a branch
    private compareMainVsBranch(
        mainBase: XRMergeItem,
        mainNow: XRMergeItem,
        branchBase: XRMergeItem,
        branchNow: XRMergeItem,
    ): void {
        let that = this;

        let item1 = mainNow ? mainNow : mainBase;
        let item2 = branchNow ? branchNow : branchBase;

        if (!item1 || !item2) {
            // that should never be the case
            return;
        }

        this.compare3Way(
            {
                id: item1.itemRef,
                revision: item1.version,
                description: "Mainline",
            },
            {
                id: item2.itemRef,
                revision: item2.version,
                description: "Branch",
                project: this.branchProject,
            },
            {
                id: mainNow && mainBase ? mainBase.itemRef : "",
                revision: mainNow && mainBase ? mainBase.version : 0,
                description: "Common Base",
            },
        );
    }

    // *************************************************************
    // some helper
    // *************************************************************

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderBranchId(id: string) {
        let title = this.branchNowItemMap[id] ? this.branchNowItemMap[id].title : "";
        return `<span title="${title}">#${this.branchProject}/${id}#</span>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderMainId(id: string) {
        let title = this.mainlineNowItemMap[id] ? this.mainlineNowItemMap[id].title : "";
        return `<span title="${title}">${id}</span>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderBranchOrMainId(id: string) {
        // take mainline title over branch item
        if (this.mainlineNowItemMap[id]) {
            return this.renderMainId(id);
        }
        if (this.branchNowItemMap[id]) {
            return this.renderBranchId(id);
        }
        // we should not actually render this
        return `<span>${id}</span>`;
    }
    // create map to find merge item info by item id
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createMap(items: XRMergeItem[]) {
        let lookup: IMergeLookup = {};
        for (let item of items) {
            lookup[item.itemRef] = item;
        }
        return lookup;
    }

    // verify if project selected is a real branch
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private mergeSelect(project: string, control: JQuery) {
        let res = $.Deferred();
        let that = this;

        this.branchProject = project;

        restConnection.getServer(this.branchProject).done(function (projectConfig) {
            let branchInfos = (projectConfig as XRGetProject_ProjectInfo_ProjectInfo).settingList.filter(
                function (setting) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return setting.key == BranchingConstants.BRANCH_INFO_SETTING;
                },
            );
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (branchInfos.length != 1 || JSON.stringify(JSON.parse(branchInfos[0].value)) == "{}") {
                ml.UI.showError("Not a branch", "The selected project is not a branch");
            } else {
                that.branchInfo = JSON.parse(branchInfos[0].value);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.branchInfo.sourceProject != matrixSession.getProject()) {
                    ml.UI.showError(
                        "Bad Branch",
                        "The select project is not branched of this but of '" + that.branchInfo.sourceProject + "'",
                    );
                } else {
                    // prepare the field mapping
                    that.fieldMapping = {};
                    $.each(that.branchInfo.fieldMapping, function (idx: number, mapping: IFieldMergeMapping) {
                        that.fieldMapping["" + mapping.branch] = mapping.mainline;
                    });

                    //  remember that this is a mergeable project
                    if (!that.lastMerges[project]) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.lastMerges[project] = <any>{};
                        that.rememberMergeInfo();
                    }

                    // start the merge activity
                    res.resolve();
                }
            }
        });
        return res;
    }

    // remember the projects which can be merged into this projects
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private rememberMergeInfo() {
        app.setSettingJSON("lastMerges", this.lastMerges);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getToolTip(actionText: string) {
        return "";
    }
}

class BranchPush extends BranchBase implements IPlugin {
    protected pageRoot = "BRANCHES";
    protected pageID = "PUSH";
    protected pageTitle = "Push";
    protected mergeType = EMergeType.push;

    protected msg_na = "not applicable"; // don't even show option
    protected msg_ignore = "ignore"; // don't push from mainline into branch
    protected msg_add_restore = "add/restore"; // add an item from the mainline to branch. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    protected msg_add_restore_now = "add/restore now"; // add an item from the mainline to the branch. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    protected msg_replace = "update branch"; // update the branch with the mainline content
    protected msg_restore_replace = "restore and replace"; // restore and update the branch item to match the mainline
    protected msg_delete = "delete"; // delete the branch item to match the delete in the branch

    // misc texted explaining the situation
    protected msg_bothChanged = `You need to decide: these items were changed (or don't exist) in the mainline and the branch. Choose the left option to keep the branch unchanged.`;
    protected msg_changedInBranchOnly = `By default these are ignored.`;
    protected msg_changedInMainlineOnly = `By default these are pushed into branch.`;

    protected msg_explain_merge_push =
        "Here you can update the branch by pushing items from the mainline into the branch";
    protected msg_explain_merge_push_help =
        "Select a project to which you want to push changes. Note: you need write access to both projects";

    protected msg_links_added = "Links only in mainline - added after last push to branch";
    protected msg_links_removed = "Links removed in mainline";
    protected msg_links_not_in_other = "Links only in mainline - existed before last push to branch";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getToolTip(actionText: string) {
        let that = this;

        switch (actionText) {
            case that.msg_add_restore:
                return "create or restore item in branch and fill with content of mainline";
            case that.msg_add_restore_now:
                return "create or restore item in branch and fill with content of mainline";
            case that.msg_delete:
                return "delete item in branch as it was deleted in mainline";
            case that.msg_replace:
                return "update item in branch to match mainline";
            case that.msg_restore_replace:
                return "restore and replace item in branch to match mainline";
            case that.msg_ignore:
                return "keep item from branch";
        }
        return "";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showPage(control: JQuery) {
        this.wizardShowBranchSelect(control);
    }
}
class BranchMerge extends BranchBase implements IPlugin {
    protected pageRoot = "BRANCHES";
    protected pageID = "MERGE";
    protected pageTitle = "Merge";
    protected mergeType = EMergeType.merge;

    protected msg_na = "not applicable"; // don't even show option
    protected msg_ignore = "ignore"; // don't merge a difference from the branch to the mainline
    protected msg_add_restore = "add/restore"; // add an item from the branch to the mainline. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    protected msg_add_restore_now = "add/restore now"; // add an item from the branch to the mainline. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    protected msg_replace = "replace"; // update the mainline item to match the branch
    protected msg_restore_replace = "restore and replace"; // restore and update the mainline item to match the branch
    protected msg_delete: string = "delete"; // delete the mainline item to match the delete in the branch

    // misc texted explaining the situation
    protected msg_bothChanged = `You need to decide: these items were changed (or don't exist) in the mainline and the branch. Choose the left option to keep the mainline unchanged.`;
    protected msg_changedInBranchOnly = `By default these are merged back into mainline.`;
    protected msg_changedInMainlineOnly = `By default the mainline stays as it is.`;

    protected msg_explain_merge_push = "Here you can update the mainline by merging changes back from the branch";
    protected msg_explain_merge_push_help =
        "Select a project from which you want to get the changes. Note: you need write access to both projects.";

    protected msg_links_added = "Links only in branch - added after last merge";
    protected msg_links_removed = "Links removed in branch";
    protected msg_links_not_in_other = "Links only in branch - existed before last merge";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getToolTip(actionText: string) {
        let that = this;

        switch (actionText) {
            case that.msg_add_restore:
                return "create or restore item in mainline and fill with content of branch";
            case that.msg_add_restore_now:
                return "create or restore item in mainline and fill with content of branch";
            case that.msg_delete:
                return "delete item in mainline as it was deleted in branch";
            case that.msg_replace:
                return "update item in mainline to match branch";
            case that.msg_restore_replace:
                return "restore and replace item in mainline to match branch";
            case that.msg_ignore:
                return "keep item from mainline";
        }
        return "";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showPage(control: JQuery) {
        this.wizardShowBranchSelect(control);
    }
}

class BranchHistory extends BranchBase implements IPlugin {
    protected pageRoot = "BRANCHES";
    static redirect = "BRANCH_HISTORY";
    protected pageID = "BRANCH_HISTORY"; // needs to be the same as above
    protected pageTitle = "Branch History";
    protected mergeType = EMergeType.undefined;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showPage(control: JQuery) {
        this.showBranchHistory(control);
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function initialize() {
    plugins.register(new BranchHistory());
    plugins.register(new BranchPush());
    plugins.register(new BranchMerge());
}
