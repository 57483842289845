import { InitializeCodeMirrorPlugins } from "./CodeMirrorPlugins";
import "./jqueryFunctions";
import "./RefLink";
import "./SummernotePlugins";

export { InitializeParts };

// Function exists to ensure initializations in the imported modules above
// are made.
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeParts() {
    InitializeCodeMirrorPlugins();
}
