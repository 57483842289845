import { MR1, IItemChangeEvent } from "../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, plugins } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { IBaseControlOptions, BaseControl } from "../../common/UI/Controls/BaseControl";
import { IRiskParameter } from "../../common/UI/Controls/riskCtrl2";
import {
    ControlState,
    IReference,
    IBooleanMap,
    IStringJQueryMap,
    matrixSession,
    globalMatrix,
    restConnection,
    app,
    IItemGet,
    IGenericMap,
    IItem,
} from "../../globals";

import { IRiskConfig, IColDef, IFieldDescription } from "../../ProjectSettings";
import { XCPutEditItem } from "../../RestCalls";
import { FieldDescriptions } from "../../common/businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../common/businesslogic/FieldHandlers/EmptyFieldHandler";
import { ItemHelpers } from "../../common/businesslogic/ItemHelpers";

export type { IRiskTableControlOptions, IRiskTableParams };
export { initialize };

interface IRiskTableControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: () => void;
    parameter?: IRiskTableParams;
    links?: IReference[];
}

interface IRiskTableParams {
    tableOptions?: {
        showFullRisk?: boolean; // if true the risk ID will be shown with the title
        hideReadonly?: boolean; // if true readonly values are hidden from the table
        cloneButtonName?: string; // name of button to clone the last row
        showUplinks?: boolean; // show uplinks in the table
    };
}

class RiskControlFolderControl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected config: IRiskConfig;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IRiskTableControlOptions;

    private changes: IBooleanMap = {};
    private riskIDs: string[] = [];
    private riskControlsRows: IStringJQueryMap = {};
    private forwardChangeEvents: boolean = false;

    constructor(control: JQuery) {
        super(control, new EmptyFieldHandler(FieldDescriptions.Field_dummy, {}));
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IRiskTableControlOptions) {
        let that = this;

        if (!matrixSession.hasRisks()) {
            this._root.append("risks not licensed");
            return;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!options || options.controlState != ControlState.FormEdit) {
            return;
        }

        let defaultOptions: IRiskTableControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {},
            parameter: {
                tableOptions: {
                    showFullRisk: false,
                    hideReadonly: false,
                },
            },
        };
        this.settings = <IRiskTableControlOptions>ml.JSON.mergeOptions(defaultOptions, options);
        // get risk config
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.config = (<IRiskParameter>this.settings.parameter).riskConfig
            ? (<IRiskParameter>this.settings.parameter).riskConfig
            : globalMatrix.ItemConfig.getRiskConfig();

        if (this.settings.id === undefined) {
            this._root.append("Item id is undefined");
            return;
        }

        // We kwown that the id is not undefined.
        let cat = ml.Item.parseRef(this.settings.id).type;
        let canEditCat = globalMatrix.ItemConfig.canEdit(cat);

        this._root.append(super.createHelp(this.settings));
        let scrollableDiv = $("<div class='horizontal-scroll' />");
        let riskAsTable = $('<table class="table">');

        scrollableDiv.append(riskAsTable);
        this._root.append(scrollableDiv);

        // get and show risks
        app.getNeedlesAsync("folder=" + options.id, true, true, "*", true).done((resultsNeedles) => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (resultsNeedles === undefined || !resultsNeedles.length || that.settings.id === undefined) {
                // nothing to do - for now
                return;
            }

            // the needle search returns an unordered list of children, the tree has the ordered info
            let orderedChildren = app.getChildrenIds(that.settings.id);
            let needles = resultsNeedles.sort(function (a, b) {
                if (a.id === undefined || b.id === undefined) {
                    return 0;
                }
                return orderedChildren.indexOf(a.id) - orderedChildren.indexOf(b.id);
            });

            // otherwise lots of not needed events
            that.disableChangeEvents();

            riskAsTable.append($("<thead>").append(that.renderTableHeaderRow()));
            let tbody = $("<tbody class='riskTableBody'>").appendTo(riskAsTable);

            // there's gonna be a risk field in that type -> otherwise the constructor would not have been called
            let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", that.settings.type);
            let riskFieldId = riskFields[0].field.id;
            for (let risk of needles) {
                let riskValue = risk[riskFieldId];
                let canEdit = canEditCat && !ItemHelpers.isItemLocked(risk, globalMatrix.ItemConfig);
                if (risk.id === undefined) {
                    continue;
                }
                that.renderRiskRow({
                    tbody: tbody,
                    itemId: risk.id,
                    riskFieldId: riskFieldId,
                    riskValue: riskValue,
                    downLinkList: risk.downLinks ?? [],
                    upLinkList: risk.upLinks ?? [],
                    labels: risk.labels ?? [],
                    hideReadonly: that.settings.parameter?.tableOptions?.hideReadonly === true,
                    canEdit: canEdit,
                });
            }

            tbody.sortable({
                // this prevents all hyperlinks and inputs from being dragged
                cancel: "a,input,textarea,select,.RBM,.RAM",
                stop: function (event, ui) {
                    that.moveRow();
                },
            });
            // add extra row
            let riskItemId = resultsNeedles[resultsNeedles.length - 1].id;
            if (riskItemId !== undefined) {
                that.renderTableFooterRow(riskAsTable, riskItemId, riskFieldId);
                // now all is done,
                that.enableChangeEvents();
            }
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        let that = this;
        return new Promise<boolean>((resolve, reject) => {
            let changed = false;
            $.each(this.changes, function (itemId, itemChanged) {
                changed = changed || itemChanged;
            });
            $.each($(".riskDetails", this._root), function (rowIdx, row) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.riskIDs[rowIdx] != $(row).data("itemId")) {
                    changed = true;
                }
            });
            resolve(changed);
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        let that = this;

        return "";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    async saveChanges(): Promise<void> {
        let that = this;
        let hasChanged = await this.hasChangedAsync();
        if (!hasChanged) {
            return;
        }
        let comment = await matrixSession.getCommentAsync();
        return this.saveChangesRec(comment ?? "Updating risk");
    }

    private async reorderRec(row: number): Promise<void> {
        if (row >= this.riskIDs.length) {
            return;
        } else {
            for (let i = row; i < this.riskIDs.length; i++) {
                await app.moveItemsAsync(
                    $($(".riskDetails", this._root)[i]).data("itemId"),
                    this.settings.id!,
                    10000 + i,
                );
            }
        }
        const project = matrixSession.getProject();
        if (project) {
            app.loadProject(project, app.getCurrentItemId());
        }
    }
    // save changes of all risk which changed
    private async saveChangesRec(comment: string): Promise<void> {
        let that = this;
        // TODO: refactor it
        for (let row = 0; row < this.riskIDs.length; row++) {
            if (this.changes[this.riskIDs[row]]) {
                // this row needs saving
                let riskId = this.riskIDs[row];
                // get new value of the field
                let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", ml.Item.parseRef(riskId).type);
                let fieldId = riskFields[0].field.id;
                // get new value
                let riskValue = await that.riskControlsRows[this.riskIDs[row]].getController().getValueAsync();
                // put it on server
                await that.putRisk(riskId, fieldId, riskValue, comment);
            }
        }
        this.changes = {};
        // done saving the content changes, check if something was moved
        let reorderFrom = -1;
        let orderedRows = $(".riskDetails", this._root);
        let rowIdx = 0;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
            while (reorderFrom == -1 && rowIdx < orderedRows.length) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
                if (that.riskIDs[rowIdx] != $(orderedRows[rowIdx]).data("itemId")) {
                reorderFrom = rowIdx;
            } else {
                rowIdx++;
            }
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
            if (reorderFrom != -1) {
            await this.reorderRec(reorderFrom);
        }
    }

    // callback when a value in a cell has changed
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async rowChange(itemId: string, row: JQuery) {
        this.changes[itemId] = await row.getController().hasChangedAsync();
        if (this.forwardChangeEvents && this.settings.valueChanged) {
            this.settings.valueChanged();
        }
    }
    // prevent change events being sent to app
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private disableChangeEvents() {
        this.forwardChangeEvents = false;
    }
    // enable events and actually do one check
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private enableChangeEvents() {
        this.forwardChangeEvents = true;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.valueChanged();
    }
    // callback after drop of row
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private moveRow() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.valueChanged();
    }

    // set the width of a column
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setWidth(th: JQuery, colDef: IColDef) {
        if (!colDef) {
            return;
        }
        if (colDef.maxWidth) {
            th.css("max-width", colDef.maxWidth);
        }
        if (colDef.minWidth) {
            th.css("min-width", colDef.minWidth);
        }
        if (colDef.width) {
            th.css("width", colDef.width);
        }
    }

    // check whether a cell should be the same for risks
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private isRowSpan(colDef: IColDef) {
        if (!colDef) {
            return false;
        }
        if (colDef.rowSpan) {
            return true;
        }
        return false;
    }

    // render a row showing a risk
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderRiskRow(params: {
        tbody: JQuery;
        itemId: string;
        riskFieldId: number;
        riskValue: string;
        downLinkList: IReference[];
        upLinkList: IReference[];
        labels: string[];
        hideReadonly: boolean;
        canEdit: boolean;
    }) {
        let that = this;
        // create a new fake risk item
        let item: IItemGet = {
            id: params.itemId,
            title: "",
            type: this.settings.type,
            downLinks: [],
            upLinks: [],
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            modDate: null,
            labels: [],
        };
        // copy the risk field data inside
        (<IGenericMap>item)[params.riskFieldId.toString()] = params.riskValue;
        // copy the downlinks (risk controls) inside
        $.each(params.downLinkList, function (dlIdx, dl) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            item.downLinks.push(dl);
        });
        // copy the uplinks (uplinks) inside
        $.each(params.upLinkList, function (ulIdx, ul) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            item.upLinks.push(ul);
        });

        let tr = $("<tr class='riskDetails'>").appendTo(params.tbody);

        tr.data("itemId", params.itemId);

        let rowSettings = ml.JSON.clone(this.settings);
        rowSettings.item = item;
        rowSettings.id = item.id;
        rowSettings.isItem = true;
        rowSettings.links = item.downLinks;
        rowSettings.fieldValue = (<IGenericMap>item)[params.riskFieldId.toString()];
        rowSettings.hideReadonlyColumns = params.hideReadonly;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        rowSettings.valueChanged = () => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.rowChange(item.id, tr);
        };
        rowSettings.canEdit = params.canEdit;

        tr.riskCtrl2(rowSettings);

        if (this.settings?.parameter?.tableOptions?.showUplinks) {
            let div = $("<div class='uplinks'></div>");
            for (let i = 0; i < params.upLinkList.length; i++) {
                let upLink = params.upLinkList[i];
                let p = $(`<p title='${upLink.to + " " + upLink.title}'></p>`);
                p.append(upLink.to + "!");
                div.append(p);
            }
            let td = $("<td></td>");
            td.append(div);
            td.insertAfter(tr.find(".riskIdCell"));
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        that.riskControlsRows[item.id] = tr;
        // set item to not changed
        // this would overwrite value from onchange that.changes[item.id] = false;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        that.riskIDs.push(item.id);

        // change form-control to use max-content
        $("select", tr).css({ width: "max-content", "max-width": "500px" });

        this.highlightRow(tr);
    }

    // render a row with risk column names
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderTableHeaderRow() {
        let that = this;

        let tr = $("<tr class='rotateHead'>");

        $("<th class='riskIdCell'>").appendTo(tr); // column for risk id
        if (this.settings?.parameter?.tableOptions?.showUplinks) {
            $("<th class='rotate'><div>Uplinks</div></th>").appendTo(tr); // column for risk id
        }

        // add columns for text inputs (weights)
        $.each(this.config.factors, function (factorIdx, factor) {
            let th = $("<th class='rotate'>")
                .appendTo(tr)
                .html("<div>" + factor.label + "</div>"); // column for factor (text input);
            that.setWidth(th, factor.colDef);
            if (factor.hideTextInput === true) {
                th.hide();
            }
        });
        // add columns values inputs (factors)
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                if (!weight.hidden) {
                    let th = $("<th class='rotate'>")
                        .appendTo(tr)
                        .html("<div>" + weight.label + "</div>");
                    // column for weight (drop down input);
                    that.setWidth(th, weight.colDef);
                }
            });
        });
        if (!this.config?.rbm?.hidden) {
            // add column for RBM // RPN before controls
            let rbm = $("<th class='rotate'>")
                .appendTo(tr)
                .html("<div>" + (this.config.rbm && this.config.rbm.short ? this.config.rbm.short : "RBM") + "</div>");
            if (this.config.rbm) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.setWidth(rbm, this.config.rbm.colDef);
            }
        }

        // add column for risk controls
        let thc = $("<th class='rotate'>")
            .appendTo(tr)
            .html("<div>" + (this.config.controls ? this.config.controls : "Risk Controls") + "</div>");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        that.setWidth(thc, this.config.mitigationColDef);

        // add columns for values (factors) after risk controls
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                if (!weight.hidden) {
                    let th = $("<th class='rotate'>")
                        .appendTo(tr)
                        .html("<div>" + weight.label + "</div>"); // column for weight (drop down input)
                    that.setWidth(th, weight.colDef);
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.parameter.tableOptions &&
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.parameter.tableOptions.hideReadonly &&
                        (ml.JSON.isTrue(weight.readonly) || !that.config.postReduction)
                    ) {
                        th.hide();
                    }
                    if (that.config.postReduction) {
                        let visibleColumns = that.config.postReduction.weights.map(function (weight) {
                            return weight.type;
                        });
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (visibleColumns.indexOf(weight.type) == -1) {
                            th.remove();
                        }
                    }
                }
            });
        });

        // column for RAM // RPN after  controls
        if (!this.config?.ram?.hidden) {
            let ram = $("<th class='rotate'>")
                .appendTo(tr)
                .html("<div>" + (this.config.ram && this.config.ram.short ? this.config.ram.short : "RAM") + "</div>");
            if (this.config.ram) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.setWidth(ram, this.config.ram.colDef);
            }
        }

        return tr;
    }

    // render a row with an add button to clone last row
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderTableFooterRow(table: JQuery, lastItemId: string, riskFieldId: number) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.parameter.tableOptions || !this.settings.parameter.tableOptions.cloneButtonName) {
            // no extra function(s)
            return;
        }
        let tfoot = $("<tfoot>").appendTo(table);
        let tr = $("<tr class='riskFooter'>").appendTo(tfoot);

        // compute colspan
        let colspan = 1;
        // add columns for text inputs (weights)
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        colspan += this.config.factors.length;

        // add columns values inputs (factors)
        $.each(this.config.factors, function (factorIdx, factor) {
            colspan += factor.weights.length;
        });
        // add column for RBM // RPN before controls
        colspan++;
        // add column for risk controls
        colspan++;

        // add columns for values (factors) after risk controls
        $.each(this.config.factors, function (factorIdx, factor) {
            colspan += factor.weights.length;
        });

        // column for RAM // RPN after  controls
        colspan++;

        let firstCol = $("<td colspan='" + colspan + "'>").appendTo(tr); // column for risk id
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let addButtonName = this.settings.parameter.tableOptions.cloneButtonName;
        $("<button class='btn btn-default btn-xs' style='margin:6px'>" + addButtonName + "</button>")
            .click(function () {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                matrixSession.getCommentAsync().done(function (comment: string) {
                    let params = {
                        targetFolder: that.settings.id,
                        targetProject: matrixSession.getProject(),
                        reason: comment,
                    };
                    restConnection
                        .postServer(matrixSession.getProject() + "/copy/" + lastItemId, params)
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        .done(async function (newItems: any) {
                            app.insertInTree({
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                parent: that.settings.id,
                                position: 10000,
                                item: {
                                    type: that.settings.type,
                                    id: newItems.itemsAndFoldersCreated[0],
                                    title: app.getItemTitle(lastItemId),
                                },
                            });
                            tr.remove();
                            that.renderRiskRow({
                                tbody: table,
                                itemId: newItems.itemsAndFoldersCreated[0],
                                riskFieldId: riskFieldId,
                                riskValue: await that.riskControlsRows[lastItemId].getController().getValueAsync(),
                                downLinkList: [],
                                upLinkList: [],
                                labels: [],
                                hideReadonly: that.settings.parameter?.tableOptions?.hideReadonly === true,
                                canEdit: true,
                            });

                            that.renderTableFooterRow(table, lastItemId, riskFieldId);
                        });
                });
            })
            .appendTo(firstCol);
    }

    // update a risk in the database (only change the risk field)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private putRisk(itemId: string, riskFieldId: number, riskValue: string, comment: string) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let putIt: XCPutEditItem = <any>{ reason: comment, onlyThoseFields: 1, onlyThoseLabels: 1 };

        (<IGenericMap>putIt)["fx" + riskFieldId] = riskValue;

        return restConnection.putProject("item/" + itemId, putIt);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refreshLinks() {
        Object.values(this.riskControlsRows).forEach((row) => {
            this.highlightRow(row);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private highlightRow(row: JQuery) {
        $(".riskIdCell", row).highlightReferences();
        $(".uplinks", row).highlightReferences();
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.riskCtrl2Folder = function (this: JQuery, options: IRiskTableControlOptions) {
    let baseControl = new RiskControlFolderControl(this);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class RiskControlFolderPlugin implements IPlugin {
    static fieldType = FieldDescriptions.Field_riskFolder;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    control: JQuery;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor() {}

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;

    public isDefault = true;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(item: IItem, jui: JQuery) {
        this._item = item;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        MR1.onBeforeSaveAsync().subscribe(this, function (event: IItemChangeEvent) {
            let ctrls = event.view.getControls(RiskControlFolderPlugin.fieldType);
            let res = $.Deferred();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (ctrls.length == 1) {
                let controller: RiskControlFolderControl = <RiskControlFolderControl>ctrls[0].getController();
                controller.saveChanges().then(() => {
                    res.resolve();
                });
            } else {
                res.resolve();
            }
            return res;
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {}

    async getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return [];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMenu(ul: JQuery) {}

    supportsControl(fieldType: string): boolean {
        return fieldType === RiskControlFolderPlugin.fieldType;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createControl(ctrl: JQuery, options: IBaseControlOptions) {
        this.control = ctrl;
        if (!options.isForm || options.isItem) {
            ctrl.riskCtrl2Folder(null);
            return;
        }
        let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", options.type);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (riskFields.length == 1) {
            let riskConfig = (<IRiskParameter>riskFields[0].field.parameterJson).riskConfig;
            if (riskConfig) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (<any>options.parameter).riskConfig = riskConfig;
            }

            ctrl.riskCtrl2Folder(options);
        } else {
            // create 'empty' control
            ctrl.riskCtrl2Folder(null);
        }
    }

    // @ts-ignore I see no issue there, because IFieldParam have `[key: string]: any;`
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addFieldSettings(
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        configApp: any,
        project: string,
        pageId: string,
        fieldType: string,
        fieldParams: IRiskTableParams,
        ui: JQuery,
        paramChanged: () => void,
    ) {
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (fieldType != RiskControlFolderPlugin.fieldType) {
            return;
        }

        if (!fieldParams.tableOptions) {
            fieldParams.tableOptions = {};
        }
        ml.UI.addCheckbox(ui, "show full risk title", fieldParams.tableOptions, "showFullRisk", paramChanged);
        ml.UI.addCheckbox(
            ui,
            "hide readonly post reduction values",
            fieldParams.tableOptions,
            "hideReadonly",
            paramChanged,
        );
        ml.UI.addCheckbox(ui, "Show uplinks", fieldParams.tableOptions, "showUplinks", paramChanged);

        ml.UI.addTextInput(
            ui,
            "add button to clone last risk (empty for none)",
            fieldParams.tableOptions,
            "cloneButtonName",
            paramChanged,
        );
    }

    getFieldConfigOptions(): IFieldDescription[] {
        return [
            {
                id: RiskControlFolderPlugin.fieldType,
                label: "Risk Table [" + RiskControlFolderPlugin.fieldType + "]",
                capabilities: {
                    canBeXtcPreset: false,
                    canBePublished: false,
                    canBeReadonly: true,
                    canHideInDoc: true,
                    canRequireContent: false,
                },
                class: "folder",
                help: "shows risks in a folder in tabular form",
            },
        ];
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    let folderRiskControl = new RiskControlFolderPlugin();
    // @ts-ignore I see no issue there, because IFieldParam have `[key: string]: any;`
    plugins.register(folderRiskControl);
}
