/// <reference types="matrixrequirements-type-declarations" />
import { IDataStorage } from "../globals";

export { ProjectStorage };

class ProjectStorage implements IDataStorage {
    Project: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    DOMPurify: any;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor(project: string, appVersion: string, matrixBaseUrl: string, dompurifylib: any) {
        this.DOMPurify = dompurifylib;
        let vs = appVersion.split(".");
        let vo = vs[0] + "." + vs[1] + ".";
        if (!localStorage.getItem(vo + "cleanup")) {
            for (let x in localStorage) {
                if (x.indexOf("remember_selection") > 0 && localStorage[x] === "") {
                    localStorage.removeItem(x);
                }
                if (x.indexOf(matrixBaseUrl) === 0) {
                    let y = x.replace(matrixBaseUrl, "1.8.");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    localStorage.setItem(y, localStorage.getItem(x));
                    localStorage.removeItem(x);
                }
            }
            localStorage.setItem(vo + "cleanup", "done");
        }
        this.Project = vo + "_" + project + "_";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setItem(itemKey: string, itemVal: string, dontSanitize?: boolean) {
        localStorage.setItem(this.Project + itemKey, dontSanitize ? itemVal : this.DOMPurify.sanitize(itemVal) + "");
    }

    getItem(itemKey: string, dontSanitize?: boolean): string {
        let val = localStorage.getItem(this.Project + itemKey);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return dontSanitize ? val : this.DOMPurify.sanitize(val) + "";
    }
    getItemDefault(itemKey: string, defaultValue: string): string {
        let val = localStorage.getItem(this.Project + itemKey);
        if (!val) {
            val = defaultValue;
        }
        return this.DOMPurify.sanitize(val) + "";
    }
}
