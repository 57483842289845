// ***********************************************
// category setting to configure the publishing category
// ***********************************************

import { IItemCopyBuffer } from "../../../client/plugins/beta/CopyPaste";
import { app, ControlState, globalMatrix, INumberStringMap, matrixSession, restConnection } from "../../../globals";
import { IPublicationCategory, IPublication, IAutoFillSetting, EnumItemPublish } from "../../../ProjectSettings";
import { XRGetProject_JobStatus_JobsStatusWithUrl } from "../../../RestResult";
import { ISearchResult } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { LabelTools } from "../../matrixlib/index";
import { IPanel } from "../Application";
import { HistoryTools } from "../Tools/ItemHistoryView";

export type {
    IPublishInfo,
    IPublished,
    IToPublish,
    IToPublishMap,
    IPublicationGroup,
    IDocTitle,
    IPublicationHistory,
    IPublicationHistoryItem,
    INewPublication,
};
export { PublishPanel };

interface IPublishInfo {
    target: string; // the name under which to publish the category (part of the URL, defaults to pub)
    SOPLabelGroupType: string; // an id of the label group which contains SOPs, defaults to SOPS
    ProcReviewLabelGroupType: string; // an id of the label group which contains SOP review labels, defaults to PROC_RW
    WiReviewLabelGroupType: string; // an id of the label group which contains EI review labels, defaults to WI_RW
    publisher: string; // comma separated list of users who can click on publish button
}

// item versions which were published -> these items are saved in a field in the PUB category
interface IPublished {
    item: string; // published item
    version: number; // version of published item
    wis: string[]; // last published downlinks
    sop: string; // last published groups
}

// items which are to be published
interface IToPublish {
    itemInfo: ISearchResult; // meta info of the item (version, labels, links, ...)
    approved: boolean; // true if all required approval labels are set
    lastPublishedVersion: number; // last published version if the item was published before
    wis: string[]; // last published downlinks
    sop: string; // last published groups
    rolesAndUsers: string[]; // roles  (e.g. responsible from user drop downs)
}

interface IToPublishMap {
    [key: string]: IToPublish;
}

interface IPublicationGroup {
    groupInfo: IPublicationCategory; // definition of group
    label: string;
    groupItems: string[]; // all item ids in group
    approved: boolean; // whether all items in group are approved
    needsPublication: boolean; // true if at least one item has changed and all items are ready to publish
}

interface IDocTitle {
    id: string;
    title: string;
}

enum SelectedEntity {
    ItemsInGroupsOnly = 0,
    ChangedItems = 1,
    DeletedItems = 2,
    UnPublish = 3,
}

// ***********************************************
// unchanged
// ***********************************************

interface IPublicationHistory {
    history: IPublicationHistoryItem[];
}

interface IPublicationHistoryItem {
    item: string;
    date: string;
    comment: string;
}

// ***********************************************
// new updated configurations and data structures
// ***********************************************

// details for new publication item
interface INewPublication {
    item: string; // published item
    version: number; // version of published item
    wis: string[]; // for items list of downlinked items in publication
    sop: string; // , separated list of labels
}

class PublishPanel implements IPanel {
    title = "QMS Publish";

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private control: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private body: JQuery;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private pubConfig: IPublication;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectedForPublication: JQuery[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectedForUnPublication: JQuery[];

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private deletedStillPublished: IPublished[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private itemMap: IToPublishMap;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private unGrouped: string[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private groups: IPublicationGroup[];

    constructor(folderType: string) {
        this.paint(folderType);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private paint(folderType: string) {
        let that = this;

        this.control = app.itemForm;
        document.title = this.title + " - " + matrixSession.getProject();

        this.control.prepend(ml.UI.getPageTitle(this.title));

        let progress = ml.UI.getSpinningWait("retrieving publication status").appendTo(this.control);

        this.body = $("<div class='panel-body-v-scroll fillHeight'>");
        this.control.append(this.body);
        this.body.hide();

        $(".toolbarButtons", app.itemForm).append(
            "<a href='" +
                globalMatrix.matrixBaseUrl +
                "/pub/" +
                matrixSession.getProject() +
                "' class='openPublish btn btn-default ' target='_blank'>Open published QMS</a>",
        );

        // get configuration
        let qmsConfig = globalMatrix.ItemConfig.getQMSConfig().publications.filter(function (conf) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return conf.toCategory == folderType;
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (qmsConfig.length != 1) {
            $(".spinningWait").html("<div>No publish configuration defined for this category</div>");
            return;
        }
        this.pubConfig = qmsConfig[0];

        if (
            this.pubConfig &&
            this.pubConfig.publisher &&
            !matrixSession.amIAllowedUser(this.pubConfig.publisher.split(","))
        ) {
            $(".spinningWait").html("<div>You have no rights to publish</div>");
            return;
        }

        this.unGrouped = []; // items which can be published without being in a group
        this.itemMap = {}; // all retrieved items by item id with publication meta info
        this.deletedStillPublished = []; // items which are still published but were deleted after
        this.selectedForPublication = []; // groups and items the user wants to (un)published after change
        this.selectedForUnPublication = []; // items which were selected to be removed from publication
        this.groups = [];

        // build groups for the publications
        $.each(this.pubConfig.rules, function (catIdx, category) {
            if (category.groupLabelType) {
                let labels = ml.LabelTools.getLabelsOfLabelGroupsType(category.groupLabelType);
                $.each(labels, function (labelIdx, label) {
                    let group: IPublicationGroup = {
                        groupInfo: category,
                        label: label,
                        groupItems: [],
                        approved: false,
                        needsPublication: false,
                    };
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (that.groups.filter((g) => g.label == group.label).length == 0) {
                        that.groups.push(group);
                    }
                });
            }
        });

        // retrieve all items from server which need to be published and check it the item or groups could be published
        this.getPublicationItemsAndGroups().done(function () {
            // add information about what has been published before
            that.addInfoLastPublication().done(function () {
                // add information if groups needs publishing (version of 1+ item in group changed)
                that.computePublicationNeeds();

                that.renderTabs();

                that.body.show();
                progress.remove();
            });
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderTabs() {
        // paint the tabs
        let tabPanel = $('<div role="tabpanel" class="tabpanel-container " style="">');
        this.body.append(tabPanel);

        // add the tabs
        let tabPanelUl = $('<ul class="nav nav-tabs contextFrameTabs" role="tablist">');
        tabPanelUl.append(
            '<li role="presentation" class="active"><a href="#PUBLISHOK"  role="tab" data-toggle="tab">Ready To Publish</a></li>',
        );
        tabPanelUl.append(
            '<li role="presentation"><a href="#PUBLISHNOK"  role="tab" data-toggle="tab">Not Ready to Publish</a></li>',
        );
        tabPanelUl.append(
            '<li role="presentation"><a href="#NONEED"  role="tab" data-toggle="tab">Not Modified</a></li>',
        );

        // add a place for the panels
        tabPanel.append(tabPanelUl);
        let tabPanels = $('<div class="tab-content" >');
        tabPanel.append(tabPanels);

        // add the panels for each tab
        let readyToPublish = $(
            '<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane active" id="PUBLISHOK" >',
        );
        tabPanels.append(readyToPublish);
        this.renderReadyToPublish(true, readyToPublish);

        let notReadyToPublish = $(
            '<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane" id="PUBLISHNOK" >',
        );
        tabPanels.append(notReadyToPublish);
        this.renderNotReadyToPublish(notReadyToPublish);

        let allreadyPublished = $(
            '<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane" id="NONEED" >',
        );
        tabPanels.append(allreadyPublished);
        this.renderReadyToPublish(false, allreadyPublished);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderPublishUi(panel: JQuery) {
        let that = this;

        let autoFill = <IAutoFillSetting>matrixSession.getCustomerSettingJSON("autoFillSettings", {});
        let allowFill = autoFill.allowAutoFill || autoFill.allowPublishAutoFill;

        let pt = $("<input autocomplete='off' type='text' class='form-control'>");
        let sig = $(
            "<input type='" + (allowFill ? "password" : "text") + "' class='form-control' placeholder='password'>",
        );
        let signButton = $("<button id='publishSOPs' class='btn btn-success'>Publish</button>");

        if (!allowFill) {
            sig.on("keyup", () => {
                sig.attr("type", "password");
            });
        }

        sig.on("keydown", (e, args) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (e.keyCode == 13) {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
                if (!signButton.is(":disabled")) {
                    signButton.click();
                }
            }
        });

        let trainingSelection = $(
            '<select class="form-control" style="width: 100%;height: 34px;"">' +
                '<option value="changedItems">Create training for changed items</option>' +
                '<option value="no">Do not create training notifications</option>' +
                '<option value="changedSOPs">Create training for changed item groups</option>' +
                '<option value="all">Create training for all items (whole QMS)</option>' +
                "</select>",
        );
        let title = $("<tr>")
            .append($("<td>").html("Publication title"))
            .append($("<td>").append(pt))
            .append($("<td>"));
        let training = $("<tr>")
            .append($("<td>").html("Training"))
            .append($("<td>").append(trainingSelection))
            .append($("<td>"));
        let sign = $("<tr>")
            .append($("<td>").html("Signature"))
            .append($("<td>").append($("<form>").append(sig)))
            .append($("<td>").append(signButton));

        let table = $("<table class='table borderless'>").insertBefore($(".nav-tabs", app.itemForm));
        let tbody = $("<tbody>").appendTo(table);
        tbody.append(title).append(training).append(sign);

        pt.val(ml.UI.DateTime.renderDashFormat(new Date()));
        this.enablePublish();

        signButton.click(function () {
            ml.UI.setEnabled(signButton, false);
            app.checkPassword(sig.val())
                .done(async function () {
                    // first tab
                    let checkedPublishGroups: JQuery[] = [];
                    for (let cb of that.selectedForPublication) {
                        if ((await cb.getController().getValueAsync()) && cb.data("groupItems") !== "") {
                            checkedPublishGroups.push(cb);
                        }
                    }
                    let itemsToBePublished: string[] = await that.getItemsFromCheckBoxes(
                        that.selectedForPublication,
                        SelectedEntity.ChangedItems,
                    );
                    let itemsToBeRemoved: string[] = await that.getItemsFromCheckBoxes(
                        that.selectedForPublication,
                        SelectedEntity.DeletedItems,
                    );
                    let itemsToBeUnpublished: string[] = await that.getItemsFromCheckBoxes(
                        that.selectedForUnPublication,
                        SelectedEntity.UnPublish,
                    );

                    // 3rd tab remove items selected to unpublish, if they are in publication group
                    itemsToBeUnpublished = itemsToBeUnpublished.filter(function (item) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return itemsToBePublished.indexOf(item) == -1;
                    });

                    that.publishSelected(
                        checkedPublishGroups,
                        itemsToBePublished,
                        itemsToBeRemoved,
                        itemsToBeUnpublished,
                        pt.val(),
                        trainingSelection.val(),
                    );
                })
                .fail(function (jqxhr, textStatus, error) {
                    ml.UI.setEnabled(signButton, true);
                    ml.UI.showError("Incorrect  password!", "");
                });
        });
    }

    // show everything which should and can be published or is already published
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderReadyToPublish(isForPublication: boolean, panel: JQuery) {
        let that = this;

        let selectAllReady = $("<span class='showMore'>");
        if (isForPublication) {
            that.renderPublishUi(panel);

            panel.append(
                "<p class='explainer'>Note: <b>select</b> any of the items below to <b>publish the latest version</b></p>",
            );
            // show select all button
            selectAllReady
                .html("select all")
                .click(async function (e) {
                    for (let cb of that.selectedForPublication) {
                        if (!(await cb.getController().getValueAsync())) {
                            $("input[type=checkbox]", cb).trigger("click");
                        }
                    }
                })
                .appendTo(panel);

            // show heading
            panel.append("<h2>Collections Ready to Publish</h2>");
        } else {
            panel.append(
                "<p class='explainer'>Note: <b>un-select</b> any of the items below to <b>remove</b> them from the publication</p>",
            );
            panel.append("<h2>Unchanged Collections</h2>");
        }

        // group publication
        let groupCount = 0;

        $.each(that.groups, function (idx, group) {
            if (group.approved && isForPublication && group.needsPublication) {
                that.selectedForPublication.push(that.showGroup(panel, group, isForPublication));
                groupCount++;
            } else if (group.approved && !isForPublication && !group.needsPublication) {
                that.selectedForUnPublication.push(that.showGroup(panel, group, isForPublication));
                groupCount++;
            }
        });
        if (!groupCount) {
            panel.append(
                isForPublication
                    ? "<div>No collections ready for publication</div>"
                    : "<div>No unchanged collections</div>",
            );
        }

        // individual item publication
        let singleCount = 0;
        panel.append(isForPublication ? "<h2>Items Ready to Publish</h2>" : "<h2>Unchanged Items</h2>");

        $.each(that.unGrouped, function (idx, itemId) {
            let item = that.itemMap[itemId];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (item.approved && isForPublication && item.lastPublishedVersion != item.itemInfo.version) {
                that.selectedForPublication.push(that.showItem(panel, item, isForPublication));
                singleCount++;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (item.approved && !isForPublication && item.lastPublishedVersion == item.itemInfo.version) {
                that.selectedForUnPublication.push(that.showItem(panel, item, isForPublication));
                singleCount++;
            }
        });
        if (!singleCount) {
            panel.append(
                isForPublication ? "<div>No items ready for publication</div>" : "<div>No unchanged items</div>",
            );
        }

        // deleted item update
        if (isForPublication) {
            let deleteCount = 0;
            $("<h2>Deleted Items to Remove From Publication</h2>").appendTo(panel);
            $.each(this.deletedStillPublished, function (idx, deleted) {
                that.selectedForPublication.push(that.showDeletedItem(panel, deleted.item, isForPublication));
                deleteCount++;
            });
            if (!deleteCount) {
                $("<div>No items currently published were deleted</div>").appendTo(panel);
            }

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (groupCount + singleCount + deleteCount == 0) {
                selectAllReady.remove();
            }
        }
    }

    // show everything which should and canNOT be published
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderNotReadyToPublish(panel: JQuery) {
        let that = this;

        // group publication
        let groupCount = 0;
        panel.append(
            "<p class='explainer'>Note: These collections cannot be published because some of the items are not approved!</p>",
        );
        $("<h2>Collections Not Ready to Publish</h2>").appendTo(panel);
        $.each(that.groups, function (idx, group) {
            if (!group.approved && group.needsPublication) {
                let cb = that.showGroup(panel, group, true);
                $("input", cb).prop("disabled", true);
                groupCount++;
            }
        });
        if (!groupCount) {
            $("<div>No groups not ready for publication</div>").appendTo(panel);
        }

        // individual item publication
        let singleCount = 0;
        $("<h2>Items Not Ready to Publish</h2>").appendTo(panel);
        $.each(that.unGrouped, function (idx, itemId) {
            let item = that.itemMap[itemId];
            if (!item.approved) {
                that.showItem(panel, item, true);
                singleCount++;
            }
        });
        if (!singleCount) {
            $("<div>No items not ready for publication</div>").appendTo(panel);
        }
    }

    // enable the publish button if there's at least one publishable selected
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async enablePublish() {
        let canPublish = false;
        for (let cb of this.selectedForPublication) {
            if (await cb.getController().getValueAsync()) {
                canPublish = true;
            }
        }
        for (let cb of this.selectedForUnPublication) {
            if (await cb.getController().getValueAsync()) {
                canPublish = true;
            }
        }
        ml.UI.setEnabled($("#publishSOPs"), canPublish);
    }

    // show selector to publish a group
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showGroup(panel: JQuery, group: IPublicationGroup, needsPublish: boolean) {
        let that = this;
        let cb = $("<div></div>")
            .appendTo(panel)
            .checkBox({
                canEdit: true,
                help: group.groupInfo.groupName + " " + ml.CreateNewLabelTools().getDisplayName(group.label),
                valueChanged: function () {
                    that.enablePublish();
                    that.updateItems();
                },
                parameter: {},
                fieldValue: needsPublish ? "0" : "1",
            })
            .data("groupLabel", group.label)
            .data("groupName", group.groupInfo.groupName)
            .data("groupItems", group.groupItems);
        if (needsPublish) {
            // view change details
            $("<span class='showMore'>")
                .html(" show details")
                .click(function (e) {
                    that.showGroupDetails(group);
                })
                .appendTo($("label", cb).parent());
            $(".baseControl", cb).addClass("rowFlexBaseLine");
        }
        return cb;
    }

    // this changes items which are also in a group, if a group is selected, the items are automatically selected and disabled
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async updateItems() {
        // get items from selected groups
        let items = await this.getItemsFromCheckBoxes(this.selectedForPublication, SelectedEntity.ItemsInGroupsOnly);
        for (let cb of this.selectedForPublication) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (cb.data("itemId") && items.indexOf(cb.data("itemId")) != -1) {
                if (!(await cb.getController().getValueAsync())) {
                    $("input[type=checkbox]", cb).trigger("click");
                }
                $("input[type=checkbox]", cb).attr("disabled", "disabled");
            } else {
                $("input[type=checkbox]", cb).removeAttr("disabled");
            }
        }
    }

    // retrieve items from checkboxes
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async getItemsFromCheckBoxes(cbs: JQuery[], what: SelectedEntity) {
        let items: string[] = [];
        // get all from groups
        for (let cb of cbs) {
            if (cb.data("groupItems")) {
                // checkbox belongs to a group
                if (
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    ((what == SelectedEntity.ItemsInGroupsOnly || what == SelectedEntity.ChangedItems) &&
                        (await cb.getController().getValueAsync())) ||
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    (what == SelectedEntity.UnPublish && !(await cb.getController().getValueAsync()))
                ) {
                    // a selected group, add all the items inside
                    $.each(cb.data("groupItems"), function (iidx, item) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (items.indexOf(item) == -1) {
                            items.push(item);
                        }
                    });
                }
            }
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (what == SelectedEntity.ItemsInGroupsOnly) {
            return items;
        }
        // add additionally selected items
        for (let cb of cbs) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (cb.data("itemId") && what == SelectedEntity.UnPublish && !(await cb.getController().getValueAsync())) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (items.indexOf(cb.data("itemId")) == -1) {
                    items.push(cb.data("itemId"));
                }
            } else if (
                cb.data("itemId") &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                what == SelectedEntity.ChangedItems &&
                (await cb.getController().getValueAsync())
            ) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (items.indexOf(cb.data("itemId")) == -1) {
                    items.push(cb.data("itemId"));
                }
            } else if (
                cb.data("deletedItemId") &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                what == SelectedEntity.DeletedItems &&
                (await cb.getController().getValueAsync())
            ) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (items.indexOf(cb.data("deletedItemId")) == -1) {
                    items.push(cb.data("deletedItemId"));
                }
            }
        }

        return items;
    }

    // show selector to publish a item
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showItem(panel: JQuery, item: IToPublish, needsPublish: boolean) {
        let that = this;
        let cb = $("<div></div>")
            .appendTo(panel)
            .checkBox({
                canEdit: true,
                help: ml.Item.renderLink(item.itemInfo.itemId, item.itemInfo.title, true).html(),
                valueChanged: function () {
                    that.enablePublish();
                },
                parameter: {},
                fieldValue: needsPublish ? "0" : "1",
            })
            .data("itemId", item.itemInfo.itemId);
        if (needsPublish) {
            // view change details
            $("<span class='showMore'>")
                .html(" show details")
                .click(function (e) {
                    that.showItemDetails(item);
                })
                .appendTo($("label", cb).parent());
            $(".baseControl", cb).addClass("rowFlexBaseLine");
        }
        return cb;
    }

    // show selector to un-publish a deleted item
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showDeletedItem(panel: JQuery, itemId: string, canSelect: boolean) {
        let that = this;
        let cb = $("<div>")
            .appendTo(panel)
            .checkBox({
                canEdit: canSelect,
                help: itemId,
                valueChanged: function () {
                    that.enablePublish();
                },
                parameter: {},
                fieldValue: "0",
            })
            .data("deletedItemId", itemId);

        return cb;
    }
    // show details about group
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showGroupDetails(group: IPublicationGroup) {
        let that = this;

        let th = "<tr><th>Item</th><th>Last Published Version</th><th>Current Version</th><th>Approved</th>";
        let tb = $("<tbody>");

        // new publishable items
        $.each(group.groupItems, function (pidx, itemId) {
            let item = that.itemMap[itemId];
            if (!item.lastPublishedVersion) {
                tb.append(
                    $("<tr class='sopItemStatus'>")
                        .append($("<td>").append(ml.Item.renderLink(itemId, item.itemInfo.title, true)))
                        .append($("<td>").html("new"))
                        .append($("<td>").html("" + item.itemInfo.version))
                        .append($("<td>").html(item.approved ? "yes" : "no")),
                );
            }
        });

        // changed items
        $.each(group.groupItems, function (pidx, itemId) {
            let item = that.itemMap[itemId];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (item.lastPublishedVersion && item.lastPublishedVersion != item.itemInfo.version) {
                let compare = $("<span class='showMore'>compare versions</span>").click(function () {
                    let ht = new HistoryTools();
                    ht.compareVersions(itemId, item.lastPublishedVersion, item.itemInfo.version);
                });
                tb.append(
                    $("<tr>")
                        .append($("<td>").append(ml.Item.renderLink(itemId, item.itemInfo.title, true)))
                        .append($("<td>").html("" + item.lastPublishedVersion))
                        .append(
                            $("<td>")
                                .append($("<span>" + item.itemInfo.version + " </span>"))
                                .append(compare),
                        )
                        .append($("<td>").html(item.approved ? "yes" : "no")),
                );
            }
        });

        // unchanged items
        $.each(group.groupItems, function (pidx, itemId) {
            let item = that.itemMap[itemId];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (item.lastPublishedVersion && item.lastPublishedVersion == item.itemInfo.version) {
                tb.append(
                    $("<tr>")
                        .append($("<td>").append(ml.Item.renderLink(itemId, item.itemInfo.title, true)))
                        .append($("<td colspan='3'>").html("published")),
                );
            }
        });

        let infoTable = $("<table class='table table-bordered table-hover'>")
            .append("<thead>" + th + "</thead>")
            .append(tb);

        app.dlgForm.html("");
        app.dlgForm.append(infoTable);
        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.removeClass("dlg-no-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: group.groupInfo.groupName + " " + ml.CreateNewLabelTools().getDisplayName(group.label),
                height: app.itemForm.height() * 0.9,
                width: $(document).width() * 0.9,
                modal: true,
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                close: function () {},
                open: function () {
                    // otherwise there's some tooltip (which is somehow triggered by focusing something in the table)
                    $(".tooltip").hide();
                },
                resizeStop: function (event, ui) {
                    app.dlgForm.resizeDlgContent([]);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnCancelIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([], false);
    }

    // show details about group
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showItemDetails(item: IToPublish) {
        let ht = new HistoryTools();
        ht.compareVersions(item.itemInfo.itemId, item.lastPublishedVersion, item.itemInfo.version);
    }

    // add information about last publication (last publication version, item)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addInfoLastPublication(): JQueryDeferred<{}> {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let res: JQueryDeferred<{}> = $.Deferred();
        // get all items in category as flat list
        app.searchAsync("mrql:category=" + this.pubConfig.toCategory, "", true).done(function (searchResults) {
            // get item ids, sort by ref (highest number first!)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let ids = searchResults
                .map(function (value) {
                    return Number(value.itemId.replace(that.pubConfig.toCategory + "-", ""));
                })
                .sort(function (a, b) {
                    return a < b ? 1 : -1;
                });

            if (ids.length) {
                let versionsFields = globalMatrix.ItemConfig.getFieldsOfType(
                    "publishedItemList",
                    that.pubConfig.toCategory,
                );
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (versionsFields.length == 0) {
                    ml.UI.showError(
                        that.pubConfig.toCategory + "not correctly configured.",
                        "Missing field of type 'publishedItemList'",
                    );
                    res.reject();
                }
                // get newest item
                app.getItemAsync(that.pubConfig.toCategory + "-" + ids[0]).done(function (item) {
                    let field = (<INumberStringMap>item)[versionsFields[0].field.id];
                    if (field) {
                        // handle different ways to save parsed items (over time that changed...)
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        let parsed = <any>JSON.parse(field);
                        if (parsed && parsed.publishedItems) {
                            parsed = parsed.publishedItems;
                        }
                        if (parsed) {
                            $.each(<IPublished[]>parsed, function (pidx, p) {
                                if (that.itemMap[p.item]) {
                                    that.itemMap[p.item].lastPublishedVersion = p.version;
                                    that.itemMap[p.item].wis = p.wis;
                                    that.itemMap[p.item].sop = p.sop;
                                } else {
                                    that.deletedStillPublished.push(p);
                                }
                            });
                        }
                    }
                    res.resolve();
                });
            } else {
                // very first time, nothing has been published
                res.resolve();
            }
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private computePublicationNeeds() {
        let that = this;

        $.each(that.groups, function (groupIdx, group) {
            group.needsPublication =
                group.groupItems.filter(function (item) {
                    // the item needs to be published if at least one version of at least one item inside changed
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return that.itemMap[item].lastPublishedVersion != that.itemMap[item].itemInfo.version;
                }).length > 0;
        });
    }
    // get from server all items which can be published together with meta data
    // check if each item can be published (== all required  review labels set)
    // check if there are groups of items which can be published (only) together, and
    // if these groups can be published (all required  for labels for all items set)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getPublicationItemsAndGroups(): JQueryDeferred<{}> {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let res: JQueryDeferred<{}> = $.Deferred();

        // user fields
        let userFields = globalMatrix.ItemConfig.getFieldsOfType("user")
            .map(function (field) {
                return field.field.id;
            })
            .join(",");
        // get all current items
        let query = this.pubConfig.rules
            .map(function (pubCat) {
                return "category=" + pubCat.category;
            })
            .join(" or ");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.searchAsync("mrql:(" + query + ")", "", true, userFields, null, true, true, false, true).done(
            function (searchResults) {
                // for each item, get the review status, and figure out if it can be published independent of groups
                $.each(searchResults, function (sri, sr) {
                    let category = ml.Item.parseRef(sr.itemId).type;
                    let categoryPublicationConfig = that.pubConfig.rules.filter(function (pc) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return pc.category == category;
                    })[0];

                    // in case there must be some review label(s) set, check that they are set
                    let isReviewed = true;
                    $.each(categoryPublicationConfig.readyLabels, function (rli, rl) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        isReviewed = isReviewed && sr.labels.indexOf(rl) != -1;
                    });

                    let rolesAndUsers: string[] = [];
                    if (sr.fieldVal) {
                        $.each(sr.fieldVal, function (uFIdx, userField) {
                            if (userField.value) {
                                $.each(userField.value.split(","), function (ufvidx, ufv) {
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    if (rolesAndUsers.indexOf(ufv) == -1) {
                                        rolesAndUsers.push(ufv);
                                    }
                                });
                            }
                        });
                    }

                    // create publication info and put in map
                    let pwi: IToPublish = {
                        itemInfo: sr, // search result
                        approved: isReviewed,
                        lastPublishedVersion: 0, // will be filled later
                        wis: [],
                        sop: "",
                        rolesAndUsers: rolesAndUsers,
                    };
                    that.itemMap[sr.itemId] = pwi;

                    // check if item is in a group, if so add it
                    let isInGroup = false;
                    $.each(that.groups, function (groupIdx, group) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (sr.labels.indexOf(group.label) != -1 && group.groupItems.indexOf(sr.itemId) == -1) {
                            isInGroup = true;
                            group.groupItems.push(sr.itemId);
                        }
                    });
                });

                // fill all the groups with down traces, check if the group can be published
                $.each(that.groups, function (groupIdx, group) {
                    $.each(group.groupInfo.groupDown, function (downIdx, down) {
                        // down is a category name which needs to be linked (e.g. for a PROC it would be WI)
                        $.each(group.groupItems, function (groupItemIdx, groupItem) {
                            // for all items in the group, e.g. PROCs
                            $.each(that.itemMap[groupItem].itemInfo.downlinks, function (dlIdx, dl) {
                                // check all downlinks if they are of the given type
                                let itemRef = ml.Item.parseRef(dl);
                                if (
                                    that.itemMap[itemRef.id] &&
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    itemRef.type == down &&
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    group.groupItems.indexOf(itemRef.id) == -1
                                ) {
                                    // item is being published, and it's a good type
                                    group.groupItems.push(itemRef.id);
                                }
                            });
                        });
                    });

                    // can be published?
                    group.approved =
                        group.groupItems.filter(function (groupItem) {
                            return that.itemMap[groupItem].approved;
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                        }).length == group.groupItems.length;
                });

                // now build a list of all items which can be published independently of a group
                $.each(searchResults, function (sri, sr) {
                    let category = ml.Item.parseRef(sr.itemId).type;
                    let categoryPublicationConfig = that.pubConfig.rules.filter(function (pc) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return pc.category == category;
                    })[0];

                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (categoryPublicationConfig.itemRules == EnumItemPublish.Always) {
                        that.unGrouped.push(sr.itemId);
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                    } else if (categoryPublicationConfig.itemRules == EnumItemPublish.IfNotInGroup) {
                        let isInGroup = false;
                        $.each(that.groups, function (groupIdx, group) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (group.groupItems.indexOf(sr.itemId) != -1) {
                                isInGroup = true;
                            }
                        });
                        // remember if item can be published independent of group
                        if (!isInGroup) {
                            that.unGrouped.push(sr.itemId);
                        }
                    }
                });
                res.resolve();
            },
        );

        return res;
    }

    // create a message for user to summarize and acknowledge the changes
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private publishSelected(
        selectedChangedGroups: JQuery[],
        selectedChangedItems: string[],
        selectedDeletedItems: string[],
        selectedUnPublishItems: string[],
        publicationTitle: string,
        trainingInfo: string,
    ) {
        let that = this;

        // create a list of published groups (selected changed groups)
        let publishedGroups = that.groups.filter(function (group) {
            let wasSelected = false;
            $.each(selectedChangedGroups, function (cgidx, cb) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (cb.data("groupLabel") == group.label && cb.data("groupName") == group.groupInfo.groupName) {
                    wasSelected = true;
                }
            });
            return wasSelected;
        });

        let pubInfo = $("<div>");
        pubInfo.append(
            $("<p>").html(
                "<b>Change summary</b>" + matrixSession.getComment()
                    ? matrixSession.getComment()
                    : "please describe change",
            ),
        );
        pubInfo.append($("<hr>"));

        if (publishedGroups.length) {
            pubInfo.append($("<p>The following collections were changed</p>"));
            let ul = $("<ul>").appendTo(pubInfo);
            $.each(publishedGroups, function (pgidx, pg) {
                $("<li>")
                    .appendTo(ul)
                    .html(pg.groupInfo.groupName + " " + ml.CreateNewLabelTools().getDisplayName(pg.label));
            });
        }

        let userRolesToInform: string[] = [];
        if (selectedChangedItems.length) {
            pubInfo.append($("<br><p>The following items were changed/added</p>"));
            let ul = $("<ul>").appendTo(pubInfo);
            $.each(selectedChangedItems, function (iidx, item) {
                let last = that.itemMap[item].lastPublishedVersion;
                let current = that.itemMap[item].itemInfo.version;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (last != current) {
                    let compare = $(
                        "<span contenteditable='false' title='click to see details' class='addLink showMore' data-item='" +
                            item +
                            "' data-v0=" +
                            last +
                            " data-v1=" +
                            current +
                            ">" +
                            item +
                            "</span>",
                    );

                    $("<li>")
                        .appendTo(ul)
                        .append(compare)
                        .append("<span> " + that.itemMap[item].itemInfo.title + "</span>");
                }
                $.each(that.itemMap[item].rolesAndUsers, function (roleIdx, role) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (userRolesToInform.indexOf(role) == -1) {
                        userRolesToInform.push(role);
                    }
                });
            });
        }

        if (selectedDeletedItems.length) {
            pubInfo.append($("<br><p>The following items were removed</p>"));
            let ul = $("<ul>").appendTo(pubInfo);
            $.each(selectedDeletedItems, function (iidx, item) {
                $("<li>")
                    .appendTo(ul)
                    .html("<span> " + item + "</span>");
            });
        }
        if (selectedUnPublishItems.length) {
            pubInfo.append($("<br><p>The following items were removed from the publication</p>"));
            let ul = $("<ul>").appendTo(pubInfo);
            $.each(selectedUnPublishItems, function (iidx, item) {
                $("<li>")
                    .appendTo(ul)
                    .html("<span> " + item + "  " + that.itemMap[item].itemInfo.title + "</span>");
            });
        }
        let text = pubInfo.html();

        let dlg = $("#editFieldDlg");
        dlg.html("");
        dlg.removeClass("dlg-no-scroll");
        dlg.addClass("dlg-v-scroll");

        let rte = $("<div>");
        dlg.append($("<div>").append(rte));

        rte.richText({
            fieldValue: text,
            canEdit: true,
            controlState: ControlState.FormEdit,
            help: " ",
            parameter: { height: 310, tableMode: true, autoEdit: true, autoFocus: true, tiny: true },
        });

        let padding = 28;
        dlg.dialog({
            autoOpen: true,
            title: "Edit Change Comment",
            height: 550,
            width: 730,
            modal: true,
            resize: function () {
                $(".note-editable", rte).height(dlg.height() - 65);
                $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
            },
            resizeStop: function () {
                $(".note-editable", rte).height(dlg.height() - 65);
                $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
            },
            closeOnEscape: false, // escape is annoying because it cannot be undone and it can happen when entering tables
            open: function () {
                padding = $("#editFieldDlg").parent().width() - $("#editFieldDlg").width();
                ml.UI.pushDialog(dlg);
                $(".showMore", rte).click(function (event: JQueryEventObject) {
                    let ht = new HistoryTools();
                    let link = $(event.delegateTarget);
                    ht.compareVersions(link.data("item"), link.data("v0"), link.data("v1"));
                });
            },
            close: function () {
                ml.UI.popDialog(dlg);
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: async function () {
                        let html = $("<div>").html(await rte.getController().getValueAsync());
                        //$(".showMore", html).remove();
                        $.each($(".addLink", html), function (lidx, link) {
                            $(link).replaceWith(
                                $(
                                    "<a href='" +
                                        globalMatrix.matrixBaseUrl +
                                        "/pub/" +
                                        matrixSession.getProject() +
                                        "?show=" +
                                        $(link).text() +
                                        "'>" +
                                        $(link).text() +
                                        "</a>",
                                ),
                            );
                        });
                        that.doPublish(
                            userRolesToInform,
                            html.html(),
                            selectedChangedItems,
                            selectedDeletedItems,
                            selectedUnPublishItems,
                            publicationTitle,
                            trainingInfo,
                        );
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
        });
    }

    // get recursively all downlinked item, for the which the category should be published
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getIncludedDownlinksRec(itemId: string, linkedItems: string[]) {
        let that = this;
        $.each(that.itemMap[itemId].itemInfo.downlinks, function (iidx: number, item) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (linkedItems.indexOf(item) == -1) {
                // item is not already in the list
                let category = ml.Item.parseRef(item).type;
                let rules = that.pubConfig.rules.filter(function (pc) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return pc.category == category;
                });
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (rules.length == 1) {
                    // category of item is included in publication
                    // include it
                    linkedItems.push(item);
                    // process children
                    that.getIncludedDownlinksRec(item, linkedItems);
                }
            }
        });
    }

    // create the publication item

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private doPublish(
        roles: string[],
        comment: string,
        publishedItems: string[],
        selectedDeletedItems: string[],
        selectedUnPublishItems: string[],
        publicationTitle: string,
        trainingInfo: string,
    ) {
        let that = this;

        if (this.pubConfig.keepFlatList) {
            this.doPublishInFolder(
                roles,
                "F-" + this.pubConfig.toCategory + "-1",
                comment,
                publishedItems,
                selectedDeletedItems,
                selectedUnPublishItems,
                publicationTitle,
                trainingInfo,
            );
        } else {
            this.createDateFolder().done(function (target) {
                that.doPublishInFolder(
                    roles,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    target,
                    comment,
                    publishedItems,
                    selectedDeletedItems,
                    selectedUnPublishItems,
                    publicationTitle,
                    trainingInfo,
                );
            });
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private doPublishInFolder(
        roles: string[],
        targetFolder: string,
        comment: string,
        publishedItems: string[],
        selectedDeletedItems: string[],
        selectedUnPublishItems: string[],
        publicationTitle: string,
        trainingInfo: string,
    ) {
        let that = this;

        // figure out who should get training
        let itemsToBeTrained: string[] = [];

        // get all the information for each item which is to be published
        let newPublicationItems: INewPublication[] = [];
        $.each(publishedItems, function (pidx, item) {
            let references: string[] = [];
            that.getIncludedDownlinksRec(item, references);

            let labels = that.groups
                .filter(function (group) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return group.groupItems.indexOf(item) != -1;
                })
                .map(function (group) {
                    return group.label;
                });
            newPublicationItems.push({
                item: item,
                version: that.itemMap[item].itemInfo.version,
                wis: references,
                sop: labels.join(","),
            });
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (trainingInfo == "changedSOPs" || trainingInfo == "changedItems") {
            itemsToBeTrained = newPublicationItems.map(function (np) {
                return np.item;
            });
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (trainingInfo == "changedItems") {
                // filter by items which really changed
                itemsToBeTrained = itemsToBeTrained.filter(function (itemId) {
                    let item = that.itemMap[itemId];
                    return (
                        !item.lastPublishedVersion || // new item
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        item.lastPublishedVersion != item.itemInfo.version
                    ); // item was modified
                });
            }
        }

        // add all the other previously published items/versions unless they should be deleted
        $.each(this.itemMap, function (itemId: string, itemDetails: IToPublish) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (selectedDeletedItems.indexOf(itemId) == -1 && selectedUnPublishItems.indexOf(itemId) == -1) {
                // keep published - if
                if (
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    itemDetails.lastPublishedVersion != 0 && // item was published before
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    publishedItems.indexOf(itemId) == -1
                ) {
                    // item not selected to be republished
                    newPublicationItems.push({
                        item: itemId,
                        version: that.itemMap[itemId].lastPublishedVersion, // keep the last!
                        wis: that.itemMap[itemId].wis,
                        sop: that.itemMap[itemId].sop,
                    });
                }
            }
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (trainingInfo == "all") {
            itemsToBeTrained = newPublicationItems.map(function (np) {
                return np.item;
            });
        }

        // add items which were deleted but are still published
        $.each(this.deletedStillPublished, function (idx, di) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (selectedDeletedItems.indexOf(di.item) == -1) {
                // the item was not checked to be removed
                newPublicationItems.push(di);
            }
        });
        // now create a new item
        let newPub: IItemCopyBuffer = {};
        newPub[globalMatrix.ItemConfig.getFieldsOfType("publishedItemList", this.pubConfig.toCategory)[0].field.id] =
            JSON.stringify(newPublicationItems);

        newPub[globalMatrix.ItemConfig.getFieldsOfType("publishedTitle", this.pubConfig.toCategory)[0].field.id] =
            publicationTitle ? publicationTitle : ml.UI.DateTime.renderDashFormat(new Date());

        ml.UI.BlockingProgress.Init([{ name: "Publishing" }]);

        newPub.title = "Published " + ml.UI.DateTime.renderDashFormat(new Date());
        app.createItemOfTypeAsync(that.pubConfig.toCategory, newPub, "Published", targetFolder)
            .fail(function () {
                ml.UI.BlockingProgress.SetProgressError(0, `Error creating publication`);
                ml.UI.showError("Error creating publication", "");
            })
            .done(function (createdPub) {
                // build history of publications...
                let lastPub = <IPublicationHistory>globalMatrix.ItemConfig.getSettingJSON("lastPublication");
                if (!lastPub || !lastPub.history) {
                    lastPub = { history: [] };
                }

                lastPub.history.splice(0, 0, {
                    date: ml.UI.DateTime.renderHumanDate(new Date()),
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    item: createdPub.item.id,
                    comment: comment,
                });
                app.setSettingJSON("lastPublication", lastPub)
                    .fail(function () {
                        ml.UI.BlockingProgress.SetProgressError(0, `Failed to publish: Could not set publish comment`);
                        ml.UI.showError("Error publishing", "Could not set publish comment");
                    })
                    .done(function () {
                        ml.UI.BlockingProgress.SetProgress(0, 5);
                        restConnection
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .postProject("publish/" + createdPub.item.id, {
                                reason: comment,
                                trainingFor: itemsToBeTrained.join(","),
                            })
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            .done(function (job: any) {
                                $("#publishing").html("").append(ml.UI.getSpinningWait("publishing"));
                                ml.UI.BlockingProgress.SetProgress(0, 10);
                                that.waitForPublication(roles, comment, job.jobId, 10);
                            })
                            .fail(function (jqxhr, textStatus, error) {
                                ml.UI.BlockingProgress.SetProgressError(
                                    0,
                                    `Our servers cannot fetch the image from ${textStatus}`,
                                );
                                ml.UI.showError("Error publishing", "Error was: " + textStatus);
                            });
                    });
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private waitForPublication(roles: string[], comment: string, jobId: number, progressUi: number) {
        let that = this;

        restConnection.getProject("job/" + jobId).done(function (result) {
            const progress = result as XRGetProject_JobStatus_JobsStatusWithUrl;

            if (progress.status === "Error" || progress.status.indexOf("Report generation error") === 0) {
                ml.UI.showError("Error during publish", "");
                $("#publishing").html("publish failed");
            } else if (progress.progress < 100) {
                progressUi = progress.progress;
                if (progressUi > 99) {
                    progressUi = 99;
                }
                ml.UI.BlockingProgress.SetProgress(0, progressUi);
                window.setTimeout(function () {
                    that.waitForPublication(roles, comment, jobId, progressUi);
                }, 500);
            } else if (progress.progress > 100) {
                ml.UI.BlockingProgress.SetProgressError(0, `Error during publish`);
                ml.UI.showError("Error during publish", progress.status ? progress.status : "");
                $("#publishing").html("publish failed");
            } else {
                ml.UI.BlockingProgress.SetProgress(0, 100);
                ml.UI.showSuccess("Successfully published");
                app.treeSelectionChangeAsync(app.getCurrentItemId());

                let cannedMessage = ml.Mail.getCannedMessage("qms_published", "", "", undefined, comment);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.Mail.sendMailDlg(roles.join(","), null, "QMS change", cannedMessage, "");
            }
        });
    }

    // move publication into a a folder YYYY/MM
    private createDateFolder(): JQueryDeferred<string> {
        let year = "" + new Date().getFullYear();
        let month = ("" + (new Date().getMonth() + 101)).substr(1);

        return this.createFolders(this.pubConfig.toCategory, "F-" + this.pubConfig.toCategory + "-1", [year, month]);
    }

    // create folder hierarchy if needed, return leave folder
    private createFolders(category: string, parent: string, titles: string[]): JQueryDeferred<string> {
        let that = this;
        let res: JQueryDeferred<string> = $.Deferred();

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (titles.length == 0) {
            res.resolve(parent);
            return res;
        }

        let next = titles.splice(0, 1)[0];

        let nextParents = app.getChildrenIds(parent).filter(function (child) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return ml.Item.parseRef(child).isFolder && app.getItemTitle(child) == next;
        });

        if (nextParents.length) {
            return this.createFolders(category, nextParents[0], titles);
        }

        app.createItemOfTypeAsync(category, { children: [], title: next }, "Created", parent).done(function (result) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.createFolders(category, result.item.id, titles).done(function (folder) {
                res.resolve(folder);
            });
        });

        return res;
    }
}
