import { ml } from "../../matrixlib";
import { ItemControl } from "../Components/index";
import { IItem, IRestParam, IAnyMap, ControlState, globalMatrix, app, matrixApplicationUI } from "../../../globals";

export { Zen };

interface IZenConfig {
    width: string;
}

class Zen {
    private mainItem: IItem;
    private isMobile = false;

    constructor(item: IItem) {
        this.mainItem = item;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let that = this;
        let mobile = urlParams.has("mobile");

        if (mobile) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (ml.Item.parseRef(app.getCurrentItemId()).type == "SIGN") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let signCreationDate = new Date(this.mainItem.history[this.mainItem.history.length - 1].date);
                // TODO this is not sufficient: DOC could have uplink to SPEC so need to check all uplinks rather than taking first
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let vl = this.mainItem.upLinkList[0].itemRef;
                let theDoc = ml.Item.parseRef(vl);
                app.getItemAsync(theDoc.id).done(async function (docWithHistory) {
                    // go through history of DOC and find the version which existed just before creating the SIGN
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let docIdx = docWithHistory.history.length - 1;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    while (docIdx >= 0 && new Date(docWithHistory.history[docIdx].date) < signCreationDate) {
                        docIdx--;
                    }
                    // that's the version used to create the SIGN
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let docVersion = docWithHistory.history[docIdx + 1].version;
                    that.showVersion(
                        theDoc.id + "-v" + docVersion,
                        app.getCurrentItemId(),
                        await matrixApplicationUI.lastMainItemForm.getCurrentTitle(),
                    );
                });
            } else {
                this.showZen(this.mainItem);
            }
        }
    }

    // public functions
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    stopZen() {
        $("#zen").remove();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async toggleZen() {
        let that = this;

        if ($("#zen").length > 0) {
            $("#zen").remove();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (ml.Item.parseRef(app.getCurrentItemId()).type == "SIGN") {
            // show preview of the correct version of the DOC

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let signCreationDate = new Date(this.mainItem.history[this.mainItem.history.length - 1].date);
            // TODO this is not sufficient: DOC could have uplink to SPEC so need to check all uplinks rather than taking first
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let vl = this.mainItem.upLinkList[0].itemRef;
            let theDoc = ml.Item.parseRef(vl);
            app.getItemAsync(theDoc.id).done(async function (docWithHistory) {
                // go through history of DOC and find the version which existed just before creating the SIGN
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let docIdx = docWithHistory.history.length - 1;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                while (docIdx >= 0 && new Date(docWithHistory.history[docIdx].date) < signCreationDate) {
                    docIdx--;
                }
                // that's the version used to create the SIGN
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let docVersion = docWithHistory.history[docIdx + 1].version;
                that.showVersion(
                    theDoc.id + "-v" + docVersion,
                    app.getCurrentItemId(),
                    await matrixApplicationUI.lastMainItemForm.getCurrentTitle(),
                );
            });
        } else {
            // show preview of the current values
            let getCurrentValues: IItem = {};
            await matrixApplicationUI.lastMainItemForm.getValues(getCurrentValues);
            getCurrentValues.title = await matrixApplicationUI.lastMainItemForm.getCurrentTitle();
            that.showCurrentVersion(getCurrentValues);
        }
    }

    // show an (old) version of an item in a dialog
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showVersion(itemVersionId: string, actualId: string, actualTitle: string) {
        let that = this;
        let item = ml.Item.parseRef(itemVersionId);

        app.getItemAsync(item.id, item.version).done(function (item) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            item.title = actualTitle;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            item.id = actualId;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.showZen(item);
        });
    }

    protected showCurrentVersion(localChanges: IRestParam): void {
        let that = this;
        app.getItemAsync(app.getCurrentItemId()).done(function (item) {
            // make a copy of item and copy in all the changes from the UI
            let copyOfItem = ml.JSON.clone(item);
            $.each(copyOfItem, function (key, val) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (localChanges[key] != null) {
                    copyOfItem[key] = localChanges[key];
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (localChanges["fx" + key] != null) {
                    copyOfItem[key] = localChanges["fx" + key];
                }
            });
            copyOfItem.title = localChanges.title;
            // show the item

            that.showZen(copyOfItem);
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async showZen(currentItem: IItem) {
        let that = this;

        let zenConf = globalMatrix.serverStorage.getItem("zen");
        let zenMode: IZenConfig = zenConf ? JSON.parse(zenConf) : { width: "portrait" };

        // replace smart text in the fields also replace char(10) with \\n
        if (that.isMobile) {
            zenMode = { width: "full" };
        }

        // zen mode settings (e.g. width of displayed text)
        let zen = $("<div id='zen' class='fullscreen' style='z-index:10000'>").appendTo("body");
        $(
            `<div class='message_close_big'>${
                that.isMobile ? "<i class='fal fa-edit'></i>" : "<i class='fal fa-window-close'></i>"
            }</div>`,
        )
            .appendTo(zen)
            .click(function () {
                if (that.isMobile) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if ((window as any).applyResponsiveView != undefined && window.innerWidth < 900) {
                        localStorage.setItem("mobileLayout", "0");
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        (window as any).applyResponsiveView();
                    }
                }
                zen.remove();
            });

        $(`<div class="dropdown" style="position: absolute;top: 4px;left: 30px;">
                <button class="btn btn-xs dropdown-toggle" type="button" data-toggle="dropdown" style="background-color: white; color: grey;">
                    <span id="zenWidthSelect" >${zenMode.width}</span>&nbsp;
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                    <li class="zenWidthSelect" data-zenwidth="portrait"><a href="javascript:void(0)">portrait a4/letter</a></li>
                    <li class="zenWidthSelect" data-zenwidth="landscape"><a href="javascript:void(0)">landscape a4/letter</a></li>
                    <li class="zenWidthSelect" data-zenwidth="full"><a href="javascript:void(0)">full</a></li>
                </ul>
                </div>`).appendTo(zen);
        let contentWrapper = $("<div class='fullscreenWrapper'>").appendTo(zen);
        let content = $("<div class='fullscreenContent'>").appendTo(contentWrapper);
        this.applyZenMode(content, zenMode);
        await that.renderItemMeat(content, currentItem);
        this.makeZen(content);
        ml.SmartText.showTooltips(content);
        content.removeClass("panel-body-v-scroll");
        $(".zenWidthSelect").click(async function (event: JQueryMouseEventObject) {
            zenMode.width = $(event.delegateTarget).data("zenwidth");
            that.applyZenMode(content, zenMode);
            content.html("");
            await that.renderItemMeat(content, currentItem);
            that.makeZen(content);
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private applyZenMode(content: JQuery, zenMode: IZenConfig) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (zenMode.width == "portrait") {
            content.addClass("a4fullwidth").removeClass("a4landscape");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (zenMode.width == "landscape") {
            content.removeClass("a4fullwidth").addClass("a4landscape");
        } else {
            content.removeClass("a4fullwidth").removeClass("a4landscape");
        }
        $("#zenWidthSelect").html(zenMode.width);
        globalMatrix.serverStorage.setItem("zen", JSON.stringify(zenMode));
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private makeZen(content: JQuery) {
        $(".itemTitleBarNoToolsNoEdit", content).prev().remove();
        $(".textEditContainer", content).css("max-width", "unset");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async renderItemMeat(hi: JQuery, currentItem: IItem) {
        let itemForm = new ItemControl({
            control: hi,
            controlState: ControlState.Zen,
            isHistory: 1,
            item: currentItem,
            isItem: true,
            parameter: {
                reviewMode: true, // don't show outdated icons
            },
        });
        await itemForm.load();
        let itemDetails = $(".panel-body-v-scroll", hi);

        ml.SmartText.prepareForReadReadRender(itemDetails);
    }
}
