import { IDHFSection, IDHFControlDefinition } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import {
    IItemIndexOptions,
    ITEM_INDEX_FORMAT,
} from "../../../common/businesslogic/FieldHandlers/Document/ItemIndexDocFieldHander";
import { BaseDHFSection } from "./BaseDHFSection";

export class ItemIndex extends BaseDHFSection<IItemIndexOptions> implements IDHFSection {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrlParameter.fieldValue = "includes an index with all referenced items";
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.plainText(
            ml.JSON.setOptions(ml.JSON.clone(ctrlParameter), {
                parameter: { readonly: true, rows: 1, allowResize: false },
            }),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let format = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p0" type="text" name="format" value="" /> ',
        );

        custom.append($("<br/>"));
        custom.append($("<span>Word formatting: </span>").append(format));

        format.val(controllerConfig.format ? controllerConfig.format : ITEM_INDEX_FORMAT);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let newval = $(custom.find(".p0")[0]).val();
        let changed = controllerConfig.format !== newval;
        controllerConfig.format = newval;

        this.fieldHandler.setDHFConfig(controllerConfig);
        return changed;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
