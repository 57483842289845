import { IDB } from "./DBCache";
import { IControlDefinition, IBooleanMap, IStringMap, IItem, matrixSession, wait } from "./../../globals";
import type { IItemControlOptions } from "../UI/Components/index";
import type { IBaseControlOptions } from "../UI/Controls/BaseControl";
import type { IFieldDescription, IFieldParameter } from "../../ProjectSettings";
import { XRGetProject_StartupInfo_ListProjectAndSettings } from "../../RestResult";
import { IDashboardParametersBase } from "./FieldHandlers/Document/IDashboardParametersBase";
import { ReactElement } from "react";
import { IExternalPlugin, IDashboardLinkForAnalytics, ITile } from "../../../sdk/plugins/interfaces";

export type { IPlugin, ISettingPage, IProjectPageParam, IPluginControl, IPluginPanelOptions };
export { PluginManager, plugins };
export { pluginHooks };
export { InitializePluginManager };

interface IPlugin {
    initItem?: (item: IItem, jui: JQuery) => void;
    initServerSettings?: (serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) => void;
    updateMenu?: (ul: JQuery, hook: number) => void;

    /**
     * Returns true if the plugin offers a control that can display fields of type {fieldType}.
     */
    supportsControl?: (fieldType: string) => boolean;
    createControl?: (ctrlObj: JQuery, settings: IBaseControlOptions) => void;
    initProject?: (project: string) => void;
    isDefault?: boolean;
    filterProject?: (db: IDB[]) => void;
    updateSearchPanel?: () => void;
    updateItemPanel?: () => void;
    updateItem?: (item: IItem) => void;
    getProjectPagesAsync?: () => Promise<IProjectPageParam[]>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    preSaveHookAsync?: (isItem: boolean, type: string, controls: IControlDefinition[]) => Promise<{}>;
    renderActionButtons?: (options: IItemControlOptions, body: JQuery, controls: IControlDefinition[]) => boolean;
    updateTree?: () => void;

    // admin
    getFieldConfigOptions?: () => IFieldDescription[];
    addFieldSettings?: (
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        configApp: any,
        project: string,
        pageId: string,
        fieldType: string,
        fieldParams: IFieldParameter,
        ui: JQuery,
        paramChanged: () => void,
        canBePublished?: boolean,
    ) => void;
    getProjectSettingPagesAsync?: () => Promise<ISettingPage[]>;
    getCustomerSettingPagesAsync?: () => Promise<ISettingPage[]>;
    getPluginName?: () => string;
    getPluginVersion?: () => string;
    categorySetting?: (key: string) => string;
    editCategorySetting?: (key: string, category: string) => void;
    helpUrl?: string;
    initPrintingAsync?: () => Promise<void>;

    /**
     * Returns a list of TinyMCE menu items to be added to the editor.
     * @param editor
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getTinyMenuItems?(editor: any): ITinyMenu[];

    /**
     * Returns a list of menu items to be added to the user profile menu in the main app.
     */
    getUserMenuItems?(): IPluginMenuAction[];

    /**
     * Returns a list of menu items to be added to the user profile menu of the config page.
     */
    getConfigUserMenuItems?(): IPluginMenuAction[];

    /**
     * Returns a list of menu items to be added to the project list.
     */
    getProjectMenuItems?(): IPluginMenuAction[];

    /**
     * Returns a list of custom searches to be added to the search drowpdown.
     */
    getCustomSearches?(): IPluginSearch[];

    /**
     * Returns a list of menu items to be added to the QMS user profile menu in the liveqms.
     */
    getQMSUserMenuItems?(): IPluginMenuAction[];

    /**
     *  Notify the plugin to render itself in the element passed.
     */
    getTiles?: () => ITile[];
}

export interface IPluginHooks {
    shares: number;
}

let pluginHooks: IPluginHooks;
pluginHooks = { shares: 0 };

interface ISettingPage {
    id: string;
    title: string;
    type?: string;
    help?: string;
    externalHelp?: string;
    render: (ui: JQuery) => void;
    advanced?: () => void;
    del?: () => void;
    saveAsync?: () => JQueryDeferred<unknown>;
    getNode?: () => IDB;
}

interface IProjectPageParam {
    id: string; // the id
    title: string; // the title
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render: any; // function to call when the user clicks on icon
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy?: any; // function to call when the user clicks away
    folder?: string; // folder in which to appear - TOOLS by default
    order: number; // higher number further down
    folderTitle?: string; // if the folder does not exists it will be created using this title
    icon?: string; // optional icon
    usesFilters: boolean; // if set to true the page uses filters so the control will be enabled
}
interface IPluginControl {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: Function;
}

export interface IPluginCoreForDashboard<DASHBOARDPARAMS extends IDashboardParametersBase> {
    getDashboardAsync(): Promise<IDashboardPage<DASHBOARDPARAMS>>;
    getConfig(): IPluginConfigForDashboard;
}
export interface IPluginConfigForDashboard {
    dashboard: IPluginFeatureDashboard;
}

export interface IDashboardPage<T extends IDashboardParametersBase> {
    renderProjectPage(): void;
    renderProjectPageWithParams?(params: T, interactive: boolean): Promise<JQuery>;
    onResize(): void;
}

export interface IPluginFeatureBase {
    /** Whether to show the page */
    enabled: boolean;
    /** Id of the page in the tree/url */
    id?: string;
    /** Title of the page in the tree and of the page when displayed */
    title?: string;
}

/** interface for the value to be stored by custom field */
export interface IPluginFieldValueBase {
    /**
     *  html to be rendered to support the field printing outside of custom section
     */
    html: string;
    /**
     * value to be stored and used by the control
     */
    value: unknown;
}
export interface IPluginFeatureDashboard extends IPluginFeatureBase {
    /** Icon of the dashboard (See font awesome) */
    icon: string;
    /** Parent of the dashboard (It should exists)) */
    parent: string;
    /** Whether using filter when searching.*/
    usefilter: boolean;
    /** Order in the tree */
    order: number;
}

export interface ITinySubMenuItem {
    type: string;
    text: string;
    onAction: () => void;
}
export interface ITinyMenuItem {
    text: string;
    getSubmenuItems: () => ITinySubMenuItem[];
}
export interface ITinyMenu {
    id: string;
    menuItem: ITinyMenuItem;
}

/** Server setting for plugin.
 *
 * This you can use to save setting on an instance level (for all projects)
 * The user can edit these in the admin through the Server Setting Page
 */
export interface IServerSettingsBase {}

/** Project setting for plugin
 *
 * This you can use to save setting for one specific project.
 * The user can edit these in the admin through the Project Setting Page
 */
export interface IProjectSettingsBase {
    /**  This will contains the a project settings */
}

interface IPluginPanelOptions {
    type: string;
    control: JQuery;
    controlState: number;
}

export interface IPluginSearch {
    menu: string;
    search: (selectItems: (selectedItems: string[]) => void) => Promise<void>;
}

export interface IPluginMenuAction {
    icon: string;
    title: string;
    action: () => Promise<void>;
}

class PluginManager {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery; // div for popups
    private _plugins: IPlugin[] = [];
    private controls: IPluginControl = {};
    private destructors: IPluginControl = {};
    private titles: IStringMap = {};
    private usesFilters: IBooleanMap = {};
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private activeControlPage: string;

    /** Called by the main UI handing over a div which can be used inside a plugin
     * to display modal popups
     *
     * @param {jquery object} jui a $("<div />") object
     * @returns {undefined}
     */
    setUI(jui: JQuery): void {
        this._jui = jui;
    }

    /** function to register a plugin for a specific menu (specified by the hook)
     *
     * @param {instance of plugin} plugin
     * @returns {undefined}
     */
    register(plugin: IPlugin): void {
        this._plugins.push(plugin);
    }

    /** this method is called from the main UI whenever an item is selected to be
     * displayed. The information is forwarded to all plugins
     *
     * @param {json object} item for example a requirement. see the json documention of item types
     * @returns {undefined}
     */
    init(item: IItem): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].initItem) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].initItem(item, this._jui);
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to init for plugin : " + e.toString());
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async initPrinting() {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].initPrintingAsync) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    await this._plugins[idx].initPrintingAsync();
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to initPrinting for plugin : " + e.toString());
            }
        }
    }

    /** this method is called after connecting to server using getServer ("")
     *
     * @param {json serverSettings} serverSettings or null after unsucessful login
     * @returns {undefined}
     */
    initServerSettings(serverSettings?: XRGetProject_StartupInfo_ListProjectAndSettings): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].initServerSettings) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].initServerSettings(serverSettings);
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.log("error " + e.toString());
            }
        }
    }

    /** this method is called when creating a menu which has a hook. it allows the plugins to add
     * li's to the ul supplied
     *
     * @param {number} hook identifies the menu
     * @param {jquery object} ul  a $("<ul />) object
     * @returns {undefined}
     */
    updateMenu(hook: number, ul: JQuery): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].updateMenu) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].updateMenu(ul, hook);
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to updateMenu for plugin : " + e.toString());
            }
        }
    }

    getFieldConfigOptions(): IFieldDescription[] {
        let fco: IFieldDescription[] = [];
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].getFieldConfigOptions && this._plugins[idx].supportsControl) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    const fieldConfig = this._plugins[idx].getFieldConfigOptions();
                    for (let i = 0; i < fieldConfig.length; i++) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (this._plugins[idx].supportsControl(fieldConfig[i].id)) {
                            fco.push(fieldConfig[i]);
                        }
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getFieldConfigOptions for plugin : " + e.toString());
            }
        }
        return fco;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addFieldSettings(
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        configApp: any,
        project: string,
        pageId: string,
        fieldType: string,
        fieldParams: IFieldParameter,
        ui: JQuery,
        paramChanged: () => void,
        canBePublished?: boolean,
    ) {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].addFieldSettings) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].addFieldSettings(
                        configApp,
                        project,
                        pageId,
                        fieldType,
                        fieldParams,
                        ui,
                        () => paramChanged(),
                        canBePublished,
                    );
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to addFieldSettings for plugin : " + e.toString());
            }
        }
    }
    supportsControl(fieldType: string): boolean {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this._plugins[idx].supportsControl && this._plugins[idx].supportsControl(fieldType)) {
                    return true;
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to supportsControl for plugin : " + e.toString());
            }
        }
        return false;
    }

    createControl(ctrlObj: JQuery, settings: IBaseControlOptions): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this._plugins[idx].supportsControl && this._plugins[idx].supportsControl(settings.fieldType)) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].createControl(ctrlObj, settings);
                    return;
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to createControl for plugin : " + e.toString());
            }
        }
    }

    initProject(project: string): void {
        // delete all non default plugins === custom
        for (let idx = this._plugins.length - 1; idx >= 0; idx--) {
            if (!this._plugins[idx].isDefault) {
                this._plugins.splice(idx, 1);
            }
        }

        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].initProject) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].initProject(project);
                }
            } catch (e) {
                //Display error message to user in the console.
                console.error(e);
            }
        }
        this.initPrinting();
    }

    // to modify db tree after it has been created
    filterProject(db: IDB[]): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].filterProject) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].filterProject(db);
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to filterProject for plugin : " + e.toString());
            }
        }
    }

    // to modify search panel on left after it has been rendered
    updateSearchPanel(): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].updateSearchPanel) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].updateSearchPanel();
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to updateSearchPanel for plugin : " + e.toString());
            }
        }
    }

    // to modify item on left after it has been rendered
    updateItemPanel(): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].updateItemPanel) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].updateItemPanel();
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to updateItemPanel for plugin : " + e.toString());
            }
        }
    }

    // notify plugins that links of item changed
    updateItem(updates: IItem): void {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].updateItem) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].updateItem(updates);
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to updateItem for plugin : " + e.toString());
            }
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateTree() {
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].updateTree) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this._plugins[idx].updateTree();
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to updateTree for plugin : " + e.toString());
            }
        }

        return;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getTinyMenus(editor: any): ITinyMenu[] {
        let list = [];
        for (let plugin of this._plugins) {
            try {
                if (plugin.getTinyMenuItems) {
                    let menuItems = plugin.getTinyMenuItems(editor);
                    if (menuItems && menuItems.length > 0) {
                        list.push(...menuItems);
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getTinyMenus for plugin : " + e.toString());
            }
        }
        return list;
    }

    getCustomSearches(): IPluginSearch[] {
        let list = [];
        for (let plugin of this._plugins) {
            try {
                if (plugin.getCustomSearches) {
                    let menuItems = plugin.getCustomSearches();
                    if (menuItems && menuItems.length > 0) {
                        list.push(...menuItems);
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getCustomSearches for plugin : " + e.toString());
            }
        }
        return list;
    }

    getUserMenuItems(): IPluginMenuAction[] {
        let list = [];
        for (let plugin of this._plugins) {
            try {
                if (plugin.getUserMenuItems) {
                    let menuItems = plugin.getUserMenuItems();
                    if (menuItems && menuItems.length > 0) {
                        list.push(...menuItems);
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getUserMenuItems for plugin : " + e.toString());
            }
        }
        return list;
    }

    getQMSUserMenuItems(): IPluginMenuAction[] {
        let list = [];
        for (let plugin of this._plugins) {
            try {
                if (plugin.getQMSUserMenuItems) {
                    let menuItems = plugin.getQMSUserMenuItems();
                    if (menuItems && menuItems.length > 0) {
                        list.push(...menuItems);
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getQMSUserMenuItems for plugin : " + e.toString());
            }
        }
        return list;
    }

    getConfigUserMenuItems(): IPluginMenuAction[] {
        let list = [];
        for (let plugin of this._plugins) {
            try {
                if (plugin.getConfigUserMenuItems) {
                    let menuItems = plugin.getConfigUserMenuItems();
                    if (menuItems && menuItems.length > 0) {
                        list.push(...menuItems);
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getConfigUserMenuItems for plugin : " + e.toString());
            }
        }
        return list;
    }

    getProjectMenuItems(): IPluginMenuAction[] {
        let list = [];
        for (let plugin of this._plugins) {
            try {
                if (plugin.getProjectMenuItems) {
                    let menuItems = plugin.getProjectMenuItems();
                    if (menuItems && menuItems.length > 0) {
                        list.push(...menuItems);
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getProjectMenuItems for plugin : " + e.toString());
            }
        }
        return list;
    }

    async getProjectPages(): Promise<IProjectPageParam[]> {
        let that = this;

        let allPages: IProjectPageParam[] = [];

        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].getProjectPagesAsync) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let pages = await this._plugins[idx].getProjectPagesAsync();
                    for (let page of pages) {
                        allPages.push(page);
                        that.controls["_" + page.id] = page.render;
                        that.destructors["_" + page.id] = page.destroy;
                        that.titles["_" + page.id] = page.title;
                        that.usesFilters["_" + page.id] = page.usesFilters;
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to getProjectPages for plugin : " + e.toString());
            }
        }
        return allPages;
    }
    /**
     * Return a list of plugins that can be displayed in the dashboard analytics page.
     * */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getAllTiles() {
        let tiles: ITile[] = [];
        for (let idx = 0; idx < this._plugins.length; idx++) {
            let plugin = this._plugins[idx];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (plugin.getTiles != undefined) {
                let tile = plugin.getTiles();
                tiles.push(...tile);
            }
        }
        return tiles;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getAllDashboardLinksForAnalyticsAsync() {
        let allLinks: IDashboardLinkForAnalytics[] = [];
        for (let idx = 0; idx < this._plugins.length; idx++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let plugin = this._plugins[idx] as IExternalPlugin<any, any, any, any, any>;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (plugin && plugin.getDashboardLinksForAnalyticsAsync != undefined) {
                let links = await plugin.getDashboardLinksForAnalyticsAsync();
                allLinks.push(...links);
            }
        }
        return allLinks;
    }

    supportsControlPage(controlType: string): boolean {
        return !!this.controls[controlType];
    }

    createControlPage(options: IPluginPanelOptions, toggleFilters?: (toggle: boolean) => void): void {
        options.control.html("");

        document.title = this.titles[options.type] + " - " + matrixSession.getProject();
        if (this.usesFilters[options.type]) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            toggleFilters(true);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            toggleFilters(false);
        }
        this.activeControlPage = options.type;
        this.controls[options.type](options);
    }

    destroyActiveControlPage(): void {
        if (this.activeControlPage) {
            try {
                const destructor = this.destructors[this.activeControlPage];
                if (destructor) {
                    destructor();
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.activeControlPage = null;
            } catch (e) {
                console.error("Error removing control page", e);
            }
        } else {
            // console.log("No active control page");
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    callPreSaveHook(isItem: boolean, type: string, controls: IControlDefinition[]): JQueryDeferred<{}> {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function callRec(idx: number): JQueryDeferred<{}> {
            let res = $.Deferred();
            if (idx >= that._plugins.length) {
                res.resolve();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return res;
            }
            if (that._plugins[idx].preSaveHookAsync) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that._plugins[idx]
                    .preSaveHookAsync(isItem, type, controls)
                    .then(function () {
                        callRec(idx + 1)
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .catch(function () {
                        callRec(idx + 1).always(function () {
                            res.reject();
                        });
                    });
            } else {
                callRec(idx + 1)
                    .done(function () {
                        res.resolve();
                    })
                    .fail(function () {
                        res.reject();
                    });
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }
        return callRec(0);
    }

    // return true for handled, false for not  handled
    renderActionButtons(options: IItemControlOptions, body: JQuery, controls: IControlDefinition[]): boolean {
        let done = false;
        for (let idx = 0; idx < this._plugins.length; idx++) {
            try {
                if (this._plugins[idx].renderActionButtons) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (this._plugins[idx].renderActionButtons(options, body, controls)) {
                        done = true;
                    }
                }
            } catch (e) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                console.error("error: Fail to renderActionButtons for plugin : " + e.toString());
            }
        }
        // return true if at least on plugin is owner
        return done;
    }

    /******************** admin function  */

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getPlugins() {
        return this._plugins;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async waitForAllPlugins() {
        //Wait for all script to be loaded
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let pluginCount = () => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return (globalThis as any).pluginLoadedCount as number;
        };
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let expectedCount = () => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return (globalThis as any).pluginCountExpectedToBeLoaded as number;
        };
        console.log(` ${expectedCount()} plugins has to be loaded...`);
        console.log(`Current plugin count: ${pluginCount()}`);
        let tryCount = 0;
        // If item doesn't exist, let's check if plugins are still loading by check the # of plugins
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        while (pluginCount() == undefined || (pluginCount() != expectedCount() && tryCount < 10)) {
            // Wait few milliseconds to let the plugins register themselves
            console.log(`Waiting for plugins to load ${pluginCount()}/${expectedCount()} ... Try #${tryCount++}`);
            await wait(100);
            if (tryCount === 10) {
                console.error("Failed to load all plugins");
                break;
            }
        }
        console.info("All plugins loaded");
    }
}

let plugins: PluginManager;

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializePluginManager() {
    plugins = new PluginManager(); // plugin manager
    // @ts-ignore TODO: get rid of globals
    globalThis.plugins = plugins;
    // @ts-ignore TODO: get rid of globals
    globalThis.pluginHooks = pluginHooks;
}
