/// <reference types="matrixrequirements-type-declarations" />
import { ControlState, IReference, IGenericMap, globalMatrix, app, matrixSession } from "../../../globals";
import { FieldHandlerFactory, IDB } from "../../businesslogic/index";
import { IBaseControlOptions, IBaseControl } from "../Controls/BaseControl";
import { ListView } from "./ProjectList";
import { ProjectTree } from "./ProjectTree";
import { SearchBox, SearchBoxSelection } from "./SearchBox";
import { ViewModeSelector } from "./ViewModeSelector";
import { ml } from "../../matrixlib";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { NotificationList } from "../../../client/plugins/Notifications";
import { plugins } from "../../businesslogic/index";
import {
    IProjectPanelControlOptions,
    MyDDData,
    MyFancytree,
    MyNode,
    MyNodeData,
    SearchUpdate,
    SelectMode,
} from "./ProjectViewDefines";

// TODO(modules): an inelegant way to force a side effect from a module load.
import "../Parts/RefLink";
import { EImportMode } from "../../businesslogic/ComponentImport";
import { IFieldHandler } from "../../businesslogic/FieldHandlers/IFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";

export { ProjectView };

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.projectView = function (this: JQuery, options: IProjectPanelControlOptions) {
    let baseControl = new ProjectView(this);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    baseControl.setFieldHandler(options.fieldHandler);
    baseControl.init(options);
    return this;
};

class ProjectView implements IBaseControl {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public settings: IProjectPanelControlOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public viewModeSelector: ViewModeSelector;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public searchBox: SearchBox;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public projectTree: ProjectTree;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public listView: ListView;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public prefixCategory: string; // set to a category id, if search should only be in category
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public tree: JQuery;
    private _root: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public needsLatest: boolean; // no meaning juts to be compatible with

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    fieldHandler: IFieldHandler;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private currentFilterContainer: JQuery;
    getFieldHandler(): IFieldHandler {
        return this.fieldHandler;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setFieldHandler(fh: IFieldHandler) {
        this.fieldHandler = fh;
    }

    constructor(control: JQuery) {
        this._root = control;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IBaseControlOptions) {
        let that = this;

        let defaultOptions: IProjectPanelControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            highlight: true, // true: input should be highlighted in complete page
            canFilter: true, // true: filter / search box should be shown
            serverSearch: true, // true: search server should be enabled: disabled only in admin client
            expand: 0, // minimum number of levels to expand
            selectedItems: [], // list of selected items,
            dragAndDrop: options.controlState === ControlState.FormEdit, // embedded editable tree allows drag and drop
            tree: [], // tree in fancy tree style
            tooltips: true, // show tooltips if available
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            selectionChanged: function () {}, // callback if selection was changed, if not set a click will open another window
            dropCallback: () => false, // callback after node was dropped
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            collectionChanged: function (count: number): void {}, // callback if checkbox in tree was set or unset
            selectMode: 0, // no selection
            autoScroll: true,
        };

        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        this.settings.dragAndDrop = this.settings.dragAndDrop && matrixSession.isEditor();

        // render and search
        let ctrlDiv = $("<div class='treeCtrl'>");
        this._root.append(ctrlDiv);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.projectTree = new ProjectTree(this, this.settings.canFilter);
        this.listView = new ListView(this);
        this.searchBox = new SearchBox(this);
        this.viewModeSelector = new ViewModeSelector(this);
        // let expandMinLevel = 2;
        // if ( this.settings.tree.length > 1 ) {
        //     this.settings.expand = 0;
        //     expandMinLevel = 0;
        // }
        // initialize object
        if (this.settings.controlState === ControlState.Print || this.settings.controlState === ControlState.Tooltip) {
            return; // nothing to do
        }

        let filter = $(`<div id="searchFilter" data-cy='searchFilter' class="" style="width:100%"></div>`);
        ctrlDiv.append(filter);

        let searchVizLabel = $('<label class="treeLabel searchVizMode">View type</label>').insertAfter(filter);
        let searchVizCombo = this.viewModeSelector
            .getVizModeControl(function () {
                that.searchBox.render();
            })
            .insertAfter(searchVizLabel);
        let contentLabel = $('<label class="treeLabel treeName">Contents</label>');
        this._root.append(contentLabel);
        this.listView.control.insertAfter(contentLabel);

        // add ui for filter and search
        if (this.settings.canFilter) {
            // tree/list filter method

            let labelFilters = $('<label class="treeLabel itemFilterTool ">Filters</label>').insertAfter(
                searchVizCombo,
            );
            this.currentFilterContainer = $('<div id="currentFilterContainer" class="itemFilterTool"></div>');
            this.currentFilterContainer.insertAfter(labelFilters);

            $(
                "<div class='itemFilterTool itemFilterToolContainer'><button class='btn btn-xs btn-link itemFilterTool clearFilter'  id='clearSelectedFilter'  data-cy='clearFilter' '>Clear filter</span></div>",
            ).insertAfter(this.currentFilterContainer);

            $("#clearSelectedFilter").click(function () {
                ml.LabelTools.setFilter([]);
                $("#filterDialog").remove();
                const project = matrixSession.getProject();

                if (project) {
                    app.loadProject(project, app.getCurrentItemId()).then(() => {
                        $(".itemFilterTool").hide();
                    });
                }
            });

            // search field
            this.searchBox.renderSearchField(
                filter,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.serverSearch,
                that.settings.highlight,
                that.settings.isConfigSearch ?? false,
                this.currentFilterContainer,
            );
        }
        // add ui for tree
        this.tree = $('<div name="ftree" data-cy="tree" class="treeContent"></div>');
        this._root.append(this.tree);

        let doNotTriggerSelectionChange = false; // if true the client will not be informed,
        let rememberLastActivation: string; // to avoid double activations (which can arrive if one saves while it gets activated again)

        // determine native select mode of tree
        let sm = 0;
        switch (this.settings.selectMode) {
            case SelectMode.none: //(later we must hide checkboxes)
            case SelectMode.singleItem:
            case SelectMode.singleFolder:
                sm = 1; // checking one item will uncheck other checked
                break;
            case SelectMode.items:
            case SelectMode.folders:
            case SelectMode.independent:
            case SelectMode.independentAuto:
            case SelectMode.autoPrecise:
                sm = 2; // checkboxes for items and/or folders, checking one does not influence other
                break;
            case SelectMode.auto:
                sm = 3;
                break;
        }
        let fireUpdates = true;
        let fto = <Fancytree.FancytreeOptions>{
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            checkbox: this.settings.selectMode != SelectMode.none,
            selectMode: sm,
            clickFolderMode: 4, // expand with double click:
            autoActivate: false,
            autoCollapse: false,
            imagePath: globalMatrix.matrixBaseUrl + "/img/",
            autoScroll: this.settings.autoScroll,
            keyboard: true,
            tabbable: true, // we don't want the focus frame
            minExpandLevel: 1, // expandMinLevel,
            focus: function (e, data) {
                let ctree = $(e.delegateTarget).find(".ui-fancytree");
                if (!ctree.is(":focus")) {
                    ctree.focus();
                }
            },
            expand: function (e, data) {
                if (that.settings.onExpand) {
                    that.settings.onExpand(data.node.key);
                }
                plugins.updateTree();
            },
            blur: function (e, data) {
                data.node.scheduleAction("cancel", 0);
            },
            select: (e, data) => {
                this.searchBox.updateSelectionCheckboxesState();

                if (fireUpdates) {
                    that.saveSelection("new");
                }
            },
            activate: function (e, data) {
                let node = data.node;
                if (
                    !doNotTriggerSelectionChange &&
                    that.settings.selectionChanged &&
                    rememberLastActivation !== node.key
                ) {
                    // call app
                    rememberLastActivation = node.key;
                    that.settings.selectionChanged.apply(null, [node.key]);
                }
            },
            click: function (e, data) {
                // allow re-loads
                // TODO(modules): not sure how to fix this. There is a namespace declaration in
                // externals-fancytree, but it doesn't work.
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let tt = (<any>$.ui).fancytree.getEventTargetType(e.originalEvent);
                if (tt !== "expander" && tt !== "checkbox" && data.node && !data.node.expanded) {
                    data.node.setExpanded();
                }
                // now follows a workaround for MATRIX-634 label filtering tree kaput
                // the jquery toggle function (with a nice event) gets somehow screwed in fancy tree
                // so I just wait a little and after 220 ms I make sure the item is actually toggled

                if (tt === "expander" || (data.node && !data.node.expanded)) {
                    let node: MyNode = data.node;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let x = $(node.ul);
                    let y = data.node.expanded;
                    window.setTimeout(function () {
                        y ? x.hide() : x.show();
                    }, 220);
                }

                if (
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    that.settings.selectMode == SelectMode.independentAuto &&
                    tt === "checkbox" &&
                    data.node.folder &&
                    !data.node.isSelected()
                ) {
                    fireUpdates = false;
                    that.projectTree.selectChildren(data.node);
                    fireUpdates = true;
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.settings.selectMode == SelectMode.autoPrecise && tt === "checkbox") {
                    if (data.node.folder && !data.node.isSelected()) {
                        fireUpdates = false;
                        that.projectTree.selectChildren(data.node);
                        fireUpdates = true;
                    }

                    if (data.node.isSelected()) {
                        fireUpdates = false;
                        that.projectTree.unselectParents(data.node);
                        if (data.node.folder) {
                            that.projectTree.unSelectChildren(data.node);
                        }
                        fireUpdates = true;
                    }
                    // set partial selection status after click
                    window.setTimeout(() => {
                        that.projectTree.forcePartial();
                    }, 1);
                }
                return true;
            },
            renderNode: function (event: JQueryEventObject, data: Fancytree.EventData) {
                // Optionally tweak data.node.span
                let node: MyNode = data.node;
                let myNodeData = <MyNodeData>node.data;
                if (myNodeData.cstrender) {
                    node.unselectable =
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        that.settings.selectMode == SelectMode.none ||
                        (node.folder &&
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            (that.settings.selectMode == SelectMode.items ||
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                that.settings.selectMode == SelectMode.singleItem)) ||
                        (!node.folder &&
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            (that.settings.selectMode == SelectMode.folders ||
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                that.settings.selectMode == SelectMode.singleFolder));
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let $span = $(node.span);

                    let dataCy = myNodeData.shortTitle ?? myNodeData.title;
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (dataCy != undefined) {
                        dataCy = $(`<span>${dataCy}</span>`).text().replace(" ", "-");
                        $span.attr("data-cy", dataCy);
                    }

                    if (node.unselectable) {
                        $(".fancytree-checkbox", $span).remove();
                    }
                    if (myNodeData.background) {
                        $span.css("background-color", myNodeData.background);
                    }
                    if (myNodeData.border) {
                        $span.css("border", myNodeData.border);
                    }
                    if (node.parent.parent === null) {
                        // grey borders around root nodes
                        // $span.css("background-color", "#e5e5e5").css("border", "1px solid rgb(204, 204, 204)");
                    }
                    if (that.settings.selectionChanged) {
                        // call app
                        $span.find("> span.fancytree-title").refLink({
                            id: node.key,
                            folder: node.folder,
                            title: myNodeData.shortTitle,
                            css: myNodeData.extraStyle,
                            style: refLinkStyle.show,
                            tooltip: that.settings.tooltips ? refLinkTooltip.html : refLinkTooltip.none,
                            crossProject: that.settings.crossProject,
                            callback: function () {
                                if (that.settings.canSelectItems) {
                                    node.toggleSelected();
                                }
                                // prevent second round trip in activate
                                rememberLastActivation = node.key;
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                let ret = that.settings.selectionChanged.apply(null, [node.key]);
                                return ret;
                            },
                        });
                    } else {
                        $span.find("> span.fancytree-title").refLink({
                            id: node.key,
                            folder: node.folder,
                            title: myNodeData.shortTitle,
                            style: refLinkStyle.link,
                            css: myNodeData.extraStyle,
                            crossProject: that.settings.crossProject,
                            tooltip: that.settings.tooltips ? refLinkTooltip.html : refLinkTooltip.none,
                        });
                    }
                }
            },
            extensions: ["dnd", "filter"],
            dnd: {
                preventVoidMoves: true, // Prevent dropping nodes 'before self', etc.
                preventRecursiveMoves: true, // Prevent dropping nodes on own descendants
                autoExpandMS: 400,
                focusOnClick: true, // to enable keyboard navigation!
                dragStart: function (node: Fancytree.FancytreeNode) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    let isIncluded = (<MyNodeData>node.data).mode == EImportMode.Include;
                    // MATRIX-5845 allow to move the root ... into another place in the root
                    // || (<MyNodeData>node.data).mode == EImportMode.IncludeRoot; // it's the root of the includes

                    return (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.dragAndDrop && app.canDragDrop((<MyNode>node.data).type, node.key) && !isIncluded
                    );
                },
                dragEnter: function (node: Fancytree.FancytreeNode, dddata: MyDDData): string[] | boolean {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (dddata === null || dddata.otherNode === null || dddata.otherNode.data === null) {
                        return false;
                    }
                    if (app.dragEnter) {
                        return app.dragEnter(<Fancytree.FancytreeNode>dddata.otherNode, node);
                    }
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if ((<MyNode>dddata.otherNode.data).type !== (<MyNode>node.data).type) {
                        return false;
                    }
                    if (node.folder) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if ((<MyNode>node.parent.data).type !== (<MyNode>dddata.otherNode.data).type) {
                            return ["after"];
                        }
                        return true;
                    }
                    if (
                        node.parent === null ||
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        (<MyNode>node.parent.data).type !== (<MyNode>dddata.otherNode.data).type
                    ) {
                        return false;
                    }
                    return ["before", "after"];
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                dragOver: function (node: Fancytree.FancytreeNode, dddata: MyDDData, hitMode: string): any {
                    /** Return false to disallow dropping this node.
                     *
                     */

                    // Prevent dropping a parent below it's own child
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (!dddata || node.isDescendantOf(dddata.otherNode)) {
                        ml.Logger.log("error", "FALSE node.isDescendantOf(dddata)");
                        return false;
                    }
                    // Prohibit dropping in anything which is an include
                    if (
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        (<MyNodeData>node.data).mode == EImportMode.Include ||
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        (<MyNodeData>node.data).mode == EImportMode.IncludeRoot
                    ) {
                        return false;
                    }

                    // Prohibit include root to be dropped into anything but root folder
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if ((<MyNodeData>dddata.otherNode.data).mode == EImportMode.IncludeRoot) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let target = that.calculateDropTarget(node, dddata.hitMode);
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (target.key != "F-" + (<MyNodeData>node.data).type + "-1") {
                            return false;
                        }
                    }

                    // Prohibit creating children in non-folders (only sorting allowed)
                    if (!(<MyNode>node.data).isFolder && hitMode === "over") {
                        ml.Logger.log("error", "after !node.data.isFolder && hitMode ==='over'");
                        return "after";
                    }
                },
                dragDrop: function (node: Fancytree.FancytreeNode, dddata: MyDDData) {
                    // Prohibit dropping in anything which is an include
                    if (
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        (<MyNodeData>node.data).mode == EImportMode.Include ||
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        (<MyNodeData>node.data).mode == EImportMode.IncludeRoot
                    ) {
                        return;
                    }

                    if (that.settings.dragAndDrop) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let target = that.calculateDropTarget(node, dddata.hitMode);
                        // Prohibit include root to be dropped into anything but root folder
                        if (
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            (<MyNodeData>dddata.otherNode.data).mode == EImportMode.IncludeRoot &&
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            target.key != "F-" + (<MyNodeData>node.data).type + "-1"
                        ) {
                            return;
                        }
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.dropCallback.apply(null, [
                            {
                                parentId: target.key,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                itemId: dddata.otherNode.key,
                                index: target.index,
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                updateUI: function () {
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    dddata.otherNode.moveTo(node, dddata.hitMode);
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    let key = dddata.otherNode.key;
                                    that.render(SearchUpdate.item_dropped, key);
                                },
                            },
                        ]);
                    }
                },
                dragStop: function (node: Fancytree.FancytreeNode, dddata: MyDDData) {
                    // after a drag and drop the selection in the tree changes (no idea why)
                    // to prevent the client to do this the following lines are required
                    // the timeout ensures that all triggers started by the drag finished when resetting
                    // doNotTriggerSelectionChange
                    doNotTriggerSelectionChange = true;
                    let selectionBeforeDrag = app.getCurrentItemId();
                    window.setTimeout(function () {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        (<MyFancytree>that.tree.fancytree("getTree")).options.autoScroll = false;
                        (<MyFancytree>that.tree.fancytree("getTree")).activateKey(selectionBeforeDrag);
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        (<MyFancytree>that.tree.fancytree("getTree")).options.autoScroll = true;
                        doNotTriggerSelectionChange = false;
                    }, 1);
                },
            }, //dnd: {
        };
        if (this.settings.canFilter) {
            (<IGenericMap>fto)["filter"] = {
                mode: "dimm",
                autoApply: true,
            };
        }
        if (this.settings.noAnimation) {
            (<IGenericMap>fto)["toggleEffect"] = false;
        }
        if (NotificationList.isEnabled() && this.settings.isMainTree) {
            if (fto.extensions) {
                fto.extensions.push("notificationCounter");
            } else {
                fto["extensions"] = ["notificationCounter"];
            }
        }
        this.tree.fancytree(fto); // $("#tree").fancytree({
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.projectTree.treeFromDb(this.settings.tree);

        if (!matrixSession.isConfigClient()) {
            // do the initial filtering: maybe there are some project labels
            this.searchBox.resetSearch();
        }

        // select items
        $.each(this.settings.selectedItems, function (index, key) {
            let item: Fancytree.FancytreeNode = (<Fancytree.Fancytree>that.tree.fancytree("getTree")).getNodeByKey(
                key.to,
            );
            if (item) {
                item.setSelected();
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.settings.selectMode == SelectMode.autoPrecise && ml.Item.parseRef(key.to).isFolder) {
                    that.forceSelectChildren(key.to);
                }
            }
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (that.settings.selectMode == SelectMode.autoPrecise) {
            // set partial selection for special precision mode
            that.projectTree.forcePartial();
        }

        if (this.settings.expand && this.settings.expand > 0) {
            (<Fancytree.Fancytree>this.tree.fancytree("getRootNode")).visit(function (node) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (node.getLevel() <= that.settings.expand) {
                    node.setExpanded(true);
                }
            });
        }
        this.resizeItem();

        this.saveSelection("original");
        this.saveSelection("new");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private forceSelectChildren(id: string) {
        let that = this;
        let parent: Fancytree.FancytreeNode = (<Fancytree.Fancytree>that.tree.fancytree("getTree")).getNodeByKey(id);
        if (parent.hasChildren()) {
            for (let child of parent.children) {
                child.setSelected();
                if (ml.Item.parseRef(child.key).isFolder) {
                    that.forceSelectChildren(child.key);
                }
            }
        }
    }
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return JSON.stringify(this._root.data("original")) !== JSON.stringify(this._root.data("new"));
    }

    async getValueAsync(): Promise<IReference[]> {
        return this._root.data("new");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(selectedItems: string[]) {
        this.projectTree.setSelectedItems(selectedItems);
        this.listView.setSelectedItems(selectedItems);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    toggleSelection(selected: boolean, type: SearchBoxSelection) {
        if (this.viewModeSelector.showAsList()) {
            type === "all" ? this.listView.selectAll(selected) : this.listView.selectMatched(selected);
        } else {
            type === "all" ? this.projectTree.selectAll(selected) : this.projectTree.selectMatched(selected);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {
        this.searchBox.updateHeights();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    clearFilter() {
        this.searchBox.resetSearch();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    filterStatusChanged(itemId: string) {
        this.render(SearchUpdate.filter_status_changed, itemId);
    }

    // tree creation
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertNode(parentKey: string, item: IDB, position: { at: number }) {
        let nn = this.projectTree.addNode(this.projectTree.getNode(parentKey), item, position);

        this.render(SearchUpdate.inserted_node, item.id, item);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    moveNode(parentId: string, itemId: string, position: number) {
        let nn = this.projectTree.moveNode(parentId, itemId, position);
        this.render(SearchUpdate.inserted_node, itemId);
        this.projectTree.openTree(parentId);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refresh() {
        this.render(SearchUpdate.inserted_node);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateRec(item: IDB) {
        this.projectTree.updateRec(item);
        this.searchBox.render();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertRec(parentKey: string, item: IDB) {
        this.projectTree.insertRec(parentKey, item);
        this.searchBox.render();
    }

    updateTopPosition(top: number): void {
        if (!this.settings.canFilter) {
            $(".treeContent", this._root).css("top", "4px");
            $(".listContent", this._root).css("top", "4px");
        } else {
            $(".treeContent", this._root).css("top", top + "px");
            $(".listContent", this._root).css("top", top + "px");
        }
    }

    appendController(controller: JQuery): void {
        this._root.append(controller);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render(subtree?: number, itemId?: string, item?: IDB) {
        if (
            subtree === SearchUpdate.inserted_node ||
            subtree === SearchUpdate.filter_status_changed ||
            subtree === SearchUpdate.item_dropped ||
            subtree === SearchUpdate.title_changed
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.listView.redrawItem(item ? item.id : itemId);
            if (
                subtree === SearchUpdate.inserted_node ||
                subtree === SearchUpdate.filter_status_changed ||
                subtree === SearchUpdate.title_changed
            ) {
                // MATRIX-1378
                this.projectTree.applyFilter();
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    openTree(key: string): JQueryPromise<any> {
        return this.projectTree.openTree(key);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    closeTree(key: string) {
        this.projectTree.closeTree(key);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setTitle(key: string, title: string) {
        if (this.projectTree.setTitle(key, title)) {
            this.render(SearchUpdate.title_changed, key);
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    removeNode(key: string) {
        this.projectTree.removeNode(key);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    select(key: string) {
        this.projectTree.select(key);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isSelected(key: string) {
        return this.projectTree.isSelected(key);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateItemIsUnselected(itemId: string, isUnselected: boolean) {
        if (this.projectTree.updateItemIsUnselected(itemId, isUnselected)) {
            this.filterStatusChanged(itemId);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateNotificationCounters() {
        this.projectTree.updateNotificationCounters();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private saveSelection(target: string) {
        let sel = (<MyFancytree>this.tree.fancytree("getTree")).getSelectedNodes(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.settings.selectMode == SelectMode.auto || this.settings.selectMode == SelectMode.autoPrecise,
        );
        let selKey: IReference[] = [];
        for (let idx = 0; idx < sel.length; idx++) {
            let myNodeData = <MyNodeData>sel[idx].data;
            let title =
                sel[idx].folder && sel[idx].title
                    ? sel[idx].title
                    : myNodeData && myNodeData.shortTitle
                    ? myNodeData.shortTitle
                    : sel[idx].title;
            selKey.push({ to: sel[idx].key, title: title });
        }
        this._root.data(target, selKey);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.collectionChanged.apply(null, [sel.length]);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private calculateDropTarget(targetNode: MyNode, mode: string) {
        // simulate fancy tree to check where it would go
        if (mode === undefined || mode === "over") {
            mode = "child";
        }
        let targetParent = mode === "child" ? targetNode : targetNode.parent;
        let pos = 0;

        if (targetParent.hasChildren()) {
            switch (mode) {
                case "child":
                    // Append to existing target children
                    pos = targetParent.children.length;
                    break;
                case "before":
                    // Insert this node before target node
                    pos = $.inArray(targetNode, targetParent.children);
                    break;
                case "after":
                    // Insert this node after target node
                    pos = 1 + $.inArray(targetNode, targetParent.children);
                    break;
            }
        }

        return {
            key: targetParent.key,
            index: pos,
        };
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getRoot() {
        return this._root;
    }
}
