import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { RichtextFieldHandler };

class RichtextFieldHandler implements IFieldHandler {
    private data?: string;
    private config: XRFieldTypeAnnotatedParamJson;

    constructor(configIn: XRFieldTypeAnnotatedParamJson) {
        this.config = configIn;
        this.data = undefined;
    }

    getFieldType(): string {
        return FieldDescriptions.Field_richtext;
    }

    // TODO: is there more to richtext than just an html string?
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        if (serializedFieldData !== undefined) {
            this.data = serializedFieldData;
        } else if (this.config.initialContent !== undefined) {
            this.data = this.config.initialContent;
        }
    }

    getData(): string | undefined {
        return this.data;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }
    getHtml(): string | undefined {
        return this.data;
    }
    setHtml(str: string): RichtextFieldHandler {
        this.data = str;
        return this;
    }
}
