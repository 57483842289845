import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { CheckboxFieldHandler } from "../../businesslogic/FieldHandlers/CheckboxFieldHandler";
import { IFieldParameter } from "../../../ProjectSettings";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";

export type { ICheckBoxControlOptions };
export { CheckBoxImpl };

interface ICheckBoxParams extends IFieldParameter {
    initialContent?: boolean; // set to true to set
}

interface ICheckBoxControlOptions extends IBaseControlOptions {
    parameter?: ICheckBoxParams;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.checkBox = function (this: JQuery, options: ICheckBoxControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_checkbox,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new CheckBoxImpl(this, options.fieldHandler as CheckboxFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class CheckBoxImpl extends BaseControl<CheckboxFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ICheckBoxControlOptions;

    constructor(control: JQuery, fieldHandler: CheckboxFieldHandler) {
        super(control, fieldHandler);
    }

    // This is a useful convention so we don't have to cast all over the place within a control
    // to manipulate data through the appropriate field handler.
    private getHandler(): CheckboxFieldHandler {
        return <CheckboxFieldHandler>this.settings.fieldHandler;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: ICheckBoxControlOptions) {
        let that = this;

        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
            },
        };
        this.settings = <ICheckBoxControlOptions>ml.JSON.mergeOptions(defaultOptions, options);
        // initialize object
        let currentValue = this.getHandler().getValue();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let virginField = currentValue == undefined;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let value = virginField ? (this.settings.parameter.initialContent ? true : false) : currentValue === true;

        let cbDom =
            '<label><input type="checkbox" ' +
            (this.settings.canEdit ? "" : 'disabled="disabled" ') +
            (value ? 'checked="checked"' : "") +
            '><span class="checkboxLabel">' +
            this.settings.help +
            "</span></label>";

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.inlineHelp) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            cbDom += "<div class='inlineHelp cbInlineHelp'>" + this.settings.parameter.inlineHelp + "</div>";
        }
        let cb = $(cbDom);
        // MATRIX-6909 :in review state, the cb should be in a p
        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.Review
        ) {
            this._root.append($("<p>").append(cb));
            return;
        }
        let ctrlContainer = $("<div>").addClass("baseControl");
        this._root.append(ctrlContainer);
        ctrlContainer.append(cb);
        $("input", cb).change(function () {
            let checkBoxValue = that.getCheckboxState(cb);
            that._root.data("new", that.getCheckboxState(cb));
            that.getHandler().setValue(checkBoxValue);
            if (that.settings.valueChanged) {
                that.settings.valueChanged(checkBoxValue, that._root);
            }
        });
        // if the checkbox field was added after the item fake a change to trigger a save
        let cbValue = that.getCheckboxState(cb);
        this._root.data("original", virginField ? "virgin" : cbValue);
        this._root.data("new", cbValue);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getCheckboxState(cb: JQuery): any {
        return $("input", cb).prop("checked");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let virginField = this.getHandler().getValue() == undefined;
        return !virginField && this._root.data("original") !== this._root.data("new");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        // TODO: should this return serialized database values, like IFieldHandler.getData()?
        this.getHandler().setValue(this._root.data("new"));
        return this.getHandler().getValue();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(value: boolean) {
        this._root.data("original", value);
        this.getHandler().setValue(value);
        if (this.settings.valueChanged) {
            this.settings.valueChanged(value);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}
}
