import {
    IDHFSectionOptions,
    IDHFSection,
    IDHFControlDefinition,
    DocumentSectionType,
    ColumnTypesInfo,
    mDHF,
    ColumnEditor,
} from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { ITableRow } from "../../../common/UI/Controls/tableCtrl";
import { IGenericMap, ControlState, IStringMap } from "../../../globals";
import { IDropdownOption, IDHFConfig } from "../../../ProjectSettings";
import {
    IDhfTableOptions,
    ITableConfig,
    ITableConfigColumn,
} from "../../../common/businesslogic/FieldHandlers/Document/GenericTableDocHandler";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";
export { DhfTable };

class DhfTable extends BaseDHFSection<IDhfTableOptions> implements IDHFSection {
    private config: ITableConfig;
    private dhfTypeXML: string;
    private columnTypes: ColumnTypesInfo;

    constructor(defaultConfig: IDHFConfig, dhfTypeXML: string, dhfType: string, columnTypes: ColumnTypesInfo) {
        super();
        let setting = (<IGenericMap>defaultConfig)[dhfType];
        this.config = { default: setting ? setting : {} };
        this.dhfTypeXML = dhfTypeXML;
        this.columnTypes = columnTypes;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.tableCtrl(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    columns: this.getConfig(ctrl).columns,
                    showLineNumbers: false,
                },
            }),
        );
    }
    getConfig(ctrl: IDHFControlDefinition): IDhfTableOptions {
        let controllerConfig = this.fieldHandler.dhfFieldConfig as IDhfTableOptions;
        if (!controllerConfig) {
            controllerConfig = this.config.default;
        }

        return controllerConfig;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let existing: string[] = [];
        this.fieldHandler.dhfFieldConfig;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.fieldHandler && this.fieldHandler.dhfFieldConfig && (<any>this.fieldHandler.dhfFieldConfig).columns) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            existing = (<ITableConfigColumn[]>(<any>this.fieldHandler.dhfFieldConfig).columns).map(
                (col) => col.columnType,
            );
        }
        custom.tableCtrl({
            help: "Define columns of table",
            controlState: ControlState.DialogCreate,
            parameter: {
                canBeModified: true,
                columns: [
                    { name: "Id", field: "field", editor: ColumnEditor.none },
                    { name: "Column Name", field: "name", editor: ColumnEditor.textline },
                    {
                        name: "Column Type",
                        field: "columnType",
                        editor: ColumnEditor.select,
                        options: this.columnTypes.getNames(existing),
                    },
                ],
            },
            canEdit: true,
            fieldValue: JSON.stringify(this.getConfig(ctrl).columns),
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);
        let controllerConfigOrg = <IDhfTableOptions>ml.JSON.clone(controllerConfig);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let oldData = await ctrl.control.getController().getValueAsync();
        let newColumns = <ITableConfigColumn[]>JSON.parse(await custom.getController().getValueAsync());
        let columnMap: IStringMap = {};

        for (let idx = 0; idx < newColumns.length; idx++) {
            let newColumnId = "col" + idx;
            if (newColumns[idx].field) {
                // this column existed before -- we want to keep the values so we know where it goes
                columnMap[newColumns[idx].field] = newColumnId;
            }
            newColumns[idx].field = newColumnId;
            newColumns[idx].pos = idx;
            newColumns[idx].editor = this.columnTypes.getEditorOfType(newColumns[idx].columnType);
            let options = this.columnTypes.getOptionsOfType(newColumns[idx].columnType);
            if (options) {
                newColumns[idx].options = options;
            }
        }

        let needsDataUpdate =
            oldData &&
            (<ITableConfigColumn[]>JSON.parse(oldData)).length > 0 &&
            JSON.stringify(controllerConfigOrg.columns) !== JSON.stringify(newColumns);

        // save new configuration
        controllerConfig.columns = ml.JSON.clone(newColumns);
        // save it
        this.fieldHandler.setDHFConfig(controllerConfig);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.dhfValue.ctrlConfig = controllerConfig;
        if (needsDataUpdate) {
            let oldTable = JSON.parse(oldData);
            // delete old table
            let table: IStringMap[] = [];
            // re-add data
            $.each(oldTable, function (idx, oldRow) {
                let newRow: IStringMap = {};
                $.each(columnMap, function (idOld, idNew) {
                    if (oldRow[idOld]) {
                        // the column existed before and after the change
                        newRow[idNew] = oldRow[idOld];
                    }
                });
                table.push(newRow);
            });

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.dhfValue["fieldValue"] = JSON.stringify(table);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.dhfValue["fieldValue"] = oldData;
        }
        // update control
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.destroy();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.html("");
        mDHF.renderControl(ctrl, ctrlParameter, JSON.stringify(ctrl.dhfValue));

        return true;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastValue = await ctrl.control.getController().getValueAsync();
        let lastVal = lastValue ? JSON.parse(lastValue) : [];
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (lastVal.length == 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected GetColumnCount(controllerConfig: IDhfTableOptions) {
        return controllerConfig.columns ? controllerConfig.columns.length : 0;
    }
}
