import { app, globalMatrix, IItem, matrixSession } from "../../../globals";
import {
    IPrintCustomFormatter,
    IPrintFormatterTop,
    IPrintFormatter,
    IPrintFormatterTree,
    IPrintFormatterList,
    IPrintFormatterTraces,
    IPrintFormatterBlock,
    IPrintFormatterFields,
    IPrintFormatterTable,
    IPrintFormatterSubTable,
    IPrintSubTableCell,
    IPrintTableRow,
} from "../../../printinterface/PrintFormatter";
import { IPrintItemIterator } from "../../../printinterface/PrintIterators";
import { globalPrintConfig, PrintFindAllScriptsRegex } from "../../../printinterface/PrintProcessorInterfaces";
import { IPrintSorter } from "../../../printinterface/PrintSorter";
import { IDropdownOption } from "../../../ProjectSettings";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { MR1, IItemViewEvent, INewItemIdEvent, IItemChangeEvent, IGenericItemEvent } from "../../businesslogic/index";
import { IPlugin, plugins } from "../../businesslogic/index";
import { ml } from "../../matrixlib";

export type { IGenericTableRow, IGenericTableData };
export { PrintProjectUIMods };
export { initialize };

interface IGenericTableRow {
    [key: string]: string;
}
type IGenericTableData = IGenericTableRow[];

class PrintProjectUIMods implements IPlugin {
    public isDefault = true; // show always (no need to enable in admin)

    static CAT_TABLE = "TABLE";
    static CAT_SEQUENTIAL = "SEQUENTIAL";
    static CAT_TRACES = "TRACES";
    static CAT_BLOCK = "BLOCK";
    static CAT_FIELDS = "FIELDS";
    static CAT_SUBTABLE = "SUBTABLE";

    static TYPE_TOP = [PrintProjectUIMods.CAT_SEQUENTIAL, PrintProjectUIMods.CAT_TABLE];
    static TYPE_HIERARCHY = [PrintProjectUIMods.CAT_SEQUENTIAL, PrintProjectUIMods.CAT_TRACES];
    static TYPE_FORMATTER = [
        PrintProjectUIMods.CAT_FIELDS,
        PrintProjectUIMods.CAT_TRACES,
        PrintProjectUIMods.CAT_BLOCK,
        PrintProjectUIMods.CAT_FIELDS,
    ];

    static MENU_FIELD_AND_LABEL = [PrintProjectUIMods.CAT_FIELDS];
    static MENU_CSS = [PrintProjectUIMods.CAT_FIELDS];
    static MENU_ITEM = [PrintProjectUIMods.CAT_SEQUENTIAL, PrintProjectUIMods.CAT_BLOCK, PrintProjectUIMods.CAT_TRACES];
    static MENU_RECURSION = [PrintProjectUIMods.CAT_SEQUENTIAL, PrintProjectUIMods.CAT_TRACES];

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getMenu() {
        return "matrixcontext autoheader printletswrap printletseditwrap printletsunwrap" + " ";
    }

    static cssMenus: string[] = [];
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addTinyMenus(editor: any, valueChanged: Function, category: string) {
        editor.ui.registry.addMenuItem("menu_edit_function_params", {
            icon: "checklist",
            text: "Edit parameters",
            onAction: function () {
                let fct = $(editor.selection.getStart());

                globalPrintConfig.editFunctionOptions(fct.attr("title"), (newParams) => {
                    fct.attr("title", newParams);
                    valueChanged.apply(null);
                });
            },
        });

        PrintProjectUIMods.cssMenus = [];
        let fcts = globalPrintConfig.getFunctions("CSS");
        for (let fct in fcts) {
            // menu to wrap selection with a span which can have print function calls
            PrintProjectUIMods.cssMenus.push("menu_add_" + fct);
            editor.ui.registry.addMenuItem("menu_add_" + fct, {
                icon: "color-picker",
                text: "Insert span with " + fcts[fct].getName(),
                onAction: function () {
                    let template = `color:$['renderFunction':'${fct}']$`;
                    if (fcts[fct].getTemplate) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        template = fcts[fct].getTemplate();
                    }

                    editor.selection.setContent(
                        `<span class="printWrap" title="${template}">${editor.selection.getContent()}</span>`,
                    );
                    //globalPrintConfig.editStyle(editor.selection.getContent());
                },
            });
        }

        // menu to unwrap selection from a span which can have print function calls
        editor.ui.registry.addMenuItem("menu_remove_css_function", {
            icon: "close",
            text: "Remove CSS Function",
            onAction: function () {
                let wrap = $(editor.selection.getStart());
                if (!wrap.hasClass("printWrap")) {
                    wrap = wrap.closest(".printWrap");
                }
                wrap.replaceWith(wrap.contents());
            },
        });

        // edit span which can have print function calls
        editor.ui.registry.addMenuItem("menu_edit_css_function", {
            icon: "fill",
            text: "Edit CSS Function",
            onAction: function () {
                let wrap = $(editor.selection.getStart());
                let cssWrap = wrap.hasClass("printWrap") ? wrap : wrap.closest(".printWrap");
                globalPrintConfig.editStyle(cssWrap);
            },
        });

        editor.ui.registry.addMenuItem("menu_add_t_head", {
            icon: "change-case",
            text: "Wrap with auto header",
            onAction: function () {
                editor.selection.setContent("<h_depth_>" + editor.selection.getContent() + "</h_depth_>");
            },
        });

        editor.ui.registry.addMenuItem("menu_add_t_head1", {
            icon: "change-case",
            text: "Wrap with auto header + 1",
            onAction: function () {
                editor.selection.setContent("<h_depth1_>" + editor.selection.getContent() + "</h_depth1_>");
            },
        });

        editor.ui.registry.addMenuItem("menu_remove_t_head", {
            icon: "change-case",
            text: "Remove auto header",
            onAction: function () {
                let wrap = $(editor.selection.getStart()).closest("h_depth_");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (wrap.length == 0) {
                    wrap = $(editor.selection.getStart()).closest("h_depth1_");
                }
                wrap.replaceWith(wrap.contents());
            },
        });

        // context menu on macros
        editor.ui.registry.addContextMenu("matrixcontext", {
            update: function (element: Element) {
                let node = $(element);

                let menu: string[] = [];

                // add menu to allow to edit parameters of a function
                if ($(element).hasClass("execPrintFunction")) {
                    let functionUid = element.textContent;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (globalPrintConfig.functionHasOptions(functionUid)) {
                        menu.push("menu_edit_function_params");
                    }
                }

                // add menu to allow to edit parameters of a function
                if ($(element).hasClass("execPrintMacro")) {
                    let functionUid = element.textContent;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (globalPrintConfig.functionHasOptions(functionUid)) {
                        menu.push("menu_edit_function_params");
                    }
                }

                // menu to wrap selection with a span which can have print function calls
                if (!$(element).hasClass("printWrap") && !$(element).parent().hasClass("printWrap")) {
                    for (let cssmenu of PrintProjectUIMods.cssMenus) {
                        menu.push(cssmenu);
                    }
                }
                if ($(element).hasClass("printWrap") || $(element).parent().hasClass("printWrap")) {
                    menu.push("menu_remove_css_function");
                    menu.push("menu_edit_css_function");
                }

                // function to add/remove <h_depth> around something
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if ($(element).closest("h_depth_").length == 0 && $(element).closest("h_depth1_").length == 0) {
                    menu.push("menu_add_t_head");
                    menu.push("menu_add_t_head1");
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if ($(element).closest("h_depth_").length != 0 || $(element).closest("h_depth1_").length != 0) {
                    menu.push("menu_remove_t_head");
                }

                return menu;
            },
        });

        // Menu for item details
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (PrintProjectUIMods.MENU_ITEM.indexOf(category) != -1) {
            editor.ui.registry.addNestedMenuItem("PrintMenuItems", {
                text: "Item Information",
                getSubmenuItems: function () {
                    let fcts = globalPrintConfig.getFunctions("Item");

                    return PrintProjectUIMods.buildMenu(fcts, editor);
                },
            });
        }

        // Menu for FIELD and LABEL Details
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (PrintProjectUIMods.MENU_FIELD_AND_LABEL.indexOf(category) != -1) {
            editor.ui.registry.addNestedMenuItem("PrintMenuFields", {
                text: "Field Details",
                getSubmenuItems: function () {
                    let fcts = globalPrintConfig.getFunctions("Fields");

                    return PrintProjectUIMods.buildMenu(fcts, editor);
                },
            });

            editor.ui.registry.addNestedMenuItem("PrintMenuLabels", {
                text: "Label Information",
                getSubmenuItems: function () {
                    let fcts = globalPrintConfig.getFunctions("Label");

                    return PrintProjectUIMods.buildMenu(fcts, editor);
                },
            });
        }

        // recursion menu for sequential
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (PrintProjectUIMods.MENU_RECURSION.indexOf(category) != -1) {
            editor.ui.registry.addNestedMenuItem("PrintMenuMacros", {
                text: editor.fieldParams.parameter.printTracesRecurse ? "Navigating Traces" : "Navigating Folders",
                getSubmenuItems: function () {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    let menu: any[] = [];

                    // show menus to recurse into tree (folders and items)
                    if (
                        editor.fieldParams &&
                        editor.fieldParams.parameter &&
                        editor.fieldParams.parameter.printFolderIterator
                    ) {
                        // this is the folder of a sequential -> here goes the iteration over folders
                        for (let uid in globalPrintConfig.getAllIterators()) {
                            let iterator = globalPrintConfig.getAllFunctions()[uid];

                            if ((<IPrintItemIterator>iterator).folderIterator) {
                                menu.push({
                                    type: "menuitem",
                                    text: iterator.getName(),
                                    onAction: function () {
                                        const content = `<span class='mceNonEditable execPrintFunction' title='{"renderFunction":"${uid}"}'>${iterator.getName()}</span>`;
                                        editor.insertContent(content);
                                    },
                                });
                            }
                        }
                    }

                    // show menu to recurse into traces
                    if (
                        editor.fieldParams &&
                        editor.fieldParams.parameter &&
                        editor.fieldParams.parameter.printTracesRecurse
                    ) {
                        menu.push({
                            type: "menuitem",
                            text: "Follow traces",
                            onAction: function () {
                                const content = `<span class='mceNonEditable execPrintFunction' title='{"renderFunction":"recurseTraces"}'>Follow traces</span>`;
                                editor.insertContent(content);
                            },
                        });
                    }
                    // some special depth functions for both

                    let fcts = globalPrintConfig.getFunctions("Depth");
                    let fctuids = Object.keys(fcts).sort((a, b) => {
                        return fcts[a].getName().localeCompare(fcts[b].getName());
                    });
                    for (let uid of fctuids) {
                        let fct = fcts[uid];
                        menu.push({
                            type: "menuitem",
                            text: fct.getName(),
                            onAction: function () {
                                editor.insertContent(
                                    `<span class='mceNonEditable execPrintFunction' title='{"renderFunction":"${uid}"}'>${fct.getName()}</span> `,
                                );
                            },
                        });
                    }

                    return menu;
                },
            });
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static buildMenu(fcts: any, editor: any) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let menu: any[] = [];
        let fctuids = Object.keys(fcts).sort((a, b) => {
            return fcts[a].getName().localeCompare(fcts[b].getName());
        });
        let subs: string[] = [];
        for (let uid of fctuids) {
            let fct = fcts[uid];
            let sub = fct.getSubGroup ? fct.getSubGroup() : "";
            if (sub) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subs.indexOf(sub) == -1) {
                    subs.push(sub);
                }
            } else {
                menu.push({
                    type: "menuitem",
                    text: fct.getName(),
                    onAction: function () {
                        editor.insertContent(
                            `<span class='mceNonEditable execPrintFunction' title='{"renderFunction":"${uid}"}'>${fct.getName()}</span> `,
                        );
                    },
                });
            }
        }
        let subIdx = 0;
        for (let sub of subs.sort()) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let subMenu: any[] = [];
            for (let uid of fctuids) {
                let fct = fcts[uid];
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (sub == (fct.getSubGroup ? fct.getSubGroup() : "")) {
                    subMenu.push({
                        type: "menuitem",
                        text: fct.getName(),
                        onAction: function () {
                            editor.insertContent(
                                `<span class='mceNonEditable execPrintFunction' title='{"renderFunction":"${uid}"}'>${fct.getName()}</span> `,
                            );
                        },
                    });
                }
            }
            editor.ui.registry.addNestedMenuItem("SubMenu" + subIdx, {
                text: sub,
                getSubmenuItems: function () {
                    return subMenu;
                },
            });
            menu.push({
                type: "nestedmenuitem",
                text: sub,
                getSubmenuItems: function () {
                    return subMenu;
                },
            });
        }

        return menu;
    }
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private codeFieldId: number;

    private enabledProject = false;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
        // can have a code field
        this.codeFieldId = this.enabledProject && item.type ? globalMatrix.ItemConfig.getFieldId(item.type, "code") : 0;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {
        let conf = <IPrintCustomFormatter>matrixSession.getCustomerSettingJSON("PrintProcessor");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        this.enabledProject = conf && conf.source == matrixSession.getProject();
        if (this.enabledProject) {
            let itemSorters = globalPrintConfig.getItemSorters();
            // update menu used by ui
            let dropdowns: IDropdownOption[] = [];
            $.each(itemSorters, function (id: string, sorter: IPrintSorter) {
                dropdowns.push({ id: id + "asc", label: sorter.getName() + " ascending" });
                dropdowns.push({ id: id + "desc", label: sorter.getName() + " descending" });
            });
            globalMatrix.ItemConfig.setSettingJSON("dd_sorting", { groups: [], options: dropdowns });
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    subscribe() {
        let autoSave = "";

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        MR1.onItemDisplayed().subscribe(<any>this, (event: IItemViewEvent) => {
            if (!this.enabledProject) {
                return;
            }

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (event.item.id == autoSave && this.codeFieldId && !event.item[this.codeFieldId]) {
                app.setFieldValue(this.codeFieldId, " ");
                app.saveAsync(false);
            } else {
                // an item is displayed
                // update the print library (e.g. after a restore)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.saveToInstance(event.item.id, event.item.modDate);
            }
            autoSave = "";
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        MR1.onAfterCreate().subscribe(<any>this, (event: INewItemIdEvent) => {
            if (!this.enabledProject) {
                return;
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            autoSave = event.item.item.id;
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        MR1.onBeforeSaveAsync().subscribe(<any>this, (event: IItemChangeEvent) => {
            let res = $.Deferred();

            if (!this.enabledProject) {
                res.resolve();
                return res;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (ml.Item.parseRef(this._item.id).isFolder) {
                res.resolve();
                return res;
            }
            // verify there's at most one sub table in each table
            let type = this._item.type;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (type == PrintProjectUIMods.CAT_TABLE) {
                for (let tab = 0; tab < 2; tab++) {
                    // for both tables
                    this.getTable(type, ["ITEM ROWS - CELLS", "FOLDER ROWS - CELLS"][tab]).then((table) => {
                        let sub = "";
                        for (let row = 0; row < table.length; row++) {
                            let content = table[row].content;
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (content && content.indexOf(PrintProjectUIMods.CAT_SUBTABLE + "-") == 0) {
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (sub && sub != content) {
                                    ml.UI.showError(
                                        "you can only call one sub table per table",
                                        `You cannot call ${sub} and ${content} as they might have different numbers of rows.`,
                                    );
                                    res.reject();
                                    return res;
                                }
                                sub = content;
                            }
                        }
                    });
                }
            }
            if (this.codeFieldId) {
                this.convertPrintToJson().then((code) => {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    (<any>event).after[this.codeFieldId] = JSON.stringify(JSON.parse(code), null, 2);
                    res.resolve();
                });
            } else {
                res.resolve();
            }
            return res;
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        MR1.onAfterSave().subscribe(<any>this, (event: IItemChangeEvent) => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!this.enabledProject || ml.Item.parseRef(this._item.id).isFolder) {
                let res = $.Deferred();
                res.resolve();
                return res;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return this.saveToInstance(event.after.id, event.after.modDate);
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        MR1.onBeforeDeleteAsync().subscribe(<any>this, (event: IGenericItemEvent) => {
            this.itemsToDelete = [];

            if (this.enabledProject) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (ml.Item.parseRef(event.item.id).isFolder) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.itemsToDelete = app.getChildrenIdsRec(event.item.id);
                } else {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.itemsToDelete = [event.item.id];
                }
            }

            let res = $.Deferred();
            res.resolve();
            return res;
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        MR1.onAfterDelete().subscribe(<any>this, (event: IGenericItemEvent) => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.enabledProject && !ml.Item.parseRef(this._item.id).isFolder) {
                this.markDeleted();
            }
        });
    }

    // called after saving item
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async saveToInstance(itemId: string, modDate: string) {
        let that = this;

        let code = await this.convertPrintToJson();

        app.readSettingCustomerJSONAsync("PrintProcessor").done(function (formatters: IPrintCustomFormatter) {
            try {
                // update the setting
                if (!formatters.items) {
                    formatters.items = {};
                }

                let json = JSON.parse(code);
                json.deleted = false;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (formatters.items[itemId] && formatters.items[itemId].modDate == modDate) {
                    // nothing to save!
                    return;
                }
                json.modDate = modDate;

                formatters.items[itemId] = json;

                // publish it
                app.setSettingCustomerJSON("PrintProcessor", formatters)
                    .done(function () {
                        //ml.UI.showSuccess("published!", 1000);
                    })
                    .fail(function () {
                        ml.UI.showError("Failed to save to print config", "");
                    });
            } catch (ex) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.UI.showError("Failed to convert to json", ex.toString());
            }
        });
    }

    // called after saving item
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private itemsToDelete: string[];
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private markDeleted() {
        let that = this;

        app.readSettingCustomerJSONAsync("PrintProcessor").done(function (formatters: IPrintCustomFormatter) {
            if (!formatters.items) {
                formatters.items = {};
            }

            for (let formatter of that.itemsToDelete) {
                if (
                    formatters.items[formatter] &&
                    (<IPrintFormatterTop>(<unknown>formatters.items[formatter])).topLevelTemplate
                ) {
                    (<IPrintFormatterTop>(<unknown>formatters.items[formatter])).deleted = true;
                }
            }

            that.itemsToDelete = [];
            // publish it
            app.setSettingCustomerJSON("PrintProcessor", formatters)
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                .done(function () {})
                .fail(function () {
                    ml.UI.showError("Failed to save to print config", "");
                });
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private isOnlyRecursion(macro: string) {
        if (!macro) {
            return true;
        } // nothing so nothing to print

        let close = macro.match("]$");

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (close && close.length != 1) {
            return false;
        } // two functions
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (macro.indexOf("]$") != macro.length - 2) {
            return false;
        } //something after last macro

        for (let iterator of Object.keys(globalPrintConfig.getAllIterators())) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (macro.indexOf(`$["renderFunction":"${iterator}"`) == 0) {
                return true;
            }
        }
        return false;
    }

    // called before saving items
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async convertPrintToJson() {
        let type = this._item.type;
        let code = "";

        let result: IPrintFormatter = {
            uid: app.getCurrentItemId(),
            name: await app.getCurrentTitle(),
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            help: await this.toText(type, "Description"),
        };

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (PrintProjectUIMods.TYPE_TOP.indexOf(type) != -1) {
            const topLevel = await app.getFieldValueAsync(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                globalMatrix.ItemConfig.getFieldId(type, "Top Level Template"),
            );
            let breadcrumbs = app.getBreadcrumbs(app.getCurrentItemId()).reverse();
            breadcrumbs.splice(0, 2);
            let titles = breadcrumbs.map((id) => app.getItemTitle(id));

            (<IPrintFormatterTop>result).topLevelTemplate = topLevel;
            (<IPrintFormatterTop>result).path = topLevel ? titles.join(" &gt; ") : "";
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (PrintProjectUIMods.TYPE_HIERARCHY.indexOf(type) != -1) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            const ul = await this.getBeforeAfter(type, "ul");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            const li = await this.getBeforeAfter(type, "li");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            const item = li[0] + (await this.toPrintScript(type, "item")) + li[1];

            (<IPrintFormatterTree>result).item = item;
            (<IPrintFormatterTree>result).before = ul[0] as string;
            (<IPrintFormatterTree>result).after = ul[1] as string;
        }

        switch (type) {
            case PrintProjectUIMods.CAT_SEQUENTIAL: {
                const li = await this.getBeforeAfter(type, "li");
                const topLevel = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "Top Level Template"),
                );
                let folderMacro = await this.toPrintScript(type, "folder");
                const hideFolder = this.isOnlyRecursion(folderMacro);

                (<IPrintFormatterList>result).topLevelTemplate = topLevel;
                (<IPrintFormatterList>result).folder =
                    (hideFolder ? "" : li[0]) + folderMacro + (hideFolder ? "" : li[1]);
                (<IPrintFormatterList>result).hideFolder = hideFolder;
                break;
            }
            case PrintProjectUIMods.CAT_TRACES: {
                let traceIterator = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(type, "iterator"));
                (<IPrintFormatterTraces>result).iterator = traceIterator ? traceIterator : "";
                (<IPrintFormatterTraces>result).params = traceIterator
                    ? await this.toJSON(type, "iterator params")
                    : "{}";
                break;
            }
            case PrintProjectUIMods.CAT_BLOCK:
                (<IPrintFormatterBlock>result).render = await this.toPrintScript(type, "item or folder");
                (<IPrintFormatterBlock>result).condition = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "condition"),
                );
                (<IPrintFormatterBlock>result).params = await this.toJSON(type, "condition params");
                break;
            case PrintProjectUIMods.CAT_FIELDS: {
                let raw = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(type, "raw"));

                (<IPrintFormatterFields>result).render = await this.toPrintScript(type, "item or folder");
                (<IPrintFormatterFields>result).iterator = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "iterator"),
                );
                (<IPrintFormatterFields>result).params = await this.toJSON(type, "iterator params");
                (<IPrintFormatterFields>result).render = raw ? raw : await this.toPrintScript(type, "field or label");
                (<IPrintFormatterFields>result).before = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "before"),
                );
                (<IPrintFormatterFields>result).after = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "after"),
                );
                break;
            }
            case PrintProjectUIMods.CAT_TABLE:
                (<IPrintFormatterTable>result).iterator = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "ITERATOR"),
                );
                (<IPrintFormatterTable>result).params = await this.toJSON(type, "iterator params");
                (<IPrintFormatterTable>result).subTableFolder = await this.getTableItemRowSubtable(
                    type,
                    "FOLDER ROWS - CELLS",
                );
                (<IPrintFormatterTable>result).subTable = await this.getTableItemRowSubtable(type, "ITEM ROWS - CELLS");
                (<IPrintFormatterTable>result).before = await this.getTableBefore(type, "TABLE HEADERS");
                (<IPrintFormatterTable>result).after = this.getTableAfter(type, "TABLE HEADERS");
                (<IPrintFormatterTable>result).renderItemRow = await this.getTableItemRow(
                    type,
                    "ITEM ROWS - CELLS",
                    "Item Rows - Styling",
                );
                (<IPrintFormatterTable>result).renderFolderRow = await this.getTableItemRow(
                    type,
                    "FOLDER ROWS - CELLS",
                    "Folder Rows - Styling",
                );
                break;
            case PrintProjectUIMods.CAT_SUBTABLE:
                (<IPrintFormatterSubTable>result).iterator = await app.getFieldValueAsync(
                    globalMatrix.ItemConfig.getFieldId(type, "ITERATOR"),
                );
                (<IPrintFormatterSubTable>result).params = await this.toJSON(type, "iterator params");
                (<IPrintFormatterSubTable>result).subTable = await this.getTableItemRowSubtable(type, "COLUMNS");
                (<IPrintFormatterSubTable>result).cells = await this.getSubTableCells(type, "COLUMNS");
        }

        return JSON.stringify(result);
    }

    // helper to convert TABLE category
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async getTableBefore(category: string, field: string) {
        let tableStr = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(category, field));
        return tableStr.replace("</tbody>", "").replace("</table>", "");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getTableAfter(category: string, field: string) {
        //let tableStr = app.getFieldValue( IC.getFieldId(category,field) );
        return "</tbody></table>";
    }

    private async getTable(category: string, name: string): Promise<IGenericTableData> {
        try {
            let fieldIds = globalMatrix.ItemConfig.getFieldsOfType(FieldDescriptions.Field_steplist, category).filter(
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (field) => field.field.label.toLowerCase() == name.toLowerCase(),
            );
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (fieldIds.length == 0) {
                ml.UI.showError("bad configuration!", "table " + name + "does not exist");
                return [];
            }
            let fieldId = fieldIds[0].field.id;
            let tableStr = await app.getFieldValueAsync(fieldId);
            if (!tableStr) {
                return [];
            }

            return JSON.parse(tableStr);
        } catch (ex) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.UI.showError("bad data in table " + name, ex.toString());
            return [];
        }
    }

    private rowBefore(colspan: string | number, classValue: string, merge: boolean): string {
        return `<td rowspan="${
            merge ? "_merge_" : "1"
        }" class="${classValue} _classes_" style="_style_" colspan="${colspan}">`;
    }

    private rowAfter(): string {
        return "</td>";
    }

    private async getSubTableCells(category: string, name: string): Promise<IPrintSubTableCell[]> {
        let table = await this.getTable(category, name);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (table.length == 0) {
            // nothing should be rendered
            return [];
        }
        let subtable = "";
        let cells: IPrintSubTableCell[] = [];
        for (let row = 0; row < table.length; row++) {
            let content = table[row].content;

            if (!content) {
                ml.UI.showError("bad item table", "empty cell found");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (PrintProjectUIMods.TYPE_FORMATTER.indexOf(content.split("-")[0]) != -1) {
                // normal text cell
                cells.push({
                    render: `$["renderItem":"${content}"]$`,
                    fetchColumn: 0,
                    fetchColumnAlt: "",
                });
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (content.indexOf(PrintProjectUIMods.CAT_SUBTABLE + "-") == 0) {
                const subtableIdx =
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    table[row].subtable && table[row].subtable.charAt(0) == "c"
                        ? table[row].subtable.replace("c", "")
                        : "1";
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subtable && subtable != content) {
                    ml.UI.showError("bad item table", "only one sub table is supported");
                } else {
                    subtable = content;
                    cells.push({
                        render: "",
                        fetchColumn: parseInt(subtableIdx),
                        fetchColumnAlt: table[row].alttext ? table[row].alttext : "",
                    });
                }
            }
        }
        return cells;
    }

    private async getTableItemRow(
        category: string,
        tableName: string,
        stylingFieldName: string,
    ): Promise<IPrintTableRow> {
        let table = await this.getTable(category, tableName);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (table.length == 0) {
            // nothing should be rendered
            return { before: "", after: "", cells: [] };
        }
        let subtable = "";
        const stylingFieldId = globalMatrix.ItemConfig.getFieldByName(category, stylingFieldName);
        const stylingValue = await app.getFieldValueAsync(stylingFieldId.id);
        let ptr: IPrintTableRow = { before: `<tr style="${stylingValue}">`, after: "</tr>", cells: [] };
        for (let row = 0; row < table.length; row++) {
            let colspan = table[row].colspan ? table[row].colspan.replace("c", "") : 1;
            const classValue = table[row].class ? table[row].class : "";
            let content = table[row].content;
            let subtableIdx = table[row].subtable ? table[row].subtable.replace("c", "") : "1";
            if (!content) {
                ml.UI.showError("bad table", "there needs to be a value in all cells of the first column");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (PrintProjectUIMods.TYPE_FORMATTER.indexOf(content.split("-")[0]) != -1) {
                // normal text cell
                ptr.cells.push({
                    before: this.rowBefore(colspan, classValue, ml.JSON.isTrue(table[row].merge)),
                    after: this.rowAfter(),
                    render: `$["renderItem":"${content}"]$`,
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    merge: table[row].merge == "true",
                    fetchColumn: 0,
                    fetchColumnAlt: "",
                });
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (content.indexOf(PrintProjectUIMods.CAT_SUBTABLE + "-") == 0) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subtable && subtable != content) {
                    ml.UI.showError("bad table", "only one sub table is supported");
                } else {
                    subtable = content;
                    ptr.cells.push({
                        before: this.rowBefore(colspan, classValue, ml.JSON.isTrue(table[row].merge)),
                        after: this.rowAfter(),
                        render: "",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        merge: table[row].merge == "true",
                        fetchColumn: parseInt(subtableIdx),
                        fetchColumnAlt: table[row].alttext ? table[row].alttext : "",
                        sortPriority: table[row].sortpriority ? Number(table[row].sortpriority.replace("c", "")) : 0,
                        sortFunction: table[row].sortfunction ? table[row].sortfunction : "",
                    });
                }
            }
        }
        return ptr;
    }

    private async getTableItemRowSubtable(category: string, name: string): Promise<string> {
        let table = await this.getTable(category, name);
        for (let row = 0; row < table.length; row++) {
            let content = table[row].content;

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (content && content.indexOf(PrintProjectUIMods.CAT_SUBTABLE + "-") == 0) {
                return content;
            }
        }
        return "";
    }

    // general help to transform stuff
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async toPrintScript(category: string, field: string) {
        const fieldId = globalMatrix.ItemConfig.getFieldId(category, field);
        if (!fieldId) {
            // e.g. TRACES has no folder
            return "";
        }
        let code = (await app.getFieldValueAsync(fieldId)).replace(/\n/g, "").replace(/_codemode_/g, "");

        // replace single quote in attribute scripts
        // that will be undone after going through an xml validation...
        let scripts = code.match(PrintFindAllScriptsRegex);
        if (scripts) {
            for (const script of scripts) {
                code = code.replace(script, script.replace(/'/g, "xquotx"));
            }
        }

        // replace smart links

        let categories = globalMatrix.ItemConfig.getCategories(true);
        // let lists = code.match( new RegExp(`((F-)*(\/)*(${categories.join("|")})-[0-9]+)`,'g'));
        let lists = code.match(new RegExp(`(${categories.join("|")})-[0-9]+`, "g"));
        // remove duplicates
        let renderList = lists
            ? lists.filter(function (value: string, index: number, self: string[]) {
                  return self.indexOf(value) === index;
              })
            : [];
        $.each(renderList, function (idx, match) {
            code = code.replace(new RegExp(match + "!", "g"), match);
            code = code.replace(new RegExp(match + "\\+", "g"), match);
            code = code.replace(new RegExp(match + "(?=$|[^0-9])", "g"), '$["renderItem":"' + match + '"]$');
        });

        let xml = $($.parseHTML("<xml />"));

        try {
            xml = $($.parseHTML("<xml>" + code + "</xml>"));
        } catch (ex) {
            ml.UI.showError(field, "'" + field + "' need to be correct xml/html");
        }

        // replace the calls to functions
        $.each($(".execPrintFunction", xml), function (idx, exec) {
            let fct = $(exec).attr("title");
            fct = fct.replace(/^{/, "$[").replace(/}$/, "]$");
            // keep < as <
            fct = fct.replace(/</g, "xlessthanx");
            $(exec).replaceWith(fct);
        });

        let resolved = xml.html();

        // replace the temp quotes by \" quotes and put back the <
        resolved = resolved.replace(/xquotx/g, '"').replace(/xlessthanx/g, "<");

        // replace the printWrap title="" by printWrap style="" - that's a bit of a hack I could save it as style directly but that gets lowercased
        resolved = resolved.replace(/class="printWrap" title=/g, 'class="printWrap" style=');

        return resolved;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async getBeforeAfter(category: string, field: string) {
        let text = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(category, field));
        if (text) {
            try {
                let html = $($.parseHTML(text)).append("_splitmehere_")[0].outerHTML;
                let split = html.split("_splitmehere_");
                return [split[0], split[1]];
            } catch (ex) {
                ml.UI.showError(field, "'" + field + "' need to be correct xml/html");
                return $($.parseHTML("<xml />"));
            }
        }
        return ["", ""];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async toJSON(category: string, field: string) {
        let text = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(category, field));
        if (text) {
            try {
                return eval("(" + text + ")");
            } catch (ex) {
                ml.UI.showError(field, "'" + field + "' need to be correct json");
                return {};
            }
        }
        return {};
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async toXML(category: string, field: string) {
        let text = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(category, field));
        if (text) {
            try {
                return $($.parseHTML("<xml>" + text + "</xml>"));
            } catch (ex) {
                ml.UI.showError(field, "'" + field + "' need to be correct xml/html");
                return $($.parseHTML("<xml />"));
            }
        }
        return $($.parseHTML("<xml />"));
    }

    private async toText(category: string, field: string): Promise<string> {
        const text = await app.getFieldValueAsync(globalMatrix.ItemConfig.getFieldId(category, field));
        if (text) {
            return text;
        } else {
            return "";
        }
    }
}

let auto = new PrintProjectUIMods();
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    plugins.register(auto);
    auto.subscribe();
}
