import { app, globalMatrix, matrixSession } from "../../../globals";
import { IPrintCustomFormatter, IPrintFormatterTop, IPrintFormatter } from "../../../printinterface/PrintFormatter";
import { IPrintBaseFunction } from "../../../printinterface/PrintFunction";
import { ICustomSection, globalPrintConfig } from "../../../printinterface/PrintProcessorInterfaces";
import {
    XRPostProject_LaunchReport_CreateReportJobAck,
    XRGetProject_JobStatus_JobsStatusWithUrl,
} from "../../../RestResult";
import { ml } from "../../matrixlib";
import { IReportOptions, UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { JsonEditor } from "../JsonEditor";
import { ICustomSectionOptions } from "../../businesslogic/FieldHandlers/Document/CustomDocFieldHandler";

export { Layouter };

class Layouter {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private itemId: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mf: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private sectionConfig: ICustomSection;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private fromSelection: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private toSelection: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private isLandScape: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private displayStyle: { width: string };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor() {}

    // show editor dialog

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    show(
        itemId: string,
        fieldId: number,
        sectionConfig: ICustomSectionOptions,
        fromSelection: string,
        toSelection: string,
        labelFilter: string,
        onUpdate: (code: string) => void,
        previewOnly = false,
        previewDiv?: JQuery,
    ) {
        let that = this;
        this.itemId = itemId;
        this.sectionConfig = sectionConfig && sectionConfig.options ? sectionConfig.options : <ICustomSection>{};
        // TODO(modules): property landscape not found on ICustomSectionOptions.
        this.isLandScape = sectionConfig && sectionConfig.landscape;

        this.toSelection = toSelection;
        this.fromSelection = fromSelection;

        let ui = $("<div style='width:100%'>");
        ui.append(ml.UI.getSpinningWait("retrieving data..."));

        //MATRIX-6029: Hack to call a specific report for print with only 1 level of downlinks. For that we need to specify the reportNameOverride in the reportOptions
        let reportOptions: IReportOptions = { format: "mf", reportNameOverride: "dhf_generic_print" };
        if (labelFilter && labelFilter.length > 0) {
            reportOptions.filter = JSON.parse(labelFilter).join(",");
        }
        let start1 = new Date().getTime();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.startCreateDocumentAsync(this.itemId, reportOptions).done(function (
            result: XRPostProject_LaunchReport_CreateReportJobAck,
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.waitForJob(result.jobId).done(function (iXML: number) {
                // , oXML:number) {
                let start2 = new Date().getTime();
                console.log("time to create filter[s]: " + (start2 - start1) / 1000);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.downloadInMemory(result.jobId, iXML.toString(), "text").done(function (iDown: string) {
                    let start3 = new Date().getTime();
                    console.log("time to download filter[s]: " + (start3 - start2) / 1000);
                    if (iDown === "") {
                        console.error(
                            "Received empty filter.xml, check the server response and make sure it can be loaded.",
                        );
                        ml.UI.showError(
                            "Error loading print data",
                            "Failed to load data from server, please contact support",
                        );
                        return;
                    }
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    that.mf = <any>$.parseXML(iDown);
                    let start4 = new Date().getTime();
                    console.log("time to create dom[s]: " + (start4 - start3) / 1000);
                    app.readSettingCustomerJSONAsync("PrintProcessor").done(function (
                        formatters: IPrintCustomFormatter,
                    ) {
                        if (!previewOnly) {
                            that.initEditor(formatters, ui);
                        } else {
                            that.displayPreview(previewDiv);
                        }
                    });
                });
            });
        });
        // show dialog
        if (!previewOnly) {
            let dlg = $("<div class=''>").appendTo($("body"));
            ml.UI.showDialog(
                dlg,
                "Section Layout",
                ui,
                $(document).width() * 0.9,
                app.itemForm.height() * 0.9,
                [
                    {
                        text: "Save",
                        class: "btnDoIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            onUpdate(JSON.stringify(that.sectionConfig));
                            $("#layoutR").html("");
                            dlg.dialog("close");
                        },
                    },
                    {
                        text: "Cancel",
                        class: "btnCancelIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            $("#layoutR").html("");
                            dlg.dialog("close");
                        },
                    },
                ],
                UIToolsConstants.Scroll.None,
                true,
                true,
                () => {
                    dlg.remove();
                },
                () => {},
                () => {
                    $("#layoutR").height(dlg.height());
                    dlg.resizeDlgContent([]);
                },
            );
        }
    }

    // show the layout editor
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private initEditor(formatters: IPrintCustomFormatter, ui: JQuery) {
        let sidebar = localStorage.getItem("layout_sidebar");
        if (!sidebar) {
            sidebar = "200px";
        }

        let layoutC = $(
            `<div id="layoutC" style="overflow:auto;width:${sidebar};padding-right:12px;float:left;height: 100%;margin-right: 6px;">`,
        );
        let dragbar = $(
            `<div style="background-color:var(--Grey6);height: 100%;float:left;width: 5px;cursor: col-resize;">`,
        );
        let layoutR = $(`<div id="layoutR" style="overflow:auto;padding-left:12px;height: 100%;">`);

        ui.html("").append(layoutC).append(dragbar).append(layoutR).css("height", "100%");

        const leftContainer = $(`<div class="layouter-left-container">`).appendTo(layoutC);

        // dropdown  to select width
        this.displayStyle = { width: this.isLandScape ? "26.7cm" : "18cm" };
        ml.UI.addDropdownToValue(
            leftContainer,
            "page size & orientation (preview)",
            this.displayStyle,
            "width",
            [
                { id: "18cm", label: "Portrait (A4)" },
                { id: "18.6cm", label: "Portrait (Letter)" },
                { id: "26.6cm", label: "Portrait (A3)" },
                { id: "26.7cm", label: "Landscape (A4)" },
                { id: "24.9cm", label: "Landscape (Letter)" },
                { id: "39cm", label: "Landscape (A3)" },
                { id: "100%", label: "Full width" },
                { id: "EXCEL", label: "Excel (Plaintext)" },
            ],
            false,
            false,
            () => this.displayPreview(),
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            null,
            { width: "100%" },
        );

        const textOptionDiv = $("<div style='margin: -12px 0 0 0;'>").appendTo(leftContainer);
        $("<button class='btn btn-link'>Set introductory text</button>")
            .click(() => this.introOptions())
            .appendTo(textOptionDiv);
        $("<button class='btn btn-link'>Set advanced options</button>")
            .click(() => this.advancedOptions())
            .appendTo(textOptionDiv);

        let filter = $(
            '<input type="text" style="margin-bottom:10px;padding: 6px 12px;" placeholder="filter..." class="form-control">',
        )
            .on("keyup", function (e) {
                let filterVal = filter.val().toLowerCase();
                $(".formatter-item").each((idx, fi) => {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    filterVal && $(fi).text().toLowerCase().indexOf(filterVal) == -1 ? $(fi).hide() : $(fi).show();
                });
            })
            .appendTo(leftContainer);

        this.formatterList(formatters).appendTo(leftContainer);

        dragbar.mousedown(function (e) {
            if (e.preventDefault) {
                e.preventDefault();
            }
            $(document).mousemove(function (e) {
                let maxWidth = ui.width() - 100;
                let mousePos = e.pageX - layoutC.offset().left; // mousepos relative to left border of layoutC
                if (mousePos > 100 && mousePos < maxWidth) {
                    localStorage.setItem("layout_sidebar", mousePos - 15 + "px");
                    layoutC.width(mousePos - 15);
                }
            });
        });

        if (
            !this.sectionConfig.formatter ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.sectionConfig.formatter.trim() == "" ||
            !formatters.items[this.sectionConfig.formatter]
        ) {
            layoutR.append(`<div style="padding: 16px;"><em>Please choose a layout!</em></div>`);
        } else {
            this.displayPreview();
        }
    }

    private formatterList(formatters: IPrintCustomFormatter): JQuery {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        const createFormatter = (formatter: IPrintFormatterTop) => {
            const selected = this.sectionConfig.formatter === formatter.uid ? "selected" : "";
            const id = `formatter-item-${formatter.uid}`;
            let help = formatter.help;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (help == null || help.trim() == "") {
                help = "<i>No description provided</i>";
            }
            return $(
                `<div class="formatter-item ${selected}" id="${id}" title="${
                    formatter.path ? formatter.path : ""
                }"><div class="formatter-title"><span class="formatter-id">${formatter.uid}</span> ${
                    formatter.name
                }</div><div class="formatter-help">${help}</div></div>`,
            ).click(() => {
                if (globalMatrix.globalShiftDown) {
                    //open the source item in a new tab
                    const url = `${globalMatrix.matrixBaseUrl}/${formatters.source}/${formatter.uid}`;
                    window.open(url, "_blank");
                    return;
                }
                this.sectionConfig.formatter = formatter.uid;
                this.displayPreview();
                $(".formatter-item.selected").removeClass("selected");
                $("#" + id).addClass("selected");
            });
        };
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        const sectionTitle = (title: string) => {
            return $(`<div class="list-section-title">${title}</div>`);
        };

        const formatterList = $(`<div class="formatter-list">`);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        const scroller = () => {
            const id = `#formatter-item-${this.sectionConfig.formatter}`;
            const offsetElement = $(id);
            if (offsetElement.length > 0) {
                const me = offsetElement[0];
                const myOffset = me.offsetTop;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                const parentOffset = me.parentElement.offsetTop;
                formatterList.scrollTop(myOffset - parentOffset);
            }
        };

        sectionTitle("Lists").appendTo(formatterList);
        const topLevelLists = Layouter.filterTopLevelFormatters(formatters.items, "SEQUENTIAL");
        const topLevelTables = Layouter.filterTopLevelFormatters(formatters.items, "TABLE");
        Object.keys(topLevelLists)
            .sort(Layouter.sortItems)
            .forEach((id) => {
                const formatter = <IPrintFormatterTop>(<unknown>formatters.items[id]);
                createFormatter(formatter).appendTo(formatterList);
            });
        sectionTitle("Tables").appendTo(formatterList);
        Object.keys(topLevelTables)
            .sort(Layouter.sortItems)
            .forEach((id) => {
                const formatter = <IPrintFormatterTop>(<unknown>formatters.items[id]);
                createFormatter(formatter).appendTo(formatterList);
            });
        window.setTimeout(scroller, 100);
        return formatterList;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private displayPreview(previewDiv?: JQuery) {
        let width = "100%";
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (previewDiv == null) {
            previewDiv = $("#layoutR");
            width = this.displayStyle.width;
        }
        Layouter.convert(this.sectionConfig, this.fromSelection, this.toSelection, width, this.mf, previewDiv);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private introOptions() {
        const dlg = $("<div>").appendTo($("body"));
        const ui = $("<div style='height:100%;width:100%'>");
        ml.UI.addRichTextInput(
            ui,
            { width: "100%" },
            "intro",
            this.sectionConfig,
            "description",
            () => {},
            () => {},
        );
        ml.UI.addRichTextInput(
            ui,
            { width: "100%" },
            "intro only if there are items selected",
            this.sectionConfig,
            "descriptionContent",
            () => {},
            () => {},
        );
        ml.UI.addRichTextInput(
            ui,
            { width: "100%" },
            "intro if there are no items selected",
            this.sectionConfig,
            "descriptionNoContent",
            () => {},
            () => {},
        );
        ml.UI.showDialog(
            dlg,
            "Set introductory text",
            ui,
            $(document).width() * 0.9,
            app.itemForm.height() * 0.9,
            [
                {
                    text: "Close",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: () => {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {
                dlg.remove();
                this.displayPreview();
            },
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private advancedOptions() {
        let current = this.sectionConfig.functionDefaults ? this.sectionConfig.functionDefaults : {};
        let je = new JsonEditor();
        let apiHelp = `<h1>Section options</h1>
<p>The content is rendered using functions specified in the PRINT project. Each function has some parameters with some default values.</p>
<p>The default parameters of functions can be overwritten globally (for all projects), in the PRINT project itself, for the project itself or for each section</p>
<p>These are the section settings with the following syntax</p>
<pre>
{
    "debug":number // default:0. if set to 1 or more some debugging information is printed.
    "FUNCTION_1_ID": { // the id of the function (see below a list of all functions)
        "FUNCTION_PARAM_1":value1 // the name of a parameter and it's value
        "FUNCTION_PARAM_2":value2
        ...
    },
    "FUNCTION_2_ID": {
        ...
    }
}
</pre>
<p>The following functions exist:</p>`;

        $.each(globalPrintConfig.getAllFunctions(), function (uid: string, fct: IPrintBaseFunction) {
            apiHelp += fct.getHelp(true).replace("<pre>", `<pre>"${uid}":{`).replace("</pre>", "}</pre>");
        });

        let currentCode = ml.JSON.unEscapeJson(JSON.stringify(current));
        je.showDialog(
            "Advanced Options",
            currentCode,
            (newValue: string) => {
                this.sectionConfig.functionDefaults = JSON.parse(ml.JSON.escapeJson(newValue));
                this.displayPreview();
            },
            { apiHelp: apiHelp },
        );
    }

    private static sortItems(a: string, b: string): number {
        const aRef = ml.Item.parseRef(a);
        const bRef = ml.Item.parseRef(b);
        return aRef.number - bRef.number;
    }

    private static filterTopLevelFormatters(
        formatters: { [key: string]: IPrintFormatter },
        category: string,
    ): { [key: string]: IPrintFormatterTop } {
        const result: { [key: string]: IPrintFormatterTop } = {};
        Object.keys(formatters).forEach((key) => {
            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                key.indexOf(category + "-") == 0 &&
                !(<IPrintFormatterTop>(<unknown>formatters[key])).deleted &&
                (<IPrintFormatterTop>(<unknown>formatters[key])).topLevelTemplate
            ) {
                result[key] = <IPrintFormatterTop>formatters[key];
            }
        });
        return result;
    }

    // wait until a document has been created
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private waitForJob(jobId: number) {
        let that = this;
        let res: JQueryDeferred<number> = $.Deferred();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getReportDetails(jobId).done(function (progress: XRGetProject_JobStatus_JobsStatusWithUrl) {
            if (progress.status === "Error" || progress.status.indexOf("Report generation error") === 0) {
                ml.UI.showError("Error creating document", "");
            } else if (progress.status !== "Done" || progress.progress < 100) {
                window.setTimeout(function () {
                    // TODO: what is this oXML and where is it coming from? wasn't it commented out on line 416?
                    that.waitForJob(jobId).done(function (iXML, oXML) {
                        res.resolve(iXML, oXML);
                    });
                }, 500);
            } else {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let filters = progress.jobFile.filter((jobFile) => jobFile.visibleName == "filter.xml");
                // let mos = progress.jobFile.filter( (jobFile) => { return jobFile.visibleName != "filter.xml" && jobFile.mimeType == "text/xml"});

                res.resolve(filters[0].jobFileId); //, mos[0].jobFileId);
            }
        });
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static convert(
        customSectionConfig: ICustomSection,
        customSectionFroms: string,
        customSectionTo: string,
        paperWidth: string,
        mf: JQuery,
        container: JQuery,
    ) {
        let pp = globalPrintConfig.getPrintProcessor();
        let format = "html";
        if (paperWidth === "EXCEL") {
            format = "xlsx";
        }
        let startPrepare = new Date().getTime();
        pp.prepareProcessing(
            mf,
            (message) => {
                ml.UI.showError("processing error", message);
            },
            format,
        );
        let timer = new Date().getTime() - startPrepare;
        console.log("report preparation time [s]:" + timer / 1000);

        container.html("").append(ml.UI.getSpinningWait("creating preview..."));

        app.readSettingCustomerJSONAsync("PrintProcessor").done(async function (formatters: IPrintCustomFormatter) {
            // put newest into cache
            matrixSession.setCustomerSettingJSON("PrintProcessor", formatters);

            let projectSettings = globalMatrix.ItemConfig.getDHFConfig();
            let projectFunctionDefaults =
                projectSettings && projectSettings.functionDefaults ? projectSettings.functionDefaults : { debug: 0 };

            let startDate = new Date().getTime();
            let rendered = await pp.processSection(
                formatters,
                customSectionConfig,
                projectFunctionDefaults,
                customSectionFroms ? customSectionFroms.split(",") : [],
                customSectionTo ? customSectionTo.split(",") : [],
            );
            let timer = new Date().getTime() - startDate;
            console.log("report generation time [s]:" + timer / 1000);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            console.log(pp.globals.count);
            let iframe = $(`<iframe frameborder='0' style='width:${paperWidth}'>`);
            container.html("");

            container.append(iframe);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let doc = ((<any>iframe[0]).contentWindow || (<any>iframe[0]).contentDocument).document;
            const ppStylingKey = "new_element_custom_style";
            let ppStyling: string = "";
            if (globalMatrix.ItemConfig.getSetting(ppStylingKey)) {
                ppStyling += globalMatrix.ItemConfig.getSetting(ppStylingKey) + "\n";
            }
            ppStyling += pp.getCustomStylesheet();
            doc.write(`<!doctype html>
            <html>
                <head>
                <link href="${globalMatrix.matrixBaseUrl}/static/css/print.css" rel="stylesheet" type="text/css" />
                <link href="${globalMatrix.matrixBaseUrl}/css/font-awesome.min.css" rel="stylesheet" type="text/css" />
                <style>
                    ${ppStyling}
                </style>
                </head>
                <body>
                    ${rendered.html}
                </body>
            </html>`);
            doc.close();
            $("a", doc.body).attr("target", "_blank");

            iframe.width(
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                paperWidth == "100%"
                    ? // TODO: MATRIX-7555: lint errors should be fixed for next line
                      // eslint-disable-next-line
                      (<any>iframe[0]).contentWindow.document.body.scrollWidth - 10
                    : iframe.width() + 50,
            );
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            iframe.height((<any>iframe[0]).contentWindow.document.body.scrollHeight + 50);
            window.setTimeout(() => {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                iframe.height((<any>iframe[0]).contentWindow.document.body.scrollHeight + 50);
            }, 1);
        });
    }
}
