import { FieldDescriptions } from "../FieldDescriptions";
import { IDropDownConfig, IDropdownOption } from "../../../ProjectSettings";
import { IFieldHandler } from "./IFieldHandler";
import { ItemConfiguration, XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { LT } from "../../matrixlib/ui/LT";

export interface IBaseDropdownFieldParams {
    splitHuman?: boolean;
    maxItems?: number;
    create?: boolean; // true if values can be added
    options?: IDropdownOption[]; // list of options to choose from
    optionSetting?: string; // can be name of project setting with options and groups
    initialContent?: string; // allows to set the initial content
}
export class DropdownFieldHandler implements IFieldHandler {
    private rawData: string | undefined;
    private human = "";
    private lt = new LT();

    protected params: IBaseDropdownFieldParams;

    constructor(params: IBaseDropdownFieldParams, initialValue?: string) {
        this.params = params;
        this.rawData = undefined;
        this.initData(initialValue);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static UpdateFieldConfig(options: XRFieldTypeAnnotatedParamJson, itemConfig: ItemConfiguration) {
        if (options.optionSetting) {
            let config = <IDropDownConfig>itemConfig.getSettingJSON(options.optionSetting);
            if (config) {
                options.options = config.options;
                options.groups = config.groups;
            }
        }
    }
    getData(): string | undefined {
        return this.rawData;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string) {
        this.initData(value);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_dropdown;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        if (this.params?.splitHuman) {
            let split = serializedFieldData?.split("|") || "";
            this.rawData = split[0];
            this.human = split[split.length - 1];
            return;
        }

        this.rawData = serializedFieldData === undefined ? this.params?.initialContent || "" : serializedFieldData;
        const dataArray = this.rawData.split(",");
        this.human = (this.params?.options || [])
            .filter((option) => dataArray.includes(option.id))
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .map((option) => this.lt.forUI(option.label, null))
            .join(",");
    }

    /**
     * Retrieve the current value of the field, formatted as an array.
     * If there are N current values, the array will have N items.
     * @param filterOnOptions true if you only want to get back values that show up
     *     in the options list.
     * @returns an array. Empty if there is no current value.
     */
    getValues(filterOnOptions = true): string[] {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.rawData != undefined || this.rawData != null) {
            let values = this.rawData.split(",");
            if (filterOnOptions && this.params && !this.params.create) {
                return values.filter((value) => {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    return this.params.options.some((option) => {
                        return option.id === value;
                    });
                });
            } else {
                // When creating new values, we don't want to return empty value like this : [""] but []
                if (this.params && this.params.create && this.rawData === "") {
                    return [];
                } else {
                    return values;
                }
            }
        }

        return [];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHuman() {
        return this.human;
    }
}
