/// <reference types="matrixrequirements-type-declarations" />

import { ItemConfiguration } from "./../businesslogic/index";
import { IJcxhr, Tasks } from "../businesslogic/index";
import { ItemControl } from "../UI/Components/index";
import { IDropdownParams } from "../UI/Controls/dropdown";
import { IRichTextParams } from "../UI/Controls/richText";
import { ml } from "./../matrixlib";
import { IDropdownGroup, IDropdownOption } from "../../ProjectSettings";
import {
    XRGetGroup_AllGroups_GetGroupListAck,
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRGetUser_AllUsers_GetUserListAck,
    XRGroupPermissionType,
    XRGroupType,
    XRUserPermissionType,
} from "../../RestResult";
import {
    IBlockingProgressUI,
    IBlockingProgressUITask,
    ICIColor,
    IDateTimeUI,
    IDialogOptions,
    IDropDownButtonOption,
    ILT,
    IProgressUI,
    ISelectUserOrGroupUI,
    IToolTipCache,
    IUIToolsEnum,
    UIToolsConstants,
} from "./MatrixLibInterfaces";
import {
    app,
    ControlState,
    globalMatrix,
    IGenericMap,
    IItem,
    IStringNumberMap,
    matrixApplicationUI,
    restConnection,
    setIC,
} from "../../globals";
import { DateTimeUI } from "./DateTimeUI";
import { LT } from "./ui/LT";
import { CheckBoxImpl } from "../UI/Controls/checkBox";

export class UIToolsEnum implements IUIToolsEnum {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fixC3ForCopy(copied: JQuery) {
        $(".c3-legend-background", copied).remove();
        $("svg path", copied).css("cursor", "unset");
        $("svg text", copied).css("opacity", "1");
        $(".c3-legend-item ", copied).css("cursor", "unset");
        $(".c3-axis path.domain", copied).attr("stroke", "black");
        $(".c3-axis .tick line", copied).attr("stroke", "black");
        $(".c3-axis path.domain", copied).attr("fill", "none");
    }

    private tooltip_cache: { [key: string]: IToolTipCache } = {};
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastTooltipRequest: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastTooltipHide: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private hidden_tooltip_itemId: string; // not yet used
    private removeTimer: IStringNumberMap = {};

    // date time formatting in UI
    DateTime: IDateTimeUI;

    // full screen (modal) progress
    BlockingProgress: IBlockingProgressUI;

    SelectUserOrGroup: ISelectUserOrGroupUI;

    lt: ILT;

    // bottom progress bar
    Progress: IProgressUI;

    constructor() {
        this.DateTime = new DateTimeUI();
        this.BlockingProgress = new BlockingProgressUI();
        this.SelectUserOrGroup = new SelectUserOrGroupUI();
        this.lt = new LT();
        this.Progress = new ProgressUI();
    }

    // helper to show a drop down / drop up button allowing to click on one of several options
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createDropDownButton(
        defaultText: string,
        options: IDropDownButtonOption[],
        isUp: boolean,
        buttonId?: string,
        disableDefaultButtonClick?: boolean,
    ): JQuery {
        let id = buttonId ? 'id="' + buttonId + '"' : "";

        if (options.length === 1) {
            const bootLook = "btn btn-default";
            // render as simple button
            const button = $("<span " + id + ' type="button" class="' + bootLook + '">');
            const btnText = $('<span class="ui-button-text">');
            btnText.append($("<span>").html(defaultText));

            button.append(btnText).click(function (event) {
                if (!button.prop("disabled")) {
                    // do the click and disable button for a while
                    options[0].click();
                    button.prop("disabled", true);
                    button.addClass("disabled");
                    setTimeout(function () {
                        button.prop("disabled", false);
                        button.removeClass("disabled");
                    }, 1000);
                }
                if (event.preventDefault) {
                    event.preventDefault();
                }
                return false;
            });
            return $('<div class="btn-group">').append(button);
        }

        const bootLook = "btn btn-default dropdown-toggle";

        const button = $('<span type="button" class="' + bootLook + '" data-toggle="dropdown" aria-expanded="false">');

        const btnText = $('<span class="ui-button-text">');
        button.append(btnText);

        let ddcaret = $('<span class="caret ddmenubtndrop">');
        btnText.append(
            $("<span " + id + " >")
                .html(defaultText)
                .click(function (event) {
                    if (disableDefaultButtonClick) {
                        ddcaret.trigger("click");
                    } else if (!button.prop("disabled")) {
                        // do the click and disable button for a while
                        options[0].click();
                        button.prop("disabled", true);
                        button.addClass("disabled");
                        setTimeout(function () {
                            button.prop("disabled", false);
                            button.removeClass("disabled");
                        }, 1000);
                    }
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                    return false;
                }),
        );
        btnText.append(ddcaret);

        let ddOptions = $('<ul class="dropdown-menu" role="menu">');

        $.each(options, function (idx, opt) {
            ddOptions.append(
                $("<li>")
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .append($("<a>").attr("href", "javascript:void(0)").addClass(opt.class).html(opt.name))
                    .click(function (event) {
                        opt.click();
                        button.closest(".dropdown").removeClass("open");
                        if (event.preventDefault) {
                            event.preventDefault();
                        }
                        return false;
                    }),
            );
        });

        return $('<div class="btn-group ' + (isUp ? "dropup" : "dropdown") + '">')
            .append(button)
            .append(ddOptions);
    }

    // get optimal size of a dialog window dependent on available screen
    getNiceDialogSize(minWidth: number, minHeight: number): { width: number; height: number } {
        return {
            width: minWidth < 0 ? Math.abs(minWidth) : Math.max(minWidth, app.itemForm.width() / 2),
            height: minHeight < 0 ? Math.abs(minHeight) : Math.max(minHeight, app.itemForm.height() / 4),
        };
    }

    // show success message
    // hideAfter =0 or undefined: 2000 ms, -1 do not hide
    showSuccess(messageTitle: string, hideAfter?: number): void {
        ml.Logger.log("success", messageTitle);
        $("#message_success").html("<span class='msgHeader'>" + messageTitle + "</span>");
        this.showSlide("#message_success", hideAfter ? (hideAfter < 0 ? 0 : hideAfter) : 2000);
    }

    hideSuccess(): void {
        this.hideSlide($("#message_success"));
    }
    hideError(): void {
        this.hideSlide($("#message_error"));
    }
    // show error message
    showError(messageTitle: string, messageBody: string, showForMS?: number): void {
        ml.Logger.log("error", messageTitle + ": " + messageBody);
        $("#message_error").html(
            "<span class='msgHeader'>" +
                messageTitle +
                "</span>" +
                "<hr/>" +
                "<p class='msgBody'>" +
                messageBody +
                "</p>",
        );
        let duration = 5000;
        if (showForMS) {
            duration = showForMS;
        }
        this.showSlide("#message_error", duration);
    }

    // show acknowledge message

    /**
     * show acknowledge dialog
     * @param ackId: a (unique) value > 0 can be used as unique id to have acknowledge boxes which are shown only one
     * @param messageTitle
     * @param dlgTitle
     */
    showAck(ackId: number, messageTitle: string, dlgTitle?: string): void {
        if (globalMatrix.serverStorage.getItem("ackMessage_" + ackId)) {
            // user clicked on ack -> on this computer
            // or it was manually set
            return;
        }

        ml.Logger.log("ack", messageTitle);
        $("#message_ack_title").html(dlgTitle ? dlgTitle : "Acknowledge");

        $("#message_ack_ok").off("click");
        $("#message_ack_ok").one("click", function () {
            if (ackId > 0) {
                // allow to click away
                globalMatrix.serverStorage.setItem("ackMessage_" + ackId, new Date().toString());
            }
        });

        $("#message_ack_content").html(messageTitle);
        $("#message_ack").modal({ backdrop: "static" });
    }

    // ask for confirmation
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showConfirm(
        confId: number,
        messageInfo: { title: string; ok: string; nok?: string; third?: string },
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        confFunction: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        noConfFunction: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        thirdFunction?: Function,
    ): void {
        let that = this;

        if (globalMatrix.serverStorage.getItem("confMessage_" + confId)) {
            // user clicked on conf -> on this computer
            // or it was manually set
            return;
        }

        ml.Logger.log("conf", messageInfo.title);

        $("#message_conf_ok").off("click");
        $("#message_conf_nok").off("click");
        $("#message_conf_third").off("click").hide();
        $("#message_conf").off("hidden.bs.modal");

        $("#message_conf_ok").one("click", function () {
            if (confFunction) {
                $("#message_conf").one("hidden.bs.modal", () => {
                    confFunction();
                });
            }
        });
        $("#message_conf_nok").one("click", function () {
            if (noConfFunction) {
                $("#message_conf").one("hidden.bs.modal", () => {
                    noConfFunction();
                });
            }
        });
        $("#message_conf_third").one("click", function () {
            if (thirdFunction) {
                $("#message_conf").one("hidden.bs.modal", () => {
                    thirdFunction();
                });
            }
        });
        $("#message_conf_content").html(messageInfo.title);
        $("#message_conf_ok").html(messageInfo.ok ? messageInfo.ok : "OK");
        $("#message_conf_nok").html(messageInfo.nok ? messageInfo.nok : "Cancel");
        if (messageInfo.third) {
            $("#message_conf_third").html(messageInfo.third);
            $("#message_conf_third").show();
        }
        $("#message_conf").modal({ backdrop: "static" });
        $("#message_conf_content").closest(".modal").css("z-index", "20000");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    confirmSpinningWait(message: string) {
        $("#message_conf_content").html("").append(this.getSpinningWait(message));
        this.setEnabled($("#message_conf_ok"), false);
        this.setEnabled($("#message_conf_nok"), false);
        $("#message_conf").modal({ backdrop: "static" });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    closeConfirmSpinningWait() {
        this.setEnabled($("#message_conf_ok"), true);
        this.setEnabled($("#message_conf_nok"), true);
        $("#message_conf").modal("hide");
    }
    // show item as tool tip
    showTooltip(itemId: string, target: JQuery, event: Event, crossProject?: string): void {
        let that = this;
        clearTimeout(this.lastTooltipHide);
        clearTimeout(this.lastTooltipRequest);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.lastTooltipHide = null;
        this.lastTooltipRequest = window.setTimeout(
            function () {
                that.showTooltip_Delayed(itemId, target, crossProject);
            },
            globalMatrix.globalShiftDown ? 10 : 500,
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showTaskAsTooltip(id: string, title: string, url: string, htmlContent: string, target: JQuery): void {
        let that = this;
        clearTimeout(this.lastTooltipHide);
        clearTimeout(this.lastTooltipRequest);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.lastTooltipHide = null;
        this.lastTooltipRequest = window.setTimeout(
            function () {
                that.showTaskAsTooltip_Delayed(id, title, url, htmlContent, target);
            },
            globalMatrix.globalShiftDown ? 10 : 500,
        );
    }

    // hide item tool tip
    hideTooltip(now?: boolean): void {
        let that = this;
        clearTimeout(this.lastTooltipHide);
        clearTimeout(this.lastTooltipRequest);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.lastTooltipHide = null;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.lastTooltipRequest = null;
        if (now) {
            let height = Math.max(300, this.getSlideHeight($("#tooltip_panel"), true));
            $("#tooltip_panel").css("margin-top", "-" + height + "px");
        } else {
            this.lastTooltipHide = window.setTimeout(function () {
                that.hideCurrentToolTip();
            }, 2000);
        }
    }

    // refresh tool tip (does not yet work...)
    updateTooltip(): void {
        if (this.hidden_tooltip_itemId) {
            this.showTooltip_Delayed(this.hidden_tooltip_itemId, $("#tooltip_panel"));
        }
    }

    // show message that user name or password has spaces
    spaceMessage(userHasSpaces: boolean, passwordHasSpaces: boolean): string {
        if (userHasSpaces && passwordHasSpaces) {
            return "user name and password cannot have spaces";
        } else if (userHasSpaces) {
            return "user name cannot have spaces";
        } else if (passwordHasSpaces) {
            return "password cannot have spaces";
        } else {
            return "";
        }
    }

    // return span with spinning wait icon and text
    getSpinningWait(message?: string): JQuery {
        let span = $("<span class='spinningWait hideCopy'>");
        span.append($('<span class="fal fa-sync-alt refresh-animate"></span>"')).append(
            $('<span class="waitscreenmessage">' + (message ? message : "please wait....") + '</span>"'),
        );
        return span;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setEnabled(button: JQuery, enabled: boolean) {
        if (enabled) {
            button.prop("disabled", false).removeClass("ui-state-disabled");
        } else {
            button.prop("disabled", true).addClass("ui-state-disabled");
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getDisplayError(jqxhr: IJcxhr, textStatus: string, error: string) {
        if (jqxhr && jqxhr.responseJSON && jqxhr.responseJSON.displayError) {
            return jqxhr.responseJSON.displayError;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (textStatus == "parsererror") {
            return "unknown error";
        }
        return "Status: " + textStatus + "<br/>Error was:" + error;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showDialog(
        dlg: JQuery,
        title: string,
        content: JQuery,
        minMaxWidth: number,
        minMaxHeight: number,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        buttons: any[], // DialogButtonOptions
        scrolling: UIToolsConstants.Scroll,
        autoResize?: boolean,
        maximizeButton?: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        close?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        open?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        resize?: Function,
        noCloseOnEscape?: boolean,
    ) {
        let that = this;
        let isMax = false;

        // get best size (depending on available screen size, and given max values)
        let niceSize = this.getNiceDialogSize(minMaxWidth, minMaxHeight);
        let nicePos = { top: 0, left: 0 };

        // content
        if (content) {
            dlg.html("");
            dlg.append(content);
        }

        dlg.removeClass("dlg-auto-scroll");
        dlg.removeClass("dlg-no-scroll");
        dlg.removeClass("dlg-v-scroll");

        switch (scrolling) {
            case UIToolsConstants.Scroll.Vertical:
                dlg.addClass("dlg-v-scroll");
                break;
            case UIToolsConstants.Scroll.None:
                dlg.addClass("dlg-no-scroll");
                break;
            case UIToolsConstants.Scroll.Auto:
                dlg.addClass("dlg-scroll");
                break;
        }

        dlg.dialog({
            autoOpen: true,
            title: title,
            height: niceSize.height,
            width: niceSize.width,
            modal: true,
            closeOnEscape: !noCloseOnEscape,
            close: function () {
                // prepare for next opening...
                $(".maxbutton").remove();
                dlg.removeClass("dlg-auto-scroll");
                dlg.removeClass("dlg-no-scroll");
                dlg.removeClass("dlg-v-scroll");

                that.popDialog(dlg);
                if (close) {
                    close();
                }
            },
            open: function () {
                if (maximizeButton) {
                    let maxButton = $(
                        '<button title="Maximize" class="maxbutton ui-button ui-corner-all ui-widget ui-button-icon-only ui-dialog-titlebar-max" type="button"><span class="ui-icon ui-icon-arrow-4-diag">maximize</span>Maximize</button>',
                    );
                    let ui = $(dlg).closest(".ui-dialog");
                    let close = $(".ui-dialog-titlebar-close", ui);
                    let content = $(".ui-dialog-content", ui);
                    close.parent().prepend(maxButton);
                    maxButton.click(function () {
                        if (isMax) {
                            isMax = false;

                            ui.width(niceSize.width);
                            ui.height(niceSize.height);
                            ui.css("left", nicePos.left + "px");
                            ui.css("top", nicePos.top + "px");
                        } else {
                            isMax = true;

                            nicePos = ui.position();
                            niceSize = { width: ui.width(), height: ui.height() };

                            ui.width($(window).width() - 20);
                            ui.height($(window).height() - 20);
                            ui.css("left", "10px");
                            ui.css("top", "10px");
                        }
                        $(content).width(ui.width() - 28);
                        $(content).height(ui.height() - 112);

                        if (autoResize) {
                            dlg.resizeDlgContent([content]);
                        }
                        if (resize) {
                            resize();
                        }
                    });
                }
                if (open) {
                    open();
                }
                that.pushDialog(dlg);
            },
            resizeStop: function (event, ui) {
                if (autoResize) {
                    dlg.resizeDlgContent([content]);
                }
                if (resize) {
                    resize();
                }
            },
            buttons: buttons,
        });
        if (autoResize) {
            dlg.resizeDlgContent([content], false);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showDialogDes({
        // optional parameters with defaults
        maximizeButton = false,
        noXButton = false,
        autoResize = false,
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        onClose = null,
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        onOpen = null,
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        onResize = null,
        noCloseOnEscape = false,
        minMaxWidth = $(document).width() * 0.9,
        minMaxHeight = app.itemForm.height() * 0.9,
        scrolling = UIToolsConstants.Scroll.None,
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        content = null,
        // required parameters
        container,
        title,
        buttons,
    }: IDialogOptions) {
        let that = this;
        let isMax = false;

        // get best size (depending on available screen size, and given max values)
        let niceSize = this.getNiceDialogSize(minMaxWidth, minMaxHeight);
        let nicePos = { top: 0, left: 0 };

        // content
        if (content) {
            container.html("");
            container.append(content);
        }

        container.removeClass("dlg-auto-scroll");
        container.removeClass("dlg-no-scroll");
        container.removeClass("dlg-v-scroll");

        switch (scrolling) {
            case UIToolsConstants.Scroll.Vertical:
                container.addClass("dlg-v-scroll");
                break;
            case UIToolsConstants.Scroll.None:
                container.addClass("dlg-no-scroll");
                break;
            case UIToolsConstants.Scroll.Auto:
                container.addClass("dlg-scroll");
                break;
        }

        container.dialog({
            autoOpen: true,
            title: title,
            height: niceSize.height,
            width: niceSize.width,
            modal: true,
            closeOnEscape: !noCloseOnEscape,
            close: function () {
                // prepare for next opening...
                $(".maxbutton").remove();
                container.removeClass("dlg-auto-scroll");
                container.removeClass("dlg-no-scroll");
                container.removeClass("dlg-v-scroll");

                that.popDialog(container);
                if (onClose) {
                    onClose();
                }
            },
            open: function () {
                let ui = $(container).closest(".ui-dialog");
                let close = $(".ui-dialog-titlebar-close", ui);

                if (maximizeButton) {
                    let maxCss = noXButton ? "maxNoX" : "";
                    let maxButton = $(
                        '<button title="Maximize" class="' +
                            maxCss +
                            ' maxbutton ui-button ui-corner-all ui-widget ui-button-icon-only ui-dialog-titlebar-max" type="button"><span class="ui-icon ui-icon-arrow-4-diag">maximize</span>Maximize</button>',
                    );

                    let content = $(".ui-dialog-content", ui);
                    close.parent().prepend(maxButton);
                    maxButton.click(function () {
                        if (isMax) {
                            isMax = false;

                            ui.width(niceSize.width);
                            ui.height(niceSize.height);
                            ui.css("left", nicePos.left + "px");
                            ui.css("top", nicePos.top + "px");
                        } else {
                            isMax = true;

                            nicePos = ui.position();
                            niceSize = { width: ui.width(), height: ui.height() };

                            ui.width($(window).width() - 20);
                            ui.height($(window).height() - 20);
                            ui.css("left", "10px");
                            ui.css("top", "10px");
                        }
                        $(content).width(ui.width() - 28);
                        $(content).height(ui.height() - 112);

                        if (autoResize) {
                            container.resizeDlgContent([content]);
                        }
                        if (onResize) {
                            onResize();
                        }
                    });
                }

                if (noXButton) {
                    close.remove();
                }

                if (onOpen) {
                    onOpen();
                }

                that.pushDialog(container);
            },
            resizeStop: function (event, ui) {
                if (autoResize) {
                    container.resizeDlgContent([content]);
                }
                if (onResize) {
                    onResize();
                }
            },
            buttons: buttons,
        });
        if (autoResize) {
            container.resizeDlgContent([content], false);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    pushDialog(thisDialog: JQuery) {
        let depth = 0;
        for (let idx = 0; idx < 4; idx++) {
            if (this.countVisibleDialogs(idx)) {
                depth = idx + 1;
            }
        }
        if (depth === 0) {
            return;
        }
        let dlg = thisDialog.closest(".ui-dialog");
        dlg.removeClass("ui-front");
        dlg.addClass("ui-front" + depth);
        $(".ui-widget-overlay,.ui-widget-overlay1,.ui-widget-overlay2,.ui-widget-overlay3,.ui-widget-overlay4").each(
            function (idx, over) {
                $(over).removeClass("ui-widget-overlay");
                for (let idx = 1; idx < depth; idx++) {
                    $(over).removeClass("ui-widget-overlay" + idx);
                }
                $(over).addClass("ui-widget-overlay" + depth);
            },
        );
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    popDialog(thisDialog: JQuery) {
        let dlg = thisDialog.closest(".ui-dialog");
        // normalize stack for next time it's displayed
        dlg.addClass("ui-front");
        for (let idx = 0; idx < 4; idx++) {
            dlg.removeClass("ui-front" + (idx + 1));
        }

        $(".ui-widget-overlay1").each(function (idx, over) {
            $(over).removeClass("ui-widget-overlay1");
            $(over).addClass("ui-widget-overlay");
        });
        $(".ui-widget-overlay2").each(function (idx, over) {
            $(over).removeClass("ui-widget-overlay2");
            $(over).addClass("ui-widget-overlay1");
        });
        $(".ui-widget-overlay3").each(function (idx, over) {
            $(over).removeClass("ui-widget-overlay3");
            $(over).addClass("ui-widget-overlay2");
        });
        $(".ui-widget-overlay4").each(function (idx, over) {
            $(over).removeClass("ui-widget-overlay4");
            $(over).addClass("ui-widget-overlay3");
        });
    }

    public serverHtmlCleanupBlob(content: JQuery): JQueryDeferred<string> {
        let htmlContent = content.html();
        let def = $.Deferred<string>();

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        restConnection.postProjectJson("htmlCleanupBlob", { htmlToClean: htmlContent }).done((result: any) => {
            def.resolve(result.cleanedHtml);
        });

        return def;
    }

    /*  anchor: where to add a button
        tooltip: to inform user about what is copied
        content: from where to copy
        catchKey: ??
        onProcessCopy: for post processing of copied text
        btnText: // to add some text to copy button
    */

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    copyBuffer(
        anchor: JQuery,
        tooltip: string,
        content: JQuery,
        catchKey?: JQuery,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onProcessCopy?: Function,
        btnText?: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        beforeCopy?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        afterCopy?: Function,
    ) {
        let that = this;
        $(
            '<i class="fal fa-copy hideCopy" aria-hidden="true" style="padding:0 5px 0 12px;cursor:pointer" data-original-title="' +
                tooltip +
                '" ></i><span>' +
                (btnText ? btnText : "") +
                "</span> ",
        )
            .appendTo(anchor)
            .click(function () {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (beforeCopy != undefined) {
                    beforeCopy();
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.doCopy(content, onProcessCopy);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (afterCopy != undefined) {
                    afterCopy();
                }
            })
            .tooltip({ container: "body", placement: "auto" });

        /*
        if (catchKey) {
            let ctrlDown = false,
            ctrlKey = 17,
            cmdKey = 91,
            vKey = 86,
            cKey = 67;

            $(catchKey).keydown(function(e) {
                if (e.keyCode == ctrlKey || e.keyCode == cmdKey) {
                    ctrlDown = true;
                }
                if (ctrlDown &&  e.keyCode == cKey) {
                    that.doCopy( content, onProcessCopy );
                    return false;
                }
                return true;
            }).keyup(function(e) {
                if (e.keyCode == ctrlKey || e.keyCode == cmdKey) {
                    ctrlDown = false;
                }
            });
        }*/
    }

    toolIcons = [
        "ad",
        "address-book",
        "address-card",
        "adjust",
        "air-freshener",
        "align-center",
        "align-justify",
        "align-left",
        "align-right",
        "allergies",
        "ambulance",
        "american-sign-language-interpreting",
        "anchor",
        "angle-double-down",
        "angle-double-left",
        "angle-double-right",
        "angle-double-up",
        "angle-down",
        "angle-left",
        "angle-right",
        "angle-up",
        "angry",
        "ankh",
        "apple-alt",
        "archive",
        "archway",
        "arrow-alt-circle-down",
        "arrow-alt-circle-left",
        "arrow-alt-circle-right",
        "arrow-alt-circle-up",
        "arrow-circle-down",
        "arrow-circle-left",
        "arrow-circle-right",
        "arrow-circle-up",
        "arrow-down",
        "arrow-left",
        "arrow-right",
        "arrow-up",
        "arrows-alt",
        "arrows-alt-h",
        "arrows-alt-v",
        "assistive-listening-systems",
        "asterisk",
        "at",
        "atlas",
        "atom",
        "audio-description",
        "award",
        "baby",
        "baby-carriage",
        "backspace",
        "backward",
        "bacon",
        "balance-scale",
        "balance-scale-left",
        "balance-scale-right",
        "ban",
        "band-aid",
        "barcode",
        "bars",
        "baseball-ball",
        "basketball-ball",
        "bath",
        "battery-empty",
        "battery-full",
        "battery-half",
        "battery-quarter",
        "battery-three-quarters",
        "bed",
        "beer",
        "bell",
        "bell-slash",
        "bezier-curve",
        "bible",
        "bicycle",
        "biking",
        "binoculars",
        "biohazard",
        "birthday-cake",
        "blender",
        "blender-phone",
        "blind",
        "blog",
        "bold",
        "bolt",
        "bomb",
        "bone",
        "bong",
        "book",
        "book-dead",
        "book-medical",
        "book-open",
        "book-reader",
        "bookmark",
        "border-all",
        "border-none",
        "border-style",
        "bowling-ball",
        "box",
        "box-open",
        "boxes",
        "braille",
        "brain",
        "bread-slice",
        "briefcase",
        "briefcase-medical",
        "broadcast-tower",
        "broom",
        "brush",
        "bug",
        "building",
        "bullhorn",
        "bullseye",
        "burn",
        "bus",
        "bus-alt",
        "business-time",
        "calculator",
        "calendar",
        "calendar-alt",
        "calendar-check",
        "calendar-day",
        "calendar-minus",
        "calendar-plus",
        "calendar-times",
        "calendar-week",
        "camera",
        "camera-retro",
        "campground",
        "candy-cane",
        "cannabis",
        "capsules",
        "car",
        "car-alt",
        "car-battery",
        "car-crash",
        "car-side",
        "caret-down",
        "caret-left",
        "caret-right",
        "caret-square-down",
        "caret-square-left",
        "caret-square-right",
        "caret-square-up",
        "caret-up",
        "carrot",
        "cart-arrow-down",
        "cart-plus",
        "cash-register",
        "cat",
        "certificate",
        "chair",
        "chalkboard",
        "chalkboard-teacher",
        "charging-station",
        "chart-area",
        "chart-bar",
        "chart-line",
        "chart-pie",
        "check",
        "check-circle",
        "check-double",
        "check-square",
        "cheese",
        "chess",
        "chess-bishop",
        "chess-board",
        "chess-king",
        "chess-knight",
        "chess-pawn",
        "chess-queen",
        "chess-rook",
        "chevron-circle-down",
        "chevron-circle-left",
        "chevron-circle-right",
        "chevron-circle-up",
        "chevron-down",
        "chevron-left",
        "chevron-right",
        "chevron-up",
        "child",
        "church",
        "circle",
        "circle-notch",
        "city",
        "clinic-medical",
        "clipboard",
        "clipboard-check",
        "clipboard-list",
        "clock",
        "clone",
        "closed-captioning",
        "cloud",
        "cloud-download-alt",
        "cloud-meatball",
        "cloud-moon",
        "cloud-moon-rain",
        "cloud-rain",
        "cloud-showers-heavy",
        "cloud-sun",
        "cloud-sun-rain",
        "cloud-upload-alt",
        "cocktail",
        "code",
        "code-branch",
        "coffee",
        "cog",
        "cogs",
        "coins",
        "columns",
        "comment",
        "comment-alt",
        "comment-dollar",
        "comment-dots",
        "comment-medical",
        "comment-slash",
        "comments",
        "comments-dollar",
        "compact-disc",
        "compass",
        "compress",
        "compress-arrows-alt",
        "concierge-bell",
        "cookie",
        "cookie-bite",
        "copy",
        "copyright",
        "couch",
        "credit-card",
        "crop",
        "crop-alt",
        "cross",
        "crosshairs",
        "crow",
        "crown",
        "crutch",
        "cube",
        "cubes",
        "cut",
        "database",
        "deaf",
        "democrat",
        "desktop",
        "dharmachakra",
        "diagnoses",
        "dice",
        "dice-d20",
        "dice-d6",
        "dice-five",
        "dice-four",
        "dice-one",
        "dice-six",
        "dice-three",
        "dice-two",
        "digital-tachograph",
        "directions",
        "divide",
        "dizzy",
        "dna",
        "dog",
        "dollar-sign",
        "dolly",
        "dolly-flatbed",
        "donate",
        "door-closed",
        "door-open",
        "dot-circle",
        "dove",
        "download",
        "drafting-compass",
        "dragon",
        "draw-polygon",
        "drum",
        "drum-steelpan",
        "drumstick-bite",
        "dumbbell",
        "dumpster",
        "dumpster-fire",
        "dungeon",
        "edit",
        "egg",
        "eject",
        "ellipsis-h",
        "ellipsis-v",
        "envelope",
        "envelope-open",
        "envelope-open-text",
        "envelope-square",
        "equals",
        "eraser",
        "ethernet",
        "euro-sign",
        "exchange-alt",
        "exclamation",
        "exclamation-circle",
        "exclamation-triangle",
        "expand",
        "expand-arrows-alt",
        "external-link-alt",
        "external-link-square-alt",
        "eye",
        "eye-dropper",
        "eye-slash",
        "fan",
        "fast-backward",
        "fast-forward",
        "fax",
        "feather",
        "feather-alt",
        "female",
        "fighter-jet",
        "file",
        "file-alt",
        "file-archive",
        "file-audio",
        "file-code",
        "file-contract",
        "file-csv",
        "file-download",
        "file-excel",
        "file-",
        "file-image",
        "file-import",
        "file-invoice",
        "file-invoice-dollar",
        "file-medical",
        "file-medical-alt",
        "file-pdf",
        "file-powerpoint",
        "file-prescription",
        "file-signature",
        "file-upload",
        "file-video",
        "file-word",
        "fill",
        "fill-drip",
        "film",
        "filter",
        "fingerprint",
        "fire",
        "fire-alt",
        "fire-extinguisher",
        "first-aid",
        "fish",
        "fist-raised",
        "flag",
        "flag-checkered",
        "flag-usa",
        "flask",
        "flushed",
        "folder",
        "folder-minus",
        "folder-open",
        "folder-plus",
        "font",
        "football-ball",
        "forward",
        "frog",
        "frown",
        "frown-open",
        "funnel-dollar",
        "futbol",
        "gamepad",
        "gas-pump",
        "gavel",
        "gem",
        "genderless",
        "ghost",
        "gift",
        "gifts",
        "glass-cheers",
        "glass-martini",
        "glass-martini-alt",
        "glass-whiskey",
        "glasses",
        "globe",
        "globe-africa",
        "globe-americas",
        "globe-asia",
        "globe-europe",
        "golf-ball",
        "gopuram",
        "graduation-cap",
        "greater-than",
        "greater-than-equal",
        "grimace",
        "grin",
        "grin-alt",
        "grin-beam",
        "grin-beam-sweat",
        "grin-hearts",
        "grin-squint",
        "grin-squint-tears",
        "grin-stars",
        "grin-tears",
        "grin-tongue",
        "grin-tongue-squint",
        "grin-tongue-wink",
        "grin-wink",
        "grip-horizontal",
        "grip-lines",
        "grip-lines-vertical",
        "grip-vertical",
        "guitar",
        "h-square",
        "hamburger",
        "hammer",
        "hamsa",
        "hand-holding",
        "hand-holding-heart",
        "hand-holding-usd",
        "hand-lizard",
        "hand-middle-finger",
        "hand-paper",
        "hand-peace",
        "hand-point-down",
        "hand-point-left",
        "hand-point-right",
        "hand-point-up",
        "hand-pointer",
        "hand-rock",
        "hand-scissors",
        "hand-spock",
        "hands",
        "hands-helping",
        "handshake",
        "hanukiah",
        "hard-hat",
        "hashtag",
        "hat-cowboy",
        "hat-cowboy-side",
        "hat-wizard",
        "haykal",
        "hdd",
        "heading",
        "headphones",
        "headphones-alt",
        "headset",
        "heart",
        "heart-broken",
        "heartbeat",
        "helicopter",
        "highlighter",
        "hiking",
        "hippo",
        "history",
        "hockey-puck",
        "holly-berry",
        "home",
        "horse",
        "horse-head",
        "hospital",
        "hospital-alt",
        "hospital-symbol",
        "hot-tub",
        "hotdog",
        "hotel",
        "hourglass",
        "hourglass-end",
        "hourglass-half",
        "hourglass-start",
        "house-damage",
        "hryvnia",
        "i-cursor",
        "ice-cream",
        "icicles",
        "icons",
        "id-badge",
        "id-card",
        "id-card-alt",
        "igloo",
        "image",
        "images",
        "inbox",
        "indent",
        "industry",
        "infinity",
        "info",
        "info-circle",
        "italic",
        "jedi",
        "joint",
        "journal-whills",
        "kaaba",
        "key",
        "keyboard",
        "khanda",
        "kiss",
        "kiss-beam",
        "kiss-wink-heart",
        "kiwi-bird",
        "landmark",
        "language",
        "laptop",
        "laptop-code",
        "laptop-medical",
        "laugh",
        "laugh-beam",
        "laugh-squint",
        "laugh-wink",
        "layer-group",
        "leaf",
        "lemon",
        "less-than",
        "less-than-equal",
        "level-down-alt",
        "level-up-alt",
        "life-ring",
        "lightbulb",
        "link",
        "lira-sign",
        "list",
        "list-alt",
        "list-ol",
        "list-ul",
        "location-arrow",
        "lock",
        "lock-open",
        "long-arrow-alt-down",
        "long-arrow-alt-left",
        "long-arrow-alt-right",
        "long-arrow-alt-up",
        "low-vision",
        "luggage-cart",
        "magic",
        "magnet",
        "mail-bulk",
        "male",
        "map",
        "map-marked",
        "map-marked-alt",
        "map-marker",
        "map-marker-alt",
        "map-pin",
        "map-signs",
        "marker",
        "mars",
        "mars-double",
        "mars-stroke",
        "mars-stroke-h",
        "mars-stroke-v",
        "mask",
        "medal",
        "medkit",
        "meh",
        "meh-blank",
        "meh-rolling-eyes",
        "memory",
        "menorah",
        "mercury",
        "meteor",
        "microchip",
        "microphone",
        "microphone-alt",
        "microphone-alt-slash",
        "microphone-slash",
        "microscope",
        "minus",
        "minus-circle",
        "minus-square",
        "mitten",
        "mobile",
        "mobile-alt",
        "money-bill",
        "money-bill-alt",
        "money-bill-wave",
        "money-bill-wave-alt",
        "money-check",
        "money-check-alt",
        "monument",
        "moon",
        "mortar-pestle",
        "mosque",
        "motorcycle",
        "mountain",
        "mouse",
        "mouse-pointer",
        "mug-hot",
        "music",
        "network-wired",
        "neuter",
        "newspaper",
        "not-equal",
        "notes-medical",
        "object-group",
        "object-ungroup",
        "oil-can",
        "om",
        "otter",
        "outdent",
        "pager",
        "paint-brush",
        "paint-roller",
        "palette",
        "pallet",
        "paper-plane",
        "paperclip",
        "parachute-box",
        "paragraph",
        "parking",
        "passport",
        "pastafarianism",
        "paste",
        "pause",
        "pause-circle",
        "paw",
        "peace",
        "pen",
        "pen-alt",
        "pen-fancy",
        "pen-nib",
        "pen-square",
        "pencil-alt",
        "pencil-ruler",
        "people-carry",
        "pepper-hot",
        "percent",
        "percentage",
        "person-booth",
        "phone",
        "phone-alt",
        "phone-slash",
        "phone-square",
        "phone-square-alt",
        "phone-volume",
        "photo-video",
        "piggy-bank",
        "pills",
        "pizza-slice",
        "place-of-worship",
        "plane",
        "plane-arrival",
        "plane-departure",
        "play",
        "play-circle",
        "plug",
        "plus",
        "plus-circle",
        "plus-square",
        "podcast",
        "poll",
        "poll-h",
        "poo",
        "poo-storm",
        "poop",
        "portrait",
        "pound-sign",
        "power-off",
        "pray",
        "praying-hands",
        "prescription",
        "prescription-bottle",
        "prescription-bottle-alt",
        "print",
        "procedures",
        "project-diagram",
        "puzzle-piece",
        "qrcode",
        "question",
        "question-circle",
        "quidditch",
        "quote-left",
        "quote-right",
        "quran",
        "radiation",
        "radiation-alt",
        "rainbow",
        "random",
        "receipt",
        "record-vinyl",
        "recycle",
        "redo",
        "redo-alt",
        "registered",
        "remove-format",
        "reply",
        "reply-all",
        "republican",
        "restroom",
        "retweet",
        "ribbon",
        "ring",
        "road",
        "robot",
        "rocket",
        "route",
        "rss",
        "rss-square",
        "ruble-sign",
        "ruler",
        "ruler-combined",
        "ruler-horizontal",
        "ruler-vertical",
        "running",
        "rupee-sign",
        "sad-cry",
        "sad-tear",
        "satellite",
        "satellite-dish",
        "save",
        "school",
        "screwdriver",
        "scroll",
        "sd-card",
        "search",
        "search-dollar",
        "search-location",
        "search-minus",
        "search-plus",
        "seedling",
        "server",
        "shapes",
        "share",
        "share-alt",
        "share-alt-square",
        "share-square",
        "shekel-sign",
        "shield-alt",
        "ship",
        "shipping-fast",
        "shoe-prints",
        "shopping-bag",
        "shopping-basket",
        "shopping-cart",
        "shower",
        "shuttle-van",
        "sign",
        "sign-in-alt",
        "sign-language",
        "sign-out-alt",
        "signal",
        "signature",
        "sim-card",
        "sitemap",
        "skating",
        "skiing",
        "skiing-nordic",
        "skull",
        "skull-crossbones",
        "slash",
        "sleigh",
        "sliders-h",
        "smile",
        "smile-beam",
        "smile-wink",
        "smog",
        "smoking",
        "smoking-ban",
        "sms",
        "snowboarding",
        "snowflake",
        "snowman",
        "snowplow",
        "socks",
        "solar-panel",
        "sort",
        "sort-alpha-down",
        "sort-alpha-down-alt",
        "sort-alpha-up",
        "sort-alpha-up-alt",
        "sort-amount-down",
        "sort-amount-down-alt",
        "sort-amount-up",
        "sort-amount-up-alt",
        "sort-down",
        "sort-numeric-down",
        "sort-numeric-down-alt",
        "sort-numeric-up",
        "sort-numeric-up-alt",
        "sort-up",
        "spa",
        "space-shuttle",
        "spell-check",
        "spider",
        "spinner",
        "splotch",
        "spray-can",
        "square",
        "square-full",
        "square-root-alt",
        "stamp",
        "star",
        "star-and-crescent",
        "star-half",
        "star-half-alt",
        "star-of-david",
        "star-of-life",
        "step-backward",
        "step-forward",
        "stethoscope",
        "sticky-note",
        "stop",
        "stop-circle",
        "stopwatch",
        "store",
        "store-alt",
        "stream",
        "street-view",
        "strikethrough",
        "stroopwafel",
        "subscript",
        "subway",
        "suitcase",
        "suitcase-rolling",
        "sun",
        "superscript",
        "surprise",
        "swatchbook",
        "swimmer",
        "swimming-pool",
        "synagogue",
        "sync",
        "sync-alt",
        "syringe",
        "table",
        "table-tennis",
        "tablet",
        "tablet-alt",
        "tablets",
        "tachometer-alt",
        "tag",
        "tags",
        "tape",
        "tasks",
        "taxi",
        "teeth",
        "teeth-open",
        "temperature-high",
        "temperature-low",
        "tenge",
        "terminal",
        "text-height",
        "text-width",
        "th",
        "th-large",
        "th-list",
        "theater-masks",
        "thermometer",
        "thermometer-empty",
        "thermometer-full",
        "thermometer-half",
        "thermometer-quarter",
        "thermometer-three-quarters",
        "thumbs-down",
        "thumbs-up",
        "thumbtack",
        "ticket-alt",
        "times",
        "times-circle",
        "tint",
        "tint-slash",
        "tired",
        "toggle-off",
        "toggle-on",
        "toilet",
        "toilet-paper",
        "toolbox",
        "tools",
        "tooth",
        "torah",
        "torii-gate",
        "tractor",
        "trademark",
        "traffic-light",
        "train",
        "tram",
        "transgender",
        "transgender-alt",
        "trash",
        "trash-alt",
        "trash-restore",
        "trash-restore-alt",
        "tree",
        "trophy",
        "truck",
        "truck-loading",
        "truck-monster",
        "truck-moving",
        "truck-pickup",
        "tshirt",
        "tty",
        "tv",
        "umbrella",
        "umbrella-beach",
        "underline",
        "undo",
        "undo-alt",
        "universal-access",
        "university",
        "unlink",
        "unlock",
        "unlock-alt",
        "upload",
        "user",
        "user-alt",
        "user-alt-slash",
        "user-astronaut",
        "user-check",
        "user-circle",
        "user-clock",
        "user-cog",
        "user-edit",
        "user-friends",
        "user-graduate",
        "user-injured",
        "user-lock",
        "user-md",
        "user-minus",
        "user-ninja",
        "user-nurse",
        "user-plus",
        "user-secret",
        "user-shield",
        "user-slash",
        "user-tag",
        "user-tie",
        "user-times",
        "users",
        "users-cog",
        "utensil-spoon",
        "utensils",
        "vector-square",
        "venus",
        "venus-double",
        "venus-mars",
        "vial",
        "vials",
        "video",
        "video-slash",
        "vihara",
        "voicemail",
        "volleyball-ball",
        "volume-down",
        "volume-mute",
        "volume-off",
        "volume-up",
        "vote-yea",
        "vr-cardboard",
        "walking",
        "wallet",
        "warehouse",
        "water",
        "wave-square",
        "weight",
        "weight-hanging",
        "wheelchair",
        "wifi",
        "wind",
        "window-close",
        "window-maximize",
        "window-minimize",
        "window-restore",
        "wine-bottle",
        "wine-glass",
        "wine-glass-alt",
        "won-sign",
        "wrench",
        "x-ray",
        "yen-sign",
        "yin-yang",
    ];
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getIconOptions() {
        return this.toolIcons.map(function (ti) {
            return { id: "fal fa-" + ti, label: "fal fa-" + ti };
        });
    }

    //private colorScheme:string[] =  ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"];
    private colorScheme: ICIColor[] = [
        UIToolsConstants.CIColors.Black,
        UIToolsConstants.CIColors.BlueDoger,
        UIToolsConstants.CIColors.BlueEastBay,
        UIToolsConstants.CIColors.BlueLagoon,
        UIToolsConstants.CIColors.BlueZiggurat,
        UIToolsConstants.CIColors.BrownCoffeeRoyal,
        UIToolsConstants.CIColors.BrownDiSerria,
        UIToolsConstants.CIColors.BrownTiaMaria,
        UIToolsConstants.CIColors.GreenSushi,
        UIToolsConstants.CIColors.GreenYellow,
        UIToolsConstants.CIColors.GreyDark,
        UIToolsConstants.CIColors.OrangeYellow,
        UIToolsConstants.CIColors.PinkHot,
        UIToolsConstants.CIColors.RedPersimmon,
        UIToolsConstants.CIColors.VioletElectric,
        UIToolsConstants.CIColors.VioletMauve,
        UIToolsConstants.CIColors.YellowBrightSun,
    ];

    public calculateColorFrom(input: string): ICIColor {
        let colorIndex = 0;
        for (let i = 0; i < input.length; i++) {
            colorIndex += input.charCodeAt(i);
        }
        return this.colorScheme[colorIndex % this.colorScheme.length];
    }

    public getAvatar(info: string, size: number): JQuery {
        let avatarDiv = $("<div class='avatar noselect' title='" + info + "'/>");

        let settings = {
            border: {
                color: UIToolsConstants.CIColors.GreyLightAlto.color, // '#ddd',
                width: 3,
            },
            colors: this.colorScheme.filter(function (color) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return color.color != UIToolsConstants.CIColors.GreyLightAlto.color;
            }), //["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"],

            text: UIToolsConstants.CIColors.Black.color, // '#fff',
            size: size,
            middlename: true,
            uppercase: true,
        };

        let elementText = info;
        let initialLetters = info.length < 2 ? [info[0]] : [info[0], info[1]];
        let initials = initialLetters.join("");

        let color = this.calculateColorFrom(info);

        avatarDiv.text(initials);
        avatarDiv.css({
            color: color.alternateColor,
            "background-color": color.color,
            display: "inline-block",
            "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
            "font-size": settings.size * 0.4,
            "border-radius": settings.size + "px",
            width: settings.size + "px",
            height: settings.size + "px",
            "line-height": settings.size + "px",
            "text-align": "center",
            "text-transform": settings.uppercase ? "uppercase" : "",
        });
        return avatarDiv;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public doCopy(content: JQuery, onProcessCopy: Function) {
        let that = this;
        let copyBufferHTML = $("<div class='copyBuffer'>").appendTo("body");
        copyBufferHTML.append(content.clone(true));
        $(".hideCopy", copyBufferHTML).remove();
        $(".hideCopyAlways", copyBufferHTML).remove();
        $(".tooltip", copyBufferHTML).remove();
        $(".tooltip-inner", copyBufferHTML).remove();

        $(".hideScreen", copyBufferHTML).removeClass("hideScreen");
        // copy content of data into html
        $.each($(".replaceCopy", copyBufferHTML), function (ridx, replace) {
            $(replace).html($(replace).data("with"));
        });

        if (onProcessCopy) {
            onProcessCopy(copyBufferHTML);
        }

        this.serverHtmlCleanupBlob(copyBufferHTML).done((cleanHtml) => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            globalMatrix.serverStorage.setItem("copyBuffer", cleanHtml);
            copyBufferHTML.remove();
            ml.UI.showAck(
                -3,
                `To paste the copied information, use the <b>"Paste Dashboard" menu entry </b> in the Matrix menu (for the TinyMCE editor) or the <b>"Paste" menu entry</b> in the paste menu button <br/> (for the legacy editor).<br> <br> Please refer to <a href='https://urlshort.matrixreq.com/d25/faq/pastedashboard' target='_blank'> the FAQ </a>for more details`,
                "Copy completed",
            );
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private countVisibleDialogs(idx: number) {
        let count = 0;
        let cls = idx ? ".ui-dialog.ui-front" + idx : ".ui-dialog.ui-front";
        $(cls).each(function (dlgidx, dlg) {
            if ($(dlg).css("display") !== "none") {
                count++;
            }
        });
        // return count BUT substract 1 from level 0 (the dialog itself which is pushed )
        return idx ? count : count - 1;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addChevronSection(container: JQuery, text: string, help: string, open?: boolean) {
        let isOpen = open ? true : false;
        let details = $(`<div class='chevronInner' style='display:${isOpen ? "block" : "none"};'>`);
        let helpLine = $(`<div class='chevronHelp'>${help}</div>`);
        let toggle = $(
            `<div class='chevronHeading'><span class="fa ${
                isOpen ? "fa-chevron-down" : "fa-chevron-right"
            }"></span> <span class='chevronText'>${text}</span></div>`,
        )
            .appendTo(container)
            .click(function () {
                if (isOpen) {
                    isOpen = false;
                    details.hide();
                    $(".fa", toggle).removeClass("fa-chevron-down").addClass("fa-chevron-right");
                } else {
                    isOpen = true;
                    details.show();
                    $(".fa", toggle).removeClass("fa-chevron-right").addClass("fa-chevron-down");
                }
            });

        if (help) {
            helpLine.appendTo(container);
        }

        details.appendTo(container);
        return details;
    }

    private getSlideHeight(slide: JQuery, padded: boolean): number {
        let height = 0;
        slide.children().each(function (idx: number, child: Node) {
            height += $(child).height();
        });
        let padding = slide.outerHeight() - slide.height(); // padding of outer div + border

        if (height + padding < 50) {
            height = 50 - padding;
        }
        if (height > 300) {
            height = 300;
        }

        let screenHeight = $("#itemDetails").height() ? $("#itemDetails").height() : 400;

        if (!padded) {
            return Math.min(screenHeight, Math.max(height, slide.height()));
        }
        return Math.min(screenHeight, Math.max(height + padding, slide.outerHeight()));
    }

    private showSlide(slideId: string, outAgain?: number): void {
        let slide = $(slideId);
        // calc height of box
        slide.height("auto");
        let height = this.getSlideHeight(slide, false);
        let heightPadded = this.getSlideHeight(slide, true);
        // set height of box
        slide.height(height + "px");

        if (slide.css("margin-top") !== "0px") {
            // slide is not visible
            // move box out of top of screen, so that bottom is just above top of screen
            slide.css("margin-top", "-" + heightPadded + "px");
        }
        // scroll it in
        slide.clearQueue().animate({ "margin-top": "0px" });
        // after s sec out again

        if (outAgain) {
            window.clearTimeout(this.removeTimer[slideId]);
            this.removeTimer[slideId] = window.setTimeout(function () {
                slide.clearQueue().animate({ "margin-top": "-" + (heightPadded + 14) + "px" });
            }, outAgain);
        }
    }

    private hideSlide(slide: JQuery): void {
        // calc height of box
        let that = this;
        slide.promise().done(function () {
            let heightPadded = that.getSlideHeight(slide, true);

            slide.css("margin-top", "-" + (heightPadded + 4) + "px");
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getItemHtmlFromCacheOrServerAsync(itemId: string, crossProject?: string): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();
        let now = new Date();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.hidden_tooltip_itemId = null;
        let last_cached = crossProject ? null : this.tooltip_cache[itemId];
        if (crossProject) {
            app.getItemProjectAsync(crossProject, itemId, true)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(function (item: IItem) {
                    that.renderCrossItem(item, crossProject).always(() => {
                        res.resolve();
                    });
                })
                .fail(function (error) {
                    res.reject(error);
                });
        } else if (last_cached && now.getTime() - last_cached.date.getTime() < 10000) {
            that.renderItem(last_cached.item).then(() => {
                res.resolve();
            });
        } else {
            app.getItemAsync(itemId, undefined, true)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(async function (item: IItem) {
                    await that.renderItem(item);
                    last_cached = { date: new Date(), item: item };
                    that.tooltip_cache[itemId] = last_cached;
                    res.resolve();
                })
                .fail(function (error) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (!error || !error.responseJSON || error.responseJSON.code != "AuthenticationFailed") {
                        matrixApplicationUI.renderErrorControl(
                            $("#tooltip_panel"),
                            "The item " + itemId + " does not exist.",
                            "And it never did ....",
                            true,
                        );
                    }

                    res.resolve();
                });
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async renderItem(item: IItem) {
        let that = this;
        $("#tooltip_panel").html("");
        $("<div class='tooltip_panel_close'>x</div>")
            .appendTo($("#tooltip_panel"))
            .click(function () {
                that.hideCurrentToolTip();
            });
        if (item.title === undefined) {
            matrixApplicationUI.renderErrorControl(
                $("#tooltip_panel"),
                "The item " + item.id + " was deleted",
                "You can find it in the deleted item log.",
                true,
            );
        } else if (app.canViewItem(item)) {
            let ctrl = new ItemControl({
                control: $("#tooltip_panel"),
                controlState: ControlState.Tooltip,
                item: item,
                isItem: typeof item.children === "undefined",
            });
            await ctrl.load();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let bcs = app.getBreadcrumbs(item.id).filter(function (bc) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return bc != item.id;
            });
            let breadcrumb = bcs
                .reverse()
                .map(function (bid: string) {
                    return bid + " " + app.getItemTitle(bid);
                })
                .join(" > ");

            let title = $("#tooltip_panel .itemTitle")
                .addClass("tooltipItem")
                .removeClass("pull-left")
                .removeClass("itemTitle");
            title.closest(".itemTitleBarNoToolsNoEdit").removeClass("itemTitleBarNoToolsNoEdit");
            $("<hr style='border-top-color: #aaa;margin-top: 10px;width: 100%;margin-bottom: 10px;'/>").insertAfter(
                title,
            );
            $('<div style="font-size: smaller;">')
                .append($("<span class='inlineHelp'>").html(breadcrumb))
                .insertAfter(title);
        } else {
            matrixApplicationUI.renderErrorControl(
                $("#tooltip_panel"),
                "You have no rights to view " + item.id + "",
                "Talk to the project administrator.",
                true,
            );
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderCrossItem(item: IItem, project: string) {
        let that = this;
        let res = $.Deferred();

        $("#tooltip_panel").html("");
        $("<div class='tooltip_panel_close'>x</div>")
            .appendTo($("#tooltip_panel"))
            .click(function () {
                that.hideCurrentToolTip();
            });

        if (item.isDeleted) {
            $("#tooltip_panel").html(item.id + " in project " + project + " was deleted");
            res.resolve();
            return res;
        }
        let originalIC = ml.JSON.clone(globalMatrix.ItemConfig);
        let newIC = new ItemConfiguration(ml.Logger, ml.JSON);

        restConnection
            .getServer(project + "/cat")
            .done(function (catDetails) {
                newIC.addCategories(catDetails as XRGetProject_CategoryList_GetProjectStructAck);

                restConnection
                    .getServer(project + "/setting")
                    .done(async function (settings) {
                        newIC.addSettings(settings as XRGetProject_ProjectSettingAll_GetSettingAck);

                        setIC(newIC);

                        let itemForm = new ItemControl({
                            control: $("#tooltip_panel"),
                            controlState: ControlState.Tooltip,
                            item: item,
                            isItem: typeof item.children === "undefined",
                        });
                        await itemForm.load();
                        let projectInfo = "Project: " + project;

                        let title = $("#tooltip_panel .itemTitle")
                            .addClass("tooltipItem")
                            .removeClass("pull-left")
                            .removeClass("itemTitle");
                        title.closest(".itemTitleBarNoToolsNoEdit").removeClass("itemTitleBarNoToolsNoEdit");
                        $(
                            "<hr style='border-top-color: #aaa;margin-top: 10px;width: 100%;margin-bottom: 10px;'/>",
                        ).insertAfter(title);
                        $('<div style="font-size: smaller;">')
                            .append($("<span class='inlineHelp'>").html(projectInfo))
                            .insertAfter(title);

                        setIC(originalIC);
                        res.resolve();
                    })
                    .fail(function () {
                        setIC(originalIC);
                        res.resolve();
                    });
            })
            .fail(function () {
                setIC(originalIC);
                res.resolve();
            });
        return res;
    }

    private hideCurrentToolTip(): void {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.hidden_tooltip_itemId = null;
        clearTimeout(this.lastTooltipHide);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.lastTooltipHide = null;
        let height = Math.max(300, this.getSlideHeight($("#tooltip_panel"), true));
        $("#tooltip_panel").css("margin-top", "-" + (height + 30) + "px");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showTaskAsTooltip_Delayed(id: string, title: string, url: string, htmlContent: string, target: JQuery) {
        let that = this;

        $("#tooltip_panel").html("");
        $("<div class='tooltip_panel_close'>x</div>")
            .appendTo($("#tooltip_panel"))
            .click(function () {
                that.hideCurrentToolTip();
            });
        if (title === undefined) {
            matrixApplicationUI.renderErrorControl($("#tooltip_panel"), "The item " + " was deleted", "", true);
        } else {
            $("#tooltip_panel").append(
                `<div class="tooltipItem"><a href="${url}" target="_blank"><span class="">${id}</span></a><span class="refTitle">${title}</span></div>`,
            );
            $("#tooltip_panel").append(
                `<hr style="border-top-color: #aaa;margin-top: 10px;width: 100%;margin-bottom: 10px;">`,
            );
            $("#tooltip_panel").append(`<div class="dialog-body">${htmlContent} </div>`);
        }

        that.showSlide("#tooltip_panel");
        $("#tooltip_panel").on("mouseover", function () {
            clearTimeout(that.lastTooltipHide);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.lastTooltipHide = null;
        });
        $("#tooltip_panel").on("mouseleave", function () {
            if (!globalMatrix.globalShiftDown) {
                that.hideCurrentToolTip();
            }
        });
    }
    private showTooltip_Delayed(itemId: string, target: JQuery, crossProject?: string): void {
        let that = this;

        if (
            itemId.indexOf("-") === 0 ||
            app.getType(itemId)[0] === "_" ||
            app.isFolder(itemId) ||
            app.getCurrentItemId() === itemId
        ) {
            this.hideCurrentToolTip();
            return;
        }

        if (Tasks.isTaskId(itemId)) {
            this.hideCurrentToolTip();
            return;
        }

        this.getItemHtmlFromCacheOrServerAsync(itemId, crossProject).done(function () {
            if (!globalMatrix.globalShiftDown || app.getCurrentItemId() === itemId) {
                that.hideCurrentToolTip();
                return;
            }

            that.showSlide("#tooltip_panel");
            $("#tooltip_panel").on("mouseover", function () {
                clearTimeout(that.lastTooltipHide);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.lastTooltipHide = null;
            });
            $("#tooltip_panel").on("mouseleave", function () {
                if (!globalMatrix.globalShiftDown) {
                    that.hideCurrentToolTip();
                }
            });
        });
    }

    // config client helper

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    enableIf(cb: JQuery, state: boolean, ctrls: JQuery[]) {
        let that = this;
        $("input", cb).change(function () {
            $.each(ctrls, function (idx, ctrl) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ml.UI.setEnabled(ctrl, $("input", cb).is(":checked") == state);
            });
        });
        $.each(ctrls, function (idx, ctrl) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            ml.UI.setEnabled(ctrl, $("input", cb).is(":checked") == state);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addCheckboxD(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        defaultValue: string,
    ): JQuery {
        let that = this;
        let dataCY = propertyName ?? text.replace(/\s/g, "-");

        let cb = $(`<div data-cy="${dataCY}">`)
            .appendTo(ui)
            .checkBox({
                canEdit: true,
                help: text,
                valueChanged: async function () {
                    let newValue = await cb.getController().getValueAsync();
                    if (newValue !== fieldParams[propertyName]) {
                        fieldParams[propertyName] = newValue;
                        if (onChange) {
                            onChange();
                        }
                    }
                },
                parameter: {},
                fieldValue: defaultValue,
            });

        cb.getController()
            .getValueAsync()
            .then((value: boolean) => {
                fieldParams[propertyName] = value;
            });
        return cb;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addCheckbox(ui: JQuery, text: string, fieldParams: IGenericMap, propertyName: string, onChange: Function) {
        return this.addCheckboxD(ui, text, fieldParams, propertyName, onChange, fieldParams[propertyName] ? "1" : "0");
    }
    /** checkbox will only be checked if explicitly be set to true*/
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addCheckboxIsTrue(ui: JQuery, text: string, fieldParams: IGenericMap, propertyName: string, onChange: Function) {
        return this.addCheckboxD(
            ui,
            text,
            fieldParams,
            propertyName,
            onChange,
            ml.JSON.isTrue(fieldParams[propertyName]) ? "1" : "0",
        );
    }
    /** checkbox will only checked if NOT explicitly be set to false (so unchanged=default=checked) */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addCheckboxIsFalse(ui: JQuery, text: string, fieldParams: IGenericMap, propertyName: string, onChange: Function) {
        return this.addCheckboxD(
            ui,
            text,
            fieldParams,
            propertyName,
            onChange,
            ml.JSON.isFalse(fieldParams[propertyName]) ? "0" : "1",
        );
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addPassInput(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onUnFocus?: Function,
    ) {
        return this.addTextInput(ui, text, fieldParams, propertyName, onChange, onUnFocus, true);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addTextInput(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onFocusOut?: Function,
        isPass?: boolean,
        help?: string,
        readonly?: boolean,
        rows = 1,
        allowResize = false,
    ): JQuery {
        let that = this;
        let dataCY = propertyName ?? text.replace(/\s/g, "-");
        let pt = $(`<div data-cy="${dataCY}" class="controlContainer">`)
            .appendTo(ui)
            .plainText({
                canEdit: true,
                help: text,
                valueChanged: async function () {
                    let newValue = await pt.getController().getValueAsync();
                    if (fieldParams[propertyName] || newValue) {
                        // avoid creating a empty "" field instead of non existing which would trigger a save

                        if (isPass) {
                            // unescaping the password using TextArea.
                            newValue = $("<textarea/>").html(newValue).text();
                        }

                        fieldParams[propertyName] = newValue;
                    }
                    if (onChange) {
                        onChange();
                    }
                },
                parameter: {
                    rows: rows,
                    allowResize: allowResize,
                    password: isPass ? true : false,
                    inlineHelp: help,
                    readonly: readonly,
                },
                fieldValue: fieldParams[propertyName],
            });
        if (isPass) {
            $("input", pt)
                .attr("readonly", "readonly")
                .on("focus", () => {
                    $("input", pt).removeAttr("readonly");
                });
        }
        if (onFocusOut) {
            pt.focusout(function () {
                onFocusOut();
            });
        }
        return pt;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addRichTextInput(
        ui: JQuery,
        params: IRichTextParams,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onFocusOut?: Function,
    ): JQuery {
        let that = this;
        let dataCY = propertyName ?? text.replace(/\s/g, "-");
        let pt = $(`<div data-cy="${dataCY}"  class="controlContainer">`)
            .appendTo(ui)
            .richText({
                canEdit: true,
                help: text,
                valueChanged: async function () {
                    let newValue = await pt.getController().getValueAsync();
                    if (fieldParams[propertyName] || newValue) {
                        // avoid creating a empty "" field instead of non existing which would trigger a save
                        fieldParams[propertyName] = newValue;
                    }
                    if (onChange) {
                        onChange();
                    }
                },
                parameter: params,
                fieldValue: fieldParams[propertyName],
            });
        if (onFocusOut) {
            pt.focusout(function () {
                onFocusOut();
            });
        }
        return pt;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addDateSelect(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        help?: string,
        readonly?: boolean,
    ): JQuery {
        let dataCY = propertyName ?? text.replace(/\s/g, "-");
        let pt = $(`<div data-cy="${dataCY}"  class="controlContainer">`)
            .appendTo(ui)
            .dateselect({
                controlState: ControlState.FormEdit,
                canEdit: true,
                help: text,
                fieldValue: fieldParams[propertyName] ? fieldParams[propertyName] : "",
                valueChanged: async function () {
                    fieldParams[propertyName] = await pt.getController().getValueAsync();
                    onChange();
                },
                parameter: {
                    allowClear: true,
                    inlineHelp: help,
                    readonly: readonly,
                },
            });
        return pt;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addIconInput(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onFocusOut?: Function,
        isPass?: boolean,
    ) {
        return this.addDropdownToValue(
            ui,
            text,
            fieldParams,
            propertyName,
            this.getIconOptions(),
            true,
            true,
            onChange,
            "",
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addDropdownToArray(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        options: IDropdownOption[],
        grouping: IDropdownGroup[],
        maxItems: number,
        create: boolean,
        sort: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        placeholder?: string,
    ): JQuery {
        let that = this;
        let dataCY = propertyName ?? text.replace(/\s/g, "-");
        let dd = $(`<div data-cy="${dataCY}"  class="controlContainer">`)
            .appendTo(ui)
            .mxDropdown({
                help: text,
                canEdit: true,
                parameter: {
                    placeholder: placeholder ? placeholder : "please select",
                    create: create,
                    options: options,
                    maxItems: maxItems,
                    groups: grouping,
                    sort: sort,
                },
                valueChanged: async function () {
                    if (dd) {
                        let changed = await dd.getController().getValueAsync();
                        fieldParams[propertyName] = changed ? changed.split(",") : [];
                        if (onChange) {
                            onChange();
                        }
                    }
                },
                fieldValue: fieldParams[propertyName] ? fieldParams[propertyName].join(",") : "",
            });
        return dd;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addDropdownNumber(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        first: number,
        last: number,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        placeholder?: string,
        paramsBase?: IDropdownParams,
    ) {
        const current = { val: fieldParams[propertyName] ? "" + fieldParams[propertyName] : "0" };
        const options = Array.from({ length: 1 + last - first }, (_, n) => {
            return n + first;
        }).map((n) => {
            return { id: "" + n, label: "" + n };
        });
        this.addDropdownToValue(
            ui,
            text,
            current,
            "val",
            options,
            false,
            false,
            () => {
                fieldParams[propertyName] = Number(current.val);
                if (onChange) {
                    onChange();
                }
            },
            placeholder,
            paramsBase,
        );
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addDropdownToValue(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        options: IDropdownOption[],
        create: boolean,
        sort: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        placeholder?: string,
        paramsBase?: IDropdownParams,
    ): JQuery {
        let defaultParams = paramsBase ? paramsBase : {};
        let params = ml.JSON.clone({
            ...defaultParams,
            ...{
                placeholder: placeholder ? placeholder : "please select",
                create: create,
                options: options,
                maxItems: 1,
                sort: sort,
            },
        });
        let dataCY = propertyName ?? text.replace(/\s/g, "-");
        let dd = $(`<div data-cy="${dataCY}" class="controlContainer">`)
            .appendTo(ui)
            .mxDropdown({
                help: text,
                canEdit: true,
                parameter: params,
                valueChanged: async function () {
                    if (dd) {
                        let changed = await dd.getController().getValueAsync();
                        fieldParams[propertyName] = changed;
                        if (onChange) {
                            onChange();
                        }
                    }
                },
                fieldValue: fieldParams[propertyName] ? fieldParams[propertyName] : "",
            });
        return dd;
    }

    getPageTitle(title: string, getPanel?: () => JQuery, resize?: () => void): JQuery {
        let that = this;
        let div = $(`<div class="panel-heading itemTitleBar addedTitle">
            <div class="itemTitle pull-left">
                <span data-cy='title' class="refTitle">${title}</span>
            </div>

         </div>`);
        let toolBar = $(
            "<div class=' hidden-print toolsBarButtonsContainer'><div class='btn-group toolbarButtons'></div></div>",
        );
        div.append(toolBar);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (getPanel != undefined) {
            let bResize = $(
                "<div  class='btn-group btn-dashboard-fullscreen' ><button title data-original-title='Fullscreen' tabindex='-1' class='btn btn-item btn-fullscreen'> <span class='fal fa-expand-arrows-alt'></span></button></div>",
            ).click(function () {
                let panel = getPanel();

                let panelParent = panel.parent();
                let toolbarParent = toolBar.parent();

                let dlg = $("<div>").appendTo($("body"));
                ml.UI.showDialog(
                    dlg,
                    title,
                    panel,
                    window.innerWidth,
                    window.innerHeight,
                    [
                        {
                            text: "Ok",
                            class: "btnDoIt",
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            click: function () {
                                dlg.dialog("close");
                                panelParent.append(panel);
                                toolBar.appendTo(toolbarParent);
                                dlg.remove();
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                resize();
                            },
                        },
                    ],
                    UIToolsConstants.Scroll.None,
                    false,
                    false,
                    () => {
                        dlg.dialog("close");
                        panelParent.append(panel);
                        toolBar.appendTo(toolbarParent);
                        dlg.remove();
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        resize();
                    },
                    () => {
                        $(".ui-dialog-titlebar").append(toolBar);
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        resize();
                    },
                    () => {},
                );
            });
            $(".toolbarButtons", toolBar).append(bResize);
        }
        return div;
    }

    /* ***************************************************
       list of items with up / down / edit delete buttons
       *************************************************** */

    // add a line which can be moved up or down
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createConfigLine(
        lineId: string,
        linePrefix: string,
        lineName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        lineArray: any[],
        idProp: string,
        onChangedOrder: () => void,
        onEdit: (lineId: string) => void,
        needsEdit: boolean,
        onDelete: (lineId: string) => void,
    ): JQuery {
        let that = this;
        let li = $("<li>");
        $("<span>").html(`<b>${linePrefix}</b>`).appendTo(li);
        $("<span>").html(`<b>${lineName}</b>`).appendTo(li);

        $("<span class='moveUp btn-link' data-original-title='Move up' >")
            .data("lineId", lineId)
            .append('<i style="cursor:pointer" class="taglist fal fa-arrow-up" >')
            .click(function (event) {
                let liToMove = $(event.delegateTarget).data("lineId");
                let liBefore = that.moveUp($(event.delegateTarget));

                let labelToMoveIdx = that.getIndex(lineArray, idProp, liToMove);
                let labelBeforeIdx = that.getIndex(lineArray, idProp, liBefore);

                let toMove = lineArray.splice(labelToMoveIdx, 1);
                lineArray.splice(labelBeforeIdx, 0, toMove[0]);

                onChangedOrder();
            })
            .appendTo(li);

        $("<span class='moveDown btn-link'>  data-original-title='Move down'")
            .data("lineId", lineId)
            .append('<i style="cursor:pointer" class="taglist fal fa-arrow-down" >')
            .click(function (event) {
                let liToMove = $(event.delegateTarget).data("lineId");
                let liAfter = that.moveDown($(event.delegateTarget));
                let labelToMoveIdx = that.getIndex(lineArray, idProp, liToMove);
                let labelAfterIdx = that.getIndex(lineArray, idProp, liAfter);
                let toMove = lineArray.splice(labelToMoveIdx, 1);
                lineArray.splice(labelAfterIdx + 1, 0, toMove[0]);

                onChangedOrder();
            })
            .appendTo(li);

        // edit button
        if (onEdit) {
            $(`<span style='${needsEdit ? "color:red" : ""}' class=' btn-link  data-original-title='Edit'>)`)
                .html("<i class='fal fa-pencil'/>")
                .data("lineId", lineId)
                .click(function (event) {
                    onEdit($(event.delegateTarget).data("lineId"));
                })
                .appendTo(li);
        }

        // delete button
        $("<span class=' btn-link'  data-original-title='Delete' >")
            .html("<i class='fal fa-trash-alt'/>")
            .data("lineId", lineId)
            .click(function (event) {
                ml.UI.showConfirm(
                    -1,
                    { title: "Delete " + lineId, ok: "Delete", nok: "Cancel" },
                    () => {
                        let dl = $(event.delegateTarget).data("lineId");

                        onDelete(dl);
                    },
                    () => {},
                );
            })
            .appendTo(li);

        return li;
    }

    // remove first up / last down icon button
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fixArrows(ul: JQuery) {
        let count = ul.data("count");
        $.each($("li", ul), function (idx, li) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            idx == 0 || count < 2 ? $(".moveUp", li).hide() : $(".moveUp", li).show();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            idx + 1 == count || count < 2 ? $(".moveDown", li).hide() : $(".moveDown", li).show();
        });
    }

    // line to add a new element
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createConfigAddLine(action: string, onAdd: () => void) {
        let li = $("<li>");

        $("<span class='editLinkButtonLeft'>")
            .html(action)
            .click(function (event) {
                onAdd();
            })
            .appendTo(li);

        return li;
    }
    standardizeColor(fieldValue: string, alpha = 20): string {
        let ctx = document.createElement("canvas").getContext("2d");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctx.fillStyle = fieldValue;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return ctx.fillStyle + (alpha > 0 ? alpha.toString() : "");
    }
    // MATRIX-6169: disallow filter selection when item is not saved
    /**
     * Enable or disable the global filter selection
     * @param isEnabled
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    changeGlobalFilterSelectionEnabled(isEnabled: boolean) {
        if (isEnabled) {
            $("#sidebar .searchBoxFilter").removeAttr("disabled");
            $("#sidebar .searchBoxFilter").removeAttr("title");
            $("#sidebar #clearSelectedFilter").removeAttr("disabled");
            $("#sidebar .chip a").show();
        } else {
            $("#sidebar .searchBoxFilter").attr("disabled", "disabled");
            $("#sidebar .searchBoxFilter").attr("title", "Filter selection is disabled until item is saved");
            $("#sidebar #clearSelectedFilter").attr("disabled", "disabled");
            $("#sidebar .chip a").hide();
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    toggleFilters(filterOn: boolean) {
        if (filterOn) {
            $("#globalProjectFilter >> button").removeAttr("disabled");
            $("#sidebar .searchBoxFilter").show();
            $(".itemFilterTool").show();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if ($(".itemFilterTool .chip:visible").length == 0) {
                $(".itemFilterTool").hide();
            }
        } else {
            $("#globalProjectFilter >> button").attr("disabled", "disabled");
            $("#sidebar .searchBoxFilter").hide();
            $(".itemFilterTool").hide();
        }
    }

    /* ***************************************************
      handle < in html to prevent dangerous stuff
      *************************************************** */

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getIndex(lineArray: any[], idProp: string, lineId: string) {
        for (let idx = 0; idx < lineArray.length; idx++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (lineArray[idx][idProp] == lineId) {
                return idx;
            }
        }
        return -1;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected moveUp(moveBtn: JQuery) {
        let li1 = moveBtn.closest("li");
        let li2 = li1.prev("li");

        li2.before(li1);

        this.fixArrows(moveBtn.closest("ul"));
        return $(".moveDown", li2).data("lineId");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected moveDown(moveBtn: JQuery) {
        let li1 = moveBtn.closest("li");
        let li2 = li1.next("li");

        li2.after(li1);

        this.fixArrows(moveBtn.closest("ul"));
        return $(".moveUp", li2).data("lineId");
    }
}

export class BlockingProgressUI implements IBlockingProgressUI {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private progressLauncher: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private animation: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private taskList: IBlockingProgressUITask[];

    private closeIfDone(): void {
        let allDone = true;
        $.each(this.taskList, function (idx, task) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (task.progress < 100) {
                allDone = false;
            }
        });
        if (allDone) {
            clearTimeout(this.progressLauncher);
            $("#message_progress").hide();
        }
    }

    Init(tasks: IBlockingProgressUITask[], animate?: boolean): void {
        let that = this;

        this.taskList = ml.JSON.clone(tasks);
        clearTimeout(this.progressLauncher);
        clearTimeout(this.animation);

        let table = $("<table style='width:100%'>");
        let tbody = $("<tbody>");
        table.append(tbody);
        $("#message_progress_text")
            .addClass("alert-success")
            .removeClass("alert-danger")
            .css("width", "600px")
            .html("")
            .append(table);
        $.each(this.taskList, function (idx, task) {
            task.progress = 0;

            let tr = $("<tr>");
            let tdp = $(
                '<div class="progress-bar progress-bar-success progress-bar-striped active progressCtrl2 pci' +
                    idx +
                    '" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0%"></div>',
            );
            tdp.data("valuenow", 1).width(1 + "%");

            let name = task.name;
            if (name && name.length > 40) {
                name = name.substring(0, 37) + "...";
            }

            tbody.append(
                tr
                    .append($("<td id='progressN" + idx + "' style='text-align:left'>").html(name))
                    .append($("<td style='width:66%;  padding-right: 10px;'>").append(tdp)),
            );
        });

        this.progressLauncher = window.setTimeout(function () {
            $("#message_progress").show();
            if (animate) {
                $(".pci0", $("#message_progress_text")).width("30px");
                that.taskList[0].progress = 0;
                that.animation = window.setInterval(function () {
                    that.tick();
                }, 50);
            }
        }, 10);
    }

    SetProgress(taskIdx: number, percent: number, newText?: string): void {
        clearTimeout(this.animation);
        $(".pci0", $("#message_progress_text")).css("margin-left", "");
        percent = percent <= 100 ? percent : 100;
        this.taskList[taskIdx].progress = percent;
        $(".pci" + taskIdx, $("#message_progress_text"))
            .data("valuenow", percent)
            .width(percent + "%");
        if (newText) {
            $("#progressN" + taskIdx).html(newText);
        }
        this.closeIfDone();
    }

    SetProgressError(taskIdx: number, problem: string): void {
        clearTimeout(this.animation);
        this.taskList[taskIdx].progress = 100;
        $(".pci0", $("#message_progress_text")).css("margin-left", "");
        $(".pci" + taskIdx, $("#message_progress_text"))
            .html(problem)
            .css("background-color", "red")
            .width("100%");
        $("#message_progress_text").removeClass("alert-success").addClass("alert-danger");
        this.closeIfDone();
    }
    private tick(): void {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let percent = this.taskList[0].progress + 1;
        this.taskList[0].progress = percent < 90 ? percent : 0;
        let bar = $(".pci0", $("#message_progress_text"));
        bar.prop("style", "width:30px; margin-left:" + percent + "% !important");
    }
}

export class ProgressUI implements IProgressUI {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ProgressCtrlTimer: number;

    Init(message: string, warning?: boolean): void {
        $("#progressCtrlContainer").removeClass("hide");
        $("#progressCtrlText").text(message);
        $("#progressCtrl")
            .data("valuenow", 1)
            .width(1 + "%")
            .removeClass("progressWarning");
        if (warning) {
            $("#progressCtrl").addClass("progressWarning");
        }
    }
    Update(progress: number): void {
        $("#progressCtrl")
            .data("valuenow", progress)
            .width(progress + "%");
    }
    getProgress(): number {
        return $("#progressCtrl").data("valuenow");
    }
    SuccessHide(message: string, ms: number): void {
        ml.Logger.log("success", message);

        $("#progressCtrlText").text(message);
        $("#progressCtrl")
            .data("valuenow", 100)
            .width(100 + "%");
        clearTimeout(this.ProgressCtrlTimer);
        this.ProgressCtrlTimer = window.setTimeout(function () {
            $("#progressCtrlContainer").addClass("hide");
            $("#progressCtrlText").text("");
            $("#progressCtrl")
                .data("valuenow", 0)
                .width(0 + "%");
        }, ms);
    }
    ErrorHide(message: string, ms: number): void {
        ml.Logger.log("error", message);
        $("#progressCtrlContainer").css("background-color", "red");
        $("#progressCtrlText").text(message);
        $("#progressCtrl")
            .data("valuenow", 0)
            .width(0 + "%");
        clearTimeout(this.ProgressCtrlTimer);
        this.ProgressCtrlTimer = window.setTimeout(function () {
            $("#progressCtrlContainer").addClass("hide");
            $("#progressCtrlContainer").css("background-color", "white");
            $("#progressCtrlText").text("");
            $("#progressCtrl")
                .data("valuenow", 0)
                .width(0 + "%");
        }, ms);
    }
}

export class SelectUserOrGroupUI implements ISelectUserOrGroupUI {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    dlg: JQuery;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showMultiUserSelect(
        container: JQuery,
        help: string,
        selected: string[],
        title: string,
        selectFrom: string,
        selectTo: string,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string[]) => void,
        preSelectedUsers?: XRUserPermissionType[],
    ) {
        let that = this;
        $('<span class="baseControlHelp">' + help + "</span>").appendTo(container);
        let ctrl = $("<div class='baseControl'>").appendTo(container);
        let input = $("<div class='form-control userSelect'>").appendTo(ctrl);
        input.html(
            selected
                ? selected
                      .map(function (userOrGroup) {
                          return that.getGroupDisplayNameFromId(userOrGroup);
                      })
                      .join(", ")
                : "",
        );
        ctrl.click(function (event: JQueryMouseEventObject) {
            that.showSelectDialog(
                selected,
                title,
                selectFrom,
                selectTo,
                showUsers,
                showGroups,
                (selection: string[]) => {
                    let selectedUI = selection.map(function (userOrGroup) {
                        return that.getGroupDisplayNameFromId(userOrGroup);
                    });
                    input.html(selectedUI.join(", "));
                    onSelect(selection);
                    selected = selection;
                },
                preSelectedUsers,
            );

            return false;
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getUsersInSelection(selection: string[]) {
        let that = this;

        let users: string[] = selection.filter(function (userOrRole) {
            return !that.isGroup(userOrRole);
        });

        // add users from groups
        let groups = globalMatrix.ItemConfig.getUserGroups();
        if (groups) {
            $.each(groups, function (aclIdx, group) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (selection.indexOf(that.getGroupId(group)) != -1) {
                    $.each(group.membership, function (mIdx, member) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (users.indexOf(member.login) == -1) {
                            users.push(member.login);
                        }
                    });
                }
            });
        }

        return users;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroupId(group: XRGroupPermissionType | XRGroupType) {
        return globalMatrix.ItemConfig.groupIdToName(group.groupId);
    }

    // convert a group id used in mail to italic group name
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroupDisplayNameFromId(groupOrUserId: string) {
        if (!groupOrUserId) {
            groupOrUserId = "";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (groupOrUserId.indexOf("g_") != 0 || groupOrUserId.indexOf("_g") != groupOrUserId.length - 2) {
            return groupOrUserId; // Not a group let's return the current value.
        }

        let groups = globalMatrix.ItemConfig.getUserGroups().sort(function (a, b) {
            if (a.groupName < b.groupName) {
                return -1;
            } else {
                return 1;
            }
        });
        for (let idx = 0; idx < groups.length; idx++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.getGroupId(groups[idx]) == groupOrUserId) {
                return "<span class='groupFlag'>group&nbsp;</span>" + groups[idx].groupName;
            }
        }
        if (!globalMatrix.ItemConfig.hasGroupInfo(groupOrUserId)) {
            return `<span class='groupFlag'>group&nbsp;</span><s>${groupOrUserId}</s>`;
        }
        return groupOrUserId;
    }
    // return if the id is a group
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isGroup(groupOrUserId: string) {
        let groups = globalMatrix.ItemConfig.getUserGroups();
        for (let idx = 0; idx < groups.length; idx++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.getGroupId(groups[idx]) == groupOrUserId) {
                return true;
            }
        }
        return false;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    exists(groupOrUserId: string) {
        if (this.isGroup(groupOrUserId)) {
            return true;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (globalMatrix.ItemConfig.getUserIds().indexOf(groupOrUserId) != -1) {
            return true;
        }
        return false;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSelectDialog(
        selected: string[],
        title: string,
        selectFrom: string,
        selectTo: string,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string[]) => void,
        preSelectedUsers?: XRUserPermissionType[],
    ) {
        let that = this;

        that.dlg = $(`<div data-cy='${title.replace(/\s/g, "-")}'>`).appendTo("body");
        let select = $("<select multiple>").appendTo(that.dlg);

        selected = selected ? selected : [];

        select.append("<option style='display:none' value='' data-search=''>");
        let users = preSelectedUsers ? preSelectedUsers : globalMatrix.ItemConfig.getUserNames();
        let groups = globalMatrix.ItemConfig.getUserGroups().sort(function (a, b) {
            if (a.groupName < b.groupName) {
                return -1;
            } else {
                return 1;
            }
        });

        let userSelect = select;
        let groupSelect = select;
        if (showGroups && showUsers) {
            userSelect = $("<optgroup label='Users'>").appendTo(select);
            groupSelect = $("<optgroup label='User Groups'>").appendTo(select);
        }
        if (showUsers) {
            // sort users alphabetically
            users = users.sort(function (a, b) {
                if (a.login < b.login) {
                    return -1;
                } else {
                    return 1;
                }
            });

            // add to user select
            $.each(users, function (userIdx, user) {
                let name = globalMatrix.ItemConfig.getCombinedName(user);
                let email = user.email ? user.email : "";
                let searchVal = email + " " + name;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let sel = selected.indexOf(user.login) != -1 ? " selected" : "";

                userSelect.append(
                    $(`
                    <option data-search='${searchVal.toLowerCase()}' data-cy='${user.login}' data-user='${user.login}'
                        title='${email}' value='${user.login}' ${sel}>
                        ${name}
                    </option>`),
                );
            });
        }
        if (showGroups) {
            $.each(groups, function (groupIdx, group) {
                let groupName = group.groupName;
                let groupId = that.getGroupId(group);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let sel = selected.indexOf(groupId) != -1 ? " selected" : "";
                let tooltip =
                    "Press shift to select individual users instead of groups: " +
                    group.membership
                        .map(function (member) {
                            return member.login;
                        })
                        .join(", ");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (group.membership.length == 0) {
                    tooltip = "group has no members";
                }
                groupSelect.append(
                    $(
                        `<option title='${tooltip}'  data-group='group' data-search='group ${groupName.toLowerCase()}' value='${groupId}' ${sel} >${groupName}</option>`,
                    ),
                );
            });
        }

        that.dlg.dialog({
            autoOpen: true,
            title: title ? title : "Select",
            width: 716,
            height: 580,
            resizeStop: function () {
                that.resize();
            },
            modal: true,
            open: function () {
                ml.UI.pushDialog(that.dlg);
                select.multiSelect({
                    selectableHeader:
                        "<div class='custom-header'>" +
                        selectFrom +
                        "</div><input type='text' class='form-control ms-search' autocomplete='off' placeholder='filter'>",
                    selectionHeader:
                        "<div class='custom-header'>" +
                        selectTo +
                        "</div><input type='text' class='form-control ms-search' autocomplete='off' placeholder='filter'>",
                    afterInit: function () {
                        let that = this,
                            $selectableSearch = that.$selectableUl.prev(),
                            $selectionSearch = that.$selectionUl.prev(),
                            selectableSearchString =
                                "#" + that.$container.attr("id") + " .ms-elem-selectable:not(.ms-selected)",
                            selectionSearchString =
                                "#" + that.$container.attr("id") + " .ms-elem-selection.ms-selected";

                        $("li", that.dlg).each(function (idx, opt) {
                            if ($(opt).data("group")) {
                                $(opt).prepend("<span class='groupFlag'>group</span>");
                            }
                            if ($(opt).data("user")) {
                                $(opt).prepend(ml.UI.getAvatar($(opt).data("user"), 25));
                            }
                        });

                        $selectableSearch
                            .on("keyup", function (e: JQueryKeyEventObject) {
                                if (e.which === 40) {
                                    that.$selectableUl.focus();
                                    return false;
                                } else {
                                    let searchExpr = $selectableSearch.val().toLowerCase();
                                    $.each($(selectableSearchString), function (lidx, li) {
                                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                                        // eslint-disable-next-line
                                        !searchExpr || $(li).data("search").indexOf(searchExpr) != -1
                                            ? $(li).show()
                                            : $(li).hide();
                                    });
                                    return true;
                                }
                            })
                            .focus();

                        $selectionSearch.on("keyup", function (e: JQueryKeyEventObject) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (e.which == 40) {
                                that.$selectionUl.focus();
                                return false;
                            } else {
                                let searchExpr = $selectionSearch.val().toLowerCase();
                                $.each($(selectionSearchString), function (lidx, li) {
                                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                                    // eslint-disable-next-line
                                    !searchExpr || $(li).data("search").indexOf(searchExpr) != -1
                                        ? $(li).show()
                                        : $(li).hide();
                                });
                                return true;
                            }
                        });
                    },
                    afterSelect: function (sel: string[]) {
                        let groupAddedAsUsers = false;
                        if (globalMatrix.globalShiftDown && showGroups) {
                            $.each(groups, function (groupIdx, group) {
                                let groupId = that.getGroupId(group);
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                if (groupId == sel[0]) {
                                    groupAddedAsUsers = true;
                                    let newMembers = group.membership
                                        .map(function (member) {
                                            return member.login;
                                        })
                                        .filter(function (login) {
                                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                                            // eslint-disable-next-line
                                            return selected.indexOf(login) == -1;
                                        });
                                    // update UI
                                    $.each(newMembers, function (nmIdx, newMember) {
                                        select.multiSelect("select", [newMember]);
                                    });
                                    // update internal selection
                                    // selected = selected.concat( newMembers );
                                }
                            });
                        }
                        if (!groupAddedAsUsers) {
                            selected.push(sel[0]);
                        }
                    },
                    afterDeselect: function (sel: string[]) {
                        selected = selected.filter(function (s) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            return s != sel[0];
                        });
                    },
                });
                that.resize();
            },
            close: function () {
                ml.UI.popDialog(that.dlg);
                that.dlg.remove();
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        onSelect(selected);
                        that.dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        that.dlg.dialog("close");
                    },
                },
            ],
        });
    }

    getUserDropDownOptions(
        showUsers: boolean,
        showGroups: boolean,
        preSelectedUsers?: XRUserPermissionType[],
        possiblyDeletedUserGroupNames?: string,
    ): IDropdownOption[] {
        return globalMatrix.ItemConfig.getValidUserOptions(
            showUsers,
            showGroups,
            preSelectedUsers,
            possiblyDeletedUserGroupNames,
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSingleSelect(
        control: JQuery,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string) => void,
        preSelectedUsers?: XRUserPermissionType[],
        possiblyDeletedUsername?: string,
    ) {
        let that = this;

        let options: IDropdownOption[] = this.getUserDropDownOptions(
            showUsers,
            showGroups,
            preSelectedUsers,
            possiblyDeletedUsername,
        );

        control.selectize({
            persist: false,
            maxItems: 1,
            valueField: "id",
            labelField: "label",
            searchField: ["label"],
            options: options,
            onChange: function (args: string) {
                onSelect(args);
            },
            render: {
                option: function (item: { label: string; strikethrough: boolean }) {
                    // This is to avoid escaped strings showing in the UI
                    let labelText = item.label;
                    if (item.strikethrough) {
                        labelText = `<s>${labelText}</s>`;
                    }
                    return `<div class='option'>${labelText}</div>`;
                },
                item: function (item: { label: string; strikethrough: boolean }) {
                    let labelText = item.label;
                    if (item.strikethrough) {
                        labelText = `<s>${labelText}</s>`;
                    }
                    return `<div class='item'>${labelText}</div>`;
                },
            },
        });
    }

    // store all the current groups and users in page
    getAllUsersAndGroups(): JQueryDeferred<IDropdownOption[]> {
        let that = this;
        let res: JQueryDeferred<IDropdownOption[]> = $.Deferred();

        let dropdownOptions: IDropdownOption[] = [];

        restConnection.getServer("user").done(function (allUsersResult) {
            let users = (allUsersResult as XRGetUser_AllUsers_GetUserListAck).user
                .filter(function (user) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return user.userStatus != "deleted" && user.userStatus != "blocked" && !user.superAdmin;
                })
                .sort(function (a, b) {
                    if (a.login < b.login) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

            // add to user select
            $.each(users, function (userIdx, user) {
                let name = (user.firstName ? user.firstName : "") + " " + (user.lastName ? user.lastName : "");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                name = user.login + (name == " " ? "" : " - " + name);

                let opt: IDropdownOption = { id: user.login, label: name, class: "users" };

                dropdownOptions.push(opt);
            });

            restConnection.getServer("group").done(function (allGroupsResult) {
                let groups = (allGroupsResult as XRGetGroup_AllGroups_GetGroupListAck).groups.sort(function (a, b) {
                    if (a.groupName < b.groupName) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                // add groups
                $.each(groups, function (groupIdx, group) {
                    let groupName = group.groupName;
                    let groupId = that.getGroupId(group);
                    let opt: IDropdownOption = { id: groupId, label: groupName, class: "groups" };
                    dropdownOptions.push(opt);
                });

                res.resolve(dropdownOptions);
            });
        });

        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSingleSelectDialog(
        selected: string,
        title: string,
        help: string,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string) => void,
        preSelectedUsers?: XRUserPermissionType[],
    ) {
        let that = this;

        that.dlg = $("<div>").appendTo("body");

        $('<span class="baseControlHelp">' + help + "</span>").appendTo(that.dlg);
        let ctrl = $("<div class='baseControl'>").appendTo(that.dlg);
        let input = $("<div class='form-control'>").appendTo(ctrl);
        this.showSingleSelect(
            input,
            showUsers,
            showGroups,
            (newSelection: string) => {
                selected = newSelection;
            },
            preSelectedUsers,
            selected,
        );

        that.dlg.dialog({
            autoOpen: true,
            title: title ? title : "Select",
            width: 716,
            height: 380,
            resizeStop: function () {
                that.resize();
            },
            modal: true,
            open: function () {
                ml.UI.pushDialog(that.dlg);
            },
            close: function () {
                ml.UI.popDialog(that.dlg);
                that.dlg.remove();
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        onSelect(selected);
                        that.dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        that.dlg.dialog("close");
                    },
                },
            ],
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private resize() {
        let height = this.dlg.height();
        let top = $(".ms-list", this.dlg).position().top;

        $(".ms-list", this.dlg).height(height - top);
    }
}
