import { IDHFControlDefinition, IDHFSection } from "../../../common/businesslogic/index";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { ICheckboxOptions } from "../../../common/businesslogic/FieldHandlers/Document/CheckBoxDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";

export { Checkbox };

class Checkbox extends BaseDHFSection<ICheckboxOptions> implements IDHFSection {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        return false;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
