import { app, globalMatrix, matrixSession } from "../../../globals";
import { IPanel } from "../Application";
import { HistoryTools } from "../Tools/ItemHistoryView";

export { DocumentPanel };

class DocumentPanel implements IPanel {
    title = "Document Status";
    constructor() {
        document.title = this.title + " - " + matrixSession.getProject();

        let ht = new HistoryTools();
        ht.showReadersDigest(app.itemForm);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
}
