import { InitializePluginManager } from "./PluginManager";
import { InitializePluginManagerDocuments } from "./PluginManagerDocuments";
import { InitializePluginManagerFormulas } from "./PluginManagerFormulas";
import { InitializeTasks } from "./Tasks";
import { InitializeTestManager } from "./TestManager";
import { InitializeTrainingManager } from "./TrainingManager";
import { InitializeFieldHandlers as InitializeFieldHandlers } from "./FieldHandlers/index";

export { InitializeBusinessLogic };

export * from "./BasicFunctions";
export * from "./Branching";
export * from "./DBCache";
export * from "./ItemConfiguration";
export * from "./MatrixReq";
export * from "./MatrixRequirementsAPI";
export * from "./MatrixSession";
export * from "./PluginManager";
export * from "./PluginManagerDocuments";
export * from "./PluginManagerFormulas";
export * from "./PushMessages";
export * from "./RestConnector";
export * from "./RestDB";
export * from "./Tasks";
export * from "./TestManager";
export * from "./TrainingManager";
export * from "./FieldHandlers/index";
export * from "./NotificationsBL";
export * from "./NotificationsCache";

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeBusinessLogic() {
    // Why: sets up field handlers.
    InitializeFieldHandlers();
    // Why: sets up the global plugins
    InitializePluginManager();
    // Why: sets up the global mDHF, registers it as a plugin.
    InitializePluginManagerDocuments();
    // Why: sets up the global mTasks
    InitializeTasks();
    // Why: sets up the global mTM, registers it as a plugin.
    InitializeTestManager();
    // Why: sets up the global ContextFrameManager.
    InitializeTrainingManager();
    // Why: sets up the global tableMath.
    InitializePluginManagerFormulas();
}
export { GateFieldHandler } from "./FieldHandlers/GateFieldHandler";
