import { globalMatrix, matrixSession } from "../../../globals";

// TODO: find a better name, it clearly does more than just replacing "<" to "&lt;"
/** helper to escape < into non-dangerous &lt; */
export class LT {
    /** prepares the text for storage in db and readonly display
     * @param code is the text
     * @param fieldId (or 0/null) is the field which can have this turned off
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    forDB(code: string, fieldId: number) {
        let config = globalMatrix.ItemConfig ? globalMatrix.ItemConfig.getFieldConfig(fieldId) : null;

        if (config && config.unsafeHtml) {
            return code;
        }

        if (code && !matrixSession?.isConfigClient()) {
            return code.replace(/&/g, "&amp;").replace(/</g, "&lt;");
        }
        return code;
    }

    /** prepares the text for editing in UI
     * @param code is the text
     * @param fieldId (or 0/null) is the field which can have this turned off
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    forUI(code: string, fieldId: number) {
        let config = fieldId && globalMatrix.ItemConfig ? globalMatrix.ItemConfig.getFieldConfig(fieldId) : null;

        if (config && config.unsafeHtml) {
            return code;
        }

        if (code && !matrixSession?.isConfigClient()) {
            return code.replace(/&lt;/g, "<").replace(/&amp;/g, "&").replace(/&gt;/g, ">");
        }

        return code;
    }
}
