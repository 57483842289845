import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { ITestStepsResultsConfig, TestManagerConfiguration } from "../TestManagerConfiguration";
import { BaseTableFieldHandler, ITableControlBaseParams } from "./BaseTableFieldHandler";
import { BaseValidatedTableFieldHandler } from "./BaseValidatedTableFieldHandler";

export { TestStepsResultFieldHandler };

class TestStepsResultFieldHandler extends BaseValidatedTableFieldHandler {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static UpdateFieldConfig(options: XRFieldTypeAnnotatedParamJson, testConfig: TestManagerConfiguration) {
        // This field handler gets it's configuration options from the test manager.
        const rconfig = testConfig.getTestStepsResultsConfig();
        for (let name in rconfig) {
            options[name] = rconfig[name as keyof ITestStepsResultsConfig];
        }
    }

    constructor(options: ITableControlBaseParams) {
        super(options);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_test_steps_result;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDataAsArray(dataIn: any[], fixData = false) {
        if (dataIn && fixData) {
            for (let row of dataIn) {
                // Each row has a property name for each column.
                for (const colId in row) {
                    let colDetails = this.getColumnByField(colId);
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (!colDetails && colId !== "human" && colId != "render") {
                        delete row[colId];
                    }
                }
            }
        }
        this.data = dataIn;
        this.validate();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    validate() {
        // TODO: validation for this kind of table is more difficult.
        // There is a property "passFailEditorConfig" on the options that needs to
        // be looked at.
    }
}
