/// <reference types="matrixrequirements-type-declarations" />
import { ILoggerTools } from "./MatrixLibInterfaces";

export { SERVER_LOG_LEVEL, LoggerTools };

enum SERVER_LOG_LEVEL {
    WEIRD_STATE = "WEIRD_STATE",
    BROKEN_STATE = "BROKEN_STATE",
}

type FunctionRenderHumanDate = (date: Date) => string;
type FunctionSanitize = (data: string) => string;

class LoggerTools implements ILoggerTools {
    private verbose: boolean = false;
    private lastLogMsg: string = "none";
    private logData: string[] = [];
    private logIdx: number = 0;
    private logSize: number = 50;
    private functionRenderHumanDate: FunctionRenderHumanDate;
    private functionSanitize: FunctionSanitize;

    constructor(functionRenderHumanDate: FunctionRenderHumanDate, sanitize?: FunctionSanitize) {
        this.functionRenderHumanDate = functionRenderHumanDate;
        this.functionSanitize = sanitize
            ? sanitize
            : // TODO: MATRIX-7555: lint errors should be fixed for next line
              // eslint-disable-next-line
              (d) => {
                  return DOMPurify.sanitize(d);
              };
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    log(id: string, msg: string) {
        if (!this.verbose && id === "debug") {
            return;
        }
        msg = this.functionSanitize(msg) + "";
        if (!msg) {
            return;
        }

        console.log(id + ":" + msg);

        if (this.lastLogMsg === msg) {
            this.logData[this.logIdx] = this.logData[this.logIdx] + ".";
        } else {
            this.logIdx++;
            if (this.logIdx === this.logSize) {
                this.logIdx = 0;
            }
            // this.logData[this.logIdx] = ml.UI.DateTime.renderHumanDate(new Date()) + " [" + id + "]: " + msg;
            this.logData[this.logIdx] = this.functionRenderHumanDate(new Date()) + " [" + id + "]: " + msg;
            this.lastLogMsg = msg;
        }
    }

    debug(message: string): void {
        this.log("debug", message);
    }

    info(message: string): void {
        this.log("info", message);
    }

    warning(message: string): void {
        this.log("warning", message);
    }

    error(message: string): void {
        this.log("error", message);
    }

    getLog(): string {
        let msg = "";
        for (let idx = this.logIdx + 1; idx < this.logIdx + this.logSize + 1; idx++) {
            if (this.logData[idx % this.logSize]) {
                msg += this.logData[idx % this.logSize] + "\n";
            }
        }
        return msg;
    }
}
