import { IPlugin, IPluginPanelOptions, IProjectPageParam, plugins } from "../../../common/businesslogic";
import { ml } from "../../../common/matrixlib";
import { app, globalMatrix, matrixSession } from "../../../globals";
import { NavigationBar } from "../../../init";
import { IDashboardLinkForAnalytics, IExternalPlugin } from "../../../../sdk/plugins/interfaces";
import React, { FC } from "react";
import { createRoot, Root } from "react-dom/client";
import { LinkListDisplay } from "./components/LinkListDisplay";
import { Container } from "./components/Container";

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function initialize() {}

export class Analytics {
    private mainContainer: JQuery;
    private root: Root | undefined;
    private previousUrl: string | undefined;

    constructor() {
        this.mainContainer = $("<div></div>").appendTo("#highlightContext");

        this.mainContainer.hide();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async open() {
        this.previousUrl = window.location.href;
        // Set the URL to the new page in the browser history
        history.pushState(null, "", globalMatrix.matrixBaseUrl + "/" + matrixSession.getProject() + "/ANALYTICS");

        //Hide the main content
        ["#sidebar", "#dragbar", "#main", "#contextframesizer", "#contextframe"].map((item) => $(item).hide());

        let root = createRoot(this.mainContainer[0]);
        //Cleanup the container for the new content

        this.mainContainer.show();

        //Render the tiles

        root.render(<Container></Container>);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public close() {
        // Show the main content that was previously hidden
        ["#sidebar", "#dragbar", "#main", "#contextframesizer", "#contextframe"].map((item) => $(item).show());
        // Cleanup the container for the new content
        this.root?.unmount();
        // Set the URL back to the previous page
        if (this.previousUrl !== undefined) {
            history.pushState(null, "", this.previousUrl);
        }
        this.mainContainer.hide();
    }
}
