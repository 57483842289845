import { FieldHandlerFactory, MR1 } from "../../businesslogic/index";
import { IDHFControlDefinitionValue, mDHF } from "../../businesslogic/index";
import { IBaseControlOptions } from "./BaseControl";
import { DocBaseImpl, ISignaturesInfo } from "./docBase.";
import { ml } from "./../../matrixlib";
import { XRGetUser_AllUsers_GetUserListAck, XRGetTodosAck, XRTodo } from "../../../RestResult";
import { UserControl } from "../Parts/UserControl";
import { IDropDownButtonOption, UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { ControlState, globalMatrix, matrixSession, restConnection, IItemGet, app, IItem } from "../../../globals";
import { ITableConfigColumn } from "../../businesslogic/FieldHandlers/Document/GenericTableDocHandler";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IDocSignOptions, ISignData, ISignComment, ISignature, ISignatureChange };
export { DocSignImpl };

interface IDocSignOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: {
        inlineHelp?: string;
    };
}

interface ISignData {
    rejectComments: ISignComment[];
    acceptComments: ISignComment[];
}

interface ISignComment {
    user: string;
    comment: string;
    createdAt: string;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.docSign = function (this: JQuery, options: IDocSignOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new DocSignImpl(this, options.fieldHandler as GenericFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);

    return this;
};

// TODO(modules): There is an ISignature here, and another in docReview.ts, missing field orgid. What to do?
interface ISignature {
    orgid: string; // originally user to sign from version 2.3 it can be different if it was changed using the proxy feature
    userid: string; // user id of the user who is supposed to sign
    signDate: string;
    signDateCustomer?: string;
    signaturefileid: string;
}

interface ISignatureChange {
    action: string;
    value: string;
}

class DocSignImpl extends DocBaseImpl {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected settings: IDocSignOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private data: ISignData;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private uiCtrl: JQuery;
    private static iHaveSignature = false;
    private static iUser = "";

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IDocSignOptions) {
        let that = this;

        this._root.append(super.createHelp(options));
        this.uiCtrl = $("<div class='baseControl'>").appendTo(this._root);

        //this.uiCtrl.append("<div style='color:red'>BETA</div>");

        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                // item the item containing the rest of the information
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        // changes shall not overwrite other people's changes
        this.needsLatest = true;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.data = this.parseValue(this.settings.fieldValue);

        if (this.data.rejectComments && this.data.rejectComments.length) {
            $.each(this.data.rejectComments, function (rcidx, rejectComment) {
                that.uiCtrl.append(
                    `<div style='color:red'>Document was rejected by <b>${
                        rejectComment.user
                    }</b> at <b>${ml.UI.DateTime.renderHumanDate(
                        new Date(rejectComment.createdAt),
                    )}</b>. Reason was:</div>`,
                );
                let reason = $(`<div'>${rejectComment.comment}</div>`).appendTo(that.uiCtrl);
                reason.highlightReferences();
            });
        } else {
            // document was not rejected
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let signatureStatus = DocBaseImpl.readSignatureInfo(this.settings.item);

            // render a signature table with current status if there is 1+ signature in document
            this.renderSignatureTable(
                signatureStatus,
                this.uiCtrl,
                "not yet signed",
                "you need to sign this document",
                "signed at",
            );

            // show signature box unless user needs to sign or this is a tooltip, print or other special view
            if (signatureStatus.needSignature) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (globalMatrix.ItemConfig.canSign(options.type)) {
                    this.showSignatureField(
                        signatureStatus,
                        this.uiCtrl,
                        $(this.uiCtrl.find("td")[0]).width(),
                        "Sign",
                        function (pwd: string) {
                            that.signDocument(pwd);
                        },
                        false,
                    );
                } else {
                    this._root.append("<div class='inlineHelp'>You have no rights to sign documents</div>");
                }
            }

            if (!that.isMe(DocSignImpl.iUser)) {
                DocSignImpl.iUser = matrixSession.getUser();
                restConnection.getServer("user").done(function (result) {
                    const allUsers = result as XRGetUser_AllUsers_GetUserListAck;
                    DocSignImpl.iHaveSignature = false;
                    for (let idx = 0; idx < allUsers.user.length; idx++) {
                        if (that.isMe(allUsers.user[idx].login)) {
                            DocSignImpl.iHaveSignature = allUsers.user[idx].signatureImage !== "";
                        }
                    }
                });
            }
        }
    }

    // initialize options
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync(currentItem?: IItemGet) {
        let that = this;

        return JSON.stringify(this.data);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {
        let width = Math.max(100, $(this.uiCtrl.find("td")[0]).width());
        $("input.signatureInfo", this.uiCtrl).width(width);
    }

    private parseValue(fieldVal: string): ISignData {
        if (fieldVal) {
            return <ISignData>JSON.parse(fieldVal);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return <ISignData>{
                rejectComments: [],
            };
        }
    }

    // try to get additional info from original DOC signature table. and than draw the UI
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected addSignMeaning(uiCtrl: JQuery) {
        let that = this;

        let itemId = app.getCurrentItemId();
        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            ml.Item.parseRef(itemId).type != "SIGN" ||
            !globalMatrix.ItemConfig.getDHFConfig().showOriginalSignMeaning
        ) {
            return;
        }

        let dhfFields = globalMatrix.ItemConfig.getFieldsOfType("dhf").map((field) => "" + field.field.id);
        // get the parent DOC
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        mDHF.getBaseDOCofSIGN(matrixSession.getProject(), that.settings.item)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done((theDoc: IItem) => {
                for (let field in theDoc) {
                    let meanings = mDHF.getSignatureMeanings() ? mDHF.getSignatureMeanings() : {};

                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (dhfFields.indexOf(field) != -1 && theDoc[field]) {
                        let dhfField = <IDHFControlDefinitionValue>JSON.parse(theDoc[field]);
                        if (
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            dhfField.type == "signaturebox" &&
                            dhfField.fieldValue &&
                            dhfField.ctrlConfig &&
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            (<any>dhfField.ctrlConfig).columns
                        ) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            let columns = <ITableConfigColumn[]>(<any>dhfField.ctrlConfig).columns;
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            let meaningColumn = columns.filter((c) => c.columnType == "type6");
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            let userColumn = columns.filter((c) => c.columnType == "type4");
                            if (meaningColumn.length && userColumn.length) {
                                let meaningColumnField = meaningColumn[0].field;
                                let userColumnField = userColumn[0].field;
                                let signTable = JSON.parse(dhfField.fieldValue);
                                for (let signDef of signTable) {
                                    let user = signDef[userColumnField];
                                    let meaning = signDef[meaningColumnField];
                                    let meaningHuman = meanings[meaning] ? meanings[meaning] : meaning;
                                    if (user && meaningHuman) {
                                        $.each($("tr", $(".signatureTable")), (rowIdx, row) => {
                                            let hasUser = false;
                                            $.each($("td", $(row)), (tdIdx, td) => {
                                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                                // eslint-disable-next-line
                                                if ($(td).text() == user) {
                                                    hasUser = true;
                                                }
                                            });
                                            if (hasUser) {
                                                let meaningTd = $(".OriginalSignMeaning", $(row));
                                                let current = $(meaningTd).text();
                                                current = (current ? current + ", " : "") + meaningHuman;
                                                $(meaningTd).text(current);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            })
            .fail(() => {
                ml.Logger.error("no original parent of DOC found, columns with signature meaning stays empty...");
            });
    }

    // render signature table (maybe with some extra info from DOC)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected renderSignatureTable(
        signatureInfo: ISignaturesInfo,
        uiCtrl: JQuery,
        notSigned: string,
        youSign: string,
        signedAt: string,
    ) {
        let that = this;

        if (signatureInfo.signatures.length === 0) {
            $(
                "<div style='margin-bottom: 12px;'><span class='searchResult'>no signatures required</span></div>",
            ).appendTo(uiCtrl);
            return;
        }
        let sigInfo = $("<div class='sigInfo'>").appendTo(uiCtrl);
        let statusTable = $("<table class='signatureTable table table-bordered'>");
        let ctrlContainer = $("<div class='baseControl'>");

        sigInfo.append(ctrlContainer.append(statusTable));
        let body = $("<tbody>");
        statusTable.append(body);

        let hasSignComment = false;
        for (let idx = 0; idx < signatureInfo.signatures.length; idx++) {
            let needSign = $("<td>").html("<span class='needsSignature'>" + notSigned + "</span>");
            if (this.isMe(signatureInfo.signatures[idx].userid) && !signatureInfo.signatures[idx].signDate) {
                needSign = $("<td>").html("<span class='needsSignature'>" + youSign + "</span>");
            }
            let dateCustomer = signatureInfo.signatures[idx].signDateCustomer;

            let tr = $("<tr>");

            let user = signatureInfo.signatures[idx].userid;
            let orgid = signatureInfo.signatures[idx].orgid;
            let userDisplayString = globalMatrix.ItemConfig.hasUserInfo(user) ? user : `<s>${user}</s>`;
            let userText = `<span title="${globalMatrix.ItemConfig.getFullName(user)}">${userDisplayString}</span>`;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (orgid && orgid != user) {
                userText += `<span class="proxyInfo">(proxy for  <span title="${globalMatrix.ItemConfig.getFullName(
                    orgid,
                )}">${orgid}</span>)</span>`;
            }
            if (globalMatrix.ItemConfig.getDHFConfig().showOriginalSignMeaning) {
                tr.append($(`<td class='OriginalSignMeaning'>`));
            }
            if (dateCustomer) {
                tr.append($("<td>").html(userText));
                let signed = $("<td>").appendTo(tr);
                signed.append($("<span>").html(signedAt + " " + dateCustomer));

                if (globalMatrix.ItemConfig.getDHFConfig().signedMeaning) {
                    let commentCol = $("<td class='commentCol'>").appendTo(tr);
                    if (that.data.acceptComments) {
                        let acceptComment = that.data.acceptComments.filter(
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            (comment) => comment.user == user && comment.comment,
                        );
                        if (acceptComment.length) {
                            hasSignComment = true;
                            commentCol.append($("<div>").html(acceptComment[acceptComment.length - 1].comment));
                        }
                    }
                }
            } else if (
                globalMatrix.ItemConfig.getDHFConfig().proxyRights &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                matrixSession.amIAllowedUser(globalMatrix.ItemConfig.getDHFConfig().proxyRights) &&
                that.settings.canEdit
            ) {
                let tdUser = $("<td>").html(
                    `<span>${userText}</span> <button data-proxy="${user}" class="proxy btn btn-xs" style="float:right">proxy</button>`,
                );
                tr.append(tdUser);
                tr.append(needSign);
                if (globalMatrix.ItemConfig.getDHFConfig().signedMeaning) {
                    let commentCol = $("<td class='commentCol'>").appendTo(tr);
                }
            } else {
                tr.append($("<td>").html(userText));
                tr.append(needSign);
                if (globalMatrix.ItemConfig.getDHFConfig().signedMeaning) {
                    $("<td class='commentCol'>").appendTo(tr);
                }
            }

            body.append(tr);
        }
        if (!hasSignComment) {
            $(".commentCol").hide();
        }
        // fill column with sign meaning from DOC

        if (globalMatrix.ItemConfig.getDHFConfig().showOriginalSignMeaning) {
            this.addSignMeaning(uiCtrl);
        }

        // handle proxy button
        $(".proxy").click(function (event) {
            let btn = $(event.delegateTarget);
            let user = btn.data("proxy");

            if (app.needsSave()) {
                ml.UI.showError("Cannot change user", "You need to save first.");
                return;
            }

            ml.UI.SelectUserOrGroup.showSingleSelectDialog(
                user,
                "Select Proxy",
                "Replaces required signature",
                true,
                false,
                (newUser) => {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (newUser == user) {
                        // nothing changed
                        return;
                    }
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (signatureInfo.signatures.map((info) => info.userid).indexOf(newUser) != -1) {
                        ml.UI.showError("User exists", "The same user cannot sign for two");
                        return;
                    }
                    let signatureFieldId = globalMatrix.ItemConfig.getFieldsOfType("signature", "SIGN")[0].field.id;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let update = { id: that.settings.item.id, onlyThoseFields: 1, onlyThoseLabels: 1 };
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let oldSign = that.settings.item[signatureFieldId];
                    let newSign = oldSign.replace(
                        `user="${user}" userId="${that.getUserId(user)}"`,
                        `user="${newUser}" userId="${that.getUserId(newUser)}"`,
                    );
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    (<any>update)["fx" + signatureFieldId] = newSign;

                    app.updateItemInDBAsync(update, "proxy change").done(function () {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        app.treeSelectionChangeAsync(that.settings.item.id);
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.removeNotifications(that.settings.item.id, user);
                    });
                },
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                null,
            );
        });
    }

    // remove signature notifications for one or more users
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected removeNotifications(itemId: string, user?: string) {
        let that = this;

        if (!globalMatrix.ItemConfig.getDHFConfig().fixNotifications) {
            return;
        }
        restConnection.getProject("todo?includeFuture=0&itemRef=" + itemId).done(function (allNotification) {
            let toDelete = (allNotification as XRGetTodosAck).todos.filter(
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (todo) => todo.action && todo.action.todoType == "needSignature" && (!user || user == todo.login),
            );
            that.removeNotificationsRec(toDelete, 0);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected removeNotificationsRec(toDelete: XRTodo[], idx: number) {
        let that = this;
        if (idx >= toDelete.length) {
            return;
        }
        restConnection.deleteServerAsync(matrixSession.getProject() + "/todo/" + toDelete[idx].todoId, {}).done(() => {
            that.removeNotificationsRec(toDelete, idx + 1);
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getUserId(login: string) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let user = globalMatrix.ItemConfig.getUserNames().filter((info) => info.login == login);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (user.length != 1) {
            return 0;
        }
        let userId = user[0].id;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (userId == -1) {
            // this is a user who has access only through a user group. get the real id
            $.each(globalMatrix.ItemConfig.getUserGroups(), function (ugIdx, ug) {
                $.each(ug.membership, function (mIdx, m) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (m.login == login) {
                        userId = m.userId;
                    }
                });
            });
        }
        return userId;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showSignatureField(
        signatureInfo: ISignaturesInfo,
        uiCtrl: JQuery,
        columnSize: number,
        btnName: string,
        onSign: (pwd: string) => void,
        templateApproval: boolean,
    ) {
        let that = this;

        if (
            !signatureInfo.needSignature ||
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.Zen ||
            this.settings.locked
        ) {
            return;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let needEnterUserName = matrixSession.getCustomerSetting("needEnterUserName") == "1";

        new UserControl().askForPassword(
            uiCtrl,
            btnName,
            !needEnterUserName,
            Math.max(100, columnSize),
            (name, pwd) => {
                if (!pwd) {
                    ml.UI.showError("You must supply a password to sign the document", "");
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                } else if (needEnterUserName && name != matrixSession.getUser()) {
                    ml.UI.showError("Please provide your user id", "");
                } else {
                    onSign(pwd);
                }
            },
        );
        if (!templateApproval) {
            if (globalMatrix.ItemConfig.getDHFConfig().signatureHint) {
                $(".signatureInfo button").html(
                    globalMatrix.ItemConfig.getDHFConfig().canReject ? "Reject/Sign*" : "Sign*",
                );
                uiCtrl.append(
                    `<div class="inlineHelp" style="margin-top:12px;font-size:smaller">* ${
                        globalMatrix.ItemConfig.getDHFConfig().signatureHint
                    }</div>`,
                );
            }
            if (
                globalMatrix.ItemConfig.getDHFConfig().canReject ||
                globalMatrix.ItemConfig.getDHFConfig().signedMeaning
            ) {
                // replace the sign button by a dropdown
                this.replaceSignButton();
            }
        }
    }

    // make the sign button a drop down menu with different options
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected replaceSignButton() {
        let that = this;
        let ddOptions: IDropDownButtonOption[] = [];
        if (
            globalMatrix.ItemConfig.getDHFConfig().signedMeaning &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            globalMatrix.ItemConfig.getDHFConfig().signedMeaning.length
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let meaning of globalMatrix.ItemConfig.getDHFConfig().signedMeaning) {
                ddOptions.push({
                    name: meaning,
                    click: () => {
                        that.signFromDropdown(meaning);
                    },
                });
            }
        } else {
            // normal sign
            ddOptions.push({
                name: "Sign",
                click: () => {
                    that.signFromDropdown();
                },
            });
        }
        if (globalMatrix.ItemConfig.getDHFConfig().canReject) {
            ddOptions.push({
                name: "Reject",
                click: () => {
                    if (globalMatrix.ItemConfig.getDHFConfig().rejectWithoutPass) {
                        that.rejectSign();
                    } else {
                        that.rejectSignWithPass();
                    }
                },
            });
        }

        $(".signatureInfo button").replaceWith(
            ml.UI.createDropDownButton($(".signatureInfo button").html(), ddOptions, false, "signBtn", true),
        );
        $("ul", $(".signatureInfo")).addClass("pull-right");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected rejectSignWithPass() {
        let pwd = $(".signatureInfo input[placeholder='password']").val();
        let name = $("input.signatureInfo").val();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let needEnterUserName = matrixSession.getCustomerSetting("needEnterUserName") == "1";
        if (!pwd) {
            ml.UI.showError("You must supply a password to reject the document", "");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (needEnterUserName && name != matrixSession.getUser()) {
            ml.UI.showError("Please provide your user id", "");
        } else {
            app.checkPassword(pwd)
                .done(() => {
                    this.rejectSign();
                })
                .fail(() => {
                    ml.UI.showError("Incorrect Password", "");
                });
        }
    }

    // ask user for comment and reject SIGN
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected rejectSign() {
        let that = this;

        let subj = $("<div>").richText({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Reject message",
            fieldValue: "",
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                autoEdit: true,
                autoFocus: true,
            },
        });

        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div style='width:100%;height:100%'>");

        ui.append(subj);

        ui.addClass("dlg-no-scroll");
        ui.removeClass("dlg-v-scroll");

        ml.UI.showDialog(
            dlg,
            "Reject SIGN",
            ui,
            -730,
            -470,
            [
                {
                    text: "Reject",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: async function () {
                        let text = await subj.getController().getValueAsync();
                        if (globalMatrix.ItemConfig.getDHFConfig().rejectNeedsComment && !text) {
                            ml.UI.showError("Please provide a comment.", "");
                            return false;
                        }
                        restConnection
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .putProject(`sign/${that.settings.item.id}`, { rejectSign: text ? text : "rejected" })
                            .done(() => {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                app.treeSelectionChangeAsync(that.settings.item.id);
                            });
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            false,
            false,
            () => {
                dlg.remove();
            },
            () => {
                $("input", subj).focus();
            },
            () => {},
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected signFromDropdown(meaning?: string) {
        let pwd = $(".signatureInfo input[placeholder='password']").val();
        let name = $("input.signatureInfo").val();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let needEnterUserName = matrixSession.getCustomerSetting("needEnterUserName") == "1";
        if (!pwd) {
            ml.UI.showError("You must supply a password to sign the document", "");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (needEnterUserName && name != matrixSession.getUser()) {
            ml.UI.showError("Please provide your user id", "");
        } else {
            this.signDocument(pwd, meaning);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected signDocument(pwd: string, meaning?: string) {
        let that = this;

        if (app.needsSave()) {
            ml.UI.showError("You need to save other changes first", "");
            return;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function addSignature() {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.signItemAsync(that.settings.item.id, pwd, meaning)
                .done(function (result) {
                    if (result && !result.ok) {
                        ml.UI.showError("Could not sign document", result.result);
                        return;
                    }
                    // get item with new signatures
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    app.getItemAsync(that.settings.id).done(function (item: IItemGet) {
                        // do automation stuff
                        let lastSignature = DocBaseImpl.readSignatureInfo(item).missingSignatures === 0;

                        MR1.triggerAfterSignature(item, lastSignature);

                        app.renderItem();
                    });
                })
                .fail(function (error) {
                    if (error) {
                        ml.UI.showError("Could not sign document", error);
                    } else {
                        // cancelled save
                    }
                });
        }

        if (!DocSignImpl.iHaveSignature) {
            ml.UI.showConfirm(
                4,
                {
                    title: "You have not uploaded a signature image! Do you want to sign the document without an image?",
                    ok: "Sign anyway",
                },
                function () {
                    addSignature();
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                function () {},
            );
        } else {
            addSignature();
        }
    }
}
