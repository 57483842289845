import { IPlugin, IProjectPageParam, plugins } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { SelectMode } from "../../../common/UI/Components/ProjectViewDefines";
import { ItemSelectionTools } from "../../../common/UI/Tools/ItemSelectionView";
import { app, globalMatrix, IItem, IReference, matrixSession } from "../../../globals";

export { initialize };

class MultiMove implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery;
    public isDefault = true;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {}
    async getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return [];
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    supportsControl() {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMenu(ul: JQuery) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (ml.Item.parseRef(this._item.id).isFolder) {
            let extras = globalMatrix.ItemConfig.getExtrasConfig();

            if (extras && (ml.JSON.isTrue(extras.moveIn) || (extras.moveIn === "admin" && matrixSession.isAdmin()))) {
                let moveIn = $('<li><a href="javascript:void(0)">Move In</a></li>').click(function () {
                    let st = new ItemSelectionTools();
                    st.showDialog({
                        selectMode: SelectMode.auto,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        linkTypes: [{ type: ml.Item.parseRef(that._item.id).type }],
                        selectionChange: function (newSelection: IReference[]) {
                            if (that.selectionContainsParent(newSelection)) {
                                ml.UI.showError("Bad selection", "cannot move a folder inside its own child folder");
                            } else {
                                //that.moveAllIn(newSelection, 0, that._item.id);
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                that.moveIn(newSelection, that._item.id);
                            }
                        },
                        getSelectedItems: async function () {
                            return [];
                        },
                    });
                });
                ul.append(moveIn);
            }
        }
    }

    private selectionContainsParent(newSelection: IReference[]): boolean {
        let selection: string[] = [];
        $.each(newSelection, function (idx, sel) {
            selection.push(sel.to);
        });
        return this.selectionContainsParentRec(selection);
    }

    private selectionContainsParentRec(itemIds: string[]): boolean {
        let that = this;
        let found: boolean = false;
        $.each(itemIds, function (idx, itemId) {
            if (itemId === that._item.id) {
                found = true;
            }
            if (ml.Item.parseRef(itemId).isFolder) {
                if (that.selectionContainsParentRec(app.getChildrenIds(itemId))) {
                    found = true;
                }
            }
        });
        return found;
    }

    private moveIn(newSelection: IReference[], target: string): void {
        let ids = newSelection.map(function (ref) {
            return ref.to;
        });
        app.moveItemsAsync(ids.join(","), target).done(function () {
            const project = matrixSession.getProject();

            if (project) {
                app.loadProject(project, app.getCurrentItemId());
            }
        });
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    plugins.register(new MultiMove());
}
