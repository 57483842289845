import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { EmptyFieldHandler };

class EmptyFieldHandler implements IFieldHandler {
    private fieldType: string;
    private config: XRFieldTypeAnnotatedParamJson;

    constructor(fieldTypeIn: string, configIn: XRFieldTypeAnnotatedParamJson) {
        this.fieldType = fieldTypeIn;
        this.config = configIn;
    }

    getFieldType(): string {
        return this.fieldType;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {}
    getData(): string | undefined {
        // undefined is semantically more correct for an "empty" field handler
        // than a string.
        return undefined;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {}
}
