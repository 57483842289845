import { ILinkInfo } from "./MatrixReq";
import { IStringStringArrayMap, globalMatrix } from "./../../globals";
import { INavigationBar } from "../UI/Components/index";
import { ITasksConfiguration } from "./Tasks";
import { IRiskParameter } from "../UI/Controls/riskCtrl2";
import {
    IFieldParameter,
    IDropDownConfig,
    IDHFConfig,
    IExtras,
    ILabelsConfig,
    IQMSConfig,
    qmsDefaultConfig,
    IPublishLegacy,
    IRiskConfig,
    ICategoryGroups,
    IACL,
    ACL_SETTING,
    ITraceConfig,
    IContextPageConfig,
    IMailConfig,
    ISearchConfig,
    ILabelLockConfig,
    ITestConfig,
    ISmartTextConfig,
    ICategorySetting,
    ITraceConfigRule,
    ICleanup,
    ITraceConfigDetails,
    ITestRuleAuto,
    ITestRuleManual,
    ITestConfigTablesColumn,
    smartTextConfigSetting,
    mailConfigSetting,
    IImportConfig,
    IDropdownOption,
} from "../../ProjectSettings";
import {
    XRFieldType,
    XRUserPermissionType,
    XRGetProject_ProjectInfo_ProjectInfo,
    XRGroupPermissionType,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRSettingType,
    XRCategoryAndSettingListType,
    XRGetProject_CategoryList_GetProjectStructAck,
    XRCategoryExtendedType,
    XRPluginSetting,
    XRUserType,
} from "../../RestResult";
import { IJSONTools, ILoggerTools } from "../matrixlib/MatrixLibInterfaces";
import { FieldDescriptions } from "./FieldDescriptions";
import { DefaultCategorySettingNames } from "../../admin/lib/categories/CategorySetting";

export type {
    ISettingMapString,
    ISettingMapStringArray,
    ISettingMapJSON,
    ICategoryConfig,
    ICategoryConfigMap,
    XRFieldTypeAnnotated,
    XRFieldTypeAnnotatedParamJson,
    XRFieldTypeAnnotatedParamJsonLinkType,
    IFieldsOfType,
    IDropDownInfo,
};
export { ItemConfiguration };

interface ISettingMapString {
    [key: string]: string;
}
interface ISettingMapStringArray {
    [key: string]: string[];
}
interface ISettingMapJSON {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: {};
}

interface ICategoryConfig {
    fieldList: XRFieldTypeAnnotated[];
    label: string;
    downLinksRequired: string[];
    downLinksOptional: string[];
    enable: string[];
}
interface ICategoryConfigMap {
    [key: string]: ICategoryConfig;
}

interface XRFieldTypeAnnotated extends XRFieldType {
    parameterJson?: XRFieldTypeAnnotatedParamJson;
}
interface XRFieldTypeAnnotatedParamJson extends IFieldParameter {
    linkTypes?: XRFieldTypeAnnotatedParamJsonLinkType[];
}

interface XRFieldTypeAnnotatedParamJsonLinkType {
    required: boolean;
    type: string;
}
interface IFieldsOfType {
    category: string;
    field: XRFieldTypeAnnotated;
}

interface IDropDownInfo {
    id: string;
    label: string;
    value: IDropDownConfig;
}

class ItemConfiguration {
    // TODO: it seems that we have category information in configuration and also in variable
    // settings, with SLIGHTLY DIFFERENT FIELDS, WHAT THE HECK?
    // (one has shortLabel, the other doesnt).
    private configuration: ICategoryConfigMap = {};
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: XRGetProject_ProjectInfo_ProjectInfo;
    private settingsString: ISettingMapString = {};
    private settingsJSON: ISettingMapJSON = {};

    private users: XRUserPermissionType[] = []; // server
    private userList: XRUserPermissionType[] = []; // cleaned up
    private userGroups: XRGroupPermissionType[] = [];

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private timewarpDate: string;

    private logger: ILoggerTools;
    private json: IJSONTools;

    constructor(logger: ILoggerTools, json: IJSONTools) {
        this.logger = logger;
        this.json = json;
    }

    public isConfigured(): boolean {
        return this.getCategories() && this.getCategories().length > 0;
    }

    public addUsers(userPermission: XRUserPermissionType[], groupPermission: XRGroupPermissionType[]): void {
        this.userGroups = groupPermission;
        this.users = userPermission;
        this.userList = [];
        if (userPermission) {
            for (let idx = 0; idx < this.users.length; idx++) {
                let ignore = false;
                if (this.users[idx].permission === 3) {
                    // 3 is an admin without write write access
                    ignore = true;
                }
                for (let ex = 0; !ignore && ex < this.userList.length; ex++) {
                    if (
                        this.userList[ex].login.toLowerCase() === this.users[idx].login.toLowerCase() ||
                        this.userList[ex].id === this.users[idx].id
                    ) {
                        // somethings wrong here...
                        this.logger.log(
                            "warning",
                            "Ignoring duplicate user... login:" + this.userList[ex].id + " ID:" + this.users[idx].login,
                        );
                        ignore = true;
                    }
                }
                if (!ignore) {
                    this.userList.push({
                        id: this.users[idx].id,
                        login: this.users[idx].login.toLowerCase(),
                        email: this.users[idx].email,
                        permission: this.users[idx].permission,
                        firstName: this.users[idx].firstName,
                        lastName: this.users[idx].lastName,
                    });
                }
            }
        }
    }

    public getUserInfo(login: string): XRUserPermissionType {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let theUser: XRUserPermissionType = null;

        for (let user of this.getUserNames()) {
            if (user.login.toLowerCase() === login.toLowerCase()) {
                theUser = user;
            }
        }

        return theUser;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getCombinedName(user: XRUserPermissionType | XRUserType) {
        let name = (user.firstName ? user.firstName : "") + " " + (user.lastName ? user.lastName : "");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return user.login + (name == " " ? "" : " - " + name);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getFullName(login: string) {
        let user = this.getUserInfo(login);
        if (user === null) {
            return login;
        }
        return this.getCombinedName(user);
    }

    public groupIdToName(groupId: number): string {
        return `g_${groupId}_g`;
    }

    public hasGroupInfo(group: string): boolean {
        return (
            this.getUserGroups().findIndex((g) => {
                return this.groupIdToName(g.groupId) === group.toLowerCase();
            }) > -1
        );
    }

    // {login} may have been deleted or doesn't exist.
    public hasUserInfo(login: string): boolean {
        return this.getUserInfo(login) !== null;
    }

    public getUserIds(): string[] {
        return this.getUserNames().map(function (user) {
            return user.login;
        });
    }

    public getEmail(user: string): string {
        let email = "";

        for (let otherUser of this.getUserNames()) {
            if (otherUser.login.toLowerCase() === user.toLowerCase()) {
                email = otherUser.email;
            }
        }

        return email;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public activateTimewarp(date: string) {
        this.timewarpDate = date;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getTimeWarp() {
        return this.timewarpDate;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public isAfterTimeWarp(date: string) {
        return this.timewarpDate && new Date(date) > new Date(this.timewarpDate);
    }

    public hasWriteAccess(user: string): boolean {
        if (!user || this.timewarpDate) {
            return false;
        }

        let permission = this.getPermission(user);

        // -1 super admin, 2 read write, 3 admin
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return permission == -1 || permission == 2 || permission == 3;
    }

    private getPermission(user: string): number {
        let permission: number = -1; // (in case the user does not exists, it's a super admin)
        // get permission directly for user
        for (let idx = 0; idx < this.users.length; idx++) {
            if (this.users[idx].login.toLowerCase() === user.toLowerCase()) {
                permission = this.users[idx].permission;
            }
        }

        // now go through all groups, maybe it's better
        for (let ug of this.getUserGroups()) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (ug.membership.map((member) => member.login.toLowerCase()).indexOf(user.toLowerCase()) != -1) {
                // user is in this group, let's see if we can bump him up
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (ug.permission == 3) {
                    permission = 3;
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (ug.permission == 2 && permission != 3) {
                    permission = 2;
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (ug.permission == 1 && permission <= 0) {
                    permission = 1;
                }
            }
        }
        return permission;
    }

    public getUserNames(sorted?: boolean): XRUserPermissionType[] {
        let users = <XRUserPermissionType[]>this.json.clone(this.userList);
        // add users from user groups if they are not directly in
        for (let ug of this.getUserGroups()) {
            for (let m of ug.membership) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (users.map((user) => user.login).indexOf(m.login) == -1) {
                    users.push({
                        id: -1,
                        login: m.login,
                        email: m.email,
                        permission: -1,
                        firstName: m.firstName,
                        lastName: m.lastName,
                    });
                }
            }
        }
        if (sorted) {
            users.sort(function (a, b) {
                if (a.login < b.login) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }
        return users;
    }

    public getUserGroups(): XRGroupPermissionType[] {
        return this.userGroups;
    }

    /**
     * getValidUserOptions returns an array of option values that describe
     * the users and groups available as values for a user field in the
     * database (fields of type {@link FieldDescriptions.Field_user}).
     *
     * @param showUsers - pass true to include users
     * @param showGroups - pass true to include groups
     * @param preSelectedUsers - if present, the list of users will be limited
     *     to this subset and the full user list will not be consulted to
     *     construct the return value.
     * @param possiblyDeletedUserGroupNames - if present, a comma-separated
     *     list of user and group names. The returned options will include
     *     these values, and mark deleted user and groups as disabled.
     * @returns An array of {@link IDropdownOptions} configured according to
     *     the input parameters and the users and groups of the current project.
     */
    getValidUserOptions(
        showUsers: boolean,
        showGroups: boolean,
        preSelectedUsers?: XRUserPermissionType[],
        possiblyDeletedUserGroupNames?: string,
    ): IDropdownOption[] {
        let that = this;
        let options: IDropdownOption[] = [];
        let users: XRUserPermissionType[] = preSelectedUsers ? preSelectedUsers : this.getUserNames();
        let groups = this.getUserGroups().sort((a, b) => {
            return a.groupName < b.groupName ? -1 : 1;
        });

        // if {possiblyDeletedUsernames} are not found in {users}, we want to add them.
        const isGroup = /g_([0-9])+_g/;
        let names = possiblyDeletedUserGroupNames ? possiblyDeletedUserGroupNames.split(",") : [];
        names.forEach((name) => {
            if (!this.hasUserInfo(name)) {
                if (!isGroup.test(name)) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    users.push(<any>{
                        id: 0,
                        login: name,
                        email: "unknown",
                        permission: 0,
                        firstName: "",
                        lastName: "",
                        deleted: true,
                    });
                } else if (!this.hasGroupInfo(name)) {
                    // We know match found a group because isGroup.test(name) returned true.
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    const groupId = parseInt(name.match(isGroup)[1]);
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    groups.push(<any>{
                        groupName: name,
                        permission: 0,
                        groupId: groupId,
                        membership: [],
                        deleted: true,
                    });
                }
            }
        });

        if (showUsers) {
            // sort users alphabetically
            users = users.sort((a, b) => {
                return a.login < b.login ? -1 : 1;
            });

            // add to user select
            users.forEach((user) => {
                // Deleted users have strikethrough text, and are disabled from selection.
                // We only display their login, not their full name.
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                const deletedUser = (<any>user).deleted === true;
                const labelText = deletedUser ? user.login : this.getCombinedName(user);
                options.push({
                    id: user.login,
                    label: labelText,
                    class: "users",
                    disabled: deletedUser,
                    strikethrough: deletedUser,
                });
            });
        }
        if (showGroups) {
            groups.forEach((group) => {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                const deletedGroup = (<any>group).deleted === true;
                let groupName = group.groupName;
                let groupId = deletedGroup ? groupName : that.groupIdToName(group.groupId);
                options.push({
                    id: groupId,
                    label: groupName,
                    class: "groups",
                    disabled: deletedGroup,
                    strikethrough: deletedGroup,
                });
            });
        }
        return options;
    }

    // TODO(modules): This is a performance hack. Better if private.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public addGroupMember(gid: number, user: string) {
        let gs = this.userGroups.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (g) => g.groupId == gid && g.membership.map((u) => u.login).indexOf(user) == -1,
        );
        if (gs.length) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            gs[0].membership.push(<any>{ login: user });
        }
    }
    // TODO(modules): This is a performance hack. Better if private.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public removeGroupMember(gid: number, user: string) {
        let gs = this.userGroups.filter(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (g) => g.groupId == gid && g.membership.map((u) => u.login).indexOf(user) != -1,
        );
        if (gs.length) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            gs[0].membership = gs[0].membership.filter((m) => m.login != user);
        }
    }

    // TODO(modules): This is a performance hack. Better if private.
    public addSettings(s: XRGetProject_ProjectInfo_ProjectInfo | XRGetProject_ProjectSettingAll_GetSettingAck): void {
        let that = this;

        this.settings = <XRGetProject_ProjectInfo_ProjectInfo>s;
        this.settingsString = {};
        this.settingsJSON = {};
        if (s.settingList) {
            for (let setting of s.settingList) {
                that.settingsString[setting.key] = setting.value;
                if (setting.value && setting.value.indexOf("{") !== -1 && setting.value.indexOf("<") !== 0) {
                    // assume it a json
                    let val = this.json.fromString(setting.value);
                    if (val.status === "ok") {
                        that.settingsJSON[setting.key] = val.value;
                    }
                }
            }
        }
    }
    public getSettings(): XRSettingType[] {
        return this.settings.settingList;
    }
    public getSetting(s: string): string {
        return this.settingsString[s];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getSettingJSON(s: string, def?: {}): {} {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.settingsJSON[s] ? this.settingsJSON[s] : def;
    }
    public getDropDowns(dropdownId?: string): IDropDownInfo[] {
        let that = this;
        let dropdowns: IDropDownInfo[] = [];

        for (let key of Object.keys(this.settingsJSON)) {
            let setting = <IDropDownConfig>that.settingsJSON[key];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (setting.options && (!dropdownId || dropdownId == key)) {
                dropdowns.push({
                    id: key,
                    label: key,
                    value: setting,
                });
            }
        }

        return dropdowns;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getTasksConfig() {
        return <ITasksConfiguration>this.getSettingJSON("task_config");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getDHFConfig() {
        return <IDHFConfig>this.getSettingJSON("dhf_config");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getExtrasConfig() {
        let extras = this.getSettingJSON("extras");
        return <IExtras>(extras ? extras : {});
    }
    public getLabelsConfig(): ILabelsConfig | undefined {
        return <ILabelsConfig>this.getSettingJSON("labels");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getIncludeConfig() {
        let conf = this.getSettingJSON("compose");
        return <IImportConfig>(conf
            ? conf
            : {
                  copies: {
                      importMasters: [],
                      lockLabel: "",
                  },
                  includes: {
                      importMasters: [],
                      lockLabel: "",
                  },
              });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getQMSConfig() {
        // get qms config (as project setting, handle 2.1 and earlier setting in category setting)
        let setting = <IQMSConfig>this.getSettingJSON("qms_config");
        if (!setting) {
            setting = qmsDefaultConfig; // as in 2.1 and earlier
        }
        for (let p of setting.publications) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (p.publisher == "_") {
                let legacy = <IPublishLegacy>this.getCategorySetting(p.toCategory, "publish");
                p.publisher = legacy ? legacy.publisher : "";
            }
        }

        return setting;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getRiskConfig() {
        return <IRiskConfig>this.getSettingJSON("risk_config");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getCategoryGroupConfig() {
        return <ICategoryGroups>this.getSettingJSON("category_groups");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getACLConfig() {
        return <IACL>this.getSettingJSON(ACL_SETTING);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getTraceConfig() {
        return <ITraceConfig>this.getSettingJSON("trace_config");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getNavigationBarConfig() {
        return <INavigationBar>this.getSettingJSON("nav_config");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getContextPagesConfig() {
        return <IContextPageConfig>this.getSettingJSON("project_help");
    }
    public getMailConfig(): IMailConfig {
        return <IMailConfig>this.getSettingJSON(mailConfigSetting);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getSearchConfig() {
        return <ISearchConfig>this.getSettingJSON("search_config");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getLabelLockConfig() {
        return <ILabelLockConfig>this.getSettingJSON("lockingLabels");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getTestConfig() {
        return <ITestConfig>this.getSettingJSON("xtc_config");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public setSettingJSON(key: string, valueJSON: {}): void {
        this.settingsString[key] = JSON.stringify(valueJSON);
        this.settingsJSON[key] = valueJSON;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getSmartText() {
        return <ISmartTextConfig>this.getSettingJSON(smartTextConfigSetting);
    }

    public addCategorySetting(categorySetting: XRCategoryAndSettingListType): void {
        if (!this.settings || !this.settings.categorySettingList) {
            return;
        }
        this.settings.categorySettingList.push(categorySetting);
    }

    public getCategorySettings(category: string): XRSettingType[] {
        if (!this.settings || !this.settings.categorySettingList) {
            return [];
        }
        for (let idx = 0; idx < this.settings.categorySettingList.length; idx++) {
            if (
                this.settings.categorySettingList[idx].categoryShort === category &&
                this.settings.categorySettingList[idx].settingList
            ) {
                return this.settings.categorySettingList[idx].settingList;
            }
        }
        return [];
    }

    // return the setting for a plugin or null if plugin or setting does not exist
    // TODO(modules): is this ever called?
    public getPluginSetting(pluginId: number, setting: string): string {
        /*
         * pluginSettingsList: [
            {
                pluginId: 101,
                settings: [ {
                    setting: "serverType",
                    value: "medical",
                    encrypted: false
                }, {
                setting: "baseUrl",
                    value: "https://matrixtest.atlassian.net",
                    encrypted: false
                } ]
            }
        ]
         */
        if (!this.settings || !this.settings.pluginSettingsList) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }

        for (let idx = 0; idx < this.settings.pluginSettingsList.length; idx++) {
            let ps = this.settings.pluginSettingsList[idx];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (ps.pluginId == pluginId) {
                for (let jdx = 0; jdx < ps.settings.length; jdx++) {
                    if (ps.settings[jdx].setting === setting) {
                        return ps.settings[jdx].value;
                    }
                }
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    public getPluginSettings(): XRPluginSetting[] {
        return this.settings.pluginSettingsList;
    }
    // return tuples {category, field} of all categories which use a type
    // used for tisk traceability in DHF
    public getFieldsOfType(fieldType: string, categoryType?: string): IFieldsOfType[] {
        let hits: IFieldsOfType[] = [];
        if (!this.settings) {
            return hits;
        }
        for (let idx = 0; idx < this.settings.categorySettingList.length; idx++) {
            let category = this.settings.categorySettingList[idx].categoryShort;
            if (!categoryType || categoryType === category) {
                let cc = this.getItemConfiguration(category);
                if (cc) {
                    let fields = cc.fieldList;
                    for (let fdx = 0; fdx < fields.length; fdx++) {
                        if (fields[fdx].fieldType === fieldType) {
                            //    var jconfig = this.json.fromString(fields[fdx].parameter);
                            hits.push({ category: category, field: fields[fdx] });
                        }
                    }
                }
            }
        }
        return hits;
    }

    public getCategorySetting(category: string, setting: string | DefaultCategorySettingNames): ICategorySetting {
        let catSettings = this.getCategorySettings(category);
        for (let idx = 0; idx < catSettings.length; idx++) {
            if (catSettings[idx].key === setting) {
                let jconfig = this.json.fromString(catSettings[idx].value);

                if (jconfig.status === "ok") {
                    // apparently parsing went well
                } else if (jconfig.status === "error") {
                    this.logger.log(
                        "error",
                        "The category setting '" + catSettings[idx].key + "' has an invalid value. Ignoring it.",
                    );
                } else {
                    this.logger.log("warning", "The category setting '" + catSettings[idx].key + "' is empty.");
                }
                return jconfig.value;
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    public getCategories(noFolders?: boolean): string[] {
        let catgories = Object.keys(this.configuration);
        if (noFolders) {
            catgories.splice(catgories.indexOf("FOLDER"), 1);
        }
        return catgories;
    }

    public getCategoryLabel(category: string): string {
        if (this.configuration[category]) {
            return this.configuration[category].label;
        }
        return "";
    }

    public getCategoryId(category: string): string {
        let cd = this.settings.categoryList.categoryExtended.filter(function (cat) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return cat.category.shortLabel == category;
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (cd.length == 1) {
            return "" + cd[0].category.id;
        }

        return "";
    }

    // links for 1.5 and earlier (configured as category setting)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getDownLinkTypes(category: string, required: boolean) {
        if (required) {
            return this.configuration[category].downLinksRequired;
        }
        return this.configuration[category].downLinksOptional;
    }

    // links for 1.5 and earlier (configured as category setting)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getUpLinkTypes(category: string, required: boolean) {
        let up: string[] = [];
        for (let key in this.configuration) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (key && key != category && this.configuration[key]) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (required && this.configuration[key].downLinksRequired.indexOf(category) != -1) {
                    up.push(key);
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (!required && this.configuration[key].downLinksOptional.indexOf(category) != -1) {
                    up.push(key);
                }
            }
        }
        return up;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public addCategories(config: XRGetProject_ProjectInfo_ProjectInfo | XRGetProject_CategoryList_GetProjectStructAck) {
        for (
            let idx = 0;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            config.categoryList.categoryExtended != undefined && idx < config.categoryList.categoryExtended.length;
            idx++
        ) {
            this.addCategory(config.categoryList.categoryExtended[idx]);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public init(config: XRGetProject_ProjectInfo_ProjectInfo) {
        this.addCategories(config);
        this.addSettings(config);
        this.addUsers(config.userPermission, config.groupPermission);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canEdit(category: string) {
        return this.canDo(category, "edit");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canEditTitle(category: string) {
        return this.canDo(category, "rename");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canMove(category: string) {
        return this.canDo(category, "move");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canCreate(category: string) {
        return this.canDo(category, "create");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canDelete(category: string) {
        return this.canDo(category, "delete");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canModifyLabels(category: string) {
        return this.canDo(category, "label");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canSign(category: string) {
        return this.canDo(category, "sign");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public canReport(category: string) {
        return this.canDo(category, "report");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private canDo(category: string, task: string) {
        if (!this.configuration[category]) {
            return false;
        }
        if (!this.configuration[category].enable) {
            return true;
        } // no limitation specified for this user
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.configuration[category].enable.indexOf(task) != -1) {
            return true;
        }

        return false;
    }

    private addCategory(config: XRCategoryExtendedType): void {
        this.configuration[config.category.shortLabel] = {
            fieldList: [],
            label: config.category.label,
            downLinksRequired: [],
            downLinksOptional: [],
            enable: config.enable,
        };

        let fieldList = <XRFieldTypeAnnotated[]>config.fieldList.field;
        if (!fieldList) {
            fieldList = [];
        }

        for (let idx = 0; idx < fieldList.length; idx++) {
            let jconfig = this.json.fromString(fieldList[idx].parameter);

            if (jconfig.status === "ok") {
                // apparently parsing went well
            } else if (jconfig.status === "error") {
                this.logger.log(
                    "error",
                    "The field with id '" + fieldList[idx].id + "' has an invalid value. Ignoring it.",
                );
            } else {
                // quite normal
            }

            fieldList[idx].parameterJson = <XRFieldTypeAnnotatedParamJson>jconfig.value;

            this.configuration[config.category.shortLabel].fieldList.push(fieldList[idx]);
            if (fieldList[idx].fieldType === FieldDescriptions.Field_links) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (fieldList[idx].parameterJson && fieldList[idx].parameterJson.linkTypes) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    for (let l = 0; l < fieldList[idx].parameterJson.linkTypes.length; l++) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let dl = fieldList[idx].parameterJson.linkTypes[l];

                        if (this.json.isTrue(dl.required)) {
                            this.configuration[config.category.shortLabel].downLinksRequired.push(dl.type);
                        } else {
                            this.configuration[config.category.shortLabel].downLinksOptional.push(dl.type);
                        }
                    }
                }
            }
        }
    }

    public getItemConfiguration(category: string): ICategoryConfig {
        return this.configuration[category];
    }

    public getFieldId(category: string, fieldLabel: string): number {
        let cc = this.getItemConfiguration(category);
        if (!cc) {
            return 0;
        }
        let fields = cc.fieldList;
        for (let idx = 0; idx < fields.length; idx++) {
            if (fields[idx].label.toLowerCase() === fieldLabel.toLowerCase()) {
                return fields[idx].id;
            }
        }
        return 0;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getFields(category: string) {
        let cc = this.getItemConfiguration(category);
        if (!cc) {
            return null;
        }
        return cc.fieldList;
    }

    public getFieldByName(category: string, name: string): XRFieldTypeAnnotated {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let fields = this.getFields(category).filter(function (field) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return field.label && field.label.toLowerCase() == name.toLowerCase();
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (fields.length == 1) {
            return fields[0];
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return undefined;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getFieldById(category: string, fieldId: number) {
        let cc = this.getItemConfiguration(category);
        if (!cc) {
            return null;
        }
        let fields = cc.fieldList.filter(function (field) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return field.id == fieldId;
        });
        return fields.length ? fields[0] : null;
    }

    public getFieldConfig(fieldId: number): XRFieldTypeAnnotatedParamJson {
        // The fieldId is unique across categories, therefore this code works properly.
        for (let category of this.getCategories()) {
            let field = this.getFieldById(category, fieldId);
            if (field) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return field.parameterJson;
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getFieldName(fieldId: number) {
        for (let category of this.getCategories()) {
            let field = this.getFieldById(category, fieldId);
            if (field) {
                return field.label;
            }
        }
        return "";
    }

    public getFieldType(category: string, fieldId: number): string {
        let cc = this.getItemConfiguration(category);
        if (!cc) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }
        let fields = cc.fieldList;
        for (let idx = 0; idx < fields.length; idx++) {
            // note this is ==: sometimes it is an int sometimes a string
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (fields[idx].id == fieldId) {
                return fields[idx].fieldType;
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    // gets required or optional up or downlink categories for a given category
    // it uses the project setting for the trace configuration if existing,
    // the category setting (1.5 and earlier) otherwise
    public getLinkTypes(category: string, down: boolean, required: boolean): string[] {
        let tc = <ITraceConfig>this.getTraceConfig();
        if (!tc) {
            // use 1.5 and before rules
            if (down) {
                return this.getDownLinkTypes(category, required);
            } else {
                return this.getUpLinkTypes(category, required);
            }
        }

        // get the up/down rule from project setting
        let updown: ITraceConfigRule[];
        for (let rule of tc.rules) {
            if (rule.category === category) {
                updown = down ? rule.down_rules : rule.up_rules;
            }
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!updown) {
            // no rules..
            return [];
        }
        let result: string[] = [];

        // rules exist get all required or option links
        for (let updownRule of updown) {
            if (updownRule.rule === "must_have" && updownRule.any_of && required) {
                for (let any of updownRule.any_of) {
                    // add to must have
                    if (result.indexOf(any) === -1) {
                        result.push(any);
                    }
                }
            } else if (updownRule.rule === "can_have" && updownRule.any_of && !required) {
                for (let any of updownRule.any_of) {
                    // add to can have
                    if (result.indexOf(any) === -1) {
                        result.push(any);
                    }
                }
            }
        }

        return result;
    }

    // gets required or optional up or downlink categories for a given category
    // including reason why they should / could exist
    // it uses the project setting for the trace configuration if existing,
    // the category setting (1.5 and earlier) otherwise
    public getLinkInfo(category: string, down: boolean, required: boolean, groupByRule: boolean): ILinkInfo[] {
        let tc = <ITraceConfig>this.getTraceConfig();
        if (!tc) {
            let links: string[] = [];
            // use 1.5 and before rules
            if (down) {
                links = this.getDownLinkTypes(category, required);
            } else {
                links = this.getUpLinkTypes(category, required);
            }
            let result: ILinkInfo[] = [];
            for (let idx = 0; idx < links.length; idx++) {
                result.push({ category: links[idx], reason: required ? "required" : "optional" });
            }
            return result;
        }

        // get the up/down rule from project setting
        let updown: ITraceConfigRule[];
        for (let rule of tc.rules) {
            if (rule.category === category) {
                updown = down ? rule.down_rules : rule.up_rules;
            }
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!updown) {
            // no rules..
            return [];
        }
        let linkInfo: ILinkInfo[] = [];

        // rules exist get all required or optional links
        for (let updownRule of updown) {
            if (updownRule.rule === "must_have" && updownRule.any_of && required) {
                if (groupByRule) {
                    linkInfo.push({ category: updownRule.any_of, reason: updownRule.name });
                } else {
                    for (let anys of updownRule.any_of) {
                        // add to must have
                        linkInfo.push({ category: anys, reason: updownRule.name });
                    }
                }
            } else if (updownRule.rule === "can_have" && updownRule.any_of && !required) {
                if (groupByRule) {
                    linkInfo.push({ category: updownRule.any_of, reason: updownRule.name });
                } else {
                    for (let anys of updownRule.any_of) {
                        // add to can have
                        linkInfo.push({ category: anys, reason: updownRule.name });
                    }
                }
            }
        }

        return linkInfo;
    }

    public getMitigations(): IStringStringArrayMap {
        let risk_config = <IRiskConfig>this.getRiskConfig();

        // get the mitigations from the project setting
        let global: string[] = [];
        if (risk_config && risk_config.mitigationTypes) {
            global = risk_config.mitigationTypes.map(function (mt) {
                return mt.type;
            });
        }
        // for each field, check if there's a field setting, if not use the global setting
        let mitCats: IStringStringArrayMap = {};
        for (let riskFieldInfo of this.getFieldsOfType("risk2")) {
            mitCats[riskFieldInfo.category] = [];
            let fieldConfig = <IRiskParameter>riskFieldInfo.field.parameterJson;
            if (fieldConfig && fieldConfig.riskConfig) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                mitCats[riskFieldInfo.category] = fieldConfig.riskConfig.mitigationTypes.map((mt) => mt.type);
            } else {
                mitCats[riskFieldInfo.category] = global;
            }
        }

        return mitCats;
    }

    /** return cleanup rules, if there's a project setting that wins, if there's no rules or it's disabled it returns -1 */
    public getCleanupRules(): ICleanup {
        return <ICleanup>this.getSettingJSON("htmlCleanup");
    }
}
