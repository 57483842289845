import { IPanel } from "../Application";
import { ItemControl, IItemControlOptions } from "../Components/ItemForm";
import { Zen } from "../Components/Zen";

export { ItemPanel };

class ItemPanel implements IPanel {
    private itemForm: ItemControl;
    private zen: Zen;
    title = "";
    constructor(options: IItemControlOptions) {
        this.itemForm = new ItemControl(options);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.zen = new Zen(options.item);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {
        // in case we are in zen mode, back to normal
        this.zen.stopZen();
        this.itemForm.destroy();
    }

    getItemForm(): ItemControl {
        return this.itemForm;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    toggleZen() {
        this.zen.toggleZen();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async load() {
        await this.itemForm.load();
    }
}
