import {
    IItemIdParts,
    IReference,
    IReferenceChange,
    IItem,
    IItemGet,
    IItemPut,
    app,
    matrixSession,
} from "./../../globals";
import { globalMatrix } from "./../../globals";
import { IItemTools, ISimpleItemTools } from "./MatrixLibInterfaces";
import { SimpleItemTools } from "./SimpleItemTools";
export { ItemTools };

class ItemTools implements IItemTools {
    private simpleItemTools: ISimpleItemTools;

    constructor() {
        this.simpleItemTools = new SimpleItemTools();
    }

    // extract different parts from item ids, like version, category, ...
    parseRef(itemRef: string): IItemIdParts {
        return this.simpleItemTools.parseRef(itemRef, matrixSession.getProject() || "", globalMatrix.matrixBaseUrl);
    }

    getCreator(item: IItem): string {
        return this.simpleItemTools.getCreator(item);
    }

    getLastEditor(item: IItem): string {
        return this.simpleItemTools.getLastEditor(item);
    }

    // render a list of item ids, to show up as <b>ID</b> name, <b>ID</b> name,
    refListToDisplayString(inputItems: IReference[] | null, prefix: string, shorten?: number): string {
        return this.simpleItemTools.refListToDisplayString(inputItems, prefix, (ref) => app.getItemTitle(ref), shorten);
    }

    renderLink(itemId: string, itemTitle?: string | null, newWindow?: boolean): JQuery {
        const realTitle = itemTitle ? itemTitle : app.getItemTitle(itemId);
        return this.simpleItemTools.renderLink(itemId, realTitle, newWindow);
    }

    // update and changes to reference lists
    updateReferences(
        oldReferences: IReference[],
        newReferences: IReference[],
        fromId: string | null,
        toId: string | null,
    ): IReferenceChange[] {
        return this.simpleItemTools.updateReferences(oldReferences, newReferences, fromId, toId);
    }

    // clone item from IItemGet to IItemPut
    clone(item: IItemGet, copyLabels: boolean): IItemPut {
        return this.simpleItemTools.clone(item, copyLabels);
    }

    sort(a: string, b: string): number {
        return this.simpleItemTools.sort(a, b, matrixSession.getProject() || "", globalMatrix.matrixBaseUrl);
    }
}
