export { User };

class User {
    private element: JQuery;
    private resultBox: JQuery;

    constructor(el: string) {
        this.element = $(el);
        this.resultBox = $("<div>");
        this.resultBox.css("color", "red");

        this.element.parent().append(this.resultBox);
        this.element.keyup(() => this.validate());
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isOK() {
        return this.validate();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private validate() {
        let name: string = this.element.val();
        if (name === "") {
            this.resultBox.html("The user id is required and cannot be empty");
            this.resultBox.show();
            return false;
        }
        if (name.length < 1 || name.length > 30) {
            this.resultBox.html("The user id must be at least 1 and at most 30 characters long");
            this.resultBox.show();
            return false;
        }
        if (/^[a-zA-Z0-9_.-]+$/.test(name)) {
            this.resultBox.hide();
            return true;
        }

        this.resultBox.html("The user id can only consist of letters, dots, digits, dash and underscores");
        this.resultBox.show();
        return false;
    }
}
