/**
 * This class maintains a list of searches to be executed in order to fill DOC sections.
 *
 * The searches are executed one after the other. This prevents the client from spamming
 * the server and allows the client to show progress.
 *
 */

export abstract class SelectSearchQueue {
    protected static verifySearchQueue: (() => JQueryDeferred<unknown>)[] = [];

    /** this function can be used if the user changes the selection in the tree to avoid unnecessary searches.
     *
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static resetSearchQueue() {
        SelectSearchQueue.verifySearchQueue = [];
    }
    static launchTimer: number;

    /** adds a search to the queue.
     *
     * if there's no new search in the next 100ms, the searches are launched.
     * the idea is that the GUI is rendered completely before running the searches.
     * so that spinning wheels can indicate the needed action.
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static addToSearchQueue(search: () => JQueryDeferred<unknown>) {
        window.clearTimeout(SelectSearchQueue.launchTimer);
        SelectSearchQueue.verifySearchQueue.push(search);
        // console.log("ADDED to search queue " + SelectSearchQueue.verifySearchQueue.length)
        SelectSearchQueue.launchTimer = window.setTimeout(() => {
            // console.log("STARTED SEARCHING")
            SelectSearchQueue.runSearches();
        }, 100);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static runSearches() {
        // get first search
        let search = SelectSearchQueue.verifySearchQueue.shift();
        if (search) {
            // if there's one execute it and launch the next

            // console.log("START search left: " + SelectSearchQueue.verifySearchQueue.length)
            search().always(() => {
                // ignore any errors, it's the callers duty to deal with that

                // console.log("DONE do next: ")
                window.setTimeout(() => {
                    // let the caller update the UI and run the next
                    SelectSearchQueue.runSearches();
                }, 1);
            });
        }
    }
}
