import { XRGetProject_Todos_GetTodosAck, XRGetTodosAck, XRTodo } from "../../RestResult";
import { restConnection } from "../../globals";
import { NotificationsCache } from "./NotificationsCache";

export class NotificationsBL {
    static NoticationCache: NotificationsCache = new NotificationsCache();

    //
    /**
     * Returns the message of the notification. either the message is plain text or some formatted json object
     * @param todo
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getMessage(todo: XRTodo) {
        if (!todo || !todo.action || !todo.action.text) {
            return "";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (todo.action.text[0] != "{") {
            return todo.action.text;
        }
        try {
            let json = JSON.parse(todo.action.text);
            if (json.message) {
                return json.message;
            }
        } catch (e) {}
        return todo.action.text;
    }
    /**
     * Returns the field of the notification. either the message is plain text or some formatted json object: the anchor is a place in the UI
     * @param todo
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getField(todo: XRTodo) {
        if (!todo || !todo.action || !todo.action.text) {
            return null;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (todo.action.text[0] != "{") {
            return null;
        }
        try {
            let json = JSON.parse(todo.action.text);
            if (json.field) {
                return json.field;
            }
        } catch (e) {}
        return null;
    }
    //
    /**
     *  either the message is plain text or some formatted json object: the reply is a reference to another todo - for which this is a replyTo
     * @param todo
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getReply(todo: XRTodo) {
        if (!todo || !todo.action || !todo.action.text) {
            return null;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (todo.action.text[0] != "{") {
            return null;
        }
        try {
            let json = JSON.parse(todo.action.text);
            if (json.replyTo) {
                return json.replyTo;
            }
        } catch (e) {}
        return null;
    }

    /**
     * Create a notification for a list of users on a specific item in a project. The notification will be due to a specific date
     * @param users
     * @param project
     * @param item
     * @param text
     * @param type
     * @param atDate
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static async createNotification(
        users: string[],
        project: string,
        item: string,
        text: string,
        type: string,
        atDate: Date,
    ): Promise<XRTodo[]> {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let todoDetails: any = {
            text: text,
            logins: users.join(","),
        };
        if (atDate) {
            todoDetails.atDate = atDate.toISOString();
        }
        if (type) {
            todoDetails.todoType = type;
        }
        return <XRTodo[]>await restConnection.postServer(project + "/todo/" + item, todoDetails);
    }

    /**
     * Remove a notification by its id
     * @param project
     * @param todoId
     * @param deleteThem
     */
    static async deleteNotificationId(project: string, todoId: number, deleteThem: boolean): Promise<void> {
        await restConnection.deleteServerAsync(project + "/todo/" + todoId, deleteThem ? { hardDelete: "yes" } : {});
        return;
    }

    /**
     * Remove a notification
     * @param notification Notif to remove
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static async deleteNotification(notification: XRTodo) {
        return NotificationsBL.deleteNotificationId(notification.projectShort, notification.todoId, false);
    }

    /**
     * Return all notifications (for all projects)
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static async getAllNotifications() {
        let allNotifications = <XRGetProject_Todos_GetTodosAck>await restConnection.getServer("all/todo", true);
        return allNotifications;
    }

    /**
     * Return all notifications for a specific project. The notifications are divided into two groups: for now and for later
     * @param project
     */
    static async getGetNotificationsNowAndFuture(
        project: string,
    ): Promise<{ todosForLater: XRTodo[]; todosForNow: XRTodo[] }> {
        let allNotifications = <XRGetTodosAck>(
            await restConnection.getServer(project + "/todo?includeDone=1&includeFuture=1", true)
        );
        return {
            todosForNow: allNotifications.todos.filter(function (notification) {
                return !notification.future;
            }),
            todosForLater: allNotifications.todos
                .filter(function (notification) {
                    return notification.future;
                })
                .sort(function (a, b) {
                    let da = new Date(a.createdAt).getTime();
                    let db = new Date(b.createdAt).getTime();
                    return da > db ? 1 : -1;
                }),
        };
    }

    /**
     * Return all notifications for a specific project and a specific item
     * @param project
     * @param currentItemId
     */
    static async getAllNotificationForItem(project: string, currentItemId: string): Promise<XRGetTodosAck> {
        let result = <XRGetTodosAck>(
            await restConnection.getServer(project + "/" + "todo?includeFuture=1&itemRef=" + currentItemId, true)
        );
        return result;
    }
}
