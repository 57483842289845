import { app, globalMatrix, IItem, matrixSession } from "../../../globals";
import { mDHF } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { IPanel } from "../Application";

export { MyDocsPanel };

class MyDocsPanel implements IPanel {
    private control: JQuery;
    title = "Signatures";

    constructor() {
        this.control = app.itemForm;
        document.title = this.title + " - " + matrixSession.getProject();
        this.render();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render() {
        let outerbody = $("<div class='panel-body-v-scroll fillHeight' style='border:none'>").appendTo(this.control);
        this.control.prepend(ml.UI.getPageTitle(this.title));

        let body = $("<div class='panel-body'>").appendTo(outerbody);

        let markAsTemplate = ""; // 'empty' search
        let markAsTemplateFields = globalMatrix.ItemConfig.getFieldsOfType("markAsTemplate", "SIGN");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (markAsTemplateFields.length == 1) {
            markAsTemplate =
                ' or (category=SIGN and "' +
                markAsTemplateFields[0].field.label +
                '"~"' +
                matrixSession.getUser() +
                '" )';
        }

        app.getNeedlesAsync(
            'needsignature="' + matrixSession.getUser() + '"' + markAsTemplate,
            false,
            false,
            "*",
            false,
            true,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ).done(function (results: IItem[]) {
            let needsSign: IItem[] = [];
            let needsApprove: IItem[] = [];

            if (!results || results.length === 0) {
                body.append("<h2>Currently you do not need to sign documents in this project</h2>");
                return;
            }

            $.each(results, function (ridx, result) {
                let docsStatus = mDHF.getDocumentStatus(result);
                if (docsStatus.isSignNeedingTemplateMyApproval) {
                    needsApprove.push(result);
                } else if (docsStatus.isSignNeedingSignature) {
                    needsSign.push(result);
                }
            });

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (needsSign.length === 0 && needsApprove.length == 0) {
                body.append("currently you do not need to sign documents in this project");
                return;
            }

            if (needsSign.length) {
                body.append("<h2>Documents which I need to sign</h2>");
                let toSign = $("<ul>").appendTo(body);

                $.each(needsSign, function (ridx, result) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    toSign.append($("<li>").append(ml.Item.renderLink(result.id, result.title)));
                });
            }
            if (needsApprove.length) {
                body.append("<h2>Templates which I need to approve</h2>");
                let toSign = $("<ul>").appendTo(body);

                $.each(needsApprove, function (ridx, result) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    toSign.append($("<li>").append(ml.Item.renderLink(result.id, result.title)));
                });
            }
        });
    }
}
