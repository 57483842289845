import diff from "@matrixreq/htmldiff";

export const DATA_HTMLDIFF_ID = "data-htmldiff-id";

export function diffHtml(
    oldHtml: string,
    newHtml: string,
    categories: string[] = [],
    skipDomManipulations?: boolean,
): string {
    const diffResult = diff(
        prepareHtml(oldHtml, categories, skipDomManipulations),
        prepareHtml(newHtml, categories, skipDomManipulations),
    );

    if (skipDomManipulations) {
        return diffResult;
    }

    return enhanceResultHtml(diffResult);
}

export function prepareHtml(html: string, categories: string[], skipDomManipulations?: boolean): string {
    if (skipDomManipulations) {
        return removeNewLinesBetweenTags(html);
    }

    const withSmartLinks = wrapSmartLinks(removeNewLinesBetweenTags(html), categories);

    return prepareDOMElements(withSmartLinks);
}

function removeNewLinesBetweenTags(html: string): string {
    return html.replace(/>(\s+)</g, "><");
}

export function wrapSmartLinks(html: string, categories: string[]): string {
    if (categories.length === 0) {
        return html;
    }

    // regex to catch item ids
    // stolen from highlighting logic, see server/Clouds/client/js/vendor/highlight/jquery.highlight.js
    // (?=[^"]*(?:"[^"]*"[^"]*)*$) - this part is responsible for ignoring item ids inside the quotes (html attributes)
    const regex = new RegExp(`((F-)?(${categories.join("|")})-[1-9]+[0-9]*(\\+|!)?)(?=[^"]*(?:"[^"]*"[^"]*)*$)`, "g");

    return html.replace(regex, `<smart-link ${DATA_HTMLDIFF_ID}='$1'>$1</smart-link>`);
}

// DOMParser and XMLSerializer only exist in the browser
// if we want to export it in the serverSDK we would have to refactor this method
export function prepareDOMElements(html: string): string {
    const parser = new DOMParser();
    let htmlDoc = parser.parseFromString(html, "text/html");
    htmlDoc = prepareDates(htmlDoc);
    htmlDoc = prepareC3Charts(htmlDoc);
    htmlDoc = prepareLists(htmlDoc);
    htmlDoc = prepareSections(htmlDoc);
    htmlDoc = prepareImages(htmlDoc);

    return serialiseHtmlElement(htmlDoc.body.children[0]);
}

function prepareC3Charts(htmlDoc: Document): Document {
    const charts = htmlDoc.getElementsByClassName("c3");

    for (let i = 0; i < charts.length; i++) {
        const chartEl = charts[i];
        const htmlDiffId = chartEl.id || i;

        chartEl.setAttribute(DATA_HTMLDIFF_ID, `chart-${htmlDiffId}`);
    }

    // tooltip containers are not rendered anyway, but in some cases (e.g. not full deletion of the chart)
    // may lead to the issues
    const c3Tooltips = htmlDoc.getElementsByClassName("c3-tooltip-container");
    Array.from(c3Tooltips).forEach((tooltip) => tooltip.remove());

    return htmlDoc;
}

// .panel-body elements can come in combination with .panel-heading elements.
// changing the tag from "div" to "section" helps algorithm to better differentiate between them
function prepareSections(htmlDoc: Document): Document {
    const panelBodies = htmlDoc.getElementsByClassName("panel-body");

    for (let i = 0; i < panelBodies.length; i++) {
        const chartEl = panelBodies[i];
        changeTagName(chartEl, "section");
    }

    return htmlDoc;
}

function prepareDates(htmlDoc: Document): Document {
    const dates = htmlDoc.getElementsByClassName("commentDate");

    for (let i = 0; i < dates.length; i++) {
        const element = dates[i];
        const date = element.getAttribute("data-cd");

        if (date) {
            element.setAttribute(DATA_HTMLDIFF_ID, date);
        }
    }

    return htmlDoc;
}

function prepareLists(htmlDoc: Document): Document {
    const listItems = htmlDoc.querySelectorAll("ul li");

    for (let i = 0; i < listItems.length; i++) {
        const listItem = listItems[i];

        // prioritize manually set data-htmldiff-attribute over everything else
        // but still wrap all the content with .highlight-diff element to make sure it looks appropriate
        if (listItem.getAttribute(DATA_HTMLDIFF_ID) !== null) {
            continue;
        }

        let updated = false;
        const itemIdElements = listItem.getElementsByClassName("itemId");
        const linkElements = listItem.getElementsByTagName("a");

        if (itemIdElements.length === 1) {
            listItem.setAttribute(DATA_HTMLDIFF_ID, itemIdElements[0].textContent || "");
            updated = true;
        }

        if (linkElements.length === 1) {
            listItem.setAttribute(DATA_HTMLDIFF_ID, linkElements[0].href);
            updated = true;
        }

        if (updated) {
            listItem.innerHTML = `<span class="highlight-diff">${listItem.innerHTML}</span>`;
        }
    }

    return htmlDoc;
}

function prepareImages(htmlDoc: Document): Document {
    const images = htmlDoc.getElementsByTagName("img");

    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const imageUrl = new URL(image.src);
        imageUrl.searchParams.delete("uncache");
        image.src = imageUrl.toString();
    }

    return htmlDoc;
}

export function enhanceResultHtml(html: string): string {
    const parser = new DOMParser();
    let htmlDoc = parser.parseFromString(html, "text/html");

    markEmptyTables(htmlDoc);

    return serialiseHtmlElement(htmlDoc.body.children[0]);
}

function markEmptyTables(htmlDoc: Document): void {
    const tables = htmlDoc.getElementsByTagName("table");

    Array.from(tables).forEach((table) => {
        const tableCells = table.getElementsByTagName("td");
        const emptyCells = Array.from(tableCells).filter((tableCell) => isTableCellEmpty(tableCell));

        if (tableCells.length === emptyCells.length) {
            table.classList.add("htmldiff__empty-table");
        }
    });
}

function isTableCellEmpty(tableCell: Element): boolean {
    const isEmpty = tableCell.childNodes.length === 0;

    if (isEmpty) {
        return true;
    }

    // also consider cell empty if all of its children are "del" elements
    const containsOnlyElements = tableCell.children.length === tableCell.childNodes.length;
    return containsOnlyElements && Array.from(tableCell.children).filter((el) => el.tagName !== "DEL").length === 0;
}

function changeTagName(element: Element, newTagName: string): void {
    const newEl = document.createElement(newTagName);

    newEl.className = element.className || "";
    newEl.id = element.id || "";

    const attributes = element.getAttributeNames();
    attributes.forEach((attribute) => {
        newEl.setAttribute(attribute, element.getAttribute(attribute) || "");
    });

    newEl.innerHTML = element.innerHTML || "";

    element.parentNode?.replaceChild(newEl, element);
}

function serialiseHtmlElement(element: Element): string {
    return (
        new XMLSerializer()
            .serializeToString(element)
            // getting rid of the xmlns attribute
            .replace(' xmlns="http://www.w3.org/1999/xhtml"', "")
    );
}
