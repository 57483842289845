import { IRestResult, globalMatrix, IRestParam, IStringMap, matrixSession, app } from "../../globals";
import { HistoryTools } from "../UI/Tools/ItemHistoryView";
import { ml } from "./../matrixlib";
import { EImportMode } from "../../common/businesslogic/ComponentImport";

export type {
    IRestConfig,
    IRestTimer,
    IJcxhr,
    IResponseJson,
    IFileParam,
    IFileUploadProgress,
    IFileUploadResult,
    IError224,
    IError224Field,
};
export { RestConnector };

interface IRestConfig {
    server: string;
}
interface IRestTimer {
    start: number;
    end?: number;
    status?: number;
    command?: string;
    type?: string;
}
interface IJcxhr {
    status: number;
    responseText: string;
    responseJSON: IResponseJson;
    displayError: string;
    statusText?: string;
}
interface IResponseJson {
    category: string;
    detailsList: string[];
    displayError?: string;
    code?: string;
}
interface IFileParam {
    name: string;
}
interface IFileUploadProgress {
    position?: number;
    loaded?: number;
    totalSize?: number;
    total?: number;
}
interface IFileUploadResult {
    fileId: string;
    fileFullPath: string;
    key: string;
}
interface IError224 {
    fields: IError224Field[];
}
interface IError224Field {
    fieldId: number;
    beforeCleanup: string;
    afterCleanup: string;
}

class RestConnector {
    private restServer: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private restServerProject: string;
    private timer: IRestTimer = { start: 0, end: 0, command: "", type: "" };
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private keepAlive: number;

    constructor(config: IRestConfig) {
        this.restServer = config.server;
    }

    setProject(projectName: string): void {
        this.restServerProject = this.restServer + "/" + projectName;
    }

    getProject(cmd: string, ignoreErrors?: boolean, ignoreFilters?: boolean): JQueryDeferred<IRestResult> {
        let labelFilter = ml.LabelTools.getFilter();
        if (!ignoreFilters && labelFilter && labelFilter.length > 0) {
            let filter = "?";
            if (cmd.indexOf("?") !== -1) {
                // there is already at least one parameter: so just add another
                filter = "&";
            }
            filter += "filter=" + labelFilter;
            cmd += filter;
        }
        if (globalMatrix.ItemConfig.getTimeWarp()) {
            let timewarp = "?";
            if (cmd.indexOf("?") !== -1) {
                // there is already at least one parameter: so just add another
                timewarp = "&";
            }
            timewarp += "atDate=" + globalMatrix.ItemConfig.getTimeWarp();
            cmd += timewarp;
        }

        return this.get(this.restServerProject + "/" + cmd, ignoreErrors);
    }

    getServer(cmd: string, noRetry?: boolean): JQueryDeferred<IRestResult> {
        return this.get(this.restServer + "/" + cmd, noRetry);
    }

    postServer(cmd: string, param?: IRestParam, payload?: boolean): JQueryDeferred<IRestResult> {
        return this.post(this.restServer + "/" + cmd, param, payload);
    }

    postProject(cmd: string, param: IRestParam, payload?: boolean): JQueryDeferred<IRestResult> {
        return this.post(this.restServerProject + "/" + cmd, param, payload);
    }

    postProjectJson(cmd: string, data: unknown): JQueryDeferred<IRestResult> {
        return this.postJson(this.restServerProject + "/" + cmd, data);
    }

    putServer(cmd: string, param: IRestParam, asPayload?: boolean): JQueryDeferred<IRestResult> {
        return this.put(this.restServer + "/" + cmd, param, asPayload);
    }

    putProject(cmd: string, param: IRestParam, itemId?: string): JQueryDeferred<IRestResult> {
        let labelFilter = ml.LabelTools.getFilter();
        if (labelFilter && labelFilter.length > 0) {
            param["filter"] = labelFilter;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.put(this.restServerProject + "/" + cmd, param, null, itemId);
    }

    deleteProjectAsync(cmd: string, param: IRestParam, asString?: boolean): JQueryDeferred<IRestResult> {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.deleteRestAsync(this.restServerProject + "/" + cmd, param, asString);
    }

    deleteServerAsync(cmd: string, param: IRestParam, asString?: boolean): JQueryDeferred<IRestResult> {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.deleteRestAsync(this.restServer + "/" + cmd, param, asString);
    }

    // file up and download
    download(cmd: string, params?: string[] | IStringMap): void {
        ml.Logger.log("info", "Downloading: " + this.restServerProject + "/" + cmd);

        let iframe = $('<iframe width=1 height=1 frameborder=0 style="display:none">');

        iframe.appendTo("body");

        let form = $('<form action="' + this.restServerProject + "/" + cmd + '" method="get"></form>');
        if (params) {
            $.each(params, function (index, value) {
                form.append($("<input name='" + index + "'>").val(value));
            });
        }
        iframe.contents().find("body").append(form);
        form.submit();
    }

    getFile(cmd: string, dataType?: string): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();

        ml.Logger.log("info", "GET FILE: " + this.restServerProject + "/" + cmd);

        $.ajaxSetup({ cache: false }); // avoid IE to cache rest calls
        $.ajax({ type: "get", url: that.restServerProject + "/" + cmd, dataType: dataType })
            .done(function (data) {
                ml.Logger.log("debug", "RESPONSE: " + "retrieved file from restServer...");
                res.resolve(data);
            })
            .fail(function (jqxhr, textStatus, error) {
                that.handleError(jqxhr, textStatus, error, "GET FILE: " + that.restServerProject + "/" + cmd)
                    .done(function () {
                        // user signed in again
                        $.ajax({ type: "get", url: that.restServerProject + "/" + cmd })
                            .done(function (data) {
                                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                res.resolve(data);
                            })
                            .fail(function (jqxhr, textStatus, error) {
                                ml.UI.showError("Could not get file!", ml.UI.getDisplayError(jqxhr, textStatus, error));
                            });
                    })
                    .fail(function () {
                        res.reject();
                    });
            });
        $.ajaxSetup({ cache: true });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    uploadFileProjectAsync(
        file: IFileParam,
        progress: (p: IFileUploadProgress) => void,
    ): JQueryDeferred<IFileUploadResult> {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.uploadFileServerAsync(file, progress, this.restServerProject, null, "/file");
    }

    fetchFileAsync(url: string, progress: (p: IFileUploadProgress) => void): JQueryDeferred<IFileUploadResult> {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.uploadFileServerAsync(null, progress, this.restServerProject, { url: url }, "/file");
    }

    uploadFileCustomerAsync(
        file: IFileParam,
        progress: (p: IFileUploadProgress) => void,
    ): JQueryDeferred<IFileUploadResult> {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.uploadFileServerAsync(file, progress, this.restServer + "/all", null, "/file");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    importProjectAsync(file: IFileParam, progress: (p: IFileUploadProgress) => void, params: {}): any {
        return this.uploadFileServerAsync(file, progress, this.restServer + "/", params, "");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    uploadFileServerAsync(
        file: IFileParam,
        progress: (p: IFileUploadProgress) => void,
        target: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        params: {},
        urlSuffix: string,
    ): JQueryDeferred<IFileUploadResult> {
        let that = this;

        let formData = new FormData();
        if (file) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            formData.append("file", <any>file);
        }
        if (params) {
            $.each(params, function (parName, parVal) {
                formData.append(parName, parVal);
            });
        }
        ml.Logger.log("info", file ? `upload FILE: ${file.name}` : `fetch ${JSON.stringify(params)}`);
        let res = $.Deferred();
        let url = target ? target + urlSuffix : globalMatrix.matrixRestUrl + "/all" + urlSuffix;

        $.ajax({
            url: url, //Server script to process data
            type: "POST",
            xhr: function () {
                // Custom XMLHttpRequest
                let myXhr: JQueryXHR = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    // Check if upload property exists
                    myXhr.upload.addEventListener("progress", progress, false);
                }
                return myXhr;
            },
            success: function (data, textStatus, jqXHR) {
                res.resolve(data);
            },
            error: function (jqxhr, textStatus, error) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (that.isTimeout(<any>jqxhr)) {
                    ml.Logger.log("info", "Trying to reconnect...");
                    matrixSession
                        .signInAfterTimeout()
                        .done(function () {
                            that.uploadFileServerAsync(file, progress, target, params, urlSuffix).done(
                                function (result) {
                                    res.resolve(result);
                                },
                            );
                        })
                        .fail(function () {
                            let details =
                                jqxhr &&
                                jqxhr.responseJSON &&
                                jqxhr.responseJSON.detailsList &&
                                jqxhr.responseJSON.detailsList.length > 0 &&
                                jqxhr.responseJSON.detailsList[0]
                                    ? " Details " + jqxhr.responseJSON.detailsList[0]
                                    : "";

                            res.reject("Failed to reconnect. Status:" + textStatus + ". Error was:" + error + details);
                        });
                } else {
                    let details =
                        jqxhr &&
                        jqxhr.responseJSON &&
                        jqxhr.responseJSON.detailsList &&
                        jqxhr.responseJSON.detailsList.length > 0 &&
                        jqxhr.responseJSON.detailsList[0]
                            ? " Details " + jqxhr.responseJSON.detailsList[0]
                            : "";

                    res.reject("Upload failed. Status:" + textStatus + ". Error was:" + error + details);
                }
            },
            complete: function (response) {
                ml.Logger.log("info", response.responseText);
            },
            // Form data
            data: formData,
            dataType: "json",
            //Options to tell jQuery not to process data or worry about content-type.
            cache: false,
            contentType: false,
            processData: false,
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    convertExcelProjectAsync(file: IFileParam, progress: (p: IFileUploadProgress) => void): JQueryDeferred<string> {
        return this.convertExcelServerAsync(file, progress);
    }

    convertExcelServerAsync(file: IFileParam, progress: (p: IFileUploadProgress) => void): JQueryDeferred<string> {
        let formData = new FormData();
        if (file) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            formData.append("file", <any>file);
        }

        ml.Logger.log("info", `convert FILE: ${file.name}`);
        let res = $.Deferred();
        let url = this.restServerProject + "/excelxml";

        $.ajax({
            url: url, //Server script to process data
            type: "POST",
            xhr: function () {
                // Custom XMLHttpRequest
                let myXhr: JQueryXHR = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    // Check if upload property exists
                    myXhr.upload.addEventListener("progress", progress, false);
                }
                return myXhr;
            },
            success: function (data, textStatus, jqXHR) {
                res.resolve(data);
            },
            error: function (jqxhr, textStatus, error) {
                let details =
                    jqxhr &&
                    jqxhr.responseJSON &&
                    jqxhr.responseJSON.detailsList &&
                    jqxhr.responseJSON.detailsList.length > 0 &&
                    jqxhr.responseJSON.detailsList[0]
                        ? " Details " + jqxhr.responseJSON.detailsList[0]
                        : "";

                res.reject("Upload failed. Status:" + textStatus + ". Error was:" + error + details);
            },
            complete: function (response) {
                ml.Logger.log("info", "completed conversion of excel");
            },
            // Form data
            data: formData,
            dataType: "text",
            //Options to tell jQuery not to process data or worry about content-type.
            cache: false,
            contentType: false,
            processData: false,
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    isTimeout(jqxhr: IJcxhr): boolean {
        if (jqxhr.status !== 403) {
            return false;
        }
        if (!jqxhr.responseText) {
            return false;
        }
        if (
            jqxhr.responseJSON &&
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            jqxhr.responseJSON.displayError == "Unauthorized PUT without authentication for change"
        ) {
            // CSFR token missing
            return true;
        }
        if (
            jqxhr.responseJSON &&
            jqxhr.responseJSON.displayError &&
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            jqxhr.responseJSON.displayError.indexOf("session is null") == 0
        ) {
            // CSFR token missing
            return true;
        }
        if (
            jqxhr.responseText.indexOf(
                "session is null, exception in authorization decoding session is null, no authorization header",
            ) !== -1
        ) {
            // session expired
            return true;
        }
        if (
            jqxhr.responseText.indexOf(
                "session is null, exception in authorization decoding session is null, no authorization header",
            ) !== -1
        ) {
            // google auth expired
            return true;
        }
        return false;
    }
    isGatewayTimeout(jqxhr: IJcxhr): boolean {
        if (jqxhr.status !== 504) {
            return false;
        }
        if (!jqxhr.responseText) {
            return false;
        }
        if (jqxhr.responseText.indexOf("Gateway Time-out") !== -1) {
            return true;
        }
        return false;
    }
    // error handling with automatic reconnect
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    handleError(
        jqxhr: IJcxhr,
        textStatus: string,
        error: string,
        cmd: string,
        param?: IRestParam,
        itemId?: string,
    ): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();
        // sometimes error does not arrive as json but only as text
        if (jqxhr.responseText && !jqxhr.responseJSON) {
            try {
                jqxhr.responseJSON = JSON.parse(jqxhr.responseText);
            } catch (ex) {
                // well this is no JSON
            }
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (jqxhr && jqxhr.status == 422 && jqxhr.responseJSON && (<IError224>(<unknown>jqxhr.responseJSON)).fields) {
            let errors: string[] = [];
            for (let errorField of (<IError224>(<unknown>jqxhr.responseJSON)).fields) {
                console.log(JSON.stringify(errorField));
                let fieldName = globalMatrix.ItemConfig.getFieldName(errorField.fieldId);
                errors.push(fieldName ? fieldName : "" + errorField.fieldId);
            }
            ml.UI.showError("Some field(s) have invalid html tags", "Fields with bad content: " + errors.join(","));
            res.reject();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (jqxhr && jqxhr.status == 500 && jqxhr.responseJSON && jqxhr.responseJSON.category) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (jqxhr.responseJSON.displayError == "This item has been modified") {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (itemId && app.getCurrentItemId() == itemId) {
                    let ht = new HistoryTools();
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ht.diffAgainstLatest(param);
                } else {
                    // user double clicked in project and hell broke loose
                    // (we just ignore)
                }

                res.reject();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return res;
            } else if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                jqxhr.responseJSON.displayError == "Last operation would overflow number of licenses (error in compute)"
            ) {
                ml.UI.showError("Licensing Error", "You cannot change the access. There are not enough write licenses");
                res.reject();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return res;
            }

            if (jqxhr.displayError) {
                ml.UI.showError(jqxhr.responseJSON.category, jqxhr.displayError);
                res.reject();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return res;
            }

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (jqxhr.responseJSON.category == "Permission violation") {
                ml.UI.showError(
                    jqxhr.responseJSON.category,
                    jqxhr.responseJSON.detailsList && jqxhr.responseJSON.detailsList.length > 3
                        ? jqxhr.responseJSON.detailsList[2]
                        : "",
                );
                res.reject();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return res;
            } else if (
                jqxhr.responseJSON.displayError &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                jqxhr.responseJSON.displayError.indexOf("has no sufficient permission for this action on project") != -1
            ) {
                ml.UI.showError("Permission Violation", "You do not have access to this project");
                res.reject();
            } else {
                ml.UI.showError(
                    jqxhr.responseJSON.category,
                    jqxhr.responseJSON.detailsList && jqxhr.responseJSON.detailsList.length > 0
                        ? jqxhr.responseJSON.detailsList[0]
                        : "",
                );
                res.reject();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return res;
            }
        }

        if (this.isTimeout(jqxhr)) {
            ml.Logger.log("info", "Trying to reconnect...");
            // assume this ws a sign in problem.sign in quietly if possible and do it again
            matrixSession
                .signInAfterTimeout()
                .done(function () {
                    res.resolve();
                })
                .fail(function () {
                    ml.UI.showError("Not signed in!", ml.UI.getDisplayError(jqxhr, textStatus, error));
                    res.reject();
                });
        } else if (this.isGatewayTimeout(jqxhr)) {
            ml.Logger.log("info", "gateway timeout...");
            // assume this ws a sign in problem.sign in quietly if possible and do it again
            res.reject("gatewayTimeout");
        } else {
            ml.Logger.log("error", cmd);
            ml.UI.showError("Server error.", ml.UI.getDisplayError(jqxhr, textStatus, error));
            res.reject();
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    private get(url: string, noRetry?: boolean): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();
        ml.Logger.log("info", "GET: " + url);
        $.ajaxSetup({ cache: false }); // avoid IE to cache rest calls
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastTimer = (url.indexOf("?") !== -1 ? "&" : "?") + "td=" + (this.timer.end - this.timer.start);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let adminUI = globalMatrix.matrixProduct == "Configuration Client" ? "&adminUI=1" : "";
        this.timer = {
            start: new Date().getTime(),
            command: encodeURIComponent(url),
            type: "get",
        };
        $.getJSON(url + lastTimer + adminUI)
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .done(function (data: {}) {
                that.timer.end = new Date().getTime();
                that.timer.status = 200;
                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                res.resolve(data);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                that.timer.end = new Date().getTime();
                that.timer.status = jqxhr.status;
                if (noRetry) {
                    res.reject(jqxhr, textStatus, error);
                } else {
                    that.handleError(jqxhr, textStatus, error, "GET: " + url)
                        .done(function () {
                            $.getJSON(url)
                                .done(function (data) {
                                    ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                    res.resolve(data);
                                })
                                .fail(function (jqxhr, textStatus, error) {
                                    ml.UI.showError(
                                        "Failed to get item!",
                                        ml.UI.getDisplayError(jqxhr, textStatus, error),
                                    );
                                });
                        })
                        .fail(function () {
                            res.reject(jqxhr, textStatus, error);
                        });
                }
            });
        $.ajaxSetup({ cache: true });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    private post(url: string, param?: IRestParam, asPayload?: boolean): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();

        if (typeof param !== "undefined") {
            ml.Logger.log("info", "POST: " + url);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            param.td = this.timer.end - this.timer.start;
            this.timer = {
                start: new Date().getTime(),
                command: encodeURIComponent(url),
                type: "post",
            };
            //Content-type should be application/json for payload.
            $.post({
                url: url,
                data: asPayload ? JSON.stringify(param) : param,
                dataType: asPayload ? "json" : "",
                contentType: asPayload ? "application/json" : "",
            })
                .done(function (data) {
                    that.timer.end = new Date().getTime();
                    that.timer.status = 200;
                    ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                    res.resolve(data);
                })
                .fail(function (jqxhr, textStatus, error) {
                    that.timer.end = new Date().getTime();
                    that.timer.status = jqxhr.status;

                    that.handleError(jqxhr, textStatus, error, "POST: " + url + "?" + jQuery.param(param, true))
                        .done(function () {
                            $.post(url, param)
                                .done(function (data) {
                                    ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                    res.resolve(data);
                                })
                                .fail(function (jqxhr, textStatus, error) {
                                    ml.UI.showError(
                                        "Failed to post to server!",
                                        ml.UI.getDisplayError(jqxhr, textStatus, error),
                                    );
                                });
                        })
                        .fail(function () {
                            res.reject(jqxhr, textStatus, error);
                        });
                });
        } else {
            ml.Logger.log("debug", "POST: " + url);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let lasttimer = { td: this.timer.end - this.timer.start };
            this.timer = {
                start: new Date().getTime(),
                command: encodeURIComponent(url),
            };
            $.post(url, lasttimer)
                .done(function (data) {
                    that.timer.end = new Date().getTime();
                    that.timer.status = 200;
                    ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                    res.resolve(data);
                })
                .fail(function (jqxhr, textStatus, error) {
                    that.timer.end = new Date().getTime();
                    that.timer.status = jqxhr.status;
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (url.indexOf("/logout") == url.length - 7) {
                        // attempt of logout when there's no more session, that's fine
                        res.resolve();
                    } else {
                        that.handleError(jqxhr, textStatus, error, "POST: " + url)
                            .done(function () {
                                $.post(url)
                                    .done(function (data) {
                                        ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                        res.resolve(data);
                                    })
                                    .fail(function (jqxhr, textStatus, error) {
                                        ml.UI.showError(
                                            "Failed to post to server!",
                                            ml.UI.getDisplayError(jqxhr, textStatus, error),
                                        );
                                    });
                            })
                            .fail(function () {
                                res.reject(jqxhr, textStatus, error);
                            });
                    }
                });
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    private postJson(url: string, data: unknown): JQueryDeferred<IRestResult> {
        let res = $.Deferred();
        ml.Logger.log("info", "POST: " + url);
        this.timer = {
            start: new Date().getTime(),
            command: encodeURIComponent(url),
            type: "post",
        };
        $.ajax(url, {
            type: "post",
            contentType: "application/json",
            data: JSON.stringify(data),
        })
            .done((result) => {
                this.timer.end = new Date().getTime();
                this.timer.status = 200;
                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                res.resolve(result);
            })
            .fail((jqxhr, textStatus, error) => {
                this.timer.end = new Date().getTime();
                this.timer.status = jqxhr.status;
                res.reject(jqxhr, textStatus, error);
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    postSpecialServer(cmd: string, param: IRestParam): JQueryDeferred<IRestResult> {
        return this.postSpecial(this.restServer + "/" + cmd, param);
    }
    private postSpecial(url: string, param: IRestParam): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();

        ml.Logger.log("info", "POST: " + url);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        param.td = this.timer.end - this.timer.start;
        this.timer = {
            start: new Date().getTime(),
            command: encodeURIComponent(url),
            type: "post",
        };

        //  post(url: string, data?: Object|string, success?: (data: any, textStatus: string, jqXHR: JQueryXHR) => any, dataType?: string): JQueryXHR;
        $.ajax({
            url: url,
            type: "POST",
            data: JSON.stringify(param),
            contentType: "application/json;charset=UTF-8",
            dataType: "json",
        })
            .done(function (data) {
                that.timer.end = new Date().getTime();
                that.timer.status = 200;
                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                res.resolve(data);
            })
            .fail(function (jqxhr, textStatus, error) {
                that.timer.end = new Date().getTime();
                that.timer.status = jqxhr.status;
                that.handleError(jqxhr, textStatus, error, "POST: " + url + "?" + jQuery.param(param, true))
                    .done(function () {
                        // try again after timeout
                        $.ajax({
                            url: url,
                            type: "POST",
                            data: JSON.stringify(param),
                            contentType: "application/json;charset=UTF-8",
                            dataType: "json",
                        })
                            .done(function (data) {
                                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                res.resolve(data);
                            })
                            .fail(function (jqxhr, textStatus, error) {
                                ml.UI.showError(
                                    "Failed to post to server!",
                                    ml.UI.getDisplayError(jqxhr, textStatus, error),
                                );
                            });
                    })
                    .fail(function () {
                        res.reject(jqxhr, textStatus, error);
                    });
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    private put(url: string, param: IRestParam, asPayload?: boolean, itemId?: string): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();

        ml.Logger.log("info", "PUT: " + url);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        param.td = this.timer.end - this.timer.start;
        this.timer = {
            start: new Date().getTime(),
            command: encodeURIComponent(url),
            type: "put",
        };
        $.ajax({
            type: "put",
            url: url,
            processData: false,
            data: asPayload ? JSON.stringify(param) : jQuery.param(param, true),
            contentType: asPayload
                ? "application/json; charset=UTF-8"
                : "application/x-www-form-urlencoded; charset=UTF-8",
            dataType: "json",
        })
            .done(function (data) {
                that.timer.end = new Date().getTime();
                that.timer.status = 200;
                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                res.resolve(data);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                that.timer.end = new Date().getTime();
                that.timer.status = jqxhr.status;
                that.handleError(
                    jqxhr,
                    textStatus,
                    error,
                    "PUT: " + url + "?" + jQuery.param(param, true),
                    param,
                    itemId,
                )
                    .done(function () {
                        $.ajax({
                            type: "put",
                            url: url,
                            processData: false,
                            data: jQuery.param(param, true),
                            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                            dataType: "json",
                        })
                            .done(function (data) {
                                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                res.resolve(data);
                            })
                            .fail(function (jqxhr, textStatus, error) {
                                ml.UI.showError(
                                    "Failed to modify item!",
                                    ml.UI.getDisplayError(jqxhr, textStatus, error),
                                );
                            });
                    })
                    .fail(function (msg) {
                        res.reject(msg);
                    });
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    private deleteRestAsync(url: string, param: IRestParam, asString: boolean): JQueryDeferred<IRestResult> {
        let that = this;

        let res = $.Deferred();

        if (asString) {
            ml.Logger.log("info", "DELETE: " + url + "data: " + JSON.stringify(param));
        } else {
            ml.Logger.log("info", "DELETE: " + url + "?" + jQuery.param(param, true));
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        param.td = this.timer.end - this.timer.start;
        this.timer = {
            start: new Date().getTime(),
            command: encodeURIComponent(url),
            type: "delete",
        };
        $.ajax({
            type: "delete",
            url: url,
            processData: false,
            data: asString ? JSON.stringify(param) : jQuery.param(param, true),
            contentType: "application/text",
            dataType: "text",
        })
            .done(function (data) {
                that.timer.end = new Date().getTime();
                that.timer.status = 200;
                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                res.resolve(data);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                that.timer.end = new Date().getTime();
                that.timer.status = jqxhr.status;

                that.handleError(jqxhr, textStatus, error, "DELETE: " + url + "?" + jQuery.param(param, true))
                    .done(function () {
                        $.ajax({
                            type: "delete",
                            url: url,
                            processData: false,
                            data: jQuery.param(param, true),
                            contentType: "application/text",
                            dataType: "text",
                        })
                            .done(function (data) {
                                ml.Logger.log("debug", "RESPONSE: " + JSON.stringify(data));
                                res.resolve(data);
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .fail(function () {
                        res.reject();
                    });
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
}
