import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap, IReference } from "../../../../globals";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { extend } from "../../../matrixlib/JQueryExtendReplacement";
import { ItemSelectionAbstractDocFieldHandler } from "./ItemSelectionAbstractDocFieldHandler";

// type mostly build based on the values in default config. not sure whether some of the properties optional or not
export interface IItemListOptions extends IDHFSectionOptions {
    breadcrumb: boolean;
    folderDetails: string;
    extracolumn: string;
    showlinks: boolean;
    showUpOnly: boolean;
    showDownOnly: boolean;
    showDeeplinks: boolean;
    showExternal: number;
    dateoptions: string;
    hideLinkErrors: boolean;
    hideLabels: boolean;
    showAllParentFolders: boolean;
    breakAfterItems: boolean;
    recursive?: boolean;
    tree?: boolean;
    refdocs: boolean;
    hideEmptyFolders: boolean;
    showParentFolders?: boolean;
    includeInToc: boolean;
    sortedList?: boolean;
    search?: string;
}

export class ItemListDocFieldHandler extends ItemSelectionAbstractDocFieldHandler implements IDocFieldHandler {
    getDefaultConfig(): IItemListOptions {
        return {
            breadcrumb: true,
            folderDetails: "",
            extracolumn: "",
            showlinks: true,
            showUpOnly: false,
            showDownOnly: false,
            showDeeplinks: false,
            showExternal: 0,
            dateoptions: "",
            refdocs: false,
            hideLinkErrors: false,
            hideLabels: false,
            showAllParentFolders: false,
            hideEmptyFolders: false,
            includeInToc: false,
            breakAfterItems: false,
        };
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    getXmlValue(): string {
        let js = <IReference[]>this.getItems();

        // add params (parameter: "showbreadcrumb:true,extracolumn:EX") to each row
        let controllerConfig = this.dhfFieldConfig;

        for (let row = 0; row < js.length; row++) {
            extend(true, js[row], controllerConfig);
        }
        return JSON.stringify(js);
    }
}
