import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";

export class GenericDocFieldHandler extends GenericDocAbstractFieldHandler {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getDefaultConfig(): any {
        return {};
    }

    getXmlValue(): string {
        return JSON.stringify([this.rawValue ?? ""]);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addSignatures(signatures: string[], includeAll?: boolean) {}
}
