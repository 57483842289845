import { LinkListDisplay } from "./LinkListDisplay";
import { plugins } from "../../../../common/businesslogic";
import { IDashboardLinkForAnalytics, ITile } from "../../../../../sdk/plugins/interfaces";
import { Header } from "./Header";
import { Component, createRef } from "react";

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
type Props = {};
type State = {
    links: IDashboardLinkForAnalytics[];
};
export class Container extends Component<Props, State> {
    private tiles: ITile[];
    constructor(props: Props) {
        super(props);
        this.state = {
            links: [],
        };
        this.tiles = plugins.getAllTiles();

        // Sort the tiles by order
        this.tiles = this.tiles.sort((a, b) => a.getOrder() - b.getOrder());
        let children = [];
        for (let tile of this.tiles) {
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async componentDidMount() {
        let links = await plugins.getAllDashboardLinksForAnalyticsAsync();
        links = links.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        this.setState({ links });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render() {
        return (
            <div id="analytics-container">
                <LinkListDisplay links={this.state.links} />
                <div className={"rowFlex"}>
                    <Header tiles={this.tiles} title={"Analytics"}></Header>
                    <div id="analytics-tile-container">
                        {
                            /* Add a div for each tile and call render to this */
                            this.tiles.map((tile, index) => {
                                let element = (
                                    <MyComponent key={`${index}-${tile.getTitle()}`} tile={tile}></MyComponent>
                                );
                                // get htmlElement from the jsx element
                                return element;
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

type TileContainerProps = {
    tile: ITile;
};

class MyComponent extends Component<TileContainerProps> {
    private myElementRef: React.RefObject<HTMLDivElement>;
    private tile: ITile;

    constructor(props: TileContainerProps) {
        super(props);
        this.tile = props.tile;
        this.myElementRef = createRef();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    componentDidMount() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.myElementRef.current == null) {
            console.error("Element not found");
        } else {
            this.tile.render(this.myElementRef.current);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render() {
        return <div ref={this.myElementRef}></div>;
    }
}
