import type { IBaseControlOptions } from "../UI/Controls/BaseControl";

// To keep dependencies to a minimum, BasicFunctions is directly imported so
// we don't have all of the business logic libs.
import { BasicFunctions } from "../businesslogic/BasicFunctions";

import type { IJSONTools, ILoggerTools } from "./MatrixLibInterfaces";
import { extend } from "./JQueryExtendReplacement";

interface IFromStringResult {
    status: "empty" | "ok" | "error";
    value: object;
}

export class JSONTools implements IJSONTools {
    private fromStringCache: Record<string, IFromStringResult> = {};
    private logger: ILoggerTools;

    constructor(logger: ILoggerTools) {
        this.logger = logger;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private cloner2(src: any) {
        return extend(true, {}, { x: src }).x;
    }

    mergeOptions(defaultOptions: IBaseControlOptions, options: IBaseControlOptions): IBaseControlOptions {
        let newOptions: IBaseControlOptions = this.clone(defaultOptions);
        // make sure there is a parameter object
        if (!newOptions.parameter) {
            newOptions["parameter"] = {};
        }
        // copy parameters one by one
        if (options.parameter) {
            for (let name in options.parameter) {
                newOptions.parameter[name] = options.parameter[name];
            }
        }
        // copy other values
        for (let name in options) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (name != "parameter") {
                newOptions[name] = options[name];
            }
        }
        // use parameters to overwrite some default values
        if (this.isTrue(newOptions.parameter.readonly)) {
            newOptions.canEdit = false;
        }
        return newOptions;
    }

    // overwrite default options
    setOptions(newOptions: IBaseControlOptions, options: IBaseControlOptions): IBaseControlOptions {
        // make sure there is a parameter object
        if (!newOptions.parameter) {
            newOptions["parameter"] = {};
        }
        // copy parameters one by one
        if (options.parameter) {
            for (let name in options.parameter) {
                newOptions.parameter[name] = options.parameter[name];
            }
        }
        // copy other values
        for (let name in options) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (name != "parameter") {
                newOptions[name] = options[name];
            }
        }
        // use parameters to overwrite some default values
        if (this.isTrue(newOptions.parameter.readonly)) {
            newOptions.canEdit = false;
        }
        // handle readonly for ACLs
        if (this.isTrue(newOptions.parameter.readonlyACL)) {
            newOptions.canEdit = false;
        }
        return newOptions;
    }

    isTrue(obj: undefined | null | boolean | string | number): boolean {
        return BasicFunctions.isTrue(obj);
    }

    isFalse(obj: undefined | null | boolean | string | number): boolean {
        return BasicFunctions.isFalse(obj);
    }

    fromString(str: null | string): IFromStringResult {
        let result: IFromStringResult = { status: "empty", value: {} };

        if (str && str !== "") {
            if (this.fromStringCache[str]) {
                return structuredClone(this.fromStringCache[str]);
            }

            // first replace all single quotes outside of double quotes with "
            const strp = str.replace(/(')(?=(?:[^"]|"[^"]*")*$)/g, '"').replace(/(\r\n|\n|\r)/gm, "");
            try {
                result.value = JSON.parse(strp);
                result.status = "ok";
            } catch (err) {
                try {
                    result.value = JSON.parse(str);
                    result.status = "ok";
                } catch (err) {
                    this.logger.log("error", "Error trying to parse configuration parameter: " + str);
                    this.logger.log("error", "Error was:" + err);
                    result.status = "error";
                }
            }

            this.fromStringCache[str] = result;
        }

        return structuredClone(result);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    clone(src: any): any {
        let b = this.cloner2(src);
        return b;
    }

    // MATRIX-5951: json lint and JSON.parse( ) don't handle backslashes ( "a":"\s" )
    // MATRIX-6284: skipping this replacement for double quote escapement (\")
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    escapeJson(code: string) {
        return code.replace(/\\(?!")/g, "\\\\");
    }

    /** MATRIX-5951: json lint and JSON.parse( ) don't handle backslashes ( "a":"\s" )*/
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    unEscapeJson(code: string) {
        return code.replace(/\\\\/g, "\\");
    }
}
