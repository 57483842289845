import { IAnyMap } from "../../../globals";
import { IFieldHandler } from "./IFieldHandler";
import { FieldDescriptions } from "../FieldDescriptions";

export interface IDateSelectParamsBase {
    minDate?: Date;
}
export class DateFieldHandler implements IFieldHandler {
    private date: Date | undefined;
    private config: IAnyMap;

    constructor(config: IAnyMap) {
        this.date = undefined;
        this.config = config;
    }

    getData(): string | undefined {
        if (!this.date) {
            return "";
        }
        return this.date.getFullYear() + "/" + (this.date.getMonth() + 1) + "/" + this.date.getDate();
    }

    getFieldType(): string {
        return FieldDescriptions.Field_date;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        if (serializedFieldData) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.date = DateFieldHandler.getDateFromString(serializedFieldData);
        } else if (this.config.initialContent !== undefined) {
            // initialContent for the data field means set the date to the present.
            this.date = new Date();
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.initData(value);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static getDateFromString(dateStr: string) {
        if (dateStr) {
            let split: string[] = dateStr.split("/");
            if (split.length === 3 && split[0] !== "NaN") {
                let date = new Date(parseInt(split[0]), parseInt(split[1]) - 1, parseInt(split[2]));
                return date;
            }
        }
        return null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDate(date: Date) {
        this.date = date;
    }
    getDate(): Date | undefined {
        return this.date;
    }
}
