import { XRTrimNeedleItem } from "../../RestResult";
import { IBaseControlOptions } from "../../common/UI/Controls/BaseControl";
import { ControlState, IItem, IStringMap } from "../../globals";

export interface IReviewConfig {
    // this configures tool available to manage review
    tasks?: IReviewConfigTask; // to be configured if (jira) tasks should be created
    lockLabel?: ILockAction; // action to lock items
    doneLabel?: IReviewStatusUpdate; // action to parse review table to set labels depending on status
    mailTo?: IMailAction; // button to mail to users
    allowSelectUserGroups?: boolean; // if not set to false the reviewer popup will have the possibility to select groups
    showVersions?: boolean; // if true show reviewed version in table for each item
    showAnnotations?: boolean; // if true show annotation button
    annotationMasters?: string[]; // names of users who can edit other peoples comments
    showComments?: boolean; // if true show review comment in table for each item
    showInline?: boolean; // if true show inline triangle/text
    showHistory?: boolean; // if true show history button for each item
    /** like showHistoryOutOfDate but it only shows items as out of date if review is not yet completed */
    showHistoryOutOfDateBeforeDone?: boolean;
    /** shows items as out of date if current revision is newer than the one in the review */
    showHistoryOutOfDate?: boolean;
    readonly?: boolean;
    appendComments?: boolean; // if true put new comments at bottom of comment fields
    statusDropdown?: string; // can be set to overwrite the default status label "dd_reviewOptions"
    canBeModified?: boolean; // can be set to true if review can be modified (add/remove users/items)
    canBeModifiedBy?: string[]; // users who can modify review, empty for all
    cellAskEdit?: string; // if set the table shows this to indicate that user can do something
    autoshowContext?: boolean; // show context frame if not visible once an item cell has been selected
    createDoc?: ICreateDoc; // if set, a button is added to create a signable doc with all review details
    hide_UI?: boolean; // to temporarily hide the control in UI
    autoLock?: boolean; // if true, lock will be set on all items, as soon as the review is saved for a very first time with reviewers and items selected
    autoComment?: boolean; // if true, labels which will be set through the review (lock or progress) will have the REVIEW id in in the change comment
}

export interface ICreateDoc {
    template: string; // the DOC id to be used as template
    section: string; // the title of the section to be filled
    pasteTo: string; // parent DOC folder defaults to F-DOC-1
    hide?: string[]; // remove given field types
}

export interface IReviewAction {
    // defines button
    buttonName: string; // name of button to do action
    users: string[]; // users who can operate button - empty/null for all
}

export interface IMailAction extends IReviewAction {
    mailSubject: string; // subject of email (_id_ for item item id)
}

export interface ILockAction extends IReviewAction {
    label: string; // label to set
}

export interface IReviewStatusUpdate extends IReviewAction {
    // if all reviews of an item have a drop down value which is in the dropdown group passed -> a passed label can be set
    passedLabel?: string; // label to set
    // if 1+  item is failed  (empty or dropdown group todo) the failed label will be set
    failedLabel?: string; // label to set (optional)
    // if 1+  item is not yet reviewed (empty or dropdown group todo) the todo label will be set
    todoLabel?: string; // label to set (optional)
}

export interface IReviewActionCategoryLabel {
    label: string; // which label
    category: string; // for which category
}

export interface IReviewConfigTask {
    buttonName: string; // name of button to create tickets
    users: string[]; // users who can operate button - empty/null for all
    taskPluginId: number; // e.g. jira id
    taskIssueType: string;
    taskProject: string;
    taskDescription?: string;
}

export interface ITableReviewData {
    reviewtable: ITableReviewItem[];
}

export interface ITableReviewItem extends IStringMap {
    commentlog: string;
    reviewitem: string;
    _version: string;
}

export interface INeedleMap {
    [key: string]: XRTrimNeedleItem;
}

export interface IItemReviews {
    [key: string]: IItemReview;
}

export interface IItemReview {
    passed: string[];
    failed: string[];
    todo: string[];
    all: IItem[];
}

export class ReviewControlColumns {
    static COL_COMMENT_LOG = "commentlog" as const;
    static COL_ITEM = "reviewitem" as const;
    static COL_VERSION = "_version" as const;
    static COL_ANNOTATIONS = "_annotation" as const;
}

export interface IReviewControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fieldValue?: any;
    valueChanged?: () => void;
    parameter: IReviewConfig;
    readOnly?: boolean;
}
