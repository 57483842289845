import { ITableParams } from "../UI/Controls/BaseControl";

export type { ITableDataRow, ITableFunction, ITableFunctions };
export { TableMath, tableMath };
export { InitializePluginManagerFormulas };

interface ITableDataRow {
    [key: string]: number | string;
}
interface ITableFunction {
    (table: ITableDataRow[], parameterJson: ITableParams): string;
}

interface ITableFunctions {
    [key: string]: ITableFunction;
}

class TableMath {
    protected functions: ITableFunctions = {};

    /** allow to add new functions:  */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    registerFunction(functionId: string, execute: ITableFunction) {
        this.functions[functionId] = execute;
    }

    /** executes function on a table */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    execute(functionId: string, table: ITableDataRow[], parameterJson: ITableParams) {
        if (!this.functions[functionId]) {
            return `called function "${functionId}" does not exist!`;
        }
        return this.functions[functionId](table, parameterJson);
    }
}

let tableMath: TableMath;

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializePluginManagerFormulas() {
    tableMath = new TableMath();

    tableMath.registerFunction("percent", (table: ITableDataRow[], parameterJson: ITableParams) => {
        for (let row of table) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (row.y == 0) {
                return `The value in fy cannot be 0!`;
            }
            row.z = Math.round((100 * Number(row.x)) / Number(row.y));
        }
        return "";
    });
}
