import { app, globalMatrix, matrixSession, restConnection } from "../../../globals";
import { XCGetProjectAudit } from "../../../RestCalls";
import { XRGetProject_Calendar_CalendarTypeList, XRGetProject_ProjectAudit_TrimAuditList } from "../../../RestResult";
import { ml } from "../../matrixlib";
import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { IPanel } from "../Application";
import { HistoryTools } from "../Tools/ItemHistoryView";

export type { IInfoPerDay, IInfoPerDayDetail, IInfoPerDayMap };
export { CalendarPanel };

interface IInfoPerDay {
    auditIdMin: number;
    auditIdMax: number;
    details?: IInfoPerDayDetail[];
    nbChanges: number;
    dejaVu?: boolean;
}
interface IInfoPerDayDetail {
    itemId: string;
    type: string;
    title: string;
    version: number;
    action: string;
    human: string;
    reason: string;
}
interface IInfoPerDayMap {
    [key: string]: IInfoPerDay;
}

class CalendarPanel implements IPanel {
    private control: JQuery;
    private infoPerDay: IInfoPerDayMap;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectFromDate: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectToDate: string;
    private cats: string[];
    private select: JQuery; // calendar overview
    private results: JQuery; // details for selected days
    private auditIdMin: number; // covered audit range for selected items
    private auditIdMax: number;
    private killed = false;
    private timewarpColors: string[];
    private deletedOnly = false;
    title = "Calendar View";

    constructor(deletedOnly?: boolean) {
        if (deletedOnly) {
            this.deletedOnly = deletedOnly;
            this.title = "Deleted Items";
        }

        this.control = app.itemForm;
        document.title = this.title + " - " + matrixSession.getProject();

        this.infoPerDay = {};
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.selectFromDate;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.selectToDate;
        this.cats = globalMatrix.ItemConfig.getCategories();
        this.select = $("<div class='panel-body-no-scroll change-calendar'>"); // calendar overview
        this.results = $("<div class='panel-body-no-scroll change-result'>"); // details for selected days
        this.auditIdMin = Number.MAX_VALUE; // covered audit range for selected items
        this.auditIdMax = 0;

        this.timewarpColors = [
            "#BD0000",
            "#A40000",
            "#840000",
            "#6E0000",
            "#580000",
            "#420000",
            "#2E0000",
            "#1C0000",
            "#150000",
        ];

        this.renderCalendar();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {
        this.killed = true;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private resetSelection() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.selectToDate = null;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.selectFromDate = null;
        this.updateSelection();
    }

    // called if user clicks on a cell
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private changeSelection(selectedCell: JQuery) {
        let ti = selectedCell.data("date");

        if (!this.selectFromDate) {
            // first selected cell
            this.selectFromDate = ti;
            this.selectToDate = ti;
        } else if (ti < this.selectFromDate) {
            // before first selected cell. just enlarge range to the earlier point
            this.selectFromDate = ti;
        } else if (ti > this.selectToDate) {
            // after last selected. just enlarge range to new cell
            this.selectToDate = ti;
        } else if (ti === this.selectFromDate) {
            // click on first cell in range
            if (this.selectToDate !== this.selectFromDate) {
                // range has two+ days: just toggle off the first day
                const d = new Date(this.selectFromDate);
                const e = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
                this.selectFromDate = ml.UI.DateTime.renderDashFormat(e);
            } else {
                // range is just one day. just toggle off
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.selectToDate = null;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.selectFromDate = null;
            }
        } else if (ti === this.selectToDate) {
            // click on last cell in range: see above
            if (this.selectToDate !== this.selectFromDate) {
                const d = new Date(this.selectToDate);
                const e = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
                this.selectToDate = ml.UI.DateTime.renderDashFormat(e);
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.selectToDate = null;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.selectFromDate = null;
            }
        } else if (
            new Date(this.selectToDate).getTime() - new Date(ti).getTime() <
            new Date(ti).getTime() - new Date(this.selectFromDate).getTime()
        ) {
            // inbetween: closer to last cell: make range shorter, remove the end
            this.selectToDate = ti;
        } else {
            // inbetween: closer to first cell: make range shorter, remove the start
            this.selectFromDate = ti;
        }
        this.updateSelection();
    }
    // repaint selection and selection details
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private updateSelection() {
        let that = this;

        this.auditIdMin = Number.MAX_VALUE;
        this.auditIdMax = 0;

        $.each($("td", this.select), function (idx, td) {
            let d = new Date($(td).data("date"));
            let inSelection =
                that.selectFromDate &&
                that.selectToDate &&
                d &&
                d >= new Date(that.selectFromDate) &&
                d <= new Date(that.selectToDate);
            $(td).css("color", inSelection ? "white" : $(td).data("color"));
            $(td).css(
                "background-color",
                inSelection ? UIToolsConstants.CIColorsPrimary.P6_LogoDarkGreen.color : $(td).data("background-color"),
            );
            $(td).css("font-weight", inSelection ? "bold" : "normal");
            let date = $(td).data("date");
            if (inSelection && that.infoPerDay[date]) {
                that.auditIdMin = Math.min(that.auditIdMin, that.infoPerDay[date].auditIdMin);
                that.auditIdMax = Math.max(that.auditIdMax, that.infoPerDay[date].auditIdMax);
            }
        });

        if (this.auditIdMin > this.auditIdMax) {
            // nothing in selection
            that.renderEmptySelectionHelp();
            return;
        }

        // render details

        let ht = new HistoryTools();
        if (that.deletedOnly) {
            ht.setDeletedOnly();
        }
        that.results.html("");
        let headline = that.appendSelectionTime("Selected days: ");
        ht.showActivity(that.results, that.auditIdMin, that.auditIdMax);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ml.UI.copyBuffer(headline, "copy changes", that.results);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private dayOfTimeWarp(date: Date) {
        if (!globalMatrix.ItemConfig.getTimeWarp()) {
            return false;
        }
        let tw = new Date(globalMatrix.ItemConfig.getTimeWarp());
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (tw.getFullYear() != date.getFullYear()) {
            return false;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (tw.getMonth() != date.getMonth()) {
            return false;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (tw.getDate() != date.getDate()) {
            return false;
        }
        return true;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderMonth(date: Date, maxPerDay: number) {
        let that = this;

        let today = new Date();
        let table = $("<table class='month table-bordered'>");
        let tbody = $("<tbody>");
        table.append(tbody);
        const tr = $("<tr>");
        let month = $("<td colspan='7' class='change-month'>").html(
            ml.UI.DateTime.renderHumanMonth(date) + " " + date.getFullYear(),
        );
        tbody.append(tr.append(month));
        this.select.append(table);
        this.select.append($("<br>"));
        let day = -date.getDay() + 1;
        for (let row = 0; row < 6; row++) {
            let d1 = new Date(date.getFullYear(), date.getMonth(), day);
            if (row !== 0 && d1.getMonth() !== date.getMonth()) {
                // first day of this row is not in month
                return;
            }
            const trInside = $("<tr>");
            tbody.append(trInside);
            for (let col = 0; col < 7; col++) {
                let td = $("<td width='30px' style='text-align:center'>");
                trInside.append(td);
                let d2 = new Date(date.getFullYear(), date.getMonth(), day);
                let d3 = ml.UI.DateTime.renderDashFormat(d2);

                td.html("&nbsp;");

                if (d2.getMonth() === date.getMonth()) {
                    td.data("date", d3);
                    td.html(d2.getDate().toString());
                    if (this.infoPerDay[d3] && this.infoPerDay[d3].nbChanges > 0) {
                        let ci = Math.floor(
                            ((UIToolsConstants.allGrey.length - 1) * this.infoPerDay[d3].nbChanges) / maxPerDay,
                        );

                        td.tooltip({ container: "body", title: "(shift) click to see changes", trigger: "hover" });
                        td.css("color", "white")
                            .data("color", "white")
                            .css(
                                "background-color",
                                this.dayOfTimeWarp(d2) ? this.timewarpColors[ci] : UIToolsConstants.allGrey[ci],
                            )
                            .data(
                                "background-color",
                                this.dayOfTimeWarp(d2) ? this.timewarpColors[ci] : UIToolsConstants.allGrey[ci],
                            );
                    } else {
                        td.css("color", this.dayOfTimeWarp(d2) ? "red" : "grey")
                            .data("color", this.dayOfTimeWarp(d2) ? "red" : "grey")
                            .css("background-color", "white")
                            .data("background-color", "white");
                    }
                    if (
                        d2.getDate() === today.getDate() &&
                        d2.getMonth() === today.getMonth() &&
                        d2.getFullYear() === today.getFullYear()
                    ) {
                        td.addClass("istoday");
                    }

                    td.click(function (event: JQueryMouseEventObject) {
                        if (!event.shiftKey) {
                            that.resetSelection();
                        } else {
                            // prevent selection of text
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            document.getSelection().removeAllRanges();
                        }
                        that.changeSelection($(event.delegateTarget));
                    });
                }
                day++;
            }
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderMonths(fromDate: Date, toDate: Date, maxPerDay: number) {
        // the month starts at 0
        let firstOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);

        this.renderMonth(firstOfMonth, maxPerDay);
        if (fromDate.getMonth() === toDate.getMonth() && fromDate.getFullYear() === toDate.getFullYear()) {
            return;
        }

        this.renderMonths(new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 1), toDate, maxPerDay);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderEmptySelectionHelp() {
        let that = this;

        let table = $("<table>");
        let tbody = $("<tbody>");
        table.append(tbody);

        this.results.html("");
        if (!this.appendSelectionTime("No changes on selected dates: ")) {
            this.results.append(
                "<p>Click in the calendar to see the changes of a selected day. Hold the <b>SHIFT</b> key down to this.select a range of days.</p>",
            );
            this.results.append(
                "<p>Note: The background color of the day cells indicate the number of changes on a particular day (white: none, dark grey: many).",
            );
        }

        $("<p><button class='btn btn-link'>Show changes of today</button></p>")
            .appendTo(this.results)
            .click(function () {
                that.selectCalendar(0);
            });

        $("<p><button class='btn btn-link'>Show changes of last week</button></p>")
            .appendTo(this.results)
            .click(function () {
                that.selectCalendar(7);
            });

        $("<p><button class='btn btn-link'>Show changes of last month</button></p>")
            .appendTo(this.results)
            .click(function () {
                that.selectCalendar(30);
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private appendSelectionTime(prefix: string) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.selectFromDate && this.selectToDate && this.selectToDate != this.selectFromDate) {
            let headline = $("<h1>").html(prefix + this.selectFromDate + " to " + this.selectToDate);
            this.results.append(headline);
            return headline;
        } else if (this.selectFromDate) {
            let headline = $("<h1>").html(prefix + this.selectFromDate);
            this.results.append(headline);
            return headline;
        }
        return null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private selectCalendar(daysFromToday: number) {
        let today = new Date();
        let before = new Date();
        before.setDate(today.getDate() - daysFromToday);
        this.resetSelection();
        this.selectToDate = ml.UI.DateTime.renderDashFormat(today);
        this.selectFromDate = ml.UI.DateTime.renderDashFormat(before);
        this.updateSelection();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addFilter() {
        let that = this;
        let filter = $(
            `<input type="text" id="calendarFilter" style="width:20vw; float:right" placeholder="filter..." class="doNotCopy  form-control"/>`,
        );
        $(".toolbarButtons", app.itemForm).append(ml.UI.DateTime.getTimeZoneCTA());
        $(".toolbarButtons", app.itemForm).append($("<div></div>").append(filter));
        filter.keyup(() => {
            that.filterCalendar();
        });
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private delayedFilter: number;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private filterCalendar() {
        window.clearTimeout(this.delayedFilter);
        this.delayedFilter = window.setTimeout(() => {
            $(".panel:has(.history-panel)", $(".change-result")).show();
            $("#hiddenItemsCount").remove();
            let value = $("#calendarFilter").val().toLowerCase();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (!value || value.length == 1) {
                ml.Search.hideHighlight();
                return;
            }
            // there are at least 2 letter in filter box
            ml.Search.highlight(value);
            let count = 0;
            $(".panel:has(.history-panel)", $(".change-result")).each((i, p) => {
                let elem = $(p);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (elem.text().toLowerCase().indexOf(value) == -1) {
                    elem.hide();
                    count++;
                }
            });
            if (count > 0) {
                $(
                    `<div id="hiddenItemsCount" style="margin-right:10px;display:block;text-align:right;margin-top:-30px"><small>${count} hidden changes</small></div>`,
                ).insertAfter(".change-result h1");
            }
        }, 200);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderCalendar() {
        let that = this;

        this.control.html("");
        that.control.append($("<div class='calendar-wrapper'>").append(that.select).append(that.results));
        that.select.html("");

        app.itemForm.prepend(ml.UI.getPageTitle("Calendar View"));

        that.select.append(ml.UI.getSpinningWait("loading calendar..."));
        that.renderCalendarSelection().done(() => {
            this.renderLastChanges();
        });
    }

    // render left part -> the calendar
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderCalendarSelection(): JQueryDeferred<any> {
        let def = $.Deferred();
        let that = this;
        // prepare change log
        let fromDate = new Date();
        let toDate = new Date();
        let maxPerDay = 0;

        let param = {
            include: globalMatrix.historyFilter,
        };
        const queryString = this.deletedOnly ? "?deletedOnly=yes" : "";
        restConnection.getProject("calendar" + queryString).done(function (response) {
            const result = response as XRGetProject_Calendar_CalendarTypeList;

            if (that.killed) {
                return;
            }

            that.control.html("");
            app.itemForm.prepend(ml.UI.getPageTitle(that.title));
            that.addFilter();

            that.control.append($("<div class='calendar-wrapper'>").append(that.select).append(that.results));

            let pcheck: XCGetProjectAudit = {
                startAt: 0,
                maxResults: 1,
                noImport: 1,
                include: globalMatrix.historyFilter,
            };

            if (that.deletedOnly) {
                pcheck.deleteOnly = "yes";
            }

            if (result.length > 0) {
                pcheck.auditIdMin = result[result.length - 1].auditIdMin;
                pcheck.auditIdMax = result[result.length - 1].auditIdMax;
            }

            // call to check the number of this.results
            restConnection.getProject("audit" + "?" + $.param(pcheck, true)).done((check) => {
                if (that.killed) {
                    return;
                }

                that.select.html("");

                if (result.length > 0) {
                    fromDate = new Date(result[result.length - 1].dateString);
                    toDate = new Date(result[0].dateString);
                    // fix th #this.results on the this.results (otherwise all the imports are part of that day...
                    result[result.length - 1].nbChanges = (
                        check as XRGetProject_ProjectAudit_TrimAuditList
                    ).totalResults;
                }

                getSelection();

                $.each(result, (idx, di) => {
                    that.infoPerDay[di.dateString] = di;
                    maxPerDay = Math.max(maxPerDay, di.nbChanges);
                });

                // show a calendar
                that.renderMonths(fromDate, toDate, maxPerDay);

                that.select.animate({ scrollTop: that.select[0].scrollHeight }, 1000, "swing");
                def.resolve();
            });
        });
        return def;
    }

    // render the details on the right: start with last n changes
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderLastChanges() {
        let that = this;
        let res = $.Deferred();

        let ht = new HistoryTools();
        if (that.deletedOnly) {
            ht.setDeletedOnly();
        }
        ht.onNewResult(() => {
            that.filterCalendar();
        });

        $("<h1>").html("Last changes: ").appendTo(that.results);
        ht.showActivity(that.results);
        //  ml.UI.copyBuffer( headline, "copy changes", that.results);
        res.resolve();

        return res;
    }
}
