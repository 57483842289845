import { ItemControl } from "../UI/Components/index";
import { IItem } from "./../../globals";
import { IDBParent } from "./DBCache";

export type {
    IItemViewEvent,
    IItemChangeEvent,
    IPreCreateItemEvent,
    IPreCreateCloseEvent,
    IGenericItemEvent,
    IGenericItemIdEvent,
    INewItemIdEvent,
    ILabelChangeEvent,
    ISignatureEvent,
    IEvent,
};
export { EventDispatcher, EventDispatcherAsync, MR1 };

interface IItemViewEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    item: IItem;
    view: ItemControl;
}

interface IItemChangeEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    view: ItemControl;
    before: IItem;
    after: IItem;
}

interface IPreCreateItemEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    view: ItemControl;
    isItem: boolean;
    category: string;
}
interface IPreCreateCloseEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    ok: boolean;
}

interface IGenericItemEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    item: IItem;
}
interface IGenericItemIdEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    itemId: string;
}
interface INewItemIdEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    item: IDBParent;
}

interface ILabelChangeEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    item: IItem;
    set: string[];
    unset: string[];
}
interface ISignatureEvent {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    caller: any;
    item: IItem;
    lastuser: boolean;
}

interface IEvent<TArgs, TMode> {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    subscribe(caller: any, fn: (args: TArgs) => TMode): void;
    unsubscribe(fn: (args: TArgs) => TMode): void;
    dispatch(args: TArgs): TMode;
}

class EventDispatcher<TArgs> implements IEvent<TArgs, void> {
    private _subscriptions: Array<(args: TArgs) => void> = new Array<(args: TArgs) => void>();
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private _callers: Array<any> = new Array<any>();

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    subscribe(caller: any, fn: (args: TArgs) => void): void {
        this._callers.push(caller);
        this._subscriptions.push(fn);
    }

    unsubscribe(fn: (args: TArgs) => void): void {
        let i = this._subscriptions.indexOf(fn);
        if (i > -1) {
            this._subscriptions.splice(i, 1);
            this._callers.splice(i, 1);
        }
    }

    dispatch(args: TArgs): void {
        for (let i = 0; i < this._subscriptions.length; i++) {
            let handler = this._subscriptions[i];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (handler != null) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (<{ caller: any }>(<any>args)).caller = this._callers[i];
                handler(args);
            }
        }
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
class EventDispatcherAsync<TArgs> implements IEvent<TArgs, JQueryDeferred<{}>> {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private _subscriptionsAsync: Array<(args: TArgs) => JQueryDeferred<{}>> = new Array<
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        (args: TArgs) => JQueryDeferred<{}>
    >();
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private _callersAsync: Array<any> = new Array<any>();

    // now same for asyncs
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    subscribe(caller: any, fn: (args: TArgs) => JQueryDeferred<{}>): void {
        this._callersAsync.push(caller);
        this._subscriptionsAsync.push(fn);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    unsubscribe(fn: (args: TArgs) => JQueryDeferred<{}>): void {
        let i = this._subscriptionsAsync.indexOf(fn);
        if (i > -1) {
            this._subscriptionsAsync.splice(i, 1);
            this._callersAsync.splice(i, 1);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    dispatch(args: TArgs): JQueryDeferred<{}> {
        return this.dispatchAsyncOne(args, 0);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private dispatchAsyncOne(args: TArgs, idx: number): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();
        if (idx >= this._subscriptionsAsync.length) {
            res.resolve();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        let handler = this._subscriptionsAsync[idx];
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (handler != null) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (<{ caller: any }>(<any>args)).caller = this._callersAsync[idx];
            handler(args)
                .done(function () {
                    that.dispatchAsyncOne(args, idx + 1)
                        .done(function () {
                            res.resolve();
                        })
                        .fail(function () {
                            res.reject();
                        });
                })
                .fail(function () {
                    res.reject();
                });
        } else {
            that.dispatchAsyncOne(args, idx + 1)
                .done(function () {
                    res.resolve();
                })
                .fail(function () {
                    res.reject();
                });
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
}

export class MR1Impl {
    cbsItemDisplayed: EventDispatcher<IItemViewEvent> = new EventDispatcher<IItemViewEvent>();
    cbsCreateItemDisplayed: EventDispatcher<IPreCreateItemEvent> = new EventDispatcher<IPreCreateItemEvent>();
    cbsCreateItemDisplayedClose: EventDispatcher<IPreCreateCloseEvent> = new EventDispatcher<IPreCreateCloseEvent>();
    cbsBeforeSave: EventDispatcherAsync<IItemChangeEvent> = new EventDispatcherAsync<IItemChangeEvent>();
    cbsAfterSave: EventDispatcher<IItemChangeEvent> = new EventDispatcher<IItemChangeEvent>();
    cbsAfterRestore: EventDispatcher<IGenericItemIdEvent> = new EventDispatcher<IGenericItemIdEvent>();
    cbsAfterDelete: EventDispatcher<IGenericItemEvent> = new EventDispatcher<IGenericItemEvent>();
    cbsAfterCreate: EventDispatcher<INewItemIdEvent> = new EventDispatcher<INewItemIdEvent>();
    cbsAfterCreateSign: EventDispatcher<IGenericItemEvent> = new EventDispatcher<IGenericItemEvent>();
    cbsBeforeDelete: EventDispatcherAsync<IGenericItemEvent> = new EventDispatcherAsync<IGenericItemEvent>();
    cbsAfterLabelChange: EventDispatcher<ILabelChangeEvent> = new EventDispatcher<ILabelChangeEvent>();
    cbsAfterSignature: EventDispatcher<ISignatureEvent> = new EventDispatcher<ISignatureEvent>();

    // triggers

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerItemCreate(view: ItemControl, isItem: boolean, category: string) {
        this.cbsCreateItemDisplayed.dispatch(<IPreCreateItemEvent>{ view: view, isItem: isItem, category: category });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerItemCreateClose(ok: boolean) {
        this.cbsCreateItemDisplayedClose.dispatch(<IPreCreateCloseEvent>{ ok: ok });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerItemDisplayed(item: IItem, view: ItemControl) {
        this.cbsItemDisplayed.dispatch(<IItemViewEvent>{ item: item, view: view });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerBeforeSaveAsync(view: ItemControl, before: IItem, after: IItem): JQueryDeferred<{}> {
        return this.cbsBeforeSave.dispatch(<IItemChangeEvent>{ view: view, before, after });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterSave(view: ItemControl, before: IItem, after: IItem) {
        this.cbsAfterSave.dispatch(<IItemChangeEvent>{ before: before, after: after, view: view });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterRestore(itemId: string) {
        this.cbsAfterRestore.dispatch(<IGenericItemIdEvent>{ itemId: itemId });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterDelete(item: IItem) {
        this.cbsAfterDelete.dispatch(<IGenericItemEvent>{ item: item });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterItemCreate(item: IDBParent) {
        this.cbsAfterCreate.dispatch(<INewItemIdEvent>{ item: item });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterCreateSign(item: IItem) {
        this.cbsAfterCreateSign.dispatch(<IGenericItemEvent>{ item: item });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerBeforeDeleteAsync(item: IItem): JQueryDeferred<{}> {
        return this.cbsBeforeDelete.dispatch(<IGenericItemEvent>{ item: item });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterLabelChange(item: IItem, before: string[], after: string[]) {
        this.cbsAfterLabelChange.dispatch(<ILabelChangeEvent>{ item: item, set: before, unset: after });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    triggerAfterSignature(item: IItem, lastuser: boolean) {
        this.cbsAfterSignature.dispatch(<ISignatureEvent>{ item: item, lastuser: lastuser });
    }

    // notifications

    onItemDisplayed(): IEvent<IItemViewEvent, void> {
        /* fired after item is rendered */ return this.cbsItemDisplayed;
    }
    onItemCreateDlgOpen(): IEvent<IPreCreateItemEvent, void> {
        /* fired after dialog is opened */ return this.cbsCreateItemDisplayed;
    }
    onItemCreateDlgClose(): IEvent<IPreCreateCloseEvent, void> {
        /* fired after dialog is closed */ return this.cbsCreateItemDisplayedClose;
    }
    onAfterSave(): IEvent<IItemChangeEvent, void> {
        /* fired after item was saved */ return this.cbsAfterSave;
    }
    onAfterRestore(): IEvent<IGenericItemIdEvent, void> {
        /* fired after item was restored */ return this.cbsAfterRestore;
    }
    onAfterDelete(): IEvent<IGenericItemEvent, void> {
        /* event after an item (or a folder) has been deleted */ return this.cbsAfterDelete;
    }
    onAfterCreate(): IEvent<INewItemIdEvent, void> {
        /* event after an item (or a folder) has been created */ return this.cbsAfterCreate;
    }
    onAfterCreateSign(): IEvent<IGenericItemEvent, void> {
        /* event after a SIGN item has been created */ return this.cbsAfterCreateSign;
    }
    onAfterLabelChange(): IEvent<ILabelChangeEvent, void> {
        return this.cbsAfterLabelChange;
    }
    onAfterSignature(): IEvent<ISignatureEvent, void> {
        return this.cbsAfterSignature;
    }

    // allowing to stop execution. need to return promise, reject() to stop resolve() to continue.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onBeforeSaveAsync(): IEvent<IItemChangeEvent, JQueryDeferred<{}>> {
        /* event before  an item will be saved. */ return this.cbsBeforeSave;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onBeforeDeleteAsync(): IEvent<IGenericItemEvent, JQueryDeferred<{}>> {
        /* event before  an item (or a folder) has been deleted. */ return this.cbsBeforeDelete;
    }
}

const MR1: MR1Impl = new MR1Impl();
// @ts-ignore TODO: get rid of the globals
globalThis.MR1 = MR1;
/** tests...

// MR1.onAfterLabelChange().subscribe( this, function (event:ILabelChangeEvent) { console.log("Label changed for " + event.item.id + " set: '" + event.set.join() + "' unset: '" + event.unset.join() + "'");} );


MR1.onBeforeDeleteAsync().subscribe( this, function (event:IGenericItemEvent) {
    console.log("MR1 >>>Could cancel item delete " + event.item.id);

    let res = $.Deferred();
    res.resolve(); // res.reject(); to cancel
    return res;
});

MR1.onBeforeSaveAsync().subscribe( this, function (event:IItemChangeEvent) {
    console.log("MR1 >>>Could cancel save of " + event.before.id);

    let res = $.Deferred();
    res.resolve(); // res.reject(); to cancel
    return res;
});

MR1.onAfterCreate().subscribe( this, function (event:INewItemIdEvent) { console.log("MR1 >>>Item created " + event.item.item.id);} );
MR1.onAfterCreateSign().subscribe( this, function (event:IGenericItemEvent) { console.log("MR1 >>>SIGN created " + event.item.id);} );

MR1.onAfterSignature().subscribe( this, function (event:ISignatureEvent) { console.log("MR1 >>>SIGNED " + event.item.id + " by " + matrixSession.getUser() +  ( event.lastuser?" as last signature":" but not last signature"));} );

MR1.onAfterSave().subscribe( this, function (event:IItemChangeEvent) { console.log("MR1 >>>Item saved " + event.after.id);} );
MR1.onAfterRestore().subscribe( this, function (event:IGenericItemIdEvent) { console.log("MR1 >>>Item restored " + event.itemId);} );

MR1.onAfterDelete().subscribe( this, function (event:IGenericItemEvent) { console.log("MR1 >>>Item deleted " + event.item.id);} );

MR1.onItemDisplayed().subscribe( this, function (event:IGenericItemEvent) { console.log("MR1 >>>Item displayed " + event.item.id);} );

MR1.onItemCreateDlgOpen().subscribe( this, function (event:IPreCreateItemEvent) {  console.log("MR1 >>>Create item dialog with " + event.view.getControls().length + " controls.");}  );

*/

/** example extract all italic from text and put in title when saving if title is " " or ""
MR1.onBeforeSaveAsync().subscribe( this, function (event:IItemChangeEvent) {
    console.log("MR1 >>>Could cancel save of " + event.before.title);

    if (event.after.title == " " || event.after.title == "") {
        let tf = IC.getFieldsOfType("richtext",event.after.type);
        let italics="";
        $.each( tf, function(idx, tfd) {
            let tfc = $("<div>").html(event.after[tfd.field.id]);
            $("span", tfc).each( function(spanIdx,span) {
                if ($(span).css("font-style").indexOf("italic")!=-1) {
                    italics += italics?(" "+$(span).text()):$(span).text();
                }
            });
        });
        event.after.title = italics?italics:"title required";
    }

    let res = $.Deferred();
    res.resolve();
    return res;
});

 */
