import { IDHFControlDefinition } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IItemSelectionFromToOptions } from "../../../../common/UI/Controls/itemSelectionFromTo";

import { globalMatrix } from "../../../../globals";
import { ICustomSectionOptions } from "../../../../common/businesslogic/FieldHandlers/Document/CustomDocFieldHandler";
import { DoubleSelectBase } from "../../../../DoubleSelectBase";

export { CustomSection };

class CustomSection extends DoubleSelectBase<ICustomSectionOptions> {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let allLinkTypes = globalMatrix.ItemConfig.getCategories(true);

        // define possible to types: no folders, reports (maybe no DOC, no SIGN)
        let hideToTypes: string[] = [];

        hideToTypes.push("FOLDER", "REPORT");
        let toTypes: string[] = [];
        for (let idx = 0; idx < allLinkTypes.length; idx++) {
            if (hideToTypes.indexOf(allLinkTypes[idx]) === -1) {
                toTypes.push(allLinkTypes[idx]);
            }
        }

        // from categories (rows): all but docs & XTC
        let hideFromTypes: string[] = ["REPORT", "FOLDER"];

        const options: IItemSelectionFromToOptions = {
            parameter: {
                prefixFrom: "Main Items:",
                buttonNameFrom: "Select Main Items",
                showNotFrom: hideFromTypes,
                prefixTo: "Other Items:",
                buttonNameTo: "Select Other Items",
                showOnlyTo: toTypes,
                // showNotTo: ["REPORT"],
                allTo: true,
            },
        };
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        ctrl.control.itemSelectionFromTo(<any>ml.JSON.mergeOptions(ctrlParameter, options));
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig: ICustomSectionOptions = this.getConfig(ctrl);

        let itoc =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included items to table of content</label></div>";
        custom.append($(itoc));

        this.addSpecificSettings(controllerConfig, custom);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        let newVal = $(custom.find(".includeInToc")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.includeInToc;
        controllerConfig.includeInToc = newVal;

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }
}
