export function hackInAQueryParamToDisableCachingForSafariOnly(original: string): string {
    if (navigator.userAgent.indexOf("Safari") === -1 || navigator.userAgent.indexOf("Chrome") !== -1) {
        // Not Safari, return original
        return original;
    }

    // handling "&amp;" as well because it might be escaped earlier
    const uncacheregex = /(&|(&amp;))uncache=(.*)$/;
    const imageRegex = /<img.*src="([^"]*)".*?>/gi;

    type Change = { original: string; replacement: string };
    const changes: Change[] = [];

    // TODO: not sure how it's suppose to work if there are no other query params in the link
    // leaving it as is just because I think it shouldn't happen in our app
    const uncache = "&uncache=" + new Date().getTime();
    let moreMatches = true;
    do {
        const imageTags = imageRegex.exec(original);
        if (imageTags) {
            const url = imageTags[1];
            let newUrl = url;
            if (uncacheregex.test(url)) {
                newUrl = url.replace(uncacheregex, uncache);
            } else {
                newUrl = url + uncache;
            }
            changes.push({ original: url, replacement: newUrl });
        } else {
            moreMatches = false;
        }
    } while (moreMatches);

    let newString = original;
    changes.forEach(({ original, replacement }) => {
        newString = newString.replace(original, replacement);
    });
    return newString;
}
