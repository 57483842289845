import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { LabelSwitches } from "../Parts/LabelSwitches";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IDocFilterOptions };
export { DocFilterImpl };

interface IDocFilterOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    parameter?: {};
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.docFilter = function (this: JQuery, options: IDocFilterOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_docFilter,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new DocFilterImpl(this, options.fieldHandler as GenericFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};
class DocFilterImpl extends BaseControl<GenericFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IDocFilterOptions;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IDocFilterOptions) {
        let that = this;
        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                // item the item containing the rest of the information
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        let currentLabelsOn: string[] = [];

        if (!this.settings.isItem) {
            return;
        }

        let ctrlContainer = $("<div class='baseControl'>");

        if (this.settings.fieldValue) {
            let onList = ml.JSON.fromString(this.settings.fieldValue);
            currentLabelsOn = ml.JSON.clone(onList.value);
        }
        // do not pass type: otherwise it will be removed. Also tooltips should be like for filter
        let lt = new LabelSwitches(
            ctrlContainer,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.canEdit,
            null,
            currentLabelsOn,
            "doc_filter",
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            function (newSelection: {}) {
                that._root.data("new", JSON.stringify(newSelection));
                if (that.settings.valueChanged) {
                    that.settings.valueChanged.apply(null);
                }
            },
        );
        if (lt.labelsExist()) {
            this._root.append(super.createHelp(this.settings));
            this._root.append(ctrlContainer);
        }

        this._root.data("original", JSON.stringify(currentLabelsOn));
        this._root.data("new", JSON.stringify(currentLabelsOn));
    }

    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        // hack the download button

        $("#filterWarning").remove();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this._root.data("new") && this._root.data("new") != "[]") {
            let applied_filters = "";
            try {
                const filterData: string[] = JSON.parse(this._root.data("new"));
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (filterData.join) {
                    let existingLabels = ml.LabelTools.getLabelNames();
                    applied_filters = filterData
                        .map((f) => f.replace("!", ""))
                        .filter(function (filter) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            return existingLabels.indexOf(filter) != -1;
                        })
                        .join(", ");
                }
            } catch (e) {
                // Ignore parsing errors
            }
            if (applied_filters) {
                $("#btnDownload").prepend(
                    '<span id="filterWarning" title="Filter enabled: ' +
                        applied_filters +
                        '" class="fal fa-exclamation-triangle" style="padding-right:12px;color: red;"></span>',
                );
            }
        }

        return this._root.data("original") !== this._root.data("new");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        return this._root.data("new");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}
}
