import { IReference } from "../../../globals";
import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { ItemSelectionFieldHandler } from "./ItemSelectionFieldHandler";
export { CrosslinksFieldHandler };

class CrosslinksFieldHandler extends ItemSelectionFieldHandler {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static UpdateFieldConfig(params: XRFieldTypeAnnotatedParamJson) {
        params.crossProject = true;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.linkTypes == undefined) {
            params.linkTypes = [];
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.prefix == undefined) {
            params.prefix = "Links";
        }
    }

    constructor(configIn: XRFieldTypeAnnotatedParamJson) {
        super(configIn, FieldDescriptions.Field_crosslinks);
    }

    /**
     * Add an item to the list at the given position
     * @param position
     * @param item
     * @returns the field handler
     * @throws Error in case a projectShortLabel doesn't exist in the item.
     */
    insertItem(position: number, item: IReference): CrosslinksFieldHandler {
        if (!item.projectShortLabel) {
            throw new Error("item must be a cross project link");
        }
        return super.insertItem(position, item);
    }

    /**
     * Append the given item to the end of the list of items.
     * @param item
     * @returns the field handler.
     * @throws Error in case a projectShort label doesn't exist in the item.
     */
    appendItem(item: IReference): CrosslinksFieldHandler {
        if (!item.projectShortLabel) {
            throw new Error("item must be a cross project link");
        }
        return super.appendItem(item);
    }
}
