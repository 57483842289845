// -----------------------------------------------------------
// A normal item control such as a requirements
// -----------------------------------------------------------

import {
    FieldHandlerFactory,
    ICategoryConfig,
    IDBParent,
    IDHFSection,
    IItemWatched,
    mDHF,
    MR1,
    mTM,
    plugins,
    XRFieldTypeAnnotated,
} from "../../businesslogic/index";
import { IBaseControlOptions } from "../Controls/BaseControl";
import { GateControlImpl, IGate } from "../Controls/gateControl";
import { ILinkCollectionOptions } from "../Controls/linkCollection";
import { SelectMode } from "./ProjectViewDefines";
import { ml } from "./../../matrixlib";
import { TitleToolbarImpl } from "../Controls/titleToolbar";
// TODO(modules): this is a hacky way to ensure the jquery $.fn.titleToolbar() func is created.
import "../Controls/titleToolbar";
import {
    ICategorySettingTabs,
    ICategorySettingTitle,
    ICategorySettingToolLocation,
    IDropdownGroup,
    IDropdownOption,
    IProjectLogo,
} from "../../../ProjectSettings";
import { XRReportType } from "../../../RestResult";
import { ItemCreationTools } from "../Tools/ItemCreationView";
import { Cleanup } from "../../../client/plugins/beta/Cleanup";
import { IDropDownButtonOption, IReportInput } from "../../matrixlib/MatrixLibInterfaces";
import {
    app,
    ControlState,
    globalMatrix,
    IBooleanMap,
    IControlDefinition,
    IGenericMap,
    IItem,
    IItemGet,
    IItemPut,
    IReference,
    IStringNumberMap,
    matrixSession,
} from "../../../globals";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { IGateStatus } from "../../businesslogic/FieldHandlers/GateFieldHandler";
import { IFieldHandler } from "../../businesslogic/FieldHandlers/IFieldHandler";
import { DefaultCategorySettingNames } from "../../../admin/lib/categories/CategorySetting";

export type { IItemControlOptions, ILinkType };
export { ItemControl };

interface IItemControlOptions extends IBaseControlOptions {
    id?: string;
    control?: JQuery;
    type?: string;
    item?: IItemGet;
    dummyData?: unknown;
    parent?: string;
    changed?: (needsSave: boolean) => void;
    isForm?: boolean;
    isItem?: boolean;
    isPrint?: boolean;
    isHistory?: number;
    isDialog?: boolean;
    canEdit?: boolean;
    canEditLabels?: boolean;
    canEditTitle?: boolean;
    disableTinyMce?: boolean;
}

interface ILinkType {
    type: string;
    name?: string;
    buttonName?: string;
    folder?: boolean;
    import?: boolean;
    required?: boolean;
}

class ItemControl {
    private settings: IItemControlOptions;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private defaultOptions: IItemControlOptions = <any>{
        control: null, // required, no default: html node in which to paint control
        type: null, // required, if no item is supplied
        controlState: ControlState.FormView, // read only rendering
        item: null, // item or null
        dummyData: false, // fill controls with a dumy data (for form design...)
        parent: null, // parent folder if there is one... interesting for delete button and creation
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        changed: function () {}, // callback to call if value changes
    };
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private resizeItTimer: number;
    controls: IControlDefinition[] = [];
    private _title = $("<div>");
    private _riders = $("<div>");
    private _outerbody = $("<div>"); // outerbody will contain title and body of the item.  the title might be inside the item (e.g. for print rendering)
    private _body = $("<div>");
    private config: ICategoryConfig;
    private title: string;
    private links: IReference[];
    private orginalControlState: ControlState;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private startEdit: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private duringFill: boolean;
    private restrictUnlockTo: string[];
    static lastTab: IStringNumberMap = {};
    private lastIsDropDown: boolean;

    constructor(options: IItemControlOptions) {
        let that = this;
        const allowAddLinkToLocked = matrixSession.getUISettings().allowAddLinkToLocked;

        this.settings = ml.JSON.mergeOptions(this.defaultOptions, options);

        this.title = "";
        if (this.settings.item) {
            try {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.title = decodeURIComponent(this.settings.item.title);
            } catch (err) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.title = this.settings.item.title;
            }
            if (this.settings.item.type) {
                this.settings.type = this.settings.item.type; // overwrite,if an item has been passed
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.orginalControlState = this.settings.controlState;

        // check if a locking label is set
        let canUnlock = false;
        this.restrictUnlockTo = [];
        if (this.settings.controlState === ControlState.FormEdit) {
            let ll = globalMatrix.ItemConfig.getLabelLockConfig();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (ll && this.settings.item.labels) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let ldefs = ml.LabelTools.getLabelDefinitions([this.settings.type]).map(function (ld) {
                    return ld.label;
                });

                $.each(ll.locks, function (idx, lock) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (that.settings.item.labels.indexOf(lock.label) !== -1 && ldefs.indexOf(lock.label) != -1) {
                        // locking label is set...
                        that.settings["allowlinkedit"] =
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            that.settings.controlState == ControlState.FormEdit &&
                            (allowAddLinkToLocked || lock.allowTraces);
                        that.settings.controlState = ControlState.FormView;
                        that.settings["locked"] = lock.label;
                        that.settings["unlockers"] = lock.lockKeeper
                            ? lock.lockKeeper
                                  .map(function (keeper) {
                                      return ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(keeper);
                                  })
                                  .join(", ")
                            : "";

                        let canUnlockThis = matrixSession.amIAllowedUser(lock.lockKeeper);
                        if (canUnlockThis) {
                            canUnlock = true;
                            that.restrictUnlockTo.push(lock.label);
                        }
                    }
                });
            }
        }
        // initialize object

        // once a SIGN has it's first signature it cannot be changed anymore
        this.settings["isDialog"] =
            this.settings.controlState === ControlState.DialogCreate ||
            this.settings.controlState === ControlState.DialogEdit;
        this.settings["isForm"] =
            this.settings.controlState === ControlState.FormEdit ||
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.FormView;
        // signed docs cannot be changed as soon as one signature was given
        this.settings["canEditLinks"] =
            this.settings.controlState === ControlState.FormEdit ||
            this.settings.controlState === ControlState.DialogCreate ||
            this.settings.controlState === ControlState.DialogEdit;
        this.settings["canEdit"] = this.settings["canEditLinks"];

        // besides labels....
        this.settings["canEditLabels"] =
            canUnlock ||
            this.settings.controlState === ControlState.FormEdit ||
            this.settings.controlState === ControlState.DialogCreate;

        // special rule for title and title bar functions: it should for example be possible to delete already signed docs if the user role allows it...
        this.settings["canEditTitle"] =
            this.settings.controlState === ControlState.FormEdit ||
            this.settings.controlState === ControlState.DialogCreate;
        this.settings["canDelete"] = this.settings.controlState === ControlState.FormEdit;
        this.settings["isPrint"] = this.settings.controlState === ControlState.Print;
        this.settings["isTooltip"] = this.settings.controlState === ControlState.Tooltip;
        this.settings["id"] = this.settings.item ? this.settings.item.id : "";

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.links = this.settings.item ? this.settings.item.downLinks : [];

        this.config = globalMatrix.ItemConfig.getItemConfiguration(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.isItem ? this.settings.type : "FOLDER",
        );

        // prepare rendering
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.settings.isForm && this.settings.controlState != ControlState.HistoryView) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            $(this.settings.control).addClass("panel panel-default");
        }

        // add title control to correct place and prepare it for printing
        this.controls.push({ control: this._title, name: "Title" });
        if (this.settings.isPrint) {
            let header = $("<table class='table-bordered printheader'></table>");
            let d = new Date();

            header.append(
                "<tr><td>" +
                    "<img class='brandLogo'>" +
                    "</td>" +
                    "<td id='iph'></td>" +
                    "<td class='reportheaderdate'>printed: " +
                    ml.UI.DateTime.renderHumanDate(d) +
                    "</td></tr>",
            );

            let logo = globalMatrix.ItemConfig.getSetting("projectLogo");
            if (logo && logo.indexOf("{") !== -1) {
                let rjs = ml.JSON.fromString(logo);
                if (rjs.status === "ok") {
                    logo = (<IProjectLogo>rjs.value).fileId;
                }
                header
                    .find(".brandLogo")
                    .attr("src", globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + logo);
            } else {
                header.find(".brandLogo").attr("src", globalMatrix.matrixBaseUrl + "/img/logo_black.svg");
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.settings.item && !app.isFolder(this.settings.id)) {
                header.append(
                    "<tr><td>version: " +
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.settings.item.history[0].version +
                        " (" +
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.settings.item.history[0].user +
                        ")</td>" +
                        "<td>comment: " +
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.settings.item.history[0].comment +
                        "</td>" +
                        "<td class='reportheaderdate'>created: " +
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.settings.item.history[0].dateUserFormat +
                        "</td></tr>",
                );
            }
            $("#iph", header).append(this._title);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.control.append(header);
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.settings.isForm && this.settings.controlState != ControlState.HistoryView) {
            this._title.addClass("panel-heading");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.control.append(this._title);

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.control.append(this._outerbody);
            this._outerbody.on("scroll", () => {
                $(".tox-pop").remove();
            });
            this._outerbody.addClass("panel-body-v-scroll").addClass("enabledd");
            this._body.addClass("panel-body");
        } else {
            // isDialog or isPrint
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.control.append(this._outerbody);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.settings.controlState == ControlState.HistoryView) {
                this._outerbody.append($("<div class='baseControlHelp'>Title</div>"));
            }
            if (!this.settings.isPrint) {
                this._outerbody.append(this._title);
            }
            this._outerbody.on("scroll", () => {
                $(".tox-pop").remove();
            });
            this._outerbody.addClass("panel-body-v-scroll").addClass("enabledd");
        }

        this.addTabs();

        this._outerbody.append(this._body);

        // create content controls
        this.lastIsDropDown = false;
        let list = this.config.fieldList;
        for (let idx = 0; idx < list.length; idx++) {
            let ctrl = $("<div>");
            this._body.append(ctrl);
            this.controls.push({ control: ctrl, name: list[idx].label, fieldId: list[idx].id });
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.lastIsDropDown = list[idx].fieldType == FieldDescriptions.Field_dropdown;
        }
        // add special this.controls, e.g. to create items in folders, or reference list or report buttons
        // these exist only if these are not special items
        if (
            this.settings.controlState !== ControlState.HistoryView &&
            this.settings.controlState !== ControlState.Zen &&
            this.settings.controlState !== ControlState.Review
        ) {
            let handled = plugins.renderActionButtons(this.settings, this._body, this.controls);

            if (!handled) {
                handled = mTM.RenderActionButtons(this.settings, this._body);
            }

            if (!handled) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (!handled && this.settings.type.toLowerCase() === "report") {
                    this.renderActionButtonsReport();
                } else if (
                    !this.settings.isItem &&
                    this.settings.controlState === ControlState.FormEdit &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    !globalMatrix.ItemConfig.getCategorySetting(
                        this.settings.type!,
                        DefaultCategorySettingNames.syncInfo,
                    ) &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    !app.getImportSource(this.settings.item)
                ) {
                    // a normal folder
                    // add button to create a new folder of this kind
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let lt: ILinkType[] = [{ type: this.settings.item.type, name: "Folder", folder: true }];
                    // add button to create an item of this kind
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let itemTypeName = globalMatrix.ItemConfig.getItemConfiguration(this.settings.item.type).label;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    lt.push({ type: this.settings.item.type, name: itemTypeName });

                    let controlContainer = $("<div class='controlContainer'></div>");
                    // add buttons to UI
                    controlContainer.append($("<span class='baseControlHelp'>Tools</span>"));
                    let folderEdit = $("<div class='hidden-print baseControl rowFlex'></div>");
                    controlContainer.append(folderEdit);

                    let folderToolsLocation = globalMatrix.ItemConfig.getCategorySetting(
                        this.settings.item!.type!,
                        DefaultCategorySettingNames.folderToolsLocation,
                    ) as ICategorySettingToolLocation;
                    if (folderToolsLocation && folderToolsLocation.location === "top") {
                        this._body.prepend(controlContainer);
                    } else {
                        this._body.append(controlContainer);
                    }

                    let createTools = new ItemCreationTools();
                    createTools.renderButtons({
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        parent: this.settings.item.id,
                        dontOpenNewItem: false,
                        control: folderEdit,
                        linkTypes: lt,
                        type: that.settings.type,
                    });
                }
            }
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async load() {
        await this.fillControls();
        if (!this.settings.isPrint && !this.settings.isTooltip) {
            this.sendNeedsSave();
            if (this.lastIsDropDown && this.settings.canEdit) {
                this._body.css("padding-bottom", "200px");
            }
        }
        // hide stuff and after a little timeout to allow to show richtext2, hide again
        this.showTab();
        window.setTimeout(() => {
            this.showTab();
        }, 501);
        // to enable dropdown menus at end to open completely
        this.resizeItem();
    }
    // initialize options

    // public interface
    destroy(): void {
        for (let idx = 0; idx < this.controls.length; idx++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.controls[idx].control.getController().destroy) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.controls[idx].control.getController().destroy();
            }
        }
    }

    async getValues(update: IItemPut, latest?: IItemGet): Promise<IItemPut> {
        for (let idx = 0; idx < this.controls.length; idx++) {
            if (
                this.controls[idx].fieldId &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.getFieldType(this.controls[idx].fieldId) !== FieldDescriptions.Field_links
            ) {
                if (this.controls[idx].isDhfType) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (<IGenericMap>update)[this.controls[idx].fieldId] = await mDHF.getValue(this.controls[idx]);
                } else if (this.controls[idx].fieldType === FieldDescriptions.Field_labels) {
                    if (this.settings.id && !app.isFolder(this.settings.id)) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (await this.controls[idx].control.getController().getValueAsync(latest)) {
                            let non_review_labels = JSON.parse(
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                await this.controls[idx].control.getController().getValueAsync(latest),
                            );
                            non_review_labels = ml.LabelTools.resetReviewLabels(
                                non_review_labels,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                this.settings.type,
                                false,
                            );
                            update.labels = non_review_labels.join(",");
                        }
                    }
                } else {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (<IGenericMap>update)[this.controls[idx].fieldId] = await this.controls[idx].control
                        .getController()
                        .getValueAsync(latest);
                }
            }
        }

        return update;
    }

    async saveAsync(category: string, auditAction: string, valueOverwrites?: IItemGet): Promise<IDBParent | IItemGet> {
        let that = this;
        let canSaveProceed = true;
        for (const control of this.controls) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            const internalController = control.control.getController();
            let value = await internalController.getValueAsync();
            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                category != "DOC" &&
                internalController.requiresContent &&
                internalController.requiresContent() &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                value == ""
            ) {
                ml.UI.showError(
                    `Field "${control.name}" is empty!`,
                    `The Project configuration requires the field ${control.name} to have a value.`,
                );
                canSaveProceed = false;
            }
            if (!Cleanup.textOk(value)) {
                ml.UI.showError(
                    `Field "${control.name}" has an invalid character!`,
                    `Remove invalid character to save - they will look probably be rendered as boxes, like \u0002`,
                );
                canSaveProceed = false;
            }
        }
        if (!canSaveProceed) {
            throw "Can't save";
        }
        ml.SmartText.updateCaptionsAndReferences();

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        async function performSaveAsync(latest?: IItemGet) {
            let res = $.Deferred();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let type = that.settings.type ? that.settings.type : ml.Item.parseRef(that.settings.parent).type;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            await mTM.PreSaveHook(that.settings.isItem, that.settings.item, type, that.controls);

            plugins
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .callPreSaveHook(that.settings.isItem, type, that.controls)
                .done(async function () {
                    // create links and labels and save
                    let linksToCreate: { up: string[]; down: string[] } = { up: [], down: [] };
                    let labelsToSet: string[] = [];
                    for (let idx = 0; idx < that.controls.length; idx++) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (that.controls[idx].control.getController().linksToCreate) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            let lc = that.controls[idx].control.getController().linksToCreate();
                            linksToCreate.up = linksToCreate.up.concat(lc.up);
                            linksToCreate.down = linksToCreate.down.concat(lc.down);
                        }
                        // if a control decides it can trigger labels to be set
                        if (
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.controls[idx].control.getController().labelsToSet &&
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.controls[idx].control.getController().labelsToSet() &&
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.controls[idx].control.getController().labelsToSet().length > 0
                        ) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            labelsToSet = labelsToSet.concat(that.controls[idx].control.getController().labelsToSet());
                        }
                    }

                    if (
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        (that.settings.isForm && that.settings.controlState != ControlState.HistoryView) ||
                        that.settings.controlState === ControlState.DialogEdit
                    ) {
                        // an item was modified
                        for (let idx = 0; idx < that.controls.length; idx++) {
                            if (
                                valueOverwrites &&
                                that.controls[idx].fieldId &&
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                valueOverwrites.hasOwnProperty(that.controls[idx].fieldId.toString())
                            ) {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                that.controls[idx].control
                                    .getController()
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    .setValue((<IGenericMap>valueOverwrites)[that.controls[idx].fieldId]);
                            }
                        }
                        let update: IItemPut = {
                            id: that.settings.id,
                            title: that._title.data("new"),
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            type: that.settings.item.type,
                        };

                        if (linksToCreate.up.length > 0) {
                            update["linksUp"] = linksToCreate.up.join();
                        }
                        if (linksToCreate.down.length > 0) {
                            update["linksDown"] = linksToCreate.down.join();
                        }

                        update = await that.getValues(update, latest);

                        if (that.settings.isItem) {
                            if (valueOverwrites && valueOverwrites.labels) {
                                update.labels = valueOverwrites.labels.join(",");
                            }
                        }

                        // now update labels if there is something to update
                        if (labelsToSet.length) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            update.labels = ml.LabelTools.setLabels(update.labels, labelsToSet);
                        }
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        MR1.triggerBeforeSaveAsync(that, that.settings.item, update)
                            .done(function () {
                                app.updateItemInDBAsync(
                                    update,
                                    auditAction,
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    latest ? latest.history.length : that.settings.item.history.length,
                                )
                                    .done(function (result) {
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        update.maxVersion = result.maxVersion;
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        MR1.triggerAfterSave(that, that.settings.item, update);
                                        res.resolve(result);
                                    })
                                    .fail(function (error) {
                                        res.reject(error);
                                    });
                            })
                            .fail(function () {
                                ml.Logger.log("info", "save cancelled by MR1 script");
                                res.reject("MR1 cancelled");
                            });
                    } else {
                        // an item was created
                        let actions: string[] = [];
                        actions.push("Created");
                        let itemJson: IItemPut = {};
                        if (!that.settings.isItem) {
                            itemJson["children"] = []; // make it a folder
                        }
                        for (let idx = 0; idx < that.controls.length; idx++) {
                            if (
                                that.controls[idx].fieldType === FieldDescriptions.Field_labels &&
                                that.settings.isItem
                            ) {
                                itemJson.labels = "";
                                let valj = ml.JSON.fromString(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    await that.controls[idx].control.getController().getValueAsync(),
                                );
                                if (valj.status === "ok") {
                                    itemJson.labels = (<string[]>valj.value).join();
                                }
                            } else if (that.controls[idx].fieldId) {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                (<IGenericMap>itemJson)[that.controls[idx].fieldId] = await that.controls[idx].control
                                    .getController()
                                    .getValueAsync();
                            }
                        }

                        itemJson.title = that._title.data("new");
                        if (linksToCreate.up.length > 0) {
                            itemJson.linksUp = linksToCreate.up.join();
                        }
                        if (linksToCreate.down.length > 0) {
                            itemJson.linksDown = linksToCreate.down.join();
                        }
                        if (labelsToSet.length) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            itemJson.labels = ml.LabelTools.setLabels(itemJson.labels, labelsToSet);
                        }

                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        app.createItemOfTypeAsync(category, itemJson, actions.join(), that.settings.parent)
                            .done(function (result) {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                MR1.triggerAfterItemCreate(result);

                                res.resolve(result);
                            })
                            .fail(function () {
                                res.reject();
                            });
                    }
                })
                .fail(function () {
                    ml.Logger.log("warning", "plugin prevented save!");
                    res.reject("plugin prevented save!");
                    return res;
                })
                .always(() => {
                    app.setSaving(false);
                });
            return res;
        }

        let needsLatest = false;
        if (this.settings.id) {
            // otherwise this is save during create
            for (let idx = 0; idx < this.controls.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this.controls[idx].control.getController().needsLatest) {
                    needsLatest = true;
                }
            }
        }

        if (needsLatest) {
            app.setSaving(true);

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let latest = await app.getItemAsync(this.settings.id);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return performSaveAsync(latest);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return performSaveAsync();
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addMove(itemId: string, newVersion: number) {
        // fakes a history change without reloading the item, avoiding merge dialog when saving
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.settings && this.settings.id == itemId && this.settings.item && this.settings.item.history) {
            if (
                this.settings.item.history.filter(function (he) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return he.version == newVersion;
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                }).length == 0
            ) {
                // someone else moved it
                this.settings.item.history.push({
                    action: "move",
                    comment: "",
                    date: "",
                    dateUserFormat: "",
                    id: itemId,
                    title: "",
                    user: "", // fake entry
                    version: newVersion,
                });
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem(force?: boolean) {
        clearTimeout(this.resizeItTimer);
        this.resizeItTimer = window.setTimeout(() => this.resizeIt(force), 299);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refreshLinks() {
        for (let cid = 0; cid < this.controls.length; cid++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.controls[cid].control.getController().refreshLinks) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.controls[cid].control.getController().refreshLinks();
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async fillControls() {
        let that = this;
        const allowAddLinkToLocked = matrixSession.getUISettings().allowAddLinkToLocked;
        this.duringFill = true;
        // prepare gate functions
        let gateTitle = false;
        let gated: IBooleanMap = {}; // if set to true the field will be disabled
        for (let idx = 0; idx < this.config.fieldList.length; idx++) {
            let fieldType = this.config.fieldList[idx].fieldType;

            if (fieldType === FieldDescriptions.Field_gateControl) {
                // get the fields value (for existing items)
                let fv = this.settings.item ? (<IGenericMap>this.settings.item)[this.config.fieldList[idx].id] : "{}";
                let fvj = <IGateStatus>(fv ? JSON.parse(fv) : {});
                let allPassed = fvj.passed;
                let gateConfig = <IGate>this.config.fieldList[idx].parameterJson;
                if (gateConfig && gateConfig.allPass) {
                    if (allPassed && gateConfig.allPass.lockAbove) {
                        // if passed the above will be disabled
                        for (let k = 0; k < idx; k++) {
                            gated[this.config.fieldList[k].id] = true;
                        }
                    }
                    if (allPassed && gateConfig.allPass.lockTitle) {
                        gateTitle = true;
                    }
                    if (!allPassed && gateConfig.allPass.enableBelow) {
                        // needs to be passed to enable the below
                        for (let k = idx + 1; k < this.config.fieldList.length; k++) {
                            gated[this.config.fieldList[k].id] = true;
                        }
                    }
                } else {
                    ml.Logger.log("warning", "badly configured gate control in " + this.settings.type);
                }
            }
        }

        /** in case up or downlinks would be locked because of gate allow them */
        if (allowAddLinkToLocked) {
            for (let k = 0; k < this.config.fieldList.length; k++) {
                if (
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    this.config.fieldList[k].fieldType == FieldDescriptions.Field_uplinkinfo ||
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    this.config.fieldList[k].fieldType == FieldDescriptions.Field_links
                ) {
                    gated[this.config.fieldList[k].id] = false;
                }
            }
        }

        // render title
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let th = <ICategorySettingTitle>globalMatrix.ItemConfig.getCategorySetting(this.settings.type, "title");
        let tops = <IBaseControlOptions>ml.JSON.mergeOptions(this.settings, {
            help: th && th.create ? th.create : "Title",
            title: this.title,
            valueChanged: () => that.sendNeedsSave(),
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            isFolder: app.isFolder(this.settings.id),
            dummyData: this.settings.dummyData,
            parameter: {
                placeholder: th && th.placeholder ? th.placeholder : "enter title",
            },
        });
        if (gateTitle) {
            tops.canEditTitle = false;
            tops.canDelete = false;
            tops.canEdit = false;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!globalMatrix.ItemConfig.canEditTitle(this.settings.type)) {
            tops.canEditTitle = false;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!globalMatrix.ItemConfig.canDelete(this.settings.type)) {
            tops.canDelete = false;
        }

        this._title.html("").titleToolbar(tops);

        // draw UI
        let closeControls: JQuery[] = [];

        // make a lookup with controls
        let mapControls: { [key: number]: IControlDefinition } = {};
        for (let cid = 0; cid < this.controls.length; cid++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            mapControls[this.controls[cid].fieldId] = this.controls[cid];
        }

        // go through all configured fields

        // for performance hide
        if (
            matrixSession.getUISettings().largeFormRender &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.config.fieldList.length > matrixSession.getUISettings().largeFormRender
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.control.hide();
        }

        for (let idx = 0; idx < this.config.fieldList.length; idx++) {
            const fieldConfig: XRFieldTypeAnnotated = this.config.fieldList[idx];
            const fieldType = fieldConfig.fieldType;

            let ui = mapControls[fieldConfig.id];
            if (!ui) {
                ml.Logger.log("warn", `field ${fieldConfig.id} is weird...`);
                break;
            }
            ui.isDhfType = false;
            ui.fieldType = fieldType;

            // If we have initialContent we need to be able to tell the difference between empty string and undefined.
            let fieldValue = "";
            if (fieldConfig && fieldConfig.parameterJson && fieldConfig.parameterJson.initialContent) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                fieldValue = undefined;
            }

            if (this.settings.item && this.settings.item[fieldConfig.id]) {
                fieldValue = (<IGenericMap>this.settings.item)[fieldConfig.id];
            }

            // Some field types get their configuration not from the database, but elsewhere.
            // UpdateFieldConfig modifies the fieldConfig.parameterJson object in place to
            // include those settings.
            FieldHandlerFactory.UpdateFieldConfig(
                globalMatrix.ItemConfig,
                mTM.getConfiguration(),
                fieldType,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.type,
                fieldValue,
                fieldConfig.parameterJson,
            );

            let handler: IFieldHandler = FieldHandlerFactory.CreateHandler(
                globalMatrix.ItemConfig,
                fieldType,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                fieldConfig.parameterJson,
            );
            handler.initData(fieldValue);

            // prepare parameter for rendering
            let ctrlParameter = ml.JSON.mergeOptions(that.settings, {
                parameter: fieldConfig.parameterJson,
                fieldId: ui.fieldId,
                canEdit:
                    that.settings.canEdit &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    app.canEditField(that.settings.type, ui.fieldId) &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    !gated[ui.fieldId] &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    !fieldConfig.parameterJson.readonlyACL,
                help: fieldConfig.label,
                fieldValue: fieldValue, // TODO: to be removed in favor of fieldHandler
                fieldHandler: handler,
                fieldType: fieldType,
                valueChanged: function () {
                    that.sendNeedsSave(ui.fieldId, fieldConfig.label);
                },
            });

            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (fieldType == FieldDescriptions.Field_uplinkinfo || fieldType == FieldDescriptions.Field_links) &&
                !ctrlParameter.canEdit
            ) {
                // maybe links can still be added even though the item is readonly
                if (allowAddLinkToLocked) {
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        (that.settings["allowlinkedit"] && !ctrlParameter.parameter.readonlyACL) ||
                        that.settings["canEditLinks"]
                    ) {
                        ctrlParameter.canEdit = true;
                    }
                }
            }

            // whether there should a > to open / close section
            let canCloseSection =
                that.settings.item &&
                ctrlParameter.isItem &&
                !ctrlParameter.isPrint &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ctrlParameter.controlState != ControlState.HistoryView &&
                ctrlParameter.isForm &&
                mDHF &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                mDHF.isDocumentType(that.settings.item.type) &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                !ctrlParameter.parameter.forceOpen;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (typeof ctrlParameter.parameter.visibleOption !== "undefined") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (ml.JSON.isTrue(ctrlParameter.parameter.visibleOption)) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                } else if (ctrlParameter.parameter.visibleOption === this.settings.type) {
                } else {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ui.control.hide();
                }
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!app.canSeeField(this.settings.type, ui.fieldId)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hide();
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ui.control
                .html("")
                .addClass("controlContainer")
                .addClass("ft_" + fieldType)
                .attr("id", "fid_" + ui.fieldId);
            if (plugins.supportsControl(fieldType)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                plugins.createControl(ui.control, ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_report) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.plainText(ml.JSON.setOptions(ctrlParameter, { parameter: { rows: 1 } }));
                globalMatrix.EmbeddedReport(ml.JSON.setOptions(ctrlParameter, { control: ui.control }));
            } else if (fieldType === FieldDescriptions.Field_richtext) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctrlParameter.parameter.height = undefined;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.richText(ctrlParameter, this);
            } else if (fieldType === FieldDescriptions.Field_publishedItemList) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_publishedFilesList) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_publishedContent) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.publishedContent(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_text) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.plainText(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_section) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.section(ctrlParameter);
            } else if (
                fieldType === FieldDescriptions.Field_fileManager ||
                fieldType === FieldDescriptions.Field_signCache
            ) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.fileManager(
                    ml.JSON.setOptions(ctrlParameter, { parameter: { titleBarControl: this._title } }),
                );
            } else if (fieldType === FieldDescriptions.Field_docFilter) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.docFilter(ml.JSON.setOptions(ctrlParameter, { help: "Document Filter" }));
            } else if (fieldType === FieldDescriptions.Field_workflow) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.workflowControl(
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    <any>ml.JSON.setOptions(ctrlParameter, { parameter: { titleBarControl: this._title } }),
                );
            } else if (fieldType === "sourceRef") {
                // type should not exist
                ml.Logger.log("warning", "Found obsolete type sourceRef");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (
                fieldType === FieldDescriptions.Field_textline ||
                fieldType === FieldDescriptions.Field_publishedTitle
            ) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.plainText(ml.JSON.setOptions(ctrlParameter, { parameter: { rows: 1, allowResize: false } }));
            } else if (fieldType === FieldDescriptions.Field_signatureControl) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.docSign(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_docTraining) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ui.control.docTraining(<any>ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_docReview) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ui.control.docReview(<any>ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_user) {
                // Set some UI options for the user control.
                // Data field options were already set above in FieldHandlerFactory.UpdateFieldConfig().
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let groups: IDropdownGroup[] = null;
                if (
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    !ml.JSON.isFalse(ctrlParameter.parameter.showUsers) &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ml.JSON.isTrue(ctrlParameter.parameter.showGroups)
                ) {
                    groups = [];
                    groups.push({ value: "groups", label: "groups" });
                    groups.push({ value: "users", label: "users" });
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (!ctrlParameter.parameter.placeholder) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctrlParameter.parameter.placeholder = "select user";
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctrlParameter.parameter.groups = groups;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.mxDropdown(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_date) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.dateselect(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_dropdown) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.mxDropdown(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_test_result) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctrlParameter.parameter.sort = false;
                let placeholder = fieldValue ? fieldValue : null;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctrlParameter.parameter.placeholder = mTM.getTestRunResultPlaceholder(placeholder);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.mxDropdown(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_links) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let linkTypes = app.getLinkCategories(this.settings.item, <ILinkCollectionOptions>ctrlParameter);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.linkCollection(
                    ml.JSON.setOptions(ctrlParameter, <ILinkCollectionOptions>{
                        parameter: {
                            linkTypes: linkTypes,
                        },
                        fieldValue: this.links,
                    }),
                );
            } else if (fieldType === FieldDescriptions.Field_crosslinks) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.itemSelection(ctrlParameter);
            } else if (
                fieldType === FieldDescriptions.Field_steplist ||
                fieldType === FieldDescriptions.Field_test_steps ||
                fieldType === FieldDescriptions.Field_test_steps_result
            ) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.tableCtrl(ctrlParameter);
                // ui.control.tableCtrl(ml.JSON.setOptions(options, {parameter: this.getTestStepsConfig(options.type)}));
            } else if (fieldType === FieldDescriptions.Field_risk) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.riskCtrl(
                    ml.JSON.setOptions(ctrlParameter, {
                        links: this.links ? this.links : [], // these are the really existing downlinks (i.e. if someone deleted and item it needs to be removed as mitigation)
                    }),
                );
            } else if (fieldType === FieldDescriptions.Field_risk2) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.riskCtrl2(
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    <any>ml.JSON.setOptions(ctrlParameter, {
                        links: this.links ? this.links : [], // these are the really existing downlinks (i.e. if someone deleted and item it needs to be removed as mitigation)
                    }),
                );
            } else if (fieldType === FieldDescriptions.Field_checkbox) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.checkBox(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_htmlForm) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.htmlform(ctrlParameter);
            } else if (
                fieldType === FieldDescriptions.Field_hidden ||
                fieldType === FieldDescriptions.Field_filter_file ||
                fieldType === FieldDescriptions.Field_signature
            ) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_hyperlink) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hyperlink(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_colorPicker) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.colorPicker(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_reportId) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_dhf) {
                canCloseSection = mDHF.renderControl(ui, ctrlParameter, fieldValue);
            } else if (fieldType === FieldDescriptions.Field_sourceref) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                canCloseSection = !ctrlParameter.parameter.readonly;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.sourceRef(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_markAsTemplate) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                canCloseSection = !ctrlParameter.parameter.readonly;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.markAsTemplate(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_guid) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.docGUID(ml.JSON.setOptions(ctrlParameter, { parameter: { titleBarControl: this._title } }));
            } else if (fieldType === FieldDescriptions.Field_oid) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.docOID(ml.JSON.setOptions(ctrlParameter, { parameter: { titleBarControl: this._title } }));
            } else if (fieldType === FieldDescriptions.Field_versionLive) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_version) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hidden(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_currentVersion) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.docVersionInfo(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_uplinkinfo) {
                ui.control?.uplinkinfo(
                    ml.JSON.setOptions(ctrlParameter, {
                        parameter: {
                            linkTypes: that.settings.item
                                ? app.getLinkCategories(that.settings.item, <ILinkCollectionOptions>ctrlParameter)
                                : [],
                        },
                        fieldValue: that.settings.item ? that.settings.item.upLinks : [],
                    }),
                );
            } else if (fieldType === FieldDescriptions.Field_labels) {
                canCloseSection = false;
                ctrlParameter.fieldValue =
                    this.settings.item && this.settings.item.labels ? JSON.stringify(this.settings.item.labels) : "";
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.labelsControl(
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    <any>ml.JSON.setOptions(ctrlParameter, {
                        restrictEditTo: that.restrictUnlockTo.length ? that.restrictUnlockTo : null,
                        canEdit:
                            (<IItemControlOptions>ctrlParameter).canEditLabels &&
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            globalMatrix.ItemConfig.canModifyLabels(that.settings.type),
                        parameter: { titleBarControl: this._title },
                    }),
                );
            } else if (fieldType === FieldDescriptions.Field_syncStatus) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.syncStatus(ml.JSON.setOptions(ctrlParameter, { controlState: this.orginalControlState }));
            } else if (fieldType === FieldDescriptions.Field_syncSourceInfo) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.syncSourceInfo(ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_cascadingSelect) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ui.control.cascadingSelect(<any>ctrlParameter);
            } else if (fieldType === FieldDescriptions.Field_gateControl) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                ui.control.gateControl(<any>ctrlParameter);
            } else {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.errorControl(ctrlParameter);
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (ctrlParameter.parameter.invisible) {
                canCloseSection = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ui.control.hide();
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (ctrlParameter.parameter.hide_UI) {
                if (globalMatrix.globalShiftDown && matrixSession.isAdmin()) {
                    $(".baseControl", ui.control).css("opacity", "0.8");
                    let jqTitleToChange = $(".baseControlHelp", ui.control);
                    jqTitleToChange.css("opacity", "0.8");
                    const children = jqTitleToChange.children();
                    if (children.length >= 1) {
                        // The baseControlHelp div can be a complex DOM object. In this case
                        // be content with annotating the first child.
                        jqTitleToChange = $(children[0]);
                    }
                    const newHtml = jqTitleToChange.text() + " (Hidden for normal user)";
                    jqTitleToChange.text(newHtml);
                } else {
                    canCloseSection = false;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ui.control.hide();
                }
            }

            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (that.settings.isHistory == undefined || that.settings.isHistory < 1) &&
                canCloseSection &&
                that.allowSectionClose(ui, ctrlParameter)
            ) {
                closeControls.push($(".baseControl", ui.control));
                $(".baseControl", ui.control).hide();
            }
        }

        // undo performance hide
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.control.show();

        setTimeout(
            function (ctrlsToHide: JQuery[]) {
                for (let idx = 0; idx < ctrlsToHide.length; idx++) {
                    // weird if controls are hidden only once tables like audit trail are fcked up
                    ctrlsToHide[idx].hide();
                    ctrlsToHide[idx].show();
                    ctrlsToHide[idx].hide();
                }
            },
            10,
            closeControls,
        );

        setTimeout(function () {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (that.settings.item && that.settings.isForm && that.settings.controlState != ControlState.HistoryView) {
                MR1.triggerItemDisplayed(that.settings.item, that);
            }
            if (!that.settings.item && that.settings.isDialog) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                MR1.triggerItemCreate(that, that.settings.isItem, that.settings.type);
            }
        }, 20);

        this.duringFill = false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async needsSave() {
        return await this.needsSaveImpl();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasTitle() {
        let title = await this._title.getController().getValueAsync();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return title && title != "";
    }

    updateItem(newItem: IItem): void {
        if (this.settings.id === newItem.id) {
            for (let cid = 0; cid < this.controls.length; cid++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this.controls[cid].control.getController().updateItem) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.controls[cid].control.getController().updateItem(newItem);
                }
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setFieldValue(fieldId: number, newValue: string) {
        for (let cid = 0; cid < this.controls.length; cid++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.controls[cid].fieldId == fieldId) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.controls[cid].control.getController().setValue(newValue);
                this.sendNeedsSave();
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getFieldValue(fieldId: number) {
        for (let cid = 0; cid < this.controls.length; cid++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.controls[cid].fieldId == fieldId) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return await this.controls[cid].control.getController().getValueAsync();
            }
        }
        return null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getCurrentTitle() {
        return await this._title.getController().getValueAsync();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setViewers(viewers: IItemWatched) {
        return (<TitleToolbarImpl>this._title.getController()).setViewers(viewers);
    }

    getControls(fieldType?: string): JQuery[] {
        let result: JQuery[] = [];
        for (let idx = 0; idx < this.config.fieldList.length; idx++) {
            for (let cid = 0; cid < this.controls.length; cid++) {
                if (this.controls[cid].fieldId === this.config.fieldList[idx].id) {
                    if (!fieldType || this.config.fieldList[idx].fieldType === fieldType) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        result.push(this.controls[cid].control);
                    }
                }
            }
        }
        return result;
    }
    /** returns (first) control with a given title */
    getControlByName(name: string): JQuery {
        for (let cid = 0; cid < this.controls.length; cid++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.controls[cid].name.toLowerCase() == name.toLowerCase()) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return this.controls[cid].control;
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }
    /** returns control with a given id */
    getControlById(fieldId: number): JQuery {
        for (let cid = 0; cid < this.controls.length; cid++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.controls[cid].fieldId == fieldId) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return this.controls[cid].control;
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    // check if the version displayed is older than the current one

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    wasUpdated(itemId: string, historyLength: number) {
        if (
            this.settings &&
            this.settings.item &&
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.settings.item.id == itemId &&
            this.settings.item.history &&
            this.settings.item.history.length < historyLength
        ) {
            return true;
        }
        return false;
    }

    // private functions

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addTabs() {
        let that = this;

        if (!this.settings.isForm || this.settings.isHistory) {
            return;
        }
        let tabDefinition = <ICategorySettingTabs>(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            globalMatrix.ItemConfig.getCategorySetting(this.settings.type, DefaultCategorySettingNames.tabs)
        );
        if (!tabDefinition || !tabDefinition.tabs || tabDefinition.tabs.length <= 1) {
            return;
        }

        let tabs = $(
            '<div id="itemTabs" style="color: #333333; background-color: var(--Grey8); margin-top: -4px; padding: 4px; border-top: 1px solid lightgray;">',
        ).insertAfter(this._title);
        $.each(tabDefinition.tabs, function (tabIdx, tab) {
            $("<span style='padding:4px;cursor:pointer'>")
                .html(tab.name)
                .appendTo(tabs)
                .click(function () {
                    that.showTab(tabIdx);
                });
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showTab(active?: number) {
        if (!this.settings.isForm || this.settings.isHistory) {
            return;
        }
        let tabDefinition = <ICategorySettingTabs>(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            globalMatrix.ItemConfig.getCategorySetting(this.settings.type, DefaultCategorySettingNames.tabs)
        );
        if (!tabDefinition || !tabDefinition.tabs || tabDefinition.tabs.length <= 1) {
            return;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (active == undefined) {
            // get favorite tab in this session in this project
            active = 0;
            let lasttab = ItemControl.lastTab[matrixSession.getProject() + ":" + this.settings.type];
            if (lasttab) {
                active = lasttab;
            }
        }
        // remember favorite tab in this session in this project
        ItemControl.lastTab[matrixSession.getProject() + ":" + this.settings.type] = active;

        $("#itemTabs span").css("font-weight", "100");
        $($("#itemTabs span")[active]).css("font-weight", "600");
        $.each(this.controls, function (cidx, control) {
            if (
                tabDefinition.tabs.filter(function (tab) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return tab.fields.indexOf("" + control.fieldId) != -1;
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                }).length == 0
            ) {
                // field not in a specific tab so don'T hide it (like title bar or labels)
            } else if (
                tabDefinition &&
                tabDefinition.tabs &&
                tabDefinition.tabs.length &&
                control.fieldId &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                tabDefinition.tabs[active].fields.indexOf("" + control.fieldId) != -1
            ) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                control.control.show();
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                control.control.hide();
            }
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private allowSectionClose(ctrl: IControlDefinition, ctrlParameter: IBaseControlOptions) {
        let isClosed =
            ctrlParameter.item &&
            globalMatrix.projectStorage.getItem("visible" + ctrlParameter.item.id + "_" + ctrl.fieldId) !== "true";

        // add > control open and close section
        let helpLabel = $("<label for='docfield" + ctrl.fieldId + "'>");
        let baseControlHelp = $(".baseControlHelp", ctrl.control);
        let checkbox = $(
            "<input id='docfield" +
                ctrl.fieldId +
                "' class='showHideAdmin' type='checkbox' " +
                (isClosed ? "" : "checked") +
                ">",
        );
        // put checkbox before label
        baseControlHelp.parent().prepend(checkbox);
        // put help into label
        baseControlHelp.wrap(helpLabel);

        // put triangle before help text
        baseControlHelp.before("<span class='cbimg fal fa-chevron-right'>");

        checkbox.change(function (e: JQueryEventObject) {
            let visibility = $(e.delegateTarget).is(":checked");
            globalMatrix.projectStorage.setItem(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                "visible" + ctrlParameter.item.id + "_" + ctrl.fieldId,
                visibility ? "true" : "false",
            );
            if (!visibility) {
                $(".baseControl", $(e.delegateTarget).closest("div")).hide();
                $(".inlineHelp", $(e.delegateTarget).closest("div")).hide();

                if (ctrl.control?.getController().closeEditor) {
                    ctrl.control.getController().closeEditor();
                }
            } else {
                $(".baseControl", $(e.delegateTarget).closest("div")).show();
                $(".inlineHelp", $(e.delegateTarget).closest("div")).show();
                // make sure table this.controls are nice shown
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (ctrl.control.getController().redraw) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctrl.control.getController().redraw();
                }
            }
        });

        return isClosed;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public resizeIt(forceRedraw?: boolean) {
        //Let calcultate the width of the form

        // add a scrollbar to the form if needed
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.settings.isForm && this.settings.controlState != ControlState.HistoryView) {
            //this._outerbody.height(this.settings.control.height() - this._title.outerHeight() - 8 - ($(".labelBar").height()? $(".labelBar").height():0));
        } else {
            forceRedraw = true; // dialogs should always completely redraw tables otherwise they are badly rendered
        }
        let hasScrollbar = this._outerbody.height() < this._body.height();
        // beautify widths if needed
        for (let idx = 0; idx < this.controls.length; idx++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.controls[idx].control.getController().resizeItem) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.controls[idx].control
                    .getController()
                    .resizeItem(this._outerbody.width() - (hasScrollbar ? 15 : 0), forceRedraw);
            }
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.settings.controlState == ControlState.FormView) {
            $("#main").css(
                "width",
                `calc(100vw - ${
                    $("#navLeft").width() +
                    $("#sidebar").width() +
                    $("#dragbar").width() +
                    $("#contextframesizer").width() +
                    $("#contextframe").width()
                }px)`,
            );
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async needsSaveImpl() {
        // label change while readonly..
        let labelChange = false;
        for (let idx = 0; idx < this.controls.length; idx++) {
            if (
                this.controls[idx] &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.controls[idx].fieldType == FieldDescriptions.Field_labels &&
                this.controls[idx].control &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.controls[idx].control.getController &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (await this.controls[idx].control.getController().hasChangedAsync())
            ) {
                labelChange = true;
            }
        }
        if (!this.settings.canEdit && !labelChange) {
            return false;
        }
        for (let idx = 0; idx < this.controls.length; idx++) {
            if (
                (this.controls[idx] &&
                    this.controls[idx].control &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.controls[idx].control.getController &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (await this.controls[idx].control.getController().hasChangedAsync())) ||
                (this.controls[idx].isDhfType && mDHF.configChanged(this.controls[idx]))
            ) {
                if (!this.startEdit && !this.duringFill && !this.settings.isDialog) {
                    this.startEdit = true;
                }
                return true;
            }
        }
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getFieldType(fieldId: number) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return globalMatrix.ItemConfig.getFieldType(this.settings.isItem ? this.settings.type : "FOLDER", fieldId);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async sendNeedsSave(fieldId?: number, fieldName?: string) {
        if (this.settings.changed) {
            const needsSave = await this.needsSave();

            this.settings.changed(needsSave);

            if (needsSave && fieldId) {
                this.markFieldAsUnsaved(fieldId);
            }
        }
        // give the gates a chance to reset themselves
        if (fieldId) {
            for (let idx = 0; idx < this.controls.length; idx++) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (this.controls[idx] && this.controls[idx].fieldType == FieldDescriptions.Field_gateControl) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (<GateControlImpl>this.controls[idx].control.getController()).changed(fieldId, fieldName);
                }
            }
        }
    }

    private markFieldAsUnsaved(fieldId: number): void {
        const control = this.controls.find((c) => c.fieldId === fieldId);
        control?.control
            ?.find(".baseControlHelp.contentNeeded")
            .removeClass("contentNeeded")
            .addClass("contentUnsaved");
    }

    private renderActionButtonsReport(): void {
        let that = this;

        let id = this.settings.item ? this.settings.item.id : "";

        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            globalMatrix.ItemConfig.getExtrasConfig().enableLegacyReport == undefined ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            globalMatrix.ItemConfig.getExtrasConfig().enableLegacyReport == "0"
        ) {
            that._body
                .html(`<p class="controlContainer" >REPORT is a legacy feature since MatrixReq v2.3. If required, ask your administrator to reactivate it.</p>
            <ul><li>Use <span class="link" id="EXPORTDOC">DOC (documents)</span> to create printable reports</li>
            <li>You can export data as EXCEL or XML in here: <span class="link" id="EXPORTURL">EXPORT</span></li>
            <li>You can export the data also in the admin client, selecting the project</li>
            </ul>`);
            $("#EXPORTURL").click(() => app.treeSelectionChangeAsync("EXPORT"));
            $("#EXPORTDOC").click(() => app.treeSelectionChangeAsync("F-DOC-1"));

            return;
        }
        let showTools = true;
        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.DialogCreate ||
            this.settings.controlState === ControlState.HistoryView
        ) {
            showTools = false;
        }

        let inputRestriction = false; // by default use input as specified in report
        let inputFilter: string[]; // by default no filter
        let inputItems: IReportInput[];
        let requiredItems: IReportInput[];

        if (this.settings.item && this.settings.item.selectSubTree && this.settings.item.selectSubTree.length) {
            inputRestriction = true;

            inputFilter = [];
            inputItems = [];

            for (let idx = 0; idx < this.settings.item.selectSubTree.length; idx++) {
                const cat = this.settings.item.selectSubTree[idx].category;
                if (globalMatrix.ItemConfig.getCategories().indexOf(cat) !== -1) {
                    inputFilter.push(cat);
                    inputItems.push({ to: this.settings.item.selectSubTree[idx].rootFolder });
                }
            }
        }

        if (this.settings.item && this.settings.item.requireSubTree) {
            requiredItems = [];
            for (let idx = 0; idx < this.settings.item.requireSubTree.length; idx++) {
                const cat = this.settings.item.requireSubTree[idx].category;
                if (globalMatrix.ItemConfig.getCategories().indexOf(cat) !== -1) {
                    requiredItems.push({ to: this.settings.item.requireSubTree[idx].rootFolder });
                }
            }
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function showInputSelectDialog() {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            async function saveAndCloseDialog() {
                let inputItems = await tree.getController().getValueAsync();

                //MATRIX-3722 Local (browser) storage of REPORT selections is screwed and make the REPORT unusable --> Change of the localstorage item and disable the sanitize
                if (rememberCheckbox.prop("checked")) {
                    globalMatrix.projectStorage.setItem(
                        "remember_items_selection_" + id,
                        JSON.stringify(inputItems),
                        true,
                    );
                } else {
                    globalMatrix.projectStorage.setItem("remember_items_selection_" + id, "", true);
                }
                dlg.dialog("close");
            }

            ml.Search.searchInDialog();

            let preferred = typeof mDHF !== "undefined" ? mDHF.getDefaultFormat("REPORT") : "docx";

            let formats: IDropDownButtonOption[] = [];
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let idx = 0; idx < that.settings.item.availableFormats.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let pos = preferred === that.settings.item.availableFormats[idx] ? 0 : formats.length;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                switch (that.settings.item.availableFormats[idx]) {
                    case "html":
                        formats.splice(pos, 0, {
                            name: "HTML",
                            click: async function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "html" },
                                    await tree.getController().getValueAsync(),
                                    requiredItems,
                                );
                                saveAndCloseDialog();
                                return false;
                            },
                        });
                        break;
                    case "docx":
                        formats.splice(pos, 0, {
                            name: "Word",
                            click: async function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "docx" },
                                    await tree.getController().getValueAsync(),
                                    requiredItems,
                                );
                                saveAndCloseDialog();
                                return false;
                            },
                        });
                        break;
                    case "pdf":
                        formats.splice(pos, 0, {
                            name: "PDF",
                            click: async function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "pdf" },
                                    await tree.getController().getValueAsync(),
                                    requiredItems,
                                );
                                saveAndCloseDialog();
                                return false;
                            },
                        });
                        break;
                    case "xml":
                        formats.splice(pos, 0, {
                            name: "XML",
                            click: async function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "xml" },
                                    await tree.getController().getValueAsync(),
                                    requiredItems,
                                );
                                saveAndCloseDialog();
                                return false;
                            },
                        });
                        break;
                    default:
                        break;
                }
            }

            let linkTypes: string[] = [];
            for (let idx = 0; idx < inputFilter.length; idx++) {
                linkTypes.push(inputFilter[idx]);
            }

            let selectedItems = ml.JSON.clone(inputItems);

            // check if selection was stored
            //MATRIX-3722 Local (browser) storage of REPORT selections is screwed and make the REPORT unusable --> Change of the localstorage item and disable the sanitize
            let selectionStored = globalMatrix.projectStorage.getItem("remember_items_selection_" + id, true);
            if (selectionStored && selectionStored !== "") {
                selectedItems = JSON.parse(selectionStored);
            }

            // build tree with default (root nodes) or selected items
            // TODO: convert to const and make sure it's still works
            // eslint-disable-next-line no-var
            var tree: JQuery = $("<div>").projectView({
                tree: app.getTree(linkTypes),
                controlState: ControlState.DialogCreate,
                selectedItems: selectedItems,
                canSelectItems: true,
                selectMode: SelectMode.auto,
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                collectionChanged: function (count: number) {},
            });

            let rememberCheckbox: JQuery;
            let dlg = $("#selectItemDlg");
            dlg.html("");
            dlg.append(tree);

            let niceSize = ml.UI.getNiceDialogSize(500, 400);
            dlg.dialog({
                autoOpen: true,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                title: that.settings.item.title + " Report - Select Input",
                height: niceSize.height,
                width: niceSize.width,
                modal: true,
                close: function () {
                    dlg.parent().html("");
                    dlg.css("display", "none");
                    // dlg is gone, remove highlights and back to global highlighting
                    ml.Search.endSearchInDialog();
                },
                resizeStop: function () {
                    dlg.resizeDlgContent([tree]);
                },
                open: function () {
                    if (formats.length > 0) {
                        $(".ui-dialog-buttonpane button:contains('Ok')", dlg.parent()).replaceWith(
                            ml.UI.createDropDownButton("Create", formats, true),
                        );
                    }

                    rememberCheckbox = $(
                        '<input type="checkbox" name="remember" ' + (selectionStored ? "checked" : "") + ">",
                    );

                    $(".ui-dialog-buttonpane button:contains(Remember)", dlg.parent()).replaceWith(
                        $('<label class="dlgCreateMultiple">').append(rememberCheckbox).append("Remember Selection"),
                    );
                },
                buttons: [
                    {
                        text: "Remember",
                        class: "",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {},
                    },
                    {
                        text: "Ok",
                        class: "btnDoIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {},
                    },
                    {
                        text: "Cancel",
                        class: "btnCancelIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            dlg.dialog("close");
                        },
                    },
                ],
            }).resizeDlgContent([tree], false);
        }
        function createReportButton(name: string): JQuery {
            let formats: IDropDownButtonOption[] = [];

            let preferred = typeof mDHF !== "undefined" ? mDHF.getDefaultFormat("REPORT") : "docx";

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let idx = 0; idx < that.settings.item.availableFormats.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let pos = preferred === that.settings.item.availableFormats[idx] ? 0 : formats.length;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                switch (that.settings.item.availableFormats[idx]) {
                    case "html":
                        formats.splice(pos, 0, {
                            name: "HTML",
                            click: function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "html" },
                                    inputRestriction ? inputItems : null,
                                    requiredItems,
                                );
                                return false;
                            },
                        });
                        break;
                    case "docx":
                        formats.splice(pos, 0, {
                            name: "Word",
                            click: function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "docx" },
                                    inputRestriction ? inputItems : null,
                                    requiredItems,
                                );
                                return false;
                            },
                        });
                        break;
                    case "pdf":
                        formats.splice(pos, 0, {
                            name: "PDF",
                            click: function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "pdf" },
                                    inputRestriction ? inputItems : null,
                                    requiredItems,
                                );
                                return false;
                            },
                        });
                        break;
                    case "xml":
                        formats.splice(pos, 0, {
                            name: "XML",
                            click: function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "xml" },
                                    inputRestriction ? inputItems : null,
                                    requiredItems,
                                );
                                return false;
                            },
                        });
                        break;
                    case "zip":
                        formats.splice(pos, 0, {
                            name: "Word with attachments",
                            click: function () {
                                ml.ReportGenerator.CreateReport(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id,
                                    { format: "zipdocx" },
                                    inputRestriction ? inputItems : null,
                                    requiredItems,
                                );
                                return false;
                            },
                        });
                        break;
                    default:
                        break;
                }
            }
            return ml.UI.createDropDownButton(name, formats, false);
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function RenderReportDefinitionDialog(body: JQuery, controls: IControlDefinition[]) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let selectedReport: XRReportType = null;

            let dlgInner = body.parent();
            let dlgOuter = dlgInner.parent();

            let titleCtrl: JQuery;
            let decriptionCtrl: JQuery;
            let decriptionCtrlValue: string;
            let reportIdCtrl: JQuery;

            for (let idx = 0; idx < controls.length; idx++) {
                if (controls[idx].name === "Title") {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    titleCtrl = controls[idx].control;
                    titleCtrl.hide();
                } else if (controls[idx].name === "Description") {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    decriptionCtrl = controls[idx].control;
                } else if (controls[idx].name === "reportId") {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    reportIdCtrl = controls[idx].control;
                    reportIdCtrl.hide();
                }
            }

            setTimeout(function () {
                dlgOuter.dialog("option", "height", 500);
                dlgOuter.dialog("option", "width", 730);
                $(".dlgCreateMultiple", dlgOuter.parent()).hide();
            }, 1);

            app.getAvailableReportsAsync().done(function (availableReports) {
                let reportSelection: IDropdownOption[] = [];
                let groups: string[] = [];
                let groupList: { value: string; label: string }[] = [];

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                for (let idx = 0; idx < availableReports.reportList.length; idx++) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let group = availableReports.reportList[idx].group;
                    group = group ? group : "hidden"; // by default do not show 1.5 and earlier reports
                    if (group.indexOf("MR_") === 0) {
                        let dhf_config = globalMatrix.ItemConfig.getDHFConfig();
                        if (dhf_config && dhf_config.customReports && dhf_config.customReports.group === group) {
                            group = "Custom";
                        } else {
                            group = "hidden";
                        }
                    }
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        availableReports.reportList[idx].label.toLowerCase() !== "legacy" &&
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        availableReports.reportList[idx].id.toLowerCase() !== "dhf_generic" &&
                        group !== "hidden"
                    ) {
                        reportSelection.push({
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            id: availableReports.reportList[idx].id,
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            label: availableReports.reportList[idx].label,
                            class: group,
                        });
                        if (groups.indexOf(group) === -1) {
                            groups.push(group);
                            groupList.push({ value: group, label: group });
                        }
                    }
                }

                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                async function selectReport(selId: string) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    for (let idx = 0; idx < availableReports.reportList.length; idx++) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (availableReports.reportList[idx].id === selId) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            selectedReport = availableReports.reportList[idx];
                        }
                    }

                    (<TitleToolbarImpl>titleCtrl.getController()).titleCreationMode();

                    titleCtrl.getController().setValue(selectedReport.label, true);

                    if (
                        !decriptionCtrlValue ||
                        decriptionCtrlValue ===
                            $($("<div>").html(await decriptionCtrl.getController().getValueAsync())).text()
                    ) {
                        // not changed, set to selected item

                        decriptionCtrl.getController().setValue(selectedReport.description);
                        decriptionCtrlValue = $(
                            $("<div>").html(await decriptionCtrl.getController().getValueAsync()),
                        ).text();
                    }
                    reportIdCtrl.getController().setValue(selectedReport.id);
                }

                let reportSelector = $("<div>");
                reportSelector.mxDropdown({
                    controlState: ControlState.DialogCreate,
                    canEdit: true,
                    dummyData: false,
                    help: "Select Report Template",
                    fieldValue: "",
                    valueChanged: async function () {
                        selectReport(await reportSelector.getController().getValueAsync());
                    },
                    parameter: {
                        placeholder: "select report",
                        readonly: false,
                        maxItems: 1, // maxItems which can be selected
                        options: reportSelection,
                        groups: groupList,
                        create: false, // true if values can be added
                        sort: true, // true sort options
                    },
                });

                dlgInner.prepend(reportSelector);
            });
        }

        if (that.settings.isItem) {
            if (that.settings.controlState === ControlState.DialogCreate) {
                RenderReportDefinitionDialog(that._body, that.controls);
            } else if (showTools) {
                let rt = $("<div style='padding-top:12px;padding-bottom:12px;'>");
                that._body.append(rt);

                if (globalMatrix.ItemConfig.canReport("REPORT")) {
                    if (inputRestriction) {
                        // user can select input items from tree -> pop up a dialog an let choose
                        let inputSelectionGroup = $("<div  class='hidden-print inputSelectionGroup'></div>");
                        rt.append(inputSelectionGroup);
                        inputSelectionGroup.append(
                            $("<button id='createReportButton' class='btn btn-default'>")
                                .html("Create Report")
                                .click(function () {
                                    showInputSelectDialog();
                                }),
                        );
                    } else {
                        // uses all input let's create report
                        rt.append(createReportButton("Create Report"));
                    }
                } else {
                    rt.append("<div class='inlineHelp'>You have no rights to download documents</div>");
                }
            }
        } else if (that.settings.controlState === ControlState.FormEdit && app.canCreateItemType("REPORT")) {
            that._body.append($("<span class='baseControlHelp'>Tools</span>"));
            let folderEdit = $("<div class='hidden-print baseControl'></div>");
            that._body.append(folderEdit);
            let createTools = new ItemCreationTools();
            let createButtons = [
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                { type: that.settings.item.type, name: "Folder", folder: true },
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                { type: that.settings.item.type, name: "Report Template" },
            ];
            createTools.renderButtons({
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                parent: that.settings.item.id,
                dontOpenNewItem: false,
                control: folderEdit,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                linkTypes: createButtons,
                type: that.settings.type,
            });
        }
    }
}
