/**
 * Matrix stores data for each item field in a serialized, structured format defined
 * by the type of field. A FieldHandler is a class that allows safe creation and
 * manipulation of these structured types.
 */
import { FieldDescriptions } from "./../FieldDescriptions";
import { EmptyFieldHandler } from "./EmptyFieldHandler";
import { RichtextFieldHandler } from "./RichtextFieldHandler";
import { TextlineFieldHandler } from "./TextlineFieldHandler";
import { CheckboxFieldHandler } from "./CheckboxFieldHandler";
import { initialize as initializeSteplist, SteplistFieldHandler } from "./SteplistFieldHandler";
import { ItemConfiguration, XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { GenericFieldHandler } from "./GenericFieldHandler";
import { DropdownFieldHandler, IBaseDropdownFieldParams } from "./DropdownFieldHandler";
import { TestStepsFieldHandler } from "./TestStepsFieldHandler";
import { TestStepsResultFieldHandler } from "./TestStepsResultFieldHandler";
import { TestResultFieldHandler } from "./TestResultFieldHandler";
import { UserFieldHandler } from "./UserFieldHandler";
import { DateFieldHandler } from "./DateFieldHandler";
import { IFieldHandler } from "./IFieldHandler";
import { GateFieldHandler, IBaseGateOptions } from "./GateFieldHandler";
import { ITableControlBaseParams } from "./BaseTableFieldHandler";
import { ITestFieldParam, TestManagerConfiguration } from "../TestManagerConfiguration";
import { HyperlinkFieldHandler } from "./HyperlinkFieldHandler";
import { CrosslinksFieldHandler } from "./CrosslinksFieldHandler";
import { DHFFieldHandler } from "./DHFFieldHandler";
import { IDHFControlDefinition } from "../DHFInterfaces";

export * from "./BaseTableFieldHandler";
export * from "./BaseValidatedTableFieldHandler";
export * from "./CheckboxFieldHandler";
export * from "./DHFFieldHandler";
export * from "./DropdownFieldHandler";
export * from "./EmptyFieldHandler";
export * from "./GenericFieldHandler";
export * from "./SteplistFieldHandler";
export * from "./TextlineFieldHandler";
export * from "./TestStepsFieldHandler";
export * from "./TestStepsResultFieldHandler";
export * from "./TestResultFieldHandler";
export * from "./UserFieldHandler";
export * from "./DateFieldHandler";
export * from "./ItemSelectionFieldHandler";
export * from "./CrosslinksFieldHandler";
export * from "./HyperlinkFieldHandler";
export * from "./RichtextFieldHandler";

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function InitializeFieldHandlers() {
    initializeSteplist();
}

// TODO(modules): At the moment, handling of structured data is spread all over the client
// code base. We'd like to get all the data manipulation into these classes, separating
// that code from say, UI code.

export class FieldHandlerFactory {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static UpdateFieldConfig(
        itemConfig: ItemConfiguration,
        testConfig: TestManagerConfiguration,
        fieldType: string,
        itemType: string,
        fieldValue: string,
        config: XRFieldTypeAnnotatedParamJson,
    ) {
        switch (fieldType) {
            case FieldDescriptions.Field_test_steps:
                TestStepsFieldHandler.UpdateFieldConfig(config, itemType, testConfig);
                break;
            case FieldDescriptions.Field_test_steps_result:
                TestStepsResultFieldHandler.UpdateFieldConfig(config, testConfig);
                break;
            case FieldDescriptions.Field_test_result:
                TestResultFieldHandler.UpdateFieldConfig(<IBaseDropdownFieldParams>config, testConfig);
                break;
            case FieldDescriptions.Field_user:
                UserFieldHandler.UpdateFieldConfig(<ITestFieldParam>config, fieldValue, itemConfig);
                break;
            case FieldDescriptions.Field_crosslinks:
                CrosslinksFieldHandler.UpdateFieldConfig(config);
                break;
            case FieldDescriptions.Field_dropdown:
                DropdownFieldHandler.UpdateFieldConfig(config, itemConfig);
                break;
            default:
                break;
        }
    }

    public static CreateHandler(
        itemConfig: ItemConfiguration,
        fieldType: string,
        config: XRFieldTypeAnnotatedParamJson,
    ): IFieldHandler {
        const description = FieldDescriptions.findById(fieldType);
        if (!description) {
            // Do not throw an error here, because this is called for every field on every item...
            // see MATRIX-5683 for discussion on this issue. Some installations may contains
            // plugins that implement field handlers, but we don't have that information here.
            // User changes the data (string/json/etc) at their own risk.
            return new GenericFieldHandler(fieldType, config);
        }
        switch (description.id) {
            case FieldDescriptions.Field_tasksControl:
            case FieldDescriptions.Field_section:
            case FieldDescriptions.Field_links:
            case FieldDescriptions.Field_uplinkinfo:
            case FieldDescriptions.Field_labels:
            case FieldDescriptions.Field_workflow:
            case FieldDescriptions.Field_syncStatus:
            case FieldDescriptions.Field_syncSourceInfo:
                return new EmptyFieldHandler(description.id, config);

            case FieldDescriptions.Field_text:
            case FieldDescriptions.Field_textline:
            case FieldDescriptions.Field_publishedTitle:
                return new TextlineFieldHandler(description.id, config);

            case FieldDescriptions.Field_richtext:
                return new RichtextFieldHandler(config);

            case FieldDescriptions.Field_checkbox:
                return new CheckboxFieldHandler(config);

            // Table-like handlers
            case FieldDescriptions.Field_steplist:
                return new SteplistFieldHandler(<ITableControlBaseParams>config);
            case FieldDescriptions.Field_test_steps:
                return new TestStepsFieldHandler(<ITableControlBaseParams>config);
            case FieldDescriptions.Field_test_steps_result:
                return new TestStepsResultFieldHandler(<ITableControlBaseParams>config);

            // Dropdown-like field handlers
            case FieldDescriptions.Field_dropdown:
                return new DropdownFieldHandler(<IBaseDropdownFieldParams>config);
            case FieldDescriptions.Field_user:
                return new UserFieldHandler(<IBaseDropdownFieldParams>config);
            case FieldDescriptions.Field_test_result:
                return new TestResultFieldHandler(<IBaseDropdownFieldParams>config);
            case FieldDescriptions.Field_date:
                return new DateFieldHandler(config);
            case FieldDescriptions.Field_gateControl:
                return new GateFieldHandler(<IBaseGateOptions>config);
            case FieldDescriptions.Field_crosslinks:
                return new CrosslinksFieldHandler(config);
            case FieldDescriptions.Field_hyperlink:
                return new HyperlinkFieldHandler(config);
            case FieldDescriptions.Field_dummy:
                return new EmptyFieldHandler(fieldType, config);
            case FieldDescriptions.Field_dhf:
                return new DHFFieldHandler(itemConfig, <IDHFControlDefinition>config);
            case FieldDescriptions.Field_colorPicker:
            case FieldDescriptions.Field_sourceref:
            case FieldDescriptions.Field_markAsTemplate:
            case FieldDescriptions.Field_docFilter:
            case FieldDescriptions.Field_fileManager:
            case FieldDescriptions.Field_risk2:
            case FieldDescriptions.Field_report:
            case FieldDescriptions.Field_cascadingSelect:
            case FieldDescriptions.Field_reportId:
            case FieldDescriptions.Field_filter_file:
            case FieldDescriptions.Field_signature:
            case FieldDescriptions.Field_signatureControl:
            case FieldDescriptions.Field_publishedContent:
            case FieldDescriptions.Field_reviewControl:
            default:
                return new GenericFieldHandler(fieldType, config);
        }
    }
}
export { ItemSelectionFieldHandlerFromTo } from "./ItemSelectionFieldHandlerFromTo";
