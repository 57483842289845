import * as React from "react";
import { Component } from "react";
import { ITile } from "../../../../../sdk/plugins/interfaces";
import { globalMatrix } from "../../../../globals";
type Props = {
    tiles: ITile[];
    title: string;
};
type State = {
    rotate: boolean;
};
export class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { rotate: false };
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render() {
        return (
            <div id="analytics-header" className={"rowFlex"} style={{ flexGrow: "1" }}>
                <div className={"itemTitle"}>{this.props.title}</div>
                <div style={{ flexGrow: "1" }}></div>
                <div className={"rowFlex"}>
                    <button
                        className={"btn  btn-default "}
                        style={{ minWidth: "182px" }}
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        onClick={() => {
                            //Open marketplace
                            window.open(globalMatrix.matrixBaseUrl + "/marketplace?addonTypes==analytics");
                        }}
                    >
                        Add analytics <i className={"fal fa-plus"} />
                    </button>
                    <button
                        title={"Dashboards displayed in that page need to be refreshed regularly"}
                        data-original-title={"Dashboards displayed in that page need to be refreshed regularly"}
                        className={"btn  btn-success "}
                        style={{ minWidth: "182px" }}
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        onClick={async () => {
                            this.startAnimation();
                            // Refresh the tiles one by one
                            for (let tile of this.props.tiles) {
                                try {
                                    await tile.refresh();
                                } catch (e) {
                                    console.error("Error while refreshing tile", e);
                                }
                            }
                            setTimeout(() => {
                                this.stopAnimation();
                            }, 1000);
                        }}
                    >
                        Refresh <i className={"fal fa-sync-alt " + (this.state.rotate ? " refresh-animate " : "")} />
                    </button>
                </div>
            </div>
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private startAnimation() {
        this.setState({ rotate: true });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private stopAnimation() {
        this.setState({ rotate: false });
    }
}
