import { restConnection, app, globalMatrix, matrixSession, ControlState } from "../../globals";
import { XRUserPermissionType } from "../../RestResult";

import { XCPostSendMail } from "./../../RestCalls";
import { ml } from "./../matrixlib";
import { IMailTools, IUserLookup } from "./MatrixLibInterfaces";

export { MailTools };

class MailTools implements IMailTools {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    sendMail(
        to: string,
        cc: string,
        bcc: string,
        subject: string,
        body: string,
        systemMail?: number,
        noSuccess?: boolean,
        noReply?: number,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
    ): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();
        let text = $("<div class='controlContainer'>").html(body);
        $("br", text).replaceWith("\n");

        let tol = to ? to.split(",") : [];
        let ccl = cc ? cc.split(",") : [];
        let bccl = bcc ? bcc.split(",") : [];

        let sendMailParm: XCPostSendMail = {
            to: tol,
            cc: ccl,
            bcc: bccl,
            subject: subject,
            htmlbody: body,
            textbody: text.text(),
            system: systemMail,
            noreply: noReply,
        };
        let queryString =
            "?" +
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (systemMail != undefined ? "system=" + systemMail + "&" : "") +
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (noReply != undefined ? "noreply=" + noReply : "");

        restConnection
            .postSpecialServer("all/sendmail" + queryString, sendMailParm)
            .done(function () {
                if (!systemMail && !noSuccess) {
                    ml.UI.showSuccess("Mail sent successfully.");
                }
                res.resolve();
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function (jqxhr: {}, textStatus: string, error: string) {
                if (!systemMail) {
                    ml.UI.showError("Error sending mail!", "Status:" + textStatus + "<br/>Error was:" + error);
                }
                res.reject();
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    sendMailDlg(
        sendTo: string,
        preSelectedUsers: XRUserPermissionType[],
        subject: string,
        body: string,
        sendCc: string,
    ): void {
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        async function updateSend() {
            let sendButton = $(".ui-dialog-buttonpane button:contains('Send')", app.dlgForm.parent());
            let enabled = sendTo.length && subj && (await subj.getController().getValueAsync()) !== "";
            sendButton.prop("disabled", !enabled);
            if (enabled) {
                sendButton.removeClass("ui-state-disabled");
            } else {
                sendButton.addClass("ui-state-disabled");
            }
        }

        let cc: string[] = sendCc ? sendCc.split(",") : [];
        let mailConf = matrixSession.getMailSettings();
        if (mailConf && mailConf.defaultCC && mailConf.defaultCC.length) {
            cc = cc.concat(
                mailConf.defaultCC.filter(function (userOrGroup) {
                    return ml.UI.SelectUserOrGroup.exists(userOrGroup);
                }),
            );
        }

        if (sendTo.split(",").indexOf(matrixSession.getUser()) === -1 && matrixSession.getUser() !== "matrixadmin") {
            // the user himself is always on cc - by default
            cc.push(matrixSession.getUser());
        }

        // get distinct names
        cc = cc.filter(function (elem, pos, arr) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return arr.indexOf(elem) == pos;
        });

        let usel = $("<div class='controlContainer'>");
        let ccl = $("<div class='controlContainer'>");

        if (preSelectedUsers && preSelectedUsers.length) {
            // take user list from parameter (no groups possible)
            ml.UI.SelectUserOrGroup.showMultiUserSelect(
                usel,
                "To",
                sendTo ? sendTo.split(",") : [],
                "Select To Users",
                "",
                "",
                true,
                false,
                (selection: string[]) => {
                    sendTo = selection.join(",");
                    updateSend();
                },
                preSelectedUsers,
            );

            ml.UI.SelectUserOrGroup.showMultiUserSelect(
                ccl,
                "Cc",
                cc,
                "Select Cc Users",
                "",
                "",
                true,
                false,
                (selection: string[]) => {
                    cc = ml.JSON.clone(selection);
                    updateSend();
                },
                preSelectedUsers,
            );
        } else {
            // take users from current project
            ml.UI.SelectUserOrGroup.showMultiUserSelect(
                usel,
                "To",
                sendTo ? sendTo.split(",") : [],
                "Select To Users / Groups",
                "",
                "",
                true,
                true,
                (selection: string[]) => {
                    sendTo = selection.join(",");
                    updateSend();
                },
            );

            ml.UI.SelectUserOrGroup.showMultiUserSelect(
                ccl,
                "Cc",
                cc,
                "Select Cc Users / Groups",
                "",
                "",
                true,
                true,
                (selection: string[]) => {
                    cc = ml.JSON.clone(selection);
                    updateSend();
                },
            );
        }

        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var subj = $("<div class='controlContainer'>").plainText({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Subject",
            fieldValue: subject,
            valueChanged: function () {
                updateSend();
            }, // callback to call if value changes
            parameter: {
                rows: 1,
                allowResize: false,
            },
        });
        let cont: JQuery = $("<div class='controlContainer'>");

        cont.richText({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Message",
            fieldValue: body,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {},
            parameter: { tiny: true, autoEdit: true, autoFocus: true },
        });

        app.dlgForm.hide();
        app.dlgForm.html("");

        app.dlgForm.append(usel);

        app.dlgForm.append(ccl);
        // workaround for MATRIX-995 cannot edit links in sendmail
        // somehow that thing gets a focus event out of nowhere

        app.dlgForm.append(subj);
        app.dlgForm.append(cont);
        app.dlgForm.addClass("dlg-no-scroll");
        app.dlgForm.removeClass("dlg-v-scroll");

        app.dlgForm.dialog({
            autoOpen: true,
            title: "Send Mail",
            width: 730,
            height: 760,
            modal: true,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            resizeStop: function () {},
            open: function () {
                window.setTimeout(function () {
                    $("input", subj).focus();
                }, 1);
                updateSend();
            },
            buttons: [
                {
                    text: "Send",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: async function () {
                        that.sendMail(
                            sendTo,
                            cc.join(","),
                            "",
                            await subj.getController().getValueAsync(),
                            await cont.getController().getValueAsync(),
                        );
                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    replacePlaceholders(
        template: string,
        itemId: string,
        rejectComment: string,
        reviewers: string[],
        readers: string[],
        trainers: string[],
        trainees: string[],
    ): string {
        let msg = ml.Mail.getCannedMessage("", "", itemId, template);
        msg = msg.replace(new RegExp("_reject_", "g"), rejectComment ? rejectComment : "");
        msg = msg.replace(new RegExp("_reviewers_", "g"), reviewers ? reviewers.join() : "");
        msg = msg.replace(new RegExp("_readers_", "g"), readers ? readers.join() : "");
        msg = msg.replace(new RegExp("_trainers_", "g"), trainers ? trainers.join() : "");
        msg = msg.replace(new RegExp("_trainees_", "g"), trainees ? trainees.join() : "");
        return msg;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getCannedMessage(messageId: string, to: string, itemId: string, custom?: string, body = ""): string {
        let message = custom;
        let mailConf = matrixSession.getMailSettings();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (custom == undefined || custom == "") {
            if (mailConf && mailConf.canned && mailConf.canned[messageId]) {
                message = mailConf.canned[messageId];
            } else if (messageId === "link") {
                message = "Hello<br/><br/>please have a look at <b>_link_ _title_</b>.<br/><br/>_me.firstName_";
            } else if (messageId === "please_sign") {
                message =
                    "Hello,<br/><br/>please review and sign the following document <b>_link_ _title_</b><br/><br/>_me.firstName_";
            } else if (messageId === "new_user") {
                message =
                    "Hello _to.firstName_,<br/><br/>Here is your access to Matrix.<br/><br/>_userinfo_<br/><br/>You have access to these projects:<br/><br/>_projectlist_<br/>_me.firstName_";
            } else if (messageId === "reset_pwd") {
                message = "Hello _to.firstName_,<br/><br/>Please change your password.<br/><br/>_userinfo_<br/>";
            } else if (messageId === "release_note") {
                message =
                    "Hello,<br/><br/>the document <b>_link_ _title_</b> has been released!<br/><br/>_me.firstName_";
            } else if (messageId === "approve_note") {
                message =
                    "Hello ,<br/><br/>the template <b>_link_ _title_</b> waits for approval!<br/><br/>_me.firstName_";
            } else if (messageId === "training_reminder") {
                message =
                    "Hello _to.firstName_,<br/><br/> You need to do the following training:<br/>_body_<br/>_me.firstName_";
            } else if (messageId === "review_reminder") {
                message =
                    "Hello _to.firstName_,<br/><br/> You need to review the following :<br/>_body_<br/>_me.firstName_";
            } else if (messageId === "qms_published") {
                message = "Hello,<br/>_body_<br/>_me.firstName_";
            } else if (messageId === "gate_passed") {
                message = `Hello _to.firstName_,<br/><br/>_me.firstName_ passed the gate _body_ for <b>_link_ _title_</b>.<br/><br/>`;
            } else if (messageId === "empty_message") {
                message = ``;
            }
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (body != undefined) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            message = message.replace("_body_", body);
        }

        // address the user eg by firstName: a) get the correct user info
        let users = globalMatrix.ItemConfig.getUserNames();
        let user: IUserLookup;
        let me: IUserLookup;

        let toUsers = to.split(",");
        let toFirst: string[] = [];
        let toLast: string[] = [];
        let toLogin: string[] = [];

        $.each(users, function (uidx, u) {
            if (u.login === to) {
                user = <IUserLookup>u;
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (toUsers.indexOf(u.login) != -1) {
                toFirst.push(u.firstName);
                toLast.push(u.lastName);
                toLogin.push(u.login);
            }
            if (u.login === matrixSession.getUser()) {
                me = <IUserLookup>u;
            }
        });

        // address the user eg by firstName: b) replace all existing keys for to
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (user) {
            $.each(Object.keys(user), function (kidx: number, key: string) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                message = message.replace(new RegExp("_to." + key + "_", "g"), <string>user[key]);
            });
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            message = message.replace(new RegExp("_to_", "g"), to);
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        message = message.replace(new RegExp("_to.firstName_", "g"), toFirst.join(","));
        message = message.replace(new RegExp("_to.lastName_", "g"), toLast.join(","));
        message = message.replace(new RegExp("_to.login_", "g"), toLogin.join(","));

        // address the user eg by firstName: c) replace all existing keys for sender
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (me) {
            $.each(Object.keys(me), function (kidx, key) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                message = message.replace(new RegExp("_me." + key + "_", "g"), <string>me[key]);
            });
        } else {
            message = message.replace(/_me.*_/g, "");
        }

        // replace other stuff
        if (itemId) {
            const project = matrixSession.getProject();

            message = message.replace(new RegExp("_id_", "g"), itemId);

            if (project) {
                message = message.replace(new RegExp("_project_", "g"), project);
            }

            message = message.replace(new RegExp("_url_", "g"), ml.Item.parseRef(itemId).url);
            message = message.replace(new RegExp("_link_", "g"), ml.Item.parseRef(itemId).link);
            message = message.replace(new RegExp("_linkv_", "g"), ml.Item.parseRef(itemId).linkv);
            let title = app.getItemTitle(itemId);
            message = message.replace(new RegExp("_title_", "g"), title);
        }
        return message;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    sendMails(sendTos: string[], subject: string, messages: string[]) {
        if (sendTos.length > 2) {
            ml.UI.Progress.Init("sending mails");
        }
        let mailConf = matrixSession.getMailSettings();
        let cc = "";
        if (mailConf && mailConf.defaultCC && mailConf.defaultCC.length) {
            cc = mailConf.defaultCC
                .filter(function (userOrGroup) {
                    return ml.UI.SelectUserOrGroup.exists(userOrGroup);
                })
                .join(",");
        }
        this.sendMailsRec(sendTos, cc, subject, messages, 0);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private sendMailsRec(sendTos: string[], cc: string, subject: string, messages: string[], idx: number) {
        let that = this;

        if (sendTos.length > 2) {
            ml.UI.Progress.Update((idx * 100) / sendTos.length);
        }
        if (idx >= sendTos.length) {
            if (sendTos.length > 2) {
                ml.UI.Progress.SuccessHide("Done sending mails", 1000);
            } else {
                ml.UI.showSuccess("Done sending mails", 1000);
            }
            return;
        }
        this.sendMail(sendTos[idx], cc, "", subject, messages[idx], 0, true).done(function () {
            that.sendMailsRec(sendTos, cc, subject, messages, idx + 1);
        });
    }
}
