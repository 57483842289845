import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { ITableControlBaseParams } from "./BaseTableFieldHandler";
import { BaseValidatedTableFieldHandler } from "./BaseValidatedTableFieldHandler";

export { initialize, SteplistFieldHandler };

class SteplistFieldHandler extends BaseValidatedTableFieldHandler {
    constructor(configIn: ITableControlBaseParams) {
        super(configIn);
    }
}

function validateTableControlParams(json: ITableControlBaseParams): Promise<string | null> {
    return new Promise((resolve) => {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!json.columns || json.columns.length == 0) {
            resolve("There are no columns defined. You need at least one column!");
        }
        const validXmlRegex = /^([_a-z][\w]?|[a-w_yz][\w]{2,}|[_a-z][a-l_n-z\d][\w]+|[_a-z][\w][a-k_m-z\d][\w]*)$/i;
        // check for bad column field properties
        let badFields: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        json.columns.forEach(function (column, idx) {
            if (!column.field) {
                badFields.push("Column " + (idx + 1) + " needs a field property");
            } else if (!column.field.match(validXmlRegex)) {
                badFields.push(
                    "The field of column " +
                        (idx + 1) +
                        ": ' " +
                        column.field +
                        "' needs to be a valid XML element name.",
                );
            }
            if (column.options) {
                for (let option in column.options) {
                    if (!option.match(validXmlRegex)) {
                        badFields.push(
                            "The option '" +
                                option +
                                "' of field of column " +
                                (idx + 1) +
                                ": ' " +
                                column.field +
                                "' needs to be a valid XML element name.",
                        );
                    }
                }
            }
        });

        if (badFields.length) {
            resolve(badFields.join("<br>"));
        }

        resolve(null);
    });
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    FieldDescriptions.setValidationFunction(FieldDescriptions.Field_steplist, validateTableControlParams);
}
