import { IGetProjectResultDateInfo } from "./index";
import { matrixSession, restConnection } from "../../globals";

export class DateTimeBL {
    // formatter (always for the user)
    public simpleDateTimeFormat: SimpleDateFormat;
    public simpleDateFormat: SimpleDateFormat;
    // formatter for the customer
    public simpleCustomerDateTimeFormat: SimpleDateFormat;
    public simpleCustomerDateFormat: SimpleDateFormat;

    // *******************************************************
    // these are setting of the currently logged in user
    // as well as the current server settings
    // they are updated when initDateTimeSettings() is called
    // *******************************************************
    public userDateTimeFormat: string;
    public userDateOnlyFormat: string;
    public userTimezone: string;
    // server formatting for user
    public dateIso8601FromServer: string;
    public dateUserFromServer: string;
    // server settings
    public serverDateTimeFormat: string;
    public serverDateOnlyFormat: string;
    public serverTimezone: string;

    static instance: DateTimeBL;

    static getInstance(): DateTimeBL {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (DateTimeBL.instance == null) {
            DateTimeBL.instance = new DateTimeBL();
        }
        return DateTimeBL.instance;
    }

    private constructor() {
        this.userDateTimeFormat = "yyyy/MM/dd HH:mm:ss";
        this.userDateOnlyFormat = "yyyy/MM/dd";
        this.userTimezone = "UTC";
        this.simpleDateTimeFormat = new SimpleDateFormat(this.userDateTimeFormat);
        this.simpleDateFormat = new SimpleDateFormat(this.userDateOnlyFormat);
        this.dateIso8601FromServer = "";
        this.dateUserFromServer = "";
        this.serverDateTimeFormat = "yyyy/MM/dd HH:mm:ss";
        this.serverDateOnlyFormat = "yyyy/MM/dd";
        this.serverTimezone = "UTC";
        this.simpleCustomerDateTimeFormat = new SimpleDateFormat(this.userDateTimeFormat);
        this.simpleCustomerDateFormat = new SimpleDateFormat(this.userDateOnlyFormat);
    }

    // isValidateDate(new Date("ccccc")) -> false
    private isValidDate(d: Date): boolean {
        if (Object.prototype.toString.call(d) === "[object Date]") {
            return !isNaN(d.getTime());
        }
        return false;
    }

    // render a human date and time in the user's preferred style
    renderHumanDate(date: Date, dateOnly?: boolean): string {
        if (!this.isValidDate(date)) {
            return "";
        }

        if (dateOnly) {
            return this.simpleDateFormat.format(date);
        }
        return this.simpleDateTimeFormat.format(date);
    }

    // render a human date and time in the user's preferred style
    renderCustomerHumanDate(date: Date, dateOnly?: boolean): string {
        if (!this.isValidDate(date)) {
            return "";
        }

        if (dateOnly) {
            return this.simpleCustomerDateFormat.format(date);
        }
        return this.simpleCustomerDateTimeFormat.format(date);
    }

    // render a date 3 letter abbreviation (e.g. for calendars)
    renderHumanMonth(dateObj: Date): string {
        let dateFormat = new SimpleDateFormat("MMM");
        return dateFormat.format(dateObj);
    }

    renderDashFormat(dateObj: Date): string {
        let dateFormat = new SimpleDateFormat("yyyy-MM-dd");
        return dateFormat.format(dateObj);
    }

    // return the simple date format for controls
    getSimpleDateFormat(): string {
        return this.userDateOnlyFormat;
    }

    private toMoment(format: string): string {
        // see https://www.angularfaces.net/cheatsheet/
        return format
            .replace("a", "A")
            .replace("DDD", "DDDD")
            .replace("D", "DDD")
            .replace("dd", "DD")
            .replace("d", "D")
            .replace("EEEE", "dddd")
            .replace("EEE", "ddd")
            .replace("EE", "dd")
            .replace("E", "d")
            .replace("u", "E")
            .replace("w", "W")
            .replace("ww", "WW")
            .replace("xxx", "Z")
            .replace("xx", "ZZ")
            .replace("YYYY", "gggg")
            .replace("YY", "gg")
            .replace("yyyy", "YYYY")
            .replace("yyy", "YYY")
            .replace("yy", "YY")
            .replace("y", "Y")
            .replace("Z", "ZZ");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getSimpleDateFormatMoment() {
        return this.toMoment(this.userDateOnlyFormat);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getSimpleDateTimeFormatMoment() {
        return this.toMoment(this.userDateTimeFormat);
    }
    async initDateTimeSettings(update?: boolean): Promise<void> {
        let dateSettings: IGetProjectResultDateInfo;
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function updateSettings() {
            that.userDateTimeFormat = dateSettings.timeformat;
            that.userDateOnlyFormat = dateSettings.dateformat;
            that.userTimezone = dateSettings.timeZone;
            that.serverDateOnlyFormat = dateSettings.customerDateformat;
            that.serverDateTimeFormat = dateSettings.customerTimeformat;
            that.serverTimezone = dateSettings.customerTimezone;
            // create formatting strings
            that.simpleCustomerDateTimeFormat = new SimpleDateFormat(that.serverDateTimeFormat);
            that.simpleCustomerDateFormat = new SimpleDateFormat(that.serverDateOnlyFormat);
            // remember the date time settings as the server thinks it should be
            that.dateIso8601FromServer = dateSettings.dateIso8601;
            that.dateUserFromServer = dateSettings.timeUserFormat;
            // create formatting strings
            that.simpleDateTimeFormat = new SimpleDateFormat(that.userDateTimeFormat);
            that.simpleDateFormat = new SimpleDateFormat(that.userDateOnlyFormat);
        }

        if (update) {
            await restConnection.getServer("all/date").done(function (ds) {
                dateSettings = ds as IGetProjectResultDateInfo;
                updateSettings();
            });
        } else {
            dateSettings = matrixSession.getDateInfo();
            updateSettings();
        }

        return;
    }
}
