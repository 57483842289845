import { CheckboxFieldHandler } from "../CheckboxFieldHandler";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { XRFieldTypeAnnotatedParamJson } from "../../ItemConfiguration";
import { IAnyMap, IGenericMap } from "../../../../globals";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";

export interface ICheckboxOptions extends IDHFSectionOptions {}

export class CheckBoxDocFieldHandler extends CheckboxFieldHandler implements IDocFieldHandler {
    private sectionType: string;
    dhfFieldConfig: IAnyMap;
    protected name = "";

    constructor(sectionType: string, config: XRFieldTypeAnnotatedParamJson, fieldValue: string) {
        super(config);
        this.sectionType = sectionType;
        if (!config) {
            config = this.getDefaultConfig();
        }
        this.dhfFieldConfig = config;
        this.initData(fieldValue);
    }
    getFieldType(): string {
        return this.sectionType;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }

    getDefaultConfig(): ICheckboxOptions {
        return {};
    }

    setDHFConfig(config: IAnyMap): void {
        this.dhfFieldConfig = config;
    }

    getXmlValue(): string {
        let xmlval: IGenericMap[] = [];
        let val = this.getValue();
        xmlval.push({ checked: val ? "true" : "false" });
        return JSON.stringify(xmlval);
    }

    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
}
