/// <reference types="matrixrequirements-type-declarations" />
import { IRestoreItemResult, ISearchResult, IUpdateCache, RestDB } from "./RestDB";
import { ItemConfiguration } from "./ItemConfiguration";
import { DBCache, IDB, IDBParent } from "./DBCache";
import {
    app,
    globalMatrix,
    IItem,
    IItemGet,
    IItemPut,
    IReferenceChange,
    IRestResult,
    IStringMap,
    matrixApplicationUI,
    matrixSession,
    setIC,
} from "./../../globals";
import { XCGetProjectAudit, XCPostCompareHtml } from "./../../RestCalls";
import { IItemWatched } from "./PushMessages";
import { IFileParam, IFileUploadProgress } from "./RestConnector";
import { ILinkCategories, ILinkCollectionOptions } from "../UI/Controls/linkCollection";
import { ml } from "./../matrixlib";
import { NavigationPanel } from "../UI/MainTree/MainTree";
import { ISearchConfig, ITraceConfigRule } from "../../ProjectSettings";
import {
    XRCategoryExtendedType,
    XRCrossProjectLink,
    XRGetProject_AllJob_JobsWithUrl,
    XRGetProject_JobStatus_JobsStatusWithUrl,
    XRGetProject_ProjectAudit_TrimAuditList,
    XRGetProject_Reports_GetReportsAck,
    XRPostProject_AddFile_AddFileAck,
    XRPostProject_CompareHtml_HtmlCompareResult,
    XRPostProject_LaunchReport_CreateReportJobAck,
    XRPostProject_SignItem_SignItemAck,
    XRProjectInfo,
    XRTag,
    XRTrimAudit,
    XRTrimNeedleItem,
} from "../../RestResult";
import { plugins } from "./PluginManager";
import { mDHF } from "./PluginManagerDocuments";
import { IApp, IPostCreateSignOrDocResult, IReportOptions, UIToolsConstants } from "../matrixlib/MatrixLibInterfaces";
import { mTasks } from "./Tasks";
import { NavBar, NavigationBar } from "../UI/Components";
import { EImportMode } from "../../common/businesslogic/ComponentImport";
import { NotificationsBL } from "./NotificationsBL";
import { DefaultCategorySettingNames } from "../../admin/lib/categories/CategorySetting";
import { Analytics } from "../../client/Dashboards/Analytics";

export type {
    ITraceRules,
    ILinkInfo,
    IVersionDetails,
    XRTrimNeedleItemJob,
    ILogoConfig,
    IReferenceUpdate,
    ISimpleTree,
    ISetField,
};
export { MatrixReq };

interface ITraceRules {
    valid: boolean; // in case any must_have rule is not followed this is false
    mustHaveCategories: string[]; // all categories which are in a must have rule
    canHaveCategories: string[]; // all categories which are in a can have rule
    exstingCategories: string[]; // all categories which are currently linked to item
    missingMustHaveCategories: string[]; // all categories which must be linked but are not
    missingCanHaveCategories: string[]; // all categories which can be linked but are not
    outdatedReferences: string[];
}

interface ILinkInfo {
    category: string | string[];
    reason: string;
}
interface IVersionDetails {
    action: string;
    id: string;
    title: string;
    user: string;
    date: string;
    dateUserFormat: string;
    job?: number;
    reason?: string;
    comment: string;
    version: number;
    fullVersion: string;
    auditId: number;
    tags: XRTag[];
}
interface INewItem {
    parent: string;
    position: number;
    item: IItem;
}

interface XRTrimNeedleItemJob extends XRTrimNeedleItem {
    job?: number;
}
// logo setting
interface ILogoConfig {
    fileId?: string;
    logoId?: string;
}

interface IReferenceUpdate {
    added: boolean;
    fromId: string;
    toId: string;
    date: string;
    dateUserFormat: string;
    comment: string;
    user: string;
}

interface ISimpleTree {
    itemId: string;
    title: string;
    children?: ISimpleTree[];
}

interface ISetField {
    fieldName: string;
    value: string;
}

class MatrixReq implements IApp {
    isConfigApplication = false as const;
    isClientApplication = true as const;

    private dbConnection: RestDB;
    private _itemId = ""; //currently selected item id. this should come from URL
    public _needsSave = false; // the current status of the rendered item
    private saving = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private forceUIRefreshTimer: number;
    private lastState = "";
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private freezeDuringSave: boolean; // set to true when saving while navigating the tree

    mainApp = true;

    public itemForm = $("#itemDetails");
    printForm = $("#appPrint");
    dlgForm = $("#appPopup");

    public mainTreeLoaded = false;

    constructor(dataSource: RestDB) {
        this.dbConnection = dataSource;
    }
    // allow to overwrite cache (for JIRA cloud plugin)
    setCache(externalCache: DBCache): void {
        this.dbConnection.setCache(externalCache);
    }

    // store the project of this session
    loadProject(project: string, item: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let that = this;

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (item && item == "null") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                item = null;
            }

            // reset to avoid ping to non existing item
            this._itemId = "";

            setIC(new ItemConfiguration(ml.Logger, ml.JSON));

            // make the database to load the project

            matrixApplicationUI.destroyOldControls();
            NavigationPanel.destroy();

            // this will show the tree and select the item
            // if the user logs in / is logged in
            this.loadTreeAndItem(project, item)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(function (result: { item: IItem; tree: IDB[]; project: XRProjectInfo }) {
                    let itemToShow = result?.item;
                    // show a logo if there is any
                    let logo = globalMatrix.matrixBaseUrl + "/img/logo_black.svg";
                    let logoSetting: string = globalMatrix.ItemConfig.getSetting("projectLogo");
                    if (logoSetting) {
                        if (logoSetting.indexOf("{") !== -1) {
                            let rjs = ml.JSON.fromString(logoSetting);
                            if (rjs.status === "ok") {
                                if ((<ILogoConfig>rjs.value).fileId) {
                                    logo =
                                        globalMatrix.matrixRestUrl +
                                        "/" +
                                        project +
                                        "/file/" +
                                        (<ILogoConfig>rjs.value).fileId;
                                }
                            }
                        } else {
                            // legacy
                            logo = logoSetting;
                        }
                    }

                    NotificationsBL.NoticationCache.setNotifications(
                        result.project.todos.filter(function (todo) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            return todo.login == matrixSession.getUser();
                        }),
                    );

                    $(".brandLogo").attr("src", logo);
                    that.updateFavicon(project, false);

                    // render the item and the reduced tree
                    that.showTree(item, itemToShow);

                    // find a place to add a progress
                    let loadTreeProgress = $("<div id='treeLoadWait'>").append(
                        $("<div>").append(ml.UI.getSpinningWait(" ")),
                    );
                    loadTreeProgress.width($("#sidebar").width());
                    $("#projectTree .listContent").after(loadTreeProgress);

                    // render the full tree and color some items by executing searches
                    window.setTimeout(() => {
                        that.loadTreeWithSearches(item).always(() => {
                            // configure highlighting of links in rich text boxes
                            let cats = globalMatrix.ItemConfig.getCategories();
                            for (let idx = 0; idx < cats.length; idx++) {
                                addHighlightLink(cats[idx], globalMatrix.matrixBaseUrl + "/" + project + "/");
                            }
                            matrixApplicationUI.refreshLinks();
                            loadTreeProgress.remove();
                            resolve();
                        });
                    }, 1);
                })
                .fail(function () {
                    ml.UI.hideSuccess();
                });
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    loadTree(project: string): JQueryDeferred<{}> {
        this.dbConnection.setProject(project);
        return this.dbConnection.retrieveTreeAsync();
    }

    // load item and the tree for the item
    loadTreeAndItem(
        project: string,
        item: string,
    ): JQueryDeferred<{ item: IItem; tree: IDB[]; project: XRProjectInfo }> {
        this.dbConnection.setProject(project);
        return this.dbConnection.retrieveTreeAndItem(item);
    }

    updateFavicon(project: string, notification: boolean): void {
        // get the base icon (default or from project setting)

        let iconPath = globalMatrix.matrixBaseUrl + "/favicon_medical.ico";
        let logoSetting: string = globalMatrix.ItemConfig.getSetting("projectLogo");

        let customPath = MatrixReq.getProjectIcon(logoSetting);

        if (customPath) {
            iconPath = globalMatrix.matrixRestUrl + "/" + project + "/file/" + customPath;
        }
        let iconNode = $("link[rel*='icon']");

        iconNode.attr("href", iconPath);

        // if there's neither a notification or a custom path I am done
        if (!notification && customPath) {
            return;
        }

        let canvas = document.createElement("canvas");
        if (canvas.getContext) {
            let img = document.createElement("img");

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            img.onload = function () {
                // once the image has loaded

                canvas.height = img.height;
                canvas.width = img.width;

                let radius = img.width / 6;
                let boxHeight = img.width / 10;
                let boxIndent = img.width / 10;

                let ctx = canvas.getContext("2d");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctx.drawImage(img, 0, 0);

                if (notification) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.fillStyle = UIToolsConstants.CIColors.RedPersimmon.color;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.beginPath();
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.arc(img.width - 2 * radius + 3, radius - 1, radius, 0, 2 * Math.PI);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.fill();
                }

                if (!customPath) {
                    // draw a box with project colors
                    let color = matrixSession.getProjectColor(project);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.fillStyle = color;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.beginPath();
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.rect(0 + boxIndent, img.height - boxHeight - 1, img.width - 1 - 2 * boxIndent, boxHeight);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ctx.fill();
                }

                iconNode.attr("href", canvas.toDataURL("image/png"));
            };
            img.src = iconPath;
        }
    }
    waitForMainTree(callback: () => void): void {
        if (callback) {
            if (app.mainTreeLoaded) {
                callback();
            } else {
                document.addEventListener("mainTreeLoaded", () => {
                    callback();
                });
            }
        }
    }

    // load the full tree and colors items with search results if some are defined
    public loadTreeWithSearches(item: string): JQueryDeferred<unknown> {
        let that = this;
        const mainTreeLoadedEvent = new Event("mainTreeLoaded");

        let res = $.Deferred();

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        that.dbConnection.retrieveTreeAsync().done(function (result: { tree: IDB[]; project: XRProjectInfo }) {
            that.colorBySearches(item, 0).done(() => {
                NavigationPanel.render();
                NavBar.init();
                NavigationPanel.select(item);
                res.resolve();
                //Raise an event when the tree is loaded
                that.mainTreeLoaded = true;
                document.dispatchEvent(mainTreeLoadedEvent);
                matrixApplicationUI.highlightReferences();
                NotificationsBL.NoticationCache.setNotifications(
                    result.project.todos.filter(function (todo) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return todo.login == matrixSession.getUser();
                    }),
                );
            });
        });
        return res;
    }

    private colorBySearches(item: string, idx: number): JQueryDeferred<unknown> {
        let that = this;
        let res = $.Deferred();

        let search: ISearchConfig = globalMatrix.ItemConfig.getSearchConfig();

        // Fix failed tests query (MATRIX-3926)
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (search != undefined && search.searches != undefined) {
            let index = search.searches.findIndex((s) => {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return s.name == "failed tests" && s.expr == "mrql:'testResult'='error'";
            });
            if (index >= 0) {
                search.searches[index].expr = 'mrql:"test run result"~"error"';
            }
        }
        // no more searches defined: time to return and to render the tree
        if (!search || !search.init || search.init.length <= idx) {
            res.resolve();
            return res;
        }
        // run the next search, color the tree and continue
        app.searchAsyncMinimalOutput(search.init[idx].expr)
            .done(function (items) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.setStyle(items, search.init[idx].style, search.init[idx].computeFolder);
                that.colorBySearches(item, idx + 1).always(() => {
                    res.resolve();
                });
            })
            .fail(function () {
                res.resolve();
            });

        return res;
    }
    // render the tree
    private showTree(item: string, cached?: IItem): void {
        let that = this;

        // render the selected item, if there is any
        NavigationPanel.render();
        NavBar.init();

        if (item) {
            if (this.dbConnection.doesExist(item)) {
                this._itemId = item;
                if (typeof globalMatrix.mobileApp !== "undefined") {
                    globalMatrix.mobileApp.ShowMobileUI();
                } else {
                    NavigationPanel.select(item);
                    this.renderItem(cached);
                }
            } else {
                globalMatrix.projectStorage.setItem("SessionLastItem", "");
                if (typeof globalMatrix.mobileApp !== "undefined") {
                    globalMatrix.mobileApp.ShowMobileUI();
                }
                if (matrixSession.getUISettings({ analyticsEnabled: false }).analyticsEnabled) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (item == "ANALYTICS") {
                        NavBar.openAnalytics();
                        return;
                    }
                }
                this.getItemAsync(item)
                    .done(function (_data) {
                        matrixApplicationUI.renderErrorControl(
                            that.itemForm,
                            "Item " + item + " does not exist anymore",
                            "It was deleted, check the 'CHANGES Dashboard' or 'Deleted Items' if you are interested in when or why it was deleted.",
                        );
                    })
                    .fail(function () {
                        matrixApplicationUI.renderErrorControl(
                            that.itemForm,
                            "Item " + item + " does not exist",
                            "Incorrect item id.",
                        );
                    });
            }
        }
    }
    // *********************************************
    // calls from item AND Tree
    // *********************************************

    getTree(subtreeFilter?: string[]): IDB[] {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.dbConnection.getTree(subtreeFilter);
    }

    // api get a simple tree structure of item ids
    getSubTree(itemId: string): ISimpleTree {
        let that = this;
        let tree: ISimpleTree = { itemId: itemId, title: this.getItemTitle(itemId) };
        if (ml.Item.parseRef(itemId).isFolder) {
            tree.children = [];
            $.each(app.getChildrenIds(itemId), function (idx, id) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                tree.children.push(that.getSubTree(id));
            });
            return tree;
        } else {
            return tree;
        }
    }

    // *********************************************
    // calls from item
    // *********************************************

    getAuditDetailsAsync(auditId?: number, ignoreErrors?: boolean): JQueryDeferred<XRTrimAudit> {
        return this.dbConnection.getAuditDetailsAsync(auditId);
    }
    getItemAsync(itemId: string, version?: number, ignoreErrors?: boolean, noHistory?: boolean): JQueryDeferred<IItem> {
        let that = this;
        let res = $.Deferred();

        if (typeof version !== "undefined") {
            this.dbConnection.getVersionAsync(itemId, version, !noHistory).done(function (result) {
                res.resolve(result);
            });
        } else {
            this.dbConnection
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .getItemAsync(itemId, ignoreErrors, !noHistory)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(function (result: IItem) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.setHiddenLink(itemId, result.isUnselected);
                    res.resolve(result);
                })
                .fail(function (error) {
                    res.reject(error);
                });
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    /** get the source of an item */
    getImportSource(item: IItemGet): XRCrossProjectLink {
        if (!item) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }
        if (!item.crossLinks) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let clones = item.crossLinks.filter((cl) => cl.upItem.projectShort == matrixSession.getProject());
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (clones.length == 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (clones.length != 1) {
            console.log("Warning: more than one import sources");
            console.log(clones);
        }

        // return the clone info
        return clones[0];
    }

    /** return true if item is included */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isIncluded(item: IItemGet) {
        let source = this.getImportSource(item);
        if (!source) {
            // not from import
            return false;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return source.relation == EImportMode.Include || source.relation == EImportMode.IncludeRoot;
    }

    /** get the source of an item */
    getUsedBy(item: IItemGet): XRCrossProjectLink[] {
        if (!item) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }
        if (!item.crossLinks) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let clones = item.crossLinks.filter((cl) => cl.downItem.projectShort == matrixSession.getProject());
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (clones.length == 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }

        // return the clone info
        return clones;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getNeedlesAsync(
        searchExpr: string,
        up?: boolean,
        down?: boolean,
        fields?: string,
        labels?: boolean,
        ignoreFilters?: boolean,
    ): JQueryDeferred<IItemGet[]> {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.dbConnection.getNeedlesAsync(searchExpr, up, down, fields, labels, ignoreFilters);
    }

    getItemProjectAsync(project: string, itemId: string, ignoreErrors?: boolean): JQueryDeferred<IItem> {
        let that = this;
        let res = $.Deferred();

        this.dbConnection
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .getItemProjectAsync(project, itemId, ignoreErrors, true)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (result: IItem) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.setHiddenLink(itemId, result.isUnselected);
                res.resolve(result);
            })
            .fail(function (error) {
                res.reject(error);
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    getProjectItemAsync(
        project: string,
        itemId: string,
        version?: number,
        includeHistory?: boolean,
    ): JQueryDeferred<IItem> {
        let that = this;
        let res = $.Deferred();

        if (typeof version !== "undefined") {
            this.dbConnection.getProjectVersionAsync(project, itemId, version, includeHistory).done(function (result) {
                res.resolve(result);
            });
        } else {
            this.dbConnection
                .getProjectItemAsync(project, itemId, true)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(function (result: IItem) {
                    res.resolve(result);
                })
                .fail(function (error) {
                    res.reject(error);
                });
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    getProjectCatFields(project: string): JQueryDeferred<XRCategoryExtendedType[]> {
        let res = $.Deferred();

        this.dbConnection
            .getProjectCat(project)
            .done(function (result) {
                let found = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                res.resolve(result.categoryList.categoryExtended);
            })
            .fail(function (error) {
                res.reject(error);
            });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    getItemFromTree(itemId: string): IDB {
        return this.dbConnection.getItemFromTree(itemId);
    }

    // get children Ids
    getChildrenIds(parentId: string): string[] {
        return this.dbConnection.getChildrenIds(parentId);
    }

    getChildrenIdsRec(itemId: string): string[] {
        let that = this;

        if (ml.Item.parseRef(itemId).isFolder) {
            let treeFlat: string[] = [];
            $.each(app.getChildrenIds(itemId), function (idx, id) {
                treeFlat = treeFlat.concat(that.getChildrenIdsRec(id));
            });
            return treeFlat;
        } else {
            return [itemId];
        }
    }

    getParentId(itemId: string): string {
        return this.dbConnection.getParentId(itemId);
    }

    // return a list of item, parent of item, parent of parent of item...
    getCategoryBreadcrumbs(category: string): string[] {
        return this.dbConnection.getCategoryBreadcrumbs(category);
    }

    // return a list of item, parent of item, parent of parent of item...
    getBreadcrumbs(itemId: string): string[] {
        return this.dbConnection.getBreadcrumbs(itemId);
    }

    /* set style of item / folder
        computeFolder: 0=look (folder style from lookup)
        computeFolder: 1=all (all children in folder have style: folder has style)
        computeFolder: 2=any (any of the children has style: folder has style) */
    setStyle(itemIds: string[], style: string, computeFolder: number): void {
        return this.dbConnection.setStyle(itemIds, style, computeFolder);
    }

    getRootOfType(category: string): string {
        return this.dbConnection.getRootOfType(category);
    }

    startReportAsync(itemId: string, reportOptions: IReportOptions): JQueryDeferred<IPostCreateSignOrDocResult> {
        return this.dbConnection.startReportAsync(itemId, reportOptions);
    }

    canLaunchReport(): JQueryDeferred<boolean> {
        let res = $.Deferred();
        this.dbConnection
            .canLaunchReport()
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (result: XRGetProject_AllJob_JobsWithUrl) {
                res.resolve(result.runningJobs <= result.maxRunningJobs);
            })
            .fail(function () {
                res.resolve(false);
            });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    startCreateDocumentAsync(
        itemId: string,
        reportOptions: IReportOptions,
    ): JQueryDeferred<XRPostProject_LaunchReport_CreateReportJobAck> {
        return this.dbConnection.startCreateDocumentAsync(itemId, reportOptions);
    }

    startCompareDocumentsWord(
        sourceId: string,
        targetId: string,
    ): Promise<XRPostProject_LaunchReport_CreateReportJobAck> {
        return this.dbConnection.startCompareDocumentsWord(sourceId, targetId);
    }

    getReportDetails(jobId: number): JQueryDeferred<XRGetProject_JobStatus_JobsStatusWithUrl> {
        return this.dbConnection.getReportDetails(jobId);
    }

    compareHTML(compareParams: XCPostCompareHtml): JQueryDeferred<XRPostProject_CompareHtml_HtmlCompareResult> {
        return this.dbConnection.compareHTML(compareParams);
    }

    isFolder(itemId: string): boolean {
        return this.dbConnection.isFolder(itemId);
    }

    getItemTitle(itemId: string, display?: boolean): string {
        let title = this.dbConnection.getItemTitle(itemId);
        return display ? ml.UI.lt.forUI(title, 0) : title;
    }

    download(jobId: number, file: number, param?: string[]): void {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.dbConnection.download(jobId, file, param);
    }
    downloadFromUrl(url: string, param?: IStringMap): void {
        this.dbConnection.downloadFromUrl(url, param);
    }

    downloadInMemory(jobId: number, file: string, dataType?: string): JQueryDeferred<string> {
        return this.dbConnection.downloadInMemory(jobId, file, dataType);
    }
    downloadInMemoryFromUrl(url: string): JQueryDeferred<string> {
        return this.dbConnection.downloadInMemoryFromUrl(url);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    searchAsync(
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        fieldList?: string,
        crossProject?: string,
        labels?: boolean,
        down?: boolean,
        up?: boolean,
        treeOrder?: boolean,
    ): JQueryDeferred<ISearchResult[]> {
        return this.dbConnection.searchAsync(
            term,
            filter,
            ignoreFilters,
            fieldList,
            crossProject,
            labels,
            down,
            up,
            treeOrder,
        );
    }

    searchAsyncMinimalOutput(
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        crossProject?: string,
    ): JQueryDeferred<string[]> {
        return this.dbConnection.searchAsyncMinimalOutput(term, filter, ignoreFilters, crossProject);
    }

    updateItemInDBAsync(itemJson: IItemPut, auditAction: string, requireVersion?: number): JQueryDeferred<IItemGet> {
        let that = this;
        let res = $.Deferred();

        matrixSession
            .getCommentAsync()
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (comment: string) {
                that.dbConnection
                    .updateItemAsync(itemJson, comment, auditAction, requireVersion)
                    .done(function (result) {
                        NavigationPanel.update(itemJson);
                        res.resolve(result);
                    })
                    .fail(function () {
                        // MATRIX-3815
                        res.reject("saving failed");
                    });
            })
            .fail(function () {
                // rien a fair
                res.reject("user cancelled");
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    async getItemFromDBAsync(itemId: string): Promise<IItem> {
        let type = ml.Item.parseRef(itemId).type;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!type || globalMatrix.ItemConfig.getCategories(true).indexOf(type) == -1) {
            ml.Logger.error(`This is not possibly an item in this project: "${itemId}"!`);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return await this.getItemAsync(itemId);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getFieldFromDBAsync(itemId: string, fieldName: string): Promise<any> {
        let type = ml.Item.parseRef(itemId).type;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!type || globalMatrix.ItemConfig.getCategories(true).indexOf(type) == -1) {
            ml.Logger.error(`This is not possibly an item in this project: "${itemId}"!`);
            return;
        }
        let fieldId = globalMatrix.ItemConfig.getFieldId(type, fieldName);
        if (!fieldId) {
            ml.Logger.error(`"${fieldName}" is not a field of this item "${itemId}"!`);
            return;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let item: IItem = await this.getItemAsync(itemId);
        return item[fieldId];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async setFieldInDBAsync(itemId: string, fieldName: string, value: string): Promise<any> {
        return this.setFieldsInDBAsync(itemId, [{ fieldName: fieldName, value: value }]);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async setFieldsInDBAsync(itemId: string, data: ISetField[]) {
        let res = $.Deferred();
        let update: Record<string, string | number> = {
            id: itemId,
            onlyThoseFields: 1,
            onlyThoseLabels: 1,
        };
        let type = ml.Item.parseRef(itemId).type;
        if (!type) {
            ml.Logger.error(`This is not possibly an item in this project: "${itemId}"!`);
            res.reject();
            return res;
        }
        for (let s of data) {
            let fieldId = globalMatrix.ItemConfig.getFieldId(type, s.fieldName);
            if (!fieldId) {
                ml.Logger.error(`"${s.fieldName}" is not a field of this item "${itemId}"!`);
                res.reject();
                return res;
            }
            update["fx" + fieldId] = s.value;
        }

        return app.updateItemInDBAsync(update, "edit");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createItemOfTypeAsync(
        category: string,
        itemJson: IItemPut,
        actions: string,
        parentId: string,
        dontFailOnCleanup?: boolean,
    ): JQueryDeferred<IDBParent> {
        let that = this;

        let res = $.Deferred();

        itemJson.type = category;

        matrixSession
            .getCommentAsync()
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (comment: string) {
                that.dbConnection
                    .createItemAsync(itemJson, comment, actions, parentId, dontFailOnCleanup)
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .done(function (result: IDBParent) {
                        // verify if new item needs to be filtered in tree
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (ml.CreateNewLabelTools().isFiltered(category, itemJson.labels)) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.dbConnection.setHiddenLink(result.item.id, 1);
                        }
                        // show it in tree
                        that.insertInTree(result);
                        res.resolve(result);
                    });
            })
            .fail(function () {
                // rien a fair
                res.reject("user cancelled");
            });

        return <JQueryDeferred<IDBParent>>res;
    }

    restoreItemAsync(itemId: string, title: string, version: number): JQueryDeferred<IRestoreItemResult> {
        let that = this;

        let res = $.Deferred();

        matrixSession
            .getCommentAsync()
            .done(function (comment) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.dbConnection.restoreItemAsync(itemId, title, version, comment).done(function (
                    result: IRestoreItemResult,
                ) {
                    if (result) {
                        let itemJson: IItem = {
                            id: itemId,
                            title: title,
                            type: ml.Item.parseRef(itemId).type,
                        };

                        let newItem: IDBParent = {
                            parent: result.response.newParent,
                            position: result.response.newOrder,
                            item: <IItem>itemJson,
                        };

                        if (result.version) {
                            NavigationPanel.update(itemJson);
                            that.renderItem();
                        } else {
                            that.insertInTree(newItem);
                        }
                    }
                    res.resolve(result);
                });
            })
            .fail(function () {
                // rien a fair
                res.reject();
            });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    deleteItem(itemId: string): JQueryDeferred<{}> {
        let res = $.Deferred();

        let that = this;

        if (this.dbConnection.hasChildren(itemId)) {
            // MATRIX-920 Allow to delete non-empty folders

            this.verifyNoLockedItems(itemId)
                .done(function () {
                    window.setTimeout(function () {
                        ml.UI.showConfirm(
                            7,
                            { title: "The folder is not empty! <b>Delete anyway?</b>", ok: "Delete" },
                            function () {
                                matrixSession
                                    .getCommentAsync()
                                    .done(function (comment) {
                                        that.dbConnection
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            .deleteItemAsync(itemId, comment, true)
                                            .done(function (parent) {
                                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                                that.removedFromTree(itemId, parent);

                                                res.resolve();
                                            })
                                            .fail(function () {
                                                res.reject();
                                            });
                                    })
                                    .fail(function () {
                                        res.reject();
                                        // rien a fair
                                    });
                            },
                            function () {
                                res.reject();
                            },
                        );
                    }, 1000);
                })
                .fail(function () {
                    res.reject();
                });

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        if (matrixApplicationUI.lastMainItemForm && this._needsSave) {
            ml.UI.showError("Item was modifed.", "Save item first or cancel changes.");
            res.reject();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        matrixSession
            .getCommentAsync()
            .done(function (comment) {
                that.dbConnection
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .deleteItemAsync(itemId, comment, false)
                    .done(function (parent) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.removedFromTree(itemId, parent);
                        res.resolve();
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                res.reject();
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private verifyNoLockedItems(folderId: string): JQueryDeferred<{}> {
        let res = $.Deferred();

        // check if there are any locks
        let ll = globalMatrix.ItemConfig.getLabelLockConfig();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!ll || !ll.locks || ll.locks.length == 0) {
            res.resolve();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        // create a list of locking labels
        let locks = ll.locks.map(function (lock) {
            return lock.label;
        });

        this.getNeedlesAsync("folderm=" + folderId, false, false, "", true, true)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (results: IItemGet[]) {
                let itemsWithLocks: string[] = [];

                $.each(results, function (idx, result) {
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        result.labels.filter(function (label) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            return locks.indexOf(label) != -1;
                        }).length > 0
                    ) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        itemsWithLocks.push(result.id);
                    }
                });
                if (itemsWithLocks.length) {
                    ml.UI.showError(
                        "Deleted failed",
                        "Some of the items are locked. Please unlock before deleting. Item(s) with locks: " +
                            itemsWithLocks.join(","),
                    );
                    res.reject();
                } else {
                    res.resolve();
                }
            })
            .fail(function () {
                res.reject();
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    uploadFileProjectAsync(file: IFileParam, progress: (p: IFileUploadProgress) => void): JQueryDeferred<{}> {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>this.dbConnection.uploadFileProjectAsync(file, progress);
    }

    fetchFileAsync(
        url: string,
        progress: (p: IFileUploadProgress) => void,
    ): JQueryDeferred<XRPostProject_AddFile_AddFileAck> {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>this.dbConnection.fetchFileAsync(url, progress);
    }
    resizeItem(force?: boolean): void {
        if (matrixApplicationUI.lastMainItemForm) {
            matrixApplicationUI.lastMainItemForm.resizeItem(force);
        }
    }

    itemChanged(needsSave: boolean): void {
        this._needsSave = needsSave;
        this.resizeItem(true);
        matrixApplicationUI.updateMainUI();
    }

    // this is called after an item has been reloaded and the rendering must be updated
    updateItem(newItem: IItem): void {
        if (matrixApplicationUI.lastMainItemForm) {
            matrixApplicationUI.lastMainItemForm.updateItem(newItem);
        }
    }

    // sets a value of a field, the field must exist and have a setValue function
    setFieldValue(fieldId: number, newValue: string): void {
        matrixApplicationUI.lastMainItemForm.setFieldValue(fieldId, newValue);
    }
    // get value from current UI
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getFieldValueAsync(fieldId: number) {
        return await matrixApplicationUI.lastMainItemForm.getFieldValue(fieldId);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getCurrentTitle() {
        return matrixApplicationUI.lastMainItemForm.getCurrentTitle();
    }
    isConfigApp(): false {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addDownLinkAsync(fromId: string, toId: string): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();

        matrixSession
            .getCommentAsync()
            .done(function (comment) {
                that.dbConnection
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .addDownLinkAsync(fromId, toId, comment)
                    .done(function () {
                        that.refreshUIAsync(fromId, toId);
                        res.resolve();
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        if (jqxhr.responseText.indexOf("This link already exists") > -1) {
                            // not really a failure so ignore
                            res.resolve();
                        } else {
                            res.reject(jqxhr, textStatus, error);
                        }
                    });
            })
            .fail(function () {
                res.reject("user cancelled");
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    removeDownLinkAsync(fromId: string, toId: string): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();
        matrixSession
            .getCommentAsync()
            .done(function (comment) {
                that.dbConnection
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .removeDownLinkAsync(fromId, toId, comment)
                    .done(function () {
                        that.refreshUIAsync(fromId, toId);
                        res.resolve();
                        /*
                WorkflowEngine.applyCommentAsync(fromId, "removedownlink|" + toId, comment);
                */
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                // rien a fair
                res.reject("user cancelled");
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setSettingJSON(key: string, valueJSON: {}): JQueryDeferred<{}> {
        return this.dbConnection.setSettingJSON(key, valueJSON);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    readSettingJSONAsync(key: string, otherProject?: string, noRetry?: boolean): JQueryDeferred<any> {
        return this.dbConnection.readSettingJSONAsync(key, otherProject, noRetry);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setSettingCustomerJSON(key: string, valueJSON: {}): JQueryDeferred<unknown> {
        return this.dbConnection.setSettingCustomerJSON(key, valueJSON);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    readSettingCustomerJSONAsync(key: string): JQueryDeferred<any> {
        return this.dbConnection.readSettingCustomerJSONAsync(key);
    }

    // *********************************************
    // manage references either from up to 1.6 category params
    // or 1.6 and later json setting
    //
    //
    /*  "rules": [
     {"category": "MREQ",
     "down_rules": [
     {
     "rule":"must_have",
     "any_of": [ "FREQ", "TREQ" ]
     },
     {
     "rule":"can_have",
     "any_of": [ "UC" ]
     },
     {
     "rule":"can_have",
     "any_of": [ "TC" ]
     }]
     */
    // *********************************************

    getMissingUpLinks(item: IItem): string[] {
        let upMissing: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let ulTypes = globalMatrix.ItemConfig.getLinkTypes(item.type, false, true);
        for (let ulidx = 0; ulidx < ulTypes.length; ulidx++) {
            let missing = true;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let idx = 0; idx < item.upLinks.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (item.upLinks[idx].to.indexOf(ulTypes[ulidx] + "-") === 0) {
                    missing = false;
                }
            }
            if (missing) {
                upMissing.push(ulTypes[ulidx]);
            }
        }
        return upMissing;
    }

    // check is a required uplink is missing
    isUpLinkMissing(item: IItem): boolean {
        let result = this.evaluateTraceRule(item, false);
        if (result) {
            return !result.valid;
        }

        return this.getMissingUpLinks(item).length > 0;
    }

    getMissingDownLinks(item: IItem): string[] {
        let result = this.evaluateTraceRule(item, true);
        if (result) {
            return result.missingMustHaveCategories;
        }

        let downMissing: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let dlTypes = globalMatrix.ItemConfig.getDownLinkTypes(item.type, true);
        for (let dlidx = 0; dlidx < dlTypes.length; dlidx++) {
            let missing = true;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let idx = 0; idx < item.downLinks.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (item.downLinks[idx].to.indexOf(dlTypes[dlidx] + "-") === 0) {
                    missing = false;
                }
            }
            if (missing) {
                downMissing.push(dlTypes[dlidx]);
            }
        }
        return downMissing;
    }

    // return a list of required and optional downlinks
    getLinkCategories(item: IItem, ctrlParameter: ILinkCollectionOptions): ILinkCategories[] {
        let result = this.evaluateTraceRule(item, true);
        if (result) {
            let resultLinks: ILinkCategories[] = [];
            let links: string[] = [];
            $.each(result.mustHaveCategories, function (catIdx, cat) {
                if (links.indexOf(cat) === -1) {
                    links.push(cat);
                    resultLinks.push({
                        name: globalMatrix.ItemConfig.getCategoryLabel(cat),
                        required: true,
                        type: cat,
                    });
                }
            });

            $.each(result.canHaveCategories, function (catIdx, cat) {
                if (links.indexOf(cat) === -1) {
                    links.push(cat);
                    resultLinks.push({
                        name: globalMatrix.ItemConfig.getCategoryLabel(cat),
                        required: false,
                        type: cat,
                    });
                }
            });
            return resultLinks;
        }

        // legacy
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return ctrlParameter.parameter.linkTypes ? ctrlParameter.parameter.linkTypes : [];
    }

    isDownLinkMissing(item: IItem): boolean {
        let result = this.evaluateTraceRule(item, true);
        if (result) {
            return !result.valid;
        }

        return this.getMissingDownLinks(item).length > 0;
    }

    // returns true if any down or uplink is outdated
    isAnyLinkOutdated(item: IItem): boolean {
        let resultDown = this.evaluateTraceRule(item, true);
        if (resultDown) {
            let resultUp = this.evaluateTraceRule(item, false);
            return resultDown.outdatedReferences.length + resultUp.outdatedReferences.length > 0;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let itemDate = new Date(item.modDate);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let idx = 0; idx < item.downLinks.length; idx++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            const refDate = new Date(item.downLinks[idx].modDate);
            if (itemDate > refDate) {
                return true;
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let idx = 0; idx < item.upLinks.length; idx++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            const refDate = new Date(item.upLinks[idx].modDate);
            if (itemDate < refDate) {
                return true;
            }
        }
        return false;
    }

    hasLinks(item: IItem): boolean {
        let result = this.evaluateTraceRule(item, false);
        if (result) {
            if (result.canHaveCategories.length > 0) {
                return true;
            }
            if (result.mustHaveCategories.length > 0) {
                return true;
            }
            result = this.evaluateTraceRule(item, true);
            if (result.canHaveCategories.length > 0) {
                return true;
            }
            if (result.mustHaveCategories.length > 0) {
                return true;
            }
            // todo ... maybe manage in
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (item.type === "XTC" || (mDHF && mDHF.isSignedType(item.type))) {
                // special case
                return true;
            }
            return false;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (globalMatrix.ItemConfig.getDownLinkTypes(item.type, false).length > 0) {
            return true;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (globalMatrix.ItemConfig.getDownLinkTypes(item.type, true).length > 0) {
            return true;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (globalMatrix.ItemConfig.getUpLinkTypes(item.type, false).length > 0) {
            return true;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (globalMatrix.ItemConfig.getUpLinkTypes(item.type, true).length > 0) {
            return true;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (item.type === "XTC" || (mDHF && mDHF.isSignedType(item.type))) {
            // special case
            return true;
        }
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isHiddenLink(itemId: string) {
        return this.dbConnection.isHiddenLink(itemId);
    }

    setHiddenLink(itemId: string, hidden: number): void {
        if (globalMatrix.ItemConfig.getTimeWarp()) {
            return;
        }
        this.dbConnection.setHiddenLink(itemId, hidden);
        NavigationPanel.updateItemIsUnselected(itemId, hidden !== 0);
    }
    // *********************************************
    // calls from outer UI
    // *********************************************

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    saveAsync(sendUnEdit: boolean): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();
        if (matrixApplicationUI.lastMainItemForm) {
            matrixSession.updateWatchItemVersion(app.getCurrentItemId(), -1);
            matrixApplicationUI.lastMainItemForm
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .saveAsync(null, null)
                .then(function (result: IItem) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    matrixSession.updateWatchItemVersion(result.id, result.maxVersion);

                    if (sendUnEdit) {
                        matrixSession.pushMessages.unEditItem();
                    }
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.setHiddenLink(result.id, result.isUnselected);
                    // that will send a new watching message after the unEdit above
                    that.renderItem(result);
                    res.resolve();
                })
                .catch(function () {
                    res.reject();
                });
        } else {
            res.resolve();
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // this is brutal, it just cancels all edits and set's the item to readonly
    // happens at start of download
    forceReadonly(itemId: string): void {
        if (matrixApplicationUI.lastMainItemForm) {
            this._needsSave = false;
            matrixApplicationUI.forceReadonly(itemId);
        }
    }
    cancel(): void {
        if (matrixApplicationUI.lastMainItemForm) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.renderItem(null);
        }
    }

    // this is called if two users watches the same item, one edits and saves it...
    someOneElseChanged(): void {
        let that = this;
        if (this.waitingForEditRights) {
            ml.UI.closeConfirmSpinningWait();
        }
        if (matrixApplicationUI.lastMainItemForm) {
            if (this.needsSave()) {
                ml.UI.showConfirm(
                    -1,
                    { title: "Someone just changed the item.", ok: "Update to latest", nok: "Ignore and merge later" },
                    () => {
                        // reload the item
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.renderItem(null);
                    },
                    () => {
                        // do nothing... user will pay the price later
                    },
                );
            } else {
                // the item changed, time to reload the new version
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.renderItem(null);
            }
        }
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private waitingForEditRights: boolean;

    someOneIsChangingTheItem(): void {
        let that = this;
        if (matrixApplicationUI.lastMainItemForm && !this.waitingForEditRights) {
            if (this.needsSave()) {
                ml.UI.showConfirm(
                    -1,
                    { title: "Someone else is changing this item.", ok: "Update to latest", nok: "Wait and merge" },
                    () => {
                        // reload the item
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.renderItem(null);
                    },
                    () => {
                        that.waitForEditRights();
                    },
                );
            } else {
                // the item changed, time to reload the new version
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.renderItem(null);
            }
        }
    }

    waitForEditRights(): void {
        let that = this;

        this.waitingForEditRights = true;
        ml.UI.showConfirm(
            -1,
            { title: ml.UI.getSpinningWait("Waiting for other user to stop editing").html(), ok: "Cancel" },
            () => {
                // reload the item
                that.waitingForEditRights = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.renderItem(null);
            },
            () => {},
        );
    }
    // someone else started to change
    someOneElseIsChanging(watcherInfo: IItemWatched): void {
        let that = this;
        if (matrixApplicationUI.lastMainItemForm) {
            if (this.needsSave()) {
                // this should not happen
                ml.UI.showConfirm(
                    -1,
                    {
                        title: watcherInfo.editor.user + " started editing the item",
                        ok: "Cancel my Edit",
                        nok: "Edit and merge later",
                    },
                    () => {
                        // reload the item
                        that.updateItemDisplay(watcherInfo);
                    },
                    () => {
                        // do nothing... user will pay the price later
                    },
                );
            } else {
                // someone else started to edit the item, make it readonly for this user to avoid conflicts
                this.updateItemDisplay(watcherInfo);
            }
        }
    }
    someOneElseWasChanging(watcherInfo: IItemWatched): void {
        let that = this;
        if (matrixApplicationUI.lastMainItemForm) {
            if (this.needsSave()) {
                if (that.freezeDuringSave) {
                    // this is a race condition we can live with
                    return;
                }
                // this should not happen
                ml.UI.showConfirm(
                    -1,
                    {
                        title: " Someone else changed the item in the meantime. You won't be able to save!",
                        ok: "Load updated item",
                        nok: "Do not yet update",
                    },
                    () => {
                        // reload the item
                        that.cancel();
                    },
                    () => {
                        // do nothing... user will pay the price later
                    },
                );
            } else {
                // someone else started to edit the item, make it readonly for this user to avoid conflicts
                this.updateItemDisplay(watcherInfo);
            }
        }
    }

    // someone else stopped editing the same item, but apparently canceled the edit, or session timed out
    someOneElseStoppedEditing(watcherInfo: IItemWatched, previousWatcherInfo: IItemWatched): void {
        ml.Logger.log("info", previousWatcherInfo.editor.user + " stopped editing");
        if (matrixApplicationUI.lastMainItemForm) {
            if (this.needsSave()) {
                ml.Logger.log("info", "user was also editing nothing I can do");
                // just let the user do it thing it might cause issues to save later
                // but nothing we can do
            } else {
                // reload the latest version of the item, and make sure it writeable (if user has write access)
                // this is not really necessary, since the item should not have changed, so we could also just switch the user from
                // readonly to write (if needed)
                this.updateItemDisplay(watcherInfo);
            }
        }
    }

    // just update the list of items
    updateItemViewers(watcherInfo: IItemWatched): void {
        if (matrixApplicationUI.lastMainItemForm) {
            this.updateItemDisplay(watcherInfo);
        }
    }

    protected updateItemDisplay(watcherInfo: IItemWatched): void {
        matrixApplicationUI.updateControl(watcherInfo, this.itemChanged.bind(this));
    }

    getVersion(): string {
        return $("meta[name='mx-version']").attr("content");
    }

    getVersionQualifier(): string {
        return $("meta[name='mx-version-qualifier']").attr("content");
    }

    getNeedsSave(): boolean {
        return this._needsSave;
    }

    getType(itemId: string): string {
        return this.dbConnection.getType(itemId);
    }

    getAvailableReportsAsync(): JQueryDeferred<XRGetProject_Reports_GetReportsAck> {
        return this.dbConnection.getAvailableReportsAsync();
    }

    private deleteLog = [];
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private deletedHandler(
        insertInList: (item: IVersionDetails) => void,
        result: XRGetProject_ProjectAudit_TrimAuditList,
    ) {
        const that = this;
        let cats = globalMatrix.ItemConfig.getCategories();
        let deleteLog = that.deleteLog;
        if (!result) {
            return;
        }

        for (let idx = 0; idx < result.audit.length; idx++) {
            if (result.audit[idx].action === "delete" && result.audit[idx].itemBefore) {
                const ir = ml.Item.parseRef(result.audit[idx].itemBefore.itemOrFolderRef);
                const itemId = ir.id;
                const version = ir.version;
                if (!ir.isFolder && cats.indexOf(ir.type) !== -1) {
                    // verify that the id is not in the current project (as it was undeleted) or already in the list of deleted objects
                    let found = false;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    for (let di = 0; di < deleteLog.length; di++) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (deleteLog[di].id == itemId) {
                            found = true;
                        }
                    }
                    found = found || that.dbConnection.doesExist(itemId);
                    if (!found) {
                        const item: IVersionDetails = {
                            action: "delete",
                            id: itemId,
                            title: result.audit[idx].itemBefore.title,
                            user: result.audit[idx].userLogin,
                            date: result.audit[idx].dateTime,
                            dateUserFormat: result.audit[idx].dateTimeUserFormat,
                            comment: result.audit[idx].reason,
                            version: version,
                            fullVersion: result.audit[idx].itemBefore.itemOrFolderRef,
                            auditId: result.audit[idx].auditId,
                            tags: result.audit[idx].tags,
                        };
                        insertInList.apply(that, [item]);
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        deleteLog.push(item);
                    }
                }
            }
            if (result.audit[idx].action === "merge" && result.audit[idx].itemsDeletedByMerge) {
                for (let idbm of result.audit[idx].itemsDeletedByMerge) {
                    const ir = ml.Item.parseRef(idbm.ref);
                    const itemId = ir.id;
                    const version = ir.version;
                    if (!ir.isFolder && cats.indexOf(ir.type) !== -1) {
                        // verify that the id is not in the current project (as it was undeleted) or already in the list of deleted objects
                        let found = false;
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        for (let di = 0; di < deleteLog.length; di++) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (deleteLog[di].id == itemId) {
                                found = true;
                            }
                        }
                        found = found || that.dbConnection.doesExist(itemId);
                        if (!found) {
                            const item: IVersionDetails = {
                                action: "delete",
                                id: itemId,
                                title: idbm.title,
                                user: result.audit[idx].userLogin,
                                date: result.audit[idx].dateTime,
                                dateUserFormat: result.audit[idx].dateTimeUserFormat,
                                comment: result.audit[idx].reason,
                                version: version,
                                fullVersion: idbm.ref,
                                auditId: result.audit[idx].auditId,
                                tags: result.audit[idx].tags,
                            };
                            insertInList.apply(that, [item]);
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            deleteLog.push(item);
                        }
                    }
                }
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getActivityAsync(
        insertInList: (
            item: IVersionDetails,
            first?: number,
            last?: number,
            referenceChange?: IReferenceUpdate,
        ) => void,
        startAt?: number,
        count?: number,
        auditIdMin?: number,
        auditIdMax?: number,
        deletedOnly?: boolean,
    ): JQueryDeferred<number> {
        let that = this;

        let res = $.Deferred();
        let cats = globalMatrix.ItemConfig.getCategories();
        let param: XCGetProjectAudit = {};
        if (auditIdMin) {
            param.auditIdMin = auditIdMin;
        }
        if (auditIdMax) {
            param.auditIdMax = auditIdMax;
        }
        if (deletedOnly) {
            param.deleteOnly = "yes";
        }
        param.include = globalMatrix.historyFilter;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.dbConnection.getAuditLogAsync(startAt, count, param).done(function (
            result: XRGetProject_ProjectAudit_TrimAuditList,
        ) {
            // We have a special handler for traversing the log of deleted items.
            if (deletedOnly) {
                if (result.startAt <= 1) {
                    // reset the delete log.
                    that.deleteLog = [];
                }
                that.deletedHandler(insertInList, result);
                return res.resolve(result.totalResults);
            }

            for (let idx = 0; idx < result.audit.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let itemDetails: XRTrimNeedleItemJob = null;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let addedLink: string = null;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let removedLink: string = null;

                switch (result.audit[idx].action) {
                    case "delete":
                        if (result.audit[idx].entity === "item_link") {
                            removedLink = result.audit[idx].itemDown.itemOrFolderRef;
                        } else {
                            itemDetails = result.audit[idx].itemBefore;
                        }
                        break;
                    case "add":
                        if (result.audit[idx].entity === "item_link") {
                            if (!result.audit[idx].itemDown) {
                                ml.Logger.log("warning", "add item_link but no details");
                                break;
                            }
                            addedLink = result.audit[idx].itemDown.itemOrFolderRef;
                        } else {
                            itemDetails = result.audit[idx].itemAfter;
                        }
                        break;
                    case "undelete":
                    case "edit":
                    case "reviewed": // reviewed is used by review labels
                    case "execute":
                    case "signature":
                    case "touch":
                        itemDetails = result.audit[idx].itemAfter;
                        break;
                    case "report":
                        // we simulate the ItemAfter object for print jobs, that is not generated by the API anymore
                        itemDetails = <XRTrimNeedleItem>new Object();
                        itemDetails["job"] = result.audit[idx].reportJobId;
                        itemDetails["itemOrFolderRef"] = result.audit[idx].reportRef + "-v0";
                        itemDetails["title"] = result.audit[idx].reportTitle;
                        break;
                    case "move":
                        // drag and drop of item
                        // I don't get the before / after so I just ignore it
                        break;
                    case "merge":
                        // branch was merged
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (result.audit[idx].entity == "begin_merge") {
                            // that's a "fake" change just before the merge
                        } else {
                            let merge: IVersionDetails = {
                                action: result.audit[idx].action,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                id: null,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                title: null,
                                user: result.audit[idx].userLogin,
                                date: result.audit[idx].dateTime,
                                dateUserFormat: result.audit[idx].dateTimeUserFormat,
                                comment: result.audit[idx].reason,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                version: null,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                fullVersion: null,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                job: null,
                                reason: result.audit[idx].reason,
                                tags: result.audit[idx].tags,
                                auditId: result.audit[idx].auditId,
                            };
                            insertInList.apply(that, [merge]);
                        }
                        break;
                    case "report udpate":
                        // this is a change to a doc's structure: changed in 1.9 to use just normal edit
                        //we just ignore it
                        break;
                    case "udpate":
                        // this is a change to a project setting
                        //we just ignore it
                        break;
                    default:
                        ml.Logger.log("warning", "ACTIVITY: " + result.audit[idx].action);
                }
                if (itemDetails) {
                    let ir = ml.Item.parseRef(itemDetails.itemOrFolderRef);
                    if (cats.indexOf(ir.type) !== -1) {
                        let item: IVersionDetails = {
                            action: result.audit[idx].action,
                            id: ir.id,
                            title: itemDetails.title,
                            user: result.audit[idx].userLogin,
                            date: result.audit[idx].dateTime,
                            dateUserFormat: result.audit[idx].dateTimeUserFormat,
                            comment: result.audit[idx].reason,
                            version: ir.version,
                            fullVersion: itemDetails.itemOrFolderRef,
                            job: itemDetails["job"],
                            reason: result.audit[idx].reason,
                            tags: result.audit[idx].tags,
                            auditId: result.audit[idx].auditId,
                        };
                        insertInList.apply(that, [item]);
                    }
                } else if (addedLink) {
                    insertInList.apply(that, [
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        <IReferenceUpdate>{
                            added: true,
                            fromId: result.audit[idx].itemUp.itemOrFolderRef,
                            toId: addedLink,
                            date: result.audit[idx].dateTime,
                            dateUserFormat: result.audit[idx].dateTimeUserFormat,
                            comment: result.audit[idx].reason,
                            user: result.audit[idx].userLogin,
                        },
                    ]);
                } else if (removedLink) {
                    insertInList.apply(that, [
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        <IReferenceUpdate>{
                            added: false,
                            fromId: result.audit[idx].itemUp.itemOrFolderRef,
                            toId: removedLink,
                            date: result.audit[idx].dateTime,
                            dateUserFormat: result.audit[idx].dateTimeUserFormat,
                            comment: result.audit[idx].reason,
                            user: result.audit[idx].userLogin,
                        },
                    ]);
                }
            }
            res.resolve(result.totalResults);
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    // *********************************************
    // calls from tree
    // *********************************************

    canNavigateAwayAsync(): JQueryDeferred<void> {
        let that = this;

        let res: JQueryDeferred<void> = $.Deferred();

        if (this.saving) {
            ml.UI.showError("Can't navigate", "Saving in progress");
            res.reject();
            return res;
        }

        if (matrixApplicationUI.lastMainItemForm && this._needsSave) {
            // get errors is comment needs ticked id
            let commentNeedsTicket: string[] = [];

            commentNeedsTicket = mTasks ? mTasks.evaluateTaskIds(matrixSession.getComment()) : [];

            if (
                localStorage.getItem("idAutoCommit") === "true" &&
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                !(this.commentRequired() && matrixSession.getComment() == "") &&
                commentNeedsTicket.length === 0
            ) {
                that.freezeDuringSave = true;
                matrixApplicationUI.lastMainItemForm
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .saveAsync(null, null)
                    .then(function (result: IItem) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.setHiddenLink(result.id, result.isUnselected);
                        that._needsSave = false;
                        that.freezeDuringSave = false;
                        res.resolve(); // no problem, changes were saved automatically
                    })
                    .catch(function (error: string) {
                        ml.UI.showError("Save failed!", error);
                        res.reject();
                        that.freezeDuringSave = false;
                    });
            } else {
                if (localStorage.getItem("idAutoCommit") === "true") {
                    if (commentNeedsTicket.length > 0) {
                        ml.UI.showError(
                            "You need to save first! Enter a session comment with a ticket id",
                            commentNeedsTicket.join(" "),
                        );
                    } else {
                        ml.UI.showError("You need to save first!", "Enter a session comment to use autosave");
                    }
                } else {
                    ml.UI.showError("You need to save first!", "Tip: enable autosave");
                }
                res.reject();
            }
        } else {
            res.resolve(); // no changes -> no problem
        }
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    treeSelectionChangeAsync(newItemId: string): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();

        if (this.freezeDuringSave) {
            // we are saving... just ignore the click
            res.reject();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }
        this.canNavigateAwayAsync()
            .done(function () {
                that._itemId = newItemId;
                that.renderItem();
                that._needsSave = false;
                // select in tree but do not trigger a reload
                NavigationPanel.select(newItemId);

                // Automatically switch to item when mobileLayout
                let mobileLayout = localStorage.getItem("mobileLayout");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (mobileLayout && mobileLayout != "" && that._itemId.indexOf("F-") != 0) {
                    localStorage.setItem("mobileLayout", "0");
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    (window as any).applyResponsiveView();
                }

                res.resolve();
            })
            .fail(function () {
                // select in tree but do not trigger a reload
                window.setTimeout(function () {
                    NavigationPanel.select(that.getCurrentItemId());
                }, 1);
                res.reject();
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    moveItemsAsync(itemIds: string, newFolder: string, newPosition?: number, useComment?: string): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();
        if (useComment) {
            that.dbConnection
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .moveItemsAsync(itemIds, newFolder, newPosition, useComment)
                .done(function (result) {
                    that.updateMaxVersion(itemIds).always(function () {
                        res.resolve();
                    });
                })
                .fail(function () {
                    res.reject();
                });
        } else {
            matrixSession
                .getCommentAsync()
                .done(function (comment) {
                    that.dbConnection
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        .moveItemsAsync(itemIds, newFolder, newPosition, comment)
                        .done(function (result) {
                            that.updateMaxVersion(itemIds).always(function () {
                                res.resolve();
                            });
                        })
                        .fail(function () {
                            res.reject();
                        });
                })
                .fail(function () {
                    res.reject();
                });
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // MATRIX-3728 e.g. after an item move, the item's history count might be increased
    // let's update it to avoid merge dialog when editing after
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMaxVersion(itemId: string): JQueryDeferred<{}> {
        let res = $.Deferred();
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (app.getCurrentItemId() != itemId) {
            // nothing to do
            res.reject();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }
        app.getItemAsync(itemId, undefined, true, true)
            .done(function (updatedItem) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                matrixSession.updateWatchItemVersion(itemId, updatedItem.maxVersion);
                res.resolve();
            })
            .fail(function () {
                // nothing i wan't to do...
                res.reject();
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // *********************************************
    // calls from database to update tree or item
    // *********************************************

    removedFromTree(itemId: string, parentId: string): void {
        // called after an item has been deleted

        // make sure trees get informed (and remove it)
        NavigationPanel.remove(itemId);
        // select the parent
        if (this._itemId === itemId) {
            this._itemId = parentId;
            this.treeSelectionChangeAsync(this._itemId)
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                .done(function () {})
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                .fail(function () {});
        }
    }

    insertInTree(newItem: IDBParent): void {
        if (newItem.item.children) {
            // 'inherit' folder icon from parent
            newItem.item.icon = this.dbConnection.getIcon(newItem.parent);
        }

        // make sure trees get informed)
        NavigationPanel.insertInTree(newItem);
    }

    // replaces the target, a child inside the target or creates a new child with source
    copyFrom(target: string, source: IDB): void {
        if (this.dbConnection.copyFrom(target, source)) {
            // make sure trees get informed
            NavigationPanel.insertUpdateTreeRec(target, source);
        }
    }

    updateCache(newItem: IUpdateCache): void {
        this.dbConnection.updateCache(newItem);
    }

    // *********************************************
    // helper functions
    // *********************************************

    createItemUrl(itemId?: string, crossProject?: string): string {
        return (
            globalMatrix.matrixBaseUrl +
            "/" +
            (crossProject ? crossProject : matrixSession.getProject()) +
            (itemId ? "/" + itemId : "") +
            window.location.search
        );
    }

    renderItem(cachedItem?: IItem): void {
        if (!matrixSession.duringBrowserNavigation && this.lastState !== this._itemId) {
            this.lastState = this._itemId;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            window.history.pushState(null, null, this.createItemUrl(this._itemId));
        }

        globalMatrix.projectStorage.setItem("SessionLastItem", this._itemId);
        let folderType = this.dbConnection.getType(this._itemId);

        matrixApplicationUI.createControl(folderType, this._itemId, this.itemChanged.bind(this), cachedItem);
    }

    print(): void {
        window.print();
    }

    touchAsync(itemOrFolderId: string, depth: number): JQueryDeferred<string> {
        let that = this;

        let res = $.Deferred();
        matrixSession
            .getCommentAsync()
            .done(function (comment) {
                that.dbConnection
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .touchAsync(itemOrFolderId, depth, comment)
                    .done(function (result) {
                        that.renderItem();
                        res.resolve(result);
                    })
                    .fail(function (error) {
                        res.reject(error);
                    });
            })
            .fail(function (error) {
                res.reject(error);
            });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }
    // saves item, but instead of getting labels from ui, it uses the passed on values
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setLabels(newLabels: IItemGet): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();
        if (matrixApplicationUI.lastMainItemForm) {
            matrixApplicationUI.lastMainItemForm
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .saveAsync(null, "reviewed", newLabels)
                .then(function (result: IItem) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.setHiddenLink(result.id, result.isUnselected);
                    that.renderItem(result);
                    res.resolve();
                })
                .catch(function () {
                    res.reject();
                });
        } else {
            res.resolve();
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    needsSave(): boolean {
        return this._needsSave;
    }

    signItemAsync(
        itemId: string,
        password: string,
        meaning?: string,
    ): JQueryDeferred<XRPostProject_SignItem_SignItemAck> {
        let that = this;

        let res = $.Deferred();

        matrixSession
            .getCommentAsync()
            .done(function (comment) {
                that.dbConnection
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .signItemAsync(itemId, password, comment, meaning)
                    .done(function (result) {
                        res.resolve(result);
                    })
                    .fail(function (error) {
                        res.reject(error);
                    });
            })
            .fail(function (error) {
                res.reject(error);
            });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    checkPassword(password: string): JQueryDeferred<IRestResult> {
        return this.dbConnection.checkPassword(password);
    }

    convertDocAsync(fileNo: number, targetDocumentFolder?: string, useOriginal?: boolean): JQueryDeferred<string> {
        let that = this;

        let res = $.Deferred();

        that.dbConnection
            .convertDocAsync(fileNo, "import for conversion", targetDocumentFolder, useOriginal)
            .done(function (result) {
                res.resolve(result);
            })
            .fail(function (error) {
                res.reject(error);
            });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return <any>res;
    }

    pingCurrentItem(): void {
        let that = this;

        // do not ping special items like -1,-2,-3 (virtual folders)
        if (this._itemId && this._itemId.indexOf("-") !== 0) {
            let type = this.dbConnection.getType(this._itemId);
            if (type[0] !== "_") {
                // in case the type is a valid category get this setting (if not it will just return null, which is fine to)
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let concurrentEdit = <any>(
                    globalMatrix.ItemConfig.getCategorySetting(type, DefaultCategorySettingNames.concurrentEdit)
                );
                if (concurrentEdit && concurrentEdit.disabled) {
                    // if concurrent editing is possible (warning the flag is kind of negated) no need to do something
                    return;
                }
                // don't ping special meta folders like _PROJECT
                this.getItemAsync(this._itemId).done(function (item) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.updateItem(item);
                });
            }
        }
    }

    getCurrentItemId(): string {
        return this._itemId;
    }

    /** sessions */

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    commitChangeListAsync(changeList: IReferenceChange[]): JQueryDeferred<{}> {
        let res = $.Deferred();
        matrixSession.startCommitTransaction();
        this.commitChangeListRec(changeList, 0)
            .done(function () {
                matrixSession.stopCommitTransaction();
                res.resolve();
            })
            .fail(function (error, stepsDone) {
                matrixSession.stopCommitTransaction();
                res.reject(error, stepsDone);
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    /**
     *  product variations
     * */

    isMedical(strict?: boolean): boolean {
        if (globalMatrix.matrixProduct.toLowerCase() === "medical") {
            return true;
        }
        if (strict) {
            return false;
        }
        return globalMatrix.matrixProduct.toLowerCase() === "tachyscp";
    }

    commentRequired(): boolean {
        return this.isMedical();
    }
    touchToolAvailable(item: IItem): boolean {
        if (item.cloneSource) {
            return false;
        }
        return this.canEditItem(item) && this.isMedical();
    }
    auditTrailAvailable(): boolean {
        return this.isMedical();
    }

    mailToolAvailable(): boolean {
        return this.isMedical();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    postLogin(user: string): void {}

    canDeleteItem(item: IItem): boolean {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!matrixSession.isEditor() || !globalMatrix.ItemConfig.canDelete(item.type)) {
            return false;
        }

        if (this.isIncluded(item)) {
            return false;
        }

        return true;
    }

    canViewItem(item: IItem): boolean {
        return true;
    }

    canEditItem(item: IItem): boolean {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!matrixSession.isEditor() || !globalMatrix.ItemConfig.canEdit(item.type)) {
            return false;
        }
        return true;
    }

    canCreateItemType(category: string, folder?: boolean): boolean {
        if (!matrixSession.isEditor() && !globalMatrix.ItemConfig.canCreate(category)) {
            return false;
        }

        return true;
    }

    canDragDrop(category: string, id: string): boolean {
        if (!matrixSession.isEditor() || !globalMatrix.ItemConfig.canMove(category)) {
            return false;
        }

        return true;
    }
    // to overwrite default drag and drop
    dragEnter?: (dragged: Fancytree.FancytreeNode, target: Fancytree.FancytreeNode) => string[] | boolean;

    canSeeField(category: string, field: number): boolean {
        return true;
    }

    canEditField(category: string, field: number): boolean {
        return true;
    }

    private refreshUIAsync(fromId: string, toId?: string): void {
        let that = this;

        clearTimeout(this.forceUIRefreshTimer);
        this.forceUIRefreshTimer = window.setTimeout(function () {
            // refresh - but only if an item is still selected...
            if (that._itemId === fromId || (toId && that._itemId === toId)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.getItemAsync(that._itemId).done(function (result: IItem) {
                    that.updateItem(result);
                    plugins.updateItem(result);
                });
            }
        }, 1000);
    }
    // return null if there is no project trace_config setting (project before 1.5, not upgraded)
    // if not return an jsob object with all trace information for the item
    evaluateTraceRule(item: IItem, checkDownRule: boolean): ITraceRules {
        let tc = globalMatrix.ItemConfig.getTraceConfig();
        if (!tc) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return null;
        }

        let result: ITraceRules = {
            valid: true, // in case any must_have rule is not followed this is false
            mustHaveCategories: [], // all categories which are in a must have rule
            canHaveCategories: [], // all categories which are in a can have rule
            exstingCategories: [], // all categories which are curently linked to item
            missingMustHaveCategories: [], // all categories which must be linked but are not
            missingCanHaveCategories: [], // all categories which can be linked but are not
            outdatedReferences: [],
        };

        if (!item || !item.modDate) {
            // this can happen during item creation... no worries, we cannot create links
            return result;
        }

        let itemDate = new Date(item.modDate);
        // build a list of all existing up or downlink categories
        // and a list of all out dated references
        let existing = checkDownRule ? item.downLinks : item.upLinks;
        if (existing) {
            $.each(existing, function (idx, link) {
                // list of existing categories
                let cat = ml.Item.parseRef(link.to).type;
                if (result.exstingCategories.indexOf(cat) === -1) {
                    result.exstingCategories.push(cat);
                }
                // list of outdated links
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let refDate = new Date(link.modDate);
                if ((checkDownRule && itemDate > refDate) || (!checkDownRule && itemDate < refDate)) {
                    result.outdatedReferences.push(link.to);
                }
            });
        }

        // get the up/down rule from project setting
        let updown: ITraceConfigRule[];
        $.each(tc.rules, function (ruleIdx, rule) {
            if (rule.category === item.type) {
                updown = checkDownRule ? rule.down_rules : rule.up_rules;
            }
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!updown) {
            // no rules.. results are complete: no mustHaveCategories, no canHaveCategories, missingCategories
            return result;
        }

        $.each(updown, function (updownIdx: number, updownRule: ITraceConfigRule) {
            if (updownRule.rule === "must_have" && updownRule.any_of) {
                let hasMustHave = false;
                $.each(updownRule.any_of, function (anyIdx: number, anyOf: string) {
                    // add to must have
                    if (result.mustHaveCategories.indexOf(anyOf) === -1) {
                        result.mustHaveCategories.push(anyOf);
                    }
                    // check if rule is followed
                    if (result.exstingCategories.indexOf(anyOf) !== -1) {
                        hasMustHave = true;
                    }
                });
                if (!hasMustHave) {
                    // problem: a must have rules is not followed
                    // add categories to missing categoires
                    $.each(updownRule.any_of, function (anyIdx, any) {
                        if (result.missingMustHaveCategories.indexOf(any) === -1) {
                            result.missingMustHaveCategories.push(any);
                        }
                    });
                    result.valid = false;
                }
            }
            if (updownRule.rule === "can_have" && updownRule.any_of) {
                let hasCanHave = false;
                $.each(updownRule.any_of, function (anyIdx: number, anyOf: string) {
                    // add to can have
                    if (result.canHaveCategories.indexOf(anyOf) === -1) {
                        result.canHaveCategories.push(anyOf);
                    }
                    // check if rule is followed
                    if (result.exstingCategories.indexOf(anyOf) !== -1) {
                        hasCanHave = true;
                    }
                });
                if (!hasCanHave) {
                    // problem: a must have rules is not followed
                    // add categories to missing categoires
                    $.each(updownRule.any_of, function (anyIdx: number, anyOf: string) {
                        if (result.missingCanHaveCategories.indexOf(anyOf) === -1) {
                            result.missingCanHaveCategories.push(anyOf);
                        }
                    });
                }
            }
        });

        return result;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private commitChangeListRec(changeList: IReferenceChange[], todo: number): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();
        if (todo >= changeList.length) {
            // all done!
            res.resolve(null, todo);
        } else {
            if (changeList[todo].action === "removeLink") {
                this.removeDownLinkAsync(changeList[todo].fromId, changeList[todo].toId)
                    .done(function () {
                        that.commitChangeListRec(changeList, todo + 1)
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function (error, done) {
                                res.reject(error, done);
                            });
                    })
                    .fail(function () {
                        res.reject(
                            "Could not remove link from " + changeList[todo].fromId + " to " + changeList[todo].toId,
                            todo,
                        );
                    });
            } else if (changeList[todo].action === "addLink") {
                this.addDownLinkAsync(changeList[todo].fromId, changeList[todo].toId)
                    .done(function () {
                        that.commitChangeListRec(changeList, todo + 1)
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function (error, done) {
                                res.reject(error, done);
                            });
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        res.reject(
                            "Could not add link from " + changeList[todo].fromId + " to " + changeList[todo].toId,
                            todo,
                        );
                    });
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static getProjectIcon(customLogo: string, alternateValue = "") {
        if (customLogo && customLogo.indexOf("{") !== -1) {
            let rjs = ml.JSON.fromString(customLogo);
            if (rjs.status === "ok") {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return (<any>rjs).value.logoId ? (<any>rjs).value.logoId : alternateValue;
            }
        }
        return alternateValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public static getProjectLogo(customLogo: string) {
        let rjs = ml.JSON.fromString(customLogo);
        if (rjs.status === "ok") {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return (<any>rjs).value.fileId ? (<any>rjs).value.fileId : "";
        } else {
            return customLogo; // legacy
        }
    }

    analytics: Analytics = new Analytics();
    openAnalytics(): void {
        this.waitForMainTree(() => {
            this.analytics.open();
        });
    }
    closeAnalytics(): void {
        this.analytics.close();
    }

    setSaving(saving: boolean): void {
        this.saving = saving;
    }
}
