import {
    IDHFSectionOptions,
    IDHFSection,
    IDHFControlDefinition,
    DocumentSectionType,
    mDHF,
} from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import {
    IListOfFiguresOptions,
    ListOfFiguresDocFieldHandler,
} from "../../../common/businesslogic/FieldHandlers/Document/ListOfFiguresDocFieldHandler";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";

export { ListOfFigures };

interface IFigTabRef {
    fita: string;
}

class ListOfFigures extends BaseDHFSection<IListOfFiguresOptions> implements IDHFSection {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let controllerConfig = this.getConfig(ctrl);
        let includes: string[] = [];
        if (controllerConfig.figures) {
            includes.push("figures");
        }
        if (controllerConfig.tables) {
            includes.push("tables");
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.html("");
        ctrlParameter.fieldValue = "includes " + includes.join(" and ");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.plainText(
            ml.JSON.setOptions(ml.JSON.clone(ctrlParameter), {
                parameter: { readonly: true, rows: 1, allowResize: false },
            }),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let figures = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p1" ' +
                (controllerConfig.figures ? "checked" : "") +
                "> Include list of figures</label></div>",
        );
        let tables = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p2" ' +
                (controllerConfig.tables ? "checked" : "") +
                "> Include list of tables</label></div>",
        );

        custom.append(figures);
        custom.append(tables);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        changed = changed || controllerConfig.figures !== $(custom.find(".p1")[0]).prop("checked");
        changed = changed || controllerConfig.tables !== $(custom.find(".p2")[0]).prop("checked");

        controllerConfig.figures = $(custom.find(".p1")[0]).prop("checked");
        controllerConfig.tables = $(custom.find(".p2")[0]).prop("checked");

        this.fieldHandler.setDHFConfig(controllerConfig);

        this.renderControl(ctrl, ctrlParameter);
        return changed;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
