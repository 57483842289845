import { IPlugin, IProjectPageParam, plugins } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { app, IItem, matrixSession, restConnection, globalMatrix } from "../../../globals";
import { XRGetProject_ProjectAudit_TrimAuditList } from "../../../RestResult";

export { initialize };

// allow user to select a folder or item and touch it and all it's
class Touchdown implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private waitTimer: number;

    public isDefault = true;
    public count = 0;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {}
    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    supportsControl() {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMenu(ul: JQuery) {
        let that = this;

        let extras = globalMatrix.ItemConfig.getExtrasConfig();

        if (extras && (ml.JSON.isTrue(extras.deepTouch) || (extras.deepTouch === "admin" && matrixSession.isAdmin()))) {
            let tdm = $('<li class="dropdown-submenu"><a tabindex="-1" href="javascript:void(0)">Touch Down</a></li>');

            let tdms = $('<ul class="dropdown-menu dropdown-menu-sub" role="menu">');

            for (let idx = 0; idx < 5; idx++) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let td = $('<li><a href="javascript:void(0)">' + idx + " Level" + (idx == 1 ? "" : "s") + "</a></li>");
                td.data("level", idx + 1);
                td.click(function (event: JQueryEventObject) {
                    that.touch($(event.delegateTarget).data("level"));
                });
                tdms.append(td);
            }
            tdm.append(tdms);
            ul.append(tdm);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private touch(levels: number) {
        let that = this;

        restConnection.getProject("audit?startAt=0&maxResults=0").done(function (result) {
            that.count = (result as XRGetProject_ProjectAudit_TrimAuditList).totalResults;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.touchAsync(that._item.id, levels)
                .done(function () {
                    window.clearTimeout(that.waitTimer);
                    ml.UI.showSuccess("Done!");
                })
                .fail(function (error) {
                    if (error !== "gatewayTimeout") {
                        window.clearTimeout(that.waitTimer);
                    }
                });

            that.waitTimer = window.setTimeout(function () {
                that.showWait(1);
            }, 300);
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showWait(text: number) {
        let that = this;
        if (text < 3) {
            ml.UI.showSuccess(
                ml.UI.getSpinningWait(
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    "This can take a while." + (text == 1 ? "" : " Get a coffee.<br>Do not do other changes."),
                ).html(),
                -1,
            );
        }
        restConnection.getProject("audit?startAt=0&maxResults=0").done(function (result) {
            if ((result as XRGetProject_ProjectAudit_TrimAuditList).totalResults > that.count) {
                // log changed ... assume it's done
                ml.UI.showSuccess("Done!");
            } else {
                let next = text + 1;
                that.waitTimer = window.setTimeout(function () {
                    that.showWait(next);
                }, 1000);
            }
        });
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    plugins.register(new Touchdown());
}
