import { globalMatrix, IDataStorage } from "../globals";

export { ProjectStorageMobile };

class ProjectStorageMobile implements IDataStorage {
    private Project: string;
    constructor(project: string) {
        this.Project = globalMatrix.matrixBaseUrl + "_" + project + "_";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setItem(itemKey: string, itemVal: string) {
        localStorage.setItem(this.Project + itemKey, itemVal);
    }

    getItem(itemKey: string): string {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return localStorage.getItem(this.Project + itemKey);
    }
    getItemDefault(itemKey: string, defaultValue: string): string {
        let v = localStorage.getItem(this.Project + itemKey);
        return v ? v : defaultValue;
    }
}
