import "./BaseControl";
import "./cascadingSelect";
import "./checkBox";
import "./colorPicker";
import "./dateselect";
import "./docBase."; // TODO(modules): fix the name of docBase, that is just silly.
import "./docFilter";
import "./docReview";
import "./docSign";
import "./dropdown";
import "./errorControl";
import "./fileManager";
import "./gateControl";
import "./hidden";
import "./htmlform";
import "./hyperlink";
import "./itemSelection";
import "./itemSelectionFromTo";
import "./labelsControl";
import "./linkCollection";
import "./markAsTemplate";
import "./oldTableCtrl";
import "./plainText";
import { initialize as InitializePrintProjectUIMods } from "./PrintProjectUIMods";
import "./publishedContent";
import "./richText";
import { initialize as InitializeRichText2 } from "./richText2";
import "./riskCtrl2";
import "./section";
import "./sourceRef";
import "./syncSourceInfo";
import { initialize as InitializeSyncStatus } from "./syncStatus";
import "./tableCtrl";
import "./tasksControl";
import "./titleToolbar";
import "./uplinkinfo";
import { initialize as InitializeWorkflowControl } from "./workflowControl";

export { InitializeControls };

// Function exists to ensure initializations in the imported modules above
// are made.
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeControls() {
    InitializeSyncStatus();
    InitializePrintProjectUIMods();
    InitializeRichText2();
    InitializeWorkflowControl();
}
