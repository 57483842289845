import { IDHFSectionOptions, IDHFControlDefinition, DocumentSectionType, mDHF } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IReference } from "../../../../globals";
import { ISingleDefaultControllerConfig, SingleSelectBase } from "../../../../SingleSelectBase";
import {
    IItemTableOptions,
    ItemTableDocFieldHandler,
} from "../../../../common/businesslogic/FieldHandlers/Document/ItemTableDocFieldHandler";

export { ItemTable };

class ItemTable extends SingleSelectBase<IItemTableOptions> {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.itemSelection(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefix: "Include a table of items with selected fields",
                    buttonName: "Select Items",
                    showOnly: [],
                    showNot: ["REPORT"],
                },
            }),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let that = this;

        let controllerConfig = this.getConfig(ctrl);

        let sort = $(
            '<div class="checkbox" ><label><input type="checkbox" class="sortedList" ' +
                (controllerConfig.sortedList ? "checked" : "") +
                "> Sort items by ID</label></div>",
        );
        custom.append(sort);

        let includeFolders = $(
            '<div class="checkbox" ><label><input type="checkbox" class="includeFolders" ' +
                (controllerConfig.includeFolders ? "checked" : "") +
                "> Include folders</label></div>",
        );
        custom.append(includeFolders);

        this.eitherCB(sort, includeFolders);

        sort.click(function () {
            that.eitherCB(sort, includeFolders);
        });
        includeFolders.click(function () {
            that.eitherCB(sort, includeFolders);
        });
        let options =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included items to table of content</label></div>";
        custom.append($(options));

        let columns = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_columns" type="text" name="columns" value="" /> ',
        );
        let sp = $(
            "<div class='sectionConfOption' style='display:inline-block'>Columns 'col1,col2,col3' with colx=field ids or labels, 'item', 'itemid', 'title', 'labels', 'up' or 'down': </div>",
        ).append(columns);
        columns.val(controllerConfig.columns);
        custom.append($(sp));

        this.addSpecificSettings(controllerConfig, custom);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);
        // read values from UI

        let changed = false;

        changed = changed || controllerConfig.sortedList !== $(custom.find(".sortedList")[0]).prop("checked");
        controllerConfig.sortedList = $(custom.find(".sortedList")[0]).prop("checked");

        changed = changed || controllerConfig.includeFolders !== $(custom.find(".includeFolders")[0]).prop("checked");
        controllerConfig.includeFolders = $(custom.find(".includeFolders")[0]).prop("checked");

        changed = changed || controllerConfig.includeInToc !== $(custom.find(".includeInToc")[0]).prop("checked");
        controllerConfig.includeInToc = $(custom.find(".includeInToc")[0]).prop("checked");

        changed = changed || controllerConfig.columns !== this.removeSpaces($(custom.find(".p_columns")[0]).val());
        controllerConfig.columns = this.removeSpaces($(custom.find(".p_columns")[0]).val());

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private eitherCB(cbr1: JQuery, cbr2: JQuery) {
        let cb1 = $("input", cbr1);
        let cb2 = $("input", cbr2);

        cb1.prop("disabled", cb2.prop("checked"));
        cb2.prop("disabled", cb1.prop("checked"));
    }
}
