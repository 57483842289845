import { ItemConfiguration } from "./ItemConfiguration";
import { globalMatrix, IItemGet } from "../../globals";

export class ItemHelpers {
    /**
     * Check if an item is locked
     * @param item
     * @param itemConfig, default is globalMatrix.ItemConfig
     * @returns true if the item has a locked label
     * @throws error if item is undefined or item id is undefined
     */
    static isItemLocked(item: IItemGet, itemConfig: ItemConfiguration): boolean {
        if (item.id === undefined) {
            throw new Error("Item id is undefined");
        }
        if (item.labels === undefined) {
            return false;
        }
        //Get category
        let cat = item.id.split("-")[0];
        let lockConfig = itemConfig.getLabelLockConfig() || { locks: [] };
        let labelConfig = itemConfig.getLabelsConfig();
        if (labelConfig === undefined || labelConfig.labels === undefined) {
            return false;
        }
        let labelsForCat = labelConfig.labels.filter((l) => l.categories.includes(cat)).map((o) => o.label);

        for (let lock of lockConfig.locks) {
            if (item.labels.indexOf(lock.label) !== -1 && labelsForCat.indexOf(lock.label) !== -1) {
                // locking label is set...
                return true;
            }
        }

        return false;
    }
}
