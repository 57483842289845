import { MatrixSession } from "./common/businesslogic/index";
import { JsonValidator } from "../jsonvalidation/JsonValidator";
import { ItemConfiguration } from "./common/businesslogic/index";
import { Application } from "./common/UI/Application";
import { RestConnector } from "./common/businesslogic/index";
import { XRCrossProjectLink, XRTrimLink, XRCategoryAndRoot } from "./RestResult";

import { IApp } from "./common/matrixlib/MatrixLibInterfaces";

export type {
    Admin,
    MatrixReq,
    Error,
    IControlDefinition,
    IGenericMap,
    IAnyMap,
    IStringMap,
    IItemGetMap,
    INumberStringMap,
    IStringNumberMap,
    IBooleanMap,
    IStringStringArrayMap,
    IStringJQueryMap,
    IJsonSetting,
    IRestParam,
    IRestResult,
};
export type {
    IReference,
    IItemIdParts,
    IReferenceChange,
    IEmail,
    IDevice,
    ISize,
    IItem,
    ILink,
    IItemGet,
    IItemPut,
    IItemHistory,
    IDataStorage,
};
export { ControlState, globalMatrix };
export { matrixSession, restConnection, app, matrixApplicationUI };
export { setIC, setMatrixSession, setRestConnection, setMatrixApplicationUI, setApp };
export { InstallLegacyAdaptor };

// TODO(modules) why are these 2 interfaces here? To solve some type error elsewhere?
interface Admin {}
interface MatrixReq {}

interface Error {
    name: string;
    message: string;
}
interface IControlDefinition {
    name?: string;
    control?: JQuery;
    fieldId?: number;
    isDhfType?: boolean;

    /**
     * fieldType is set if this control supports displaying a particular kind of field.
     * See FieldDescriptions for a list of many of the field types.
     */
    fieldType?: string;
}

// helper to allow things like x={}; x[y] = 1
interface IGenericMap {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: any;
}
interface IAnyMap {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: any;
}
interface IStringMap {
    [key: string]: string;
}
interface IItemGetMap {
    [key: string]: IItemGet;
}
interface INumberStringMap {
    [key: number]: string;
}
interface IStringNumberMap {
    [key: string]: number;
}
interface IBooleanMap {
    [key: string]: boolean;
}
interface IStringStringArrayMap {
    [key: string]: string[];
}
interface IStringJQueryMap {
    [key: string]: JQuery;
}

interface IJsonSetting {
    id: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    value: any;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
interface IRestParam extends Object {
    td?: number;
    reason?: string;
    filter?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: any;
}
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
type IRestResult = {} | string;

export class GlobalMatrix {
    // these should go once everything is converted...
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    verbose: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    ItemConfig: ItemConfiguration;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    PopulateProjects: Function; // exists only in admin client

    mobileApp: {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        Login: Function;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        ShowLoginScreen: Function;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        ShowMobileUI: Function;
    }; // exists only in mobile app
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    jiraPlugin: any; // jira cloud plugin

    // declare var EmbeddedReport:any;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    Admin: any;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    doc: any; // no idea used in ContextFrames..

    ITEM_DOES_NOT_EXIST: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    matrixRestUrl: string; // delivered by server
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    matrixBaseUrl: string; // delivered by server
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    matrixWfgw: string; // delivered by server
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    matrixExpress: boolean; // delivered by server
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    matrixProduct: string; // delivered by server
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    mxOauth: string; // delivered by server
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    mxOauthLoginUrl: string; // delivered by server

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    serverStorage: IDataStorage; // keeps information in local client storage per server

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    projectStorage: IDataStorage; // keeps information in local client storage per project

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    wfgwConnection: RestConnector; // global plugin rest connection

    globalShiftDown: boolean;
    globalCtrlDown: boolean;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    jsonValidator: JsonValidator;

    // filter for activity stream / calendar
    historyFilter;
    // legacy,js
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    EmbeddedReport: any;

    constructor() {
        this.ITEM_DOES_NOT_EXIST = "";
        this.globalShiftDown = false;
        this.globalCtrlDown = false;
        this.historyFilter = "merge,delete,undelete,add,edit,execute,reviewed,signature,report,touch,release";
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.mobileApp = undefined;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    installLegacyAdaptor() {
        // @ts-ignore have to live with it for now
        globalThis.ITEM_DOES_NOT_EXIST = this.ITEM_DOES_NOT_EXIST;
        // @ts-ignore have to live with it for now
        globalThis.matrixRestUrl = this.matrixRestUrl;
        // @ts-ignore have to live with it for now
        globalThis.matrixBaseUrl = this.matrixBaseUrl;
        // @ts-ignore have to live with it for now
        globalThis.matrixWfgw = this.matrixWfgw;
        // @ts-ignore have to live with it for now
        globalThis.matrixExpress = this.matrixExpress;
        // @ts-ignore have to live with it for now
        globalThis.matrixProduct = this.matrixProduct;
        // @ts-ignore have to live with it for now
        globalThis.mxOauth = this.mxOauth;
        // @ts-ignore have to live with it for now
        globalThis.mxOauthLoginUrl = this.mxOauthLoginUrl;

        // @ts-ignore have to live with it for now
        globalThis.serverStorage = this.serverStorage;
        // @ts-ignore have to live with it for now
        globalThis.projectStorage = this.projectStorage;
        // @ts-ignore have to live with it for now
        globalThis.wfgwConnection = this.wfgwConnection;

        // @ts-ignore have to live with it for now
        globalThis.globalShiftDown = this.globalShiftDown;
        // @ts-ignore have to live with it for now
        globalThis.globalCtrlDown = this.globalCtrlDown;

        // @ts-ignore have to live with it for now
        globalThis.jsonValidator = this.jsonValidator;
    }
}

const globalMatrix: GlobalMatrix = new GlobalMatrix();

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function setIC(newIC: ItemConfiguration) {
    globalMatrix.ItemConfig = newIC; // To use in matrixSdk
    // @ts-ignore have to live with it for now
    globalThis.IC = newIC; // legacy support
}
let matrixSession: MatrixSession;
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function setMatrixSession(session: MatrixSession) {
    matrixSession = session;
}
let restConnection: RestConnector; // global rest connection
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function setRestConnection(rc: RestConnector) {
    restConnection = rc;
}
let matrixApplicationUI: Application; // the application
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function setMatrixApplicationUI(ui: Application) {
    matrixApplicationUI = ui;
}

// TODO(modules): app was declared as interface MatrixReq, which is empty, and then usually
// assigned to a variable of type businesslogic/UI/MatrixReq. We just opt out of typing for
// this variable and ideally we should fix that with a real interface. I changed the type to
// any to make the true situation easier to see.
let app: IApp; // main application
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function setApp(newApp: any) {
    app = newApp;
}

// type JQuery = any;

// global interfaces by matrix
interface IReference {
    projectShortLabel?: string;
    to: string;
    title: string;
    modDate?: string;
    isIndirect?: boolean;
}
interface IItemIdParts {
    id: string;
    version: number;
    type: string;
    isFolder: boolean;
    url: string;
    link: string;
    linkv: string;
    number: number;
    originTag: string;
}
interface IReferenceChange {
    action: string;
    fromId: string;
    toId: string;
}

interface IEmail {
    summary: string;
    description: string;
    matrixProject: string;
    matrixItem: string;
    browser: string;
    log: string;
    email?: string;
}
interface IDevice {
    screen: ISize;
    viewport: ISize;
}
interface ISize {
    width: number;
    height: number;
}

// TODO: type properly
interface IItem {
    upLinks?: IReference[];
    upLinkList?: XRTrimLink[];
    downLinks?: IReference[];
    children?: IItem[];
    history?: IItemHistory[];
    modDate?: string;
    isUnselected?: number;
    availableFormats?: string[];
    selectSubTree?: XRCategoryAndRoot[];
    requireSubTree?: XRCategoryAndRoot[];
    icon?: string;
    type?: string;
    id?: string;
    title?: string;
    linksUp?: string;
    linksDown?: string;
    isFolder?: boolean;
    isDeleted?: boolean;
    maxVersion?: number;
    docHasPackage?: boolean;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: any;
}

// a link from one item to another item
interface ILink {
    from: string;
    to: string;
}

interface IItemGet extends IItem {
    labels?: string[];
    crossLinks?: XRCrossProjectLink[];
}
interface IItemPut extends IItem {
    labels?: string;
    onlyThoseFields?: number;
    onlyThoseLabels?: number;
}

interface IItemHistory {
    action: string;
    user: string;
    dateUserFormat: string;
    comment: string;
    id: string;
    version: number;
    date: string;
    title: string;
    deletedate?: string;
}

interface IDataStorage {
    setItem: (itemKey: string, itemVal: string, sanitize?: boolean) => void;
    getItem: (itemKey: string, dontSanitize?: boolean) => string;
    getItemDefault: (itemKey: string, defaultValue: string) => string;
}

enum ControlState {
    FormEdit = 0, //this is a embedded form which allows the user to modify the content
    FormView, //this is read only version with (some) read only tools enabled (e.g. history)
    DialogCreate, //this allows the user to modify the content, usually to create new elements. No tools
    HistoryView, //is a read only version, e.g. used for the history where smart text and smart link is not resolved
    Tooltip, // most things will not shown as tooltip...
    Print, // for printing ...
    Report, // special to render report into page
    DialogEdit, // between FormEdit and DialogCreate to edit an item in popup
    Review, // special mode for design reviews
    Zen, // zen mode
}

// This is so that old legacy scripts can "just work" to a degree.
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InstallLegacyAdaptor() {
    // @ts-ignore have to live with it for now
    globalThis.ControlState = ControlState;
    // @ts-ignore have to live with it for now
    globalThis.IC = globalMatrix.ItemConfig;
    // @ts-ignore have to live with it for now
    globalThis.matrixSession = matrixSession;
    // @ts-ignore have to live with it for now
    globalThis.restConnection = restConnection;
    // @ts-ignore have to live with it for now
    globalThis.app = app;
    // @ts-ignore have to live with it for now
    globalThis.matrixApplicationUI = matrixApplicationUI;
    globalMatrix.installLegacyAdaptor();
    // @ts-ignore have to live with it for now
    globalThis.applyResponsiveView = applyResponsiveView;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function applyResponsiveView() {
    let mobileView = localStorage.getItem("mobileLayout");
    let x = $(window).width();
    if (mobileView) {
        $("#contextframesizer").css("display", "none");
        $("#dragbar").css("display", "none");
        $("#sidebar").css("max-width", "none");
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    if (mobileView == "0") {
        // see item
        $("#sidebar").css("display", "none");
        $("#navLeft").css("display", "none");
        $("#main").css("display", "block");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
    } else if (mobileView == "1") {
        // see navigation tree
        $("#sidebar").css("width", x).css("display", "block");
        $("#main").css("display", "none");
        $("#navLeft").css("display", "");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
    } else if (mobileView == "2") {
        // see both
        $("#sidebar").css("width", x / 2 + "px");
        $("#navLeft").css("display", "");
        $("#main").css("display", "block");
    } else {
        // not mobile
        $("#dragbar").css("display", "block");
        $("#contextframesizer").css("display", "block");
        $("#navLeft").css("display", "");
        $("#main").css("display", "block");
    }

    app.resizeItem(true);
}
