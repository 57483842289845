import { ItemSelectionFieldHandler } from "../ItemSelectionFieldHandler";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap } from "../../../../globals";
import { XRFieldTypeAnnotatedParamJson } from "../../ItemConfiguration";

import { IDHFSectionOptions } from "../../PluginManagerDocuments";

export type { IItemsOptions, IItemsFromConfig };

interface IItemsOptions extends IDHFSectionOptions {
    folderDetails: string;
    extracolumn: string;
    showlinks: boolean;
    showUpOnly: boolean;
    showDownOnly: boolean;
    showDeeplinks: boolean;
    breadcrumb: boolean;
    showExternal: number;
    dateoptions: string;
    refdocs: boolean;
    hideLinkErrors: boolean;
    hideLabels: boolean;
    hideEmptyFolders: boolean;
    showAllParentFolders: boolean;
    includeInToc: boolean;
    search?: string;
    breakAfterItems: boolean;
}

interface IItemsFromConfig {
    default: IItemsOptions;
}

export abstract class ItemSelectionAbstractDocFieldHandler
    extends ItemSelectionFieldHandler
    implements IDocFieldHandler
{
    dhfFieldConfig: IAnyMap;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    abstract getDefaultConfig(): any;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }
    constructor(sectionType: string, config: XRFieldTypeAnnotatedParamJson, fieldValue: string | undefined) {
        super(config, sectionType);
        if (!config) {
            config = this.getDefaultConfig();
        }
        this.dhfFieldConfig = config;

        this.initData(fieldValue);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    abstract getXmlValue(): string;
}
