import { sanitize } from "isomorphic-dompurify";
import { IDocFieldHandler } from "./Document/IDocFieldHandler";
import { GenericFieldHandler } from "./GenericFieldHandler";
import { globalMatrix, IControlDefinition } from "../../../globals";
import { FieldDescriptions } from "../FieldDescriptions";
import { GenericDocFieldHandler } from "./Document/GenericDocFieldHandler";
import { SectionDescriptions } from "./Document/SectionDescriptions";
import { DocFieldHandlerFactory } from "./Document";
import { IDHFControlDefinitionValue, IDHFSectionOptions, IDHFXMLOptions } from "../DHFInterfaces";
import { ItemConfiguration } from "../ItemConfiguration";
import { ITableRow } from "../../UI/Controls/tableCtrl";
import { extend } from "../../matrixlib/JQueryExtendReplacement";

interface IDHFControlDefinition extends IControlDefinition {
    dhfValue?: IDHFControlDefinitionValue;
    configTouched?: boolean;
}

/**
 * The FieldHandler for all DOC fields.
 */
export class DHFFieldHandler extends GenericFieldHandler {
    innerDataHandler: IDocFieldHandler;

    constructor(
        private itemConfig: ItemConfiguration,
        private fieldConfig: IDHFControlDefinition,
    ) {
        super(FieldDescriptions.Field_dhf, fieldConfig);
        this.innerDataHandler = new GenericDocFieldHandler(SectionDescriptions.section_hidden, {}, "");
    }
    getData(): string | undefined {
        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.innerDataHandler == undefined ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.innerDataHandler.getFieldType() == SectionDescriptions.section_hidden
        ) {
            return undefined;
        }
        let dhfValue: Record<string, string> = {
            type: this.innerDataHandler.getFieldType(),
            name: this.innerDataHandler.getFieldName(),
            fieldValueXML: "",
            fieldValue: "",
        };

        // create the controller
        let fieldHandler = this.innerDataHandler;

        // udpate the controller values
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        dhfValue.fieldValue = fieldHandler.getData();
        // define global options
        let controllerConfig = extend(fieldHandler.dhfFieldConfig, fieldHandler.getDefaultConfig());

        let global: IDHFSectionOptions = {
            globalOptions: true,
            page_break: controllerConfig["page_break"],
            sub_section: controllerConfig["sub_section"],
            landscape: controllerConfig["landscape"],
            show_section_title: controllerConfig["show_section_title"],
            automation: controllerConfig["automation"],
        };

        // get specific options of control and add global as a row
        let options: IDHFXMLOptions[] = JSON.parse(fieldHandler.getXmlValue());
        options.push(global);

        // update the xml dump
        dhfValue.fieldValueXML = JSON.stringify(options);

        dhfValue["ctrlConfig"] = controllerConfig;
        dhfValue.name = sanitize(dhfValue.name) + "";
        dhfValue.name = dhfValue.name.replace(/&lt;/g, "<");

        return JSON.stringify(dhfValue);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(fieldValue: string | undefined) {
        if (fieldValue && fieldValue.length > 0 && this.itemConfig) {
            try {
                this.fieldConfig.dhfValue = <IDHFControlDefinitionValue>JSON.parse(fieldValue);
            } catch (e) {
                console.error("Error parsing dhfValue. Defaulting to empty", e);
                this.fieldConfig.dhfValue = {};
            }

            this.fieldConfig.dhfValue.ctrlConfig = DocFieldHandlerFactory.GetDHFFieldConfig(
                this.itemConfig,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.fieldConfig.dhfValue.type,
                this.fieldConfig.dhfValue.ctrlConfig,
            );
            this.setInnerFieldHandler(DocFieldHandlerFactory.createHandler(this.itemConfig, this.fieldConfig.dhfValue));
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setInnerFieldHandler(docFieldHandler: IDocFieldHandler) {
        this.innerDataHandler = docFieldHandler;
    }
}
