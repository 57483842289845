/// <reference types="matrixrequirements-type-declarations" />

export type { SchemaDef, JsonEditorValidation };
export { JsonValidator };

interface SchemaDef {
    module: string;
    type: string;
    serverUse: string;
    serverProp: string;
}

type JsonEditorValidation = (json: unknown) => Promise<string | null>;

/**
 * Globally available JSON Validator. It can validate against schemas stored in web/schemas/*.json
 * The schemas in this directory are generated by the `gulp schema` command and defined in schemagen/schemas.json
 * To add validation for a new type:
 * * Add to schemas.json
 * * Run gulp schema (or gulp build)
 * * Call jsonValidator.validateType( yourObject, "IYourInterface")
 *
 * There are some utility functions that can wrap a type in a validator closure or give you
 * access to the raw object.
 */
class JsonValidator {
    private schemas: SchemaDef[] = [];
    private validators: { [key: string]: Ajv.ValidateFunction } = {};
    private version: string;
    private ajv = new Ajv({
        allErrors: true,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        loadSchema: (uri) => {
            console.log("LOAD SCHEMA", uri);
            const versionedUrl = this.patchVersionIntoUrl(`${this.baseUrl}/schemas/${uri}`);
            return fetch(versionedUrl).then((response) => response.json());
        },
    });

    /**
     * This is a global object, you should not have to create it. See `jsonValidator`
     */
    constructor(
        private baseUrl: string,
        version: string,
    ) {
        this.version = version;
        const versionedUrl = this.patchVersionIntoUrl(`${this.baseUrl}/schemas/schemas.json`);
        fetch(versionedUrl).then((result) => {
            if (result.ok) {
                result.json().then((data) => {
                    this.schemas = data.schemalist ?? [];
                });
            }
        });
    }

    /**
     * Create a display string from an error list
     * @param errors
     */
    errorString(errors: Ajv.ErrorObject[]): string | null {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (errors == null) {
            return null;
        }
        const errorString = errors.map((error: Ajv.ErrorObject) => {
            return `${error.dataPath} ${error.message}`;
        });
        return errorString.join("\n");
    }

    /**
     * Create a validation function for the given type that is used in some of the Matrix editors
     * @param type
     */
    validationFunction(type: string): JsonEditorValidation {
        return async (json: unknown) => {
            try {
                const result = await this.validateType(json, type);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (result != null) {
                    return this.errorString(result);
                } else {
                    return null;
                }
            } catch (e) {
                console.error("Failed to validate json: ", e);
                return "Validation error";
            }
        };
    }

    /**
     * Validate the given data against the given schema.
     * @param data
     * @param type
     * @return null if valid, or an array of errors if not
     */
    async validateType(data: unknown, type: string): Promise<Ajv.ErrorObject[] | null> {
        const validator = await this.validatorByType(type);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (validator != null) {
            const valid = validator(data);
            if (!valid) {
                console.error(validator.errors);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (validator.errors != null) {
                    return validator.errors;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Get the validator function for the given type
     * @param type
     */
    async validatorByType(type: string): Promise<Ajv.ValidateFunction | null> {
        const schema = await this.schemaByType(type);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (schema != null) {
            return this.ajv.compileAsync(schema);
        } else {
            return null;
        }
    }

    urlRegex = /^\/schemas\/(\S+)\.json$/;
    patchVersionIntoUrl(url: string): string {
        const results = this.urlRegex.exec(url);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (results && results.length == 2) {
            const schema = results[1];
            return `${this.baseUrl}/schemas/${schema}${this.version}.json`;
        } else {
            return url;
        }
    }

    /**
     * Get the schema object for the given type
     * @param type
     * @throws Exception if the schema is not found
     */
    async schemaByType(type: string): Promise<object | null> {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.validators[type] == null) {
            const name = this.schemas.filter((schema) => schema.type === type)[0];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (name == null) {
                console.log(`There is no schema for type ${type}`);
                throw `Unable to find a schema for ${type}`;
            }
            const versionedUrl = this.patchVersionIntoUrl(`${this.baseUrl}/schemas/${type}.json`);
            this.validators[type] = await fetch(versionedUrl).then((response) => response.json());
        }
        return this.validators[type];
    }

    /**
     * Get the schema UI element. This can be embedded in other DOM elements
     * @param type The name of the type - this should exist in the schemas dir
     * @throws Exception if the schema is not found
     */
    async schemaView(type: string): Promise<MatrixSchemaView | null> {
        const schema = await this.schemaByType(type);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (schema != null) {
            const resolved = await JsonRefs.resolveRefs(schema, {});
            return new MatrixSchemaView(resolved.resolved as ISchema);
        } else {
            return null;
        }
    }
}

interface ISchema extends ISchemaObject {}

type ISchemaPropertyMap = { [key: string]: ISchemaItem };

interface ISchemaItem {
    description?: string;
    type?: ESchemaType;
    anyOf?: ISchemaItem[];
}

interface ISchemaObject extends ISchemaItem {
    properties?: ISchemaPropertyMap;
    additionalProperties?: ISchemaItem;
    required?: string[];
}

interface ISchemaArray extends ISchemaItem {
    items?: ISchemaItem;
}

enum ESchemaType {
    string = "string",
    array = "array",
    object = "object",
    number = "number",
    boolean = "boolean",
}

interface ISchemaPrintTypeInfo {
    help?: string;
    type?: string;
    subItems?: string;
    subItemStart?: string;
    subItemEnd?: string;
}

class MatrixSchemaView {
    schema: ISchema;

    constructor(schema: ISchema) {
        console.log("SCHEMA", schema);
        this.schema = schema;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    render() {
        const html = this.renderObject(this.schema);
        const container = document.createElement("div");
        container.id = "jsonSchema";
        container.setAttribute("style", "overflow-y:scroll;");
        container.innerHTML = html;
        return container;
    }

    renderObject(object: ISchemaObject): string {
        let display = `<div class="json-schema-container"><div class="rowpadding">&nbsp;</div><div>`;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (object.additionalProperties != null) {
            const { type, subItemStart, subItems, subItemEnd } = this.renderProperty(object.additionalProperties);
            display += `<div class="item"><span class="basetype">[key:string]</span>: `;
            if (type) {
                display += `<span class="basetype">${type}</span>`;
            } else {
                display += `<span class="subitemstart">${subItemStart}</span>`;
            }
            display += "</div>";
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (subItems != null) {
                display += `<div class="subitem">${subItems}</div>`;
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (subItemEnd != null) {
                display += `<div class="subitemend">${subItemEnd}</div>`;
            }
        }
        if (object.properties) {
            const props = object.properties;
            const propList = Object.keys(props).reduce((accum, current) => {
                const prop = props[current];
                if (prop.description) {
                    accum += `<div class="schemadescription">${prop.description}</div>`;
                }
                const { type, subItemStart, subItems, subItemEnd } = this.renderProperty(prop);
                accum += `<div class="proprow"><span class="proptitle">${current}</span>`;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (object.required != null && object.required.indexOf(current) != -1) {
                    accum += `<span class="proprequired">*</span>`;
                }
                accum += ": ";
                if (type) {
                    accum += `<span class="basetype">${type}</span>`;
                } else if (subItemStart) {
                    accum += `<span class="subitemstart">${subItemStart}</span>`;
                } else {
                    accum += "<b>error in type</b>";
                }
                accum += "</div>";
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subItems != null) {
                    accum += `<div class="subitem">${subItems}</div>`;
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subItemEnd != null) {
                    accum += `<div class="subitemend">${subItemEnd}</div>`;
                }
                return accum;
            }, "");
            display += `<div class="proplist">${propList}</div>`;
        }
        return display + "</div></div>";
    }

    renderArray(array: ISchemaArray): string {
        let display = `<div style="display:flex;flex-direction:row;"><div>&nbsp;</div><div>`;
        if (array.items) {
            //TODO:anyOf/allOf support?
            const { type, subItemStart, subItems, subItemEnd } = this.renderProperty(array.items);
            if (type) {
                display += `<span class="basetype">${type}</span>`;
            } else {
                display += `<div><span class="item">${subItemStart}</span></div>`;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subItems != null) {
                    display += `<div class="subitem">${subItems}</div>`;
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (subItemEnd != null) {
                    display += `<div class="subitemend">${subItemEnd}</div>`;
                }
            }
        }
        return display + "</div></div>";
    }

    renderProperty(prop: ISchemaItem): ISchemaPrintTypeInfo {
        if (prop.anyOf) {
            const result: string[] = [];
            prop.anyOf.forEach((p: ISchemaItem) => {
                const { type } = this.renderProperty(p);
                if (type) {
                    result.push(type);
                } else {
                    console.error("Error trying to render alternate type - subtypes too complex.", p);
                }
            });
            return { type: result.join("|") };
        } else if (prop.type) {
            switch (prop.type) {
                case ESchemaType.string:
                    return { type: "string" };
                case ESchemaType.boolean:
                    return { type: "boolean" };
                case ESchemaType.number:
                    return { type: "number" };
                case ESchemaType.array:
                    return { subItemStart: "[", subItems: this.renderArray(prop as ISchemaArray), subItemEnd: "]" };
                case ESchemaType.object: {
                    const schemaObject = prop as ISchemaObject;
                    if (schemaObject.properties || schemaObject.additionalProperties) {
                        return {
                            subItemStart: "{",
                            subItems: this.renderObject(prop as ISchemaObject),
                            subItemEnd: "}",
                        };
                    } else {
                        return { type: "object" };
                    }
                }
                default:
                    console.error("Unsupported type ", prop.type);
                    return { type: "unknown type" };
            }
        } else {
            return { type: "<i>any type</i>" };
        }
    }
}
