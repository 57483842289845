import { initialize as InitializeNavigationBar } from "./NavigationBar";

export { InitializeComponents };

// Function exists to ensure initializations in the imported modules above
// are made.
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeComponents() {
    InitializeNavigationBar();
}
