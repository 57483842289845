import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { globalMatrix, app, ControlState, matrixSession, restConnection } from "../../globals";
import {
    ICaption,
    ISmartTextConfigReplacement,
    IDropdownOption,
    ISmartTextConfig,
    smartTextConfigSetting,
} from "../../ProjectSettings";
import { XRGetProject_ProjectInfo_ProjectInfo } from "../../RestResult";

import { ml } from "./../matrixlib";
import { ISmartTextTools, ICaptionFormat } from "./MatrixLibInterfaces";
import { FieldDescriptions } from "../businesslogic/FieldDescriptions";
import { DATA_HTMLDIFF_ID } from "../../../sdk/utils/differs/diffHtml";

export { SmartTextTools };

class SmartTextTools implements ISmartTextTools {
    private figureDef: ICaptionFormat = {
        preNo: "Figure ",
        postNo: ": ",
        captionDetails: "captionDetailsFig",
        captionClass: "captionFig",
        referenceClassNo: "referenceFig",
    };
    private tableDef: ICaptionFormat = {
        preNo: "Table ",
        postNo: ": ",
        captionDetails: "captionDetailsTab",
        captionClass: "captionTab",
        referenceClassNo: "referenceTab",
    };

    //****************************************
    // LEGACY summernote functions
    //***************************************

    /* summernote create menu to enter edit macros
     * docMode is true if editor/menu is a document -> in that case we support table and figure captions
     * tableMode is true if editor/menu is for a table cell  -> in that case we do not support smart text
     */
    createMenu(docMode: boolean, tableMode: boolean): void {
        let hasTags = false;
        let list = "";
        let config = this.getCurrentConfig();
        let dhf_config = globalMatrix.ItemConfig.getDHFConfig();

        // (set (default texts)
        if (dhf_config && dhf_config.captions) {
            if (dhf_config.captions.figure) {
                this.figureDef.preNo = dhf_config.captions.figure.preNo
                    ? dhf_config.captions.figure.preNo
                    : this.figureDef.preNo;
                this.figureDef.postNo = dhf_config.captions.figure.postNo
                    ? dhf_config.captions.figure.postNo
                    : this.figureDef.postNo;
            }
            if (dhf_config.captions.table) {
                this.tableDef.preNo = dhf_config.captions.table.preNo
                    ? dhf_config.captions.table.preNo
                    : this.tableDef.preNo;
                this.tableDef.postNo = dhf_config.captions.table.postNo
                    ? dhf_config.captions.table.postNo
                    : this.tableDef.postNo;
            }
        }
        let isInDialog = $(".smarttaglist").closest(".dialog-body").length > 0;

        // group them by tag type
        let groups: ISmartTextConfigReplacement[][] = [];
        let gc = 0;
        $.each(config.replacements, function (optIdx, opt) {
            let tt = opt.tagType ? opt.tagType : opt.plain ? 1 : 2;
            if (!groups[tt]) {
                gc++;
                groups[tt] = [];
            }
            groups[tt].push(opt);
        });
        // insert smart references
        list +=
            '<li class="selectSmartTextGroup dropdown-submenu"><a tabindex="-1" href="javascript:void(0)">' +
            'Insert References to</a><ul style="min-width:150px;" class="dropdown-menu">';

        list +=
            '<li class="">' +
            '<a class="selectSmartText smarttextblock" data-event="selectSmartLink" href="#" ' +
            '" data-toggle="tooltip"><span style="margin:0 80px 0 0">Item(s) in this project</span></a>' +
            "</li>";
        list +=
            '<li class="">' +
            '<a class="selectSmartText smarttextblock" data-event="selectCrossLink" href="#" ' +
            '" data-toggle="tooltip"><span style="margin:0 80px 0 0">Item(s) in another project</span></a>' +
            "</li>";

        list += "</ul></li>";

        // insert smart text blocks
        for (let gidx = 0; !tableMode && gidx < groups.length; gidx++) {
            let group = groups[gidx];
            if (group) {
                list += `
                <li class="selectSmartTextGroup dropdown-submenu">
                    <a tabindex="-1" href="javascript:void(0)">Insert ${this.getTooltipType(group[0])}</a>
                    <ul style="min-width:150px;" class="stsm dropdown-menu">
                        <li class="filterListList"><input autocomplete="off" placeholder="filter list" class="filterList" type="text"></li>`;

                $.each(group, function (optIdx, opt) {
                    list += `
                    <li class="">
                        <a class="selectSmartText smarttextblock" data-event="selectSmartText" href="#" name="${opt.what}" data-value="${opt.what}" data-toggle="tooltip">
                           <span style="margin:0 80px 0 0">${opt.what}</span>
                           <i class="deleteSmartText fal fa-trash-alt"></i>
                           <i class="editSmartText fal fa-edit"></i>
                        </a>
                    </li>`;

                    hasTags = true;
                });

                list += "</ul></li>";
            }
        }

        // insert figures / tables
        if (!tableMode && dhf_config && dhf_config.captions) {
            if ($("#itemDetails ." + this.figureDef.captionDetails).length > 0) {
                list +=
                    '<li class="selectSmartTextGroup dropdown-submenu"><a tabindex="-1" href="javascript:void(0)">' +
                    'Insert Figure Reference</a><ul style="min-width:400px;max-width:450px;" class="dropdown-menu">';

                $.each($("#itemDetails ." + this.figureDef.captionDetails), function (captIdx, caption) {
                    list +=
                        '<li class="">' +
                        '<a class="selectSmartText smarttextblock" data-event="insertFigReference" href="#" name="' +
                        "figure" +
                        '" data-value="' +
                        $(caption).data("mid") +
                        '" data-toggle="tooltip"><span style="white-space:normal;margin:0 80px 0 0">' +
                        $(caption).text();
                    list += "</a>" + "</li>";
                    hasTags = true;
                });

                list += "</ul></li>";
            }
            if ($("#itemDetails ." + this.tableDef.captionDetails).length > 0) {
                list +=
                    '<li class="selectSmartTextGroup dropdown-submenu"><a tabindex="-1" href="javascript:void(0)">' +
                    'Insert Table Reference</a><ul style="min-width:400px;max-width:450px;" class="dropdown-menu">';

                $.each($("#itemDetails ." + this.tableDef.captionDetails), function (captIdx, caption) {
                    list +=
                        '<li class="">' +
                        '<a class="selectSmartText smarttextblock" data-event="insertTabReference" href="#" name="' +
                        "table" +
                        '" data-value="' +
                        $(caption).data("mid") +
                        '" data-toggle="tooltip"><span style="white-space:normal;margin:0 80px 0 0">' +
                        $(caption).text();
                    list += "</a>" + "</li>";
                    hasTags = true;
                });

                list += "</ul></li>";
            }
        }

        if (globalMatrix.serverStorage.getItem("copyBuffer")) {
            list +=
                '<li><a title="paste copy buffer" data-event="pasteBuffer" href="#" data-value="">Paste ' + "</a></li>";
        }

        if (!tableMode) {
            // add menu to create new tags
            if (!hasTags) {
                list +=
                    '<li><a href="https://urlshort.matrixreq.com/d25/manual/macros" target="_blank" class="documentationLink" >Smart Text Help</a></li>';
            }
            list += '<li><hr style="margin:0px" /></li>';

            list +=
                '<li><a title="can only be used in rich text fields" data-event="createSmartText" href="#" data-value="">Define rich text tag ' +
                "</a></li>";
            list +=
                '<li><a title="can be used in rich text fields and document properties" data-event="createPlainText" href="#" data-value="">Define plain text tag ' +
                "</a></li>";
            list +=
                '<li><a title="can be used to build a dictionary of terms" data-event="createTermText" href="#" data-value="">Define term ' +
                "</a></li>";
            list +=
                '<li><a title="can be used to build a dictionary of abbreviation" data-event="createAbbreviationText" href="#" data-value="">Define abbreviation ' +
                "</a></li>";

            if (dhf_config && dhf_config.captions) {
                list +=
                    '<li><a title="can be used to insert a caption for a figure" data-event="createFigureCaption" href="#" data-value="">Define figure caption ' +
                    "</a></li>";
                list +=
                    '<li><a title="can be used to insert a caption for a table" data-event="createTableCaption" href="#" data-value="">Define table caption ' +
                    "</a></li>";
            }
        }

        // add menu
        $(".smarttaglist").html(list);
        window.setTimeout(function () {
            // one rendered
            // avoid flickering of sub menus (at bottom of screen)
            $.each($(".smarttaglist .stsm"), function (idx, stsm) {
                $(stsm).css("max-height", $(stsm).parent().parent().height() - $(stsm).parent().position().top + "px");
            });
        }, 200);

        // implement filter
        $(".filterList").click(function (event: JQueryEventObject) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        });
        $(".filterList").keyup(function (event: JQueryEventObject) {
            let filterInput = $(event.delegateTarget);
            let filterText = $(event.delegateTarget).val().toLowerCase();

            $.each($("a", filterInput.closest("ul")), function (idx, st) {
                if (!filterText) {
                    $(st).show();
                } else {
                    let key: string = $(st).data("value") + "";
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let reps = config.replacements.filter(function (rep) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return rep.what == key;
                    });
                    let rwith = $("<div>").html(reps[0].with).text().toLowerCase();
                    if (
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        reps.length == 1 &&
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        key.toLowerCase().indexOf(filterText) == -1 &&
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        rwith.indexOf(filterText) == -1
                    ) {
                        $(st).hide();
                    } else {
                        $(st).show();
                    }
                }
            });

            return false;
        });

        // add tooltips
        $.each(config.replacements, function (optIdx, opt) {
            let replace = opt.with;
            if (opt.tagType === 3 || opt.tagType === 4) {
                replace += "<hr style='margin:0px'><div class='smarttext-menu-tt'>" + opt.description + "</div>";
            }
            $("a[name=" + opt.what + "]", $(".smarttaglist")).tooltip({
                html: true,
                title: replace,
                placement: "right",
                container: "body",
                template:
                    '<div class="tooltip smarttext-tooltip" role="tooltip"><div class="tooltip-arrow smarttext-tooltip-arrow"></div><div class="tooltip-inner smarttext-tooltip-inner"></div></div>',
            });
        });
    }

    // summernote create menu to get rid of a macro
    deleteTag(what: string): void {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function deleteIt() {
            let config = globalMatrix.ItemConfig.getSmartText();
            if (!config || !config.replacements) {
                config = { replacements: [] };
            }
            let replacements: ISmartTextConfigReplacement[] = [];
            $.each(config.replacements, function (optIdx, opt) {
                if (opt.what + "" !== what + "") {
                    replacements.push(opt);
                }
            });
            $('[data-value="' + what + '"]')
                .parent()
                .remove();
            config.replacements = replacements;
            app.setSettingJSON("rtf", config);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        app.searchAsync("_" + what + "_").done(function (results: {}[]) {
            if (results.length > 0) {
                ml.UI.showConfirm(
                    5,
                    {
                        title:
                            "The smart text tag <b>" +
                            what +
                            "</b> is currently used " +
                            results.length +
                            " or more times. Delete anyway?",
                        ok: "Delete",
                    },
                    function () {
                        deleteIt();
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    function () {},
                );
            } else {
                deleteIt();
            }
        });
    }

    // summernote insert a figure reference
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertFigReference(reference: string, editor: any, editable: any) {
        let ref = $(`#itemDetails .${this.figureDef.captionDetails}[data-mid='${reference}']`); /// Figure X: Explain
        this.insertReference(ref, this.figureDef, editor, editable);
    }

    // summernote insert a table of reference
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertTabReference(reference: string, editor: any, editable: any) {
        let ref = $(`#itemDetails .${this.tableDef.captionDetails}[data-mid='${reference}']`); /// Table X: Explain
        this.insertReference(ref, this.tableDef, editor, editable);
    }
    //  summernote menu to paste from internal buffer
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    pasteBuffer(editor: any, editable: any) {
        let html = $(globalMatrix.serverStorage.getItem("copyBuffer"));
        editor.insertNode(editable, $(html)[0], true);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private insertReference(source: JQuery, def: ICaptionFormat, editor: Summernote.editor, editable: any) {
        let id = source.data("mid");
        let text = $(".captionText", source).html();
        let reference = `<span class="referenceDetails" data-mid="${id}">
                <span class="referenceFix" contenteditable="false">
                    <span class="referencePre">${def.preNo}</span>
                    <span  class="${def.referenceClassNo}" data-mid="${id}"></span>
                    <span class="referencePost">${def.postNo}</span>
                </span>
                <span class="referenceText">${text}</span>
            </span> `;

        editor.insertNode(editable, $(reference)[0], true);
        editor.insertNode(editable, $("<span>&nbsp;</span>")[0], true);
        // put in current number
        this.updateCaptionsAndReferences();
    }
    // insert a caption for table or image
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createCaption(isTable: boolean, editor: Summernote.editor, editable: any) {
        let nowms = new Date().getTime();

        let def = isTable ? this.tableDef : this.figureDef;
        let caption = `<p class="caption">
            <span class="${def.captionDetails}" data-mid="${nowms}">
                <span class="captionFix" contenteditable="false">
                    <span class="captionPre">${def.preNo}</span>
                    <span  class="${def.captionClass}" data-mid="${nowms}"></span>
                    <span class="captionPost">${def.postNo}</span>
                </span>
                <span class="captionText">${isTable ? "Table Caption" : "Figure Caption"}</span>
            </span></p>`;

        editor.insertNode(editable, $(caption)[0], true);
        this.updateCaptionsAndReferences();
    }

    //  summernote update numbers for tables and figure references
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateCaptionsAndReferences() {
        $.each($("#itemDetails .captionFig"), function (idx, obj) {
            $(obj).html("");
            let mid = $(obj).data("mid");
            if (mid) {
                $(obj).html(idx + 1);
                $(`#itemDetails .referenceFig[data-mid='${mid}']`).html(idx + 1);
            }
        });
        $.each($("#itemDetails .captionTab"), function (idx, obj) {
            $(obj).html("");
            let mid = $(obj).data("mid");
            if (mid) {
                $(obj).html(idx + 1);
                $(`#itemDetails .referenceTab[data-mid='${mid}']`).html(idx + 1);
            }
        });
    }
    //  summernote function to create or modify tags
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createEditTag(
        tagType: number,
        what: string,
        data?: ISmartTextConfigReplacement,
        saveFct?: (newFct: ISmartTextConfigReplacement) => boolean,
        forceTiny?: boolean,
    ): void {
        // tagType:
        // 0 edit existing tag
        // 1 new plain text tag
        // 2 new rich text tag
        // 3 new term tag
        // 4 abbreviation tag
        // what is either the macro id or the item to be edited, the later is the case if a saveFct is passed
        // saveFct: optional function to save creation / changes, if not saved in project settings
        let that = this;

        let dlg = $("#smartTextDlg");
        dlg.hide();
        dlg.html("");
        dlg.removeClass("dlg-no-scroll");
        dlg.addClass("dlg-v-scroll");
        let body = `<div class="form-group row-fluid">
                <div class="smarttext-tag-name controlContainer" ></div>
                <div class="smartEditor controlContainer" ></div>
                <div class="tagDescription controlContainer" ></div>
            </div>
            <div class="checkbox">
                <label><input type="checkbox">Show me current value before creating signed documents</label>
            </div>`;
        dlg.append(body);

        let smartEditor: JQuery = $(".smartEditor", dlg);
        let tagDescription: JQuery = $(".tagDescription", dlg);
        let warnCheckbox: JQuery = $("[type=checkbox]", dlg);

        let newTagName = $(".smarttext-tag-name", dlg);

        let content = "";
        let description = "";
        let warn = false;
        let dlgTitle = "Edit ";
        let isCreate = false;
        // define default content: get it from selection of rich text editor
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if ((tagType === 1 || tagType === 2 || tagType === 4) && window.getSelection().rangeCount) {
            dlgTitle = "Create ";
            isCreate = true;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let range = window.getSelection().getRangeAt(0);
            try {
                let fragment = range.cloneContents();
                let div = document.createElement("div");
                div.appendChild(fragment.cloneNode(true));
                content = tagType === 1 ? div.innerText : div.innerHTML;
            } catch (ex) {
                // nothing selected
            }
        } else if (tagType === 0 && what) {
            // it is an edit... replace it with the actual tag type which is stored in the database
            let opt = data ? data : this.getReplacement(what);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            tagType = opt.tagType ? opt.tagType : opt.plain ? 1 : 2;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            description = opt.description ? opt.description : "";
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            content = opt.with ? opt.with : "";
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            warn = opt.warn ? opt.warn : false;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            what = opt.what;
        }

        if (tagType === 1) {
            dlgTitle += "Plain Text";
        } else if (tagType === 4) {
            dlgTitle += "Abbreviation";
        } else if (tagType === 2) {
            dlgTitle += "Smart Text";
        } else if (tagType === 3) {
            dlgTitle += "Term";
        }
        dlg.dialog({
            autoOpen: true,
            title: dlgTitle,
            width: 716,
            height: 680,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            resizeStop: function () {},
            modal: true,
            open: function () {
                ml.UI.pushDialog(dlg);
                // enable or set tag name input field
                warnCheckbox.prop("checked", warn);
                warnCheckbox.on("change", () => {
                    that.calculateButtonEnable(
                        newTagName,
                        smartEditor,
                        tagDescription,
                        //No need to check the state of the checkbox when creating.
                        isCreate ? undefined : warnCheckbox,
                        warn,
                    );
                });

                // set editor to plain text or html
                smartEditor.html("");

                if (tagType === 1) {
                    //No need to check the state of the checkbox when creating.
                    smartEditor.plainText({
                        fieldValue: content,
                        canEdit: true,
                        help: "Plain Text",
                        valueChanged: () => {
                            that.calculateButtonEnable(newTagName, smartEditor, tagDescription);
                        },
                    });
                } else if (tagType === 2) {
                    //No need to check the state of the checkbox when creating.
                    smartEditor.richText({
                        controlState: ControlState.DialogEdit,
                        fieldValue: content,
                        canEdit: true,
                        help: "Rich Text",
                        parameter: { noConvertTiny: !forceTiny, autoEdit: isCreate },
                        valueChanged: () => {
                            that.calculateButtonEnable(newTagName, smartEditor, tagDescription);
                        },
                    });
                } else if (tagType === 3) {
                    //No need to check the state of the checkbox when creating.
                    smartEditor.plainText({
                        controlState: ControlState.DialogEdit,
                        fieldValue: content,
                        canEdit: true,
                        help: "Term",
                        parameter: { rows: 1, allowResize: false },
                        valueChanged: () => {
                            that.calculateButtonEnable(newTagName, smartEditor, tagDescription);
                        },
                    });
                    tagDescription.richText({
                        fieldValue: description,
                        canEdit: true,
                        help: "Explanation",
                        parameter: { height: 200, noConvertTiny: !forceTiny, autoEdit: isCreate },
                        valueChanged: () => {
                            that.calculateButtonEnable(newTagName, smartEditor, tagDescription);
                        },
                    });
                } else if (tagType === 4) {
                    smartEditor.plainText({
                        fieldValue: content,
                        canEdit: true,
                        help: "Short Name",
                        parameter: { rows: 1, allowResize: false },
                        valueChanged: () => {
                            that.calculateButtonEnable(newTagName, smartEditor, tagDescription, warnCheckbox, warn);
                        },
                    });
                    tagDescription.plainText({
                        fieldValue: description,
                        canEdit: true,
                        help: "Full Name",
                        parameter: { rows: 1, allowResize: false, autoEdit: isCreate },
                        valueChanged: () => {
                            that.calculateButtonEnable(newTagName, smartEditor, tagDescription, warnCheckbox, warn);
                        },
                    });
                }
                that.addEnter(newTagName, smartEditor, tagDescription, what);
                if (what) {
                    // edit existing tag
                    newTagName.getController().setValue(what);
                }

                ml.UI.setEnabled($(".btnDoIt", $("#smartTextDlg").parent()), false);
            },
            close: function () {
                ml.UI.popDialog(dlg);
            },
            buttons: [
                {
                    text: "Save",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: async function () {
                        let html = await smartEditor.getController().getValueAsync();
                        let description =
                            tagType === 3 || tagType === 4 ? await tagDescription.getController().getValueAsync() : "";
                        let tag = await newTagName.getController().getValueAsync();
                        let warn = warnCheckbox.prop("checked");
                        let newRTF: ISmartTextConfigReplacement = {
                            what: tag,
                            with: html,
                            warn: warn,
                            tagType: tagType,
                            description: description,
                            when: new Date().toISOString(),
                        };

                        if (saveFct) {
                            if (saveFct(newRTF)) {
                                dlg.dialog("close");
                            }
                        } else {
                            that.saveTag(newRTF);
                            dlg.dialog("close");
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
        });
    }

    //****************************************
    // tiny rich text functions
    // *************************************** /

    // tiny: dialog to manipulate tags
    selectEditCreateTag(mode: number, tagType: number, tagSelected: (tag: ISmartTextConfigReplacement) => void): void {
        // mode:
        // 0 insert
        // 1 add
        // 2 edit
        // tagType:
        // 1 new plain text tag
        // 2 new rich text tag
        // 3 new term tag
        // 4 abbreviation tag
        // saveFct: function returning the selected macro
        let that = this;

        // get existing options
        let existing: ISmartTextConfigReplacement[] = [];
        let allTagIds: string[] = [];

        let config = that.getCurrentConfig();
        $.each(config.replacements, function (optIdx, opt) {
            allTagIds.push(opt.what);
            let tt = opt.tagType ? opt.tagType : opt.plain ? 1 : 2;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (tt == tagType) {
                existing.push(opt);
            }
        });

        let previous: ISmartTextConfigReplacement[] = ml.JSON.clone(existing);

        // prepare dialog
        let dlg = $("#smartTextDlg");
        dlg.hide();
        dlg.html("");
        dlg.removeClass("dlg-no-scroll");
        dlg.addClass("dlg-v-scroll");
        let body = `<div class="form-group row-fluid">
            <div class="smartSelect" ></div>
            <div class="smartEditor" ></div>
            <div class="tagDescription" ></div>
        </div>
        <div class="checkbox">
            <label><input type="checkbox">Show me current value before creating signed documents</label>
        </div>`;
        dlg.append(body);

        let smartSelect: JQuery = $(".smartSelect", dlg);
        let smartEditor: JQuery = $(".smartEditor", dlg);
        let tagDescription: JQuery = $(".tagDescription", dlg);
        let warnCheckbox: JQuery = $("[type=checkbox]", dlg);

        let content = "";
        let description = "";
        let warn = false;
        let dlgTitle = "";

        if (tagType === 1) {
            dlgTitle += "Plain Text";
        } else if (tagType === 4) {
            dlgTitle += "Abbreviation";
        } else if (tagType === 2) {
            dlgTitle += "Smart Text";
        } else if (tagType === 3) {
            dlgTitle += "Term";
        }
        let dialogOptions: JQueryUI.DialogOptions = {
            autoOpen: true,
            title: dlgTitle,
            width: 716,
            height: 680,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            resizeStop: function () {},
            modal: true,
            open: function () {
                ml.UI.pushDialog(dlg);
                // enable or set tag name input field
                warnCheckbox.prop("checked", warn);

                // set editor to plain text or html
                smartEditor.html("");
                if (tagType === 1) {
                    smartEditor.plainText({
                        fieldValue: content,
                        canEdit: mode > 0,
                        help: "Plain Text",
                        valueChanged: () => {
                            that.calculateButtonEnable(smartSelect, smartEditor, tagDescription);
                        },
                    });
                    tagDescription.hidden({});
                } else if (tagType === 2) {
                    smartEditor.richText({
                        controlState: ControlState.DialogEdit,
                        fieldValue: content,
                        canEdit: mode > 0,
                        help: "Rich Text",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        parameter: { tableMode: true, tiny: true, autoEdit: mode == 1 },
                        valueChanged: () => {
                            that.calculateButtonEnable(smartSelect, smartEditor, tagDescription);
                        },
                    });
                    tagDescription.hidden({});
                } else if (tagType === 3) {
                    smartEditor.plainText({
                        fieldValue: content,
                        canEdit: mode > 0,
                        help: "Term",
                        parameter: { rows: 1, allowResize: false },
                        valueChanged: () => {
                            that.calculateButtonEnable(smartSelect, smartEditor, tagDescription);
                        },
                    });
                    tagDescription.richText({
                        controlState: ControlState.DialogEdit,
                        fieldValue: description,
                        canEdit: mode > 0,
                        help: "Explanation",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        parameter: { height: 280, tiny: true, autoEdit: mode == 1 },
                        valueChanged: () => {
                            that.calculateButtonEnable(smartSelect, smartEditor, tagDescription);
                        },
                    });
                } else if (tagType === 4) {
                    smartEditor.plainText({
                        fieldValue: content,
                        canEdit: mode > 0,
                        help: "Short Name",
                        parameter: { rows: 1, allowResize: false },
                        valueChanged: () => {
                            that.calculateButtonEnable(smartSelect, smartEditor, tagDescription);
                        },
                    });
                    tagDescription.plainText({
                        fieldValue: description,
                        canEdit: mode > 0,
                        help: "Full Name",
                        parameter: { rows: 1, allowResize: false },
                        valueChanged: () => {
                            that.calculateButtonEnable(smartSelect, smartEditor, tagDescription);
                        },
                    });
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (mode == 0 /* insert */ || mode == 2 /* add */) {
                    that.addSelect(smartSelect, existing, tagType, smartEditor, tagDescription, warnCheckbox);
                } else {
                    that.addEnter(smartSelect, smartEditor, tagDescription, "");
                }
                ml.UI.setEnabled($(".btnDoIt", $("#smartTextDlg").parent()), false);
            },
            close: function () {
                ml.UI.popDialog(dlg);
            },
            buttons: [
                {
                    text: "Close",
                    class: "btnCancelIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
        };

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (mode == 0 /* insert */) {
            (<JQueryUI.DialogButtonOptions[]>dialogOptions.buttons).splice(0, 0, {
                text: "Insert",
                class: "btnDoIt",
                click: async function () {
                    let html = await that.removeOuterParagraph(await smartEditor.getController().getValueAsync());
                    let description =
                        tagType === 3 || tagType === 4 ? await tagDescription.getController().getValueAsync() : "";
                    let tag = await smartSelect.getController().getValueAsync();
                    let warn = warnCheckbox.prop("checked");

                    // update the current if there's one
                    let exists = existing.filter(function (repl) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return repl.what == tag;
                    });
                    if (exists.length) {
                        exists[0].description = description;
                        exists[0].with = html;
                        exists[0].warn = warn;
                    }
                    if (exists.length) {
                        tagSelected(exists[0]);
                    }

                    that.updateTags(tagType, previous, existing);

                    dlg.dialog("close");
                },
            });
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (mode == 1 /* edit */ || mode == 2 /* add */) {
            (<JQueryUI.DialogButtonOptions[]>dialogOptions.buttons).splice(0, 0, {
                text: "Save",
                class: "btnDoIt",
                click: async function () {
                    let html = that.removeOuterParagraph(await smartEditor.getController().getValueAsync());
                    let description =
                        tagType === 3 || tagType === 4 ? await tagDescription.getController().getValueAsync() : "";
                    let tag = await smartSelect.getController().getValueAsync();
                    let warn = warnCheckbox.prop("checked");
                    // update the current if there's one
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    let existsAsAnyType = allTagIds.indexOf(tag) != -1;
                    let exists = existing.filter(function (repl) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        return repl.what == tag;
                    });
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (mode == 1 && existsAsAnyType) {
                        // create
                        ml.UI.showError("Tag id exists already", "There is already a tag with id '" + tag + "'");
                        return false;
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                    } else if (mode == 2 && exists.length == 0 && existsAsAnyType) {
                        // edit dialog, trying to add something which exists as other type
                        ml.UI.showError("Tag id exists already", "There is already another tag with id '" + tag + "'");
                        return false;
                    } else if (exists.length) {
                        // the tag already exist as same type
                        exists[0].description = description;
                        exists[0].with = html;
                        exists[0].warn = warn;
                    } else {
                        // create new
                        existing.push({
                            tagType: tagType,
                            what: tag,
                            description: description,
                            with: html,
                            warn: warn,
                            when: new Date().toISOString(),
                        });
                    }

                    that.updateTags(tagType, previous, existing);

                    dlg.dialog("close");
                    return true;
                },
            });
        }

        dlg.dialog(dialogOptions);
    }

    // tiny: figure out if tag can be inserted
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private calculateButtonEnableInsert(option: string) {
        let btnEnabled = !!option;

        ml.UI.setEnabled($(".btnDoIt", $("#smartTextDlg").parent()), btnEnabled);
    }

    // tiny: figure out if tag can be created
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async calculateButtonEnable(
        tagName: JQuery,
        smartEditor: JQuery,
        tagDescription: JQuery,
        warnCheckbox?: JQuery,
        warn?: boolean,
    ) {
        let smartEditorHasChanged = await smartEditor.getController().hasChangedAsync();
        let tagDescriptionHasChanged = false;
        let tagNameHasChanged = false;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let checkboxHasChanged = warnCheckbox ? warnCheckbox.prop("checked") != warn : false;

        let btnEnabled = true;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if ((await smartEditor.getController().getValueAsync()) == "") {
            btnEnabled = false;
        }
        if (tagDescription.getController) {
            // MATRIX-7600 : Only check if we should disable the save button if it's not a hidden fieldHandler.
            if (tagDescription.getController().getFieldHandler()?.getFieldType() !== FieldDescriptions.Field_hidden) {
                tagDescriptionHasChanged = await tagDescription.getController().hasChangedAsync();
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if ((await tagDescription.getController().getValueAsync()) == "") {
                    btnEnabled = false;
                }
            }
        }
        if (tagName.getController) {
            tagNameHasChanged = await tagName.getController().hasChangedAsync();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if ((await tagName.getController().getValueAsync()) == "") {
                btnEnabled = false;
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (!tagName.getController && tagName.val() == "") {
            btnEnabled = false;
        }
        if (btnEnabled) {
            // Only trigger enable if the value has changed
            btnEnabled = smartEditorHasChanged || tagDescriptionHasChanged || tagNameHasChanged || checkboxHasChanged;
        }
        ml.UI.setEnabled($(".btnDoIt", $("#smartTextDlg").parent()), btnEnabled);
    }

    // tiny: get rid of <p>tag around rich text (if there are not 2 paragraphs)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    removeOuterParagraph(edit: string) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (edit == undefined) {
            return "";
        }
        if (edit.length < 8) {
            return edit;
        } // no <p>something</p>
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (edit.substr(0, 3) != "<p>") {
            return edit;
        } // no <p>something</p>
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (edit.substr(edit.length - 4, 4) != "</p>") {
            return edit;
        } // no <p>something</p>
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (edit.indexOf("<p", 1) != -1) {
            return edit;
        } // <p>something</p><p> xxx</p>
        return edit.substr(3, edit.length - 7);
    }

    // tiny: create dropdown with existing tags
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addSelect(
        container: JQuery,
        existing: ISmartTextConfigReplacement[],
        tagType: number,
        replace: JQuery,
        description: JQuery,
        warn: JQuery,
    ) {
        let that = this;

        let options: IDropdownOption[] = existing.map(function (st, idx) {
            return { id: st.what, label: st.projectSetting ? st.what : st.what + " (server)" };
        });

        let last = "";

        let dd = container.mxDropdown({
            help: "Macro Name",
            canEdit: true,
            parameter: {
                placeholder: "select macro",
                create: true,
                options: options,
                maxItems: 1,
                sort: true,
            },
            valueChanged: async function () {
                let val = await dd.getController().getValueAsync();
                let before = existing.filter(function (sm) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return sm.what == last;
                });
                if (before.length) {
                    // update the previously selected
                    before[0].with = await replace.getController().getValueAsync();
                    before[0].description = that.removeOuterParagraph(
                        await description.getController().getValueAsync(),
                    );
                    before[0].warn = warn.prop("checked");
                }

                let after = existing.filter(function (sm) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return sm.what == val;
                });
                if (after.length) {
                    // load the new one
                    replace.getController().setValue(after[0].with);
                    description.getController().setValue(after[0].description);
                    warn.prop("checked", after[0].warn);
                }

                last = val;
                that.calculateButtonEnableInsert(val);
            },
            fieldValue: "",
        });
        return dd;
    }

    // tiny: field to enter tag name
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addEnter(container: JQuery, replace: JQuery, description: JQuery, text: string) {
        let that = this;

        let dd = container.plainText({
            id: "",
            help: "New Macro Name:",
            parameter: { rows: 1, allowResize: false },
            controlState: ControlState.DialogCreate,
            valueChanged: async () => {
                let val = await dd.getController().getValueAsync();
                let clean = val ? val.replace(/[^a-zA-Z0-9]/g, "") : "";
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (val != clean) {
                    dd.getController().setValue(clean);
                }
                await that.calculateButtonEnable(container, replace, description);
            },
            canEdit: !text,
            validate: false,
            noAutoActivation: true,
            fieldValue: text ? text : "",
        });
        return dd;
    }

    //****************************************
    // both editors (sumernote+tiny) functions
    // *************************************** /

    replaceTextFragments(text: string, showTooltips?: boolean, encoded?: boolean): string {
        let that = this;

        let textFragments = this.getCurrentConfig();
        if (textFragments && textFragments.replacements) {
            $.each(textFragments.replacements, function (replacementIdx, replacement) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let resolved = that.resolveRec(replacement.with, textFragments.replacements, 5);

                let rwith =
                    "<span class='macro' data-macro='_" +
                    replacement.what +
                    "_'>" +
                    resolved +
                    "<span class='" +
                    (showTooltips ? "smart-replace" : "") +
                    "' data-toggle='tooltip'  data-what='" +
                    replacement.what +
                    "' data-placement='auto top' >" +
                    (showTooltips ? "*" : "") +
                    "</span></span>";
                if (encoded) {
                    rwith = JSON.stringify(rwith);
                    rwith = rwith.substr(1, rwith.length - 2);
                }
                text = text.replace(new RegExp("_" + replacement.what + "_", "g"), rwith);
            });
        }
        return text;
    }

    private resolveRec(text: string, replace: ISmartTextConfigReplacement[], levels: number): string {
        if (!levels) {
            // no more replacements
            return text;
        }
        let org = text;
        for (let rep of replace) {
            text = text.replace(new RegExp("_" + rep.what + "_", "g"), rep.with);
        }
        // if something was replaced go on
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return org == text ? org : this.resolveRec(text, replace, levels - 1);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showTooltips(node: JQuery, noContainer?: boolean) {
        let that = this;
        $.each($(".smart-replace", node), function (tti, tt) {
            const what = $(tt).data("what");
            const rwith = that.getReplacement(what);
            if (rwith) {
                const tagType = rwith.tagType ? rwith.tagType : rwith.plain ? 1 : 2;

                let tooltip = "<div class='smarttext_what'>_" + what + "_ (" + that.getTooltipType(rwith) + ")</div>";

                if (tagType === 3 || tagType === 4) {
                    tooltip += "<div class='smarttext_description'>" + rwith.description + "</div>";
                }
                tippy(tt, {
                    content: tooltip,
                    allowHTML: true,
                    arrow: true,
                });
            }
        });
    }

    prepareForReadReadRender(itemDetails: JQuery): void {
        const shadowRoots: Record<string, string> = {};

        // replace all form fields with static html to so that they can be easily copied
        $("select", itemDetails).each(function (idx, select) {
            $(select).replaceWith("<div>" + ml.UI.lt.forDB($("option:selected", $(select)).text(), 0) + "</div>");
        });
        $("textarea", itemDetails).each(function (idx, select) {
            $(select).replaceWith("<div>" + ml.UI.lt.forDB($(select).val(), 0).replace(/\n/g, "<br>") + "</div>");
        });
        // line input fields
        $("input[type!=checkbox]", itemDetails).each(function (idx, select) {
            $(select).replaceWith("<div>" + ml.UI.lt.forDB($(select).val(), 0) + "</div>");
        });
        // checkboxes
        $("input[type=checkbox]:not(.showHideAdmin)", itemDetails).each(function (idx, select) {
            const checked = $(select).is(":checked");

            $(select).parent().attr(DATA_HTMLDIFF_ID, checked.toString());
            $(select).replaceWith(`<span class='fal ${checked ? "fa-check-square" : "fa-square"}'></span>`);
        });
        // Shadow Doms for text boxes
        itemDetails[0].querySelectorAll(".shadow-root").forEach((element) => {
            const shadow = element.shadowRoot;
            const id = element.id;
            if (shadow === null) {
                console.error("Shadow node does not contain a shadow root");
            } else if (id === null || id === "") {
                console.error("Shadow node does not have an ID, history view won't work!");
            } else {
                if (shadowRoots.hasOwnProperty(id)) {
                    console.error("Shadow node without unique ID, this will cause issues!");
                } else {
                    shadowRoots[id] = shadow.innerHTML;
                }
            }
        });
        // cache html
        itemDetails.parent().data("originalHTML", itemDetails.html());
        // cache shadow roots
        itemDetails.parent().data("shadowRoots", JSON.stringify(shadowRoots));
    }

    private getTooltipType(repl: ISmartTextConfigReplacement): string {
        let tagType = repl.tagType ? repl.tagType : repl.plain ? 1 : 2;
        switch (tagType) {
            case 1:
                return "Plain Text Replacement";
            case 2:
                return "Rich Text Replacement";
            case 3:
                return "Term Definition";
            case 4:
                return "Abbreviation";
        }
        return "";
    }

    private getCurrentConfig(): ISmartTextConfig {
        let config: ISmartTextConfig = { replacements: [] };
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (globalMatrix.ItemConfig == undefined) {
            return config;
        }

        let sconfig = globalMatrix.ItemConfig.getSmartText();
        if (sconfig && sconfig.replacements && sconfig.replacements.length > 0) {
            // mark the project wide settings
            config = ml.JSON.clone(sconfig);
            $.each(config.replacements, function (optIdx, opt) {
                opt.projectSetting = true;
            });
        }
        // check if there are server/customer settings and merge them in (project have precedence)
        let sc = <ISmartTextConfig>matrixSession.getCustomerSettingJSON("rtf");
        if (sc && sc.replacements) {
            $.each(sc.replacements, function (csidx, cs) {
                let found = false;
                $.each(config.replacements, function (optIdx, opt) {
                    if (opt.what + "" === cs.what + "") {
                        // make sure both are strings
                        found = true;
                    }
                });
                if (!found) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    config.replacements.push(cs);
                }
            });
        }

        // sort
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        config.replacements = config.replacements.sort(function (a, b) {
            return a.what > b.what ? 1 : a.what < b.what ? -1 : 0;
        });

        return config;
    }

    // update and add new tags
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private updateTags(
        tagType: number,
        previous: ISmartTextConfigReplacement[],
        updated: ISmartTextConfigReplacement[],
    ) {
        restConnection.getProject("setting").done(function (result) {
            globalMatrix.ItemConfig.addSettings(result as XRGetProject_ProjectInfo_ProjectInfo);

            let config = globalMatrix.ItemConfig.getSmartText();
            if (!config || !config.replacements || config.replacements.length === 0) {
                config = { replacements: [] };
            }
            let changed = false;

            $.each(updated, function (eIdx, update) {
                let found = false;

                let previously = previous.filter(function (prev) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return prev.what == update.what;
                });
                let unChanged =
                    previously.length &&
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    previously[0].with == update.with &&
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    previously[0].description == update.description &&
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    previously[0].warn == update.warn;

                if (!unChanged) {
                    // either replace the old from the project setting ...
                    $.each(config.replacements, function (optIdx, current) {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (current.what == update.what) {
                            if (
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                current.with != update.with ||
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                current.description != update.description ||
                                // TODO: MATRIX-7555: lint errors should be fixed for next line
                                // eslint-disable-next-line
                                current.warn != update.warn
                            ) {
                                // update the project setting
                                found = true;
                                changed = true;
                                current.with = update.with;
                                current.description = update.description;
                                current.warn = update.warn;
                                current.when = new Date().toISOString();
                            }
                        }
                    });
                    // ... or create a new one
                    if (!found) {
                        // we need to create a new project setting
                        changed = true;
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        config.replacements.push({
                            what: update.what,
                            with: update.with,
                            warn: update.warn,
                            tagType: tagType,
                            description: update.description,
                            when: new Date().toISOString(),
                        });
                    }
                }
            });

            if (changed) {
                app.setSettingJSON(smartTextConfigSetting, config);
            }
        });
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private saveTag(tag: ISmartTextConfigReplacement) {
        // reload the settings to be sure nobody changed it inbetween
        restConnection.getProject("setting").done(function (result) {
            globalMatrix.ItemConfig.addSettings(result as XRGetProject_ProjectInfo_ProjectInfo);

            let config = globalMatrix.ItemConfig.getSmartText();
            if (!config || !config.replacements || config.replacements.length === 0) {
                config = { replacements: [tag] };
            } else {
                // check if it is an update or new
                let found = false;
                $.each(config.replacements, function (optIdx, opt) {
                    if (opt.what + "" === tag.what + "") {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        config.replacements[optIdx] = tag;
                        found = true;
                    }
                });
                if (!found) {
                    config.replacements.push(tag);
                }
            }
            app.setSettingJSON("rtf", config);
        });
    }

    private getReplacement(what: string): ISmartTextConfigReplacement | undefined {
        let config = this.getCurrentConfig();
        let hit: ISmartTextConfigReplacement;
        $.each(config.replacements, function (optIdx, opt) {
            if (opt.what + "" === what + "") {
                // make sure both are strings
                hit = opt;
            }
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return hit;
    }
}
