import { ControlState, IRestResult, restConnection } from "../../../globals";
import { XRGetUser_User_UserType, XRTokenType, XRUserType } from "../../../RestResult";
import { ml } from "../../matrixlib";
import { MatrixSession } from "../../businesslogic";

export { TokenControl };

type TokenAddedCallback = (token: string) => void;

class TokenControl {
    static showUserTokens(container: JQuery, login: string): void {
        let table = $(
            "<table class='table'><thead><tr><th>Purpose</th><th>ID</th><th>Token</th><th>Valid Until</th><th>Description</th><th></th></tr></thead></table>",
        ).appendTo(container);
        let body = $("<tbody>").appendTo(table);

        restConnection.getServer("user/" + login).done(function (result) {
            const user = result as XRGetUser_User_UserType;
            TokenControl.showTokenList({
                body,
                createUser: user.login,
                tokenList: user.tokenList,
                tokensEnabled: true,
            });
            TokenControl.showTokenAdd(body, user.login, [], (tokenValue: string) => {
                MatrixSession.NewTokenDisplay(tokenValue);
                container.html("");
                TokenControl.showUserTokens(container, login);
            });
        });
    }

    static showTokenList(params: {
        body: JQuery;
        createUser?: string;
        displayUser?: string;
        tokenList: XRTokenType[];
        tokensEnabled: boolean;
    }): void {
        const { body, createUser, displayUser, tokenList, tokensEnabled } = params;

        if (tokenList) {
            $.each(tokenList, function (tidx, token) {
                let row = $("<tr>").appendTo(body);
                if (displayUser) {
                    row.append("<td>" + displayUser + "</td>");
                }
                row.append("<td>" + token.purpose + "</td>");
                row.append("<td>" + token.tokenId + "</td>");

                if (tokensEnabled || token.purpose === "password_reset") {
                    row.append("<td class='line-break-anywhere'>" + token.value + "</td>");
                } else {
                    let tokenContainer = $("<div>")
                        .addClass("token-container")
                        .append(
                            $("<div>").addClass("token-disabled").text(token.value),
                            $("<span>").addClass("token-disabled-chip").text("Disabled"),
                        );
                    row.append($("<td>").append(tokenContainer));
                }
                row.append("<td>" + token.validToUserFormat + "</td>");
                row.append("<td>" + (token.reason ? token.reason : "") + "</td>");
                let deleteToken = $(
                    "<i style='cursor:pointer' class='deleteToken fal fa-trash-alt' data-user='" +
                        (displayUser ? displayUser : createUser) +
                        "'data-token='" +
                        token.tokenId +
                        "'>",
                );
                row.append($("<td>").append(deleteToken));

                deleteToken.click(function (event: JQueryMouseEventObject) {
                    let tokenDel = $(event.delegateTarget);

                    restConnection
                        .deleteServerAsync("user/" + tokenDel.data("user") + "/token", {
                            tokenId: tokenDel.data("token"),
                        })
                        .done(function () {
                            tokenDel.closest("tr").remove();
                        });
                });
            });
        }
    }

    static showTokenAdd(body: JQuery, user: string | null, allUsers: XRUserType[], onAdded: TokenAddedCallback): void {
        let row = $("<tr>").appendTo(body);
        let userSelect: JQuery;

        if (!user) {
            let options = allUsers.map(function (user) {
                return { id: user.login, label: user.login };
            });

            userSelect = $("<div id='tokenUser'>").mxDropdown({
                controlState: ControlState.FormEdit,
                canEdit: true,
                help: "",
                fieldValue: "",
                valueChanged: function () {
                    if (userSelect) {
                        TokenControl.updateCreate(user, userSelect);
                    }
                },
                parameter: {
                    placeholder: "select user",
                    maxItems: 1,
                    options: options,
                    groups: [],
                    create: false,
                    sort: true,
                    splitHuman: false,
                },
            });
            row.append($("<td id='to_user'>").append(userSelect));
        }

        row.append("<td id='to_purpose'></td>");
        row.append("<td id='to_tokenid'></td>");
        row.append("<td id='to_token'></td>");

        let endDate = $("<input id='endTimePicker' type='text' class='form-control'>");
        endDate.datetimepicker({
            format: ml.UI.DateTime.getSimpleDateTimeFormatMoment(),
            widgetPositioning: { vertical: "bottom" },
        });
        row.append($("<td id='to_date'>").append(endDate));
        endDate.on("dp.change", function () {
            TokenControl.updateCreate(user, userSelect);
        });
        let reason = $("<input id='tokenReason' type='text' class='form-control'>");
        reason.on("change keyup paste", function (event: JQueryEventObject) {
            TokenControl.updateCreate(user, userSelect);
        });
        row.append($("<td id='to_reason'>").append(reason));
        row.append("<td><button class='btn btn-default' id='tokenCreate' disabled>create</button></td>");

        $("#tokenCreate").click(function () {
            TokenControl.createToken(onAdded);
        });
    }

    // change status of create button
    private static async updateCreate(defaultUser: string | null, userSelect: JQuery): Promise<void> {
        let date = $("#endTimePicker").data("DateTimePicker").date();
        let user = defaultUser ? defaultUser : await userSelect.getController().getValueAsync();
        let reason = $("#tokenReason").val();

        let f = new Date(date);
        let n = new Date();

        $("#tokenCreate").data("date", date).data("user", user).data("reason", reason);
        ml.UI.setEnabled(
            $("#tokenCreate"),
            !!reason && date && !!user && Math.ceil((f.getTime() - n.getTime()) / 3600000) > 0,
        );
    }

    // create token
    private static createToken(onAdded: TokenAddedCallback): void {
        let date = $("#tokenCreate").data("date");
        let user = $("#tokenCreate").data("user");
        let reason = $("#tokenCreate").data("reason");

        let future = new Date(date);
        let now = new Date();
        let delta = Math.ceil((future.getTime() - now.getTime()) / 3600000);

        restConnection
            .postServer("user/" + user.toLowerCase() + "/token", {
                purpose: "oauth",
                validity: delta,
                reason: reason,
            })
            .done(function (createTokenResponse?: IRestResult) {
                if (createTokenResponse) {
                    onAdded((createTokenResponse as { token: string }).token);
                }
            });
    }
}
