import { IItemIdParts, IItem, IReference, IReferenceChange, IItemGet, IItemPut, IGenericMap } from "../../globals";
import { refLinkStyle, refLinkTooltip } from "../UI/Parts/RefLinkDefines";
import { ISimpleItemTools } from "./MatrixLibInterfaces";

export { SimpleItemTools };

// Implements ItemTools functionality with minimal dependencies
class SimpleItemTools implements ISimpleItemTools {
    // extract different parts from item ids, like version, category, ...
    public parseRef(itemRef: string, project: string, matrixBaseUrl: string): IItemIdParts {
        let ref = itemRef; // e.g. F-REQ-13-v12
        let result = {
            id: "", // F-REQ-13-v12 -> REQ-13
            number: 0, // F-REQ-13-v12 -> 13
            originTag: "", // F-REQ-13-SOURCE-NSOURCE-v12 -> SOURCE-NSOURCE
            version: 0, // F-REQ-13-v12 -> 12
            type: "", // F-REQ-13-v12 -> REQ
            isFolder: false, // F-REQ-13-v12 -> true
            url: "", // company.matrixreq.com/project/REQ-13-v12
            link: "", // <a href=url>item</a>
            linkv: "", // <a href=url>url</a>
        } as IItemIdParts;

        let vp = ref.indexOf("-v");
        if (vp !== -1) {
            result.version = parseInt(ref.substr(vp + 2));
            ref = ref.substr(0, vp);
        }
        result.id = ref;
        if (ref.indexOf("F-") === 0) {
            result.isFolder = true;
            ref = ref.substr(2);
        }
        let parts = ref.split("-");

        if (parts.length > 1) {
            result.type = parts[0];

            result.number = Number(parts[1]);
            if (parts.length > 2) {
                // There may be an origin tag, which is all parts up to the "-v..." if present.
                let originTag = "";
                for (let i = 2; i < parts.length; i++) {
                    if (parts[i].indexOf("v-") < 0) {
                        originTag += i === 2 ? parts[i] : "-" + parts[i];
                    }
                }
                result.originTag = originTag;
            }
        }
        result.url = matrixBaseUrl + "/" + project + "/" + itemRef;
        result.link = "<a style='color:blue !important' href='" + result.url + "'>" + itemRef + "</a>";
        result.linkv = "<a style='color:blue !important' href='" + result.url + "'>" + result.url + "</a>";

        return result;
    }

    public getCreator(item: IItem): string {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return item.history[item.history.length - 1].user;
    }

    public getLastEditor(item: IItem): string {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return item.history.length > 0 ? item.history[0].user : "";
    }

    // render a list of item ids, to show up as <b>ID</b> name, <b>ID</b> name,
    refListToDisplayString(
        inputItems: IReference[] | null,
        prefix: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        getTitleFunction: any,
        shorten?: number,
    ): string {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function makeLink(itemobj: IReference, shorten: boolean) {
            if (!itemobj.projectShortLabel) {
                return "<b>" + itemobj.to + "</b>" + (shorten ? "" : " " + getTitleFunction(itemobj.to));
            }

            let label = itemobj.projectShortLabel + ":" + itemobj.to;

            return "#" + itemobj.projectShortLabel + "/" + itemobj.to + "#";

            //return "<a class='crossProjectLink' title='" + itemobj.title + "' href='" + matrixBaseUrl + "/" + itemobj.projectShortLabel + "/" + itemobj.to + "' target='_blank'>" + label + "</a> ";
        }

        if (inputItems) {
            // build the list ItemId Title can take a long time, e.g. if there's dozens of search results and the list should be a short list. In that case only show names if there's less than 5 items
            let refs = inputItems.map(function (inputItem) {
                return makeLink(inputItem, shorten && inputItems.length > 4 ? true : false);
            });
            let list = refs.join(", ");
            if (shorten && list.replace(/<b>/g, "").replace(/<\/b>/g, "").length > shorten) {
                refs = inputItems.map(function (inputItem) {
                    return makeLink(inputItem, true);
                });
                list = refs.join(", ");
            }
            if (shorten && list.replace(/<b>/g, "").replace(/<\/b>/g, "").length > shorten) {
                list =
                    "<b>" +
                    list
                        .replace(/<b>/g, "")
                        .replace(/<\/b>/g, "")
                        .substr(0, shorten - 4) +
                    " ...</b>";
            }
            if (prefix) {
                list = prefix + " " + list;
            }

            return list;
        } else {
            return "";
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderLink(itemId: string, itemTitle: string, newWindow?: boolean) {
        let dbt = itemTitle;

        return $("<div>").refLink({
            id: itemId,
            folder: false,
            title: dbt ? dbt : "(deleted)",
            style: newWindow ? refLinkStyle.link : refLinkStyle.selectTree,
            tooltip: refLinkTooltip.html,
        });
    }
    // update and changes to reference lists
    updateReferences(
        oldReferences: IReference[],
        newReferences: IReference[],
        fromId: string | null,
        toId: string | null,
    ): IReferenceChange[] {
        let changeList: IReferenceChange[] = [];
        // find links to add
        for (let idx = 0; idx < newReferences.length; idx++) {
            let found = false;
            for (let jdx = 0; jdx < oldReferences.length; jdx++) {
                if (newReferences[idx].to === oldReferences[jdx].to) {
                    found = true;
                    continue;
                }
            }
            if (!found) {
                if (fromId) {
                    changeList.push({ action: "addLink", fromId: fromId, toId: newReferences[idx].to });
                } else if (toId) {
                    changeList.push({ action: "addLink", fromId: newReferences[idx].to, toId: toId });
                }
            }
        }
        // find links to remove
        for (let jdx = 0; jdx < oldReferences.length; jdx++) {
            let found = false;
            for (let idx = 0; idx < newReferences.length; idx++) {
                if (newReferences[idx].to === oldReferences[jdx].to) {
                    found = true;
                    continue;
                }
            }
            if (!found) {
                if (fromId) {
                    changeList.push({ action: "removeLink", fromId: fromId, toId: oldReferences[jdx].to });
                } else if (toId) {
                    changeList.push({ action: "removeLink", fromId: oldReferences[jdx].to, toId: toId });
                }
            }
        }
        return changeList;
    }

    // clone item from IItemGet to IItemPut
    clone(item: IItemGet, copyLabels: boolean): IItemPut {
        let newItem: IItemPut = {};

        $.each(item, function (idx, val) {
            if (
                idx !== "maxVersion" &&
                idx !== "labels" &&
                idx !== "labels" &&
                idx !== "downLinks" &&
                idx !== "upLinks" &&
                idx !== "type" &&
                idx !== "children" &&
                idx !== "hide" &&
                idx !== "history" &&
                idx !== "isUnselected" &&
                idx !== "modDate" &&
                idx !== "restricted" &&
                idx !== "upLinkList" &&
                idx !== "userRights"
            ) {
                (<IGenericMap>newItem)[idx] = val;
            }
        });

        if (copyLabels) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            newItem.labels = item.labels.join(",");
        } else {
            newItem.labels = "";
        }

        return newItem;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    sort(a: string, b: string, project: string, matrixBaseUrl: string) {
        let at = this.parseRef(a, project, matrixBaseUrl);
        let bt = this.parseRef(b, project, matrixBaseUrl);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (at.type == bt.type) {
            if (at.isFolder && !bt.isFolder) {
                return -1;
            }
            if (bt.isFolder && !at.isFolder) {
                return 1;
            }

            return at.number - bt.number;
        }
        return a < b ? -1 : 1;
    }
}
