import { app, matrixSession, globalMatrix, ControlState } from "../../../globals";
import { IDropdownOption } from "../../../ProjectSettings";
import { ISearchResult } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { IPanel } from "../Application";
import { ISyncCatgoryInfo, SyncStatusImpl } from "../Controls/syncStatus";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { DefaultCategorySettingNames } from "../../../admin/lib/categories/CategorySetting";

export { SyncPanel };

class SyncPanel implements IPanel {
    private control: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private syncCatgoryInfo: ISyncCatgoryInfo;
    private body: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private syncLists: JQuery;
    title = "Agile Sync";

    constructor() {
        let that = this;

        this.control = app.itemForm;
        document.title = this.title + " - " + matrixSession.getProject();

        let outerbody = $("<div class='panel-body-v-scroll fillHeight'>");
        this.control.append(outerbody);
        //this.control.css("border","none");
        this.body = $("<div class='panel-body'>");
        outerbody.append(this.body);

        // get categories which are from external source and can be synced
        let syncCategories: string[] = [];
        $.each(globalMatrix.ItemConfig.getCategories(), function (idx, cat) {
            let si = <ISyncCatgoryInfo>(
                globalMatrix.ItemConfig.getCategorySetting(cat, DefaultCategorySettingNames.syncInfo)
            );
            if (si) {
                syncCategories.push(cat);
            }
        });
        //
        this.control.prepend(ml.UI.getPageTitle(this.title));

        if (syncCategories.length === 0) {
            this.body.append("<h1>no syncable categories</h1>");
            return;
        }

        let categorySelect: IDropdownOption[] = [];
        $.each(syncCategories, function (idx, cat) {
            categorySelect.push({ id: cat, label: cat });
        });

        // Add handler to render the sync category
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let refresh = async () => {
            let cat = await syncSel.getController().getValueAsync();
            that.syncCatgoryInfo = <ISyncCatgoryInfo>(
                globalMatrix.ItemConfig.getCategorySetting(cat, DefaultCategorySettingNames.syncInfo)
            );

            that.renderSyncCategory(cat);
        };

        let syncSel = $("<div>").mxDropdown({
            controlState: ControlState.FormEdit,
            canEdit: true,
            dummyData: false,
            help: "Select Sync Category",
            parameter: {
                readonly: false,
                placeholder: "select category",
                splitHuman: false,
                options: categorySelect,
            },
            valueChanged: function () {
                refresh();
            },
        });

        this.body.append(syncSel);
        this.syncLists = $("<div>");
        this.body.append(this.syncLists);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (categorySelect.length == 1) {
            syncSel.getController().setValue(categorySelect[0].id);
        }
        refresh();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderSyncCategory(cat: string) {
        this.syncLists.html("");
        this.renderNew(cat, false);
        this.showItemList(
            cat,
            "UNSYNC",
            "Synced items which changed after last sync",
            "No items changed after last sync.",
        );
        this.showItemList(cat, "SYNCED", "Synced items which did not change after last sync", "No items are in sync.");
        this.renderNew(cat, true);
    }

    /** show items from external database which never have been synched */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderNew(sourceCat: string, showIgnored: boolean) {
        let that = this;

        let section = $("<div>");
        this.syncLists.append(section);

        ml.LabelTools.ignoreProjectFilter = true;
        app.searchAsync('mrql:category="' + sourceCat + '" and label=' + (showIgnored ? "IGNORE" : "NEW"))
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (results: ISearchResult[]) {
                section.append(showIgnored ? "<h1>Ignored external items</h1>" : "<h1>New external items</h1>");

                if (results.length === 0) {
                    section.append("<p>All items are linked or ignored.</p>");
                    return;
                }

                // render new / unsynced items
                let table = $("<table class='table table-bordered' style='border:1px solid #ddd;'>");
                let thead = $("<thead>");
                let tbody = $("<tbody>");
                table.append(thead);
                table.append(tbody);

                let actions = $('<div class="syncActions">');
                section.append(actions);

                $.each(that.syncCatgoryInfo.categories.split(","), function (catIdx, cat) {
                    let btn = $(
                        '<button name="sync"' +
                            cat +
                            ' class="btn btn-default sync_createNew btn-sync-field ">Create new ' +
                            cat +
                            " from selected</button>",
                    );
                    actions.append(btn);
                    btn.click(() => that.createNew(btn, sourceCat, cat, tbody));
                });
                if (!showIgnored) {
                    let btn_no = $(
                        '<button name="syncno" class="btn btn-default sync_ignore btn-sync-field ">Do not sync selected items</button>',
                    );
                    actions.append(btn_no);
                    btn_no.click(() => that.ignore(btn_no, sourceCat, tbody));
                }
                let toggleSelection = $(
                    '<label><input type="checkbox"><span style="font-weight:normal;text-decoration:italic;padding-left:10px"><b>un/select all<b></span></label>',
                );
                toggleSelection.click((event: JQueryEventObject) => that.toggleTable(event, tbody));

                section.append(table);

                let row = $("<tr>");
                row.append($("<th style='white-space:nowrap;margin-top:6px;'>").append(toggleSelection));
                row.append($("<th style='width:99%;vertical-align: middle;'>").html("item"));
                thead.append(row);

                $.each(results, function (idx, syncItem) {
                    let row = $("<tr>");
                    let select = $('<input type="checkbox">').data("itemId", syncItem.itemId);
                    row.append($("<td>").append(select));
                    row.append($("<td>").append(that.createItemLink(syncItem.itemId)));
                    tbody.append(row);
                });
                table.highlightReferences();
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function () {})
            .always(function () {
                ml.LabelTools.ignoreProjectFilter = false;
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showItemList(sourceCat: string, label: string, heading: string, noHits: string) {
        let that = this;

        let section = $("<div>");
        this.syncLists.append(section);

        ml.LabelTools.ignoreProjectFilter = true;
        app.searchAsync('mrql:category="' + sourceCat + '" and label=' + label)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (results: ISearchResult[]) {
                section.append($("<h1>").html(heading));

                if (results.length === 0) {
                    section.append($("<p>").html(noHits));
                    return;
                }

                // render new / unsynced items
                let table = $("<table class='table table-bordered' style='border:1px solid #ddd;'>");
                let thead = $("<thead>");
                let tbody = $("<tbody>");
                table.append(thead);
                table.append(tbody);

                let actions = $('<div class="syncActions">');
                section.append(actions);

                let btn = $(
                    '<button name="resync"' +
                        sourceCat +
                        ' class="btn btn-default sync_re btn-sync-field ">Re-sync selected items</button>',
                );
                actions.append(btn);
                btn.click(() => that.reSync(btn, sourceCat, tbody));

                let btn_no = $(
                    '<button name="syncno" class=" btn btn-default sync_break btn-sync-field ">Stop synchronising selected items</button>',
                );
                actions.append(btn_no);
                btn_no.click(() => that.ignore(btn_no, sourceCat, tbody));

                let toggleSelection = $(
                    '<label><input type="checkbox"><span style="font-weight:normal;text-decoration:italic;padding-left:10px"><b>un/select all<b></span></label>',
                );
                toggleSelection.click((event: JQueryEventObject) => that.toggleTable(event, tbody));

                section.append(table);

                let row = $("<tr>");
                row.append($("<th style='white-space:nowrap;margin-top:6px;'>").append(toggleSelection));
                row.append($("<th style='width:99%;vertical-align: middle;'>").html("item"));
                thead.append(row);

                $.each(results, function (idx, syncItem) {
                    let row = $("<tr>");
                    let select = $('<input type="checkbox">').data("itemId", syncItem.itemId);
                    row.append($("<td>").append(select));
                    row.append($("<td>").append(that.createItemLink(syncItem.itemId)));
                    tbody.append(row);
                });
                table.highlightReferences();
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function () {})
            .always(function () {
                ml.LabelTools.ignoreProjectFilter = false;
            });
    }
    /** toggle items in table */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private toggleTable(event: JQueryEventObject, body: JQuery) {
        let on = $("input", event.delegateTarget).prop("checked");
        $("input", body).prop("checked", on ? "checked" : "");
    }

    /** create a new items from selected external items */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createNew(btn: JQuery, externalCategory: string, targetCategory: string, body: JQuery) {
        let that = this;

        let items: string[] = [];
        $.each($("input:checked", body), function (idx, cb) {
            items.push($(cb).data("itemId"));
        });
        if (items.length === 0) {
            ml.UI.showError("No items selected", "please check items to be synced");
            return;
        }

        ml.UI.setEnabled(btn, false);
        SyncStatusImpl.createNew(externalCategory, items, targetCategory).done(function () {
            that.renderSyncCategory(externalCategory);
            ml.UI.setEnabled(btn, true);
        });
    }

    /** re-sync existing links */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private reSync(btn: JQuery, externalCategory: string, body: JQuery) {
        let that = this;

        let items: string[] = [];
        $.each($("input:checked", body), function (idx, cb) {
            items.push($(cb).data("itemId"));
        });

        if (items.length === 0) {
            ml.UI.showError("No items selected", "please check items to be synced");
            return;
        }

        ml.UI.setEnabled(btn, false);
        SyncStatusImpl.reSyncItems(externalCategory, items).done(function () {
            that.renderSyncCategory(externalCategory);
            ml.UI.setEnabled(btn, true);
        });
    }

    /** re-sync existing links */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private ignore(btn: JQuery, externalCategory: string, body: JQuery) {
        let that = this;

        let items: string[] = [];
        $.each($("input:checked", body), function (idx, cb) {
            items.push($(cb).data("itemId"));
        });

        if (items.length === 0) {
            ml.UI.showError("No items selected", "please check items to be synced");
            return;
        }
        ml.UI.setEnabled(btn, false);
        SyncStatusImpl.breakLinks(externalCategory, items).done(function () {
            that.renderSyncCategory(externalCategory);
            ml.UI.setEnabled(btn, true);
        });
    }
    /** create a clickable link */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createItemLink(targetId: string) {
        return $("<span>").refLink({
            id: targetId,
            folder: false,
            title: app.getItemTitle(targetId),
            style: refLinkStyle.selectTree,
            tooltip: refLinkTooltip.html,
        });
    }
}
