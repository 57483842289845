import { app, ControlState, globalMatrix, IReference } from "../../../globals";
import { SelectMode } from "../Components/ProjectViewDefines";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ILinkCategories } from "./linkCollection";
import { ml } from "./../../matrixlib";
import { ItemSelectionTools } from "../Tools/ItemSelectionView";
import { ItemSelectionFieldHandler } from "../../businesslogic/FieldHandlers/ItemSelectionFieldHandler";

export type { IItemSelectionParams, IItemSelectionOptions };
export { ItemSelectionImpl };

interface IItemSelectionParams {
    prefix?: string; // "Selected items:",
    buttonName?: string; //"Select Items",
    showOnly?: string[]; //
    showNot?: string[]; //
    crossProject?: boolean; // if it is cross project links
    singleFolderOnly?: boolean;

    readOnly?: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    linkTypes?: {}[]; // legacy not used anymore
    readonly?: boolean;

    // 2.1
    crossProjectHideDelete?: boolean; // true to hide unlink icon
    crossProjectAsList?: boolean; // if true cross project links will be show as 'normal references in list rather than , separated line
}

interface IItemSelectionOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: IItemSelectionParams;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.itemSelection = function (this: JQuery, options: IItemSelectionOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = new ItemSelectionFieldHandler(options);
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new ItemSelectionImpl(this, options.fieldHandler as ItemSelectionFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class ItemSelectionImpl extends BaseControl<ItemSelectionFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IItemSelectionOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectedItems: IReference[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private uiCtrl: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private currentSelection: JQuery;

    constructor(control: JQuery, fieldHandler: ItemSelectionFieldHandler) {
        super(control, fieldHandler);
    }

    private getHandler(): ItemSelectionFieldHandler {
        return <ItemSelectionFieldHandler>this.getFieldHandler();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IItemSelectionOptions) {
        let that = this;

        let defaultOptions: IItemSelectionOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                prefix: "Selected items:",
                buttonName: "Select Items",
                showOnly: [],
                showNot: [],
                crossProject: false,
                readOnly: false,
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        this._root.append(super.createHelp(options));
        this.uiCtrl = $("<div class='baseControl'>").appendTo(this._root);

        // initialize object
        this.selectedItems = [];
        if (typeof this.getHandler().hasItems === "function") {
            this.selectedItems = this.getHandler().getItems();
        }

        if (this.settings.controlState === ControlState.Print || this.settings.controlState === ControlState.Tooltip) {
            this.currentSelection = $(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                "<div class='" + (this.settings.parameter.crossProjectAsList ? "" : "itemSelectionList") + "'>",
            ).appendTo(this.uiCtrl);
            this.showCurrentSelection(false);
            return;
        }
        let ctrlContainer = $("<div style='display: flex;flex-direction: column;'>").addClass("baseControl");
        this.uiCtrl.append(ctrlContainer);
        let selButton = $("<span>");

        let allLinkTypes = globalMatrix.ItemConfig.getCategories();
        //It's a cross project, so IC.getCategories() can return empty array or a list of category that not relevant for the cross project
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (options.parameter.crossProject) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (options.parameter.showOnly != undefined) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                options.parameter.showOnly.forEach((cat) => {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (allLinkTypes.indexOf(cat) == -1) {
                        allLinkTypes.push(cat);
                    }
                });
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (options.parameter.showNot != undefined) {
                allLinkTypes = allLinkTypes.filter((cat) => {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return options.parameter.showNot.indexOf(cat) == -1;
                });
            }
        }

        let linkTypes: ILinkCategories[] = [];
        for (let idx = 0; idx < allLinkTypes.length; idx++) {
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.showNot.length !== 0 &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.showNot.indexOf(allLinkTypes[idx]) === -1
            ) {
                linkTypes.push({ type: allLinkTypes[idx] });
            } else if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.showOnly.length !== 0 &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.showOnly.indexOf(allLinkTypes[idx]) !== -1
            ) {
                linkTypes.push({ type: allLinkTypes[idx] });
            }
        }

        let selectTools = new ItemSelectionTools();
        let projectShortLabel = "";
        let projectSelectedItems: IReference[] = [];

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.canEdit && !this.settings.parameter.readonly) {
            selectTools.renderButtons({
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                selectMode: this.settings.parameter.crossProject
                    ? // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                      this.settings.parameter.singleFolderOnly
                        ? SelectMode.singleFolder
                        : SelectMode.independent
                    : // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.settings.parameter.singleFolderOnly
                    ? SelectMode.singleFolder
                    : this.settings.preciseSelection
                    ? SelectMode.autoPrecise
                    : SelectMode.auto,
                control: selButton,
                linkTypes: linkTypes,
                smallbutton: true,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                crossProject: this.settings.parameter.crossProject,
                crossProjectInit: function (psl: string) {
                    projectShortLabel = psl;
                    // for UI filter out other projects items
                    projectSelectedItems = [];
                    $.each(that.selectedItems, function (selectedItemIdx, selectedItem) {
                        if (selectedItem.projectShortLabel === projectShortLabel) {
                            projectSelectedItems.push(selectedItem);
                        }
                    });
                },
                selectionChange: function (newSelection: IReference[]) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (that.settings.parameter.crossProject) {
                        projectSelectedItems = newSelection;
                        let combinedSelection: IReference[] = [];

                        // update selection to point to project and add to global selection
                        $.each(projectSelectedItems, function (sidx, si) {
                            si.projectShortLabel = projectShortLabel;
                            combinedSelection.push(si);
                        });
                        // add selected items from other projects
                        // we don't want to keep previous selection if it's a singleFolderOnly
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (!that.settings.parameter.singleFolderOnly) {
                            $.each(that.selectedItems, function (sidx, si) {
                                if (si.projectShortLabel !== projectShortLabel) {
                                    combinedSelection.push(si);
                                }
                            });
                        }
                        // save it
                        that.selectedItems = ml.JSON.clone(combinedSelection);
                    } else {
                        // this is in project selection: so all
                        that.selectedItems = newSelection;
                    }
                    that.getHandler().setItems(that.selectedItems);
                    that.uiCtrl.data("new", JSON.stringify(that.selectedItems));
                    that.showCurrentSelection(false);
                    if (that.settings.valueChanged) {
                        that.settings.valueChanged.apply(null);
                    }
                },
                getSelectedItems: async function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (that.settings.parameter.crossProject) {
                        return projectSelectedItems;
                    } else {
                        return that.selectedItems;
                    }
                },
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                buttonName: this.settings.parameter.buttonName,
            });
        }

        this.currentSelection = $("<span>");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.parameter.crossProjectAsList) {
            this.currentSelection.addClass("itemSelectionList");
        }
        ctrlContainer.append(this.currentSelection).append(selButton);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.showCurrentSelection(this.settings.canEdit && !this.settings.parameter.readonly);
        this.uiCtrl.data("original", JSON.stringify(this.selectedItems));
        this.uiCtrl.data("new", JSON.stringify(this.selectedItems));
    }
    // implement interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return this.uiCtrl.data("original") !== this.uiCtrl.data("new");
    }
    async getValueAsync(): Promise<string> {
        return this.uiCtrl.data("new");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(itemIds: string[]) {
        this.selectedItems = itemIds.map(function (itemId) {
            return { to: itemId, title: app.getItemTitle(itemId) };
        });
        this.getHandler().setItems(this.selectedItems);
        this.uiCtrl.data("new", JSON.stringify(this.selectedItems));
        this.showCurrentSelection(false);
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}
    // private functions

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addUnlink(li: JQuery, idx: number) {
        let that = this;

        $(
            "<span title data-original-title='Remove link' class='btn-deleteRef hidden-print'> <i class='fal fa-unlink'></i></span>",
        )
            .appendTo(li)
            .data("linkIdx", idx)
            .click(function (elem: JQueryEventObject) {
                let idxDelete = $(elem.delegateTarget).data("linkIdx");
                let toDelete = that.selectedItems[idxDelete];
                ml.UI.showConfirm(
                    2,
                    {
                        title:
                            "Remove link to '" +
                            (toDelete.projectShortLabel ? toDelete.projectShortLabel + "/" : "" + toDelete.to) +
                            "'?",
                        ok: "Remove",
                    },
                    function () {
                        that.selectedItems.splice(idxDelete, 1);
                        that.uiCtrl.data("new", JSON.stringify(that.selectedItems));
                        that.showCurrentSelection(true);
                        if (that.settings.valueChanged) {
                            that.settings.valueChanged.apply(null);
                        }
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    function () {},
                );
            })
            .tooltip();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showCurrentSelection(canEdit: boolean) {
        let that = this;

        if (this.selectedItems.length === 0) {
            this.currentSelection.html(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.prefix ? this.settings.parameter.prefix : "" + " (none)",
            );
            return;
        }

        let isCross = this.selectedItems.length && this.selectedItems[0].projectShortLabel;

        let links = this.selectedItems.map(function (link, idx) {
            return ml.Item.refListToDisplayString([link], "");
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.crossProjectAsList) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.currentSelection.html(this.settings.parameter.prefix ? this.settings.parameter.prefix : "");
            let ul = $("<ul>").appendTo(this.currentSelection);
            $.each(links, function (lidx, link) {
                let li = $("<li>").append(link);
                ul.append(li);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (canEdit && !that.settings.parameter.crossProjectHideDelete) {
                    that.addUnlink(li, lidx);
                }
            });
        } else {
            let list = ml.Item.refListToDisplayString(
                this.selectedItems,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.prefix,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.crossProject ? 0 : 100,
            );

            this.currentSelection.html(list);
        }

        if (isCross) {
            this.currentSelection.highlightReferences();
        }
    }
}
