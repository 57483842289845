import { IFieldHandler } from "./IFieldHandler";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { FieldDescriptions } from "../FieldDescriptions";

export interface IBaseGateOptions {
    /** define different reviews/approvals which need to be made for gate to pass */
    lines?: IGateLineBase[];
}
export interface IGateLineBase {
    /** a unique id for the line */
    id: string; // unique id of line
    /** define which users can approve */
    users: string[];
}

// save status of gate programmable and searchable
export interface IGateStatus {
    passed: boolean; // all lines passed
    failed: boolean; // 1+ failed
    lines?: IGateStatusLine[];
    search: string /*
        _passed_ (all lines passed)
        _failed_ (one line failed)
        _todo_ (needs some action)
        _pass_userId_ (a user passed it)
        _fail_userId_ (a user failed it)
        _todo_userId_ (a user needs to do something)

    */;
}

export interface IGateStatusLine {
    id: string; // same id as in IGateLine
    passed: boolean; // last user (see below) passed
    failed: boolean; // last user (see below) failed
    user: string; // last user action
    date: string; // last user date (in utc)
    dateUser: string; // last user date (in server formatting)
    comment: string; // text
}

export class GateFieldHandler implements IFieldHandler {
    private config: IBaseGateOptions;
    private currentValue: IGateStatus | undefined;

    constructor(config: IBaseGateOptions) {
        this.config = config;
        this.currentValue = undefined;
    }

    getData(): string | undefined {
        return this.currentValue ? JSON.stringify(this.currentValue) : undefined;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.currentValue = JSON.parse(value);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_gateControl;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.currentValue = this.parseFieldValue(serializedFieldData);
    }

    private defautValue: IGateStatus = {
        passed: false,
        failed: false,
        lines: [],
        search: "",
    };

    // the field value is saved as json object
    parseFieldValue(stored: string): IGateStatus {
        // default
        let state = { ...this.defautValue };
        // if something was saved before, use it
        if (stored) {
            state = JSON.parse(stored);
        }

        // remove lines which do not exist anymore (might happen if config changes)
        if (this.config.lines && state.lines) {
            const lineIds = this.config.lines.map((lineDef) => lineDef.id);
            state.lines = state.lines.filter((line) => {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return lineIds.indexOf(line.id) != -1;
            });

            // add missing lines
            let existsIds = state.lines.map((lineValue) => {
                return lineValue.id;
            });
            let newLines = this.config.lines
                .map((lineDef): IGateStatusLine => {
                    return {
                        // create default lines
                        id: lineDef.id,
                        passed: false,
                        failed: false,
                        user: "",
                        comment: "",
                        date: "",
                        dateUser: "",
                    };
                })
                .filter((defaultLine) => {
                    // filter out lines which exist already
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return existsIds.indexOf(defaultLine.id) == -1;
                });
            state.lines = state.lines.concat(newLines);
        }
        // update overall status and text
        this.updateOverallStatusInternal(state);
        return state;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateOverallStatus() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.updateOverallStatusInternal(this.currentValue);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private updateOverallStatusInternal(status: IGateStatus) {
        let that = this;

        status.passed = false;
        // if one line is failed, the whole status is failed
        status.failed =
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            status.lines.filter((line) => {
                return line.failed;
            }).length > 0;
        if (!status.failed) {
            // if all lines passed, the status is passed
            status.passed =
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                status.lines.filter((line) => {
                    return line.passed;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                }).length == status.lines.length;
        }
        // build searchable string
        /*
            _passed_ (all lines passed)
            _failed_ (one line failed)
            _todo_ (needs some action)
            _pass_userId_ (a user passed it)
            _fail_userId_ (a user failed it)
            _todo_userId_ (a user needs to do something)
        */

        status.search = "";

        if (status.passed) {
            status.search += "_passed_";
        } else if (status.failed) {
            status.search += "_failed_";
        } else {
            status.search += "_todo_";
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let state of status.lines) {
            if (state.passed) {
                status.search += "_pass_" + state.user + "_";
            } else if (state.failed) {
                status.search += "_fail_" + state.user + "_";
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                for (let lineDef of that.config.lines) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (lineDef.id == state.id) {
                        for (let user of lineDef.users) {
                            status.search += "_todo_" + user + "_";
                        }
                    }
                }
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGateValue() {
        return this.currentValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setGateValue(gateValue: IGateStatus) {
        if (!gateValue) {
            gateValue = { ...this.defautValue };
        }
        this.currentValue = gateValue;
    }
}
