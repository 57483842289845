/// <reference types="matrixrequirements-type-declarations" />
import { ControlState, IItemGet, IGenericMap, IItem, globalMatrix, app, matrixSession } from "../../../globals";
import { ItemControl } from "../Components/ItemForm";
import { IBaseControlOptions } from "./BaseControl";
import { DocBaseImpl, ISignaturesInfo } from "./docBase.";
import { ml } from "./../../matrixlib";
import { XRPostProject_LaunchReport_CreateReportJobAck } from "../../../RestResult";
import { FieldHandlerFactory, mDHF } from "../../businesslogic/index";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type {
    IDocReviewOptions,
    IReviewData,
    IInlineComment,
    IInlineCommentRange,
    IAnnotationChange,
    ISignature,
    ISignatureChange,
};
export { DocReviewImpl, HTMLAnnotator };

interface IDocReviewOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: {
        hideReview?: boolean; // hide option to leave 'inline' review comments
        allowModifyOthers?: boolean; // set to true to allow normal users to modify other people's comments
    };
}

interface IReviewData {
    inlineComments: IInlineComment[];
}

interface IInlineComment {
    ranges: IInlineCommentRange[]; // positions
    quote: string; //highlighted text
    text: string; // comment
    id: string; // unique id
    changedBy: string;
    createdBy: string;
    changedAt: string;
    createdAt: string;
    highlights?: JQuery[];
}
interface IInlineCommentRange {
    start: string;
    startOffset: number;
    end: string;
    endOffset: number;
}

interface IAnnotationChange {
    action: string;
    value: IInlineComment;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.docReview = function (this: JQuery, options: IDocReviewOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new DocReviewImpl(this, options.fieldHandler as GenericFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);

    return this;
};

interface ISignature {
    orgid: string;
    userid: string;
    signDate: string;
    signDateCustomer?: string;
    signaturefileid: string;
}

interface ISignatureChange {
    action: string;
    value: string;
}

class DocReviewImpl extends DocBaseImpl {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IDocReviewOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private data: IReviewData;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private annotationRecording: IAnnotationChange[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private commentSortedBy: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private uiCtrl: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private reportBuffer: string;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IDocReviewOptions) {
        let that = this;
        this.annotationRecording = [];

        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            options.controlState == ControlState.Print ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            options.controlState == ControlState.Tooltip ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            options.controlState == ControlState.HistoryView ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            options.controlState == ControlState.Zen
        ) {
            return;
        }

        this._root.append(super.createHelp(options));
        this.uiCtrl = $("<div style='margin-top:6px' class='baseControl'>").appendTo(this._root);

        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                // item the item containing the rest of the information
            },
        };
        this.settings = <IDocReviewOptions>ml.JSON.mergeOptions(defaultOptions, options);

        // changes shall not overwrite other people's changes
        this.needsLatest = true;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.data = this.parseValue(this.settings.fieldValue);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let signatureStatus = DocBaseImpl.readSignatureInfo(this.settings.item);

        // show the comment dialog button
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.parameter.hideReview) {
            this.reviewButton(signatureStatus);
        }
    }

    // initialize options
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return this.annotationRecording && this.annotationRecording.length > 0;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync(currentItem?: IItemGet) {
        if (currentItem) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let currentValue = this.parseValue((<{ [key: number]: string }>currentItem)[this.settings.fieldId]);

            currentValue = this.applyRecordings(currentValue);

            return JSON.stringify(currentValue);
        } else {
            return JSON.stringify(this.data);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {
        this.reportBuffer = "";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    private applyRecordings(currentValue: IReviewData): IReviewData {
        $.each(this.annotationRecording, function (aidx, record) {
            // if there is already an comment with this id: delete it
            currentValue.inlineComments = currentValue.inlineComments.filter(function (r) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                return record.value.id != r.id;
            });
            switch (record.action) {
                // re(add) it
                case "add":
                case "update":
                    currentValue.inlineComments.push(record.value);
                    break;
            }
        });
        return currentValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private parseValue(fieldVal: string) {
        if (fieldVal) {
            return <IReviewData>JSON.parse(fieldVal);
        } else {
            return <IReviewData>{
                inlineComments: [],
            };
        }
    }

    /********************************************

     ******************************************** */
    // show button to allow user to comment on document
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected reviewButton(signatureStatus: ISignaturesInfo) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.hideReview) {
            return;
        }

        let canEdit = matrixSession.isEditor();
        let button = $(
            `<button class='btn btn-default docActionButton'>${
                canEdit ? "Review and add comments" : "Review comments"
            }</button>`,
        ).click(function () {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.getItemAsync(that.settings.id).done(function (item: IItemGet) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let mostRecentCommentField = (<IGenericMap>item)[that.settings.fieldId];
                let mostRecentComments: IInlineComment[] = [];
                if (mostRecentCommentField) {
                    let rd = <IReviewData>JSON.parse(mostRecentCommentField);
                    if (rd && rd.inlineComments) {
                        rd = that.applyRecordings(rd);
                        mostRecentComments = rd.inlineComments;
                    }
                }
                that.showReviewComments(canEdit, mostRecentComments);
            });
        });
        if ((this.data.inlineComments && this.data.inlineComments.length > 0) || canEdit) {
            this.uiCtrl.append(button);
            this.uiCtrl.append(
                "<span class='searchResult'>there are <span id='currentComments'>" +
                    (this.data.inlineComments ? this.data.inlineComments.length : 0) +
                    "</span> comments</span>",
            );
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    recordAnnotation(action: string, annotation: IInlineComment) {
        this.annotationRecording.push({ action: action, value: annotation });

        let rd = this.applyRecordings(ml.JSON.clone(this.data));
        this.showComments(rd.inlineComments);
    }

    // show dialog to allow user to comment on document
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected showReviewComments(canEdit: boolean, comments: IInlineComment[]) {
        let that = this;
        let reportProc: number;

        // make dialog UI: table left comments, right preview area
        let preview = $("<div id='previewDiv' class='reviewDlgBox '>");
        let commentBar = $("<div  class='reviewDlgBox commentBarBox'>");

        let sort = $("<div class='commentOrder'>").appendTo(commentBar).html("Sort by: ");
        $("<div id='commentBarDiv'>").appendTo(commentBar);
        let ds = $("<button class='sortButton nav-link btn-xs'>Creation Date<div class='commentSort' /></button>")
            .appendTo(sort)
            .click(function () {
                that.sortCommentsBy(1);
            });
        $("<button class='sortButton  nav-link btn-xs'>Creator<div class='commentSort' /></button>")
            .appendTo(sort)
            .click(function () {
                that.sortCommentsBy(2);
            });
        $("<button class='sortButton  nav-link btn-xs'>Location<div class='commentSort' /></button>")
            .appendTo(sort)
            .click(function () {
                that.sortCommentsBy(3);
            });
        let table = $("<table style='width:100%'>");
        table.append(
            $("<tr>")
                .append($("<td class='commentBar'>").append(commentBar))
                .append($("<td class='commentDoc'>").append(preview)),
        );
        preview.append(ml.UI.getSpinningWait("retrieving document..."));

        // show dialog
        app.dlgForm.html("").append(table);

        app.dlgForm.removeClass("dlg-v-scroll");
        app.dlgForm.addClass("dlg-no-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Add comments below",
                height: app.itemForm.height() * 0.9,
                width: $(document).width() * 0.9,
                modal: true,
                close: function () {
                    // dlg is gone
                },
                open: function () {
                    that.commentSortedBy = 1;
                    that.showComments(comments);
                    // start recording
                    let height = app.dlgForm.height();
                    preview.height(height - 1);
                    commentBar.height(height - 1);
                },
                resizeStop: function (event, ui) {
                    app.dlgForm.resizeDlgContent([]);
                    let height = app.dlgForm.height();
                    preview.height(height - 1);
                    commentBar.height(height - 1);
                },
                buttons: [
                    {
                        text: "OK",
                        class: "btnDoIt",
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        click: function () {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.settings.valueChanged();
                            let commentCount = that.data.inlineComments ? that.data.inlineComments.length : 0;
                            $.each(that.annotationRecording, function (idx, ar) {
                                switch (ar.action) {
                                    case "add":
                                        commentCount++;
                                        break;
                                    case "delete":
                                        commentCount--;
                                        break;
                                }
                            });
                            $("#currentComments").html("" + commentCount);
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([], false);
        if (this.reportBuffer) {
            // used cached html
            that.showReportWithComments(preview, comments, canEdit);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.startCreateDocumentAsync(this.settings.item.id, { format: "html" }).done(function (
                result: XRPostProject_LaunchReport_CreateReportJobAck,
            ) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                mDHF.loadDocument(result.jobId, function (htmlDOM: any) {
                    that.reportBuffer = htmlDOM;
                    that.showReportWithComments(preview, comments, canEdit);
                });
            });
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showReportWithComments(preview: JQuery, comments: IInlineComment[], canEdit: boolean) {
        preview.html("");
        preview.append(this.reportBuffer).annotator({
            readOnly: !canEdit,
        });
        preview.data("annotator");

        preview.annotator("loadAnnotations", comments);
        preview.annotator("addPlugin", "IO", true);
        preview.data("instance", this);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showComments(comments: IInlineComment[]) {
        if (!comments || comments.length === 0) {
            $("#commentBarDiv").html("<span class='searchResult'>no comments</span>");
            return;
        }
        let preview = $("#previewDiv");
        $("#commentBarDiv").html("");
        $.each(comments, function (idx, comment) {
            let cui = $("<div class='commentUI'>").appendTo($("#commentBarDiv"));
            let tr = $("<div class='commenttr'>").appendTo(cui);
            if (comment.changedBy === matrixSession.getUser()) {
                tr.addClass("myComment");
            }
            $("<div class='commentUser'>").appendTo(tr).html(comment.changedBy);
            $("<div class='commentDate'>")
                .appendTo(tr)
                .html(ml.UI.DateTime.renderHumanDate(new Date(comment.changedAt), false));
            $("<div class='commentText'>").appendTo(cui).text(comment.text);
            cui.data("date", comment.changedAt);
            cui.data("id", comment.id);
            cui.data("user", comment.changedBy);
            cui.click(function (event: JQueryMouseEventObject) {
                $(".selectedAnnotation").removeClass("selectedAnnotation");
                $(".selectedComment").removeClass("selectedComment");
                let box = $(event.delegateTarget).addClass("selectedComment");
                let anno = $("[data-annotation-id='" + box.data("id") + "']");
                anno.addClass("selectedAnnotation");
                // pos absolut in box
                let offset = anno.offset().top - preview.offset().top;
                let visibleTop = preview.scrollTop();

                if (offset < 0) {
                    preview.scrollTop(visibleTop + offset);
                } else if (offset > preview.height() - 20) {
                    preview.scrollTop(visibleTop + (offset - preview.height() + 20));
                }
            });
        });
        this.sortCommentsBy(3);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public sortCommentsBy(sortBy: number) {
        let that = this;
        if (sortBy) {
            this.commentSortedBy = this.commentSortedBy === sortBy ? -sortBy : sortBy;
        }
        $(".commentSortDown").removeClass("commentSortDown");
        $(".commentSortUp").removeClass("commentSortUp");

        $(".commentSort", $(".sortButton")[Math.abs(this.commentSortedBy) - 1]).addClass(
            this.commentSortedBy > 0 ? "commentSortDown" : "commentSortUp",
        );
        $(".commentUI")
            .toArray()
            .sort(function (a, b) {
                let fv: string | number = "";
                let sv: string | number = "";
                let swap = false;

                switch (that.commentSortedBy) {
                    case 1:
                        fv = $(a).data("date");
                        sv = $(b).data("date");
                        swap = fv > sv;
                        break;
                    case -1:
                        fv = $(a).data("date");
                        sv = $(b).data("date");
                        swap = fv < sv;
                        break;
                    case 2:
                        fv = $(a).data("user");
                        sv = $(b).data("user");
                        swap = fv > sv;
                        break;
                    case -2:
                        fv = $(a).data("user");
                        sv = $(b).data("user");
                        swap = fv < sv;
                        break;
                    case 3: {
                        const f = $("[data-annotation-id='" + $(a).data("id") + "']").offset();
                        const s = $("[data-annotation-id='" + $(b).data("id") + "']").offset();
                        swap = f.top > s.top || (f.top === s.top && f.left > s.left);
                        break;
                    }
                    case -3: {
                        const f = $("[data-annotation-id='" + $(a).data("id") + "']").offset();
                        const s = $("[data-annotation-id='" + $(b).data("id") + "']").offset();
                        swap = f.top < s.top || (f.top === s.top && f.left < s.left);
                    }
                }
                // swap in UI
                if (swap) {
                    // create marker element and insert it where obj1 is
                    let temp = document.createElement("div");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    a.parentNode.insertBefore(temp, a);

                    // move obj1 to right before obj2
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    b.parentNode.insertBefore(a, b);

                    // move obj2 to right before where obj1 used to be
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    temp.parentNode.insertBefore(b, temp);

                    // remove temporary marker node
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    temp.parentNode.removeChild(temp);
                }
                // sort array
                return swap ? 1 : -1;
            });
    }
}

// allows user to add annotations / linked to some html
class HTMLAnnotator {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private annotationsBefore: IReviewData; // date before editing starts
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private annotationsAfter: IReviewData; // date before editing starts
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private commentSortedBy: number;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor() {}

    // initialize options
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    hasChanged() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.annotationsAfter && JSON.stringify(this.annotationsAfter) != JSON.stringify(this.annotationsBefore);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        return JSON.stringify(this.annotationsAfter);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    /**
     * this function merges annotations done locally with the changes happened in parallel on the server.
     *
     * @param serverVersion these are the annotations last saved (by another user)
     * @param localBefore these are the ones which existed locally, before starting to edit
     * @param localAfter  these are the ones which existed locally when user saves
     * @returns
     */
    static mergeAnnotation(serverVersion: string, localBefore: string, localAfter: string): string {
        if (!serverVersion) {
            return localAfter;
        } // currently no annotations on the server, so all local ones are to be taken
        if (!localAfter) {
            return serverVersion;
        } // locally were were no changes and there are no changes (so nothing got added or deleted) -> the server is still good
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (localAfter == localBefore) {
            return serverVersion;
        } // nothing changed locally so the server is still good

        let server: IInlineComment[] = (<IReviewData>JSON.parse(serverVersion)).inlineComments;
        let after: IInlineComment[] = (<IReviewData>JSON.parse(localAfter)).inlineComments;

        if (localBefore) {
            // we need to update the annotations coming from the server:

            let before: IInlineComment[] = (<IReviewData>JSON.parse(localBefore)).inlineComments;
            let beforeId: string[] = before.map(function (comment) {
                return comment.id;
            });
            let afterId: string[] = after.map(function (comment) {
                return comment.id;
            });

            // update everything which CHANGED locally
            for (let comment of server) {
                if (beforeId.includes(comment.id) && afterId.includes(comment.id)) {
                    // possible it changed
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    let b = before.filter((c) => c.id == comment.id)[0];
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    let a = after.filter((c) => c.id == comment.id)[0];
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (JSON.stringify(a) != JSON.stringify(b)) {
                        // it did change
                        comment.changedAt = a.changedAt;
                        comment.changedBy = a.changedBy;
                        comment.highlights = a.highlights;
                        comment.quote = a.quote;
                        comment.ranges = a.ranges;
                        comment.text = a.text;
                    }
                }
            }

            // now  remove all annotations from the server if they were removed locally
            server = server.filter(function (comment) {
                // keep it IF it did not exist before or if it still exists
                return !beforeId.includes(comment.id) || afterId.includes(comment.id);
            });

            // now add all annotations which were added locally
            let added = after.filter((comment) => !beforeId.includes(comment.id));
            if (added.length) {
                server = server.concat(added);
            }
        } else {
            // add all comments were created locally so they can just be added
            server = server.concat(after);
        }
        return JSON.stringify({ inlineComments: server });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    static hasAnnotations(reviewComments: string) {
        if (!reviewComments) {
            return false;
        }
        return (<IReviewData>JSON.parse(reviewComments)).inlineComments.length > 0;
    }

    // show dialog to allow user to comment on document
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showReviewDialog(
        canEdit: boolean,
        itemId: string,
        version: number,
        data: IItem,
        reviewComments: string,
        isSuperUser: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onUpdate: Function,
    ) {
        let that = this;

        let reviewItem = $("<div class=''>");

        this.annotationsBefore = reviewComments ? <IReviewData>JSON.parse(reviewComments) : { inlineComments: [] };
        this.annotationsAfter = reviewComments ? <IReviewData>JSON.parse(reviewComments) : { inlineComments: [] };

        // make dialog UI: table left comments, right preview area
        let preview = $("<div id='previewDiv' class='reviewDlgBox'>").append(reviewItem);

        let commentBar = $("<div  class='reviewDlgBox commentBarBox'>");
        let sort = $("<div  class='commentOrder'>").appendTo(commentBar).html("Sort by: ");
        $("<div id='commentBarDiv'>").appendTo(commentBar);
        $("<button class='sortButton btn btn-default btn-xs'>Creation Date<div class='commentSort' /></button>")
            .appendTo(sort)
            .click(function () {
                that.sortCommentsBy(1);
            });
        $("<button class='sortButton btn btn-default btn-xs'>Creator<div class='commentSort' /></button>")
            .appendTo(sort)
            .click(function () {
                that.sortCommentsBy(2);
            });
        $("<button class='sortButton btn btn-default btn-xs'>Location<div class='commentSort' /></button>")
            .appendTo(sort)
            .click(function () {
                that.sortCommentsBy(3);
            });
        let table = $("<table style='width:100%'>");
        table.append(
            $("<tr>")
                .append($("<td class='commentDoc'>").append(preview))
                .append($("<td class='commentBar'>").append(commentBar)),
        );

        // show dialog

        let dlg = $("<div>").appendTo($("body"));

        ml.UI.showDialogDes({
            container: dlg,
            content: table,
            title: "Add/Edit Comments",
            noXButton: true,
            noCloseOnEscape: true,
            autoResize: true,
            maximizeButton: true,
            buttons: [
                {
                    text: "OK",
                    class: "btnDoIt",
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    click: function () {
                        onUpdate();
                        dlg.dialog("close");
                    },
                },
            ],
            onOpen: async () => {
                // start drawing item
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let itemForm = new ItemControl(<any>{
                    control: reviewItem,
                    controlState: ControlState.Review,
                    isHistory: version,
                    type: ml.Item.parseRef(itemId).type,
                    item: data,
                    isItem: true,
                    parameter: {
                        reviewMode: true,
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    changed: function () {},
                });
                await itemForm.load();

                that.commentSortedBy = 1;
                that.showCommentList(that.annotationsBefore.inlineComments);
                $(".panel-body-v-scroll", reviewItem).css("text-align", "initial");
                $(".panel-body-v-scroll", reviewItem).css("overflow-x", "auto");
                // start recording
                let height = dlg.height();
                preview.height(height - 1);
                commentBar.height(height - 1 - (commentBar.outerHeight() - commentBar.height())); // take padding into account
                // show annotations
                window.setTimeout(function () {
                    // give it some time to redraw / resize tables (itemForm resize, 299)
                    that.showHTMLWithComments(reviewItem, that.annotationsBefore.inlineComments, canEdit, isSuperUser);
                    // fix layout
                    $(".itemTitleBarNoToolsNoEdit", reviewItem).css("height", "");
                    $(".itemTitle", reviewItem).removeClass("pull-left").css("max-width", "100%");
                }, 301);
            },
            onResize: () => {
                dlg.resizeDlgContent([]);
                let height = dlg.height();
                preview.height(height - 1);
                commentBar.height(height - 1 - (commentBar.outerHeight() - commentBar.height())); // take padding into account
            },
            onClose: () => {
                dlg.remove();
            },
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected recordAnnotation(action: string, annotation: IInlineComment) {
        // in remove the previous value for the annotation with this id
        this.annotationsAfter.inlineComments = this.annotationsAfter.inlineComments.filter(function (r) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return annotation.id != r.id;
        });
        // unless the action is a delete re-add it
        switch (action) {
            // re(add) it
            case "add":
            case "update":
                this.annotationsAfter.inlineComments.push(annotation);
                break;
        }
        // update ui
        this.showCommentList(this.annotationsAfter.inlineComments);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showHTMLWithComments(preview: JQuery, comments: IInlineComment[], canEdit: boolean, isSuperUser: boolean) {
        let that = this;

        preview.annotator({
            readOnly: !canEdit,
        });
        preview.data("annotator");

        preview.annotator("loadAnnotations", comments);
        preview.annotator("addPlugin", "IO", isSuperUser);
        preview.data("instance", this);

        // sort comment by place in UI (wait a bit so that UI is ready)
        window.setTimeout(function () {
            that.sortCommentsBy(-3);
        }, 1000);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showCommentList(comments: IInlineComment[]) {
        if (!comments || comments.length === 0) {
            $("#commentBarDiv").html("<span class='searchResult'>no comments</span>");
            return;
        }
        let preview = $("#previewDiv");
        $("#commentBarDiv").html("");
        $.each(comments, function (idx, comment) {
            let cui = $("<div class='commentUI'>").appendTo($("#commentBarDiv"));
            let tr = $("<div class='commenttr'>").appendTo(cui);
            if (comment.changedBy === matrixSession.getUser()) {
                tr.addClass("myComment");
            }
            $("<div class='commentUser'>").appendTo(tr).html(comment.changedBy);
            $("<div class='commentDate'>")
                .appendTo(tr)
                .html(ml.UI.DateTime.renderHumanDate(new Date(comment.changedAt), false));
            $("<div class='commentText'>").appendTo(cui).text(comment.text);
            cui.data("date", comment.changedAt);
            cui.data("id", comment.id);
            cui.data("user", comment.changedBy);
            cui.click(function (event: JQueryMouseEventObject) {
                $(".selectedAnnotation").removeClass("selectedAnnotation");
                $(".selectedComment").removeClass("selectedComment");
                let box = $(event.delegateTarget).addClass("selectedComment");
                let anno = $("[data-annotation-id='" + box.data("id") + "']");
                anno.addClass("selectedAnnotation");
                // pos absolut in box
                let offset = anno.offset().top - preview.offset().top;
                let visibleTop = preview.scrollTop();

                if (offset < 0) {
                    preview.scrollTop(visibleTop + offset);
                } else if (offset > preview.height() - 20) {
                    preview.scrollTop(visibleTop + (offset - preview.height() + 20));
                }
            });
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private sortCommentsBy(sortBy: number) {
        let that = this;
        if (sortBy) {
            this.commentSortedBy = this.commentSortedBy === sortBy ? -sortBy : sortBy;
        }
        $(".commentSortDown").removeClass("commentSortDown");
        $(".commentSortUp").removeClass("commentSortUp");

        $(".commentSort", $(".sortButton")[Math.abs(this.commentSortedBy) - 1]).addClass(
            this.commentSortedBy > 0 ? "commentSortDown" : "commentSortUp",
        );
        let comments = $(".commentUI").toArray();
        comments.sort(function (a, b) {
            let fv: string | number = "";
            let sv: string | number = "";
            let swap = false;
            switch (that.commentSortedBy) {
                case 1:
                    fv = $(a).data("date");
                    sv = $(b).data("date");
                    swap = fv > sv;

                    break;
                case -1:
                    fv = $(a).data("date");
                    sv = $(b).data("date");
                    swap = fv < sv;

                    break;
                case 2:
                    fv = $(a).data("user");
                    sv = $(b).data("user");
                    swap = fv > sv;

                    break;
                case -2:
                    fv = $(a).data("user");
                    sv = $(b).data("user");
                    swap = fv < sv;

                    break;
                case 3: {
                    const f = $("[data-annotation-id='" + $(a).data("id") + "']").offset();
                    const s = $("[data-annotation-id='" + $(b).data("id") + "']").offset();

                    swap = f.top > s.top || (f.top === s.top && f.left > s.left);

                    break;
                }
                case -3: {
                    const f = $("[data-annotation-id='" + $(a).data("id") + "']").offset();
                    const s = $("[data-annotation-id='" + $(b).data("id") + "']").offset();
                    swap = f.top < s.top || (f.top === s.top && f.left < s.left);
                }
            }

            // sort array
            return swap ? -1 : 1;
        });
        // now repaint the UI, sorted
        //$("#commentBarDiv").html("");
        $.each(comments, function (idx, commentBox) {
            $("#commentBarDiv").append(commentBox);
        });
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
Annotator.Plugin.IO = function (this: any, element: any, options: any) {
    Annotator.Plugin.superUser = <boolean>options;
    // eslint-disable-next-line prefer-rest-params
    Annotator.Plugin.apply(this, arguments);
};

function getAnnotation(obj: IInlineComment): IInlineComment {
    let cp = <IInlineComment>ml.JSON.clone(obj);
    cp.highlights = [];
    return <IInlineComment>cp;
}

$.extend(Annotator.Plugin.IO.prototype, new Annotator.Plugin(), {
    events: {},
    options: {},
    pluginInit: function (this: { annotator: Annotator.AnnotatorObj }) {
        let that = this;

        this.annotator
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .subscribe("annotationEditorShown", function (this: Node, editor: any) {
                let leftBox = $(this).offset().left;
                let widthBox = $(this).width();

                let editorBox = $("#" + editor.fields[0].id);
                let leftEditor = editorBox.offset().left;
                let widthEditor = editorBox.width();

                let anchorX = leftEditor + 20;

                let anchorRight = editorBox.closest(".annotator-editor").hasClass("annotator-invert-x");
                if (anchorRight) {
                    anchorX = leftEditor + widthEditor - 20;
                }
                // check if box needs to be flipped
                if (anchorX - leftBox < widthBox / 2) {
                    // box is in left half and should open to right
                    editorBox.closest(".annotator-editor").removeClass("annotator-invert-x");
                    editorBox.css("max-width", widthBox + leftBox - anchorX + "px");
                } else {
                    // right half: open to left
                    editorBox.closest(".annotator-editor").addClass("annotator-invert-x");
                    editorBox.css("max-width", anchorX - leftBox + "px");
                }
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .subscribe("annotationViewerShown", function (this: Node, annotation: any) {
                let widthPanel = $(this).width();

                let viewerPos = $(annotation.element).position().left;
                let viewerWidth = $(".annotator-widget", $(annotation.element)).width();
                if (viewerPos - viewerWidth < 10) {
                    // make sure the viewer opens to the right
                    $(annotation.element).removeClass("annotator-invert-x");
                }
                if (widthPanel - viewerWidth - 10 < viewerPos) {
                    // make sure the viewer opens to the left
                    $(annotation.element).addClass("annotator-invert-x");
                }
            })
            .subscribe("annotationCreated", function (this: Node, annotation: IInlineComment) {
                annotation.id = new Date().getTime().toString();
                annotation.createdBy = annotation.changedBy = matrixSession.getUser();
                annotation.createdAt = annotation.changedAt = new Date().toISOString();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $(annotation.highlights[0]).attr("data-annotation-id", annotation.id);
                (<DocReviewImpl>$(this).data("instance")).recordAnnotation("add", getAnnotation(annotation));
            })
            .subscribe("annotationUpdated", function (this: Node, annotation: IInlineComment) {
                annotation.changedBy = matrixSession.getUser();
                annotation.changedAt = new Date().toISOString();
                (<DocReviewImpl>$(this).data("instance")).recordAnnotation("update", getAnnotation(annotation));
            })
            .subscribe("annotationDeleted", function (this: Node, annotation: IInlineComment) {
                (<DocReviewImpl>$(this).data("instance")).recordAnnotation("delete", getAnnotation(annotation));
            }) // wait until all annotations are shown and sort the bar on the right
            .viewer.addField({
                load: function (
                    field: Node,
                    annotation: IInlineComment,
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    controls: { hideEdit: () => any; hideDelete: () => any },
                ) {
                    $(field).html(annotation.createdBy).addClass("annotator-user");
                    if (controls) {
                        if (!Annotator.Plugin.superUser && annotation.createdBy !== matrixSession.getUser()) {
                            controls.hideEdit();
                            return controls.hideDelete();
                        }
                    }
                },
            });
    },
});
