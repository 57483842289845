import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap } from "../../../../globals";
import { XRFieldTypeAnnotatedParamJson } from "../../ItemConfiguration";

export abstract class GenericDocAbstractFieldHandler implements IDocFieldHandler {
    private sectionType: string;
    dhfFieldConfig: IAnyMap;

    constructor(sectionType: string, config: XRFieldTypeAnnotatedParamJson, fieldValue: string | undefined) {
        if (!config) {
            config = this.getDefaultConfig();
        }
        this.dhfFieldConfig = config ?? {};
        this.sectionType = sectionType;
        this.rawValue = fieldValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }

    rawValue: string | undefined;

    getData(): string | undefined {
        return this.rawValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.rawValue = value;
    }

    getFieldType(): string {
        return this.sectionType;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        this.rawValue = serializedFieldData;
    }

    abstract getXmlValue(): string;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    abstract getDefaultConfig(): any;

    protected name = "";
    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
}
