import { IFileUploadProgress, IFileParam } from "../businesslogic/index";
import { ml } from "./../matrixlib";
import { globalMatrix, restConnection } from "../../globals";
import { IFileTools, IUploadedFileInfo, IBlockingProgressUITask } from "./MatrixLibInterfaces";

export { FileTools };

class FileTools implements IFileTools {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private uploadInfo: IUploadedFileInfo[];

    private uploadFilesAsync(files: FileList | File[], idx: number): JQueryDeferred<IUploadedFileInfo[]> {
        let that = this;

        let res: JQueryDeferred<IUploadedFileInfo[]> = $.Deferred();
        if (!files || files.length <= idx) {
            res.resolve(this.uploadInfo);
            return res;
        }

        restConnection
            .uploadFileProjectAsync(files[idx], function (p: IFileUploadProgress) {
                let done = p.position || p.loaded;
                let total = p.totalSize || p.total;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.UI.BlockingProgress.SetProgress(idx, (99 * done) / total);
            })
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (result: { fileId: string; key: string }) {
                ml.UI.BlockingProgress.SetProgress(idx, 100);
                that.uploadInfo.push({
                    fileName: files[idx].name,
                    fileId: result.fileId + "?key=" + result.key,
                    uploaded: true,
                });
                that.uploadFilesAsync(files, idx + 1)
                    .done(function (fini) {
                        res.resolve(fini);
                    })
                    .fail(function (fini) {
                        res.reject(fini);
                    });
                ml.UI.BlockingProgress.SetProgress(idx, 100);
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function (error: any) {
                ml.UI.BlockingProgress.SetProgressError(idx, "Failed to upload file!");
                that.uploadInfo.push({
                    fileName: files[idx].name,
                    fileId: error,
                    uploaded: false,
                });
                res.reject(that.uploadInfo);
            });
        return res;
    }

    UploadFilesAsync(files: FileList | File[]): JQueryDeferred<IUploadedFileInfo[]> {
        let tasks: IBlockingProgressUITask[] = [];
        $.each(files, function (idx, file) {
            tasks.push({ name: file.name });
        });
        ml.UI.BlockingProgress.Init(tasks);
        this.uploadInfo = [];
        return this.uploadFilesAsync(files, 0);
    }

    UploadFileAsync(file: File): JQueryDeferred<IUploadedFileInfo[]> {
        let tasks: IBlockingProgressUITask[] = [];

        tasks.push({ name: file.name });
        ml.UI.BlockingProgress.Init(tasks);
        this.uploadInfo = [];
        return this.uploadFilesAsync([file], 0);
    }

    convertXLSXAsync(file: IFileParam): JQueryDeferred<string> {
        let that = this;

        let res: JQueryDeferred<string> = $.Deferred();

        let tasks: IBlockingProgressUITask[] = [];
        tasks.push({ name: "converting excel ..." });
        ml.UI.BlockingProgress.Init(tasks);

        restConnection
            .convertExcelProjectAsync(file, function (p: IFileUploadProgress) {
                let done = p.position || p.loaded;
                let total = p.totalSize || p.total;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.UI.BlockingProgress.SetProgress(0, (99 * done) / total);
            })
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (result: string) {
                ml.UI.BlockingProgress.SetProgress(0, 100);
                res.resolve(result);
            })
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .fail(function (error: any) {
                ml.UI.BlockingProgress.SetProgressError(0, "Failed to upload file!");
                res.reject("");
            });
        return res;
    }
}
