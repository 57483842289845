import { ml } from "../../matrixlib";

export { StrongPass };

interface PasswordOptions {
    minChar?: number; // too short while less than this

    passIndex?: number; // Weak

    // output verdicts, colours and bar %
    label?: string;
    verdicts?: string[];
    colors?: string[];
    width?: string[];
    // tweak scores here
    scores?: number[];
    passFail?: (result: boolean) => void;
    other?: string;
}

class StrongPass {
    private options: PasswordOptions;
    private bannedPasswords: string[];
    private checks: { re: RegExp; score: number }[];
    private element: JQuery;
    private resultBox: JQuery;

    constructor(element: string, param: PasswordOptions) {
        let defaultOptions: PasswordOptions = {
            minChar: 6, // too short while less than this

            passIndex: 3, // Weak

            // output verdicts, colours and bar %
            label: "Password strength: ",
            verdicts: [
                "Error: Too Short",
                "Error: far too weak",
                "Error: very weak",
                "Error: still very weak",
                "Error: too weak",
                "Error: still too weak",
            ],
            colors: ["#ccc", "#500", "#800", "#f60", "#050", "#0f0"],
            width: ["0%", "10%", "30%", "60%", "80%", "100%"],
            // tweak scores here
            scores: [10, 15, 25, 45],
            passFail: (result: boolean) => {},
            other: "",
        };

        this.bannedPasswords = [
            // see study here: http://smrt.io/JlNfrH
            "123456",
            "12345",
            "123456789",
            "password",
            "iloveyou",
            "princess",
            "rockyou",
            "1234567",
            "12345678",
            "abc123",
            "nicole",
            "daniel",
            "babygirl",
            "monkey",
            "jessica",
            "lovely",
            "michael",
            "ashley",
            "654321",
            "qwerty",
            "password1",
            "welcome",
            "welcome1",
            "password2",
            "password01",
            "password3",
            "p@ssw0rd",
            "passw0rd",
            "password4",
            "password123",
            "summer09",
            "password6",
            "password7",
            "password9",
            "password8",
            "welcome2",
            "welcome01",
            "winter12",
            "spring2012",
            "summer12",
            "summer2012",
        ];

        this.checks = [
            /* alphaLower */ {
                re: /[a-z]/,
                score: 1,
            },
            /* alphaUpper */ {
                re: /[A-Z]/,
                score: 5,
            },
            /* mixture of upper and lowercase */ {
                re: /([a-z].*[A-Z])|([A-Z].*[a-z])/,
                score: 2,
            },
            /* threeNumbers */ {
                re: /(.*[0-9].*[0-9].*[0-9])/,
                score: 7,
            },
            /* special chars */ {
                re: /.[!@#$%^&*?_~]/,
                score: 5,
            },
            /* multiple special chars */ {
                re: /(.*[!@#$%^&*?_~].*[!@#$%^&*?_~])/,
                score: 7,
            },
            /* all together now, does it look nice? */ {
                re: /([a-zA-Z0-9].*[!@#$%^&*?_~])|([!@#$%^&*?_~].*[a-zA-Z0-9])/,
                score: 3,
            },
            /* password of a single char sucks */ {
                re: /(.)\1+$/,
                score: 2,
            },
        ];

        this.resultBox = $("<div>");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        this.options = <any>ml.JSON.mergeOptions(defaultOptions, param);
        this.element = $(element);
        this.createBox();
        this.attachEvents();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setPassIndex(newIndex: number) {
        this.options.passIndex = newIndex;
        this.runPassword();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isOK() {
        return this.runPassword();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private attachEvents() {
        // only attach events once
        this.element.keyup(() => this.runPassword());
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createBox() {
        this.element.parent().append(this.resultBox);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private runPassword() {
        let password: string = this.element.val();

        this.resultBox.html("");
        let score = this.checkPassword(password);
        let index = 0;
        let s: number[] = ml.JSON.clone(this.options.scores);
        let verdict: string;

        if (this.bannedPasswords.indexOf(password.toLowerCase()) !== -1) {
            this.resultBox.append("Error: banned password");
            this.resultBox.css("color", "red");
            this.resultBox.show();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.options.passFail(false);
            return false;
        } else if (password === "") {
            this.resultBox.append("you must enter a password");
            this.resultBox.css("color", "red");
            this.resultBox.show();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.options.passFail(false);
            return false;
        } else if (password !== "" && password.indexOf(" ") !== -1) {
            this.resultBox.append("spaces are not allowed in password");
            this.resultBox.css("color", "red");
            this.resultBox.show();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.options.passFail(false);
            return false;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (password !== "" && this.options.other !== "" && $(this.options.other).val() === password) {
            this.resultBox.append("login and signature password must be different");
            this.resultBox.css("color", "red");
            this.resultBox.show();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.options.passFail(false);
            return false;
        } else {
            if (score < 0 && score > -199) {
                index = 0;
            } else {
                s.push(score);
                s.sort(function (a, b) {
                    return a - b;
                });
                index = s.indexOf(score) + 1;
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let passed = index >= this.options.passIndex;
            if (passed) {
                this.resultBox.hide();
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                verdict = this.options.verdicts[index] || this.options.verdicts[this.options.verdicts.length - 1];
                this.resultBox.append("Score " + score + " verdict " + verdict);
                this.resultBox.css("color", passed ? "black" : "red");
                this.resultBox.show();
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.options.passFail(passed);
            return passed;
        }
    }

    private checkPassword(pass: string): number {
        let score = 0;
        let minChar = this.options.minChar;
        let len = pass.length;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let diff = len - minChar;

        (diff < 0 && (score -= 100)) ||
            (diff >= 5 && (score += 18)) ||
            (diff >= 3 && (score += 12)) ||
            (diff === 2 && (score += 6));

        $.each(this.checks, function (idx, check) {
            pass.match(check.re) && (score += check.score);
        });

        // bonus for length per char
        score && (score += len);
        return score;
    }
}
