import {
    ITableReviewData,
    ITableReviewItem,
    ReviewControlColumns,
} from "../../../core/client/plugins/ScheduleReviewDefines";
import { diffHtml } from "./diffHtml";

export function diffReviewControl(
    // Partial represents the state when reviewControl is not fully setup
    oldData: Partial<ITableReviewData>,
    newData: Partial<ITableReviewData>,
    categories: string[],
): ITableReviewData {
    const oldDataClone = structuredClone(oldData);
    const preparedOldData: ITableReviewData = { ...oldDataClone, reviewtable: oldDataClone.reviewtable || [] };

    const newDataClone = structuredClone(newData);
    const resultData: ITableReviewData = { ...newDataClone, reviewtable: newDataClone.reviewtable || [] };

    let deletedColumns: string[] = [];
    let addedColumns: string[] = [];

    // go through all oldData columns that do not exist in newData and handle deleted columns
    if (preparedOldData.reviewtable.length > 0 && resultData.reviewtable.length > 0) {
        const oldDataColumns = Object.keys(preparedOldData.reviewtable[0]);
        const newDataColumns = Object.keys(resultData.reviewtable[0]);

        deletedColumns = oldDataColumns.filter((column) => !newDataColumns.includes(column));
        addedColumns = newDataColumns.filter((column) => !oldDataColumns.includes(column));
    }

    // go through all existing newData rows and handle added and updated items
    resultData.reviewtable = resultData.reviewtable.map((reviewTableItem) => {
        const oldReviewTableItem = getReviewItemById(preparedOldData, extractReviewItemId(reviewTableItem.reviewitem));
        const diffedItem: ITableReviewItem = { _version: "", commentlog: "", reviewitem: "" };

        Object.keys(reviewTableItem)
            .concat(deletedColumns)
            .forEach((key) => {
                if (key === ReviewControlColumns.COL_VERSION) {
                    diffedItem[key] = reviewTableItem[key];
                    return;
                }

                diffedItem[deletedColumns.includes(key) ? `<del>${key}</del>` : key] = diffHtml(
                    oldReviewTableItem?.[key] || "",
                    reviewTableItem?.[key] || "",
                    categories,
                    true,
                );
            });

        return diffedItem;
    });

    // go through all oldData rows that do not exist in newData and handle deleted items
    preparedOldData.reviewtable.forEach((reviewTableItem, index) => {
        const newReviewTableItem = getReviewItemById(resultData, extractReviewItemId(reviewTableItem.reviewitem));

        if (!newReviewTableItem) {
            const diffedItem: ITableReviewItem = { _version: "", commentlog: "", reviewitem: "" };

            Object.keys(reviewTableItem)
                .concat(addedColumns)
                .forEach((key) => {
                    if (key === ReviewControlColumns.COL_VERSION) {
                        diffedItem[key] = reviewTableItem[key];
                        return;
                    }

                    diffedItem[deletedColumns.includes(key) ? `<del>${key}</del>` : key] = diffHtml(
                        reviewTableItem[key] || "",
                        "",
                        categories,
                        true,
                    );
                });

            resultData.reviewtable.splice(index, 0, diffedItem);
        }
    });

    return resultData;
}

export function extractReviewItemId(reviewItemString: string): string {
    return reviewItemString.split("!")[0] || "";
}

export function getReviewItemById(data: ITableReviewData, id: string): ITableReviewItem | null {
    const item = data.reviewtable.find((reviewItem) => extractReviewItemId(reviewItem.reviewitem) === id);

    if (item) {
        return item;
    }

    return null;
}
