import { IDocFieldHandler } from "./IDocFieldHandler";
import { SectionDescriptions } from "./SectionDescriptions";
import { IAnyMap } from "../../../../globals";
import { IItemListOptions } from "./ItemListDocFieldHandler";
import { ItemSelectionAbstractDocFieldHandler } from "./ItemSelectionAbstractDocFieldHandler";

export class ItemRefDocFieldHandler extends ItemSelectionAbstractDocFieldHandler implements IDocFieldHandler {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getDefaultConfig() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.fieldType == SectionDescriptions.section_itemlist) {
            return {
                recursive: true,
                tree: true,
                refdocs: false,
                hideEmptyFolders: false,
                includeInToc: false,
                sortedList: false,
                showParentFolders: false,
            };
        }

        return {
            recursive: false,
            tree: false,
            refdocs: false,
            hideEmptyFolders: false,
            includeInToc: false,
            sortedList: true,
            showParentFolders: false,
        };
    }

    constructor(type: string, ctrlConfig: IAnyMap, fieldValue: string | undefined) {
        super(type, ctrlConfig, fieldValue);
        this.initData(fieldValue);
    }

    getXmlValue(): string {
        let reflist = this.getItems();

        for (let ref of reflist) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let refOption = <IItemListOptions>(<any>ref);
            refOption["recursive"] = this.dhfFieldConfig.recursive;
            refOption["tree"] = this.dhfFieldConfig.tree;
            refOption["hideEmptyFolders"] = this.dhfFieldConfig.hideEmptyFolders;
            refOption["showParentFolders"] = this.dhfFieldConfig.showParentFolders;
            refOption["includeInToc"] = this.dhfFieldConfig.includeInToc;
            refOption["sortedList"] = this.dhfFieldConfig.sortedList;
        }

        return JSON.stringify(reflist);
    }
}
