import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions } from "./BaseControl";
import { DocBaseImpl } from "./docBase.";
import { ml } from "./../../matrixlib";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IDocSourceRefOptions };
export { SourceRefImpl };

interface IDocSourceRefOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: {
        readonly?: boolean;
    };
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.sourceRef = function (this: JQuery, options: IDocSourceRefOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_sourceref,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new SourceRefImpl(this, options.fieldHandler as GenericFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class SourceRefImpl extends DocBaseImpl {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IDocSourceRefOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private originalValue: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private newValue: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastValueChanged: number;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: IDocSourceRefOptions) {
        let that = this;

        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false,
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        this.originalValue = this.settings.fieldValue ? this.settings.fieldValue : "";
        this.newValue = this.originalValue;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.newValue && this.settings.parameter.readonly) {
            return;
        }

        let link = $("<a style='padding: 8px;' class='highLink'>").click(function (event: JQueryEventObject) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (that.newValue && that.newValue.split("/").length == 2) {
                let win = window.open(globalMatrix.matrixBaseUrl + "/" + that.newValue, "_blank");
            }
            event.preventDefault();
            return false;
        });
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.newValue && this.newValue.split("/").length == 2) {
            link.html(this.newValue);
        }

        let help = this._root.append(super.createHelp(this.settings).append(link));

        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            options.controlState == ControlState.Print ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            options.controlState == ControlState.Tooltip ||
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.parameter.readonly
        ) {
            return;
        }

        let ref = $("<div class='baseControl'>").appendTo(this._root);

        let editor = $("<input autocomplete='off' class='lineInput form-control' type='text'>").appendTo(ref);
        editor.val(that.newValue);

        editor.on("mouseup keyup mouseout", function () {
            clearTimeout(that.lastValueChanged);

            that.lastValueChanged = window.setTimeout(function () {
                that.newValue = editor.val();
                link.html(that.newValue);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.settings.valueChanged();
            }, 50);
        });
    }

    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.originalValue != (await this.getValueAsync());
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async getValueAsync() {
        return this.newValue;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(newInfo: string) {
        this.newValue = newInfo;

        return this.newValue;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}
}
