/// <reference types="matrixrequirements-type-declarations" />
import {
    IItem,
    IItemIdParts,
    IReference,
    IReferenceChange,
    IItemGet,
    IItemPut,
    IRestParam,
    IGenericMap,
    IStringMap,
    IRestResult,
} from "../../globals";
import {
    ILabel,
    IDesignReview,
    ILabelGroup,
    IMailConfigCanned,
    ICaption,
    ISmartTextConfigReplacement,
    IDropdownOption,
    IDropdownGroup,
} from "../../ProjectSettings";
import { XCPostCompareHtml } from "../../RestCalls";
import {
    XRCrossProjectLink,
    XRUserPermissionType,
    XRGroupPermissionType,
    XRGroupType,
    XRCategoryExtendedType,
    XRGetProject_JobStatus_JobsStatusWithUrl,
    XRGetProject_Reports_GetReportsAck,
    XRPostProject_AddFile_AddFileAck,
    XRPostProject_CompareHtml_HtmlCompareResult,
    XRPostProject_LaunchReport_CreateReportJobAck,
    XRPostProject_SignItem_SignItemAck,
    XRTrimAudit,
    XRLabelHistory,
    XRTrimNeedle,
} from "../../RestResult";
import {
    DBCache,
    IDB,
    IDBParent,
    IEvent,
    IFileParam,
    IFileUploadProgress,
    IItemWatched,
    IJcxhr,
    IReferenceUpdate,
    IRestoreItemResult,
    ISearchResult,
    ISetField,
    ISimpleTree,
    ITraceRules,
    IUpdateCache,
    IVersionDetails,
} from "../businesslogic";
import { IBaseControlOptions } from "../UI/Controls/BaseControl";
import { IDropdownParams } from "../UI/Controls/dropdown";
import { ILinkCollectionOptions, ILinkCategories } from "../UI/Controls/linkCollection";
import { IRichTextParams } from "../UI/Controls/richText";

// ContextFrameTools interfaces
export type { IContextInformation, IContextFramesTools };

// FileTools interfaces
export type { IUploadedFileInfo, IFileDefinition, IFileTools };

// ItemTools interfaces
export type { ISimpleItemTools, IItemTools };

// JSONTools interfaces
export type { IJSONTools };

// ReportGeneratorTools interfaces
export type {
    IReportTask,
    IJobList,
    IReportOptions,
    IReportTransferField,
    IReportSetField,
    IGetReportProgressResult,
    IGetReportProgressJobFileResult,
    IPostCreateSignOrDocResult,
    IReportInput,
    IReportGeneratorTools,
};

// LabelTools interfaces
export type { IChangedLabels, ILabelManager, ILabelTools };
export { DOCUMENT_STATUS_LABEL_GROUP_TYPE };

// LoggerTools interfaces
export type { ILoggerTools };

// MailTools interfaces
export type { IUserDropDown, ITestCanned, IUserLookup, IMailTools };

// SearchTools interfaces
export type { ISearchTools, ICancelSearchEvent };

// SmartTextTools interfaces
export type { ICaptionFormat, ISmartTextTools };

// UITools interfaces
export type { IToolTipCache, IDropDownButtonOption, IDialogOptions, ICIColor, ICIColorList, IUIToolsEnum };

export { UIToolsConstants };

export type { ITimeZoneOption, ITimeZoneSetting };

export type { IDateTimeUI, IBlockingProgressUITask, IBlockingProgressUI, IProgressUI, ISelectUserOrGroupUI, ILT };

// URLTools interfaces
export type { IMatrixUrlParts, IURLTools };

// XPathTools interfaces
export type { IXPathToolsComp, IXPathTools };

// IMatrix
export type { IMatrix };

// ContextFrameTools interfaces
interface IContextInformation {
    project: string;
    user: string;
    server: string;
    version: string;
    product: string;
    itemId: string;
    item: string;
    fieldList: string;
}

interface IContextFramesTools {
    showContextFrame(tabType: string, makeVisible: boolean): boolean;
    getExpender(): JQuery;
    visibility(enabled: boolean): void;
    hideContextFrames(): void;
    showContextFrames(): void;
    renderContextFrames(): void;
    fillContextFrame(_data: IItem, itemId: string): void;
    init(): void;
}

// FileTools interfaces
interface IUploadedFileInfo {
    fileName: string;
    fileId?: string;
    uploaded?: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fileObj?: any;
}
interface IFileDefinition {
    name: string;
}

interface IFileTools {
    UploadFilesAsync(files: FileList | File[]): JQueryDeferred<IUploadedFileInfo[]>;
    UploadFileAsync(file: File): JQueryDeferred<IUploadedFileInfo[]>;
    convertXLSXAsync(file: IFileParam): JQueryDeferred<string>;
}

// ItemTools interfaces

// For clients in simple environments (no app or matrixSession).
type GetTitleFunction = (itemId: string) => string;
interface ISimpleItemTools {
    parseRef(itemRef: string, project: string, matrixBaseUrl: string): IItemIdParts;
    getCreator(item: IItem): string;
    getLastEditor(item: IItem): string;
    refListToDisplayString(
        inputItems: IReference[] | null,
        prefix: string,
        getTitleFunction: GetTitleFunction,
        shorten?: number,
    ): string;
    renderLink(itemId: string, itemTitle: string, newWindow?: boolean): JQuery;
    updateReferences(
        oldReferences: IReference[],
        newReferences: IReference[],
        fromId: string | null,
        toId: string | null,
    ): IReferenceChange[];
    clone(item: IItemGet, copyLabels: boolean): IItemPut;
    sort(a: string, b: string, project: string, matrixBaseUrl: string): number;
}

interface IItemTools {
    parseRef(itemRef: string): IItemIdParts;
    getCreator(item: IItem): string;
    getLastEditor(item: IItem): string;
    refListToDisplayString(inputItems: IReference[] | null, prefix: string, shorten?: number): string;
    renderLink(itemId: string, itemTitle?: string | null, newWindow?: boolean): JQuery;
    updateReferences(
        oldReferences: IReference[],
        newReferences: IReference[],
        fromId: string | null,
        toId: string | null,
    ): IReferenceChange[];
    clone(item: IItemGet, copyLabels: boolean): IItemPut;
    sort(a: string, b: string): number;
}

// JSONTools interfaces
interface IJSONTools {
    mergeOptions(defaultOptions: IBaseControlOptions, options: IBaseControlOptions): IBaseControlOptions;
    setOptions(newOptions: IBaseControlOptions, options: IBaseControlOptions): IBaseControlOptions;
    isTrue(obj: undefined | null | boolean | string | number): boolean;
    isFalse(obj: undefined | null | boolean | string | number): boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fromString(str: null | string): { status: string; value: {} };
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    clone(src: any): any;
    escapeJson(code: string): string;
    unEscapeJson(code: string): string;
}

// ReportGeneratorTools interfaces
interface IReportTask {
    reportId: string;
    jobId: number;
    progress: number;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    postCreateCallback: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    postFailCallback?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    postProgressCallback?: Function;
    reportOptions?: IReportOptions;
}
interface IJobList {
    allGood: boolean;
    tasks: IReportTask[];
    doneBatch: number;
}

// param for server post / get
interface IReportOptions extends IRestParam {
    format?: string;
    inline?: boolean;
    reason?: string;
    filter?: string;
    isSignedReport?: boolean;
    includeSignatures?: string;
    newTitle?: string;
    copyFields?: string;
    itemList?: string;
    url?: string;
    resturl?: string;
    reportNameOverride?: string;
}
interface IReportTransferField {
    fromId: string;
    toId: string;
}

interface IReportSetField {
    toId: string;
    value: string;
}

// IReportProgress result from server:
interface IGetReportProgressResult {
    status: string;
    progress: number;
    jobFile: IGetReportProgressJobFileResult[];
    visibleName: string;
}
// IJobFile Result from server
interface IGetReportProgressJobFileResult {
    internalPath: string;
    jobFileId: number;
    mimeType: string;
    restUrl: string;
    visibleName: string;
}

interface IPostCreateSignOrDocResult {
    jobId: number;
}

interface IReportInput {
    to: string;
}

interface IReportGeneratorTools {
    SaveAndCreate(
        itemId: string,
        reportOptions: IReportOptions,
        progressInfo: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        postCreateCallback: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        postFailCallback?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        postProgressCallback?: Function,
    ): void;
    CreateSignedDoc(
        docId: string,
        signatures: string[],
        signedDocumentsControl: JQuery,
        labelFilter: string,
        signName: string,
        transferFields: IReportTransferField[],
        defaultLabels: string[],
        docUpdateCb: (createdDocumentId: string) => void,
    ): void;
    createSIGN(
        target: string,
        comment: string,
        docId: string,
        signatures: string[],
        signedDocumentsControl: JQuery,
        labelFilter: string,
        signName: string,
        transferFields: IReportTransferField[],
        defaultLabels: string[],
        docUpdateCb: (createdDocumentId: string) => void,
    ): void;
    // create and download a DOC
    CreateDoc(docId: string, format: IReportOptions, labelFilter: string): void;
    // create and download a report
    CreateReport(
        reportId: string,
        format: IReportOptions,
        inputItems?: IReportInput[],
        requiredItems?: IReportInput[],
    ): void;
    // download a SIGNed doc with signatures
    DownloadSignedDoc(signedId: string, format: IReportOptions): void;
}

// LabelTools interfaces
interface IChangedLabels {
    changed: boolean;
    added: string[];
    removed: string[];
    delta: string; // a , separated string with +LABEL for added and -LABEL for removed labels
}

const DOCUMENT_STATUS_LABEL_GROUP_TYPE = "_DOCUMENT_STATUS_";

interface ILabelManager {
    // properties
    ignoreProjectFilter: boolean;

    // methods
    getFilterColor(): string;
    getFilter(): string;
    getDisplayName(labelId: string): string;
    getFilterName(labelId: string): string;
    getLabelDefinitions(categories: string[] | void): ILabel[];
    setFilter(filter: string[]): void;
    resetReviewLabels(labelIds: string[], category: string, addXor?: boolean): string[];
    getDefaultLabels(category: string): string[];
    hasLabels(): boolean;
    setLabels(oldLabelIds: string, labels: string[]): string;
    setLabel(oldLabelIds: string[], label: string): string[];
    unsetLabel(oldLabelIds: string[], label: string): string[];
    compareLabels(before: string[], after: string[]): IChangedLabels;
    getLabelNames(): string[];
    getDesignReview(labelId: string): IDesignReview | null;
    isFiltered(category: string, labels: string): boolean;
    getLabelsOfLabelGroupsType(labelGroupType: string): string[];
    getLabelGroups(category?: string): ILabelGroup[];

    /**
     * In an {@link XRLabelHistory} object returned from the server, find the revision of the item
     * with the given {itemId} at which {label} was last set. If {beforeRevision} is non-zero, the
     * search will begin before that revision.
     *
     * @param labelHistory
     * @param itemId
     * @param label
     * @param beforeRevision
     */
    decipherLastTimeLabelWasSet(
        labelHistory: XRLabelHistory,
        itemId: string,
        label: string,
        beforeRevision: number,
    ): number;
}

interface ILabelTools extends ILabelManager {
    /**
     * Makes a server call to retrieve history for {itemId}, finds the revision of the item
     * with the given {itemId} at which {label} was last set. If {beforeRevision} is non-zero, the
     * search will begin before that revision.
     *
     * Returns the revision asynchronously.
     *
     * @param itemId
     * @param label
     * @param beforeRevision
     */
    getLastTimeLabelWasSet(itemId: string, label: string, beforeRevision: number): Promise<number>;
}

// LoggerTools interfaces
interface ILoggerTools {
    log(id: string, msg: string): void;
    debug(message: string): void;
    info(message: string): void;
    warning(message: string): void;
    error(message: string): void;
    getLog(): string;
}

// MailTools interfaces
interface IUserDropDown {
    id: string;
    label: string;
}
interface ITestCanned extends IMailConfigCanned {
    [key: string]: string | undefined;
}
interface IUserLookup extends XRUserPermissionType {
    [key: string]: string | number;
}

interface IMailTools {
    /** send mail
     * @param {type} to comma separated list of user ids
     * @param {type} cc comma separated  list of user ids
     * @param {type} bcc comma separated  list of user ids
     * @param {type} subject default subject
     * @param {type} body default body
     * @param {type} systemMail set to 1 to send mail in name of system rather than user
     *
     * @returns {undefined} nothing
     */
    sendMail(
        to: string,
        cc: string,
        bcc: string,
        subject: string,
        body: string,
        systemMail?: number,
        noSuccess?: boolean,
        noReply?: number,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
    ): JQueryDeferred<{}>;

    /** show send mail dialog and send mail
    * @param {type} sendTo comma separated list of user ids
    * @param {type} preSelectedUsers available users or null if to use all project users
    * @param {type} subject default subject
    * @param {type} body default body
    * @param {type} includeSupport whether to send it to matrix support
    * @param {type} sendCc

    * @returns {undefined} nothing
    */
    sendMailDlg(
        sendTo: string,
        preSelectedUsers: XRUserPermissionType[],
        subject: string,
        body: string,
        sendCc: string,
    ): void;

    // replace a bunch of placeholders
    replacePlaceholders(
        template: string,
        itemId: string,
        rejectComment: string,
        reviewers: string[],
        readers: string[],
        trainers: string[],
        trainees: string[],
    ): string;

    /** get default messages, replace a few strings
     *
     * @param {type} messageId
     * @param {type} itemId
     * @returns {undefined}
     */
    getCannedMessage(messageId: string, to: string, itemId: string, custom?: string, body?: string): string;

    // send a bunch of individual mails
    sendMails(sendTos: string[], subject: string, messages: string[]): void;
}

// SearchTools interfaces
interface ICancelSearchEvent {}

interface ISearchTools {
    OnCancelSearch: IEvent<ICancelSearchEvent, void>;
    getFilter(): string;
    cancelSearch(): void;
    searchInDialog(): void;
    endSearchInDialog(): void;
    highlight(term: string): void;
    hideHighlight(): void;
    renderHighlight(): void;
}

// SmartTextTools interfaces
interface ICaptionFormat extends ICaption {
    captionDetails: string;
    captionClass: string;
    referenceClassNo: string;
}

interface ISmartTextTools {
    createMenu(docMode: boolean, tableMode: boolean): void;
    deleteTag(what: string): void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertFigReference(reference: string, editor: any, editable: any): void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertTabReference(reference: string, editor: any, editable: any): void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    pasteBuffer(editor: any, editable: any): void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createCaption(isTable: boolean, editor: Summernote.editor, editable: any): void;
    updateCaptionsAndReferences(): void;
    createEditTag(
        tagType: number,
        what: string,
        data?: ISmartTextConfigReplacement,
        saveFct?: (newFct: ISmartTextConfigReplacement) => boolean,
        forceTiny?: boolean,
    ): void;
    selectEditCreateTag(mode: number, tagType: number, tagSelected: (tag: ISmartTextConfigReplacement) => void): void;
    removeOuterParagraph(edit: string): string;
    replaceTextFragments(text: string, showTooltips?: boolean, encoded?: boolean): string;
    showTooltips(node: JQuery, noContainer?: boolean): void;
    prepareForReadReadRender(itemDetails: JQuery): void;
}

// UITools interfaces
interface IToolTipCache {
    date: Date;
    item: IItem;
}
interface IDropDownButtonOption {
    name: string;
    class?: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    click: Function;
}
interface IDialogOptions {
    container: JQuery;
    title: string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    buttons: any[]; // DialogButtonOptions
    // optional
    content?: JQuery;
    minMaxWidth?: number;
    minMaxHeight?: number;
    scrolling?: UIToolsConstants.Scroll;
    autoResize?: boolean;
    maximizeButton?: boolean;
    noXButton?: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onClose?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onOpen?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onResize?: Function;
    noCloseOnEscape?: boolean;
}

interface ICIColor {
    color: string;
    alternateColor: string;
}

interface ICIColorList {
    [key: string]: ICIColor;
}
/*
enum CIColors {


}
*/
interface IUIToolsEnum {
    //
    // Properties
    //
    // date time formatting in UI
    DateTime: IDateTimeUI;

    // full screen (modal) progress
    BlockingProgress: IBlockingProgressUI;

    SelectUserOrGroup: ISelectUserOrGroupUI;

    lt: ILT;

    // bottom progress bar
    Progress: IProgressUI;
    //
    // Methods
    //
    fixC3ForCopy(copied: JQuery): void;

    createDropDownButton(
        defaultText: string,
        options: IDropDownButtonOption[],
        isUp: boolean,
        buttonId?: string,
        disableDefaultButtonClick?: boolean,
    ): JQuery;
    getNiceDialogSize(minWidth: number, minHeight: number): { width: number; height: number };
    showSuccess(messageTitle: string, hideAfter?: number): void;
    hideSuccess(): void;
    hideError(): void;
    showError(messageTitle: string, messageBody: string, showForMS?: number): void;
    showAck(ackId: number, messageTitle: string, dlgTitle?: string): void;
    showConfirm(
        confId: number,
        messageInfo: { title: string; ok: string; nok?: string; third?: string },
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        confFunction: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        noConfFunction: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        thirdFunction?: Function,
    ): void;
    confirmSpinningWait(message: string): void;
    closeConfirmSpinningWait(): void;
    showTooltip(itemId: string, target: JQuery, event: Event, crossProject?: string): void;
    showTaskAsTooltip(id: string, title: string, url: string, htmlContent: string, target: JQuery): void;
    hideTooltip(now?: boolean): void;
    updateTooltip(): void;
    spaceMessage(userHasSpaces: boolean, passwordHasSpaces: boolean): string;
    getSpinningWait(message?: string): JQuery;
    setEnabled(button: JQuery, enabled: boolean): void;
    getDisplayError(jqxhr: IJcxhr, textStatus: string, error: string): string;
    showDialog(
        dlg: JQuery,
        title: string,
        content: JQuery,
        minMaxWidth: number,
        minMaxHeight: number,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        buttons: any[], // DialogButtonOptions
        scrolling: UIToolsConstants.Scroll,
        autoResize?: boolean,
        maximizeButton?: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        close?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        open?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        resize?: Function,
        noCloseOnEscape?: boolean,
    ): void;
    showDialogDes({
        // optional parameters with defaults
        maximizeButton, // = false
        noXButton, // = false
        autoResize, // = false
        onClose, // = null
        onOpen, // = null
        onResize, // = null
        noCloseOnEscape, // = false
        minMaxWidth, // = $(document).width() * 0.9
        minMaxHeight, // = app.itemForm.height() * 0.9
        scrolling, // = UIToolsConstants.Scroll.None
        content, // = null
        // required parameters
        container,
        title,
        buttons,
    }: IDialogOptions): void;
    pushDialog(thisDialog: JQuery): void;
    popDialog(thisDialog: JQuery): void;
    serverHtmlCleanupBlob(content: JQuery): JQueryDeferred<string>;
    copyBuffer(
        anchor: JQuery,
        tooltip: string,
        content: JQuery,
        catchKey?: JQuery,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onProcessCopy?: Function,
        btnText?: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        beforeCopy?: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        afterCopy?: Function,
    ): void;
    getIconOptions(): IDropdownOption[];
    calculateColorFrom(input: string): ICIColor;
    getAvatar(info: string, size: number): JQuery;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    doCopy(content: JQuery, onProcessCopy: Function): void;
    addChevronSection(container: JQuery, text: string, help: string, open?: boolean): JQuery;
    enableIf(cb: JQuery, state: boolean, ctrls: JQuery[]): void;
    addCheckboxD(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        defaultValue: string,
    ): JQuery;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addCheckbox(ui: JQuery, text: string, fieldParams: IGenericMap, propertyName: string, onChange: Function): JQuery;
    addCheckboxIsTrue(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
    ): JQuery;
    addCheckboxIsFalse(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
    ): JQuery;
    addPassInput(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onUnFocus?: Function,
    ): JQuery;
    addTextInput(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onFocusOut?: Function,
        isPass?: boolean,
        help?: string,
        readonly?: boolean,
        rows?: number,
        allowResize?: boolean,
    ): JQuery;
    addRichTextInput(
        ui: JQuery,
        params: IRichTextParams,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onFocusOut?: Function,
    ): JQuery;
    addDateSelect(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        help?: string,
        readonly?: boolean,
    ): JQuery;
    addIconInput(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onFocusOut?: Function,
        isPass?: boolean,
    ): JQuery;
    addDropdownToArray(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        options: IDropdownOption[],
        grouping: IDropdownGroup[],
        maxItems: number,
        create: boolean,
        sort: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        placeholder?: string,
    ): JQuery;
    addDropdownNumber(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        first: number,
        last: number,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        placeholder?: string,
        paramsBase?: IDropdownParams,
    ): void;
    addDropdownToValue(
        ui: JQuery,
        text: string,
        fieldParams: IGenericMap,
        propertyName: string,
        options: IDropdownOption[],
        create: boolean,
        sort: boolean,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onChange: Function,
        placeholder?: string,
        paramsBase?: IDropdownParams,
    ): JQuery;
    getPageTitle(title: string, getPanel?: () => JQuery, resize?: () => void): JQuery;
    createConfigLine(
        lineId: string,
        linePrefix: string,
        lineName: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        lineArray: any[],
        idProp: string,
        onChangedOrder: () => void,
        onEdit: (lineId: string) => void,
        needsEdit: boolean,
        onDelete: (lineId: string) => void,
    ): JQuery;
    fixArrows(ul: JQuery): void;
    createConfigAddLine(action: string, onAdd: () => void): JQuery;

    standardizeColor(fieldValue: string, alpha?: number): string;

    toggleFilters(filterOn: boolean): void;

    changeGlobalFilterSelectionEnabled(isEnabled: boolean): void;
}

class UIToolsConstants {
    static CIColors: ICIColorList = {
        Black: { color: "#000", alternateColor: "#FFF" },
        BrownCoffeeRoyal: { color: "#6C3008", alternateColor: "#FFF" },
        BlueEastBay: { color: "#3D5A7F", alternateColor: "#FFF" },
        BlueLagoon: { color: "#17125B", alternateColor: "#FFF" },
        BlueDoger: { color: "#41B4FF", alternateColor: "#FFF" },

        BlueZiggurat: { color: "#B3D9DD", alternateColor: "#000" },
        GreenYellow: { color: "#B9D771", alternateColor: "#000" },
        GreenSushi: { color: "#68C037", alternateColor: "#000" },
        YellowBrightSun: { color: "#FFE541", alternateColor: "#000" },
        OrangeYellow: { color: "#FBA043", alternateColor: "#000" },
        //YellowOrange:{bg:"#FBA043",fg:"#000"},

        RedPersimmon: { color: "#FF545D", alternateColor: "#FFF  " },
        VioletElectric: { color: "#A543FB", alternateColor: "#000" },
        VioletMauve: { color: "#DEA3FF", alternateColor: "#000" },
        PinkHot: { color: "#FF6FBC", alternateColor: "#000" },
        BrownTiaMaria: { color: "#C6490C", alternateColor: "#FFF" },

        BrownDiSerria: { color: "#E19E72", alternateColor: "#FFF" },
        PinkBeautyBush: { color: "#F5CECE", alternateColor: "#000" },
        GreyDark: { color: "#838383", alternateColor: "#FFF" },
        GreyLightAlto: { color: "#DCDCDC", alternateColor: "#000" },
        White: { color: "#FFFFFF", alternateColor: "#000" },
    };

    static CIColorsGrey: ICIColorList = {
        G0_VeryDark: { color: "#1d2227", alternateColor: "#FFF" },
        G1: { color: "#32373c", alternateColor: "#FFF" },
        G2: { color: "#465055", alternateColor: "#FFF" },
        G3_GreyDark: { color: "#838383", alternateColor: "#FFF" },
        G4: { color: "#a9b3b8", alternateColor: "#FFF" },
        G5: { color: "#cdd2d7", alternateColor: "#000" },
        G6: { color: "#dbe0e5", alternateColor: "#000" },
        G7_GreyLightAlto: { color: "#DCDCDC", alternateColor: "#000" },
        G8: { color: "#f0f0f5", alternateColor: "#000" },
        G9: { color: "#f5f5fa", alternateColor: "#000" },
    };

    static allGrey = [
        UIToolsConstants.CIColorsGrey.G0_VeryDark.color,
        UIToolsConstants.CIColorsGrey.G1.color,
        UIToolsConstants.CIColorsGrey.G2.color,
        UIToolsConstants.CIColorsGrey.G3_GreyDark.color,
        UIToolsConstants.CIColorsGrey.G4.color,
        UIToolsConstants.CIColorsGrey.G5.color,
        UIToolsConstants.CIColorsGrey.G6.color,
        UIToolsConstants.CIColorsGrey.G7_GreyLightAlto.color,
        UIToolsConstants.CIColorsGrey.G8.color,
        UIToolsConstants.CIColorsGrey.G9.color,
    ];
    // based on green sushi
    static CIColorsPrimary: ICIColorList = {
        P0_LogoGreenYellow: { color: "#b9d771", alternateColor: "#FFF" },
        P1: { color: "#9bcf45", alternateColor: "#FFF" },
        P2: { color: "#6dcf45", alternateColor: "#FFF" },
        P3_GreenSushi: { color: "#68C037", alternateColor: "#FFF" },
        P4: { color: "#75b10f", alternateColor: "#FFF" },
        P5: { color: "#7aa608", alternateColor: "#FFF" },
        P6_LogoDarkGreen: { color: "#007e8d", alternateColor: "#FFF" },
    };
}

module UIToolsConstants {
    export enum Scroll {
        Auto,
        Vertical,
        None,
    }
}

interface ITimeZoneOption {
    val: string;
    text: string;
    timeformat?: string;
    dateformat?: string;
}
interface ITimeZoneSetting {
    [key: string]: string | ITimeZoneOption[] | undefined;
    timeZoneOptions: ITimeZoneOption[];
    settingsSource: string;
    timeformat?: string;
    dateformat?: string;
    timezone?: string;
}

interface IDateTimeUI {
    initDateTimeSettings(update?: boolean): Promise<void>;
    renderHumanDate(date: Date, dateOnly?: boolean): string;
    renderCustomerHumanDate(date: Date, dateOnly?: boolean): string;
    renderHumanMonth(dateObj: Date): string;
    renderDashFormat(dateObj: Date): string;
    renderSettingControlsAsync(options: { table: JQuery; user: string; help: string }): Promise<void>;
    renderSettingDialog(user: string): void;
    requiresTimeZoneWarning(): boolean;
    getTimeZoneCTA(): JQuery;
    getSimpleDateFormat(): string;
    getSimpleDateFormatMoment(): string;
    getSimpleDateTimeFormatMoment(): string;
}

interface IBlockingProgressUITask {
    name: string;
    progress?: number;
}
interface IBlockingProgressUI {
    Init(tasks: IBlockingProgressUITask[], animate?: boolean): void;
    SetProgress(taskIdx: number, percent: number, newText?: string): void;
    SetProgressError(taskIdx: number, problem: string): void;
}

interface IProgressUI {
    Init(message: string, warning?: boolean): void;
    Update(progress: number): void;
    getProgress(): number;
    SuccessHide(message: string, ms: number): void;
    ErrorHide(message: string, ms: number): void;
}

interface ISelectUserOrGroupUI {
    showMultiUserSelect(
        container: JQuery,
        help: string,
        selected: string[],
        title: string,
        selectFrom: string,
        selectTo: string,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string[]) => void,
        preSelectedUsers?: XRUserPermissionType[],
    ): void;
    getUsersInSelection(selection: string[]): string[];
    getGroupId(group: XRGroupPermissionType | XRGroupType): string;
    getGroupDisplayNameFromId(groupOrUserId: string): string;
    isGroup(groupOrUserId: string): boolean;
    exists(groupOrUserId: string): boolean;
    showSelectDialog(
        selected: string[],
        title: string,
        selectFrom: string,
        selectTo: string,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string[]) => void,
        preSelectedUsers?: XRUserPermissionType[],
    ): void;
    getUserDropDownOptions(
        showUsers: boolean,
        showGroups: boolean,
        preSelectedUsers?: XRUserPermissionType[],
        possiblyDeletedUsername?: string,
    ): IDropdownOption[];
    showSingleSelect(
        control: JQuery,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string) => void,
        preSelectedUsers?: XRUserPermissionType[],
        possiblyDeletedUsername?: string,
    ): void;
    getAllUsersAndGroups(): JQueryDeferred<IDropdownOption[]>;
    showSingleSelectDialog(
        selected: string,
        title: string,
        help: string,
        showUsers: boolean,
        showGroups: boolean,
        onSelect: (selection: string) => void,
        preSelectedUsers?: XRUserPermissionType[],
    ): void;
}

interface ILT {
    forDB(code: string, fieldId: number): string;
    forUI(code: string, fieldId: number): string;
}

// URLTools interfaces
interface IMatrixUrlParts {
    project: string;
    item: string;
    params: IStringMap;
}

interface IURLTools {
    getParameterByName(url: string, name: string): string | null;
    parseUrl(url: string): IMatrixUrlParts;
}

// XPathTools interfaces
interface IXPathToolsComp {
    name?: string;
    position: number;
}
interface IXPathTools {
    get(node: JQuery): string;
    hardCopy(element: JQuery): string;
}

// Overall library interface.
interface IMatrix {
    Search: ISearchTools;
    Item: IItemTools;
    ContextFrames: IContextFramesTools;

    // highlight / search function

    JSON: IJSONTools;
    XPath: IXPathTools;
    URL: IURLTools;
    Mail: IMailTools;

    // console / error logging
    Logger: ILoggerTools;

    // label tools (labels and filters)
    LabelTools: ILabelTools;

    // tooltipsmessage boxes and other ui controls
    UI: IUIToolsEnum;
    File: IFileTools;
    ReportGenerator: IReportGeneratorTools;
    SmartText: ISmartTextTools;

    /**
     * Create a label tools object from the application context.
     */
    CreateNewLabelTools(): ILabelTools;
}

export interface IApp extends IBaseApp {
    mainApp: boolean;
    mainTreeLoaded: boolean;
    setCache(externalCache: DBCache): void;
    loadProject(project: string, item: string): Promise<void>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    loadTree(project: string): JQueryDeferred<{}>;
    loadTreeAndItem(project: string, item: string): JQueryDeferred<IItem>;
    updateFavicon(project: string, notification: boolean): void;
    waitForMainTree(callback: () => void): void;
    loadTreeWithSearches(item: string): JQueryDeferred<unknown>;
    getTree(subtreeFilter?: string[]): IDB[];
    getSubTree(itemId: string): ISimpleTree;
    getAuditDetailsAsync(auditId?: number, ignoreErrors?: boolean): JQueryDeferred<XRTrimAudit>;
    getItemAsync(itemId: string, version?: number, ignoreErrors?: boolean, noHistory?: boolean): JQueryDeferred<IItem>;
    getNeedlesAsync(
        searchExpr: string,
        up?: boolean,
        down?: boolean,
        fields?: string,
        labels?: boolean,
        ignoreFilters?: boolean,
    ): JQueryDeferred<IItemGet[]>;
    getItemProjectAsync(project: string, itemId: string, ignoreErrors?: boolean): JQueryDeferred<IItem>;
    getProjectItemAsync(
        project: string,
        itemId: string,
        version?: number,
        includeHistory?: boolean,
    ): JQueryDeferred<IItem>;
    getProjectCatFields(project: string): JQueryDeferred<XRCategoryExtendedType[]>;
    getItemFromTree(itemId: string): IDB;
    getChildrenIds(parentId: string): string[];
    getChildrenIdsRec(itemId: string): string[];
    getParentId(itemId: string): string;
    getCategoryBreadcrumbs(category: string): string[];
    getBreadcrumbs(itemId: string): string[];
    setStyle(itemIds: string[], style: string, computeFolder: number): void;
    getRootOfType(category: string): string;
    startReportAsync(itemId: string, reportOptions: IReportOptions): JQueryDeferred<IPostCreateSignOrDocResult>;
    canLaunchReport(): JQueryDeferred<boolean>;
    startCreateDocumentAsync(
        itemId: string,
        reportOptions: IReportOptions,
    ): JQueryDeferred<XRPostProject_LaunchReport_CreateReportJobAck>;
    getReportDetails(jobId: number): JQueryDeferred<XRGetProject_JobStatus_JobsStatusWithUrl>;
    compareHTML(compareParams: XCPostCompareHtml): JQueryDeferred<XRPostProject_CompareHtml_HtmlCompareResult>;
    isFolder(itemId: string): boolean;
    getItemTitle(itemId: string, display?: boolean): string;
    download(jobId: number, file: number, param?: string[]): void;
    downloadFromUrl(url: string, param?: IStringMap): void;
    downloadInMemory(jobId: number, file: string, dataType?: string): JQueryDeferred<string>;
    downloadInMemoryFromUrl(url: string): JQueryDeferred<string>;
    searchAsync(
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        fieldList?: string,
        crossProject?: string,
        labels?: boolean,
        down?: boolean,
        up?: boolean,
        treeOrder?: boolean,
    ): JQueryDeferred<ISearchResult[]>;
    searchAsyncMinimalOutput(
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        crossProject?: string,
    ): JQueryDeferred<string[]>;
    updateItemInDBAsync(itemJson: IItemPut, auditAction: string, requireVersion?: number): JQueryDeferred<IItemGet>;
    getItemFromDBAsync(itemId: string): Promise<IItem>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getFieldFromDBAsync(itemId: string, fieldName: string): Promise<any>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setFieldInDBAsync(itemId: string, fieldName: string, value: string): Promise<any>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setFieldsInDBAsync(itemId: string, data: ISetField[]): Promise<any>;
    createItemOfTypeAsync(
        category: string,
        itemJson: IItemPut,
        actions: string,
        parentId: string,
        dontFailOnCleanup?: boolean,
    ): JQueryDeferred<IDBParent>;
    restoreItemAsync(itemId: string, title: string, version: number): JQueryDeferred<IRestoreItemResult>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    deleteItem(itemId: string): JQueryDeferred<{}>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    uploadFileProjectAsync(file: IFileParam, progress: (p: IFileUploadProgress) => void): JQueryDeferred<{}>;
    fetchFileAsync(
        url: string,
        progress: (p: IFileUploadProgress) => void,
    ): JQueryDeferred<XRPostProject_AddFile_AddFileAck>;
    resizeItem(force?: boolean): void;
    itemChanged(needsSave: boolean): void;
    updateItem(newItem: IItem): void;
    setFieldValue(fieldId: number, newValue: string): void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getFieldValueAsync(fieldId: number): Promise<any>;
    getCurrentTitle(): Promise<string>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addDownLinkAsync(fromId: string, toId: string): JQueryDeferred<{}>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    removeDownLinkAsync(fromId: string, toId: string): JQueryDeferred<{}>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setSettingJSON(key: string, valueJSON: {}): JQueryDeferred<{}>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    readSettingJSONAsync(key: string, otherProject?: string, noRetry?: boolean): JQueryDeferred<any>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setSettingCustomerJSON(key: string, valueJSON: {}): JQueryDeferred<unknown>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    readSettingCustomerJSONAsync(key: string): JQueryDeferred<any>;
    getMissingUpLinks(item: IItem): string[];
    isUpLinkMissing(item: IItem): boolean;
    getMissingDownLinks(item: IItem): string[];
    getLinkCategories(item: IItem, ctrlParameter: ILinkCollectionOptions): ILinkCategories[];
    isDownLinkMissing(item: IItem): boolean;
    isAnyLinkOutdated(item: IItem): boolean;
    hasLinks(item: IItem): boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    isHiddenLink(itemId: string): {};
    setHiddenLink(itemId: string, hidden: number): void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    saveAsync(sendUnEdit: boolean): JQueryDeferred<{}>;
    forceReadonly(itemId: string): void;
    cancel(): void;
    someOneElseChanged(): void;
    someOneIsChangingTheItem(): void;
    waitForEditRights(): void;
    someOneElseIsChanging(watcherInfo: IItemWatched): void;
    someOneElseWasChanging(watcherInfo: IItemWatched): void;
    someOneElseStoppedEditing(watcherInfo: IItemWatched, previousWatcherInfo: IItemWatched): void;
    updateItemViewers(watcherInfo: IItemWatched): void;
    getVersion(): string;
    getVersionQualifier(): string;
    getNeedsSave(): boolean;
    getType(itemId: string): string;
    getAvailableReportsAsync(): JQueryDeferred<XRGetProject_Reports_GetReportsAck>;
    getActivityAsync(
        insertInList: (
            item: IVersionDetails,
            first?: number,
            last?: number,
            referenceChange?: IReferenceUpdate,
        ) => void,
        startAt?: number,
        count?: number,
        auditIdMin?: number,
        auditIdMax?: number,
        deletedOnly?: boolean,
    ): JQueryDeferred<number>;
    canNavigateAwayAsync(): JQueryDeferred<void>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    treeSelectionChangeAsync(newItemId: string): JQueryDeferred<{}>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    moveItemsAsync(itemIds: string, newFolder: string, newPosition?: number, useComment?: string): JQueryDeferred<{}>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMaxVersion(itemId: string): JQueryDeferred<{}>;
    removedFromTree(itemId: string, parentId: string): void;
    insertInTree(newItem: IDBParent): void;
    copyFrom(target: string, source: IDB): void;
    updateCache(newItem: IUpdateCache): void;
    createItemUrl(itemId?: string, crossProject?: string): string;
    renderItem(cachedItem?: IItem): void;
    print(): void;
    touchAsync(itemOrFolderId: string, depth: number): JQueryDeferred<string>;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setLabels(newLabels: IItemGet): JQueryDeferred<{}>;
    needsSave(): boolean;
    signItemAsync(
        itemId: string,
        password: string,
        meaning?: string,
    ): JQueryDeferred<XRPostProject_SignItem_SignItemAck>;
    checkPassword(password: string): JQueryDeferred<IRestResult>;
    convertDocAsync(fileNo: number, targetDocumentFolder?: string, useOriginal?: boolean): JQueryDeferred<string>;
    pingCurrentItem(): void;
    getCurrentItemId(): string;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    commitChangeListAsync(changeList: IReferenceChange[]): JQueryDeferred<{}>;
    isMedical(strict?: boolean): boolean;
    commentRequired(): boolean;
    touchToolAvailable(item: IItem): boolean;
    auditTrailAvailable(): boolean;
    mailToolAvailable(): boolean;
    postLogin(user: string): void;
    canDeleteItem(item: IItem): boolean;
    canViewItem(item: IItem): boolean;
    getImportSource(item: IItemGet): XRCrossProjectLink;
    getUsedBy(item: IItemGet): XRCrossProjectLink[];
    canEditItem(item: IItem): boolean;
    canCreateItemType(category: string, folder?: boolean): boolean;
    canDragDrop(category: string, id: string): boolean;
    canSeeField(category: string, field: number): boolean;
    canEditField(category: string, field: number): boolean;
    evaluateTraceRule(item: IItem, checkDownRule: boolean): ITraceRules;
    isConfigApp(): false;
    isConfigApplication: false;
    isClientApplication: true;
    dragEnter?: (dragged: Fancytree.FancytreeNode, target: Fancytree.FancytreeNode) => string[] | boolean;
    /** Open the analytics UI */
    openAnalytics(): void;
    /** Close the analytics UI */
    closeAnalytics(): void;
    startCompareDocumentsWord(source: string, target: string): Promise<XRPostProject_LaunchReport_CreateReportJobAck>;
    setSaving(saving: boolean): void;
}
export interface IBaseApp {
    getParentId(itemId: string): string;
    itemChanged(needsSave: boolean): void;
    getVersion(): string;
    getVersionQualifier(): string;
    getNeedsSave(): boolean;
    getCurrentItemId(): string;
    pingCurrentItem(): void;
    canDragDrop(nodeType: string, pageId: string): boolean;
    dragEnter?: (dragged: Fancytree.FancytreeNode, target: Fancytree.FancytreeNode) => string[] | boolean;
    getTree(subtreeFilter?: string[]): IDB[];
    pingCurrentItem(): void;
    itemForm: JQuery;
    printForm: JQuery;
    dlgForm: JQuery;
    postLogin(user: string): void;
}
