/// <reference types="matrixrequirements-type-declarations" />
export { InitializeCodeMirrorPlugins };

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function InitializeCodeMirrorPlugins() {
    CodeMirror.extendMode("css", {
        commentStart: "/*",
        commentEnd: "*/",
        newlineAfterToken: function (type: string, content: string) {
            return /^[;{}]$/.test(content);
        },
    });

    CodeMirror.extendMode("javascript", {
        commentStart: "/*",
        commentEnd: "*/",
        // FIXME semicolons inside of for
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        newlineAfterToken: function (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this: any,
            type: string,
            content: string,
            textAfter: string,
            state: CodeMirrorState,
        ) {
            if ((<CodeMirrorEditor>this).jsonMode) {
                return /^[[,{]$/.test(content) || /^}/.test(textAfter);
            } else {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (content == ";" && state.lexical && state.lexical.type == ")") {
                    return false;
                }
                return /^[;{}]$/.test(content) && !/^;/.test(textAfter);
            }
        },
    });

    CodeMirror.extendMode("json", {
        commentStart: "/*",
        commentEnd: "*/",
        // FIXME semicolons inside of for
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        newlineAfterToken: function (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this: any,
            type: string,
            content: string,
            textAfter: string,
            state: CodeMirrorState,
        ) {
            if ((<CodeMirrorEditor>this).jsonMode) {
                return /^[[,{]$/.test(content) || /^}/.test(textAfter);
            } else {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (content == ";" && state.lexical && state.lexical.type == ")") {
                    return false;
                }
                return /^[;{}]$/.test(content) && !/^;/.test(textAfter);
            }
        },
    });

    CodeMirror.extendMode("xml", {
        commentStart: "<!--",
        commentEnd: "-->",
        newlineAfterToken: function (type: string, content: string, textAfter: string, state: CodeMirrorState) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return (type == "tag" && />$/.test(content)) || /^</.test(textAfter);
        },
    });

    // Comment/uncomment the specified range
    CodeMirror.defineExtension(
        "commentRange",
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function (this: any, isComment: boolean, from: CodeMirrorPosition, to: CodeMirrorPosition) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let curMode: CodeEditorMode = <any>CodeMirror.innerMode(this.getMode(), this.getTokenAt(from).state).mode;
            this.operation(() => {
                if (isComment) {
                    // Comment range
                    this.replaceRange(curMode.commentEnd, to);
                    this.replaceRange(curMode.commentStart, from);
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (from.line == to.line && from.ch == to.ch) {
                        // An empty comment inserted - put cursor inside
                        this.setCursor(from.line, from.ch + curMode.commentStart.length);
                    }
                } else {
                    // Uncomment range
                    let selText = this.getRange(from, to);
                    let startIndex = selText.indexOf(curMode.commentStart);
                    let endIndex = selText.lastIndexOf(curMode.commentEnd);
                    if (startIndex > -1 && endIndex > -1 && endIndex > startIndex) {
                        // Take string till comment start
                        selText =
                            selText.substr(0, startIndex) +
                            // From comment start till comment end
                            selText.substring(startIndex + curMode.commentStart.length, endIndex) +
                            // From comment end till string end
                            selText.substr(endIndex + curMode.commentEnd.length);
                    }
                    this.replaceRange(selText, from, to);
                }
            });
        },
    );

    // Applies automatic mode-aware indentation to the specified range
    CodeMirror.defineExtension(
        "autoIndentRange",
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function (this: any, from: CodeMirrorPosition, to: CodeMirrorPosition) {
            let cmInstance = <CodeMirrorEditor>this;
            (<CodeMirrorEditor>this).operation(function () {
                for (let i = from.line; i <= to.line; i++) {
                    cmInstance.indentLine(i, "smart");
                }
            });
        },
    );

    // Applies automatic formatting to the specified range
    CodeMirror.defineExtension(
        "autoFormatRange",
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function (this: any, from: CodeMirrorPosition, to: CodeMirrorPosition) {
            let cm = <CodeMirrorEditor>this;
            let outer = cm.getMode(),
                text = cm.getRange(from, to).split("\n");
            let state = CodeMirror.copyState(outer, cm.getTokenAt(from).state);
            let tabSize = cm.getOption("tabSize");

            let out = "",
                lines = 0,
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                atSol = from.ch == 0;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            function newline() {
                out += "\n";
                atSol = true;
                ++lines;
            }

            for (let i = 0; i < text.length; ++i) {
                let stream: CodeMirrorStream = new CodeMirror.StringStream(text[i], tabSize);
                while (!stream.eol()) {
                    let inner = CodeMirror.innerMode(outer, state);
                    let style = outer.token(stream, state),
                        cur = stream.current();
                    stream.start = stream.pos;
                    if (!atSol || /\S/.test(cur)) {
                        out += cur;
                        atSol = false;
                    }
                    if (
                        !atSol &&
                        inner.mode.newlineAfterToken &&
                        inner.mode.newlineAfterToken(
                            style,
                            cur,
                            stream.string.slice(stream.pos) || text[i + 1] || "",
                            inner.state,
                        )
                    ) {
                        newline();
                    }
                }
                if (!stream.pos && outer.blankLine) {
                    outer.blankLine(state);
                }
                if (!atSol) {
                    newline();
                }
            }

            cm.operation(function () {
                cm.replaceRange(out, from, to);
                for (let cur = from.line + 1, end = from.line + lines; cur <= end; ++cur) {
                    cm.indentLine(cur, "smart");
                }
                cm.setSelection(from, cm.getCursor(false));
            });
        },
    );
}
