import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { ItemControl } from "../../common/UI/Components/index";
import { IBaseControlOptions } from "../../common/UI/Controls/BaseControl";
import { IItem, globalMatrix, matrixSession, ControlState, app, matrixApplicationUI } from "../../globals";

import { IDropdownOption } from "../../ProjectSettings";
import { XRGetProject_StartupInfo_ListProjectAndSettings } from "../../RestResult";

export type { IReindexCats };
export { initialize };

interface IReindexCats {
    cats: string[];
}
class ReIndex implements IPlugin {
    // ****************************************
    // standard plugin interface
    // ****************************************

    public isDefault = true;
    private cats: IReindexCats = { cats: [] };
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initItem(_item: IItem, _jui: JQuery) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initServerSettings(serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateMenu(ul: JQuery, hook: number) {}

    supportsControl(fieldType: string): boolean {
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createControl(ctrl: JQuery, options: IBaseControlOptions) {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initProject() {}

    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];

            let extras = globalMatrix.ItemConfig.getExtrasConfig();

            if (extras && ml.JSON.isTrue(extras.indexer) && matrixSession.isAdmin()) {
                pages.push({
                    id: "INDEX",
                    title: "Re-Index",
                    folder: "TOOLS",
                    order: 2000,
                    usesFilters: false,
                    render: (options: IPluginPanelOptions) => that.renderProjectPage(options),
                });
            }
            resolve(pages);
        });
    }

    // project pages show in the top in Projects, Reports and Documents
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private renderProjectPage(options: IPluginPanelOptions) {
        let that = this;

        if (options.controlState === ControlState.Print) {
            return;
        }

        options.control.html("");
        ml.UI.getPageTitle("Field Updater").appendTo(options.control);
        let panel = $('<div class="panel-body-v-scroll fillHeight" style="padding: 12px;">').appendTo(options.control);

        $("<p>In case the project configuration changed, some items might need to be updated.</p>").appendTo(panel);
        $(
            "<p>You'll notice if you select and item and before you change it, the save button will be enabled.</p>",
        ).appendTo(panel);
        $("<p>This tool will update all items which need to be changed - it can take a while...</p>").appendTo(panel);
        $(
            "<p>Note: the process will do the same updates to items, which would be done if you just look at the item and it asks you to save. You should verify that these changes are indeed what you want.</p>",
        ).appendTo(panel);

        this.cats = {
            cats: globalMatrix.ItemConfig.getCategories(true).filter(
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                (cat) => ["SIGN", "DOC", "REPORT", "XTC"].indexOf(cat) == -1,
            ),
        };
        let categories = $("<div>").appendTo(panel);
        let ops: IDropdownOption[] = globalMatrix.ItemConfig.getCategories().map((x) => {
            return { label: x, id: x };
        });
        ml.UI.addDropdownToArray(categories, "Categories", this.cats, "cats", ops, [], 100, false, true, () => {});

        let button = $(
            '<button data-cy="reindexBtn" style="margin: 0 12px 20px 20px;" type="button" class="btn btn-success">Start update</button>',
        )
            .appendTo(panel)
            .click(function () {
                ml.UI.setEnabled(button, false);
                that.reIndex(panel);
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private reIndex(ui: JQuery) {
        // build list of all potential todo's
        let todo: string[] = [];
        $.each(this.cats.cats, function (catIdx, cat) {
            todo = todo.concat(app.getChildrenIdsRec("F-" + cat + "-1"));
        });

        // change comment to reflect the update
        let originalComment = matrixSession.getComment();
        matrixSession.setComment("re-indexing after configuration change");

        // prepare UI
        let ul = $("<ul data-cy='reindexItemList' >").appendTo(ui);
        let updater = $("<div style='position:absolute;top:0;left:-10000px;width:1000px;'>").appendTo($("body"));

        // run
        this.process(updater, todo, 0, ul, $("<li>").prependTo(ul), originalComment);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private process(
        updater: JQuery,
        todo: string[],
        index: number,
        ul: JQuery,
        nextLI: JQuery,
        originalComment: string,
    ) {
        let that = this;

        if (index >= todo.length) {
            nextLI.html("Done - all items have been updated if necessary").attr("data-cy", "indexIsDone");
            matrixSession.setComment(originalComment);
            updater.remove();
            return;
        }

        updater.html("");
        let itemId = todo[index];
        let category = itemId.replace(/^F-/, "").replace(/-.*/, "");
        app.getItemAsync(itemId).done(async function (item) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            plugins.init(item);
            let newItem = new ItemControl({
                control: updater,
                controlState: ControlState.DialogEdit,
                parent: app.getParentId(itemId),
                type: category,
                isItem: true,
                item: item,
                disableTinyMce: true,
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                changed: function () {},
            });
            await newItem.load();
            matrixApplicationUI.lastMainItemForm = newItem;
            // TODO: what's the point of this timeout? try to get rid of it
            window.setTimeout(async function () {
                if (await newItem.needsSave()) {
                    nextLI.html(index + 1 + "/" + todo.length + ": " + itemId + " needs update");

                    newItem
                        .saveAsync(category, "indexer")
                        .then(function () {
                            nextLI.html(index + 1 + "/" + todo.length + ": " + itemId + " was updated");
                            nextLI = $("<li>").prependTo(ul);

                            that.process(updater, todo, index + 1, ul, nextLI, originalComment);
                        })
                        .catch(function () {
                            $(
                                "<li style='color:red'>" +
                                    (index + 1) +
                                    "/" +
                                    todo.length +
                                    ": " +
                                    itemId +
                                    " could not be updated automatically</li>",
                            ).appendTo(ul);
                        });
                } else {
                    nextLI.html(index + 1 + "/" + todo.length + ": " + itemId + " - nothing changed");
                    that.process(updater, todo, index + 1, ul, nextLI, originalComment);
                }
            }, 222);
        });
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initialize() {
    // register the engine as plugin
    plugins.register(new ReIndex());
}
