import { BaseTableFieldHandler, ITableControlBaseParams } from "../BaseTableFieldHandler";
import { IDHFControlDefinition, IDHFSection, IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IDHFConfigTableColumn, IDropdownOption } from "../../../../ProjectSettings";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap, IGenericMap } from "../../../../globals";
import { ITableRow } from "../../../UI/Controls/tableCtrl";

export interface ITableDHFConfig {
    default: { columns: IDHFConfigTableColumn[] };
}
export interface ITableDHFSection {
    config: ITableDHFConfig;
}

export type { ITableConfig, IDhfTableOptions, ITableConfigColumn };
interface ITableConfig {
    default: IDhfTableOptions;
}

interface IDhfTableOptions extends IDHFSectionOptions {
    columns: ITableConfigColumn[];
}
interface ITableConfigColumn extends IDHFSectionOptions {
    columnType: string;
    field: string;
    pos: number;
    editor: string;
    name: string;
    options: IDropdownOption[];
}

export class GenericTableDocHandler extends BaseTableFieldHandler implements IDocFieldHandler {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private controller: ITableDHFSection;
    public dhfFieldConfig: IAnyMap;
    constructor(
        private sectionType: string,
        config: IDHFSectionOptions,
        fieldValue: string,
    ) {
        // The IDHFSectionOptions config parameter is really a ITableControlBaseParams.
        super(<ITableControlBaseParams>config);
        this.dhfFieldConfig = config ?? {};
        this.initData(fieldValue);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    validate() {
        super.validate();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    getFieldType(): string {
        return this.sectionType;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected GetColumnCount(controllerConfig: ITableControlBaseParams) {
        return controllerConfig.columns ? controllerConfig.columns.length : 0;
    }

    getXmlValue(): string {
        let controllerConfig = this.dhfFieldConfig;

        // get data entered into table
        let table: IGenericMap[] = [];
        let fieldValue = this.getData();
        if (fieldValue) {
            table = JSON.parse(fieldValue);
        }
        // add some extras for the xml dump: table header, sorting and colum type
        const col_order: IGenericMap = { sortedtable: 1 };
        const col_types: IGenericMap = { columnTypes: 1 };
        const col_header: IGenericMap = {};

        const colSorter: { name: string; order: number }[] = [];
        let idx = 0;
        //There might be no columns defined
        let columns = controllerConfig.columns || [];

        for (let columnConfig of columns) {
            let castedColumnConfig = columnConfig as ITableConfigColumn;

            colSorter.push({ name: castedColumnConfig.field, order: castedColumnConfig.pos });
            col_types[castedColumnConfig.field] = castedColumnConfig.columnType;
            col_header[castedColumnConfig.field] = castedColumnConfig.name;

            // fix custom drop down values
            let colOpts = this.getColumnByField(controllerConfig.columns[idx].field)?.options;
            if (colOpts) {
                for (let row = 0; row < table.length; row++) {
                    // @ts-ignore not sure what's going on there. type properly
                    if (table[row]["col" + idx] && colOpts[table[row]["col" + idx]]) {
                        // replace value with human readable value
                        // @ts-ignore not sure what's going on there. type properly
                        table[row]["col" + idx] = colOpts[table[row]["col" + idx]];
                    }
                }
            }
            idx++;
        }
        // make sure col indexes start at 0
        colSorter.sort(function (a, b) {
            return a.order - b.order;
        });
        let colIdx = 0;
        for (let s of colSorter) {
            col_order[s.name] = colIdx;
            colIdx++;
        }

        table.splice(0, 0, col_header);
        table.splice(0, 0, col_types);
        table.splice(0, 0, col_order);

        // return the xml dump
        return JSON.stringify(table);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getDefaultConfig(): any {
        // Default is empty as it's configured from itemConfig.getDHFConfig();
        return {};
    }

    protected name = "";
    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    addSignatures(signatures: string[], includeAll?: boolean) {
        let controllerConfig = this.dhfFieldConfig;
        let signatureNameColumn: string;
        let eSign = false;
        for (let col = 0; col < this.getColumnCount(); col++) {
            if (controllerConfig.columns[col].columnType === "type4") {
                // drop down with user names
                signatureNameColumn = controllerConfig.columns[col].field;
            } else if (controllerConfig.columns[col].columnType === "type10") {
                // drop down with user names
                signatureNameColumn = controllerConfig.columns[col].field;
            } else if (controllerConfig.columns[col].columnType === "type3") {
                // empty field to add signature image and token
                eSign = true;
            }
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!eSign && !signatureNameColumn) {
            return;
        }

        if (!eSign) {
            return;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!signatureNameColumn) {
            return;
        }

        // get data entered into table
        let table: ITableRow[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        table = JSON.parse(this.getData());

        for (let row = 0; row < table.length; row++) {
            let userId = <string>table[row][signatureNameColumn];
            if ((includeAll || userId) && signatures.indexOf(userId) === -1) {
                signatures.push(userId);
            }
        }
    }
}
