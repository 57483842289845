import { ColumnEditor, IDB } from "../../../../core/common/businesslogic/index";
import { ml } from "../../../../core/common/matrixlib";
import { ISyncCatgoryInfo } from "../../../../core/common/UI/Controls/syncStatus";
import { JsonEditor } from "../../../../core/common/UI/JsonEditor";
import { ILineEditorLine } from "../../../../core/common/UI/ILineEditor";
import { LineType } from "../../../common/UI/LineType";
import { ConfigPage } from "../ConfigPage";
import { LineEditor } from "../LinEditor";

import { plugins } from "../../../../core/common/businesslogic/index";
import { globalMatrix, matrixSession } from "../../../globals";
import { ICategorySettingToolLocation } from "../../../ProjectSettings";

export { CategorySetting };

export enum DefaultCategorySettingNames {
    texticon = "texticon",
    syncInfo = "syncInfo",
    concurrentEdit = "concurrentEdit",
    tabs = "tabs",
    folderToolsLocation = "folderToolsLocation",
}

class CategorySetting extends ConfigPage {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getNode() {
        return {
            id: "categorysetting",
            type: "CategorySetting",
            title: "CategorySetting",
            icon: "admin/setting.png",
            children: <IDB[]>[],
        };
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    saveAsync() {
        let res = $.Deferred();
        res.resolve();
        return res;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    load(pageId: string) {
        let that = this;
        super.load(pageId);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.initPage("Category settings", false, null, "");

        let found: string[] = [];
        $("<span>").appendTo(this.simple).html("Settings:");
        let ul = $("<ul>").appendTo(this.simple);
        $.each(globalMatrix.ItemConfig.getCategorySettings(this.getCategory()), function (idx, setting) {
            found.push(setting.key);
            let li = $("<li>").appendTo(ul);
            for (let plugin of plugins.getPlugins()) {
                if (plugin.categorySetting && plugin.categorySetting(setting.key)) {
                    that.createEdit(plugin.categorySetting(setting.key), setting.key, () =>
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        plugin.editCategorySetting(setting.key, that.getCategory()),
                    ).appendTo(li);
                    return;
                }
            }
            switch (setting.key) {
                case DefaultCategorySettingNames.texticon:
                    that.createEdit(
                        "Category Folder <span class='inlineHelp'>(note: changes appearance on screen only)</span>",
                        setting.key,
                        that.editCategoryIcon,
                    ).appendTo(li);
                    break;
                case DefaultCategorySettingNames.syncInfo:
                    that.createEdit(
                        "Sync Configuration",
                        setting.key,
                        that.editSyncInfo,
                        !matrixSession.hasAgileSync(),
                    ).appendTo(li);
                    break;
                case DefaultCategorySettingNames.concurrentEdit:
                    that.createEdit("Concurrent Edit", setting.key, that.editConcurrentEdit, false).appendTo(li);
                    break;
                case DefaultCategorySettingNames.tabs:
                    that.createEdit("Item Tabbing", setting.key, that.editItemTabbing, false).appendTo(li);
                    break;
                case DefaultCategorySettingNames.folderToolsLocation:
                    that.createEdit("Folder Tools Location", setting.key, that.editToolLocation, false).appendTo(li);
                    break;
                default:
                    that.createEdit(setting.key, setting.key).appendTo(li);
                    break;
            }
        });
        // make sure defaults exist
        if (found.indexOf(DefaultCategorySettingNames.texticon) === -1) {
            let li = $("<li>").appendTo(ul);
            that.createEdit(
                "Category Folder <span class='inlineHelp'>(note: changes appearance on screen only)</span>",
                DefaultCategorySettingNames.texticon,
                that.editCategoryIcon,
            ).appendTo(li);
        }
        if (found.indexOf(DefaultCategorySettingNames.syncInfo) === -1) {
            let li = $("<li>").appendTo(ul);
            that.createEdit(
                "Sync Configuration",
                DefaultCategorySettingNames.syncInfo,
                that.editSyncInfo,
                !matrixSession.hasAgileSync(),
            ).appendTo(li);
        }
        if (found.indexOf(DefaultCategorySettingNames.concurrentEdit) === -1) {
            let li = $("<li>").appendTo(ul);
            that.createEdit(
                "Concurrent Edit",
                DefaultCategorySettingNames.concurrentEdit,
                that.editConcurrentEdit,
                false,
            ).appendTo(li);
        }

        if (found.indexOf(DefaultCategorySettingNames.tabs) === -1) {
            let li = $("<li>").appendTo(ul);
            that.createEdit("Item Tabbing", DefaultCategorySettingNames.tabs, that.editItemTabbing, false).appendTo(li);
        }

        if (found.indexOf(DefaultCategorySettingNames.folderToolsLocation) === -1) {
            let li = $("<li>").appendTo(ul);
            that.createEdit(
                "Folder tools Location",
                DefaultCategorySettingNames.folderToolsLocation,
                that.editToolLocation,
                false,
            ).appendTo(li);
        }

        // allow to create new category setting for super admins only
        if (matrixSession.isSuperAdmin() || globalMatrix.globalShiftDown) {
            let li = $("<li>").appendTo(ul);
            let span = $("<span>").appendTo(li);
            $("<span>").appendTo(span).html("create new category setting");
            $("<span class='editLinkButton'>")
                .html("create")
                .click(function (event) {
                    that.createNew();
                })
                .appendTo(span);
        }
    }

    editToolLocation(): void {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let val: ICategorySettingToolLocation = globalMatrix.ItemConfig.getCategorySetting(
            this.getCategory(),
            DefaultCategorySettingNames.folderToolsLocation,
        ) as ICategorySettingToolLocation;

        let input: ILineEditorLine[] = [
            {
                help: "Show on top",
                type: LineType.boolean,
                value: (val !== null && val.location === "top").toString(),
            },
        ];
        let le = new LineEditor();
        le.showDialog(this, "Folder Tools Location", 280, input, function (update: ILineEditorLine[]) {
            let value: ICategorySettingToolLocation = {
                location: update[0].value ? "top" : "bottom",
            };

            that.configApp.setCategorySettingAsync(
                that.getProject(),
                that.getCategory(),
                DefaultCategorySettingNames.folderToolsLocation,
                JSON.stringify(value),
                that.pageId,
            );
            return true;
        });
    }

    private createEdit(text: string, key: string, fct?: () => void, disabled?: boolean): JQuery {
        let that = this;

        let span = $("<span>");
        $("<span>").appendTo(span).html(text);
        let cls = disabled ? "editLinkButtonDisabled" : "editLinkButton";
        if (fct) {
            $("<span class='" + cls + "'>")
                .html("edit")
                .click(function (event) {
                    if (disabled) {
                        return;
                    }
                    fct.call(that);
                })
                .appendTo(span);
        }
        $("<span class='" + cls + "'>")
            .html("advanced")
            .data("key", key)
            .click(function (event) {
                if (disabled) {
                    return;
                }
                let key = $(event.delegateTarget).data("key");
                let je = new JsonEditor();
                let val = globalMatrix.ItemConfig.getCategorySetting(that.getCategory(), key);
                je.showDialog(
                    "Advanced Edit [" + key + "]",
                    val ? JSON.stringify(val) : "{}",
                    function (newValue: string) {
                        that.configApp.setCategorySettingAsync(
                            that.getProject(),
                            that.getCategory(),
                            key,
                            newValue,
                            that.pageId,
                        );
                    },
                );
            })
            .appendTo(span);

        return span;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private editConcurrentEdit() {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let val: any = globalMatrix.ItemConfig.getCategorySetting(
            this.getCategory(),
            DefaultCategorySettingNames.concurrentEdit,
        );

        let input: ILineEditorLine[] = [
            { help: "Allow concurrent editing", type: LineType.boolean, value: val && val.disabled },
        ];
        let le = new LineEditor();
        le.showDialog(this, "Concurrent Editing", 280, input, function (update: ILineEditorLine[]) {
            let value = {
                disabled: update[0].value,
            };

            that.configApp.setCategorySettingAsync(
                that.getProject(),
                that.getCategory(),
                DefaultCategorySettingNames.concurrentEdit,
                JSON.stringify(value),
                that.pageId,
            );
            return true;
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private editItemTabbing() {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let val: any = globalMatrix.ItemConfig.getCategorySetting(this.getCategory(), DefaultCategorySettingNames.tabs);
        if (!val || !val.tabs) {
            val = { tabs: [] };
        }
        $.each(val.tabs, function (idx, tab) {
            tab.id = idx + 1000;
        });
        let input: ILineEditorLine[] = [
            {
                help: DefaultCategorySettingNames.tabs,
                type: LineType.table,
                value: JSON.stringify(val.tabs),
                columns: [
                    { name: "Tab id", field: "id", editor: ColumnEditor.none },
                    { name: "Tab Name", field: "name", editor: ColumnEditor.textline },
                    //,{name:"Hide for users", field:"hideUser", editor:"user", options:{} }
                ],
            },
        ];
        let le = new LineEditor();
        le.showDialog(this, "Define Tabs", 280, input, function (update: ILineEditorLine[]) {
            if (update[0].value) {
                let newTabs = JSON.parse(update[0].value);
                $.each(newTabs, function (idx, tab) {
                    if (tab.id) {
                        $.each(val.tabs, function (oldIdx, oldTab) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            if (oldTab.id == tab.id) {
                                tab.fields = oldTab.fields;
                            }
                        });
                    } else {
                        tab.fields = [];
                    }
                });
                val.tabs = newTabs;

                that.configApp.setCategorySettingAsync(
                    that.getProject(),
                    that.getCategory(),
                    DefaultCategorySettingNames.tabs,
                    JSON.stringify(val),
                    that.pageId,
                );
            }

            return true;
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private editCategoryIcon() {
        let that = this;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let val: any = globalMatrix.ItemConfig.getCategorySetting(
            this.getCategory(),
            DefaultCategorySettingNames.texticon,
        );

        let input: ILineEditorLine[] = [
            { help: "text", type: LineType.textline, value: val && val.text ? val.text : this.getCategory() },
            { help: "color (by name or hex code)", type: LineType.color, value: val && val.color ? val.color : "grey" },
        ];
        let le = new LineEditor();

        le.showDialog(this, "Edit Category Icon", 280, input, function (update: ILineEditorLine[]) {
            let value = {
                text: update[0].value,
                color: update[1].value,
            };
            if (value.text && value.color) {
                that.configApp.setCategorySettingAsync(
                    that.getProject(),
                    that.getCategory(),
                    DefaultCategorySettingNames.texticon,
                    JSON.stringify(value),
                    that.pageId,
                );
                return true;
            } else {
                alert("please provide text and color");
                return false;
            }
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private editSyncInfo() {
        let that = this;
        let val: ISyncCatgoryInfo = <ISyncCatgoryInfo>(
            globalMatrix.ItemConfig.getCategorySetting(this.getCategory(), DefaultCategorySettingNames.syncInfo)
        );
        if (!val) {
            val = { categories: "", sourceName: "" };
        }
        if (!val.categories) {
            val.categories = "";
        }
        if (!val.sourceName) {
            val.sourceName = "";
        }

        let cats = globalMatrix.ItemConfig.getCategories(true).map(function (cat) {
            return { id: cat, label: cat };
        });

        let input: ILineEditorLine[] = [
            {
                help: "categories",
                type: LineType.select,
                multiple: true,
                options: cats,
                value: val.categories,
                required: true,
            },
            { help: "name of external tool in UI", type: LineType.textline, value: val.sourceName, required: true },
        ];
        let le = new LineEditor();
        le.showDialog(this, "Edit Sync Settings", 280, input, function (update: ILineEditorLine[]) {
            let value = ml.JSON.clone(val);
            value["categories"] = update[0].value;
            value["sourceName"] = update[1].value;

            if (update[0].value && update[1].value) {
                that.configApp.setCategorySettingAsync(
                    that.getProject(),
                    that.getCategory(),
                    DefaultCategorySettingNames.syncInfo,
                    JSON.stringify(value),
                    that.pageId,
                );
                return true;
            } else {
                alert("please provide sync category and external tool name");
                return false;
            }
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createNew() {
        let that = this;

        let input: ILineEditorLine[] = [{ help: "setting key", type: LineType.textline, value: "" }];
        let le = new LineEditor();
        le.showDialog(this, "Create new setting", 240, input, function (update: ILineEditorLine[]) {
            if (update[0].value) {
                that.configApp.setCategorySettingAsync(
                    that.getProject(),
                    that.getCategory(),
                    update[0].value,
                    "{}",
                    that.pageId,
                );
                return true;
            } else {
                alert("please key for new category setting");
                return false;
            }
        });
    }
}
