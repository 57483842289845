/// <reference types="bootstrap" />
import { IReference, IBooleanMap, ControlState, IStringMap, IItem, globalMatrix, app } from "../../../globals";
import { ml } from "../../matrixlib";
import { ILinkType } from "../Components/ItemForm";
import { SelectMode } from "../Components/ProjectViewDefines";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { ItemCreationTools, ICreateDialogButtonOptions } from "../Tools/ItemCreationView";
import { ItemSelectionTools, IItemSelectButtonOptions } from "../Tools/ItemSelectionView";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { DATA_HTMLDIFF_ID } from "../../../../sdk/utils/differs/diffHtml";

export type { ILinkRenderParams, ILinkCollectionOptions, ILinkCategories };
export { LinkCollectionImpl };

interface ILinkRenderParams {
    linkTypes?: ILinkCategories[]; // list of choosable types (normally defined by traceability) e.g. [{'type':'SPEC','name':'Specification','required':'false'}]
    none?: string; // in case there is a text to display that there are no links it can be specified here
    disableCreate?: boolean; // if set to true user cannot create new items, only select is possible,
    readonly?: boolean; // only used internally
    reviewMode?: boolean; // if set to true the outdated icon is not shown
    render?: {
        category?: string;
        hideLink?: boolean;
        buttonName?: string;
        hideCreate?: boolean;
        hideSelect?: boolean;
        ignoreOutOfDate?: boolean; // in case a downlink is out of date do not show it
    }[];
}
interface ILinkCollectionOptions extends IBaseControlOptions {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    item?: any;
    fieldValue?: IReference[];
    parameter?: ILinkRenderParams;
    doNotSave?: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    mitigationRenderer?: Function;
    id?: string;
    tiny?: boolean; // if true render some things 'smaller'
}

interface ILinkCategories {
    name?: string;
    required?: boolean;
    type: string;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
$.fn.linkCollection = function (this: JQuery, options: ILinkCollectionOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_links,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new LinkCollectionImpl(this, options.fieldHandler as EmptyFieldHandler);
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class LinkCollectionImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ILinkCollectionOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private saveInDb: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private itemReferences: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private hideSelect: IBooleanMap;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ignoreOutOfDate: IBooleanMap;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private showLinks: IBooleanMap;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    init(options: ILinkCollectionOptions) {
        let that = this;

        let defaultOptions: ILinkCollectionOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            dummyData: false, // fill control with a dummy text (for form design...)
            fieldValue: [], //current list of links to show [{to:___ , title:____, mitigation: _____}] // mitigation is optional
            doNotSave: false, // set to true if someone uing the control saves it manually ( e.g. risks)
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            valueChanged: function () {}, // callback to call if value changes: removed links, added, created links
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            mitigationRenderer: null, // renders as mititigation. if there are any valueChanged: function () {},
            parameter: {
                linkTypes: [], // list of chooseable types (normally defined by traceability) e.g. [{'type':'SPEC','name':'Specification','required':'false'}]
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                none: null, // in case there is a text to display that there are none (this is the text)
                disableCreate: false, // if set to true user cannot create new items, only select is possible
                render: [], // this option allows to change look of rendering of down traces by category
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);
        if (this.settings.controlState === ControlState.DialogCreate) {
            this._root.html("");
            return;
        }

        this.showLinks = {};
        let hideLinks: IBooleanMap = {};
        let hideCreate: IBooleanMap = {};
        let buttonName: IStringMap = {};
        this.hideSelect = {};
        this.ignoreOutOfDate = {};

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(this.settings.parameter.render, function (ridx, r) {
            if (r.hideLink) {
                hideLinks[r.category] = true;
            }
            if (r.buttonName) {
                buttonName[r.category] = r.buttonName;
            }
            if (r.hideCreate) {
                hideCreate[r.category] = true;
            }
            if (r.hideSelect) {
                that.hideSelect[r.category] = true;
            }
            if (r.ignoreOutOfDate) {
                that.ignoreOutOfDate[r.category] = true;
            }
        });
        let actualCreateTypes: ILinkType[] = [];
        let actualSelectTypes: ILinkType[] = [];
        let showingLinks = false;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(this.settings.parameter.linkTypes, function (typeIdx, lt) {
            if (!hideLinks[lt.type]) {
                that.showLinks[lt.type] = true;
                showingLinks = true;
            }
            if (!hideCreate[lt.type]) {
                actualCreateTypes.push({
                    name: buttonName[lt.type] ? buttonName[lt.type] : lt.name,
                    required: lt.required,
                    type: lt.type,
                });
            }
            if (!that.hideSelect[lt.type]) {
                actualSelectTypes.push({ name: lt.name, required: lt.required, type: lt.type });
            }
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!showingLinks && actualSelectTypes.length == 0 && actualCreateTypes.length == 0) {
            ml.Logger.log("warning", "Hiding links field - no actual downlink defined for category");
            return;
        }
        this._root.append(super.createHelp(this.settings));
        this.itemReferences = $("<ul>");
        this._root.append($("<div class='baseControl'>").append(this.itemReferences));

        if (!this.settings.fieldValue) {
            this.settings.fieldValue = [];
        }
        this._root.data("original", ml.JSON.clone(this.settings.fieldValue));
        this._root.data("new", ml.JSON.clone(this.settings.fieldValue));
        this.saveInDb = true;
        if (this.settings.doNotSave === true) {
            this.saveInDb = false;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let selectTools: any;

        if (
            (this.settings.controlState === ControlState.FormEdit && this.settings.canEdit) ||
            this.settings["allowlinkedit"]
        ) {
            let itemReferenceEdit = $("<div class='hidden-print '></div>");
            this._root.append(itemReferenceEdit);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!this.settings.parameter.disableCreate) {
                let createTools = new ItemCreationTools();
                let options: ICreateDialogButtonOptions = {
                    control: itemReferenceEdit,
                    linkTypes: actualCreateTypes,
                    created: async (newRef: IReference) => {
                        await this.addReference(newRef);
                    },
                    isRiskControl: !!this.settings.mitigationRenderer,
                    dontOpenNewItem: false,
                };
                if (this.settings.tiny) {
                    options.tinybuttons = true;
                }
                createTools.renderButtons(options);
            }
            if (actualSelectTypes.length > 0) {
                selectTools = new ItemSelectionTools();
                let options: IItemSelectButtonOptions = {
                    control: itemReferenceEdit,
                    linkTypes: actualSelectTypes,
                    selectionChange: (newSelection: IReference[]) => this.selectionChange(newSelection),
                    getSelectedItems: async () => {
                        return await this.getValueAsync();
                    },
                    isRiskControl: !!this.settings.mitigationRenderer,
                    selectMode: SelectMode.items,
                };
                if (this.settings.tiny) {
                    options.tinybutton = true;
                }
                selectTools.renderButtons(options);
            }
        }

        this.renderRefs();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refreshLinks() {
        this.renderRefs();
    }
    // public interface
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async hasChangedAsync() {
        return false; // JSON.stringify(_root.data("original")) !== JSON.stringify(_root.data("new"));
    }

    async getValueAsync(): Promise<IReference[]> {
        return this._root.data("new");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue(newVal: IReference[]) {
        this._root.data("new", newVal);
        this.renderRefs();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    resizeItem() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroy() {}

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateItem(newItem: IItem) {
        this._root.data("new", newItem.downLinks);
        this.renderRefs();
    }

    // private functions
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private deleteReference(idToRemove: string) {
        let that = this;

        if (this.saveInDb) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.removeDownLinkAsync(this.settings.id, idToRemove).done(function () {
                that.removeDeletedReference(idToRemove);
            });
        } else {
            that.removeDeletedReference(idToRemove);
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private removeDeletedReference(idToRemove: string) {
        let remove = -1;
        let n: IReference[] = this._root.data("new");
        for (let idx = 0; idx < n.length; idx++) {
            if (n[idx].to === idToRemove) {
                remove = idx;
            }
        }
        if (remove !== -1) {
            n.splice(remove, 1);
            this._root.data("new", n);
            this.renderRefs();
            if (this.settings.valueChanged) {
                this.settings.valueChanged.apply(null);
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async addReference(newRef: IReference) {
        let n: IReference[] = this._root.data("new");
        n.push(newRef);
        this._root.data("new", n);
        if (this.settings.valueChanged) {
            await this.settings.valueChanged.apply(null);
        }
        this.renderRefs();
        if (this.saveInDb) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            await app.addDownLinkAsync(this.settings.id, newRef.to);
        }
    }
    // called after select button was closed with OK
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private selectionChange(newSelection: IReference[]) {
        let that = this;

        if (!this.saveInDb) {
            return;
        }

        // copy old links including mitigation details
        let oldSelection: IReference[] = ml.JSON.clone(this._root.data("new"));
        // maybe merge some stuff into new selection
        $.each(oldSelection, function (oidx, os) {
            if (that.hideSelect[ml.Item.parseRef(os.to).type]) {
                newSelection.push(os);
            }
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let changes = ml.Item.updateReferences(oldSelection, newSelection, this.settings.id, null);
        // save new selection to immediately update UI
        this._root.data("new", newSelection);

        // commit new selection to UI
        app.commitChangeListAsync(changes).always(async function (error, stepsDone) {
            if (error) {
                ml.UI.showError(error, "cancelled operation");
                return;
            }

            // the following is necessary for risks. the changes are only committed
            // when item is saved - but they shall be shown before
            for (let idx = 0; idx < newSelection.length; idx++) {
                for (let oi = 0; oi < oldSelection.length; oi++) {
                    if (oldSelection[oi].to === newSelection[idx].to) {
                        newSelection[idx] = oldSelection[oi];
                        break;
                    }
                }
            }

            if (that.settings.valueChanged) {
                await that.settings.valueChanged.apply(null);
            }

            that.renderRefs();
        });
    }

    private renderRefs(): void {
        let that = this;

        // make sure all the tooltips are gone (MATRIX-2780)
        $(".tooltip").remove();

        let theLinks: IReference[] = this._root.data("new");
        if (!this.itemReferences) {
            // MATRIX-2427
            return;
        }
        // clean
        $("li", this.itemReferences).remove();
        if (theLinks.length === 0 && this.settings.parameter?.none) {
            const referenceLI = $("<li>");
            const reference = $("<span class='nolinks'>").html(this.settings.parameter.none);
            this.itemReferences.append(referenceLI.append(reference));
            return;
        }
        // add current
        let itemDate = this.settings.item ? new Date(this.settings.item.modDate) : null;
        for (let idx = 0; idx < theLinks.length; idx++) {
            let theLinkType = ml.Item.parseRef(theLinks[idx].to).type;
            let datedLink = false;
            const modDate = theLinks[idx].modDate;
            if (modDate && itemDate && !that.ignoreOutOfDate[theLinkType]) {
                let refDate = new Date(modDate);
                if (itemDate > refDate) {
                    datedLink = true;
                }
            }
            const referenceLI = $(`<li ${DATA_HTMLDIFF_ID}="${theLinks[idx].to}">`);

            if (!this.showLinks[theLinkType]) {
                referenceLI.hide();
            }

            const reference = $("<span class='highlight-diff'>");
            this.itemReferences.append(referenceLI.append(reference));
            if (datedLink && !this.settings.parameter?.reviewMode) {
                let datedIcon = $('<span class="fal fa-history datedLink"></span>)');
                datedIcon.tooltip(<TooltipOptions>{
                    container: "body",
                    title: "referenced item is older than this item",
                });
                referenceLI.append(datedIcon);
            }

            reference.refLink({
                folder: false, // assuming refs cannot be folders for now
                id: theLinks[idx].to,
                title: theLinks[idx].title,
                style: this.settings.isHistory ? refLinkStyle.link : refLinkStyle.select,
                tooltip: this.settings.controlState === ControlState.Print ? refLinkTooltip.none : refLinkTooltip.html,
                isHidden: app.isHiddenLink(theLinks[idx].to),
                callback: function (id: string) {
                    app.treeSelectionChangeAsync(id)
                        .done(() => {})
                        .fail(() => {});
                },
            });
            if (this.settings.mitigationRenderer) {
                this.settings.mitigationRenderer(referenceLI, theLinks[idx].to, idx);
            }
            if (
                (this.settings.controlState === ControlState.FormEdit && this.settings.canEdit) ||
                this.settings["allowlinkedit"]
            ) {
                let button = $(
                    "<span title data-original-title='Remove link' class='btn-deleteRef hidden-print'> <i class='fal fa-unlink'></i></span>",
                );
                button.data("to", theLinks[idx].to).click(function (elem: JQueryEventObject) {
                    let whatToDelete = $(elem.delegateTarget).data("to");
                    ml.UI.showConfirm(
                        2,
                        { title: "Remove link to '" + whatToDelete + "'?", ok: "Remove" },
                        function () {
                            that.deleteReference(whatToDelete);
                        },
                        () => {},
                    );
                });
                button.tooltip();
                referenceLI.append(button);
            }
        }
    }
}
