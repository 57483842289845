import { ILabelGroup, ILabel, ILabelInstance, IAutoFillSetting, ILabelsConfig } from "../../../ProjectSettings";
import { XRLabelHistory } from "../../../RestResult";
import { IItem, globalMatrix, matrixSession, app, ControlState, IStringMap, restConnection } from "../../../globals";
import { MR1 } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { ml } from "../../matrixlib";
import { LabelTools } from "../../matrixlib/LabelTools";

export { LabelSwitches };

class LabelSwitches extends LabelTools {
    private lexist: boolean = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private isFilter: boolean;

    private dbClickCounter = 0;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private dbClickTimer: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private groups: ILabelGroup[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ui: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private canEdit: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private category: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private currentLabelsOn: string[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mode: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private valueChanged: (clo: string[]) => void;
    private item?: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private restrictEditTo: string[];
    private canAutoFill = false;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor(
        ui: JQuery,
        canEdit: boolean,
        category: string,
        currentLabelsOn: string[],
        mode: string,
        valueChanged: (clo: string[]) => void,
        item?: IItem,
        restrictEditTo?: string[],
    ) {
        super(ml.Logger, ml.JSON);

        // get label definitions
        let labelList = this.getLabelList();
        if (labelList.length === 0) {
            return;
        }

        // get all label names
        let allLabels = this.getLabelNames();
        if (allLabels.length === 0) {
            return;
        }

        // there are some labels
        this.lexist = true;
        this.isFilter = category === null;

        // set other parameters
        this.ui = ui;
        this.canEdit = canEdit;
        this.category = category;
        this.currentLabelsOn = currentLabelsOn;
        this.mode = mode;
        this.valueChanged = valueChanged;
        this.item = item;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.restrictEditTo = restrictEditTo;

        // get label groups
        this.groups = this.getLabelGroups(category);

        this.showLabels();
    }

    protected hasCombinedFilterMenu(): boolean {
        let conf = this.getConfig().getSetting("labels");
        if (!conf) {
            return false;
        }
        let confJson = this.json.fromString(conf);
        if (confJson.status !== "ok") {
            return false;
        }
        return this.json.isTrue((<ILabelsConfig>confJson.value).useFilterMenu);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private showLabels() {
        let setting = this.getConfig().getLabelsConfig();
        if (!setting) {
            return;
        }
        // each group is a button group with at least one button inside
        for (let gid = 0; gid < this.groups.length; gid++) {
            let group;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.mode == "filter_chip") {
                group = this.addChip(gid);
                continue;
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.groups[gid].labelDef.length === 0) {
                continue; // next group
            }

            let selectionMethod = this.isFilter ? this.groups[gid].filterSelection : this.groups[gid].selection;
            if (selectionMethod === "design_review" && this.mode !== "item_create") {
                group = this.create_group_design_review(gid);
            } else if (selectionMethod === "review" && this.mode !== "item_create") {
                group = this.create_group_review(gid);
            } else if (selectionMethod === "or") {
                group = this.create_group_or(gid);
                if (this.mode !== "doc_filter" && this.isFilter && this.groups[gid].filterMenu) {
                    if (this.hasCombinedFilterMenu()) {
                        this.add_to_global_dropdown(gid, group);
                    } else {
                        this.create_dropdown_group(gid, group);
                    }
                }
            } else if (selectionMethod === "xor" || (selectionMethod === "review" && this.mode === "item_create")) {
                group = this.create_group_xor(gid);
                if (this.mode === "item_create" && !this.json.isTrue(this.groups[gid].defaultAsk)) {
                    group.hide();
                }
            }
            // bug fix (groups are right to left in item title bar)
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (group && this.mode == "item_title" && !setting.invertGroups) {
                group.css("float", "none");
            }
        }
        // hack -> hide the empty label heading if there's no labels
        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.mode == "item_create" &&
            this.groups.filter(
                (g) => !!g.defaultAsk || (g.labelDef && g.labelDef.filter((l) => !!l.defaultAsk).length > 0),
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            ).length == 0
        ) {
            // use does not need to set labels when creating new items
            $(".baseControlHelp", this.ui.parent()).hide();
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setEnabled(enabled: boolean) {
        if (enabled) {
            $("#labelDisabler").remove();
        } else {
            this.ui.css("position", "relative");
            $("<div id='labelDisabler'>").appendTo(this.ui);
        }
    }

    labelsExist(): boolean {
        return this.lexist;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private canEditLabel(labelId: string) {
        if (!this.canEdit) {
            return false;
        }
        if (!this.restrictEditTo) {
            return true;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.restrictEditTo.indexOf(labelId) != -1;
    }

    /**
     *  this.ui elements for different label this.groups
     */
    private create_group_or(gid: number): JQuery {
        let that = this;

        let btnGroup = $('<div class="btn-group labelTools">');
        this.ui.append(btnGroup);

        $.each(this.groups[gid].labelDef, function (idx: number, label: ILabel) {
            label.isNegative = that.currentLabelsOn.indexOf("!" + label.label) > -1;
            label.isSelected = label.isNegative || that.currentLabelsOn.indexOf(label.label) > -1;

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let switchFunction: Function; // single click
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let invertFunction: Function; // dbl click for filter or null
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            switchFunction = function (button: JQuery) {
                if (!that.canEditLabel(label.label)) {
                    return;
                }

                let rememberBefore = that.json.clone(that.currentLabelsOn);

                let click_label = button.data("label");
                let click_gid = button.data("gid");

                $.each(that.groups[click_gid].labelDef, function (idx, label) {
                    if (label.label === click_label) {
                        label.isSelected = !label.isSelected;
                        label.isNegative = false;
                    }
                });

                button.tooltip("hide");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                button.replaceWith(that.createLabel(click_label, gid, switchFunction, invertFunction));

                if (that.valueChanged) {
                    that.updateSelection();
                    that.valueChanged(that.currentLabelsOn);
                }
                that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);
            };

            if (that.isFilter && that.canEdit) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                invertFunction = function (button: JQuery) {
                    if (!that.canEditLabel(label.label)) {
                        return;
                    }

                    let click_label = button.data("label");
                    let click_gid = button.data("gid");
                    let negativePrefix = "";
                    $.each(that.groups[click_gid].labelDef, function (idx, label) {
                        if (label.label === click_label) {
                            if (label.isSelected) {
                                label.isNegative = !label.isNegative;
                                negativePrefix = label.isNegative ? "!" : "";
                            } else {
                                label.isSelected = true;
                                label.isNegative = true;
                                negativePrefix = "!";
                            }
                        }
                    });

                    button.tooltip("hide");
                    button.replaceWith(
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.createLabel(negativePrefix + click_label, gid, switchFunction, invertFunction),
                    );

                    if (that.valueChanged) {
                        that.updateSelection();
                        that.valueChanged(that.currentLabelsOn);
                    }
                };
            }

            let btn = that.createLabel(
                (label.isNegative ? "!" : "") + label.label,
                gid,
                switchFunction,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                invertFunction,
            );
            // add button to group
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            btnGroup.append(btn);
            // hide button if it should not be shown in create dialog
            if (that.mode === "item_create" && !that.json.isTrue(label.defaultAsk)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                btn.hide();
            }
        });
        return btnGroup;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private triggerLabelChanged(before: string[], after: string[]) {
        if (this.isFilter) {
            return;
        }
        let set = after.filter(function (label) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return before.indexOf(label) == -1;
        });
        let unset = before.filter(function (label) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return after.indexOf(label) == -1;
        });
        if (set.length > 0 || unset.length > 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            MR1.triggerAfterLabelChange(this.item, set, unset);
        }
    }

    private create_group_xor(gid: number): JQuery {
        let that = this;

        let btnGroup = $('<div class="btn-group labelTools">');
        this.ui.append(btnGroup);

        let canEditGroup = false;
        $.each(this.groups[gid].labelDef, function (idx, label) {
            if (that.canEditLabel(label.label)) {
                canEditGroup = true;
            }
        });

        $.each(this.groups[gid].labelDef, function (idx, label) {
            label.isSelected = that.currentLabelsOn.indexOf(label.label) > -1;

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let switchFunction: Function;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            switchFunction = function (button: JQuery) {
                let rememberBefore = that.json.clone(that.currentLabelsOn);
                if (!canEditGroup) {
                    return;
                }
                button.tooltip("hide");
                let click_label = button.data("label");
                let click_gid = button.data("gid");

                $.each(that.groups[click_gid].labelDef, function (idx, label) {
                    // not possible to unselect a label, so clicked label will always be on
                    label.isSelected = label.label === click_label;
                    let newButton = that.createLabel(label.label, gid, switchFunction);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (<ILabelInstance>label).btn.replaceWith(newButton);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (<ILabelInstance>label).btn = newButton;
                });

                if (that.valueChanged) {
                    that.updateSelection();
                    that.valueChanged(that.currentLabelsOn);
                }
                that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);
            };

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            (<ILabelInstance>label).btn = that.createLabel(label.label, gid, switchFunction);

            // add button to group
            btnGroup.append((<ILabelInstance>label).btn);
        });

        return btnGroup;
    }

    private create_group_review(gid: number): JQuery {
        let that = this;

        let btnGroup = $('<div class="btn-group labelTools">');
        this.ui.append(btnGroup);

        // special handling for review labels! if no review flag is set show it in item as not set
        let color = this.groups[gid].noColor ? this.groups[gid].noColor : "red";
        let icon = this.groups[gid].noIcon ? this.groups[gid].noIcon : null;
        let name = this.groups[gid].noName ? this.groups[gid].noName : icon ? "" : "not set";

        // this will be a drop down button
        let btn: JQuery;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.mode == "item_history") {
            // in history render a simplified version of labels to be more easily comparable
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            btn = this.createButton("", gid, name, "", "", icon, null, null, "labelInHistory");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            btn = this.createButton("", gid, name, color, "transparent", icon, null, null, "labelIsMenu");
        }
        // for reviews it is possible that no label is set or one...
        // the one which is set is the only one displayed

        // figure out if the user can change something in group
        // canEdit must be true
        // but if this is a lock keeper, he can undo only his locks....
        let canEditGroup = false;
        $.each(this.groups[gid].labelDef, function (idx: number, label: ILabel) {
            if (that.canEditLabel(label.label)) {
                canEditGroup = true;
            }
        });

        $.each(this.groups[gid].labelDef, function (idx: number, label: ILabel) {
            label.isSelected = false;
            if (that.currentLabelsOn.indexOf(label.label) !== -1) {
                label.isSelected = true;
                // this is part of drop down menu....
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                btn = that.createLabel(label.label, gid, function () {});
                btn.addClass("labelIsMenu");
            }
        });

        let tooltip = this.groups[gid].tooltip ? this.groups[gid].tooltip : "";
        if (tooltip) {
            btn.tooltip({ title: tooltip, placement: "bottom", container: "body" });
        }

        // check if there are defined reviewers of label
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let userCanDo = matrixSession.amIAllowedUser(this.groups[gid].reviewers);

        if (!userCanDo || this.mode === "item_print" || !canEditGroup) {
            // we are done... this is not interactive...
            btn.css("cursor", "inherit");
            btnGroup.append(btn);
            return btnGroup; //$("");
        }

        // add drop down menu
        btn.addClass("dropdown-toggle");
        btn.attr("data-toggle", "dropdown");
        btn.append("<span class='caret' style='margin-left:5px'/>");
        let ddbtn = $('<div class="dropdown">');
        ddbtn.append(btn);
        let options = $('<ul  class="dropdown-menu">');
        $.each(this.groups[gid].labelDef, function (idx: number, label: ILabel) {
            let icon = "<span style='margin-right:8px'/>";
            let name = label.displayName ? label.displayName : "";
            if (label && label.style && label.style.label && label.style.label.on) {
                if (label.style.label.on.icon) {
                    let color = label.style.label.on.foreground ? "color:" + label.style.label.on.foreground + ";" : "";
                    let bg = label.style.label.on.background
                        ? "background-color:" + label.style.label.on.background + ";"
                        : "";

                    icon = `<i class='labelMenuIcon fal fa-${label.style.label.on.icon}' style='${color}${bg}' ></i>`;
                }
                if (label.style.label.on.displayName) {
                    name = label.style.label.on.displayName;
                }
            }

            let li = $("<li class='labelMenuEntry'></li>").html(
                '<a href="javascript:void(0)" style="min-width:100%">' + icon + name + "</a>",
            );
            li.data("label", label.label);
            li.data("gid", gid);
            options.append(li);
            li.click(function (e: JQueryEventObject) {
                $(".tooltip").remove();
                let rememberBefore = that.json.clone(that.currentLabelsOn);

                // set just the selected label from the group
                let group = that.groups[$(e.delegateTarget).data("gid")];
                let selected = $(e.delegateTarget).data("label");

                let askForComment = group.askForComment ? group.askForComment : false;
                $.each(group.labelDef, function (idx: number, label: ILabel) {
                    if (label.label === selected) {
                        askForComment = label.askForComment ? label.askForComment : askForComment;
                    }
                });
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                function setLabel(comment?: string) {
                    let labelsOff: string[] = [];
                    $.each(group.labelDef, function (idx, label) {
                        label.isSelected = label.label === selected;
                        if (!label.isSelected) {
                            labelsOff.push(label.label);
                        }
                    });
                    // set and save label
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.saveAfterLabelChange(comment);
                }
                if (askForComment) {
                    $("#dialog_comment_text").val(matrixSession.getComment());
                    $("#dialog_comment_ok").off("click");
                    $("#dialog_comment_nok").off("click");

                    $("#dialog_comment_ok").one("click", function () {
                        setLabel($("#dialog_comment_text").val());
                        that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);
                    });
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    $("#dialog_comment_nok").one("click", function () {});

                    $("#dialog_comment").modal({ backdrop: "static" });
                } else {
                    setLabel();
                    that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);
                }
            });
        });

        btnGroup.append(ddbtn);

        if (this.groups[gid].showComments && this.item && this.item.history) {
            let history = this.item.history;
            let reviews = 0;
            $.each(history, function (hixd, h) {
                reviews += h.action === "reviewed" ? 1 : 0;
            });
            if (reviews > 0) {
                options.append($("<li class='' >").html("<hr>"));
                $.each(history, function (hixd, h) {
                    if (h.action === "reviewed") {
                        options.append(
                            $(" <li class='reviewedCommentMenutitem' style='display:flex'>").html(
                                "<span class='hqlu'>" +
                                    h.user +
                                    "</span> <span class='hqld' >(" +
                                    h.dateUserFormat +
                                    "):</span> <span class='hqlc'>" +
                                    h.comment +
                                    "</span>",
                            ),
                        );
                    }
                });
                options.width(300 + ddbtn.width());
            }
            options.css("top", "30px");
        }
        ddbtn.append(options);

        return btnGroup;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private saveAfterLabelChange(comment: string) {
        let that = this;
        // set label
        that.updateSelection();

        // save: pass the labels to the touch, this overwrites the labels
        // a normal save reset's review labels!
        let mainComment = matrixSession.getComment();
        if (comment) {
            matrixSession.setComment(comment);
        }
        that.setEnabled(false); // save will render labels as readonly

        app.setLabels({ labels: that.currentLabelsOn })
            .done(function () {
                // after save item will be repainted
                matrixSession.setComment(mainComment);
            })
            .fail(() => {
                that.setEnabled(true); // save will render labels 'normally'
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private create_group_design_review(gid: number) {
        let that = this;

        let btnGroup = $('<div class="btn-group labelTools">');
        this.ui.append(btnGroup);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let label of this.groups[gid].labelDef) {
            label.isNegative = that.currentLabelsOn.indexOf("!" + label.label) > -1;
            label.isSelected = label.isNegative || that.currentLabelsOn.indexOf(label.label) > -1;

            // TODO: convert to const and make sure it's still works
            // eslint-disable-next-line no-var
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            var switchFunction: Function; // single click
            // TODO: convert to const and make sure it's still works
            // eslint-disable-next-line no-var
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            var invertFunction: Function; // dbl click for filter or null
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            switchFunction = async function (button: JQuery) {
                let rememberBefore = that.json.clone(that.currentLabelsOn);

                let click_label = button.data("label");

                if (that.mode === "item_print" || !that.canEditLabel(click_label)) {
                    return;
                }

                let click_gid = button.data("gid");

                // get the label definition
                let labelDef: ILabel;
                $.each(that.groups[click_gid].labelDef, function (idx, label) {
                    if (label.label === click_label) {
                        labelDef = label;
                    }
                });
                // get review job details
                let review_job = that.getDesignReview(click_label);

                // check if there are defined reviewers of label
                let userCanDo = matrixSession.amIAllowedUser(
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    review_job.reviewerField
                        ? // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          await that.getUsersFromField(review_job.reviewerField)
                        : // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          review_job.reviewers,
                );
                if (!userCanDo) {
                    ml.UI.showError("You do not have sufficient rights to perform review", "", 3000);
                }

                button.tooltip("hide");

                function setReviewResult(comment: string, passed: boolean): void {
                    $.each(that.groups[click_gid].labelDef, function (idx, label) {
                        if (label.label === click_label) {
                            label.isSelected = passed;
                            label.isNegative = false;
                        }
                    });

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    button.replaceWith(that.createLabel(click_label, gid, switchFunction, invertFunction));
                    // set and save label
                    that.saveAfterLabelChange(comment);
                }

                // here we can show a dialog (since this never happens while creating an item
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                async function reviewStatusChange() {
                    let okButton = $(".ui-dialog-buttonpane button:contains('Pass')", app.dlgForm.parent());
                    let failButton = $(".ui-dialog-buttonpane button:contains('Fail')", app.dlgForm.parent());

                    if (!userCanDo) {
                        okButton.prop("disabled", true);
                        okButton.addClass("ui-state-disabled");
                        failButton.prop("disabled", true);
                        failButton.addClass("ui-state-disabled");
                        return;
                    }
                    let isOk = (await drComment.getController().getValueAsync()) !== "";
                    if (isOk) {
                        $.each($(".drcbcontrol", app.dlgForm), function (idx, drcb) {
                            if (!$(drcb).data("new")) {
                                // get the value directly from control
                                isOk = false;
                            }
                        });
                    }
                    if (isOk) {
                        okButton.prop("disabled", false);
                        okButton.removeClass("ui-state-disabled");
                    } else {
                        okButton.prop("disabled", true);
                        okButton.addClass("ui-state-disabled");
                    }
                }

                app.dlgForm.hide();
                app.dlgForm.html("");
                app.dlgForm.removeClass("dlg-no-scroll");
                app.dlgForm.addClass("dlg-v-scroll");
                let settingsUI = $("<div>");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                settingsUI.append($("<h2>").html(review_job.reviewHelp));
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $.each(review_job.reviewDetails, function (idx, rd) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (rd.type == "signature") {
                        settingsUI.append($("<p style='margin:0px 20px 10px 0px;font-weight: bold;'>").html(rd.name));

                        // do not allow chrome to fill passwords (quite a hack)
                        let autoFill = <IAutoFillSetting>matrixSession.getCustomerSettingJSON("autoFillSettings", {});
                        that.canAutoFill = autoFill.allowAutoFill || autoFill.allowLabelSignAutoFill;

                        let form = $("<form>").appendTo(settingsUI);
                        let table = $(
                            '<div class="signatureInfo input-group" style="margin-top:12px;width:100%">',
                        ).appendTo(form);
                        let name = $(
                            "<input autocomplete='off' type='text' class='signatureInfo form-control' placeholder='enter user id' style='padding:8px'>",
                        );
                        let pwd = $(
                            "<input type='" +
                                (that.canAutoFill ? "password" : "text") +
                                "' class='review_password form-control' placeholder='password' style='margin: 0px 20px 20px 0px; width: 200px;'>",
                        );
                        let pwdSpan = $("<span class='input-group-btn'>").append(pwd);
                        table.append(name);
                        table.append(pwdSpan);
                        pwd.on("change keyup paste", function () {
                            reviewStatusChange();
                        });
                        pwd.on("keyup paste", function () {
                            pwd.attr("type", "password");
                        });
                        // set user name and reset password (in case it is a not allowed auto fill)
                        that.initPasswordField(name, pwd);
                    } else {
                        settingsUI.append(
                            $("<div class='drcbcontrol'>").checkBox({
                                controlState: ControlState.FormEdit, // read only rendering
                                canEdit: userCanDo,
                                fieldValue: "false",
                                help: rd.name,
                                valueChanged: async function (checked: boolean) {
                                    let comment =
                                        (await drComment.getController().getValueAsync()) +
                                        "\n" +
                                        rd.name +
                                        " " +
                                        (checked ? "passed" : "failed");
                                    drComment.getController().setValue(comment);
                                    reviewStatusChange();
                                },
                            }),
                        );
                    }
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    settingsUI.append($("<p style='margin:-7px 20px 10px 26px'>").html(rd.help));
                });
                // TODO: convert to const and make sure it's still works
                // eslint-disable-next-line no-var
                var drComment = $("<div id='designReviewComment'>").plainText({
                    controlState: ControlState.FormEdit,
                    canEdit: userCanDo,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    help: review_job.commentHeading ? review_job.commentHeading : "Design Review Comment",
                    fieldValue: matrixSession.getComment(),
                    valueChanged: function () {
                        reviewStatusChange();
                    },
                    parameter: {
                        allowResize: true,
                    },
                });

                settingsUI.append(drComment);
                app.dlgForm.append(settingsUI);
                app.dlgForm.dialog({
                    autoOpen: true,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    title: review_job.reviewName,
                    height: app.itemForm.height() * 0.9,
                    width: 730,
                    modal: true,
                    open: function () {
                        reviewStatusChange();
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    close: function () {},
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    resizeStop: function () {},
                    buttons: [
                        {
                            text: "Pass",
                            class: "btnDoIt",
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            click: async function () {
                                if ($(".review_password", app.dlgForm).length) {
                                    let pwd = $(".review_password", app.dlgForm).val();

                                    app.checkPassword(pwd)
                                        .done(function () {
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            that.verifyRevisionTable(click_label, review_job.revisionTableName).then(
                                                async () => {
                                                    setReviewResult(
                                                        await drComment.getController().getValueAsync(),
                                                        true,
                                                    );
                                                    that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);
                                                },
                                            );

                                            app.dlgForm.dialog("close");
                                        })
                                        .fail(function (jqxhr, textStatus, error) {
                                            ml.UI.showError("Incorrect  password!", "");
                                        });
                                } else {
                                    setReviewResult(await drComment.getController().getValueAsync(), true);
                                    that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);

                                    app.dlgForm.dialog("close");
                                }
                            },
                        },
                        {
                            text: "Fail",
                            class: "btnDoIt2",
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            click: async function () {
                                setReviewResult(await drComment.getController().getValueAsync(), false);
                                that.triggerLabelChanged(rememberBefore, that.currentLabelsOn);
                                app.dlgForm.dialog("close");
                            },
                        },
                        {
                            text: "Cancel",
                            class: "btnCancelIt",
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            click: function () {
                                app.dlgForm.dialog("close");
                            },
                        },
                    ],
                });
            };

            if (that.isFilter && that.canEdit) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                invertFunction = function (button: JQuery) {
                    let click_label = button.data("label");
                    let click_gid = button.data("gid");

                    $.each(that.groups[click_gid].labelDef, function (idx, label) {
                        if (label.label === click_label) {
                            if (label.isSelected) {
                                label.isNegative = !label.isNegative;
                            } else {
                                label.isSelected = true;
                                label.isNegative = true;
                            }
                        }
                    });

                    button.tooltip("hide");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    button.replaceWith(that.createLabel(click_label, gid, switchFunction, invertFunction));

                    if (that.valueChanged) {
                        that.updateSelection();
                        that.valueChanged(that.currentLabelsOn);
                    }
                };
            }
            let btn = that.createLabel(
                (label.isNegative ? "!" : "") + label.label,
                gid,
                switchFunction,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                invertFunction,
            );

            // add button to group
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            btnGroup.append(btn);

            // hide button if it should not be shown in create dialog
            if (that.mode === "item_create") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                btn.hide();
            }
        }
        return btnGroup;
    }

    protected async verifyRevisionTable(label: string, revisionTableName: string): Promise<void> {
        let that = this;
        // TODO: refactor it
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async function (resolve, reject) {
            if (!that.item) {
                // nothing to worry about - we are creating a new item
                resolve();
                return;
            }
            if (!revisionTableName) {
                // nothing to worry about - this is a normal label
                resolve();
                return;
            }

            let revisionTableField = that.getConfig().getFieldByName(that.category, revisionTableName);
            if (!revisionTableField) {
                // nothing to worry about - there is no revision table in this category
                that.logger.log(
                    "warn",
                    `Label has revisionTableName '${revisionTableName}' defined but there is no such table in ${that.category}`,
                );
                resolve();
                return;
            }

            let revisionColumn: string;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (!revisionTableField.parameterJson || !(<any>revisionTableField.parameterJson.columns)) {
                // nothing to worry about - there is no columns in table in this category
                that.logger.log(
                    "warn",
                    `Label has revisionTableName '${revisionTableName}' defined but there are no columns defined ${that.category}`,
                );
                resolve();
                return;
            }

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            for (let column of <any>revisionTableField.parameterJson.columns) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (column.editor == "revision") {
                    revisionColumn = column.field;
                }
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!revisionColumn) {
                // nothing to worry about - there is no revision column in table
                that.logger.log(
                    "warn",
                    `Label has revisionTableName '${revisionTableName}' defined but there is no revision column in table in ${that.category}`,
                );
                resolve();
                return;
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (revisionTableField.fieldType != FieldDescriptions.Field_steplist) {
                that.logger.log(
                    "warn",
                    `Label has revisionTableName '${revisionTableName}' defined but the field is not a table in ${that.category}`,
                );
                resolve();
                return;
            }

            let revisionTableVal = await app.getFieldValueAsync(revisionTableField.id);
            let currentValue = that.getRevisionFromTable(revisionTableVal, revisionColumn);

            if (!currentValue) {
                // empty table
                ml.UI.showConfirm(
                    -1,
                    {
                        title: `No revision defined in last row of table '${revisionTableName}'!`,
                        ok: "Set Label",
                        nok: "Cancel",
                    },
                    () => {
                        resolve();
                    },
                    () => {
                        reject();
                    },
                );
                return;
            }

            let empty = false;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            for (let column of <any>revisionTableField.parameterJson.columns) {
                if (!that.getRevisionFromTable(revisionTableVal, column.field)) {
                    empty = true;
                }
            }

            if (empty) {
                ml.UI.showConfirm(
                    -1,
                    {
                        title: `Last row of table '${revisionTableName} has empty cells'!`,
                        ok: "Set Label",
                        nok: "Cancel",
                    },
                    () => {
                        $(".modal-backdrop").remove();
                        that.compareRevisions(currentValue, revisionTableField.id, revisionColumn, label)
                            .done(() => {
                                resolve();
                            })
                            .fail(() => {
                                reject();
                            })
                            .always(() => {
                                $(".modal-backdrop").remove();
                            });
                    },
                    () => {
                        reject();
                    },
                );
            } else {
                that.compareRevisions(currentValue, revisionTableField.id, revisionColumn, label)
                    .done(() => {
                        resolve();
                    })
                    .fail(() => {
                        reject();
                    })
                    .always(() => {
                        $(".modal-backdrop").remove();
                    });
            }
            return;
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private compareRevisions(
        currentRevision: string,
        versionTableField: number,
        revisionTableColumn: string,
        label: string,
    ) {
        let res = $.Deferred();
        let that = this;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.getLastTimeLabelWasSet(that.item.id, label, 0).then((lastRevision: number) => {
            if (!lastRevision) {
                // it was never set ... no problem
                res.resolve();
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.getItemAsync(that.item.id, lastRevision)
                    .done(function (lastSetVersion) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let lv = lastSetVersion[versionTableField];
                        let fieldValue = JSON.parse(lv ? lv : "{}");

                        let previousRevision = fieldValue
                            ? that.getRevisionFromTable(JSON.stringify(fieldValue), revisionTableColumn)
                            : "";
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (currentRevision != previousRevision) {
                            // we assume all is good...
                            res.resolve(currentRevision);
                        } else {
                            ml.UI.showConfirm(
                                -1,
                                {
                                    title: `The current revision '${currentRevision}' is the same as it was when the label '${label}' was set the last time!`,
                                    ok: "Set Label",
                                    nok: "Cancel",
                                },
                                () => {
                                    res.resolve();
                                },
                                () => {
                                    res.reject();
                                },
                            );
                        }
                    })
                    .fail(function (error) {
                        res.reject(error);
                    });
            }
        });

        return res;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getRevisionFromTable(revisionTableVal: string, revisionColumn: string) {
        let revisionTable = <IStringMap[]>(revisionTableVal ? JSON.parse(revisionTableVal) : []);
        if (!revisionTable.length) {
            // empty table
            return "";
        }
        return revisionTable[revisionTable.length - 1][revisionColumn];
    }
    // get a list of users / user groups from a drop down field
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected async getUsersFromField(fieldName: string) {
        let cat = ml.Item.parseRef(app.getCurrentItemId()).type;
        let field = this.getConfig().getFieldByName(cat, fieldName);
        if (!field) {
            this.logger.log(
                "warning",
                `the field ${fieldName} does not exist in category ${cat}. Cannot get users from that field.`,
            );
            return [];
        }
        let val = await app.getFieldValueAsync(field.id);

        return val.split(",");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected initPasswordField(name: JQuery, pwd: JQuery) {
        name.val(matrixSession.getUser() + (this.canAutoFill ? "" : " ")); // the " "  makes sure the password is not filled when loading
        name.attr("readonly", "readonly");

        if (this.canAutoFill) {
            return;
        }
        pwd.val("");
    }

    // move all buttons into one dropdown (for filter)
    private add_to_global_dropdown(gid: number, btnGroup: JQuery): void {
        let that = this;

        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var globalFilterMenu = this.ui.find(".globalFilterMenu");
        if (globalFilterMenu.length === 0) {
            let somethingSelected = this.currentLabelsOn.length > 0 ? "Filter" : "Filter";
            let menuGroup = $('<div id="setFilter"  class="btn-group dropdown">');
            let menuBtn = $(
                '<button class="btn btn-default btn-label dropdown-toggle filterMenu" data-toggle="dropdown">' +
                    somethingSelected +
                    '&nbsp;<span class="caret"></span></button>',
            );
            // TODO: convert to const and make sure it's still works
            // eslint-disable-next-line no-var
            var globalFilterMenu = $(
                '<ul class="dropdown-menu globalFilterMenu" style="max-height:' +
                    ($(window).height() - 100) +
                    'px" role="menu">',
            );
            menuGroup.append(menuBtn).append(globalFilterMenu);
            this.ui.append(menuGroup);
            if (this.currentLabelsOn.length > 0) {
                let reset = $('<a href="javascript:void(0)" id="resetFilter">Remove all filter</a>');
                reset.click(function () {
                    that.valueChanged([]);
                });
                globalFilterMenu.append($("<li>").append(reset));
            }
        }

        // create menu section:
        // add line unless this is the first thing in the menu
        if ($(".globalFilterMenu li").length > 0) {
            globalFilterMenu.append("<li class='limenuLine'><span class='menuLine'></span></li>");
        }
        // add group name if there is one

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.groups[gid].filterMenu && this.groups[gid].filterMenu.displayName) {
            let lastMenuHeadings = $(".menuHeading", globalFilterMenu);
            let lastIsSame =
                lastMenuHeadings.length &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                $(lastMenuHeadings[lastMenuHeadings.length - 1]).text() == this.groups[gid].filterMenu.displayName;
            if (!lastIsSame) {
                globalFilterMenu.append(
                    "<li class='globalProjectFilter__group'><p class='menuHeading'>" +
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.groups[gid].filterMenu.displayName +
                        "</p></li>",
                );
            }
        }

        // move all buttons in a dropdown list with buttons unless they are selected
        btnGroup.find("button").each((idx, btn) => {
            const label = $(btn).data("label");
            if (that.currentLabelsOn.indexOf("!" + label) === -1 && that.currentLabelsOn.indexOf(label) === -1) {
                globalFilterMenu.append($("<li class='btnline'>").append(btn));

                const nf = this.getNegativeFilterBtn();
                $(btn).parent().prepend(nf);

                nf.click(function (e: JQueryEventObject) {
                    const label = $(e.delegateTarget).data("label");
                    that.currentLabelsOn.push("!" + label);
                    that.valueChanged(that.currentLabelsOn);
                })
                    .data("label", label)
                    .tooltip({ placement: "bottom", container: "body" });
            }
        });
        // move filter select dropdown after btn group
        $("#setFilter").insertAfter(btnGroup);
    }

    // move all labels of one group into a dropdown (rather than simple buttons)
    private create_dropdown_group(gid: number, btnGroup: JQuery): void {
        let that = this;

        let somethingSelected = false;
        $.each(this.groups[gid].labelDef, function (idx, label) {
            somethingSelected =
                somethingSelected ||
                that.currentLabelsOn.indexOf("!" + label.label) > -1 ||
                that.currentLabelsOn.indexOf(label.label) > -1;
        });

        // modify button group to a dropdown button group
        btnGroup.addClass("dropdown");
        //   btnGroup.removeClass("labelTools");

        // move all buttons in a dropdown list with buttons
        const btnUl = $('<ul class="dropdown-menu globalFilterMenu" role="menu">');
        btnGroup.find("button").each((idx: number, btn: Element) => {
            btnUl.append($("<li class='filterGroupDD'>").append(btn));

            if (that.currentLabelsOn.indexOf("!" + $(btn).data("label")) === -1) {
                const nf = this.getNegativeFilterBtn();
                $(btn).parent().prepend(nf);

                nf.click(function (e: JQueryEventObject) {
                    $(".tooltip").remove();

                    const alreadySet = that.currentLabelsOn.indexOf($(e.delegateTarget).data("label"));

                    if (alreadySet > -1) {
                        that.currentLabelsOn.splice(alreadySet, 1);
                    }

                    const label = $(e.delegateTarget).data("label");
                    that.currentLabelsOn.push("!" + label);
                    that.valueChanged(that.currentLabelsOn);
                })
                    .data("label", $(btn).data("label"))
                    .tooltip({ placement: "bottom", container: "body" });
            }
        });

        // create menu button
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let menuDefinition = somethingSelected ? this.groups[gid].filterMenu.on : this.groups[gid].filterMenu.off;
        if (!menuDefinition) {
            menuDefinition = {
                foreground: "black",
                background: "transparent",
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                displayName: this.groups[gid].filterMenu.displayName,
                tooltip: "",
            };
        }
        let i = "";
        if (menuDefinition.icon) {
            i = "<i class='fal fa-" + menuDefinition.icon + "' style='margin-right:6px'></i>";
        }
        let tt = "";
        if (menuDefinition.tooltip) {
            tt = " title data-original-title='" + menuDefinition.tooltip + "' ";
        }
        let btn = $(
            "<button class='btn btn-default btn-label dropdown-toggle' data-toggle='dropdown' " +
                tt +
                ">" +
                i +
                menuDefinition.displayName +
                "&nbsp;<span class='caret'></span></button>",
        );
        btn.css("background-color", menuDefinition.background).css("color", menuDefinition.foreground);
        btn.data("gid", gid);

        // append menu button
        btnGroup.append(btn);
        // append menu options
        btnGroup.append(btnUl);

        btn.tooltip({ placement: "bottom" });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getNegativeFilterBtn() {
        return $("<button class='negativeFilter btn-link' data-original-title='filter by label not set'>not</button>");
    }

    // gets selection from all groups
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private updateSelection() {
        this.currentLabelsOn = [];
        for (let gid = 0; gid < this.groups.length; gid++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let lid = 0; lid < this.groups[gid].labelDef.length; lid++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this.groups[gid].labelDef[lid].isSelected) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (this.groups[gid].labelDef[lid].isNegative) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.currentLabelsOn.push("!" + this.groups[gid].labelDef[lid].label);
                    } else {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.currentLabelsOn.push(this.groups[gid].labelDef[lid].label);
                    }
                }
            }
        }
    }

    // paint one 'normal' label button
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createButton(
        label: string,
        gid: number,
        name: string,
        foreground: string,
        background: string,
        icon: string,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onClick: Function,
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        onDblClick: Function,
        extraClass: string,
    ): JQuery {
        let that = this;

        let i = "";
        if (icon) {
            i = "<i class='fal fa-" + icon + "' style='" + (name ? "margin-right:6px" : "") + "'></i>";
        }
        let btn = $(
            "<button tabindex='-1' class='btn btn-default btn-label " +
                extraClass +
                "' " +
                (this.canEdit ? "" : "disabled") +
                ">" +
                i +
                name +
                "</button>",
        );
        btn.css("background-color", background).css("color", foreground);
        btn.data("label", label);
        btn.data("gid", gid);

        btn.click(function (e: JQueryEventObject) {
            $(".tooltip").remove();

            let buttonClicked = $(e.delegateTarget);
            // make sure (drop down menus don't close
            if (onDblClick) {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                if (e.stopPropagation) {
                    e.stopPropagation();
                }

                that.dbClickCounter++;

                if (that.dbClickCounter === 1) {
                    that.dbClickTimer = window.setTimeout(function () {
                        // after 700 ms: reset counter and make the click
                        that.dbClickCounter = 0;
                        if (onClick) {
                            onClick(buttonClicked);
                        }
                    }, 700);
                } else {
                    // dbl click...
                    clearTimeout(that.dbClickTimer);
                    that.dbClickCounter = 0;
                    onDblClick(buttonClicked);
                }
            } else if (onClick) {
                onClick(buttonClicked);
            }
        });

        return btn;
    }

    // create one 'normal' label as button
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createLabel(labelId: string, gid: number, onClick: Function, onDblClick?: Function): JQuery | null {
        let isNegative = false; // set true for negative project filters
        if (labelId.indexOf("!") !== -1) {
            labelId = labelId.replace("!", "");
            isNegative = true;
        }

        let labelList = this.getLabelList();

        let labelDef: ILabel;
        $.each(this.groups[gid].labelDef, function (idx, ll) {
            if (ll.label === labelId) {
                labelDef = ll;
            }
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!labelDef) {
            // the label does not exist (anymore): ignore
            return $("");
        }

        if (
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.mode != "project_filter" &&
            labelDef.editors &&
            labelDef.editors.indexOf(matrixSession.getUser()) === -1
        ) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            onClick = function () {};
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            onDblClick = function () {};
        }
        // figure out if label/filter should be shown as on
        let isOn = labelDef.isSelected;
        if (!isOn && this.mode === "item_print") {
            // labels is off - so it should not be shown in print mode
            return $("");
        }

        // legacy logic to retrieve names/colors/icons
        let displayName = labelDef.displayName ? labelDef.displayName : "";
        let foreground = isOn ? "white" : labelDef.color; // default: white on color is on
        let background = isOn ? labelDef.color : "transparent";

        let tooltip = labelDef.toolTipFilterOn ? labelDef.toolTipFilterOn : "";
        let icon: string;

        // new logic
        if (labelDef.style) {
            let stylefl = this.isFilter ? labelDef.style.filter : labelDef.style.label;
            if (stylefl) {
                let style = isOn ? stylefl.on : stylefl.off;
                if (style) {
                    displayName = style.displayName ? style.displayName : displayName; // overwrite name
                    foreground = style.foreground ? style.foreground : foreground;
                    background = style.background ? style.background : background;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    icon = style.icon ? style.icon : icon;
                    tooltip = style.tooltip ? style.tooltip : tooltip;
                }
            }
        }

        if (labelDef && labelDef.label) {
            if (tooltip) {
                tooltip = labelDef.label + ": " + tooltip;
            } else {
                tooltip = labelDef.label;
            }
        }
        let btn: JQuery | null = null;
        // create the button
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (icon || displayName) {
            if (isNegative) {
                displayName = displayName ? "NOT " + displayName : "NOT";
            }
            let statusClass = (isNegative ? "labelIsNot " : "") + (isOn ? "labelIsOn " : "");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.mode == "item_history") {
                // in history render a simplified version of labels to be more easily comparable
                btn = this.createButton(
                    labelId,
                    gid,
                    displayName + (isOn ? " (on)" : " (off)"),
                    "",
                    "",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    icon,
                    onClick,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    onDblClick,
                    "labelInHistory",
                );
            } else {
                btn = this.createButton(
                    labelId,
                    gid,
                    displayName,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    foreground,
                    background,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    icon,
                    onClick,
                    onDblClick,
                    statusClass,
                );
            }

            if (tooltip) {
                btn.tooltip({ title: tooltip, placement: "bottom", container: "body" });
            }
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (btn && btn.css("background") == "transparent" && btn.css("color") == "rgb(0, 0, 0)") {
            btn.css("color", "var(--Accent)");
        }

        return btn;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addChip(gid: number) {
        let that = this;

        let btnGroup = $('<div class="btn-group labelTools chip">');
        this.ui.append(btnGroup);

        $.each(this.groups[gid].labelDef, function (idx: number, label: ILabel) {
            label.isNegative = that.currentLabelsOn.indexOf("!" + label.label) > -1;
            label.isSelected = label.isNegative || that.currentLabelsOn.indexOf(label.label) > -1;
            if (label.isSelected) {
                let btn = that.createLabel(
                    (label.isNegative ? "!" : "") + label.label,
                    gid,
                    () => {},
                    () => {},
                );

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                btn.append(
                    $("<a class='fal fa-times' />").click(() => {
                        //Remove filter
                        let currentFilter = ml.LabelTools.getFilter().split(",");
                        let index = currentFilter.indexOf((label.isNegative ? "!" : "") + label.label);
                        if (index > -1) {
                            // TODO: MATRIX-7555: lint errors should be fixed for next line
                            // eslint-disable-next-line
                            currentFilter = currentFilter.filter((value, i) => i != index);
                            ml.LabelTools.setFilter(currentFilter);
                            $("#filterDialog").remove();
                            app.canNavigateAwayAsync()
                                .done(function () {
                                    $(".tooltip").remove();
                                    $(".itemFilterTool").hide();
                                    ml.LabelTools.setFilter(currentFilter);

                                    const project = matrixSession.getProject();

                                    if (!project) {
                                        return;
                                    }

                                    app.loadProject(project, app.getCurrentItemId()).then(() => {
                                        if (currentFilter.length > 0) {
                                            $(".itemFilterTool").show();
                                            new LabelSwitches(
                                                $("#currentFilterContainer"),
                                                false,
                                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                                null,
                                                currentFilter,
                                                "filter_chip",
                                                (sel) => {},
                                            );
                                        } else {
                                            $(".itemFilterTool").hide();
                                        }
                                        ml.UI.toggleFilters(true);
                                    });
                                })
                                .fail(function () {
                                    ml.UI.showError(
                                        "Filter was not activated.",
                                        "To activate reload project after saving the item!",
                                    );
                                });
                        }
                    }),
                );
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                btnGroup.append(btn);
            }
        });
        return btnGroup;
    }
}
