import { plugins } from "../businesslogic/index";
import { SelectSearchQueue } from "../../SelectSearchQueue";
import { mDHF } from "../businesslogic/index";
import { IItemWatched } from "../businesslogic/index";
import { ml } from "../matrixlib";

import { ItemControl } from "./Components/ItemForm";
import { CalendarPanel } from "./Panels/CalendarPanel";
import { DocumentPanel } from "./Panels/DocumentPanel";
import { GroupPanel } from "./Panels/GroupPanel";
import { ItemPanel } from "./Panels/ItemPanel";
import { MyDocsPanel } from "./Panels/MyDocsPanel";
import { IPublishInfo, PublishPanel } from "./Panels/PublishPanel";
import { SyncPanel } from "./Panels/SyncPanel";
import { TagPanel } from "./Panels/TagsPanel";
import { IItem, app, matrixSession, globalMatrix, matrixApplicationUI, ControlState } from "../../globals";

export interface IPanel {
    destroy: () => void;
    title: string;
    toggleZen?: () => void;
}

export interface IItemPanelOptions {
    control: JQuery;
    itemId: string;
    changed?: (needsSave: boolean) => void;
    cachedItem?: IItem;
}

export class Application {
    public lastMainItemForm: ItemControl;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public currentPanel: IPanel;
    protected currentPrintPanel: IPanel;
    protected saveEnabled: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    currentItem: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected currentItemForcedReadonly: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected isSaving: boolean;
    private latestUpdateControlEventId = 0;

    constructor() {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.lastMainItemForm = null;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.currentPrintPanel = null;

        this.saveEnabled = false; // to not fire an event when button changes
        this.setSaveCancelState(false, false);

        $("#btnSave").click(function () {
            //console.log( "SAVE BUTTON");
            that.saveSave();

            return true;
        });

        $("#btnCancel").click(function () {
            app.cancel();
            return true;
        });
        $("#comment").mousedown(function () {
            matrixSession.editComment();
        });

        $("#saveDlg").hide();
    }

    // this functions saves the item, preventing a dounle save
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    saveSave() {
        let that = this;
        //console.log( "SAVING request");
        if (that.isSaving) {
            // prevent double save
            //console.log( "SAVING already saving - ignoring request");
            return;
        }

        // now the button is readonly
        that.isSaving = true;
        that.setSaveCancelState(false, true);
        //console.log( "SAVING not yet saving - so I will start");
        app.saveAsync(true)
            .done(function () {
                // but stays readonly but set also the state to disabled
                that.setSaveCancelState(false, false);
            })
            .fail(function () {
                // item did not actually save so button goes back to enabled
                that.setSaveCancelState(true, false);
            })
            .always(function () {
                //console.log( "SAVING done - will enable a future save");
                that.isSaving = false;
            });
    }
    updateMainUI(disabled?: boolean): void {
        let that = this;
        if (disabled === true) {
            this.setSaveCancelState(false, false);
            $("#comment").prop("disabled", true);
            // $("#comment").val("");
            return;
        }
        if (globalMatrix.ItemConfig.hasWriteAccess(matrixSession.getUser()) || globalMatrix.matrixProduct === "Admin") {
            if (app.getNeedsSave()) {
                // MATRIX-6169: disallow filter selection when item is not saved
                ml.UI.changeGlobalFilterSelectionEnabled(false);
                this.setSaveCancelState(true, false);
            } else {
                ml.UI.changeGlobalFilterSelectionEnabled(true);
                this.setSaveCancelState(false, false);
            }

            $("#comment").prop("disabled", false);
            $("#comment").val(matrixSession.getComment().replace(/&lt;/g, "<"));
            matrixSession.updateCommentCheckboxBoxVisibility();
            $("#comment").removeClass("readonlyComment");
        } else {
            this.setSaveCancelState(false, false);
            $("#comment").prop("disabled", true);
            $("#comment").addClass("readonlyComment");
            if (globalMatrix.ItemConfig.getTimeWarp()) {
                $("#comment").val(ml.UI.DateTime.renderHumanDate(new Date(globalMatrix.ItemConfig.getTimeWarp())));
                $(".navbar-fixed-bottom").css("background-color", "var(--Light-red)");
                $(".navbar-inverse .navbar-brand").css("color", "var(--White)");
                $("#btnSave").remove();
                let close = $(
                    '<button style="float:right" type="submit" class="btn btn-success .navbar-btn navbarCtrl">Close</button>',
                );
                $("#btnCancel").replaceWith(close);
                close.click(function () {
                    window.location.href = globalMatrix.matrixBaseUrl + "/" + matrixSession.getProject();
                });
            } else {
                $("#comment").val("read only access");
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setSaveCancelState(enabled: boolean, quietCancel: boolean) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.saveEnabled != enabled) {
            // something changed
            if (enabled) {
                matrixSession.pushMessages.editItem(app.getCurrentItemId());
            } else if (!quietCancel) {
                matrixSession.pushMessages.unEditItem();
            }
        }

        this.saveEnabled = enabled;

        $("#btnSave").prop("disabled", !enabled);
        $("#btnCancel").prop("disabled", !enabled);
    }

    editConfiguration(): void {
        let form = $('<div class="container" style="width:100%"><br/>');

        app.dlgForm.hide();
        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.append(form);

        $("#idEnableWiki", form)
            .prop("checked", localStorage.getItem("wiki") === "on")
            .change(function () {
                localStorage.setItem("wiki", $("#idEnableWiki", form).prop("checked") ? "on" : "off");
            });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    destroyOldControls() {
        // in case there's some queued searches for document selections, cancel them
        SelectSearchQueue.resetSearchQueue();

        plugins.destroyActiveControlPage();

        document.title = "Matrix Requirements";
        if (this.currentPrintPanel && this.currentPrintPanel.destroy) {
            this.currentPrintPanel.destroy();
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.currentPrintPanel = null;

        if (this.currentPanel && this.currentPanel.destroy) {
            this.currentPanel.destroy();
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.currentPanel = null;

        app.itemForm.html("");
        app.printForm.html("");

        ml.UI.toggleFilters(false);

        if (matrixApplicationUI.lastMainItemForm && matrixApplicationUI.lastMainItemForm.controls) {
            matrixApplicationUI.lastMainItemForm.controls = [];
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refreshLinks() {
        if (this.lastMainItemForm) {
            this.lastMainItemForm.refreshLinks();
        }
    }

    updateControl(watcherInfo: IItemWatched, itemChanged: (needsSave: boolean) => void): void {
        let that = this;

        let forceReadonly = watcherInfo.editor && !watcherInfo.editor.thisSocket;
        // this.currentItem.history.length is 0 for XTC runs created by server?
        let currentVersion = this.currentItem.maxVersion;
        const eventId = ++this.latestUpdateControlEventId;

        if (watcherInfo.editor && watcherInfo.editor.thisSocket) {
            // I am actually editing the item...
            // nothing to do but to update viewers
            this.lastMainItemForm.setViewers(watcherInfo);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (this.currentItem && this.currentItem.id == watcherInfo.item) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (currentVersion == watcherInfo.version) {
                // the user still has the current version in the cache
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (this.currentItemForcedReadonly == forceReadonly) {
                    // nothing changed but the viewers
                    this.lastMainItemForm.setViewers(watcherInfo);
                } else {
                    // make current item writeable/readonly
                    this.createItemControl(
                        {
                            control: app.itemForm,
                            itemId: watcherInfo.item,
                            changed: itemChanged.bind(this),
                            cachedItem: this.currentItem,
                        },
                        forceReadonly,
                    ).done(function () {
                        that.updateMainUI();
                        ml.Search.renderHighlight();
                        that.lastMainItemForm.setViewers(watcherInfo);
                    });
                }
            } else {
                // the user has an old item in the cache...
                // get the new one and render
                this.createItemControl(
                    {
                        control: app.itemForm,
                        itemId: watcherInfo.item,
                        changed: itemChanged.bind(this),
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        cachedItem: null,
                    },
                    forceReadonly,
                    eventId,
                ).done(() => {
                    that.updateMainUI();
                    ml.Search.renderHighlight();
                    that.lastMainItemForm.setViewers(watcherInfo);
                });
            }
        } else {
            // the watched item is something else / ignore the message
        }
    }

    //  MATRIX-3721 function (re)rendering the displayed item in a readonly mode

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    forceReadonly(itemId: string) {
        this.destroyOldControls();
        this.updateMainUI(true);
        this.createItemControl(
            {
                control: app.itemForm,
                itemId: itemId,
                changed: () => {},
                cachedItem: this.currentItem,
            },
            true,
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        ).done(function () {});
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    highlightReferences() {
        if (this.lastMainItemForm) {
            for (let control of this.lastMainItemForm.getControls()) {
                if (control.getController && control.getController().highlightReferences) {
                    control.getController().highlightReferences();
                }
            }
        }
    }

    createControl(
        folderType: string,
        itemId: string,
        itemChanged?: (needsSave: boolean) => void,
        cachedItem?: IItem,
    ): void {
        let that = this;
        this.destroyOldControls();

        let nonItem = true;

        if (plugins.supportsControlPage(folderType)) {
            document.title = matrixSession.getProject() || "";
            ml.ContextFrames.visibility(false);
            plugins.createControlPage(
                {
                    type: folderType,
                    control: app.itemForm,
                    controlState: ControlState.FormView,
                },
                ml.UI.toggleFilters,
            );
            if (matrixSession.getUISettings().legacyPrint) {
                plugins.createControlPage(
                    {
                        type: folderType,
                        control: app.printForm,
                        controlState: ControlState.Print,
                    },
                    ml.UI.toggleFilters,
                );
            }
        } else if (folderType === "_DELETED") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new CalendarPanel(true);
        } else if (folderType === "_DOCS") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new DocumentPanel();
        } else if (folderType === "_CHANGES") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new CalendarPanel();
        } else if (folderType === "_TAGS") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new TagPanel();
        } else if (folderType === "_MYDOCS") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new MyDocsPanel();
        } else if (folderType === "_SYNC") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new SyncPanel();
        } else if (folderType[0] === "_") {
            ml.ContextFrames.visibility(false);
            matrixSession.pushMessages.unWatchItem();
            this.currentPanel = new GroupPanel(folderType, app.getItemTitle(itemId));
        } else {
            if (itemId.match(/(F-[A-Z]+-1$)/)) {
                matrixSession.pushMessages.unWatchItem();
                // handle QMS publishing differently
                let gs = <IPublishInfo>globalMatrix.ItemConfig.getCategorySetting(folderType, "publish");
                if (gs) {
                    ml.ContextFrames.visibility(false);
                    this.currentPanel = new PublishPanel(folderType);
                    return;
                }
            }
            ml.ContextFrames.visibility(true);
            nonItem = false;
            // enable project filters by overlay
            document.title = itemId + " - " + matrixSession.getProject();
            ml.UI.toggleFilters(true);

            this.createItemControl({
                control: app.itemForm,
                itemId: itemId,
                changed: itemChanged?.bind(this),
                cachedItem: cachedItem,
            }).done(function () {
                that.updateMainUI();
                ml.Search.renderHighlight();
                matrixSession.pushMessages.watchItem(itemId);
            });
        }

        that.updateMainUI();

        // update context frame
        if (nonItem) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.ContextFrames.fillContextFrame(null, itemId);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderErrorControl(control: JQuery, header: string, text: string, contextFrame?: boolean) {
        if (!contextFrame) {
            this.destroyOldControls();
        }

        ml.UI.showError(header, text);
        let div = $("<div class='errorControl'>");
        div.append($("<h1>").html(header));
        div.append($("<p>").html(text));

        control.html("").append(div);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async createItemControlCached(options: IItemPanelOptions, forceReadOnly?: boolean) {
        this.destroyOldControls();

        this.currentItem = ml.JSON.clone(options.cachedItem);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.currentItemForcedReadonly = forceReadOnly;

        // something went wrong
        if (!this.currentItem || this.currentItem.title === undefined) {
            this.renderErrorControl(
                options.control,
                "Item " + options.itemId + " does not exist anymore!",
                "The item was just deleted! Hit F5 to update the project tree or check the <span class='link openChanges'>CHANGES Dashboard</span>",
            );
            window.setTimeout(() => {
                $(".openChanges").click(() => {
                    app.treeSelectionChangeAsync("CHANGES");
                });
            }, 400);
            return;
        }

        if (options.cachedItem && options.cachedItem.title) {
            document.title = matrixSession.getProject() + "/" + options.itemId + " " + options.cachedItem.title;
        } else {
            document.title = options.itemId + " - " + matrixSession.getProject();
        }

        if (!app.canViewItem(this.currentItem)) {
            this.renderErrorControl(
                options.control,
                "You have no rights to view " + options.itemId + "",
                "Talk to the project administrator.",
            );
            return;
        }
        // render items for display and print
        plugins.init(this.currentItem);

        let itemPanel = new ItemPanel({
            control: options.control,
            controlState:
                app.canEditItem(this.currentItem) && !forceReadOnly ? ControlState.FormEdit : ControlState.FormView,
            changed: options.changed,
            item: this.currentItem,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            parent: app.getParentId(this.currentItem.id),
            isItem: typeof this.currentItem.children === "undefined",
        });
        await itemPanel.load();

        this.currentPanel = itemPanel;
        this.lastMainItemForm = itemPanel.getItemForm();

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (typeof mDHF === "undefined" || !mDHF.isDocumentType(this.currentItem.type)) {
            if (matrixSession.getUISettings().legacyPrint) {
                app.printForm.css("display", "block"); // to render heights of test tables correctly...
                let printPanel = new ItemPanel({
                    control: app.printForm,
                    controlState: ControlState.Print,
                    canEdit: false,
                    item: this.currentItem,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    parent: app.getParentId(this.currentItem.id),
                    isItem: typeof this.currentItem.children === "undefined",
                });

                app.printForm.css("display", "none");
                this.currentPrintPanel = printPanel;
            }
        }

        ml.UI.toggleFilters(true);

        // allow plugins to modify
        plugins.updateItemPanel();

        // allow for context help page
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ml.ContextFrames.fillContextFrame(this.currentItem, this.currentItem.id);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private createItemControl(options: IItemPanelOptions, forceReadOnly?: boolean, eventId?: number) {
        let res = $.Deferred();
        if (options.cachedItem) {
            this.createItemControlCached(options, forceReadOnly).then(() => {
                res.resolve();
            });
        } else {
            // item is not cached... so get it and do the job
            app.getItemAsync(options.itemId).done((_data) => {
                // do nothing if there are newer events
                if (typeof eventId !== "undefined" && eventId !== this.latestUpdateControlEventId) {
                    // intentionally not calling res.resolve(), because we want to skip the awaited actions as well
                    return;
                }

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                options.cachedItem = _data;

                this.createItemControlCached(options, forceReadOnly).then(() => {
                    res.resolve();
                });
            });
        }
        return res;
    }
}
