/// <reference types="bootstrap" />
import { IItemGet, ControlState, IItem } from "../../../globals";
import { IFieldParameter } from "../../../ProjectSettings";
import { IFieldHandler } from "../../businesslogic/FieldHandlers/IFieldHandler";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";

export type { ITableParams, IBaseControl, IBaseControlOptions };
export { BaseControl };

interface ITableParams {
    canBeModified?: boolean; // if true, rows can be added and moved,
    create?: boolean; // if canBeModified also can add lines (with little icon)
    showLineNumbers?: boolean; // whether to show line numbers in front of each row
    maxRows?: number; // if set to a value other than -1, the maximum number of rows which can be created
    fixRows?: number; // if set to a value other than -1, the number of rows cannot be changed to another value
    readonly_allowfocus?: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    columns: any; // TO BE DONE
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    onCellChanged?: Function; // callback
}

interface IBaseControl {
    getFieldHandler(): IFieldHandler;
    setFieldHandler(fieldHandler: IFieldHandler): void;

    getValueAsync: (latestItem?: IItemGet) => Promise<unknown>;
    hasChangedAsync: () => Promise<boolean>;
    resizeItem: (width?: number, forceRedraw?: boolean) => void;
    destroy: () => void;
    // optional
    highlightReferences?: () => void;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getText?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getValueRaw?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setValue?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    updateItem?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refreshLinks?: Function; // update hyperlinks if there's any (can be smart links or lists of items)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    linksToCreate?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    labelsToSet?: Function;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    redraw?: Function; // repaint all
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    refresh?: Function; // update row heights
    needsLatest: boolean;
    requiresContent?: () => boolean;
    // hacks...
    disableDelayedShow?: boolean; // if set to true, the display does not automatically show itself after a timeout (that's only done in tiny text editor(I think))
}

interface IBaseControlOptions {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    [key: string]: any;
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldType?: string;
    fieldId?: number;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    valueChanged?: Function;
    parameter?: IFieldParameter;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    fieldValue?: any; // value as stored in DB
    fieldHandler?: IFieldHandler;
    isItem?: boolean;
    item?: IItem;
    isForm?: boolean;
    isPrint?: boolean;
    isTooltip?: boolean;
    id?: string;
    isHistory?: number; // version shown (if known)
    type?: string;
    isFolder?: boolean;
    requiresContent?: boolean;
}

abstract class BaseControl<T extends IFieldHandler> implements IBaseControl {
    protected _root: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    disableDelayedShow: boolean; // if set to true, the display does not automatically show itself after a timeout (that's only done in tiny text editor(I think))
    needsLatest: boolean;

    constructor(control: JQuery, fieldHandler: T) {
        this._root = control;
        this.needsLatest = false;
        this.fieldHandler = fieldHandler;
    }
    fieldHandler: T;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getFieldHandler() {
        return this.fieldHandler;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setFieldHandler(fieldHandler: T) {
        this.fieldHandler = fieldHandler;
    }

    protected createHelp(settings: IBaseControlOptions): JQuery {
        let helpline = $("<span class='baseControlHelp'>" + settings.help + "</span>");
        helpline.data("fieldId", settings.fieldId);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (settings.parameter.externalHelp && settings.controlState !== ControlState.Print) {
            const showHelp = $(
                "<a href='https://" +
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    settings.parameter.externalHelp +
                    "' target='_blank'> <span class='fa fa-info-circle'></span></a>",
            );
            helpline.append(showHelp);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (settings.parameter.popupHelp && settings.controlState !== ControlState.Print) {
            const showHelp = $("<span class='fa fa-question-circle showMore' style='padding-left:10px'></span>");
            showHelp.tooltip(<TooltipOptions>{
                html: true,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                title: settings.parameter.popupHelp,
                container: "body",
                template:
                    '<div class="tooltip smarttext-tooltip" role="tooltip"><div class="tooltip-arrow smarttext-tooltip-arrow"></div><div class="tooltip-inner smarttext-tooltip-inner"></div></div>',
            });
            helpline.append(showHelp);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (settings.parameter.inlineHelp && settings.controlState !== ControlState.Print) {
            const showHelp = $("<div class='inlineHelp'>");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            showHelp.html(settings.parameter.inlineHelp);
            helpline.append(showHelp);
        }
        return helpline;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    abstract getValueAsync(): Promise<any>;
    abstract hasChangedAsync(): Promise<boolean>;
    abstract resizeItem(newWidth?: number, force?: boolean): void;
    abstract destroy(): void;
}
