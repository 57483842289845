import { IDHFSection, IDHFControlDefinition, mDHF } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { globalMatrix } from "../../../globals";
import { IRiskConfig } from "../../../ProjectSettings";
import { ITracesOptions } from "../../../common/businesslogic/FieldHandlers/Document/TraceDocFieldHandler";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";

export { Traces };

class Traces extends BaseDHFSection<ITracesOptions> implements IDHFSection {
    private upTraces: boolean;
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private traceOptions: any[];

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    fieldHandler: IDocFieldHandler;

    setFieldHandler(fieldHandler: IDocFieldHandler): void {
        this.fieldHandler = fieldHandler;
    }

    constructor(isUp: boolean) {
        super();
        let that = this;
        this.traceOptions = [];
        this.upTraces = isUp;
        let cats = globalMatrix.ItemConfig.getCategories();
        for (let cidx = 0; cidx < cats.length; cidx++) {
            if (!mDHF.isDocumentType(cats[cidx]) && cats[cidx] !== "FOLDER") {
                if (this.upTraces) {
                    let dreq = globalMatrix.ItemConfig.getLinkInfo(cats[cidx], false, true, true);
                    for (let idx = 0; idx < dreq.length; idx++) {
                        this.traceOptions.push({
                            id: cats[cidx] + "|" + (<string[]>dreq[idx].category).join("+"),
                            label:
                                (<string[]>dreq[idx].category).join(" or ") +
                                " <- " +
                                cats[cidx] +
                                ": " +
                                cats[cidx] +
                                " " +
                                dreq[idx].reason,
                        });
                    }
                    dreq = globalMatrix.ItemConfig.getLinkInfo(cats[cidx], false, false, true);
                    for (let idx = 0; idx < dreq.length; idx++) {
                        this.traceOptions.push({
                            id: cats[cidx] + "|" + (<string[]>dreq[idx].category).join("+"),
                            label:
                                (<string[]>dreq[idx].category).join(" or ") +
                                " <- " +
                                cats[cidx] +
                                ": " +
                                cats[cidx] +
                                " " +
                                dreq[idx].reason,
                        });
                    }
                } else {
                    let dreq = globalMatrix.ItemConfig.getLinkInfo(cats[cidx], true, true, true);
                    for (let idx = 0; idx < dreq.length; idx++) {
                        this.traceOptions.push({
                            id: cats[cidx] + "|" + (<string[]>dreq[idx].category).join("+"),
                            label:
                                cats[cidx] +
                                " -> " +
                                (<string[]>dreq[idx].category).join("  or ") +
                                ": " +
                                cats[cidx] +
                                " " +
                                dreq[idx].reason,
                        });
                    }
                    dreq = globalMatrix.ItemConfig.getLinkInfo(cats[cidx], true, false, true);
                    for (let idx = 0; idx < dreq.length; idx++) {
                        this.traceOptions.push({
                            id: cats[cidx] + "|" + (<string[]>dreq[idx].category).join("+"),
                            label:
                                cats[cidx] +
                                " -> " +
                                (<string[]>dreq[idx].category).join(" or ") +
                                ": " +
                                cats[cidx] +
                                " " +
                                dreq[idx].reason,
                        });
                    }
                }
            }
        }
        // add 1.4 mitigations
        let riskConfigs = globalMatrix.ItemConfig.getFieldsOfType("risk");
        for (let fdx = 0; fdx < riskConfigs.length; fdx++) {
            let riskConfigParam = <IRiskConfig>riskConfigs[fdx].field.parameterJson;
            if (riskConfigParam && riskConfigParam.mitigationTypes) {
                for (let idx = 0; idx < riskConfigParam.mitigationTypes.length; idx++) {
                    let riskMitigationType = riskConfigParam.mitigationTypes[idx];
                    if (this.upTraces) {
                        this.traceOptions.push({
                            id: riskMitigationType.type + "|" + riskConfigs[fdx].category,
                            label:
                                "From " + riskMitigationType.type + " to " + riskConfigs[fdx].category + " (mitigates)",
                        });
                    } else {
                        this.traceOptions.push({
                            id: riskConfigs[fdx].category + "|" + riskMitigationType.type,
                            label:
                                "From " +
                                riskConfigs[fdx].category +
                                " to " +
                                riskMitigationType.type +
                                " (mitigation)",
                        });
                    }
                }
            }
        }
        // add 1.5 & later mitigations
        let risk_config = globalMatrix.ItemConfig.getRiskConfig();
        if (risk_config && risk_config.mitigationTypes) {
            riskConfigs = globalMatrix.ItemConfig.getFieldsOfType("risk2");
            for (let fdx = 0; fdx < riskConfigs.length; fdx++) {
                $.each(risk_config.mitigationTypes, function (mitTypeIdx, mitType) {
                    if (that.upTraces) {
                        that.traceOptions.push({
                            id: mitType.type + "|" + riskConfigs[fdx].category,
                            label: mitType.type + " <- " + riskConfigs[fdx].category + ": as mitigation",
                        });
                    } else {
                        that.traceOptions.push({
                            id: riskConfigs[fdx].category + "|" + mitType.type,
                            label: riskConfigs[fdx].category + " -> " + mitType.type + ": as mitigation",
                        });
                    }
                });
            }
        }
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.mxDropdown(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: { options: this.traceOptions, splitHuman: false },
            }),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let options =
            '<div class="checkbox" ><label><input type="checkbox" class="p1" ' +
            (controllerConfig.strict ? "checked" : "") +
            "> Warn about missing traces</label></div>";
        custom.append($(options));
        options =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference of (from) items to table of content</label></div>";
        custom.append($(options));
        options =
            '<div class="checkbox" ><label><input type="checkbox" class="numericalOrder" ' +
            (controllerConfig.numericalOrder ? "checked" : "") +
            "> List elements in numerical order (flat)</label></div>";
        custom.append($(options));

        let itemInfo = $(
            '<select class="p4 docOptionSelect form-control">' +
                '<option value="">Ignore dates (do not mark outdated traces)</option>' +
                '<option value="warning">Indicate outdated traces</option>' +
                '<option value="error">Treat outdated traces as traceability errors</option>' +
                "</select>",
        );
        custom.append(itemInfo);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $(custom.find(".p4")[0]).val(controllerConfig.dateoptions);
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let newVal = $(custom.find(".p1")[0]).prop("checked");
        let changed = newVal !== controllerConfig.strict;
        controllerConfig.strict = newVal;
        this.fieldHandler.setDHFConfig(controllerConfig);

        newVal = $(custom.find(".p4")[0]).val();
        changed = changed || newVal !== controllerConfig.dateoptions;
        controllerConfig.dateoptions = newVal;
        newVal = $(custom.find(".includeInToc")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.includeInToc;
        controllerConfig.includeInToc = newVal;
        newVal = $(custom.find(".numericalOrder")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.numericalOrder;
        controllerConfig.numericalOrder = newVal;

        return changed;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastVal = await ctrl.control.getController().getValueAsync();
        if (!lastVal) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
}
