// A field handler base implementation for field types that ultimately are displayed
// by the table control.

import { IDropdownOption } from "../../../ProjectSettings";
import { FieldDescriptions } from "../FieldDescriptions";
import { IFieldHandler } from "./IFieldHandler";
import { IAnyMap, IStringMap } from "../../../globals";
import { ITableRow } from "../../UI/Controls/tableCtrl";

export { ColumnEditor, BaseTableFieldHandler };
export type { ITableControlOptionsColumn, ITableControlBaseParams };

// A steplist has a table with different column types. They are defined here and interpreted in the table
// control and field configuration options.
enum ColumnEditor {
    none = "none",
    number = "number",
    textline = "textline",
    select = "select",
    commentlog = "commentlog",
    colorPicker = "colorPicker",
    category = "category",
    readonly = "readonly",
    selectIcon = "selectIcon",
    text = "text",
    date_today = "date_today",
    date = "date",
    today = "today",
    current_version = "current_version",
    versionletter = "versionletter",
    signaturemeaning = "signaturemeaning",
    user = "user",
    user_self = "user_self",
    self = "self",
    group = "group",
    revision = "revision",
    result = "result",
    design = "design",
    uprules = "uprules",
    downrules = "downrules",
    ecocapa = "ecocapa",
    eco = "eco",
    uid = "uid",
    rules = "rules",
}

interface ITableControlOptionsColumn {
    name: string; //the name of the column shown in the table header
    field: string; //the id in the column / of the this.data
    editor: ColumnEditor; //  (optional) 'text','result' (if and how to edit the cell). , ...
    options?: { [key: string]: string } | IDropdownOption[]; // for select editor
    relativeWidth?: number; // can be used to start with unevenly spaced columns (default is 350)
    headerCssClass?: string; // Accepts a string as a class name, applies that class to the cell for the column header.
    cssClass?: string; // Accepts a string as a class name, applies that class to every row cell in the column.
}

interface ITableControlBaseParams {
    columns?: ITableControlOptionsColumn[]; // columns to be added [{name, field, editor, values}], list of column definitions:
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initialContent?: any[];
}

class BaseTableFieldHandler implements IFieldHandler {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected data: any[];
    protected tableConfig: ITableControlBaseParams;

    constructor(configIn: ITableControlBaseParams) {
        this.tableConfig = <ITableControlBaseParams>configIn ?? {};
        this.data = [];
    }

    getFieldType(): string {
        return FieldDescriptions.Field_steplist;
    }

    protected getColumnByField(fieldId: string): ITableControlOptionsColumn | undefined {
        if (this.tableConfig.columns) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            const cols = this.tableConfig.columns.filter((col) => col.field == fieldId);
            if (cols.length >= 1) {
                return cols[0];
            }
        }
        return undefined;
    }

    columnNumberToFieldId(columnNumber: number): string {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (columnNumber >= this.tableConfig.columns.length) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            throw new Error(`${columnNumber} is outside the range [0..${this.tableConfig.columns.length}]`);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.tableConfig.columns[columnNumber].field;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    validate() {
        // No validation by default
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        if (serializedFieldData) {
            // This requirement to remove <div> tags comes from the table control implementation.
            this.data = JSON.parse(serializedFieldData.replace("<div/>", ""));
        } else if (this.tableConfig && this.tableConfig.initialContent) {
            // A cheap clone operation.
            this.data = JSON.parse(JSON.stringify(this.tableConfig.initialContent));
        } else {
            // If we have no data and no initialContent data, we default to a valid empty table.
            this.data = [];
        }
    }

    getData(): string | undefined {
        return JSON.stringify(this.data);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.data = JSON.parse(value);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setDataAsArray(dataIn: any[], fixData = false) {
        this.data = dataIn;
    }

    getRowCount(): number {
        return this.data.length;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    deleteRow(rowNumber: number) {
        if (rowNumber >= this.data.length) {
            throw new Error(`Row ${rowNumber} not found`);
        }
        this.data.splice(rowNumber, 1);
        this.validate();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    insertRow(rowNumber: number, columnData: Array<any>) {
        // Construct a row object from the column data
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let o: Record<string, any> = {};
        columnData.forEach((v, i) => {
            o[this.columnNumberToFieldId(i)] = v;
        });
        this.data.splice(rowNumber, 0, o);
        this.validate();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    clear() {
        this.data = [];
    }

    getColumnCount(): number {
        let columnCount = 0;
        if (this.tableConfig.columns) {
            columnCount = this.tableConfig.columns.length;
        }
        return columnCount;
    }

    /**
     * Set data for a particular cell in the table given by a row number and
     * a column name.
     * @param row the zero-based row number.
     * @param columnId the column name.
     * @param data
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setColumnData(row: number, columnId: string, data: any) {
        // Create rows if necessary.
        if (this.data.length <= row) {
            for (let i = this.data.length; i <= row; i++) {
                this.data.push({});
            }
        }
        // TODO: there should be a switch statement here on type. Complex types may
        // be JSON objects and should be parsed.
        this.data[row][columnId] = data;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getColumnData(row: number, columnId: string): any {
        // TODO: there should be a switch statement here on type. Complex types may
        // be JSON objects and should be parsed.
        return this.data[row][columnId];
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getRowData(row: number): Array<any> {
        let result = [];
        for (let i = 0; i < this.getColumnCount(); i++) {
            const columnId = this.columnNumberToFieldId(i);
            result[i] = this.getColumnData(row, columnId);
        }
        return result;
    }
}
