import { LineEditorExt, ILineEditorLine } from "../../../core/common/UI/ILineEditor";
import { IConfigApp } from "./IConfigApp";
import { ConfigPage, IConfigPage } from "./ConfigPage";

export { LineEditor };

class LineEditor {
    editor: LineEditorExt;
    constructor() {
        this.editor = new LineEditorExt();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    showDialog(
        configPage: ConfigPage,
        title: string,
        height: number,
        input: ILineEditorLine[],
        onOk: (update: ILineEditorLine[]) => boolean,
        width?: number,
    ) {
        let that = this;
        this.editor.showDialog(
            title,
            height,
            input,
            onOk,
            width,
            (
                container: JQuery,
                showUsers: boolean,
                showGroups: boolean,
                help: string,
                empty: string,
                selected: string[],
                dialogTitle: string,
                onSelect: (selection: string[]) => void,
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            ) => {
                configPage.showUserAndGroupsSelectWithDialog(
                    container,
                    showUsers,
                    showGroups,
                    help,
                    empty,
                    selected,
                    dialogTitle,
                    onSelect,
                );
            },
        );
    }
    static mapToKeys(results: ILineEditorLine[]): ILineEditorLine[] {
        return LineEditorExt.mapToKeys(results);
    }
}
