import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { HyperlinkFieldHandler };

class HyperlinkFieldHandler implements IFieldHandler {
    private data: string;
    private config: XRFieldTypeAnnotatedParamJson;

    constructor(configIn: XRFieldTypeAnnotatedParamJson) {
        this.config = configIn;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.data = undefined;
    }

    getFieldType(): string {
        return FieldDescriptions.Field_hyperlink;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.data = serializedFieldData;
    }

    getData(): string | undefined {
        return this.data;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }
}
