import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { GenericFieldHandler };

/**
 * GenericFieldHandler is a field handler which does no validation on the field
 * data. It simply stores the data as a string.
 */
class GenericFieldHandler implements IFieldHandler {
    protected fieldType: string;
    protected data: string;
    private config: XRFieldTypeAnnotatedParamJson;

    constructor(fieldTypeIn: string, configIn: XRFieldTypeAnnotatedParamJson) {
        this.fieldType = fieldTypeIn;
        this.config = configIn ?? {};
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.data = undefined;
    }

    /**
     * Return the field type of this field as a string.
     * @returns string
     */
    getFieldType(): string {
        return this.fieldType;
    }

    /**
     * initData will set the data of the field handler to the passed-in parameter,
     * or, if it is undefined, consult the initialContent key of the configuration
     * object. If this key is defined, then the data of the field handler will
     * be set to that value.
     * @param serializedFieldData
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initData(serializedFieldData: string | undefined) {
        if (serializedFieldData !== undefined) {
            this.data = serializedFieldData;
        } else if (this.config && this.config.initialContent !== undefined) {
            this.data = this.config.initialContent;
        }
    }

    /**
     * Get the data for the field.
     * @returns a string or undefined.
     */
    getData(): string | undefined {
        return this.data;
    }

    /**
     * Set the field data to the input string.
     * @param value
     * @param doValidation
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }
}
